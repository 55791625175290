import { useContext, useMemo, useRef, useState } from "react";

import { type EntityModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import StripePaymentMethods, { type StripeFromHandle } from "../../../../../Pages/Entity/PaymentMethods/Stripe";
import { useAsyncLoadingFunction } from "../../../../hooks/useAsyncLoadingFunction";
import { EditBillingProfileContext } from "../../EditBillingProfileFormContext";
import { PAYMENT_PANEL_ID } from "../../panels/PaymentPanel";

export type AddPaymentMethodDialogProps = {
  open: boolean;
  entity: ModelId<EntityModel>;
  onClose: () => void;
};

export const AddPaymentMethodDialog = ({ entity, open, onClose }: AddPaymentMethodDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isStripeReady, setStripeReady] = useState(false);

  const stripeRef = useRef<StripeFromHandle>(null);
  const [isSubmitting, submitPaymentMethodChange] = useAsyncLoadingFunction(async () =>
    stripeRef.current?.handleSubmit()
  );

  const saveFormValuesAndGetURLParam = useContext(EditBillingProfileContext);

  const returnUrl = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.hash = PAYMENT_PANEL_ID;
    Array.from(currentUrl.searchParams.keys()).forEach((key) => currentUrl.searchParams.delete(key));
    currentUrl.searchParams.set(...saveFormValuesAndGetURLParam());
    return currentUrl.toString();
  }, [saveFormValuesAndGetURLParam]);

  return (
    <Dialog aria-labelledby="edit-payment-method-dialog" open={open} maxWidth="md" fullWidth fullScreen={fullScreen}>
      <DialogTitle>Add new payment method</DialogTitle>
      <DialogContent sx={{ minHeight: 360 }}>
        <Stack direction="column" spacing={2} mt={1}>
          <StripePaymentMethods
            setLoadSuccess={setStripeReady}
            entityId={entity.id ?? ""}
            customerId={entity.customer?.id ?? ""}
            currency={entity.currency || "USD"}
            returnUrl={returnUrl}
            ref={stripeRef}
            newEntity={false}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" data-cy="cancel" disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting || !isStripeReady}
          onClick={submitPaymentMethodChange}
          variant="contained"
          color="primary"
          data-cy="edit"
        >
          Add payment method
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
