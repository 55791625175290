export const getSqlScript = (password: string): string => `
    -- Step 1: Only Admin can run the following commands
    USE ROLE accountadmin;

    -- Step 2: Create the role that will get the permissions for DoiT user
    CREATE ROLE IF NOT EXISTS doit_role
        comment = 'Used by DoiT.com';

    -- Step 3: Create the warehouse to be used for DoiT billing exports. All DoiT incurred charges will be related to this warehouse
    CREATE WAREHOUSE IF NOT EXISTS doit_warehouse
      warehouse_size=xsmall
      auto_suspend=60
      initially_suspended=true
      comment = 'Used by DoiT.com';

    GRANT usage ON WAREHOUSE doit_warehouse TO ROLE doit_role;

    -- Step 4: DoiT user to be used to log in
    CREATE USER IF NOT EXISTS doit_user
      password = '${password}'
      default_role = doit_role
      default_warehouse = doit_warehouse
      comment = 'Used by DoiT.com';

    -- Step 5: Grant the needed permissions and assign the role
    GRANT ROLE doit_role TO USER doit_user;

    USE DATABASE SNOWFLAKE;

    -- Use this role for warehouse meter access USAGE_IN_CURRENCY_DAILY https://docs.snowflake.com/en/sql-reference/organization-usage
    GRANT DATABASE ROLE ORGANIZATION_BILLING_VIEWER TO ROLE doit_role;
    -- Use this role for warehouse meter access WAREHOUSE_METERING_HISTORY // https://docs.snowflake.com/en/sql-reference/snowflake-db-roles
    GRANT DATABASE ROLE ORGANIZATION_USAGE_VIEWER TO ROLE doit_role;
    -- Use this role for query history QUERY_HISTORY // https://docs.snowflake.com/en/sql-reference/snowflake-db-roles
    GRANT DATABASE ROLE GOVERNANCE_VIEWER TO ROLE doit_role;
  `;

/*
generates a password that is 12 characters long  -
and includes at least one uppercase letter, one lowercase letter,
one number, and one special character.
* */
export const generatePassword = (isTestCustomer: boolean): string => {
  if (isTestCustomer) {
    // test customer (gigabright.xyz) use a fixed pwd so it will work on dev and prod:
    return "I*b70E*TW!oM";
  }
  const passwordLength = 12;
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialCharacters = "!*";
  const allCharacters = uppercase + lowercase + numbers + specialCharacters;

  let password = "";
  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += lowercase[Math.floor(Math.random() * lowercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

  for (let i = 4; i < passwordLength; i++) {
    password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  // Shuffle the password to ensure the characters are randomly distributed
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
};

export type SnowflakeOnboardingRequest = {
  generatedPassword: string;
  accountId: string;
  orgId: string;
  onboardedBy: string;
};

export const getCustomSnowflakeErrorMessage = (errorMessage: string, timeout: boolean) => {
  errorMessage = String(errorMessage);
  const tables = [
    "QUERY_HISTORY",
    "STAGE_STORAGE_USAGE_HISTORY",
    "DATABASE_STORAGE_USAGE_HISTORY",
    "STORAGE_DAILY_HISTORY",
    "USAGE_IN_CURRENCY_DAILY",
    "WAREHOUSE_METERING_HISTORY",
    "RATE_SHEET_DAILY",
  ];
  const failedTables = tables.filter((table) => errorMessage.includes(table)).join(", ");

  if (timeout || errorMessage.includes("timeout")) {
    return "Onboarding error - timeout occurred. Are you sure you've entered the correct account and organization IDs?";
  }
  if (failedTables) {
    return `Onboarding failure - no access to the following tables: ${failedTables}.
    Please note that Snowflake accounts purchased through resellers do not have access to these tables, and are not supported.`;
  }
  // if the string contains the word "username" or "password" it's likely a login error
  if (["username", "password"].some((keyword) => errorMessage.includes(keyword))) {
    return "Incorrect username or password - Please contact support.";
  }

  if (["entitlement", "tier"].some((keyword) => errorMessage.includes(keyword))) {
    return "Incorrect DoiT Navigator tier - Please contact support to upgrade.";
  }

  return "Onboarding error, please contact support.";
};
