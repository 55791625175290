import { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { EditBillingProfileForm } from "../../Components/CreateBillingProfile/BillingForm/EditBillingProfileForm";
import { Loader } from "../../Components/Loader";
import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useGoBackToReferrer } from "./useGoBackToReferrer";

export const EditEntityPage = () => {
  const { entities, customer, init } = useCustomerContext();

  const [entity, setEntity] = useState<Entity>();
  const routerHistory = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const goBackToReferrer = useGoBackToReferrer(`/customers/${customer.id}/billing-profiles`);

  const params = useParams<{ entityId: string }>();

  useEffect(() => {
    // need to wait until customer context is loaded and customer ref in context matches customerId from URL
    // as for a brief moment there may be a mismatch when navigating to a billing profile from search bar
    if (init && customerId === customer.id) {
      const entity = entities.find(({ id }) => id === params.entityId);
      if (entity === undefined) {
        routerHistory.push("/not-found");
      } else {
        setEntity(entity);
      }
    }
  }, [entities, routerHistory, params.entityId, init, customerId, customer.id]);

  return (
    <Loader loading={entity === undefined}>
      {entity ? <EditBillingProfileForm entity={entity} onClose={goBackToReferrer} /> : <></>}
    </Loader>
  );
};
