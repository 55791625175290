import React from "react";

import { Paper } from "@mui/material";

import { convertHexToRGBA } from "../../../utils/color";

type Props = {
  children?: React.ReactNode;
  color: string;
  topBorderColor: string;
};

export const LinkedThreadPaper: React.FC<Props> = ({ color, topBorderColor, children }) => (
  <Paper
    sx={{
      mb: 2,
      backgroundColor: convertHexToRGBA(color, 8),
      borderColor: convertHexToRGBA(color, 1),
      borderTop: `8px solid ${topBorderColor}`,
      position: "relative",
      overflow: "hidden",
    }}
    variant="outlined"
  >
    {children}
  </Paper>
);
