import { TextField } from "@mui/material";

export const CustomInput = (props) => (
  <TextField
    {...props}
    {...(props.maxLength
      ? {
          inputProps: {
            maxLength: props.maxLength,
          },
        }
      : {})}
    size="medium"
  />
);
