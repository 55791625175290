import { useCallback, useEffect, useState } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { type AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";

import { useFullScreen } from "../utils/dialog";
import { getPropsWrapper } from "../utils/muiUtils";

type MultiEmailsInputTextProps = {
  emails: string[];
  validatedEmails: { [key: string]: { email: string; error?: string | false } };
  onEmailsChange: (emails: string[]) => void;
  emailValue: string;
  onEmailValueChange: (email: string) => void;
  invalidEmailsHelperComponent: React.ReactNode;
  autoCompleteComponentDataTestId?: string;
  inputLabel?: string;
  chipsVariant?: "outlined" | "filled";
  chipsColor?: "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning" | undefined;
};

export const chipSeparators = [" ", ",", "\n", "\t"];

export const MultiEmailsInputText = ({
  emails,
  validatedEmails,
  onEmailsChange,
  emailValue,
  onEmailValueChange,
  invalidEmailsHelperComponent,
  autoCompleteComponentDataTestId,
  inputLabel,
  chipsVariant,
  chipsColor,
}: MultiEmailsInputTextProps) => {
  const [emailInputValue, setEmailInputValue] = useState<string[]>(emails);
  const { isMobile } = useFullScreen();

  useEffect(() => {
    if (emailValue.match(new RegExp(chipSeparators.join("|")))) {
      const parsedEmails = emailValue.split(new RegExp(chipSeparators.join("|"))).filter((x) => x !== "");
      setEmailInputValue([...emailInputValue, ...parsedEmails]);
      onEmailsChange([...emailInputValue, ...parsedEmails]);
      onEmailValueChange("");
    }
  }, [emailValue, emailInputValue, onEmailValueChange, onEmailsChange]);

  const invalidEmails = useCallback(
    () =>
      Object.values(validatedEmails)
        .filter((x) => x.email !== emailValue)
        .some((x) => x.error),
    [emailValue, validatedEmails]
  );

  const wrapGetProps: (
    func: AutocompleteRenderGetTagProps,
    index: number
  ) => ReturnType<AutocompleteRenderGetTagProps> = useCallback(getPropsWrapper, []);

  return (
    <Autocomplete
      data-testid={autoCompleteComponentDataTestId}
      data-cy="multi-emails-input-text"
      multiple
      freeSolo
      filterSelectedOptions
      fullWidth
      autoSelect
      size="small"
      limitTags={isMobile ? 1 : -1}
      options={emailInputValue}
      inputValue={emailValue}
      onInputChange={(_, newInputValue) => {
        onEmailValueChange(newInputValue);
      }}
      value={emailInputValue}
      onChange={(_, emailsAfterChange, reason) => {
        switch (reason) {
          case "blur":
          case "createOption": {
            const newEmailsValues = emailsAfterChange.map((x) => x.toLowerCase().trim());
            setEmailInputValue(newEmailsValues);
            onEmailsChange(newEmailsValues);
            break;
          }
          default: {
            setEmailInputValue(emailsAfterChange);
            onEmailsChange(emailsAfterChange);
          }
        }
      }}
      renderTags={(tags, getTagProps) =>
        tags.map((email, index) => (
          <div key={`${email}${index}`} data-testid={email}>
            {!!validatedEmails[email]?.error && (
              <Chip
                variant="outlined"
                size="small"
                icon={<WarningIcon />}
                color="error"
                label={email}
                {...wrapGetProps(getTagProps, index)}
                sx={{
                  "& .MuiChip-label": {
                    whiteSpace: "normal",
                    overflow: "visible",
                  },
                }}
              />
            )}
            {!validatedEmails[email]?.error && (
              <Chip
                variant={chipsVariant ?? "outlined"}
                size="small"
                color={chipsColor ?? "primary"}
                label={email}
                {...wrapGetProps(getTagProps, index)}
                sx={{
                  "& .MuiChip-label": {
                    whiteSpace: "normal",
                    overflow: "visible",
                  },
                }}
              />
            )}
          </div>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          margin="dense"
          variant="outlined"
          placeholder="Add emails"
          label={inputLabel}
          error={invalidEmails()}
          helperText={invalidEmails() && invalidEmailsHelperComponent}
        />
      )}
    />
  );
};
