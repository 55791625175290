import { type JSX } from "react";

import { matchRoutes, renderRoutes, type RouteConfig } from "react-router-config";

import { NoEntitlementsText } from "../../assets/texts";
import { NoAccess } from "../../Components/NoAccess";
import NoEntitlement from "../../Components/NoEntitlement/NoEntitlement";
import { useAuthContext } from "../../Context/AuthContext";
import { useAssetsContext } from "../../Context/customer/AssetContext";
import { useContractsContext } from "../../Context/customer/ContractsContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { type CustomerPageId } from "../../Navigation/config/pages";
import NotFound from "../../Pages/NotFound/NotFound";
import { getAllRoutes } from "../core/routes";
import { type LoginContext } from "../types";
import { useCurrentPageIdInfo, useNavConfig } from "./hooks";

type Props = {
  loginContext?: LoginContext;
  routesConfig?: RouteConfig[];
  children: JSX.Element;
};

export const PageContent = ({ loginContext, routesConfig, children }: Props) => {
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const currentPageInfo = useCurrentPageIdInfo(routesConfig);
  const { assets, assetsLoading } = useAssetsContext();
  const { currentUser } = useAuthContext();
  const { contracts } = useContractsContext();
  const { accessControl } = useNavConfig();
  const { getFeatureName, loading: isTierLoading } = useTier();

  if (!loginContext || !routesConfig) {
    return null;
  }
  const presentationModeActive =
    loginContext.customerState?.inPresentationMode && currentPageInfo?.presentationModeSupported;

  const customTitles: Partial<Record<CustomerPageId, string>> = {
    "analytics:resources": "Upgrade your subscription",
    "integrations:jira": NoEntitlementsText.TITLE(presentationModeActive, "the Jira integration"),
    "integrations:zapier": NoEntitlementsText.TITLE(presentationModeActive, "the Zapier integration"),
  };

  if (currentPageInfo) {
    if (currentPageInfo.isCustomerRoute) {
      if (customer === undefined || customerOrPresentationModeCustomer === undefined || isTierLoading) {
        return null;
      }

      if (currentPageInfo.featureGate && currentPageInfo.hasEntitlement === false && !presentationModeActive) {
        return (
          <NoEntitlement
            feature={getFeatureName(currentPageInfo.featureGate) ?? "this feature"}
            inPresentationMode={loginContext.customerState?.inPresentationMode}
            customTitle={customTitles[currentPageInfo.pageId] || ""}
          />
        );
      }
    }

    return (
      <>
        {renderRoutes(routesConfig, {
          isDoitEmployee: loginContext.isDoitEmployee,
          customerId: loginContext.customerId,
          customer,
          currentUser,
          userRoles: loginContext.permissions,
          assets,
          assetsLoading,
          contracts,
          pageId: currentPageInfo.pageId,
          tabs: null,
          presentationModeActive,
        })}
      </>
    );
  }

  // check if this route controlled by router controller but has no access
  const allRouteConfig = getAllRoutes(accessControl);
  const matchedRoutes = matchRoutes(allRouteConfig, location.pathname);

  if (matchedRoutes.length > 0) {
    if (matchedRoutes[0].route.isCustomerRoute) {
      if (customer === undefined || customerOrPresentationModeCustomer === undefined) {
        return null;
      }

      if (customer === null) {
        return <NotFound />;
      }
    }

    // in case assets are still loading, we need to wait for them to finish to be sure the access is really denied
    return assetsLoading ? null : <NoAccess />;
  }

  return children;
};
