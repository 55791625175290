import { useMemo } from "react";

import { Aggregator } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { reportText } from "../../../../assets/texts";
import { type KeyTypeValues } from "../../../../Pages/CloudAnalytics/api";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { FilterPopover } from "../../dialogs/metadataFilter/FilterContent";
import { Positions, type ReportAddDimension, type ReportItemRemove } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ReportItem from "./ReportItem";
import ReportsAccordion from "./ReportsAccordion";

export type AggregatorCountProps = {
  popoverAnchorEl: any;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  handleAddDimension: ReportAddDimension;
  handleDelete: ReportItemRemove;
  getItemTooltip: (itemDisabled: boolean) => string;
};

const position = Positions.COUNT;

const AggregatorCount = ({
  handleChangeLabels,
  setPopoverAnchorEl,
  popoverAnchorEl,
  handleDelete,
  handleAddDimension,
  getItemTooltip,
}: AggregatorCountProps) => {
  const { reportConfig } = useReportConfig();
  const { dimensions = [] } = useReportDimensionsContext();

  const items = useMemo(
    () =>
      dimensions.filter((option) => {
        if (option._visible && option._position === position) {
          return option;
        }
      }),
    [dimensions]
  );

  if (reportConfig.aggregator !== Aggregator.COUNT) {
    return null;
  }

  const popoverComponent = (
    <FilterPopover
      anchorEl={popoverAnchorEl?.mdCount}
      onClose={() => setPopoverAnchorEl(null)}
      metadata={dimensions}
      excludeSelectMetadataIds={reportConfig.excludeSelectMetadataIds}
      position={position}
      handleAddDimension={handleAddDimension}
      handleChangeLabels={handleChangeLabels}
      isOptionDisabled={undefined}
    />
  );

  return (
    <ReportsAccordion
      defaultExpanded
      title={reportText.COUNT_SECTION_LABEL}
      numOfItems={items.length}
      popoverComponent={popoverComponent}
      popoverAnchorEl={popoverAnchorEl?.mdCount}
      setPopoverAnchorEl={setPopoverAnchorEl}
      position={position}
      disableExpand={true}
      disableAdd={items.length > 0}
    >
      <Box data-cy="aggregator-count">
        {items.map((item) => (
          <ReportItem
            key={item.id}
            item={item}
            id={item.id}
            onItemRemove={handleDelete}
            handleAddDimension={handleAddDimension}
            tooltip={getItemTooltip(item._disabled)}
          />
        ))}
      </Box>
    </ReportsAccordion>
  );
};
export default AggregatorCount;
