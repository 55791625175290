import { type Dispatch, type RefObject, type SetStateAction } from "react";

import { type CurrencyCode, type PaymentMethod } from "@doitintl/cmp-models";
import { MenuItem, Stack, TextField } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { BillCom } from "./BillCom";
import { getPaymentMethodModalButtonText, isPaymentMethodSupported } from "./paymentHelpers";
import { type StripeFromHandle, StripePaymentMethods, StripeSetupSession } from "./Stripe";
import { WireTransfer } from "./WireTransfer";

type Props = {
  currency: CurrencyCode;
  country: string;
  entityId: string;
  settingPaymentMethod: boolean;
  setStripeElementsLoaded: Dispatch<SetStateAction<boolean>>;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  stripeRef: RefObject<StripeFromHandle>;
  stripeReturnUrl: string;
};

const Payment = ({
  currency,
  country,
  entityId,
  settingPaymentMethod,
  setStripeElementsLoaded,
  paymentMethod,
  setPaymentMethod,
  stripeRef,
  stripeReturnUrl,
}: Props) => {
  const { customer } = useCustomerContext();
  return (
    <>
      <Stack spacing={1.4}>
        <TextField
          data-cy="paymentMethod"
          select
          label="Payment method"
          name="paymentMethod"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value as PaymentMethod)}
          variant="outlined"
          size="medium"
          disabled={settingPaymentMethod}
          required
        >
          {isPaymentMethodSupported("wire_transfer", currency) && (
            <MenuItem value="wire_transfer">Wire Transfer</MenuItem>
          )}
          {isPaymentMethodSupported("bill_com", currency) && <MenuItem value="bill_com">Bill.com</MenuItem>}
          {isPaymentMethodSupported("stripe", currency) && (
            <MenuItem value="stripe">{getPaymentMethodModalButtonText(currency)}</MenuItem>
          )}
          {isPaymentMethodSupported("bacs_debit", currency) && <MenuItem value="bacs">Bacs Direct Debit</MenuItem>}
          {isPaymentMethodSupported("acss_debit", currency) && <MenuItem value="acss">ACSS Debit</MenuItem>}
        </TextField>
      </Stack>

      {paymentMethod === "wire_transfer" && <WireTransfer country={country} />}
      {paymentMethod === "bill_com" && <BillCom />}
      {paymentMethod === "stripe" && (
        <StripePaymentMethods
          setLoadSuccess={setStripeElementsLoaded}
          entityId={entityId}
          customerId={customer.id}
          currency={currency}
          returnUrl={stripeReturnUrl}
          ref={stripeRef}
          newEntity={true}
        />
      )}
      {["bacs", "acss"].includes(paymentMethod) && (
        <StripeSetupSession
          entityId={entityId}
          customerId={customer.id}
          successUrl={stripeReturnUrl}
          cancelUrl={stripeReturnUrl}
          newEntity={true}
        />
      )}
    </>
  );
};

export default Payment;
