import { useEffect, useMemo } from "react";

import {
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  type CloudFlowNodeType,
  type GoogleCloudProjectAssetModel,
  type Member,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AnyAsset } from "../../../../types";
import { useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { stripControlledFields } from "../Common/utils";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import { GenericForm } from "./ApiActionParametersForm";
import { useFieldValidationProps } from "./useFieldValidationProps";

const PROJECT_ID_VARIATIONS = ["projectsId", "projectId", "appsId", "project"];
const CONTROLLED_FIELDS = ["organization", ...PROJECT_ID_VARIATIONS];

const GCPParameterForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const { customer, assets, appendLoadedAssetTypes } = useCustomerContext();
  const [gcpCloudConnectData] = useCloudConnectData(customer.id);
  const {
    nodeConfig: {
      parameters: {
        configurationValues: { organizationId, serviceAccount },
      },
    },
    dispatch,
  } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const requiredMembers = inputModel.requiredMembers || [];
  const members = Object.keys(inputModel.members) || [];

  const uncontrolledInputForm = useMemo(() => {
    const input = stripControlledFields(inputModel, CONTROLLED_FIELDS);
    if (Object.keys(input.members).length === 0) {
      return null;
    }
    return input;
  }, [inputModel]);

  const projectIdFieldName = PROJECT_ID_VARIATIONS.find((value) => members.includes(value));
  const projectIdProps = useMemo(
    () => formikProps.getFieldProps(projectIdFieldName || ""),
    [formikProps, projectIdFieldName]
  );
  const { showError, errorMessage } = useFieldValidationProps(formikProps, projectIdProps);

  const projectAssets = useMemo(
    () =>
      Object.values(assets).flatMap((assetGroup) =>
        assetGroup
          .filter(
            (asset): asset is AnyAsset<GoogleCloudProjectAssetModel> => asset.data.type === AssetTypeGoogleCloudProject
          )
          .map((asset) => asset.data.properties.projectId)
      ),
    [assets]
  );

  useEffect(() => {
    appendLoadedAssetTypes([
      AssetTypeGoogleCloudProject,
      AssetTypeGoogleCloudDirect,
      AssetTypeGoogleCloudStandalone,
      AssetTypeGoogleCloudProjectStandalone,
    ]);
  }, [appendLoadedAssetTypes]);

  useEffect(() => {
    const firstOrganization = gcpCloudConnectData.find(
      (gcpCloudConnect) => gcpCloudConnect.data?.organizations && gcpCloudConnect.data.organizations.length > 0
    );
    if (firstOrganization && !organizationId && !serviceAccount) {
      dispatch({
        type: "UPDATE_PARAMETERS_AT_KEY",
        payload: {
          parameterKey: "configurationValues",
          values: {
            organizationId: firstOrganization.data.organizations?.[0].name || "",
            serviceAccount: firstOrganization?.data.clientEmail || "",
          },
        },
      });
    }
  }, [dispatch, gcpCloudConnectData, organizationId, serviceAccount]);

  return (
    <Stack gap={2}>
      <FormControl fullWidth>
        <TextField
          id="organization"
          name="organization"
          variant="outlined"
          select
          label="Organization"
          value={
            organizationId && serviceAccount
              ? JSON.stringify({
                  organizationId,
                  serviceAccount,
                })
              : ""
          }
          onChange={(event) => {
            dispatch({
              type: "UPDATE_PARAMETERS_AT_KEY",
              payload: { parameterKey: "configurationValues", values: JSON.parse(event.target.value) },
            });
          }}
          required
        >
          {gcpCloudConnectData
            .filter((gcpCloudConnect) => gcpCloudConnect.data?.organizations?.length)
            .map((gcpCloudConnect) => (
              <MenuItem
                key={gcpCloudConnect.id}
                value={JSON.stringify({
                  organizationId: gcpCloudConnect.data?.organizations?.[0].name || "",
                  serviceAccount: gcpCloudConnect.data.clientEmail,
                })}
              >
                {gcpCloudConnect.data?.organizations?.[0].displayName}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
      {!!projectIdFieldName && (
        <FormControl fullWidth>
          <VirtualizedAutocomplete
            id={projectIdFieldName}
            options={projectAssets}
            getOptionLabel={(option) => option}
            onChange={(_, value) => {
              formikProps.setFieldValue(projectIdFieldName, value);
            }}
            renderOption={(props, option) => [
              props,
              <MenuItem key={option} {...props} value={option}>
                {option}
              </MenuItem>,
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                label="Project Id"
                name="Project Id"
                onBlur={formikProps.handleBlur(projectIdFieldName)}
                required={requiredMembers.includes(projectIdFieldName)}
                fullWidth
                error={showError}
                helperText={errorMessage}
                variant="outlined"
              />
            )}
            size="small"
            fullWidth
            value={formikProps?.values?.[projectIdFieldName] || null}
          />
        </FormControl>
      )}
      {uncontrolledInputForm && <GenericForm fieldPath="" inputModel={uncontrolledInputForm} label="" />}
    </Stack>
  );
};

export default GCPParameterForm;
