import { type ReactNode, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, DialogContent, Link, Typography } from "@mui/material";

import SimpleDialog from "../Components/SimpleDialog";
import TooltipArrow from "../Components/TooltipArrow/TooltipArrow";
import { useAuthContext } from "../Context/AuthContext";
import { useGetLoginUrl } from "../utils/sauron";

const cloudFormationURI = "https://go.doit.com/aws-ops-cfn";

type SauronProps = {
  accountId: string;
  children?: ReactNode;
  sauronRole: boolean;
};

export default function Sauron({ accountId, children, sauronRole }: SauronProps) {
  const { isDoitEmployee } = useAuthContext();
  const [hasError, setHasError] = useState<boolean>(false);
  const [openSupportAWS, setOpenSupportAWS] = useState<boolean>(false);
  const getLoginUrl = useGetLoginUrl();
  const { t } = useTranslation("services");

  useEffect(() => {
    setHasError(false);
  }, [accountId]);

  const deployRole = () => {
    setHasError(false);
    window.open(cloudFormationURI, "_blank");
  };

  const openAWSTicket = async () => {
    try {
      window.location.href = await getLoginUrl(accountId, false);
    } catch (error) {
      setOpenSupportAWS(false);
      setHasError(true);
    }
  };

  return (
    <Box sx={{ mt: -3 }}>
      <TooltipArrow title={isDoitEmployee ? t("Sauron.helper") : ""}>
        <Link
          disabled={isDoitEmployee}
          color={!isDoitEmployee ? "primary" : "text.disabled"}
          sx={{ textDecoration: "none" }}
          component="button"
          onClick={() => setOpenSupportAWS(true)}
        >
          <Typography variant="body2">
            {t("Sauron.title")}
            {isDoitEmployee && " (disabled)"}
            <OpenInNewIcon sx={{ ml: 0.5, mb: -0.8 }} />
          </Typography>
        </Link>
      </TooltipArrow>
      <SimpleDialog
        open={openSupportAWS}
        dialogProps={{
          fullWidth: true,
        }}
        onConfirm={openAWSTicket}
        title="Open request with AWS"
        onCancel={() => setOpenSupportAWS(false)}
        confirmButtonText="Open AWS console"
        cancelButtonText="Cancel"
        confirmButtonColor="primary"
        disableConfirm={!accountId || !sauronRole}
      >
        <DialogContent>
          <Box>{children}</Box>
          {!sauronRole && (
            <>
              <Button key={1} disabled={hasError} color="primary" onClick={deployRole} variant="contained" size="small">
                <OpenInNewIcon sx={{ mr: 1 }} />
                {t("Sauron.deploy")}
              </Button>
              <Typography sx={{ mt: 1 }} component="div" variant="caption">
                {t("Sauron.deployDescription")}
              </Typography>
            </>
          )}
        </DialogContent>
      </SimpleDialog>
    </Box>
  );
}
