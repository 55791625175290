import { useEffect, useState } from "react";

import { type PerkField, type PerkModule } from "@doitintl/cmp-models";
import { string as YupString } from "yup";

export const usePerkFormValidator = ({
  fields,
  agenda,
  modules,
  logoUrl,
  isDoitCustomer,
}: {
  fields: PerkField;
  agenda: string;
  modules: PerkModule[];
  logoUrl: string;
  isDoitCustomer: boolean;
}) => {
  const [isFormValid, setIsFormValid] = useState(true);
  useEffect(() => {
    switch (true) {
      case (fields.type as any) === "":
        setIsFormValid(false);
        break;
      case fields.name.replace(/\s{2,}/g, " ").trim() === "" ||
        fields?.shortDescription?.replace(/\s{2,}/g, " ").trim() === "":
        setIsFormValid(false);
        break;
      case fields.type === "isv-solution" && (!fields.title || fields?.title?.replace(/\s{2,}/g, " ").trim() === ""):
        setIsFormValid(false);
        break;
      case Boolean(fields.platform.length) &&
        Boolean(fields?.platformUrl) &&
        Boolean(Object.values(fields?.platformUrl ?? {}).find((url) => !YupString().url().isValidSync(url))):
        setIsFormValid(false);
        break;
      case fields.type === "isv-solution" && fields.solutionType?.length === 0:
        setIsFormValid(false);
        break;
      case fields.videoUrl && !YupString().url().isValidSync(fields.videoUrl):
        setIsFormValid(false);
        break;
      case fields.type === "isv-solution" &&
        !isDoitCustomer &&
        (!logoUrl || logoUrl.replace(/\s{2,}/g, " ").trim() === "" || !YupString().url().isValidSync(logoUrl)):
        setIsFormValid(false);
        break;
      default:
        setIsFormValid(true);
    }
  }, [agenda, fields, isDoitCustomer, logoUrl, modules]);

  return [isFormValid];
};
