import { type ReportSchedule as Schedule } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogTitle, Divider } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { globalText } from "../../../../assets/texts";
import LoadingButton from "../../../../Components/LoadingButton";
import { useAuthContext } from "../../../../Context/AuthContext";
import mixpanel from "../../../../utils/mixpanel";

type SubscriptionDialogTypes = {
  open: boolean;
  onClose: () => void;
  onSave: any;
  schedule: Schedule | null | undefined;
  loading: boolean;
  reportId: string;
};

const SubscriptionDialog = ({ schedule, open, onSave, onClose, loading, reportId }: SubscriptionDialogTypes) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isSubscribe = currentUser.email && !schedule?.to?.includes(currentUser.email);
  const subscribePhrase = "be added to";
  const unsubscribePhrase = "unsubscribe from";
  const handleUnsubscribe = () => {
    mixpanel.track("analytics.reports.unsubscribe", { reportId });
    const newSchedule: Schedule | null | undefined = cloneDeep(schedule);
    if (!newSchedule) {
      return;
    }
    newSchedule.to = (schedule?.to ?? []).filter((item) => item !== currentUser.email);
    onSave(newSchedule, true, "Unsubscribe saved successfully");
  };
  const handleSubscribe = () => {
    mixpanel.track("analytics.reports.subscribe", { reportId });
    const newSchedule: Schedule | null | undefined = cloneDeep(schedule);
    if (!newSchedule) {
      return;
    }
    const scheduleTo = schedule?.to ?? [];
    newSchedule.to = currentUser.email ? [...scheduleTo, currentUser.email] : scheduleTo;
    onSave(newSchedule, true, "Subscription saved successfully");
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        {`Would you like to ${
          isSubscribe ? subscribePhrase : unsubscribePhrase
        } the email distribution for this report?`}
      </DialogTitle>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={() => {
            isSubscribe ? handleSubscribe() : handleUnsubscribe();
          }}
          color="primary"
          variant="contained"
          mixpanelEventId={`analytics.subscription-dialog.${
            isSubscribe ? globalText.SUBSCRIBE : globalText.UNSUBSCRIBE
          }`}
        >
          {isSubscribe ? globalText.SUBSCRIBE : globalText.UNSUBSCRIBE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionDialog;
