import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

type Props = {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSave: (note: string) => void;
};
export const IssuedAtDialog = ({ open, loading, handleClose, handleSave }: Props) => {
  const [note, setNote] = useState<string>("");

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Set issued at</DialogTitle>
      <DialogContentText ml={3}>Issued at will be defined as the time when you click save</DialogContentText>
      <DialogContent>
        <TextField
          label="Reason"
          fullWidth
          margin="dense"
          required
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <DialogActions>
          <LoadingButton
            loading={loading}
            onClick={() => handleSave(note)}
            color="primary"
            variant="contained"
            disabled={!note}
          >
            Save
          </LoadingButton>
          <Button variant="text" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
