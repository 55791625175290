import { useMemo } from "react";

import { type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

import { type InAppNotification, type InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";
import { NotificationsListItem } from "./NotificationListItem";

export const NotificationList = ({
  handleClose,
  notifications,
  onlyShowUnread,
  setOnlyShowUnread,
  unreadNotificationsCount,
  markAllAsRead,
  userRef,
  loading,
  handleClickNotification,
}: {
  handleClose: () => void;
  notifications: Map<string, InAppNotificationWithRead>;
  onlyShowUnread: boolean;
  setOnlyShowUnread: (value: boolean) => void;
  unreadNotificationsCount: number;
  markAllAsRead: (userRef: ModelReference<UserModel>) => void;
  userRef: ModelReference<UserModel>;
  loading: boolean;
  handleClickNotification: (n: InAppNotificationWithRead, userRef: ModelReference<UserModel>) => Promise<void>;
}) => {
  const timeStampDesc = (a: InAppNotification, b: InAppNotification) => (a.created > b.created ? -1 : 1);
  const sortedNotifications = useMemo(() => Array.from(notifications.values()).sort(timeStampDesc), [notifications]);
  return (
    <List
      sx={{ width: "30vw", height: "92vh", minWidth: 400, maxWidth: 500, overflow: "auto", padding: 0 }}
      subheader={<li />}
    >
      <ListSubheader disableGutters>
        <ListItem
          secondaryAction={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        >
          <ListItemText
            primary="Notifications"
            primaryTypographyProps={{ sx: { fontWeight: 500, fontSize: "20px", color: "text.primary" } }}
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{ paddingTop: 0, paddingBottom: 0 }}
          secondaryAction={
            <FormControlLabel
              control={
                <Switch
                  disabled={notifications.size === 0}
                  checked={onlyShowUnread}
                  onChange={(e) => setOnlyShowUnread(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2" color={notifications.size !== 0 ? "text.primary" : "text.secondary"}>
                  Only show unread
                </Typography>
              }
            />
          }
        >
          <ListItemText disableTypography>
            <Button
              disabled={unreadNotificationsCount === 0}
              sx={{ padding: 0, fontSize: 14 }}
              onClick={() => markAllAsRead(userRef)}
            >
              Mark all as read
            </Button>
          </ListItemText>
        </ListItem>
        <Divider />
      </ListSubheader>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <CircularProgress />
          <Typography variant="body2" align="center" color="text.primary" sx={{ marginTop: 2 }}>
            Loading notifications
          </Typography>
        </Box>
      ) : notifications.size === 0 ? (
        <Typography variant="body2" align="center" color="text.primary" sx={{ marginTop: 10 }}>
          You don't have any notifications
        </Typography>
      ) : onlyShowUnread && unreadNotificationsCount === 0 ? (
        <Typography variant="body2" align="center" color="text.primary" sx={{ marginTop: 10 }}>
          You don't have any unread notifications
        </Typography>
      ) : (
        sortedNotifications.map((n) =>
          onlyShowUnread && n.read ? null : (
            <NotificationsListItem key={n.id} n={n} markAsRead={() => handleClickNotification(n, userRef)} />
          )
        )
      )}
    </List>
  );
};
