import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Link, Stack, Typography } from "@mui/material";

import { lensEnticementTexts } from "../../../../assets/texts";
import { TiersType } from "../../../../Components/BlankStateUpsell/NoEntitlement";
import { TierAvailability } from "../../../../Components/BlankStateUpsell/TierAvailability";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { LensEnticement } from "../../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../../LensEnticement/types";
import useFetchAmTamEmail from "../../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../../NoEntitlement/NoEntitlement";
import ChartsArea from "./ChartsArea";
import { bQEmptyStateDashboardTexts } from "./texts";

export const BQLensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();
  const { customer } = useCustomerContext({ allowNull: true });

  return (
    <LensEnticement
      illustration={<ChartsArea />}
      title={lensEnticementTexts.BQ_LENS_TITLE}
      listItems={lensEnticementTexts.BQ_LENS_LIST}
    >
      <Stack data-cy="bqEmptyStateDashboard_testimonialTexts" direction="column" spacing={1}>
        <Typography variant="body1">
          "Thanks to the BigQuery Lens' recommendations, we invested time in partitioning our key tables and were able
          to{" "}
          <Box component="span" fontWeight="fontWeightMedium">
            reduce
          </Box>{" "}
          our BigQuery expenses by{" "}
          <Box component="span" fontWeight="fontWeightMedium">
            25% a month
          </Box>
        </Typography>
        <Typography variant="caption" data-cy="bqEmptyStateDashboard_tesimonialQuotee">
          {bQEmptyStateDashboardTexts.QUOTEE}
        </Typography>
      </Stack>
      {state === "activate" && (
        <Stack spacing={2}>
          <Button
            component={RouterLink}
            variant="contained"
            to={`/customers/${customer?.id}/settings/gcp`}
            size="large"
            color="primary"
            aria-label={bQEmptyStateDashboardTexts.ENABLE_BQ_BUTTON}
            data-cy="bqEmptyStateDashboard_enableBQbtn"
            sx={{ width: "fit-content" }}
          >
            {bQEmptyStateDashboardTexts.ENABLE_BQ_BUTTON}
          </Button>
          <Typography variant="body2">
            {bQEmptyStateDashboardTexts.FOOTER_INFO_1}{" "}
            <Link href={bQEmptyStateDashboardTexts.LINK_PATH_TO_BQ_HELP} target="_blank" style={{ cursor: "pointer" }}>
              {bQEmptyStateDashboardTexts.LINK_TXT}
            </Link>{" "}
            {bQEmptyStateDashboardTexts.FOOTER_INFO_2}
          </Typography>
        </Stack>
      )}

      {state === "upgrade" && (
        <Stack
          direction="column"
          spacing={4}
          sx={{
            "&&": {
              mt: 2,
            },
          }}
        >
          <TierAvailability tiers={[{ tierType: TiersType.PREMIUM }, { tierType: TiersType.ENHANCED }]} />
          <ContactSalesButton sendTo={accountManagers} loading={loading} />
        </Stack>
      )}
    </LensEnticement>
  );
};
