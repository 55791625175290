import { useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { type CurrencyCode, type EntityModel, type PaymentMethod } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useApiContext } from "../../api/context";
import { CreateBillingProfileForm } from "../../Components/CreateBillingProfile/BillingForm/CreateBillingProfileForm";
import { PriorityMaintenance } from "../../Components/CreateBillingProfile/BillingForm/PriorityMaintenance";
import { useCreateBillingProfileSubmitFunction } from "../../Components/CreateBillingProfile/useCreateBillingProfileSubmitFunction";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { usePriorityErpStatus } from "../../Context/usePriorityErpStatus";
import Payment from "./PaymentMethods/Payment";
import { handlePaymentMethodUpdate } from "./PaymentMethods/paymentHelpers";
import { type StripeFromHandle } from "./PaymentMethods/Stripe";

interface HistoryLocationState {
  from?: string;
}

const steps = ["Billing profile", "Payment method"];

export const EntityForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [entityId, setEntityId] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState<CurrencyCode>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("wire_transfer");
  const [settingPaymentMethod, setSettingPaymentMethod] = useState(false);
  const [stripeElementsLoaded, setStripeElementsLoaded] = useState(false);

  const stripeRef = useRef<StripeFromHandle>(null);
  const showErrorSnackbar = useErrorSnackbar();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const history = useHistory();
  const priorityErpStatus = usePriorityErpStatus();

  const width = 700;

  const historyLocationState = history.location.state as HistoryLocationState;
  let returnPath = `/customers/${customer.id}`;
  if (historyLocationState?.from) {
    returnPath = historyLocationState.from;
  }
  const stripeReturnUrl = window.location.origin + returnPath;

  const onBillingProfileCreate = (entity: ModelId<EntityModel>) => {
    if (!entity.id || !entity.country || !entity.currency) {
      throw new Error("Entity missing data");
    }
    setEntityId(entity.id);
    setCountry(entity.country);
    setCurrency(entity.currency);
    if (entity.currency === "EGP") {
      setPaymentMethod("stripe");
    }
    setActiveStep(1);
  };

  const handleSetPaymentMethod = async () => {
    setSettingPaymentMethod(true);
    try {
      await handlePaymentMethodUpdate(stripeRef, api, customer, entityId, paymentMethod);
      history.push(returnPath);
    } catch (error) {
      setSettingPaymentMethod(false);
      showErrorSnackbar("Unexpected error occurred");
      throw error;
    } finally {
      setSettingPaymentMethod(false);
    }
  };

  const [isSubmitting, createBillingProfile] = useCreateBillingProfileSubmitFunction(onBillingProfileCreate);

  if (priorityErpStatus.available === false) {
    return <PriorityMaintenance />;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack spacing={4} mt={4} mb={12} sx={{ width }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Typography variant="h1">Create new billing profile</Typography>
            <CreateBillingProfileForm onSubmit={createBillingProfile} />
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}>
              <Divider />
              <Box display="flex" justifyContent="center" px={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1} py={2} sx={{ width }}>
                  <Button
                    color="primary"
                    aria-label="cancel"
                    disabled={isSubmitting}
                    onClick={() => history.push(returnPath)}
                  >
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" form="billingForm" type="submit" loading={isSubmitting}>
                    Create billing profile
                  </LoadingButton>
                </Box>
              </Box>
            </Paper>
          </>
        )}
        {activeStep === 1 && currency && (
          <>
            <Typography variant="h1">Payment method</Typography>
            <Payment
              currency={currency}
              country={country}
              entityId={entityId}
              settingPaymentMethod={settingPaymentMethod}
              setStripeElementsLoaded={setStripeElementsLoaded}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              stripeRef={stripeRef}
              stripeReturnUrl={stripeReturnUrl}
            />
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}>
              <Divider />
              <Box display="flex" justifyContent="center" px={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1} py={2} sx={{ width }}>
                  <Button
                    color="primary"
                    aria-label="cancel"
                    disabled={settingPaymentMethod}
                    onClick={() => history.push(returnPath)}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={handleSetPaymentMethod}
                    loading={settingPaymentMethod}
                    disabled={
                      (paymentMethod === "stripe" && !stripeElementsLoaded) || ["bacs", "acss"].includes(paymentMethod)
                    }
                  >
                    Confirm payment method
                  </LoadingButton>
                </Box>
              </Box>
            </Paper>
          </>
        )}
      </Stack>
    </Box>
  );
};
