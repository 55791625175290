import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Container, Link, Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useIsFirst24Hours } from "../hooks";
import { type ClusterConfig, type ClusterInfo } from "../types";
import { ClusterDialog } from "./ClusterDialog";
import { ClusterRow } from "./ClusterRow";
import { filters, headerColumns } from "./columns";
import { deleteClusterInfo } from "./db";

type Props = {
  clusters: ClusterConfig[];
};

const helpURL = "https://help.doit.com/docs/dashboards/eks-lens";

export const ClusterTable = ({ clusters }: Props) => {
  const history = useHistory();
  const customerId = useCustomerId();

  const first24HoursDeployment = useIsFirst24Hours(clusters);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [clusterDialogOpen, setClusterDialogOpen] = useState(false);
  const [clusterToEdit, setClusterToEdit] = useState<ClusterInfo | undefined>(undefined);
  const [clusterToDelete, setClusterToDelete] = useState<ClusterInfo | undefined>(undefined);

  const handleCreateClusterClicked = () => {
    setClusterToEdit(undefined);
    setClusterDialogOpen(true);
  };

  const handleClusterDeleteClicked = () => {
    if (clusterToDelete) {
      return deleteClusterInfo(customerId, clusterToDelete);
    }
  };

  const RowWrapper = useCallback(({ row }: { row: ClusterConfig }) => {
    const handleEditClusterClicked = (config: ClusterConfig) => {
      setClusterToEdit(config);
      setClusterDialogOpen(true);
    };

    const handleRemoveClusterClicked = (config: ClusterConfig) => {
      setClusterToDelete(config);
      setConfirmDeleteDialogOpen(true);
    };

    return (
      <ClusterRow
        row={row}
        onEditClusterClicked={handleEditClusterClicked}
        onRemoveClusterClicked={handleRemoveClusterClicked}
      />
    );
  }, []);

  return (
    <Container sx={{ pt: 2, pb: 8 }}>
      {clusterDialogOpen && (
        <ClusterDialog value={clusterToEdit} open={clusterDialogOpen} setOpen={setClusterDialogOpen} />
      )}
      {first24HoursDeployment && (
        <Alert severity="info" sx={{ mb: 2 }}>
          It can take up to 24 hours to start seeing data after connecting a cluster
        </Alert>
      )}
      <DeleteDialog
        open={confirmDeleteDialogOpen}
        title="Remove cluster"
        message="This cluster will no longer appear in this list or appear in EKS reports. You can re-add this cluster at a later point if needed. "
        deleteButtonText="Remove cluster"
        onDelete={handleClusterDeleteClicked}
        onClose={() => setConfirmDeleteDialogOpen(false)}
      />

      <FilterTable<ClusterConfig>
        toolbarProps={{
          title: (
            <Stack direction="row" alignItems="center">
              <IconButton aria-label="Back" onClick={history.goBack} size="large">
                <BackIcon color="primary" />
              </IconButton>
              <Typography variant="h1" display="inline">
                EKS clusters
              </Typography>
              <Tooltip title="More Info" style={{ marginLeft: "20px" }}>
                <Link href={helpURL} target="_blank" underline="none">
                  {clusters.length === 0 && <span>Please enable cost allocation tags to view EKS clusters.</span>}
                  <IconButton color="inherit">
                    <HelpOutlineIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </Stack>
          ),
          primaryButton: {
            text: "Add new EC2 cluster",
            onClick: handleCreateClusterClicked,
          },
        }}
        rowComponent={RowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        tableItems={clusters}
        fillEmptySpace={false}
      />
    </Container>
  );
};
