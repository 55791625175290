import { useState } from "react";

import { Button } from "@mui/material";

import { useAwsAccountBatchRequest } from "../../data/useAwsAccountBatchRequest";
import { AwsAccountCreationDialog } from "./AwsAccountCreationDialog";

export const AwsAccountCreationDialogButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const onShow = () => setShowDialog(true);
  const onClose = () => setShowDialog(false);
  const createAwsAccountsBatch = useAwsAccountBatchRequest();

  return (
    <>
      <Button variant="contained" color="primary" onClick={onShow}>
        Create AWS accounts
      </Button>
      {showDialog && <AwsAccountCreationDialog onSubmit={createAwsAccountsBatch} onClose={onClose} />}
    </>
  );
};
