import { useCallback, useEffect, useMemo, useState } from "react";

import { Redirect, useHistory, useParams } from "react-router";
import {
  Aggregator,
  AlertCondition,
  type AlertConfig,
  AnalyticsDataSource,
  type AttributionFilter,
  CloudAnalyticsModelMetricModel,
  type CurrencyCode,
  type IgnoreValuesRange,
  Metadata,
  MetricFilterOperator,
  TimeInterval,
} from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";
import { v4 as uuidv4 } from "uuid";

import { analyticsAlertText } from "../../../assets/texts";
import { useAlert } from "../../../Components/hooks/cloudAnalytics/alerts/useAlert";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { type AnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { useCustomMetrics } from "../../../Components/hooks/cloudAnalytics/useCustomMetrics";
import useBeforeUnload from "../../../Components/hooks/useBeforeUnload";
import useUnmountEffect from "../../../Components/hooks/useUnmountEffect";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepperProps, type StepState } from "../../../Components/Stepper";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { type AttributionWRef, type MetadataOption, type MetricWSnap } from "../../../types";
import { serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { type CloudAnalyticsHistoryState } from "../types";
import { isEditor } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import { useAlertAnalytics } from "./hooks";
import { getCalcMetricFromAlert, getFilteredDimensionFromDimensions } from "./shared";
import { ConditionStep } from "./stepperComponents/ConditionStep";
import { DimensionStep } from "./stepperComponents/DimensionStep";
import { AnalyticsAlertsInfoPanel } from "./stepperComponents/InfoPanel";
import { NotificationsStep } from "./stepperComponents/NotificationsStep";
import { ScopeStep } from "./stepperComponents/ScopeStep";
import { type AnalyticsAlertWithRef } from "./types";

export type Props = {
  alert: AnalyticsAlertWithRef;
  attributions: AttributionWRef[];
  hasDataHub: boolean;
  metadataSnapshots: AnalyticsMetadata;
};

export const defaultLowerBound = -1;
export const defaultUpperBound = 1;

export type IgnoreValuesRangeForStepper = {
  lowerBound: string;
  upperBound: string;
};

const stringifyBounds = ({ lowerBound, upperBound }: IgnoreValuesRange) => ({
  lowerBound: `${lowerBound}`,
  upperBound: `${upperBound}`,
});

export const AnalyticsAlertStepperComponent = ({ alert, attributions, hasDataHub, metadataSnapshots }: Props) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { filteredExtendedMetrics: extendedMetrics, datahubMetrics } = useAlertAnalytics();
  const [metrics] = useCustomMetrics();

  // routing
  const history = useHistory<CloudAnalyticsHistoryState>();
  const { customer } = useCustomerContext();
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const alertsUrl = `/customers/${customer.id}/analytics/alerts`;
  const prevPageUrl = history.location?.state?.prevPage ?? alertsUrl;

  // stepper
  const isCurrentUserEditor = isEditor(currentUser.email, alert.data);
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [currentStep, getCurrentStep] = useState<number>(0);
  // stateX is the state of the X step in the alert stepper
  const [state0, setState0] = useState<StepState>("editing");
  const [state1, setState1] = useState<StepState>("incomplete");
  const [state2, setState2] = useState<StepState>("incomplete");
  const [editMode, setEditMode] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();

  // exit stepper
  const [alertEdited, setAlertEdited] = useState<boolean>(false);

  // step 0
  const [dataSource, setDataSource] = useState<AnalyticsDataSource>(
    alert.data.config.dataSource ?? AnalyticsDataSource.BILLING
  );

  const { dimensions, getLabelsDimensions } = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
    defaultDataSource: dataSource,
  });

  const [alertName, setAlertName] = useState<string>(alert.data.name);
  const [newAttributionDialogOpen, setNewAttributionDialogOpen] = useState(false);
  const [newAttributionId, setNewAttributionId] = useState<string>("");
  const [scope, setScope] = useState<AttributionWRef[]>(
    alert.data.config.scope ? (getAttributionScope(alert.data.config.scope, attributions) ?? []) : []
  );
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<AttributionFilter[]>(alert.data.config.filters ?? []);
  const [dimension, setDimension] = useState<MetadataOption | null>(null);

  // step 1
  const [metric, setMetric] = useState<number>(alert.data.config.metric || 0);
  const [calculatedMetric, setCalculatedMetric] = useState<MetricWSnap | null>(
    getCalcMetricFromAlert(alert.data.config.calculatedMetric, metrics)
  );
  const [extendedMetric, setExtendedMetric] = useState<string | null>(alert.data.config.extendedMetric);
  const [currency, setCurrency] = useState<CurrencyCode>(alert.data.config.currency);
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(alert.data.config.timeInterval || TimeInterval.MONTH);
  const [condition, setCondition] = useState<AlertCondition>(alert.data.config.condition || AlertCondition.VALUE);
  const [operator, setOperator] = useState<MetricFilterOperator>(
    alert.data.config.operator || MetricFilterOperator.GREATER_THAN
  );
  const [alertValue, setAlertValue] = useState<number | "">(alert.data.config.values[0] ?? "");
  const [evaluateByDimension, setEvaluateByDimension] = useState<MetadataOption | null>(null);

  // step 2
  const [recipients, setRecipients] = useState<string[]>(alert.data.recipients ?? []);
  const [usingIgnoreValues, setUsingIgnoreValues] = useState<boolean>(true);
  const [ignoreValuesRange, setIgnoreValuesRange] = useState<IgnoreValuesRangeForStepper>(
    stringifyBounds(
      alert.data.config.ignoreValuesRange ?? { lowerBound: defaultLowerBound, upperBound: defaultUpperBound }
    )
  );

  const handleExitTab = async () => {
    if (!alert.data.isValid) {
      try {
        await alert.ref.delete();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Alert was deleted before");
      }
    }
  };

  useUnmountEffect(handleExitTab);
  useBeforeUnload(handleExitTab);

  const filteredDimensions = useMemo(() => dimensions?.filter((d) => d.data.type !== Metadata.DATETIME), [dimensions]);

  useEffect(() => {
    setDimension(getFilteredDimensionFromDimensions(dimensions ?? [], alert.data.config.filters?.at(0)));
  }, [dimensions, setDimension, alert.data.config.filters]);

  useEffect(() => {
    if (alert.data.isValid) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [alert.data.isValid]);

  useEffect(() => {
    if (newAttributionId !== "") {
      const attribution = attributions.find((a) => a.ref.id === newAttributionId);
      if (attribution) {
        setScope([...scope, attribution]);
        setNewAttributionId("");
      }
    }
  }, [attributions, newAttributionId, scope]);

  useEffect(() => {
    if (overrideStep !== -1) {
      setOverrideStep(-1);
    }
  }, [overrideStep]);

  useEffect(() => {
    if (alert.data.config.rows.length > 0) {
      setEvaluateByDimension(dimensions?.find((d) => d.id === alert.data.config.rows[0]) ?? null);
    }
  }, [alert.data.config.rows, dimensions]);

  useEffect(() => {
    if (alertName.length > 0) {
      setState0("complete");
    } else {
      setState0("incomplete");
    }
  }, [alertName.length]);

  useEffect(() => {
    if (
      (alertValue === "" && condition !== AlertCondition.PERCENTAGE) ||
      (condition === AlertCondition.PERCENTAGE &&
        (alertValue === "" ||
          isNaN(parseFloat(ignoreValuesRange.upperBound)) ||
          isNaN(parseFloat(ignoreValuesRange.lowerBound))))
    ) {
      setState1("editing");
    } else if (currentStep === 0) {
      setState1("incomplete");
    } else {
      setState1("complete");
    }
  }, [alertValue, condition, currentStep, ignoreValuesRange.lowerBound, ignoreValuesRange.upperBound]);

  useEffect(() => {
    if (currentStep === 2 && recipients.length > 0 && isCurrentUserEditor) {
      setState2("complete");
    } else {
      setState2("editing");
    }
  }, [recipients.length, currentStep, isCurrentUserEditor]);

  const onCancel = () => {
    history.push(prevPageUrl);
  };

  const onSave = async () => {
    if (alertValue === "") {
      return;
    }

    const values: number[] = [Number(alertValue)];
    let rows: string[] = [];
    if (condition !== AlertCondition.FORECAST && evaluateByDimension?.id) {
      rows = [evaluateByDimension.id];
    }

    const calcMetric = calculatedMetric
      ? getCollection(CloudAnalyticsModelMetricModel).doc(calculatedMetric?.snapshot.id)
      : null;

    const config: WithFirebaseModel<AlertConfig> = {
      aggregator: Aggregator.TOTAL,
      calculatedMetric: calcMetric,
      condition,
      currency,
      dataSource,
      extendedMetric,
      metric,
      operator,
      ignoreValuesRange:
        condition === AlertCondition.PERCENTAGE && usingIgnoreValues
          ? {
              lowerBound: Number(ignoreValuesRange.lowerBound),
              upperBound: Number(ignoreValuesRange.upperBound),
            }
          : null,
      rows,
      scope: scope.map((s) => s.ref),
      timeInterval,
      values,
      filters: filters.length > 0 ? filters : [],
    };

    const alertData = {
      config,
      etag: uuidv4(),
      isValid: true,
      name: alertName,
      recipients,
      timeModified: serverTimestamp(),
    };
    await alert.ref.update(alertData);
    clearPendingPrompt();
    mixpanel.track("analytics.alerts.save", { alertId: alert.ref.id });
    successSnackbar(analyticsAlertText.STEPPER.ALERT_SAVED_SUCCESSFULLY);
    history.push(prevPageUrl);
  };

  const onConfirmCallback = useCallback(async () => {
    if (!editMode && !alert.data.isValid) {
      await alert.ref.delete();
    }
    history.push(prevPageUrl);
  }, [alert.data.isValid, alert.ref, editMode, history, prevPageUrl]);

  useEffect(() => {
    if (alertEdited) {
      activatePendingPrompt({
        onConfirmCallback,
      });
    } else {
      clearPendingPrompt();
    }
  }, [activatePendingPrompt, alertEdited, clearPendingPrompt, onConfirmCallback]);

  const infoPanel = (
    <AnalyticsAlertsInfoPanel
      alertName={alertName}
      alertValue={alertValue}
      calculatedMetric={calculatedMetric}
      condition={condition}
      currency={currency}
      currentStep={currentStep}
      dimensionOptions={filteredDimensions ?? []}
      extendedMetricOptions={extendedMetrics}
      metric={metric}
      operator={operator}
      scope={scope}
      evaluateByDimension={evaluateByDimension}
      selectedExtendedMetric={extendedMetric}
      setOverrideStep={setOverrideStep}
      timeInterval={timeInterval}
      filter={filters.length > 0 ? filters[0] : null}
    />
  );

  const scopeStep = (
    <ScopeStep
      alertName={alertName}
      attributions={attributions}
      isCurrentUserEditor={isCurrentUserEditor}
      newAttributionDialogOpen={newAttributionDialogOpen}
      scope={scope}
      setAlertEdited={setAlertEdited}
      setAlertName={setAlertName}
      setNewAttributionDialogOpen={setNewAttributionDialogOpen}
      setNewAttributionId={setNewAttributionId}
      setScope={setScope}
    />
  );

  const dimensionStep = (
    <DimensionStep
      alertName={alertName}
      dataSource={dataSource}
      dimension={dimension}
      dimensions={filteredDimensions ?? []}
      filterDialogOpen={filterDialogOpen}
      filters={filters}
      hasDataHub={hasDataHub}
      isCurrentUserEditor={isCurrentUserEditor}
      setAlertEdited={setAlertEdited}
      setAlertName={setAlertName}
      setDataSource={setDataSource}
      setDimension={setDimension}
      setFilterDialogOpen={setFilterDialogOpen}
      setFilters={setFilters}
      getLabelsDimensions={getLabelsDimensions}
    />
  );

  const conditionStep = (
    <ConditionStep
      alertValue={alertValue}
      calculatedMetric={calculatedMetric}
      calculatedMetrics={metrics}
      condition={condition}
      currency={currency}
      evaluateByDimension={evaluateByDimension}
      datahubMetrics={datahubMetrics}
      dataSource={dataSource}
      dimensions={filteredDimensions ?? []}
      extendedMetric={extendedMetric}
      extendedMetrics={extendedMetrics}
      infoPanel={infoPanel}
      isCurrentUserEditor={isCurrentUserEditor}
      metric={metric}
      operator={operator}
      setAlertEdited={setAlertEdited}
      setAlertValue={setAlertValue}
      setCalculatedMetric={setCalculatedMetric}
      setCondition={setCondition}
      setCurrency={setCurrency}
      setEvaluateByDimension={setEvaluateByDimension}
      setExtendedMetric={setExtendedMetric}
      setMetric={setMetric}
      setOperator={setOperator}
      setTimeInterval={setTimeInterval}
      timeInterval={timeInterval}
      ignoreValuesRange={ignoreValuesRange}
      setIgnoreValuesRange={setIgnoreValuesRange}
      usingIgnoreValues={usingIgnoreValues}
      setUsingIgnoreValues={setUsingIgnoreValues}
    />
  );

  const notificationsStep = (
    <NotificationsStep
      infoPanel={infoPanel}
      isCurrentUserEditor={isCurrentUserEditor}
      recipients={recipients}
      setAlertEdited={setAlertEdited}
      setRecipients={setRecipients}
    />
  );

  const firstStep =
    !(editMode && alert.data.config.scope?.length) || alert.data.config.filters?.length ? dimensionStep : scopeStep;

  const steps: Step[] = [
    {
      label: analyticsAlertText.STEPPER.ALERT_SCOPE,
      order: 1,
      required: true,
      state: state0,
      children: [firstStep],
    },
    {
      label: analyticsAlertText.STEPPER.Conditions,
      order: 1,
      required: true,
      state: state1,
      children: [conditionStep],
    },
    {
      label: analyticsAlertText.STEPPER.Notifications,
      order: 1,
      required: true,
      state: state2,
      children: [notificationsStep],
    },
  ];

  const stepperProps: StepperProps = {
    steps,
    backButtonLabel: editMode ? analyticsAlertText.EDIT_ALERT : analyticsAlertText.NEW_ALERT,
    onCancel,
    onSubmit: onSave,
    disableSubmit: false,
    loading: false,
    overrideStep,
    getCurrentStep,
    maxWidth: 740,
    footerMaxWidth: 1080,
  };

  return <Stepper {...stepperProps} />;
};

export const AnalyticsAlertStepper = ({ assetsLoading }) => {
  const params: any = useParams();

  const { alertId, customerId: customerIdUrlParam } = params;
  const { getFeatureKey } = useTier();

  const [alert, alertLoading] = useAlert(alertId);
  const { metadata: metadataSnapshots, hasDataHub, fetchMetadata, hasEKSData } = useCloudAnalyticsContext();
  const { filteredAttributions: filteredAttributionsByTier, attributionsLoading } = useAttributionsContext();
  const { customer } = useCustomerContext();

  const attributions = useMemo(
    () =>
      filteredAttributionsByTier.filter((attr) => {
        const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
        const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
        const isEKSAttribution = entitlementsKeys.includes("pdi:eks");
        return (hasEKSData && isEKSAttribution) || !isEntitlementAttribution;
      }),
    [filteredAttributionsByTier, getFeatureKey, hasEKSData]
  );

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  if (assetsLoading || attributionsLoading || alertLoading || customerIdUrlParam !== customer.id)
    return <CircularProgressLoader />;

  if (alert) {
    return (
      <AnalyticsAlertStepperComponent
        hasDataHub={hasDataHub}
        alert={alert}
        attributions={attributions}
        metadataSnapshots={metadataSnapshots}
      />
    );
  }

  return <Redirect to="." />;
};
