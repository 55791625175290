import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { FlexsaveTypes, type StandaloneOnboardingAWS } from "@doitintl/cmp-models";
import { CircularProgress, Container } from "@mui/material";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { useAuthContext } from "../../../../Context/AuthContext";
import mixpanel from "../../../../utils/mixpanel";
import { UpdateSalesforceAfterActivation } from "../../../../utils/salesforce/updateSalesforceAfterActivation";
import { UpdateSalesforceAfterEstimation } from "../../../../utils/salesforce/updateSalesforceAfterEstimation";
import { formatAsDollars } from "../../utils/costUtils";
import { CreateNewBillingProfile } from "../Common/BillingProfile/CreateNewBillingProfile";
import { AWSContractVersion } from "../Common/Contract";
import { ContractDialog } from "../Common/ContractDialog";
import { EditBillingProfile } from "../Common/EditBillingProfile/EditBillingProfile";
import { NoSavings } from "../Common/NoSavings";
import { OnboardingSuccess } from "../Common/OnboardingSuccess";
import { OnboardingTasks } from "../Common/OnboardingTasks/OnboardingTasks";
import { useBillingProfileName, useHasManuallySignedContract, useStandaloneAPI } from "../hooks";
import { OnboardingStep, type SavingsReport } from "../types";
import { calculateSavings } from "../utils/calculateSavings";
import { saveSelectedBillingProfile } from "./db";
import DeleteEstimation from "./DeleteEstimation";
import { BillingPermissions } from "./Steps/BillingPermissions";
import { EstimationPermissions } from "./Steps/EstimationPermissions";
import { ShowEstimations } from "./Steps/ShowEstimations";

type Props = {
  children: JSX.Element;
  currentAccount?: StandaloneOnboardingAWS;
  unsetCurrentAccount: () => void;
  onBackButtonClick?: () => void;
};

export const SingleAccountOnboarding = ({
  children,
  currentAccount,
  onBackButtonClick,
  unsetCurrentAccount,
}: Props) => {
  const customerId = useCustomerId();
  const { isDoitEmployee } = useAuthContext();

  const hasManuallySigned = useHasManuallySignedContract(customerId, FlexsaveTypes.AWS);
  const standaloneAPI = useStandaloneAPI(FlexsaveTypes.AWS);
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.WELCOME);

  const [openContract, setOpenContract] = useState<boolean>(false);

  const { currentUser } = useAuthContext();
  const [billingProfileName, loadingBillingProfileName] = useBillingProfileName(
    currentAccount?.selectedPriorityId,
    customerId
  );

  const hasEmptySavings = useMemo(
    () => currentAccount?.errors?.savings?.type === "empty_savings",
    [currentAccount?.errors?.savings?.type]
  );

  useMountEffect(() => {
    mixpanel.track("flexsaveSA-aws.emptyview");
  });

  useEffect(() => {
    if (currentAccount?.completed) {
      setCurrentStep(OnboardingStep.SUCCESS);
    }
  }, [currentAccount?.completed]);

  const [savings, computeSpend] = useMemo(
    () => (currentAccount?.savings ? calculateSavings(currentAccount.savings) : []),
    [currentAccount]
  );

  useEffect(() => {
    if (currentStep === OnboardingStep.SHOW_SAVINGS) {
      standaloneAPI.refreshEstimations();
    }
  }, [currentStep, standaloneAPI]);

  const goToWelcomePage = useCallback(() => setCurrentStep(OnboardingStep.WELCOME), []);

  const handleActivateFlexsave = useCallback(() => {
    mixpanel.track("flexsaveSA.checklistactivate", { type: "aws" });
    if (currentAccount?.agreedToContract || hasManuallySigned) {
      setCurrentStep(OnboardingStep.GIVE_BILLING_PERMISSIONS);
    } else {
      mixpanel.track("flexsaveSA.accesscontractseen", { type: "aws" });
      setOpenContract(true);
    }
  }, [currentAccount?.agreedToContract, hasManuallySigned]);

  const handleAgreeContract = useCallback(() => {
    mixpanel.track("flexsaveSA.accesscontractsigned", { type: "aws" });
    standaloneAPI.agreeContract(currentUser?.email || "", {
      accountId: currentAccount?.accountId,
      contractVersion: AWSContractVersion,
    });
    setOpenContract(false);
    setCurrentStep(OnboardingStep.GIVE_BILLING_PERMISSIONS);
  }, [currentUser?.email, currentAccount?.accountId, standaloneAPI]);

  const onGetEstimate = () => {
    mixpanel.track(`flexsaveSA.checklistestimate`, { type: "aws" });
    setCurrentStep(OnboardingStep.GIVE_ESTIMATION_PERMISSIONS);
  };

  const onProvideDetails = () => {
    mixpanel.track(`flexsaveSA.checklistbilling`, { type: "aws" });
    setCurrentStep(OnboardingStep.CREATE_BILLING_PROFILE);
  };

  const onViewEstimate = () => {
    mixpanel.track(`flexsaveSA.viewestimate`, { type: "aws" });
    setCurrentStep(OnboardingStep.SHOW_SAVINGS);
  };

  const onViewPaymentDetails = () => {
    mixpanel.track(`flexsaveSA.viewbillingprofile`, { type: "aws" });
    setCurrentStep(OnboardingStep.EDIT_BILLING_PROFILE);
  };

  const onViewMyEstimate = useCallback(() => {
    mixpanel.track("flexsaveSA.getestimate", { type: "aws" });
    if (hasEmptySavings) {
      setCurrentStep(OnboardingStep.SHOW_NO_SAVINGS);
    } else {
      setCurrentStep(OnboardingStep.SHOW_SAVINGS);
    }
  }, [hasEmptySavings]);

  const handleBillingProfileUpdate = useCallback(
    async (priorityId) => {
      if (currentAccount?.accountId) {
        return saveSelectedBillingProfile(customerId, currentAccount?.accountId, priorityId);
      }
    },
    [customerId, currentAccount?.accountId]
  );

  const handleSavingsReportDownload = useCallback(() => {
    if (savings && computeSpend) {
      const savingsReport: SavingsReport = {
        annualSavings: formatAsDollars(savings.annualSavings),
        annualSavingsWithGrowth: formatAsDollars(savings.annualSavingsWithGrowth),
        lastMonthComputeSpend: formatAsDollars(computeSpend.lastMonthComputeSpend),
        lastMonthWithFlexsave: formatAsDollars(computeSpend.lastMonthWithFlexsave),
      };
      return standaloneAPI.downloadSavingsReport(FlexsaveTypes.AWS, savingsReport);
    }
    return Promise.resolve(false);
  }, [computeSpend, savings, standaloneAPI]);

  const headingAction = useMemo(() => {
    if (!isDoitEmployee || !currentAccount?.accountId) {
      return undefined;
    }
    return <DeleteEstimation accountId={currentAccount?.accountId} onSuccess={unsetCurrentAccount} />;
  }, [currentAccount?.accountId, isDoitEmployee, unsetCurrentAccount]);

  const currentPage = useMemo(() => {
    if (!loadingBillingProfileName) {
      switch (currentStep) {
        case OnboardingStep.WELCOME:
          return (
            <OnboardingTasks
              headingAction={headingAction}
              type={FlexsaveTypes.AWS}
              savings={savings}
              errors={currentAccount?.errors}
              emptySavings={hasEmptySavings}
              billingProfileName={billingProfileName}
              handleGetEstimate={onGetEstimate}
              handleProvideDetails={onProvideDetails}
              handleViewEstimate={onViewEstimate}
              handleViewPaymentDetails={onViewPaymentDetails}
              handleActivateFlexsave={handleActivateFlexsave}
              onBackButtonClick={onBackButtonClick}
            >
              {children}
            </OnboardingTasks>
          );
        case OnboardingStep.GIVE_ESTIMATION_PERMISSIONS:
          return (
            <EstimationPermissions
              error={currentAccount?.errors?.savings?.type}
              allowToContinue={Boolean(savings) || hasEmptySavings}
              handleBack={goToWelcomePage}
              handleDone={onViewMyEstimate}
            />
          );
        case OnboardingStep.SHOW_SAVINGS:
          return (
            <>
              {computeSpend && savings && (
                <>
                  <UpdateSalesforceAfterEstimation platform={FlexsaveTypes.AWS} savings={savings} />
                  <ShowEstimations
                    savings={savings}
                    computeSpend={computeSpend}
                    handleDone={goToWelcomePage}
                    handleSavingsReportDownload={handleSavingsReportDownload}
                  />
                </>
              )}
            </>
          );

        case OnboardingStep.SHOW_NO_SAVINGS:
          return <NoSavings handleDone={goToWelcomePage} />;
        case OnboardingStep.CREATE_BILLING_PROFILE:
          return (
            <CreateNewBillingProfile
              handleBack={goToWelcomePage}
              handleDone={goToWelcomePage}
              handleUpdate={handleBillingProfileUpdate}
            />
          );
        case OnboardingStep.EDIT_BILLING_PROFILE:
          return (
            <EditBillingProfile
              handleBack={goToWelcomePage}
              handleDone={goToWelcomePage}
              savedPriorityId={currentAccount?.selectedPriorityId}
              cloudType={FlexsaveTypes.AWS}
              handleUpdate={handleBillingProfileUpdate}
            />
          );
        case OnboardingStep.GIVE_BILLING_PERMISSIONS:
          return (
            <BillingPermissions
              error={currentAccount?.errors?.activation?.type}
              allowToContinue={Boolean(currentAccount?.completed)}
              isMissingAWSRole={Boolean(currentAccount?.isMissingAWSRole)}
              handleBack={goToWelcomePage}
              handleDone={() => setCurrentStep(OnboardingStep.SUCCESS)}
            />
          );
        case OnboardingStep.SUCCESS:
          return (
            <>
              <UpdateSalesforceAfterActivation />
              <OnboardingSuccess type={FlexsaveTypes.AWS} />
            </>
          );
      }
    }
    return <CircularProgress />;
  }, [
    loadingBillingProfileName,
    currentStep,
    headingAction,
    savings,
    currentAccount?.errors,
    currentAccount?.selectedPriorityId,
    currentAccount?.completed,
    currentAccount?.isMissingAWSRole,
    hasEmptySavings,
    billingProfileName,
    handleActivateFlexsave,
    onBackButtonClick,
    children,
    goToWelcomePage,
    onViewMyEstimate,
    computeSpend,
    handleSavingsReportDownload,
    handleBillingProfileUpdate,
  ]);

  return (
    <Container
      maxWidth="xl"
      sx={{ pt: { xs: 3, sm: 8 }, alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <ContractDialog
        open={openContract}
        type={FlexsaveTypes.AWS}
        handleClose={() => setOpenContract(false)}
        handleConfirm={handleAgreeContract}
      />
      {currentPage}
    </Container>
  );
};
