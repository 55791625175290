import { AnalyticsResourceType, Roles } from "@doitintl/cmp-models";

import { type AttributionWRef, type Budget, type MetricWSnap, type ReportWSnap } from "../../../types";
import { type AnalyticsAlertWithRef } from "../alerts";
import { type AttributionGroupWithRef } from "../attributionGroups/types";
import {
  type AnalyticsResourcesAlerts,
  type AnalyticsResourcesAttributionGroups,
  type AnalyticsResourcesAttributions,
  type AnalyticsResourcesBudgets,
  type AnalyticsResourcesMetrics,
  type AnalyticsResourcesReports,
} from "./types";

export const cloudAnalyticsResourceTypes = {
  alert: { label: "Alert", urlMask: "/customers/:customerId/analytics/alerts/:id?", type: "alert" } as const,
  attribution_group: {
    label: "Attribution Group",
    urlMask: "/customers/:customerId/analytics/attribution-groups/:id?",
    type: "attribution_group",
  } as const,
  budget: { label: "Budget", urlMask: "/customers/:customerId/analytics/budgets/:id?", type: "budget" } as const,
  attribution: {
    label: "Attribution",
    urlMask: "/customers/:customerId/analytics/attributions/:id?",
    type: "attribution",
  } as const,
  report: { label: "Report", urlMask: "/customers/:customerId/analytics/reports/:id?", type: "report" } as const,
  metric: { label: "Metric", urlMask: "/customers/:customerId/analytics/metrics/:id?", type: "metric" } as const,
} as const;

export const reportsToAnalyticsResources = (reports: ReportWSnap[]) =>
  reports.map(
    (r) =>
      ({
        contentType: cloudAnalyticsResourceTypes.report.type,
        type: r.data.type as AnalyticsResourceType,
        owner: r.data.owner,
        id: r.snapshot.id,
        data: r.data,
        ref: r.snapshot.ref,
        snapshot: r.snapshot,
      }) satisfies AnalyticsResourcesReports
  );

export const attributionsToAnalyticsResources = (attributions: AttributionWRef[]) =>
  attributions.map(
    (r) =>
      ({
        data: r.data,
        contentType: cloudAnalyticsResourceTypes.attribution.type,
        type: r.data.type as AnalyticsResourceType,
        owner: r.data.collaborators?.find((c) => c.role === Roles.OWNER)?.email,
        id: r.ref.id,
        ref: r.ref,
      }) satisfies AnalyticsResourcesAttributions
  );

export const attributionGroupsToAnalyticsResources = (groups: AttributionGroupWithRef[]) =>
  groups.map(
    (r) =>
      ({
        contentType: cloudAnalyticsResourceTypes.attribution_group.type,
        type: r.data.type,
        owner: r.transform.owner,
        id: r.ref.id,
        data: r.data,
        ref: r.ref,
      }) satisfies AnalyticsResourcesAttributionGroups
  );

export const metricsToAnalyticsResources = (metrics: MetricWSnap[]) =>
  metrics.map(
    (r) =>
      ({
        contentType: cloudAnalyticsResourceTypes.metric.type,
        type: r.data.type as AnalyticsResourceType,
        owner: r.data.owner,
        id: r.snapshot.id,
        data: r.data,
        ref: r.snapshot.ref,
      }) satisfies AnalyticsResourcesMetrics
  );

export const alertsToAnalyticsResources = (alerts: AnalyticsAlertWithRef[]) =>
  alerts
    .filter((a) => a.data.isValid)
    .map(
      (r) =>
        ({
          contentType: cloudAnalyticsResourceTypes.alert.type,
          type: AnalyticsResourceType.CUSTOM,
          owner: r.transform.owner,
          id: r.ref.id,
          data: r.data,
          ref: r.ref,
        }) satisfies AnalyticsResourcesAlerts
    );

export const budgetsToAnalyticsResources = (budgets: Budget[]) =>
  budgets.map(
    (r) =>
      ({
        contentType: cloudAnalyticsResourceTypes.budget.type,
        type: AnalyticsResourceType.CUSTOM,
        owner: r.data.owner,
        id: r.snapshot.id,
        data: r.data,
        ref: r.ref,
      }) satisfies AnalyticsResourcesBudgets
  );
