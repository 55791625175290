import { useEffect, useMemo, useRef, useState } from "react";

import { type CommitmentContract, DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData, type WithFirebaseModel } from "@doitintl/models-firestore";
import { alpha, Chip, Tooltip, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { grey, lightBlue, orange, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";

import { assetTypeName, formatNumber } from "../../utils/common";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemWithWidgetIdProps } from "./types";

const getStackNameByIndex = (stackName, barIndex) => {
  const formattedStackName = stackName.startsWith("Rollover from period") ? "Rollover from period" : stackName;

  switch (formattedStackName) {
    case "Shortfall":
    case "Remaining Commitment":
      return "Remaining Commitment";
    case "Rollover from period":
      return `Rollover from period ${barIndex - 1}`;
    default:
      return formattedStackName;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardHeaderAction: {
    marginTop: 0,
  },
  cardContent: {
    paddingBottom: 0,
    height: 201,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  chip: {
    backgroundColor: () => theme.palette.grey[300],
    color: () => theme.palette.getContrastText(theme.palette.grey[300]),
  },
  hintContent: {
    backgroundColor: alpha(theme.palette.grey[700], 0.9),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0.5, 1),
  },
  hintTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
  },
  hintValue: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(11),
  },
}));

type Commitment = {
  title: string;
  description: string;
  data: WithFirebaseModel<CommitmentContract>;
};

type Graph = {
  index: number;
  total: number;
  y: number;
  x: number;
  hint: number;
  color: string;
  title: string;
  period: string;
};

const CommitmentContractsCard = ({
  fallbackComponent,
  isCustomizeMode,
  widgetId,
  widgetHeight = 1,
}: WidgetItemWithWidgetIdProps) => {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const gridRef = useRef<HTMLInputElement>(null);
  const [commitment, setCommitment] = useState<Commitment | null>();
  const color = useMemo(
    () => (commitment?.data?.type === "google-cloud" ? lightBlue : orange),
    [commitment?.data?.type]
  );
  const classes = useStyles({ color });

  const [commitmentContractsData] = useDocumentData(
    getCollection(DashboardModel).doc("commitment-contracts").collection("commitmentContracts").doc(widgetId)
  );

  useEffect(() => {
    if (!commitmentContractsData) {
      setCommitment(null);
      return;
    }
    setCommitment({
      title: `${assetTypeName(commitmentContractsData.type)} Commitment`,
      description: "Commitment vs Spend",
      data: commitmentContractsData,
    });
  }, [commitmentContractsData]);

  const current = useMemo(() => {
    if (!commitment?.data.commitmentPeriods) {
      return null;
    }
    const i = commitment.data.commitmentPeriods.findIndex((cp) => cp.current);
    if (i > -1) {
      const cp = commitment.data.commitmentPeriods[i];
      return {
        ...cp,
        index: i,
      };
    }
    return null;
  }, [commitment]);

  const series = useMemo(() => {
    if (!commitment?.data.commitmentPeriods) {
      return [];
    }
    const commitmentRollover = commitment.data.commitmentRollover ?? false;
    return commitment.data.commitmentPeriods.reduce(
      (memo, cp, i) => {
        const start = DateTime.fromJSDate(cp.startDate.toDate()).toFormat("dd LLL yyyy");
        const end = DateTime.fromJSDate(cp.endDate.toDate()).toFormat("dd LLL yyyy");
        const period = `${start} - ${end}`;
        const y = i + 1;
        let s1 = 0;
        let s2 = 0;
        let s3 = 0;
        let s0 = 0;
        // s0 - rolled over
        // s1 - commitment fulfilled ("spend for period")
        // s2 - commitment remaining
        // s3 - commitment to rollover
        if (cp.rollover > cp.value) {
          s0 = cp.value;
          s1 = 0;
          s2 = 0;
          s3 = commitmentRollover ? cp.total + cp.rollover - cp.value : 0;
        } else if (cp.total + cp.rollover > cp.value) {
          s0 = cp.rollover;
          s1 = commitmentRollover
            ? cp.value > cp.rollover
              ? cp.value - cp.rollover
              : 0
            : cp.total > cp.value
              ? cp.value
              : cp.total;
          s2 = 0;
          s3 = commitmentRollover ? cp.total + cp.rollover - cp.value : 0;
        } else {
          s0 = cp.rollover;
          s1 = cp.total;
          s2 = cp.value - (cp.total + cp.rollover);
          s3 = 0;
        }

        memo[0].unshift({
          index: i,
          total: cp.value,
          y,
          x: s0,
          hint: s0,
          color: color.A100,
          title: `Rollover from period ${i}`,
          period,
        });
        memo[1].unshift({
          index: i,
          total: cp.value,
          y,
          x: s1,
          hint: s1,
          color: color.A200,
          title: "Actual Spend",
          period,
        });
        memo[2].unshift({
          index: i,
          total: cp.value,
          y,
          x: s2,
          hint: s2,
          color: cp.ended && s2 > 0 ? red[200] : grey[200],
          title: cp.ended ? "Shortfall" : "Remaining Commitment",
          period,
        });
        memo[3].unshift({
          index: i,
          total: cp.value,
          y,
          x: s3,
          hint: s3,
          color: color.A400,
          title: "Will Rollover",
          period,
        });

        return memo;
      },
      [[] as Graph[], [] as Graph[], [] as Graph[], [] as Graph[]]
    );
  }, [color, commitment]);

  if (isCustomizeMode && chartRef.current && gridRef.current?.offsetWidth) {
    chartRef.current.chart.reflow();
  }

  if (commitment === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (commitment === null) {
    return fallbackComponent;
  }

  return (
    <Grid ref={gridRef}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={`${assetTypeName(commitment.data.type)} Commitment`}
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader="Commitment vs Spend"
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
          action={
            !isCustomizeMode &&
            current &&
            current.estimated > 0 && (
              <Tooltip title={`Forecast for Period ${current.index + 1}`}>
                <Chip size="small" label={`$${formatNumber(current.estimated)}`} className={classes.chip} />
              </Tooltip>
            )
          }
          classes={{
            action: classes.cardHeaderAction,
          }}
        />
        <CardContent style={{ height: widgetHeight * 200 }} className={classes.cardContent}>
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={{
              xAxis: {
                categories: series.map((_, index) => index + 1),
                labels: {
                  style: {
                    color: theme.palette.text.primary,
                  },
                },
              },
              yAxis: {
                title: null,
                tickAmount: 6,
                labels: {
                  formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
                    return `$${formatNumber(this.value, 2)}`;
                  },
                  style: {
                    color: theme.palette.text.primary,
                  },
                },
              },
              title: {
                text: null,
              },
              subtitle: {
                text: null,
              },
              credits: false,
              chart: { type: "bar", backgroundColor: "transparent", animation: false },
              tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                  return `${getStackNameByIndex(this.series.name, this.x)}:$${formatNumber(this.y, 2)}`;
                },
              },
              plotOptions: {
                series: {
                  stacking: "normal",
                  pointWidth: 33.75,
                  pointPadding: 0,
                  borderWidth: 0,
                  groupPadding: 0,
                  animation: false,
                },
              },
              series:
                series[0][0].index === 0
                  ? series.map((stacks) => ({
                      showInLegend: false,
                      name: stacks[0]?.title ?? "",
                      data: stacks.map((item) => item.x),
                      color: stacks[0].color,
                    }))
                  : series.reverse().map((stacks) => ({
                      showInLegend: false,
                      data: stacks.reverse().map((item) => item.x),
                      color: stacks[0].color,
                    })),
              exporting: {
                enabled: false,
              },
            }}
            containerProps={{ style: { height: "100%" } }}
          />
        </CardContent>
        <div />
      </Card>
    </Grid>
  );
};

export default CommitmentContractsCard;
