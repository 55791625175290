import { type HTMLAttributes, useCallback, useEffect, useMemo } from "react";

import {
  AnalyticsDataSource,
  AnalyticsResourceType,
  type DatahubMetrics,
  type ExtendedMetric,
  Metric,
} from "@doitintl/cmp-models";
import {
  Autocomplete,
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderInputParams,
  Box,
  type SxProps,
  TextField,
  type Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import isEqual from "lodash/isEqual";

import { metricFiltersText, reportText } from "../../../assets/texts";
import { entitlementsTxt } from "../../../assets/texts/CloudAnalytics/entitlements";
import { useTier } from "../../../Context/TierProvider";
import { createFilterOptions, CSPMetric, MetricOptions } from "../../../Pages/CloudAnalytics/utilities";
import { type MetricWSnap } from "../../../types";
import { usePrevious } from "../../../utils/usePrevious";

type MetricOption = {
  label: string;
  groupName: string;
  value: string;
  disabled?: boolean;
  tooltip?: string;
};

export type Props = {
  calculatedMetrics: MetricWSnap[];
  dataCy?: string;
  dataSource: AnalyticsDataSource;
  datahubMetrics?: DatahubMetrics[];
  disabled?: boolean;
  disabledTooltip?: string;
  extendedMetrics: ExtendedMetric[];
  forcePopupIcon?: boolean;
  handleMetricSelect: (metricVal: Metric, value: MetricWSnap | string) => void;
  handleResetLimits?: () => void;
  inputLabel?: string;
  isCSP?: boolean;
  selectedCalculatedMetric: MetricWSnap | null;
  selectedExtendedMetric?: string | null;
  selectedMetric: number | null;
  size?: "medium" | "small";
  sx?: SxProps<Theme>;
};

const MetricSelect = ({
  calculatedMetrics,
  dataCy = "metric-select",
  dataSource,
  datahubMetrics,
  disabled,
  disabledTooltip,
  extendedMetrics,
  forcePopupIcon = true,
  handleMetricSelect,
  handleResetLimits = () => {},
  inputLabel = metricFiltersText.METRIC_VALUE_LABEL,
  isCSP,
  selectedCalculatedMetric,
  selectedExtendedMetric,
  selectedMetric,
  size = "small",
  sx,
}: Props) => {
  const { isFeatureEntitled } = useTier();
  const isDataSourceBilling = dataSource === AnalyticsDataSource.BILLING;
  const isDataSourceBillingDatahub = dataSource === AnalyticsDataSource.BILLING_DATAHUB;

  const metricOptionsFiltered = useMemo(
    () =>
      MetricOptions.filter(
        (m) => isDataSourceBilling || isDataSourceBillingDatahub || [Metric.COST, Metric.USAGE].includes(m.value)
      ),
    [isDataSourceBilling, isDataSourceBillingDatahub]
  );

  const extendedMetricsFiltered = useMemo(
    () =>
      extendedMetrics?.filter((m) => {
        if (isDataSourceBillingDatahub) {
          return m.dataSource === AnalyticsDataSource.BILLING_DATAHUB || m.dataSource === AnalyticsDataSource.BILLING;
        }
        return m.dataSource === dataSource;
      }),
    [dataSource, extendedMetrics, isDataSourceBillingDatahub]
  );

  const calculatedMetricsFiltered = useMemo(() => {
    const calculatedMetricsCustomEntitled = isFeatureEntitled("analytics:calculatedMetrics", true);
    const calculatedMetricsPresetsEntitled = isFeatureEntitled("analytics:calculatedMetrics:presets", true);

    return calculatedMetrics.filter((m) => {
      switch (m.data.type) {
        case AnalyticsResourceType.CUSTOM:
          return calculatedMetricsCustomEntitled;
        case AnalyticsResourceType.PRESET:
          return calculatedMetricsPresetsEntitled;
        default:
          return true;
      }
    });
  }, [calculatedMetrics, isFeatureEntitled]);

  const isExtendedMetricDisabled = useCallback(
    (m: ExtendedMetric) => {
      let result = { disabled: false, tooltip: "" };

      switch (m.key) {
        case "amortized_cost":
        case "amortized_savings":
          if (!isFeatureEntitled("analytics:extendedMetrics:amortizedCostSavings")) {
            result = {
              disabled: true,
              tooltip: entitlementsTxt.EXTENDED_METRIC_AMORTIZED_COST_SAVINGS,
            };
          }

          break;
        default:
          break;
      }

      return result;
    },
    [isFeatureEntitled]
  );

  const metricOptions = useMemo(() => {
    const basicMetricsOptions: MetricOption[] = metricOptionsFiltered.map((metric) => ({
      label: metric.label,
      groupName: reportText.BASIC_METRICS,
      value: metric.value.toString(),
    }));

    if (isCSP && (isDataSourceBilling || isDataSourceBillingDatahub)) {
      basicMetricsOptions.push({
        label: CSPMetric.label,
        groupName: reportText.BASIC_METRICS,
        value: CSPMetric.value.toString(),
      });
    }

    const extendedMetricsOptions: MetricOption[] = extendedMetricsFiltered.map((metric) => {
      const { disabled, tooltip } = isExtendedMetricDisabled(metric);
      return {
        label: metric.label,
        groupName: reportText.EXTENDED_METRICS,
        value: metric.key,
        disabled,
        tooltip,
      };
    });

    let dataHubMetricsOptions: MetricOption[] = [];
    if (isDataSourceBillingDatahub && datahubMetrics && datahubMetrics.length > 0) {
      dataHubMetricsOptions = datahubMetrics
        .map((metric) => ({
          label: metric.label,
          groupName: reportText.DATAHUB_METRICS,
          value: metric.key,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    const calculatedMetricsOptions = calculatedMetricsFiltered
      .map((metric) => ({
        label: metric.data.name,
        groupName: reportText.CALCULATED_METRICS,
        value: metric.snapshot.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [...basicMetricsOptions, ...extendedMetricsOptions, ...dataHubMetricsOptions, ...calculatedMetricsOptions];
  }, [
    calculatedMetricsFiltered,
    datahubMetrics,
    extendedMetricsFiltered,
    isCSP,
    isDataSourceBilling,
    isDataSourceBillingDatahub,
    isExtendedMetricDisabled,
    metricOptionsFiltered,
  ]);

  const selectedMetricOption = useMemo(() => {
    let selectedMetricValue = selectedMetric?.toString();

    if (selectedCalculatedMetric?.snapshot.id) {
      selectedMetricValue = selectedCalculatedMetric.snapshot.id;
    } else if (selectedExtendedMetric) {
      selectedMetricValue = selectedExtendedMetric;
    }

    if (!selectedMetricValue) {
      return undefined;
    }

    return metricOptions.find((m) => m.value === selectedMetricValue);
  }, [metricOptions, selectedCalculatedMetric?.snapshot.id, selectedExtendedMetric, selectedMetric]);

  const prevSelected = usePrevious(selectedMetricOption);

  // todo check if this is needed
  useEffect(() => {
    if (!prevSelected) {
      return;
    }
    if (parseInt(prevSelected.value) <= Metric.MARGIN) {
      return;
    }

    if (!isEqual(selectedMetricOption, prevSelected)) {
      handleResetLimits();
    }
  }, [prevSelected, selectedMetricOption, handleResetLimits]);

  const filterOptions = createFilterOptions<MetricOption>({
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: "any",
  });

  const handleChange = useCallback(
    (_, metricOption: MetricOption | null) => {
      if (!metricOption) {
        return;
      }

      switch (metricOption.groupName) {
        case reportText.BASIC_METRICS:
          handleMetricSelect(Number(metricOption?.value) as Metric, "");
          break;
        case reportText.CALCULATED_METRICS: {
          const cMetric = calculatedMetrics?.find((m) => m.snapshot.id === metricOption.value);
          if (cMetric) {
            handleMetricSelect(Metric.CALCULATED, cMetric);
          }
          break;
        }
        case reportText.EXTENDED_METRICS:
        case reportText.DATAHUB_METRICS:
          handleMetricSelect(Metric.EXTENDED, metricOption.value);
          break;
        default:
          break;
      }
    },
    [calculatedMetrics, handleMetricSelect]
  );

  const isError =
    (selectedMetric === Metric.EXTENDED || selectedMetric === Metric.CALCULATED) &&
    !selectedCalculatedMetric &&
    !selectedExtendedMetric;

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <Tooltip title={disabled ? disabledTooltip : ""}>
      <TextField
        {...params}
        label={inputLabel}
        size={size}
        data-cy="metric-select-input"
        margin="dense"
        InputLabelProps={{
          sx: [{ fontSize: "14px" }],
        }}
        error={isError}
        helperText={isError ? reportText.NO_ACCESS_METRIC : ""}
      />
    </Tooltip>
  );

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: MetricOption) => (
    <Tooltip title={option.tooltip ?? ""} key={option.value}>
      <span>
        <li {...props} style={{ fontSize: "14px" }}>
          {option.label}
        </li>
      </span>
    </Tooltip>
  );

  const handleRenderGroup = (params: AutocompleteRenderGroupParams) => (
    <Box key={params.key}>
      <Typography
        sx={{
          fontSize: "12px",
          color: "text.disabled",
          position: "sticky",
          top: "-8px",
          padding: "5px 10px",
          backgroundColor: "background.paper",
        }}
      >
        {params.group}
      </Typography>
      {params.children}
    </Box>
  );

  return (
    <Autocomplete
      data-cy={dataCy}
      disableClearable
      disabled={disabled}
      filterOptions={filterOptions}
      forcePopupIcon={forcePopupIcon}
      getOptionDisabled={(option) => option.disabled ?? false}
      groupBy={(option) => option.groupName}
      onChange={handleChange}
      options={metricOptions}
      renderGroup={handleRenderGroup}
      renderInput={handleRenderInput}
      renderOption={handleRenderOption}
      selectOnFocus={false}
      size={size}
      sx={sx}
      value={selectedMetricOption}
    />
  );
};

export default MetricSelect;
