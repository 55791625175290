import { useEffect, useReducer, useState } from "react";

import { useHistory } from "react-router-dom";

import { useApiContext } from "../../../api/context";
import { attributionGroupsText } from "../../../assets/texts";
import { guidedExperienceText } from "../../../assets/texts/CloudAnalytics";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { WorkflowDialog } from "../../../Components/Workflow/WorkflowDialog";
import { consoleErrorWithSentry } from "../../../utils";
import { createAttributionGroup } from "../attributionGroups/api";
import { costAllocationReducer, initialCostAllocationState } from "./costAllocationReducer";
import FinalStep from "./steps/FinalStep";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import SummaryStep from "./steps/SummaryStep";
import { GUIDED_EXPERIENCE } from "./utils";

const CONFIRM_STEP_NUMBER = 4;

type Props = {
  onClose: () => void;
  origin: string;
};

const GuidedExperienceDialog = ({ onClose, origin }: Props) => {
  const api = useApiContext();
  const showErrorSnackbar = useErrorSnackbar();
  const history = useHistory();
  const customerId = useCustomerId();
  const [costAllocationState, dispatchCostAllocationState] = useReducer(
    costAllocationReducer,
    initialCostAllocationState
  );
  const [goToStep, setGoToStep] = useState<number>();
  const [nextBtnIsLoading, setNextBtnIsLoading] = useState(false);

  useEffect(() => {
    dispatchCostAllocationState({ type: "RESET", payload: {} });
  }, []);

  const onLastStepConfirm = () => {
    history.push(`/customers/${customerId}/analytics/attribution-groups/${costAllocationState.AGId}`);
  };

  const nextStepCustomCallback = async (step: number) => {
    if (step !== CONFIRM_STEP_NUMBER) {
      return true;
    }

    setNextBtnIsLoading(true);

    try {
      const attributions = costAllocationState.newAttributions.filter((attr) => !!attr.data).map((attr) => attr.ref.id);
      const attributionGroupId = await createAttributionGroup({
        api,
        customerId,
        attributions,
        name: costAllocationState.selectedAG.name,
        description: "",
        nullFallback: costAllocationState.unallocatedName,
        fromDraftAttributions: true,
      });
      dispatchCostAllocationState({ type: "SET_AG_ID", payload: { AGId: attributionGroupId } });
      return true;
    } catch (error: any) {
      showErrorSnackbar(attributionGroupsText.CREATE_ATTRIBUTION_GROUP_ERROR);
      consoleErrorWithSentry(error);
      return false;
    } finally {
      setNextBtnIsLoading(false);
    }
  };

  const nextButtonLoadingCheck = (step: number) => {
    if (step === CONFIRM_STEP_NUMBER) {
      return {
        loading: nextBtnIsLoading,
        mixpanelEventId: "analytics.attribution-groups.create",
        eventProperties: { from: GUIDED_EXPERIENCE },
      };
    }
  };

  return (
    <WorkflowDialog
      open={true}
      onClose={onClose}
      stepsProps={{
        title: guidedExperienceText.titles.main,
        stepNames: guidedExperienceText.titles.steps,
        disableOnLastStep: true,
        resetOnOpen: true,
      }}
      gotoStep={goToStep}
      setGoToStep={setGoToStep}
      footerProps={{
        nextBtnText: guidedExperienceText.nextBtnText,
        disableNext: costAllocationState.nextButtonsDisabled,
        hideBackOnLastStep: true,
        nextButtonLoadingCheck,
      }}
      onLastStepConfirm={onLastStepConfirm}
      nextStepCustomCallback={nextStepCustomCallback}
    >
      <Step1 origin={origin} />
      <Step2
        selectedAG={costAllocationState.selectedAG}
        dispatchCostAllocationState={dispatchCostAllocationState}
        origin={origin}
      />
      <Step3
        costAllocationState={costAllocationState}
        dispatchCostAllocationState={dispatchCostAllocationState}
        origin={origin}
      />
      <Step4 origin={origin} />
      <SummaryStep
        costAllocationState={costAllocationState}
        handleGoToStep={(step) => setGoToStep(step)}
        origin={origin}
      />
      <FinalStep
        costAllocationState={costAllocationState}
        dispatchCostAllocationState={dispatchCostAllocationState}
        handleGoToStep={(step) => setGoToStep(step)}
        origin={origin}
      />
    </WorkflowDialog>
  );
};

export default GuidedExperienceDialog;
