import { type FC } from "react";

import { type StringApiServiceModelDescriptor } from "@doitintl/cmp-models";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { type FieldInputProps, type FormikProps } from "formik";

import { useToggle } from "../../../../../utils/useToggle";
import { useFieldValidationProps } from "../useFieldValidationProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";

export const StringParam: FC<{
  formikProps: FormikProps<unknown>;
  fieldProps: FieldInputProps<string>;
  inputModel: StringApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
}> = ({ formikProps, fieldProps, inputModel, label, onRemove }) => {
  const { showError, errorMessage } = useFieldValidationProps(formikProps, fieldProps);
  const required = onRemove === undefined;
  const [isVisible, , , toggleVisibility] = useToggle(false);

  const endAdornment = inputModel.sensitive ? (
    <InputAdornment position="end">
      <IconButton aria-label="toggle value visibility" onClick={toggleVisibility} edge="end">
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  ) : undefined;

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      <TextField
        type={!inputModel.sensitive || isVisible ? "text" : "password"}
        value={fieldProps.value}
        label={label}
        name={fieldProps.name}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        required={required}
        helperText={errorMessage}
        error={showError}
        fullWidth
        select={inputModel.enum !== undefined}
        multiline={inputModel.multiline}
        rows={4}
        maxRows={10}
        InputProps={{
          endAdornment,
        }}
      >
        {inputModel.enum?.map((itemValue, index) => (
          <MenuItem key={itemValue} value={itemValue}>
            {inputModel.enumDescriptions?.[index] ?? itemValue}
          </MenuItem>
        ))}
      </TextField>
    </OptionalParamWrapper>
  );
};
