import { Stack, Typography } from "@mui/material";

import { RadioCard } from "./RadioCard";

export const ActionStep = ({
  actionStepHandler,
  action,
  actionCards,
}: {
  actionStepHandler: (title: string) => void;
  action?: string;
  actionCards: any[];
}) => (
  <Stack gap={1} sx={{ mt: 1 }}>
    <Typography variant="subtitle2">Popular actions:</Typography>
    <>
      {actionCards.map((card) => (
        <RadioCard
          key={card.title}
          card={card}
          clickHandler={() => actionStepHandler(card.title)}
          isChecked={card.title === action}
        />
      ))}
    </>
  </Stack>
);
