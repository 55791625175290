import { useCallback, useEffect, useRef, useState } from "react";

import {
  type DashboardModelAttributionModel,
  type NotificationCommonFilterDescriptor,
  NotificationCommonFilterType,
  type UserNotification,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { useField } from "formik";

import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { type AttributionWRef } from "../../../types";
import { AttributionSelectMulti } from "../../Selects/CloudAnalytics/AttributionSelectMulti";
import { type NotificationWithGroupDescriptor } from "../useNotificationDescriptors";

type NotificationsWithAttributions =
  | UserNotification.CostAnomalies
  | UserNotification.DailyDigest
  | UserNotification.WeeklyDigest;

type NotificationAttributionsFilterProps = {
  notification: NotificationWithGroupDescriptor<NotificationsWithAttributions>;
};

function isAttributionsFilter({ type }: NotificationCommonFilterDescriptor) {
  return type === NotificationCommonFilterType.ATTRIBUTIONS;
}

export function hasAttributionsFilter(
  notification: NotificationWithGroupDescriptor
): notification is NotificationWithGroupDescriptor<NotificationsWithAttributions> {
  return notification.commonFilters?.some(isAttributionsFilter) ?? false;
}

export const NotificationAttributionsFilter = ({ notification }: NotificationAttributionsFilterProps) => {
  const [required, setRequired] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const [{ value: notificationValue }] = useField<object | undefined>({
    name: notification.value.toString(),
  });

  const [{ value }, { error, touched }, { setValue: setAttributionsValue, setTouched }] = useField<
    ModelReference<DashboardModelAttributionModel>[]
  >({
    name: `${notification.value}.attributions`,
  });

  useEffect(
    () => setRequired(notification.commonFilters?.find(isAttributionsFilter)?.required ?? false),
    [notification.commonFilters]
  );

  const { filteredAttributions, attributionsLoading } = useAttributionsContext();
  const [selectedScope, setSelectedScope] = useState<AttributionWRef[]>([]);

  useEffect(() => {
    setSelectedScope(
      filteredAttributions.filter(({ ref: { id } }) => value?.find(({ id: attributionId }) => id === attributionId))
    );
  }, [value, filteredAttributions, notification.value]);

  const disabled = !(notification.enabled && notificationValue !== undefined);
  const onChange = useCallback(
    (attributions) => setAttributionsValue(attributions.map((attribution) => attribution.ref)),
    [setAttributionsValue]
  );

  const showError = error !== undefined && touched;
  const helperText = required ? undefined : "Leave this field blank to receive all notifications";

  useEffect(() => {
    if (showError) {
      inputRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [showError]);

  return (
    <AttributionSelectMulti
      disabled={disabled}
      attributions={filteredAttributions.filter((a) => !a.data.hidden)}
      scope={selectedScope}
      onChange={onChange}
      onBlur={() => setTouched(true)}
      textFieldProps={{
        label: "Scope",
        placeholder: attributionsLoading ? "Loading..." : "Select attributions",
        helperText: required && showError ? error : helperText,
        error: showError,
        required,
        ref: inputRef,
      }}
    />
  );
};
