import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Button, Link, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { tiersText } from "../../assets/texts";
import { isProduction } from "../../constants";
import { useTier } from "../../Context/TierProvider";

const clientId = isProduction ? "95544667184.517333928260" : "95544667184.2093165412694";
const scopes = [
  "channels:read",
  "chat:write",
  "chat:write.public",
  "links:read",
  "links:write",
  "groups:read",
  "users:read",
  "users:read.email",
];

const userScopes = [
  "channels:read",
  "groups:read",
  "links:read",
  "links:write",
  "mpim:read",
  "users:read",
  "users:read.email",
];

export const slackAppIntegrationURI = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scopes.join(",")}&user_scope=${userScopes.join(",")}&state=`;
const slackAppInfoURI = "https://help.doit.com/docs/general/slack";

type SlackAppIntegrationButtonProps = {
  hasSlackChannel: boolean;
  customerId: string;
  isSlackAppEntitled?: boolean;
};
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: ({ hasSlackChannel }: { hasSlackChannel: boolean }) => (hasSlackChannel ? "flex-start" : "center"),
  },
  text: {
    marginBottom: ({ hasSlackChannel }: { hasSlackChannel: boolean }) => (hasSlackChannel ? 0 : 20),
    textAlign: ({ hasSlackChannel }: { hasSlackChannel: boolean }) => (hasSlackChannel ? "inherit" : "center"),
  },
});

export const SlackAppIntegrationButton = ({
  isSlackAppEntitled,
  hasSlackChannel,
  customerId,
}: SlackAppIntegrationButtonProps) => {
  const classes = useStyles({ hasSlackChannel });
  const { getFeatureName } = useTier();

  const navigateToSlackAppIntegration = () => {
    window.open(`${slackAppIntegrationURI}${customerId}`, "_self");
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1" color="textSecondary" className={classes.text}>
        Our application enables rich previews for Cloud Analytics reports and delivers budget alerts to any public Slack
        channel.
        <Link target="_blank" variant="body1" href={slackAppInfoURI} sx={{ textDecoration: "none" }}>
          {" Read more"}
          <OpenInNewIcon style={{ margin: "0px 0px -6px 4px", width: 20 }} />
        </Link>
      </Typography>
      <Tooltip
        title={!isSlackAppEntitled ? tiersText.UPGRADE_SUBSCRIPTION(getFeatureName("notification:slack:app")) : ""}
        arrow={true}
        placement="right"
      >
        <span>
          <Button
            disabled={!isSlackAppEntitled}
            onClick={navigateToSlackAppIntegration}
            variant="outlined"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Add to Slack
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
