import { type Dispatch } from "react";

import { Renderer } from "@doitintl/cmp-models";
import { MenuItem, TextField } from "@mui/material";

import mixpanel from "../../../utils/mixpanel";
import { useReportConfig, useReportDimensionsContext } from "../Context";
import { MenuProps, RendererOptions } from "../utilities";

export type ViewSelectProps = {
  shouldTreemapUpdateConfig: boolean;
  setTreemapDialogOpen: Dispatch<boolean>;
  disableTreemap: boolean;
  touched: Set<string>;
};

export const ReportViewSelect = ({
  shouldTreemapUpdateConfig,
  setTreemapDialogOpen,
  disableTreemap,
  touched,
}: ViewSelectProps) => {
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { handleTimeIntervalChange } = useReportDimensionsContext();
  const handleReportViewChange = (renderer: Renderer) => {
    if (renderer === Renderer.TREEMAP && shouldTreemapUpdateConfig) {
      setTreemapDialogOpen(true);
    } else {
      if (reportConfig.renderer === Renderer.TREEMAP) {
        handleTimeIntervalChange(reportConfig.timeInterval);
      }
      dispatchReportConfig({ payload: { renderer } });
      mixpanel.track("analytics.reports.view-changed", {
        view: renderer,
      });
      touched.add("rendererSelect");
    }
  };

  return (
    <TextField
      size="small"
      label="View As"
      value={reportConfig.renderer}
      onChange={(event) => handleReportViewChange(event.target.value as Renderer)}
      variant="outlined"
      margin="dense"
      select
      fullWidth
      SelectProps={{ MenuProps }}
      data-cy="reportViewSelect"
    >
      {RendererOptions.map((rendererOption) => (
        <MenuItem
          key={rendererOption.value}
          value={rendererOption.value}
          dense
          disabled={rendererOption.value === Renderer.TREEMAP && disableTreemap}
        >
          {rendererOption.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ReportViewSelect;
