import { type ChangeEvent, Fragment } from "react";

import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import capitalize from "lodash/capitalize";

import { type FirestoreTimestamp } from "../../../../utils/firebase";
import { assetSupportTypeOptionsMappingByHierarchy, overrideReasonMissing } from "../../assetUtils";
import SupportTierAlert from "./SupportTierAlert";

type Props = {
  supportTierValue: string;
  originalSupportTier: string;
  overridingSupportTierCurrentlyInFS: string;
  onTypeChange: (e) => void;
  disabled: boolean;
  showAlert: boolean;
  onRemoveOverride: () => void;
  overrideReason: string;
  updateOverrideReason: (reason: string) => void;
  showReasonError: boolean;
  supportTierChanged: boolean;
  overrideRemoved: boolean;
  overriddenOn?: FirestoreTimestamp | null;
};

const AssetsSupportTier = (props: Props) => {
  const {
    disabled,
    supportTierValue,
    onTypeChange,
    showAlert,
    onRemoveOverride,
    overrideReason,
    updateOverrideReason,
    showReasonError,
    supportTierChanged,
    originalSupportTier,
    overridingSupportTierCurrentlyInFS,
    overrideRemoved,
    overriddenOn,
  } = props;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onTypeChange(event.target.value);
  };
  const options = assetSupportTypeOptionsMappingByHierarchy.map((opt) => {
    const optionSupport = `${opt} support`;
    return (
      <MenuItem key={optionSupport} value={opt} data-cy={optionSupport}>
        {optionSupport}
      </MenuItem>
    );
  });

  const capitalizedSupportTierValue = supportTierValue && capitalize(supportTierValue);

  const shouldShowReasonField =
    (supportTierChanged && !overrideRemoved) || (overrideRemoved && supportTierValue !== originalSupportTier);

  return (
    <>
      <TextField
        disabled={disabled}
        label="Asset support tier"
        InputLabelProps={{ shrink: true }}
        value={capitalizedSupportTierValue || ""}
        onChange={handleChange}
        select
        size="medium"
        data-cy="supportTypeField"
        name="supportTypeField"
        id="supportTypeField"
      >
        {options}
      </TextField>
      {showAlert && (
        <SupportTierAlert
          originalSupportTier={originalSupportTier}
          overridingSupportTierCurrentlyInFS={overridingSupportTierCurrentlyInFS}
          overrideReason={overrideReason}
          onRemoveOverride={onRemoveOverride}
          overriddenOn={overriddenOn || null}
          overrideRemoved={overrideRemoved}
        />
      )}
      {shouldShowReasonField && (
        <TextField
          label="Override reason"
          value={overrideReason || ""}
          InputLabelProps={{ shrink: true }}
          size="medium"
          required
          helperText={showReasonError && overrideReasonMissing}
          onChange={(e) => updateOverrideReason(e.target.value)}
        />
      )}
    </>
  );
};
export default AssetsSupportTier;
