import { Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type Tier, tierColors } from "./NoEntitlement";

interface TierAvailabilityProps {
  tiers: Tier[];
}

export const TierAvailability: React.FC<TierAvailabilityProps> = ({ tiers }) => {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={0.5}>
      <Typography variant="body2">Available in DoiT Cloud Navigator tiers:</Typography>
      <Stack direction="row" spacing={1}>
        {tiers.map((tier) => (
          <Paper key={tier.tierType} elevation={0} sx={{ py: 0.5, px: 2, backgroundColor: tierColors[tier.tierType] }}>
            <Typography variant="subtitle2" color={theme.palette.getContrastText(tierColors[tier.tierType])}>
              {tier.title ?? tier.tierType}
            </Typography>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
};
