import { makeStyles } from "@mui/styles";

export const CARD_CONTENT_HEIGHT = 200;
export const CARD_HEADER_HEIGHT = 82;
export const HEADING_VARIANT = "subtitle2";
export const SUBHEADING_VARIANT = "caption";
export const HEADER_PADDING = "20px";

export const budgetStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    paddingBottom: 0,
    height: CARD_CONTENT_HEIGHT,
    overflowX: "auto",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
    cursor: "pointer",
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
}));
