import { useCallback, useRef, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Link, Typography } from "@mui/material";
import { type FormikProps } from "formik";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../utils";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";

export const useFormDialog = () => {
  const formRef = useRef<FormikProps<Record<string, unknown>>>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitDialog = useCallback(async () => {
    if (formRef.current) {
      try {
        setIsLoading(true);
        await formRef.current.submitForm();
      } catch (e) {
        consoleErrorWithSentry(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [formRef]);

  return { submitDialog, isOpen, formRef, setIsOpen, isLoading };
};

export const useFlexsaveSupportDialog = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();
  const [serviceData] = useDocumentDataOnce(
    getCollection(AppModel).doc("support").collection("services").doc("cmp-flexsave-standalone")
  );

  const createTicket = useCallback(
    async ({ issue, details }, { setSubmitting }) => {
      const formData = new FormData();

      formData.append("subject", issue);
      formData.append("description", details);
      formData.append("platformField", serviceData?.platform ?? "");
      formData.append("productField", serviceData?.name ?? "");
      formData.append("priority", "high");
      (serviceData?.tags ?? []).forEach((tag) => formData.append("tags", tag));

      try {
        const ticket = await api.post(`/support/customers/${customer.id}/tickets`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        showSuccessSnackbar(
          <Typography variant="body1">
            Your support message has been sent. View your ticket{" "}
            <Link
              color="inherit"
              component={RouterLink}
              to={`/customers/${customer.id}/support/tickets/${ticket.data.id}`}
              underline="always"
              variant="body1"
            >
              here
            </Link>{" "}
          </Typography>
        );

        return ticket;
      } catch (e) {
        showErrorSnackbar("Sorry, something went wrong. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
    [
      serviceData?.platform,
      serviceData?.name,
      serviceData?.tags,
      api,
      customer.id,
      showSuccessSnackbar,
      showErrorSnackbar,
    ]
  );
  return { createTicket };
};
