import {
  type FocusEvent,
  type FormEvent,
  type KeyboardEvent,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";

import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import CellInput from "./CellInput";
import { type UpdatePlanDataType } from "./PeriodList";

export type PlannedRowProps = {
  classes: Record<string, string>;
  hasEditPermission: boolean;
  inputEl: RefObject<HTMLTableCellElement> | null;
  onOpenCellDialog: () => void;
  periodMonthsArr: Array<Array<number>>;
  periodNum: number;
  periodStart: FirestoreTimestamp;
  tableEndPos: number;
  tableStartPos: number;
  fillerLength: number;
  updatePlanData: UpdatePlanDataType;
  periodPlanData: number[];
};

const PlannedRow = (props: PlannedRowProps) => {
  const {
    classes,
    hasEditPermission,
    inputEl,
    onOpenCellDialog,
    periodNum,
    periodPlanData,
    tableEndPos,
    tableStartPos,
    updatePlanData,
    periodMonthsArr,
    fillerLength,
  } = props;
  const [hoverKey, setHoverKey] = useState<null | number>(null);
  const nextCellRef = useRef<HTMLTableCellElement | null>(null);
  const [periodCellsCopy, setPeriodCellsCopy] = useState<Array<number | ""> | null>(null);
  const [arrowIconClickedOn, setArrowIconClickedOn] = useState<number | null>(null);
  const [nextCellIndex, setNextCellIndex] = useState<number | null>(null);
  const [focusedCellIndex, setFocusedCellIndex] = useState<number | null>(null);

  useEffect(() => {
    if (periodPlanData.length) {
      setPeriodCellsCopy(periodPlanData);
    }
  }, [periodPlanData]);

  const updateRampPlanFB = (i: number) => {
    if (periodCellsCopy) {
      periodPlanData[i] = !isNaN(periodCellsCopy[i] as number)
        ? parseInt(`${periodCellsCopy[i]}`.replace(/,/g, "").replace("$", ""))
        : 0;
      // updating firebase
      updatePlanData(periodNum - 1, null, i, false, false);
    }
    setFocusedCellIndex(i);
  };

  const updateStateAndFormat = (index: number, targetVal: number, isFromInput: boolean) => {
    setPeriodCellsCopy((prevState) => {
      const valSource = `${!isFromInput && periodPlanData[index] ? periodPlanData[index].toFixed(0) : targetVal}`;
      const cleanVal = valSource.replace(/,/g, "").replace("$", "");
      const stateCopy = cloneDeep(prevState);
      if (periodCellsCopy && stateCopy !== null) {
        stateCopy[index] = parseFloat(cleanVal);
      }
      return stateCopy;
    });
  };

  const onInputFocus = (e: FocusEvent<HTMLTableCellElement | HTMLInputElement | HTMLTextAreaElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const focusedIndex = parseInt(e.target?.attributes?.index.value);
    setFocusedCellIndex(focusedIndex);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateStateAndFormat(focusedIndex, e.target.value, false);
    setNextCellIndex(focusedIndex + 1);
  };

  const onInputBlur = (e: FocusEvent<HTMLTableCellElement | HTMLInputElement | HTMLTextAreaElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const i = parseInt(e.target?.attributes?.index?.value);
    updateRampPlanFB(i);
    setArrowIconClickedOn(null);
  };

  const onEnterPress = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.blur();
    if (nextCellRef.current !== null) {
      nextCellRef.current.focus();
    }
    nextCellRef.current = null;
  };

  const onInputKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onEnterPress(e as any);
    }
  };

  const onInputChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateStateAndFormat(e.target?.attributes?.index?.value, e.target.value, true);
  };

  const onCellMouseEnter = (cellIndexInPeriod: number | null) => {
    if (cellIndexInPeriod !== null) {
      setHoverKey(cellIndexInPeriod);
    }
  };

  const onCellMouseLeave = () => {
    setHoverKey(null);
  };
  const arrowClicked = () => {
    setArrowIconClickedOn(hoverKey);
    onOpenCellDialog();
  };

  const updateFocusedCellIndex = (index: number | null) => setFocusedCellIndex(index);

  const cellsRow = periodMonthsArr[periodNum - 1].slice(tableStartPos, tableEndPos).map((_item, index: number) => {
    const cellIndexInPeriod: number = index + tableStartPos;
    const isItNextCell: boolean = nextCellIndex === cellIndexInPeriod;
    const nextCellRefOrNull: RefObject<HTMLTableCellElement> | null = isItNextCell ? nextCellRef : null;
    return (
      <CellInput
        key={cellIndexInPeriod}
        arrowClicked={arrowClicked}
        arrowIconClickedOn={arrowIconClickedOn}
        classes={classes}
        focusedCellIndex={focusedCellIndex}
        hasEditPermission={hasEditPermission}
        hoverKey={hoverKey}
        inputEl={inputEl}
        nextCellRefOrNull={nextCellRefOrNull}
        onCellMouseEnter={onCellMouseEnter}
        onCellMouseLeave={onCellMouseLeave}
        onInputBlur={onInputBlur}
        onInputChange={onInputChange}
        onInputFocus={onInputFocus}
        onInputKeyPress={onInputKeyPress}
        periodCellsCopy={periodCellsCopy}
        updateFocusedCellIndex={updateFocusedCellIndex}
        cellIndexInPeriod={cellIndexInPeriod}
      />
    );
  });

  const fillerPlanned = new Array(fillerLength - cellsRow.length).fill(
    <CellInput
      arrowClicked={() => {}}
      arrowIconClickedOn={13}
      classes={classes}
      focusedCellIndex={focusedCellIndex}
      hasEditPermission={false}
      hoverKey={hoverKey}
      inputEl={inputEl}
      nextCellRefOrNull={null}
      onCellMouseEnter={() => {}}
      onCellMouseLeave={() => {}}
      onInputBlur={() => {}}
      onInputChange={() => {}}
      onInputFocus={() => {}}
      onInputKeyPress={() => {}}
      periodCellsCopy={new Array(fillerLength - cellsRow.length).fill("")}
      updateFocusedCellIndex={updateFocusedCellIndex}
      cellIndexInPeriod={13}
    />
  );
  cellsRow.push(...fillerPlanned);

  return (
    <TableRow data-testid="plannedRow">
      <TableCell padding="none" size="small" className={clsx(classes.borderedCell, classes.menuCell)}>
        Planned spend
      </TableCell>
      {periodCellsCopy !== null && cellsRow}
    </TableRow>
  );
};
export default PlannedRow;
