import { type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

@subCollection("affectedByKnownIssues")
export class InAppNotificationKnownIssueModel {
  issueDocId!: string;

  platform!: string;

  products!: string[];

  status!: string;

  created!: Timestamp;

  customerRef!: Reference<CustomerModel>;

  updated!: Timestamp;
}
