import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router";
import { DialogContent, Tooltip } from "@mui/material";

import { generalTxt } from "../../../../assets/texts/Settings/general";
import LoadingButton from "../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { useDeleteCustomer } from "../api";

const DeleteCustomer = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const deleteCustomerHandler = useDeleteCustomer();
  const { customer } = useCustomerContext();
  const history = useHistory();
  const errSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () =>
      void (async () => {
        try {
          const result = await deleteCustomerHandler(customer.id, false);
          setIsButtonDisabled(!result.executionPossible);
        } catch (e) {
          consoleErrorWithSentry(e);
        } finally {
          setIsLoading(false);
        }
      })(),
    [deleteCustomerHandler, customer.id]
  );

  const onConfirm = useCallback(async () => {
    try {
      await deleteCustomerHandler(customer.id, true);
      successSnackbar(generalTxt.CUSTOMER_DELETE_SUCCESS);
      history.push("/customers");
    } catch (e) {
      consoleErrorWithSentry(e);
      errSnackbar(generalTxt.CUSTOMER_DELETE_ERROR);
    } finally {
      setIsDialogOpen(false);
    }
  }, [deleteCustomerHandler, customer.id, successSnackbar, history, errSnackbar]);

  const DeleteButton = ({ color }) => (
    <LoadingButton
      color={color}
      variant="text"
      loading={isLoading}
      disabled={isButtonDisabled}
      onClick={() => setIsDialogOpen(true)}
      mixpanelEventId="settings.customer.delete"
    >
      {generalTxt.DELETE_CUSTOMER_BTN_TITLE}
    </LoadingButton>
  );

  const ButtonWithTooltip = ({ isLoading, isButtonDisabled }) => {
    if (isLoading) {
      return (
        <Tooltip key="delete-customer-loading" title={generalTxt.CUSTOMER_RETRIEVING_DATA}>
          <span>
            <DeleteButton color="primary" />
          </span>
        </Tooltip>
      );
    }

    if (isButtonDisabled) {
      return (
        <Tooltip key="delete-customer-disabled" title={generalTxt.CUSTOMER_DELETE_DISABLED}>
          <span>
            <DeleteButton color="primary" />
          </span>
        </Tooltip>
      );
    }

    return (
      <span>
        <DeleteButton color="error" />
      </span>
    );
  };

  return (
    <>
      <ButtonWithTooltip isLoading={isLoading} isButtonDisabled={isButtonDisabled} />
      <SimpleDialog
        open={isDialogOpen}
        title={generalTxt.DELETE_CUSTOMER_BTN_TITLE}
        onConfirm={onConfirm}
        onCancel={() => setIsDialogOpen(false)}
        confirmButtonText={generalTxt.DELETE_CUSTOMER_CONFIRM_BTN_TITLE}
      >
        <DialogContent sx={{ mt: 2 }}>
          <>{generalTxt.CONFIRM_DELETE_CUSTOMER(customer.name)}</>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};

export { DeleteCustomer };
