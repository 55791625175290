import { type FC } from "react";

import { FormLabel, Stack, Switch } from "@mui/material";
import { type FieldInputProps, type FormikProps } from "formik";

import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";

export const BooleanParam: FC<{
  formikProps: FormikProps<unknown>;
  fieldProps: FieldInputProps<boolean>;
  label: string;
  onRemove?: () => void;
}> = ({ formikProps, fieldProps, label, onRemove }) => (
  <OptionalParamWrapper onRemove={onRemove}>
    <Stack direction="row" spacing={2} alignItems="center" flex={1}>
      <FormLabel>{label}</FormLabel>
      <Switch
        value={fieldProps.value}
        name={fieldProps.name}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
      />
    </Stack>
  </OptionalParamWrapper>
);
