import { useState } from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LoadingButton from "../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../../utils";
import { deleteThread } from "../firestore";
import { LinkedThreadPaper } from "./LinkedThreadPaper";

export const ErroredLinkedThread = ({ threadID }) => {
  const theme = useTheme();

  const { isDoitEmployee } = useAuthContext();

  const successSnackbar = useSuccessSnackbar();

  const [deleting, setDeleting] = useState(false);

  const handleDeleteThread = async () => {
    try {
      setDeleting(true);

      await deleteThread(threadID);

      successSnackbar("Thread deleted successfully!");

      setDeleting(false);
    } catch (e) {
      consoleErrorWithSentry(e);
      setDeleting(false);
    }
  };

  return (
    <LinkedThreadPaper color={theme.palette.error.main} topBorderColor={theme.palette.error.main}>
      <Box sx={{ p: 2, textAlign: "center" }}>
        <WarningAmberIcon sx={{ color: theme.palette.error.main }} />
        <Typography sx={{ fontSize: 14 }}>Something went wrong and the thread could not be loaded.</Typography>
        <Typography sx={{ fontSize: 14, mt: 1 }}>
          This could be because the issue has been deleted in Jira. Would you like to delete this thread?
        </Typography>
        <Tooltip title={isDoitEmployee ? "DoiT employees cannot delete threads" : ""}>
          <span>
            <LoadingButton
              disabled={isDoitEmployee}
              loading={deleting}
              variant="outlined"
              size="medium"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleDeleteThread}
              fullWidth
              mixpanelEventId="threads.delete"
            >
              Delete thread
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>
    </LinkedThreadPaper>
  );
};
