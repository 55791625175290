import { useMemo, useState } from "react";

import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { Box, Link, Stack, Typography } from "@mui/material";
import sumBy from "lodash/sumBy";

import useMountEffect from "../../../../../../Components/hooks/useMountEffect";
import { formatCurrency } from "../../../../../../utils/common";
import mixpanel from "../../../../../../utils/mixpanel";
import { type CostDifferencesData, type Explainer } from "../../../types";
import ExplainerCostDifferencesFiltersComponent from "../../Components/CostDifferencesFiltersComponent";
import ExplainerCostDifferencesTableComponent from "../../Components/CostDifferencesTableComponent";
import { parseCostDifferenceData } from "../utils";

type Props = {
  explainer: Explainer;
  onCurrencyChange?: (currency: CurrencyCode) => void;
};

const ExplainerCostPerService = ({ explainer, onCurrencyChange }: Props) => {
  const [currency, setCurrency] = useState<CurrencyCode>(CurrencyCodes.USD);

  const data = useMemo(() => parseCostDifferenceData(explainer.service), [explainer]);
  const [filteredData, setFilteredData] = useState<CostDifferencesData[]>(data);

  const handleCurrencyChange = (currency: CurrencyCode) => {
    setCurrency(currency);
    if (onCurrencyChange) {
      onCurrencyChange(currency);
    }
  };

  useMountEffect(() => {
    mixpanel.track("invoices.invoice.explainer.servicebreakdown.open");
  });

  return (
    <Box maxWidth="800px">
      <Typography variant="h3" mb={3}>
        Comparing services
      </Typography>
      <Typography variant="body1" mb={2}>
        Use this breakdown to understand what services are causing a difference in cost between AWS and DoiT. All values
        are displayed before tax.
      </Typography>
      <Typography variant="body1" mb={2}>
        For more information, read about DoiT and AWS invoice differences in our{" "}
        <Link
          href="https://help.doit.com/docs/billing/billing-explained/billing-differences"
          target="_blank"
          onClick={() => mixpanel.track("invoices.invoice.explainer.helpcenter.open")}
        >
          {" "}
          Help Center
        </Link>
        .
      </Typography>
      <Stack direction="row" justifyContent="space-between" mt={3} mb="18px">
        <Stack>
          <Typography variant="body1" color="text.secondary">
            Total services
          </Typography>
          <Typography variant="h4">{data.length}</Typography>
        </Stack>
        <Stack alignItems="flex-end">
          <Typography variant="body1" color="text.secondary">
            Total pre-tax charges
          </Typography>
          <Typography variant="h4">{formatCurrency(sumBy(data, "doitCost"), currency)}</Typography>
        </Stack>
      </Stack>
      <ExplainerCostDifferencesFiltersComponent
        data={data}
        onFilterChange={setFilteredData}
        onCurrencyChange={handleCurrencyChange}
        storageKey="explainer_cost_per_service"
      />
      <ExplainerCostDifferencesTableComponent
        data={filteredData}
        currency={currency}
        costNameLabel="Service"
        storageKey="explainer_cost_per_service"
      />
    </Box>
  );
};

export default ExplainerCostPerService;
