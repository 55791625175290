import { useMemo, useState } from "react";

import { type ProductTypes, type SubscriptionPaymentTerm, TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Alert, Container, Grid, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { sanitizeKeepingLocalDate } from "../../../../utils/common";
import { isNext10Contract } from "../../utils";
import {
  chargePerTermLabelMap,
  commitmentTermOptions,
  onDemandTermOptions,
  paymentTermOptions,
  queryTypeMap,
  subscriptionTypeOptions,
} from "../const";
import { type ContractStateType, type Errors } from "../types";
import {
  calculateChargePerTerm,
  calculateTotalSalesPrice,
  getSelectedTier,
  isMonthlyFlatRateApplicable,
  stateDefaultValues,
} from "../utils";
import ContractCurrencySelector from "./components/ContractCurrencySelector";

const dateYearAgo = DateTime.now().minus({ year: 1 });

type Props = {
  state: ContractStateType;
  setState: React.Dispatch<React.SetStateAction<ContractStateType>>;
  isDoitContractAdmin: boolean;
  isDoitContractOwner: boolean;
  handleChangeNumber: (name: string) => ({ target }: { target: any }) => void;
  productTypes: ProductTypes;
  overlappingContractExists: boolean;
};

export const SubscriptionStep = ({
  state,
  setState,
  handleChangeNumber,
  isDoitContractAdmin,
  isDoitContractOwner,
  productTypes,
  overlappingContractExists,
}: Props) => {
  const [tierPrice, setTierPrice] = useState<number>(0);

  const [tier] = useCollectionDataOnce(
    getCollection(TierModel)
      .where("packageType", "==", queryTypeMap[state.type])
      .where("name", "in", ["standard", "enhanced", "premium", "enterprise"]),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const tiers = useMemo(
    () =>
      tier?.sort((t1, t2) => {
        if (t1.name === "enterprise" && t2.name !== "enterprise") {
          return 1;
        }
        if (t1.name !== "enterprise" && t2.name === "enterprise") {
          return -1;
        }
        return (t1.price?.[state.currency] || 0) - (t2.price?.[state.currency] || 0);
      }),
    [tier, state.currency]
  );

  const pageTitle = useMemo(() => {
    const currentProductLabel = productTypes.find((option) => option.value === state.type)?.label;
    return `${currentProductLabel} details`;
  }, [productTypes, state.type]);

  const monthlyFlatRateText = useMemo(
    () => `+${state.monthlyFlatRate}% of the monthly cloud spend`,
    [state.monthlyFlatRate]
  );

  const selectedTier = useMemo(() => getSelectedTier(tiers, state.tier), [state.tier, tiers]);

  const tierMonthlyPrice =
    selectedTier?.name === "enterprise" ? state.chargePerTerm : selectedTier?.price?.[state.currency];

  const availableCommitmentTermOptions = useMemo(
    () => (state.paymentTerm === "annual" ? [12] : commitmentTermOptions),
    [state.paymentTerm]
  );

  const handleChangeStartDate = (date: DateTime<boolean> | null) => {
    let endDate = state.endDate;

    if (state.commitmentMonths && state.commitmentMonths > 0 && date) {
      endDate = sanitizeKeepingLocalDate(sanitizeKeepingLocalDate(date).plus({ months: state.commitmentMonths }));
    } else {
      endDate = null;
    }

    setState((prevState: any) => ({
      ...prevState,
      startDate: date ? sanitizeKeepingLocalDate(date) : null,
      endDate,
      errors: { ...prevState?.errors, startDate: false, endDate: false },
    }));
  };

  const handleChangeEndDate = (date: DateTime<boolean> | null) =>
    setState((prevState: any) => ({
      ...prevState,
      endDate: date ? sanitizeKeepingLocalDate(date) : null,
      chargePerTerm: tierMonthlyPrice,
      errors: { ...prevState?.errors, endDate: false },
    }));

  const handleChange =
    (
      name:
        | "tier"
        | "commitmentMonths"
        | "chargePerTerm"
        | "paymentTerm"
        | "monthlyFlatRate"
        | "isCommitment"
        | "currency"
    ) =>
    ({ target: { value } }) => {
      let errors: Errors = {};
      let { tier, currency, endDate, chargePerTerm, commitmentMonths, paymentTerm, isCommitment, monthlyFlatRate } =
        state;

      switch (name) {
        case "currency": {
          currency = value;
          const tierPrice = selectedTier?.name === "enterprise" ? 0 : selectedTier?.price?.[currency];
          chargePerTerm = calculateChargePerTerm(paymentTerm, commitmentMonths, tierPrice);
          break;
        }

        case "tier": {
          tier = value;
          const tierDetails = getSelectedTier(tiers, tier);
          const tierPrice = tierDetails?.name === "enterprise" ? 0 : tierDetails?.price?.[currency];
          chargePerTerm = calculateChargePerTerm(paymentTerm, commitmentMonths, tierPrice);
          monthlyFlatRate = isMonthlyFlatRateApplicable(tierDetails)
            ? tierDetails?.name === "enterprise"
              ? state.monthlyFlatRate === undefined || state.monthlyFlatRate === stateDefaultValues.monthlyFlatRate
                ? 0 // Set to 0 if state.monthlyFlatRate is the default value
                : state.monthlyFlatRate // Retain the updated value
              : state.monthlyFlatRate || stateDefaultValues.monthlyFlatRate // For non-enterprise tiers
            : undefined;
          errors = { chargePerTerm: false, monthlyFlatRate: false, tier: false };
          break;
        }

        case "paymentTerm":
          paymentTerm = value;
          commitmentMonths = paymentTerm === "annual" ? 12 : state.commitmentMonths;
          chargePerTerm = calculateChargePerTerm(
            paymentTerm,
            commitmentMonths,
            selectedTier?.name === "enterprise" ? tierPrice : tierMonthlyPrice
          );
          break;

        case "commitmentMonths": {
          commitmentMonths = value;
          endDate =
            commitmentMonths && commitmentMonths > 0
              ? sanitizeKeepingLocalDate(state.startDate.plus({ months: commitmentMonths }))
              : endDate;
          chargePerTerm = calculateChargePerTerm(
            paymentTerm,
            commitmentMonths,
            selectedTier?.name === "enterprise" ? tierPrice : tierMonthlyPrice
          );
          paymentTerm = commitmentMonths === -1 ? "monthly" : paymentTerm;
          break;
        }

        case "chargePerTerm": {
          const price = value !== "" && Number(value) > 0 ? Number(value) : 0;
          chargePerTerm = price;
          if (selectedTier?.name === "enterprise") {
            setTierPrice(price);
          }
          break;
        }

        case "isCommitment":
          isCommitment = value === "commitment";
          commitmentMonths = isCommitment ? (state.commitmentMonths ?? commitmentTermOptions[0]) : undefined;
          endDate = isCommitment
            ? sanitizeKeepingLocalDate(state.startDate.plus({ months: commitmentMonths }))
            : state.endDate;
          paymentTerm = isCommitment ? state.paymentTerm : "monthly";
          chargePerTerm = calculateChargePerTerm(
            paymentTerm,
            commitmentMonths,
            selectedTier?.name === "enterprise" ? tierPrice : tierMonthlyPrice
          );
          break;
      }

      setState({
        ...state,
        tier,
        currency,
        chargePerTerm,
        endDate,
        commitmentMonths,
        isCommitment,
        paymentTerm,
        monthlyFlatRate,
        errors: { ...state.errors, ...errors },
      });
    };

  const handleBlur = ({ target: { value } }) => {
    setState({
      ...state,
      errors: { ...state.errors, monthlyFlatRate: value === "" || value === undefined || value === null },
    });
  };

  return (
    <Container maxWidth="sm" data-cy="subscription-step">
      {overlappingContractExists && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          A contract with the selected type already exists for the selected dates. You must cancel any concurrent
          contract or adjust the dates
        </Alert>
      )}
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {pageTitle}
      </Typography>
      <Grid>
        <TextField
          label="Subscription type"
          data-cy="subscription-type"
          value={state.isCommitment ? "commitment" : "on-demand"}
          onChange={handleChange("isCommitment")}
          disabled={state.isEditForbidden}
          select
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {subscriptionTypeOptions?.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid>
        {state.isCommitment ? (
          <TextField
            label="Subscription term"
            data-cy="subscription-term"
            value={state.commitmentMonths}
            onChange={handleChange("commitmentMonths")}
            disabled={state.isEditForbidden}
            select
            fullWidth
            margin="normal"
            variant="outlined"
          >
            {availableCommitmentTermOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option} months
              </MenuItem>
            ))}
            {state.paymentTerm === "monthly" && <MenuItem value={-1}>Custom</MenuItem>}
          </TextField>
        ) : (
          <TextField
            label="Subscription term"
            data-cy="subscription-term"
            value={onDemandTermOptions[0]}
            onChange={() => undefined}
            fullWidth
            margin="normal"
            variant="outlined"
            select
          >
            {onDemandTermOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          renderInput={(params) => (
            <TextField
              data-cy="subscription-start-date"
              margin="normal"
              fullWidth
              {...params}
              error={state.errors?.startDate}
            />
          )}
          minDate={!isDoitContractAdmin || !isDoitContractOwner ? sanitizeKeepingLocalDate(dateYearAgo) : undefined}
          label="Subscription start date"
          value={state.startDate}
          onChange={handleChangeStartDate}
          disabled={state.isEditForbidden}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>
      {state.isCommitment && (
        <Grid item xs={12} md={6}>
          <DatePicker
            renderInput={(params) => (
              <TextField data-cy="subscription-end-date" margin="normal" fullWidth {...params} />
            )}
            label="Subscription end date"
            value={state.endDate}
            minDate={state.startDate}
            onChange={handleChangeEndDate}
            disabled={state.commitmentMonths !== -1 || state.isEditForbidden}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat="dd LLL, yyyy"
          />
        </Grid>
      )}
      <Grid>
        <TextField
          label="Tier"
          data-cy="tier"
          value={state.tier}
          onChange={handleChange("tier")}
          error={state.errors.tier}
          disabled={state.isEditForbidden}
          select
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {tiers?.map((tier) => (
            <MenuItem key={tier.id} value={tier.id}>
              {tier.displayName}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid display="flex">
        <ContractCurrencySelector
          value={state.currency}
          disabled={state.isEditForbidden || !state.tier}
          onChange={handleChange("currency")}
          options={selectedTier?.price ? Object.keys(selectedTier.price) : []}
        />
        <TextField
          label={chargePerTermLabelMap[state.paymentTerm ?? "monthly"].label}
          data-cy="payment-amount"
          value={state.chargePerTerm === 0 ? "" : state.chargePerTerm}
          error={selectedTier?.name === "enterprise" && state.errors.chargePerTerm}
          onChange={handleChange("chargePerTerm")}
          disabled={selectedTier?.name !== "enterprise"}
          type="number"
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid>
        <TextField
          label="Discount"
          value={state.discount}
          data-cy="discount"
          onChange={handleChangeNumber("discount")}
          error={state.errors.discount}
          disabled={state.isEditForbidden}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          fullWidth
          margin="normal"
          sx={{ mb: 0 }}
          variant="outlined"
        />
        <Typography variant="caption" color="textSecondary">
          {" "}
          Discounts are used to change the total monthly sales price{" "}
        </Typography>
      </Grid>

      {isMonthlyFlatRateApplicable(selectedTier) && (
        <Grid>
          <TextField
            label="Monthly flat rate based on the cloud spend"
            data-cy="monthly-flat-rate"
            value={state.monthlyFlatRate}
            onChange={handleChangeNumber("monthlyFlatRate")}
            onBlur={handleBlur}
            error={state.errors.monthlyFlatRate}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Grid>
      )}
      <Grid>
        <TextField
          label="Payment term"
          data-cy="payment-term"
          value={state.paymentTerm}
          onChange={handleChange("paymentTerm")}
          error={state.errors.paymentTerm}
          disabled={isNext10Contract(state.type) ? state.isEditForbidden : state.isEditForbidden || !state.isCommitment}
          select
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {paymentTermOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {Boolean(state.chargePerTerm && state.paymentTerm) && (
        <Grid mt={2}>
          <Typography display="div" variant="body2" sx={{ fontWeight: 500 }}>
            Total sales price:
          </Typography>
          <Typography display="div" variant="body2">
            {calculateTotalSalesPrice(
              state.chargePerTerm as number,
              state.paymentTerm as SubscriptionPaymentTerm,
              state.discount,
              state.currency
            )}
          </Typography>
          {isMonthlyFlatRateApplicable(selectedTier) && Number(state.monthlyFlatRate) > 0 && (
            <Typography display="div" variant="body2" color={grey[500]}>
              {monthlyFlatRateText}
            </Typography>
          )}
        </Grid>
      )}
    </Container>
  );
};
