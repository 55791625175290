import { useCallback, useMemo } from "react";

import { AnalyticsResourceType, type Collaborators, type PublicAccess, Roles } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import { type Method } from "axios";
import { useClipboard } from "use-clipboard-copy";

import { useApiContext } from "../../../api/context";
import { httpMethods, reportText } from "../../../assets/texts";
import { reportTxt } from "../../../assets/texts/CloudAnalytics";
import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import {
  useErrorSnackbar,
  useInfoSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { type ReportWSnap } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import Invites from "../../IAM/InviteUserDialog/handleInvite";
import { useFormatLink } from "../analyticsResources/hooks";
import { type AnalyticsResourcesReports } from "../analyticsResources/types";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import useGenerateReport from "../generateReport";
import { type createReportPayload } from "../generateReport/types";
import { deleteWidget } from "../handlers/updateWidgets";
import {
  getNewCollaborators,
  getNewUsers,
  type ReportMenuOption,
  reportMenuOptions,
  ReportMenuOptionsValues,
} from "../utilities";
import { deleteManyReports } from "./db";

export const useReportsThreeDotsMenu = ({
  row,
  reportId,
  shareHandler,
  scheduleHandler,
  deleteHandler,
}: {
  row: ReportWSnap | AnalyticsResourcesReports;
  reportId: string;
  shareHandler: () => void;
  scheduleHandler: () => void;
  deleteHandler: () => void;
}) => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { getTemplateIdByReportId, handleFavoriteReportTemplateUpdate } = useAnalyticsContext();
  const { isFeatureEntitled } = useTier();
  const generateReport = useGenerateReport();
  const infoSnackbar = useInfoSnackbar(3);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const clipboard = useClipboard({
    onSuccess: () => {
      infoSnackbar(reportText.CLIPBOARD_COPY);
    },
  });

  const handleCopyReport = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    const newReportId = await generateReport(
      {
        name: `Copy of ${row.data.name}`,
        description: "",
        collaborators: [{ email: currentUser.email, role: Roles.OWNER }],
        config: row.data.config,
        draft: false,
      } as createReportPayload,
      true
    );
    mixpanel.track("analytics.reports.new", { reportId: newReportId, draft: false });
    infoSnackbar(reportText.DUPLICATE_SUCCESSFUL);
  }, [generateReport, currentUser, row, infoSnackbar]);

  const getSchedulePermissions = useCallback(() => {
    const isOwner = row.data.owner === currentUser.email;
    const isScheduled = !!row.data.schedule;
    const isSubscribed = row.data.schedule?.to.includes(currentUser.email ?? "") ?? false;
    return { isOwner, isScheduled, isSubscribed };
  }, [row, currentUser.email]);

  const linkToReport = useFormatLink({ type: "report", id: reportId });
  const handleCopyLink = useCallback(
    (reportId) => {
      mixpanel.track(`analytics.report.list.copy-link`, {
        reportId,
      });

      clipboard.copy(`${window.location.origin}${linkToReport}`);
    },
    [clipboard, linkToReport]
  );

  const handleRemoveManagedReport = useCallback(
    (reportId) => {
      const templateId = getTemplateIdByReportId(reportId);
      if (templateId) {
        handleFavoriteReportTemplateUpdate(templateId);
        mixpanel.track("analytics.templates.remove-from-reports-list");
        successSnackbar(reportTxt.REPORT_REMOVED_SUCCESSFULLY);
      } else {
        errorSnackbar(reportTxt.REPORT_REMOVE_ERROR);
      }
    },
    [errorSnackbar, getTemplateIdByReportId, handleFavoriteReportTemplateUpdate, successSnackbar]
  );

  const handleSelection = useCallback(
    async ({ option }: { option: ReportMenuOption }) => {
      switch (option.value) {
        case ReportMenuOptionsValues.COPY_LINK:
          handleCopyLink(reportId);
          break;
        case ReportMenuOptionsValues.SHARE:
          shareHandler();
          break;
        case ReportMenuOptionsValues.SCHEDULE:
          mixpanel.track("analytics.report.list.schedule", { reportId });
          scheduleHandler();
          break;
        case ReportMenuOptionsValues.DELETE:
          deleteHandler();
          break;
        case ReportMenuOptionsValues.DUPLICATE:
          await handleCopyReport();
          break;
        case ReportMenuOptionsValues.REMOVE:
          handleRemoveManagedReport(reportId);
          break;
      }
    },
    [
      deleteHandler,
      handleCopyLink,
      handleCopyReport,
      handleRemoveManagedReport,
      reportId,
      scheduleHandler,
      shareHandler,
    ]
  );

  const menuOptions = useMemo(
    () =>
      reportMenuOptions
        .flatMap((option): ReportMenuOption | [] => {
          const { isOwner, isScheduled, isSubscribed } = getSchedulePermissions();

          if (option.value === ReportMenuOptionsValues.REMOVE && row.data.type !== AnalyticsResourceType.MANAGED) {
            return [];
          }

          if (
            (option.value === ReportMenuOptionsValues.SCHEDULE && !isScheduled && !isOwner && !isSubscribed) ||
            (option.value === ReportMenuOptionsValues.SHARE &&
              (row.data.type !== AnalyticsResourceType.CUSTOM || row.data.name.length === 0)) ||
            (option.value === ReportMenuOptionsValues.DELETE && !isOwner) ||
            (option.value === ReportMenuOptionsValues.DUPLICATE &&
              !isDoitEmployee &&
              !isFeatureEntitled("analytics:reports"))
          ) {
            return { ...option, disabled: true };
          }

          return option;
        })
        .sort((b, a) => b.order - a.order)
        .reduce((total, option) => {
          let label = <Typography color={option.secondary ? "error" : undefined}>{option.label}</Typography>;
          let action: () => Promise<void> | void = () => handleSelection({ option });
          if (option.disabled) {
            label = <Typography style={{ opacity: 0.15, cursor: "not-allowed" }}>{option.label}</Typography>;
            action = () => void 0;
          }
          return [
            ...total,
            {
              label,
              action,
              key: option.value,
            },
          ];
        }, [] as ThreeDotsMenuOption[]),
    [getSchedulePermissions, row.data.type, row.data.name.length, isDoitEmployee, isFeatureEntitled, handleSelection]
  );

  return <ThreeDotsMenu options={menuOptions} closeAfterSelect={row.data.type === AnalyticsResourceType.MANAGED} />;
};

export const useDeleteReportsHandler = ({
  reports,
  setSelectedReports,
}: {
  reports: ReportWSnap[] | AnalyticsResourcesReports[];
  setSelectedReports: (reports: ReportWSnap[]) => void;
}) => {
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(async () => {
    try {
      const reportIds: string[] = reports.map((r: ReportWSnap | AnalyticsResourcesReports) => r.snapshot.id);

      await Promise.all([
        ...reports.map((report: ReportWSnap | AnalyticsResourcesReports) =>
          deleteWidget(api, customer.id, report.snapshot.id)
        ),
        deleteManyReports(api, customer.id, reportIds),
      ]);
      successSnackbar(reportText.DELETE_REPORT_SUCCESS);
      mixpanel.track("analytics.reports.delete", { reportIds });
    } catch (error) {
      errorSnackbar(reportText.DELETE_ERROR);
      consoleErrorWithSentry(error);
    } finally {
      setSelectedReports([]);
    }
  }, [api, customer.id, errorSnackbar, reports, setSelectedReports, successSnackbar]);
};

export const useInviteAction = () => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { entities } = useEntitiesContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();

  return useCallback(
    async (collaborators: Collaborators, report: ReportWSnap) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: report,
        allUsersAndInvites,
      });
      if (emails.length) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );
};

export const useShareReport = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(
    async ({
      publicAccess,
      reportId,
      collaborators,
    }: {
      publicAccess: PublicAccess;
      reportId: string;
      collaborators: Collaborators;
    }) => {
      await api.request({
        method: httpMethods.PATCH as Method,
        url: `/v1/customers/${customer.id}/analytics/reports/${reportId}/share`,
        data: {
          public: publicAccess ? publicAccess : undefined,
          collaborators,
        },
      });
    },
    [api, customer.id]
  );
};

export const useShareReportsHandler = ({
  selected,
  closeDialog,
  setShareDialogLoading,
}: {
  selected: ReportWSnap[] | AnalyticsResourcesReports[];
  closeDialog: () => void;
  setShareDialogLoading: (isLoading: boolean) => void;
}) => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const snackbar = useSnackbar();
  const { handleMissingPermission } = useAnalyticsContext();
  const errorSnackbar = useErrorSnackbar();

  const isCurrentUserUserManager = useMemo(() => userRoles.usersManager || userRoles.doitEmployee, [userRoles]);
  const inviteAction = useInviteAction();
  const shareReport = useShareReport();

  return useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess) => {
      if ([...selected].every((report) => report?.data?.type === AnalyticsResourceType.CUSTOM)) {
        try {
          setShareDialogLoading(true);
          for (const report of selected) {
            if (isCurrentUserUserManager) {
              try {
                await inviteAction(collaborators, report);
              } catch (e) {
                handleMissingPermission(e as string);
                closeDialog();
                return;
              }
            }

            await shareReport({
              publicAccess: publicAccess === "mixed" ? report.data.public : publicAccess,
              collaborators: getNewCollaborators(collaborators, report.data.collaborators),
              reportId: report.snapshot.id,
            });
          }

          mixpanel.track("analytics.report-list.share", {
            reportIds: selected.map((x) => x.snapshot.id),
          });
          snackbar.onOpen({ message: reportText.REPORT_SHARE_SUCCESS, variant: "success", autoHideDuration: 5000 });
        } catch (error: any) {
          errorSnackbar(error.response?.data?.error || reportText.SHARE_FAILED);
          consoleErrorWithSentry(error);
        } finally {
          setShareDialogLoading(false);
          closeDialog();
        }
      }
    },
    [
      selected,
      setShareDialogLoading,
      snackbar,
      isCurrentUserUserManager,
      shareReport,
      inviteAction,
      handleMissingPermission,
      closeDialog,
      errorSnackbar,
    ]
  );
};
