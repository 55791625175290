import React, { useMemo } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import type { AnomalyFeedbackReason, CustomerFeedback } from "@doitintl/cmp-models";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useTier } from "../../../Context/TierProvider";
import { usePatchCostAnomaly } from "../hooks";
import AnomalyFeedbackReasonSelect from "./AnomalyFeedbackReasonSelect";

type Props = {
  open: boolean;
  onClose: () => void;
  customerFeedback?: CustomerFeedback;
};

const AnomalyFeedbackDialog = ({ open, onClose, customerFeedback }: Props) => {
  const { isAnomaly, reason, additionalComments } = customerFeedback ?? {};
  const [isAnomalyValue, setIsAnomalyValue] = React.useState(isAnomaly ?? true);
  const [reasonValue, setReasonValue] = React.useState<AnomalyFeedbackReason | undefined>(reason);
  const [comments, setComments] = React.useState(additionalComments ?? undefined);
  const patchAnomaly = usePatchCostAnomaly();
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();
  const { isFeatureEntitled } = useTier();

  const handleIsAnomalyChange = (event) => {
    setReasonValue(undefined);
    setIsAnomalyValue(event.target.value === "true");
  };

  const handleReasonChange = (event) => {
    setReasonValue(event);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const [loading, setLoading] = React.useState(false);

  const canSubmit = useMemo(() => reasonValue !== undefined, [reasonValue]);

  const handleSubmit = async () => {
    if (!reasonValue) {
      return;
    }

    setLoading(true);
    await patchAnomaly({
      anomaly: {
        customerFeedback: {
          isAnomaly: isAnomalyValue,
          additionalComments: comments,
          reason: reasonValue,
        },
      },
      onSuccess: () => {
        showSuccess("Anomaly feedback saved");
        onClose();
      },
      onError: () => {
        showError("An error occurred while saving anomaly feedback");
      },
    });
    setLoading(false);
  };

  if (!isFeatureEntitled("governance:costAnomalies:acknowledgement")) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: "600px",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h3" mb={2}>
          Acknowledge anomaly
        </Typography>
        <Typography variant="subtitle1" mb={1}>
          Would you classify this as an anomaly?
        </Typography>
        <Typography variant="caption" color="text.secondary" mb={1.5} component="p">
          An anomaly is a spike in cloud service expenses, often indicating potential issues like misconfigurations,
          over-provisioning, or unauthorized usage.
        </Typography>
        <FormControl sx={{ pl: 1, mb: 3 }}>
          <RadioGroup value={isAnomalyValue} onChange={handleIsAnomalyChange}>
            <FormControlLabel value={true} control={<Radio size="small" />} label="Yes, this was an anomaly" />
            {isAnomalyValue && (
              <AnomalyFeedbackReasonSelect
                isAnomaly={isAnomalyValue}
                onChange={handleReasonChange}
                value={reasonValue}
                sx={{ ml: "27px", width: 320 }}
              />
            )}
            <FormControlLabel value={false} control={<Radio size="small" />} label="No, this wasn't an anomaly" />
            {isAnomalyValue !== undefined && !isAnomalyValue && (
              <AnomalyFeedbackReasonSelect
                isAnomaly={isAnomalyValue}
                onChange={handleReasonChange}
                value={reasonValue}
                sx={{ ml: "27px", width: 320 }}
              />
            )}
          </RadioGroup>
        </FormControl>
        <TextField
          label="Additional comments"
          multiline
          rows={6}
          value={comments}
          onChange={handleCommentsChange}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 1,
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit} loading={loading} disabled={!canSubmit}>
          Save
        </LoadingButton>
      </Box>
    </Dialog>
  );
};

export default AnomalyFeedbackDialog;
