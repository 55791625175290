import { type JSX, useCallback } from "react";

import { useHistory } from "react-router";
import { DoitRole } from "@doitintl/cmp-models";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { type FilterTableToolbarProps } from "../../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { type WorkloadDetailsItem } from "../types";
import { AllWorkloadDetailsRow } from "./AllWorkloadDetailsRow";
import { allWorkloadsFilters, allWorkloadsHeaderColumns } from "./columns";
import { EmptyWorkloadsTableState } from "./EmptyWorkloadsTableState";

type Props = {
  workloadItems?: WorkloadDetailsItem[];
  onEditClick: (workloadDetailsItem: WorkloadDetailsItem) => void;
  title: JSX.Element;
};

export const AllWorkloadDetailsTable = ({ workloadItems, onEditClick, title }: Props) => {
  const AllWorkloadsDetailsRowWrapper = useCallback(
    (props: { row: WorkloadDetailsItem }) => <AllWorkloadDetailsRow row={props.row} onEditClick={onEditClick} />,
    [onEditClick]
  );

  const history = useHistory();
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);

  const toolbar: FilterTableToolbarProps = {
    allowToEditColumns: true,
    title,
  };

  if (isFlexsaveAdmin) {
    toolbar.secondaryButtons = [
      {
        text: "Manual purchase",
        onClick: () => history.push(`${history.location.pathname}/create`),
      },
    ];
  }

  return (
    <FilterTable<WorkloadDetailsItem>
      tableItems={workloadItems}
      rowComponent={AllWorkloadsDetailsRowWrapper}
      headerColumns={allWorkloadsHeaderColumns}
      filterColumns={allWorkloadsFilters}
      persistenceKey="flexsave_opts_workloads_details"
      emptyTableComponent={<EmptyWorkloadsTableState />}
      toolbarProps={toolbar}
    />
  );
};
