import { type JSX, type ReactElement } from "react";

import {
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  type ContractModelType,
  EntityModel,
  GooglePartnerSupportLevel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import find from "lodash/find";
import type * as axios from "axios";

import { type SharedSnackbarProps } from "../../../Components/SharedSnackbar/types";
import { type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { type InitData } from "../../../Navigation/types";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { updateAssetAndSettings } from "../utils";

export const labelTyp = (label: string | ReactElement): JSX.Element => <Typography variant="body1">{label}</Typography>;

export const menuItems = (
  editAssetAction: () => void,
  transferThisAsset: () => void,
  manageBALinkAction: () => void,
  manageBALink: JSX.Element
): ThreeDotsMenuOption[] => [
  {
    label: labelTyp("Edit asset"),
    action: editAssetAction,
  },
  {
    label: labelTyp("Transfer projects"),
    action: transferThisAsset,
  },
  {
    label: manageBALink,
    action: manageBALinkAction,
  },
];

export const gcProjectsTransfer = async (
  billingAccountId: string,
  customerProjects: string[],
  projects: string[],
  api: axios.AxiosInstance,
  customerId: string
) => {
  const srcBillingAccounts = [];
  const dstBillingAccount = billingAccountId;
  projects.forEach((project) => {
    const obj = find(customerProjects, { projectId: project });
    if (obj) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      srcBillingAccounts.push(obj.billingId.slice(16));
    }
  });
  mixpanel.track("assets.google-cloud.projects.transfer", {
    sourceBillingAccounts: srcBillingAccounts.join(", "),
    destinationBillingAccount: dstBillingAccount,
    numProjects: projects.length,
  });
  let res;
  try {
    res = await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/google-cloud/transfer-projects`,
      data: {
        billingAccountId: dstBillingAccount,
        projects,
      },
    });
  } catch (error: any) {
    res = error;
    consoleErrorWithSentry(error);
  }
  return res;
};

export const createServiceAccount = async (api: axios.AxiosInstance, customerId: string, billingAccountId: string) => {
  let res;
  try {
    res = await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/google-cloud/service-account`,
      data: {
        billingAccountId,
      },
    });
  } catch (error: any) {
    consoleErrorWithSentry(error);
    res = error;
  }
  return res;
};
export const testServiceAccount = async (api: axios.AxiosInstance, customerId: string, billingAccountId: string) => {
  let res;
  try {
    res = await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/google-cloud/test-iam`,
      data: {
        billingAccountId,
      },
    });
  } catch (error: any) {
    consoleErrorWithSentry(error);
    res = error;
  }
  return res;
};
export const handleSendInstructions = async (
  emailAddress,
  api: InitData["api"],
  customerId: string,
  entityId: string,
  billingAccountId: string,
  displayName: string
): Promise<string> => {
  try {
    await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/entities/${entityId}/google-cloud/${billingAccountId}/send`,
      data: {
        email: emailAddress,
        displayName,
      },
    });
    mixpanel.track("assets.google-cloud.billingaccount.sendemail", {
      id: billingAccountId,
      displayName,
      target: emailAddress,
    });
    return "success";
  } catch (error: any) {
    consoleErrorWithSentry(error);
    return error;
  }
};

export const getEntityRef = async (entId: string) => getCollection(EntityModel).doc(entId);

export type UpdatedAssetData = {
  tags?: string[];
  entity?: ModelReference<EntityModel>;
};

export const updateAssetFields = async (
  assetId: string,
  updatedData: UpdatedAssetData,
  openSnackbar: (obj: SharedSnackbarProps) => void,
  closSnackbar: () => void,
  successRedirect: () => void,
  standaloneCustomer: boolean
): Promise<void> => {
  const newData = updatedData.entity ? { ...updatedData, bucket: null, contract: null } : updatedData;

  const successSnackbar = () => {
    openSnackbar({
      message: "Asset updated",
      variant: "success",
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={closSnackbar} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
    successRedirect();
  };

  const errorSnackbar = (message?: string) =>
    openSnackbar({
      message,
      variant: "error",
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={closSnackbar} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });

  await updateAssetAndSettings(assetId, newData, standaloneCustomer, {
    success: successSnackbar,
    failure: errorSnackbar,
  });
};

export const saveGcAsset = async ({
  didTagChange,
  didEntityChange,
  tagInputText,
  assetId,
  selectedEntityId,
  onSnackbarOpen,
  onSnackbarClose,
  history,
  backTo,
  standaloneCustomer,
}) => {
  if (didTagChange) {
    tagInputText !== null &&
      assetId &&
      (await updateAssetFields(
        assetId,
        { tags: [tagInputText] },
        onSnackbarOpen,
        onSnackbarClose,
        () => history.push(backTo),
        standaloneCustomer
      ));
  }
  if (didEntityChange && selectedEntityId) {
    let entRef: ModelReference<EntityModel>;
    try {
      entRef = await getEntityRef(selectedEntityId);
    } catch (e) {
      consoleErrorWithSentry(`Cannot update asset - unable to get entity ref for ${selectedEntityId}`);
      return;
    }
    await updateAssetFields(
      assetId,
      { entity: entRef },
      onSnackbarOpen,
      onSnackbarClose,
      () => history.push(backTo),
      standaloneCustomer
    );
  }
};

export type GcpTableMode =
  | "google-cloud"
  | "google-cloud-direct"
  | "google-cloud-project"
  | "google-cloud-project-standalone";

export const titleAppendText = {
  [AssetTypeGoogleCloud]: "Partner",
  [AssetTypeGoogleCloudDirect]: "Direct",
  [AssetTypeGoogleCloudProject]: "Projects",
  [AssetTypeGoogleCloudProjectStandalone]: "Projects",
};

export const mapEntitiesByKey = (entities: Entity[]): Record<string, Entity> =>
  entities.reduce((acc, entity) => {
    acc[entity.id] = entity;
    return acc;
  }, {});

type GoogleContractSupportMap = { [key in ContractModelType]?: GooglePartnerSupportLevel };

export const contractSupportMap: GoogleContractSupportMap = {
  ["google-cloud-partner-led-premium-support"]: GooglePartnerSupportLevel.PARTNER_PREMIUM,
} as const;
