import { useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/system/Box";

import { FAQAccordion } from "../../../Components/FAQ/FAQAccordion";
import { SideDrawer } from "../../../Components/SideDrawer";
import { FaqCategory } from "./FAQCategory";
import { AWSFaq, DoiTFaq, GCPFaq } from "./faqs";

export type InvoiceFAQCategory = "aws" | "gcp" | "doit";

export const InvoicesFaqButton = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState<InvoiceFAQCategory | null>(null);

  const handleCategoryClicked = (category: InvoiceFAQCategory) => {
    setCategoryOpen(category);
  };

  const handleClose = () => {
    setSideDrawerOpen(false);
    setCategoryOpen(null);
  };

  const faqs = useMemo(() => {
    if (categoryOpen === "aws") {
      return AWSFaq;
    } else if (categoryOpen === "gcp") {
      return GCPFaq;
    } else if (categoryOpen === "doit") {
      return DoiTFaq;
    }

    return [];
  }, [categoryOpen]);

  return (
    <>
      <SideDrawer open={sideDrawerOpen} title="Invoice FAQs" disableGutters hideOverflow={false} onClose={handleClose}>
        <>
          {!categoryOpen && (
            <Stack justifyContent="start">
              <FaqCategory
                text="Amazon Web Services questions"
                category="aws"
                onCategoryClicked={handleCategoryClicked}
              />
              <FaqCategory text="Google Cloud questions" category="gcp" onCategoryClicked={handleCategoryClicked} />
              <FaqCategory text="DoiT Console questions" category="doit" onCategoryClicked={handleCategoryClicked} />
              <Stack alignItems="start" px={1}>
                <Button
                  href="https://docs.google.com/document/d/1Xo_JgV3pTcsg4TxBwnVQfbrVLCkpvxwNCvQDSDLOPjU/edit#heading=h.pw00dptrsbfl"
                  target="_blank"
                  variant="text"
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                    },
                  }}
                  endIcon={<OpenInNewIcon sx={{ fontSize: 14, mr: 1 }} />}
                >
                  View all FAQs
                </Button>
              </Stack>
            </Stack>
          )}
          {categoryOpen && (
            <Stack>
              <Stack direction="row" alignItems="center">
                <IconButton onClick={() => setCategoryOpen(null)}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography color="text.secondary" variant="body2">
                  Back to all FAQs
                </Typography>
              </Stack>
              <Box px={2}>
                <FAQAccordion showUnderlines={false} faqs={faqs} />
              </Box>
            </Stack>
          )}
        </>
      </SideDrawer>
      <Button onClick={() => setSideDrawerOpen(true)}>Invoice FAQs</Button>
    </>
  );
};
