import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import { Box, Button, CardContent } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";

import { useApiContext } from "../../../../api/context";
import { alertTexts } from "../../../../assets/texts";
import { helpURLs } from "../../../../assets/urls";
import { LearnMoreAlert } from "../../../../Components/Alerts";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import Hide from "../../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { deleteAccount } from "../db";
import { useAWSAccounts } from "../hooks";
import { type AWSAccount } from "../types";
import { AWSAccountsRow } from "./AWSAccountsRow";
import { filterColumns, headers } from "./columns";
import { DeleteRoleDialog } from "./DeleteRoleDialog";
import { TableSkeleton } from "./TableSkeleton";

/**
 * Screen to show filter table with all the linked accounts
 */
export const AWSAccounts = () => {
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const [accounts, numNotConfiguredAccounts] = useAWSAccounts();
  const [accountToDelete, setAccountToDelete] = useState<AWSAccount | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();

  useEffect(() => {
    api.get(`/v1/customers/${customer.id}/cloudconnect/aws/health`).then(() => {
      setLoading(false);
    });
  }, [api, customer.id]);

  // use a row component with pre-defined delete action
  const AWSAccountsRowWrapper = (props: { row: AWSAccount }) => (
    <AWSAccountsRow row={props.row} onDeleteClicked={setAccountToDelete} />
  );

  const onLinkAccountClicked = () => {
    history.push(`${routeMatchURL}/link`);
  };

  const handleDeleteAccount = () => {
    if (accountToDelete) {
      deleteAccount(customer.id, accountToDelete.id);
    }
  };

  return (
    <>
      <>
        <CardHeader title="Link Amazon Web Services" titleTypographyProps={{ variant: "h1" }} />
        <CardContent>
          <Box mt={-3} pb={1} hidden={loading}>
            <LearnMoreAlert text={alertTexts.CLOUD_CONNECT_AWS} url={helpURLs.ADD_AWS_IAM_ROLE} />
          </Box>
          {loading && <TableSkeleton />}
          <Box hidden={loading}>
            <FilterTable<AWSAccount>
              filterBarPlaceholder="Filter accounts"
              rowComponent={AWSAccountsRowWrapper}
              showRowsSelection={false}
              headerColumns={headers}
              filterColumns={filterColumns}
              tableItems={accounts}
              defaultSortingColumnValue="status"
              persistenceKey="aws_accounts_manage"
            >
              <Hide lgDown>
                <Grid item>
                  <Button
                    disabled={numNotConfiguredAccounts === 0}
                    variant="contained"
                    color="primary"
                    startIcon={<LinkIcon />}
                    onClick={onLinkAccountClicked}
                  >
                    Link account
                  </Button>
                </Grid>
              </Hide>
            </FilterTable>
          </Box>
        </CardContent>
      </>
      <DeleteRoleDialog
        accountId={accountToDelete?.accountId}
        handleDelete={handleDeleteAccount}
        onClose={() => setAccountToDelete(null)}
      />
    </>
  );
};
