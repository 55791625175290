import { useState } from "react";

import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { Stepper } from "../../../../Components/Stepper";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useUserContext } from "../../../../Context/UserContext";
import { customersRoute } from "../../../../Navigation/core/utils";
import { getStepStatus } from "../Azure/utils";
import SnowflakeStepConnection from "./SnowflakeStepConnection";
import SnowflakeStepPermissions from "./SnowflakeStepPermissions";
import SnowflakeStepTestConnection from "./SnowflakeStepTestConnection";
import { generatePassword, type SnowflakeOnboardingRequest } from "./utils";

export default function SnowflakeOnboarding() {
  const history = useHistory();
  const customerId = useCustomerId();
  const { userModel, userRoles } = useUserContext();
  const [wasTestSuccessful, setWasTestSuccessful] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [attemptedStep2, setAttemptedStep2] = useState(false);
  const [snowflakeOnboardingRequest, setSnowflakeOnboardingRequest] = useState<SnowflakeOnboardingRequest>({
    generatedPassword: generatePassword(customerId === "iSS6YWqdJLeTdloOSdS9"),
    accountId: "",
    orgId: "",
    onboardedBy: userModel?.email ?? "",
  });
  const { isDoitEmployee } = useAuthContext();
  const permissionToOnboard = userRoles?.billingProfilesAdmin || isDoitEmployee;
  const showErrorSnackbar = useErrorSnackbar();

  return (
    <Box mt={6}>
      <Stepper
        loading={false}
        onCancel={() => history.push(customersRoute(customerId))}
        onSubmit={() => history.push(customersRoute(customerId))}
        nextButtonClickHandler={async (activeStep: number) => {
          if (!permissionToOnboard) {
            showErrorSnackbar("Permission Denied: Billing Profile Admin role required");
            return false;
          }
          if (activeStep === 1) {
            setAttemptedStep2(true);
            return snowflakeOnboardingRequest.orgId !== "" && snowflakeOnboardingRequest.accountId !== "";
          } else {
            setAttemptedStep2(false);
            return true;
          }
        }}
        steps={[
          {
            children: <SnowflakeStepPermissions />,
            label: "Requirements",
            order: 0,
            required: true,
            state: getStepStatus(0, currentStep),
          },
          {
            children: (
              <SnowflakeStepConnection
                attemptedStep2={attemptedStep2}
                setSnowflakeOnboardingRequest={setSnowflakeOnboardingRequest}
                snowflakeOnboardingRequest={snowflakeOnboardingRequest}
              />
            ),
            label: "Set up connection",
            order: 1,
            required: true,
            state: getStepStatus(1, currentStep),
          },
          {
            children: (
              <SnowflakeStepTestConnection
                wasTestSuccessful={wasTestSuccessful}
                setWasTestSuccessful={setWasTestSuccessful}
                onboardingRequest={snowflakeOnboardingRequest}
              />
            ),
            label: "Test connection",
            order: 2,
            required: true,
            state: wasTestSuccessful ? "complete" : currentStep === 3 ? "editing" : "incomplete",
            hideBackButton: wasTestSuccessful,
          },
        ]}
        getCurrentStep={setCurrentStep}
        submitButtonLabel="Done"
        contentSx={{
          mt: 3,
          mb: 3,
        }}
        footerMaxWidth={860}
        maxWidth={1000}
      />
    </Box>
  );
}
