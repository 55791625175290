import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export const getOnboardStatus = async ({ customerId, doc }: { customerId: string; doc: string }) => {
  const status = await getCollection(CustomerModel)
    .doc(customerId)
    .collection("cloudConnect")
    .doc(doc)
    .collection("GKEConnect")
    .doc("GKEOnboardStatus")
    .get();

  return status.asModelData();
};
