import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

export function stripControlledFields(
  inputModel: StructureApiServiceModelDescriptor<Member>,
  controlledInputs: string[]
) {
  const inputCopy = cloneDeep(inputModel);

  controlledInputs.forEach((field) => {
    if (field in inputCopy.members) {
      delete inputCopy.members[field];
    }
  });

  return inputCopy;
}
