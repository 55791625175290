import { createContext, type ReactNode, useContext } from "react";

import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import noop from "lodash/noop";

import { type AnalyticsMetadata, useAnalyticsMetadata } from "../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import useTransforms from "../Components/hooks/cloudAnalytics/useTransforms";
import { type Customer } from "../types";

export type AnalyticsContextType = {
  metadata: AnalyticsMetadata;
  hasDataHub: boolean;
  fetchMetadata: (withLabels?: boolean, withAttributionGroups?: boolean) => void;
  fetchAttributionGroupsMetadata: () => void;
  transforms: Record<string, any>;
  currency: CurrencyCode;
  hasEKSData?: boolean;
} & ({ loading: true; hasMetadata: undefined } | { loading: false; hasMetadata: boolean });

const analyticsContextDefaultValue: AnalyticsContextType = {
  metadata: [],
  loading: true,
  hasMetadata: undefined,
  hasDataHub: false,
  fetchMetadata: noop,
  fetchAttributionGroupsMetadata: noop,
  transforms: {},
  currency: CurrencyCodes.USD,
};

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export const useCloudAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalyticsMetadataContext must be used within a AnalyticsMetadataContextProvider");
  }

  return context;
};

export const AnalyticsContextProvider = ({
  children,
  customer,
  customerOrPresentationModeCustomer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
  customerOrPresentationModeCustomer: Customer | undefined | null;
}) => {
  const [metadata, loading, hasMetadata, hasDataHub, fetchMetadata, fetchAttributionGroupsMetadata, hasEKSData] =
    useAnalyticsMetadata(customer, customerOrPresentationModeCustomer);

  const [transforms, currency] = useTransforms();

  return (
    <AnalyticsContext.Provider
      value={
        {
          metadata,
          loading,
          hasMetadata,
          hasDataHub,
          fetchMetadata,
          fetchAttributionGroupsMetadata,
          transforms,
          currency,
          hasEKSData,
        } as AnalyticsContextType
      }
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsContextProviderForTesting = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: Partial<AnalyticsContextType>;
}) => {
  const actualValue = value ? value : analyticsContextDefaultValue;
  return <AnalyticsContext.Provider value={actualValue as AnalyticsContextType}>{children}</AnalyticsContext.Provider>;
};
