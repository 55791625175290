import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Button, Stack } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeData } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: NodeData;
};

const buttons = [
  { label: cloudflowTexts.PERFORM_ACTION, nodeType: CloudFlowNodeType.ACTION },
  { label: cloudflowTexts.SEND_FOR_APPROVAL, nodeType: CloudFlowNodeType.APPROVAL },
  { label: cloudflowTexts.CHECK_CONDITION, nodeType: CloudFlowNodeType.CONDITION },
  { label: cloudflowTexts.RUN_LOOP, nodeType: CloudFlowNodeType.LOOP },
];

export const ActionStepConfigurator = ({ id, data }: Props) => (
  <NodeWrapper
    title={data.name}
    status={data.status}
    isActive={false}
    onClick={noop}
    onDelete={data.onDeleteNode}
    disableHover={true}
  >
    <Stack gap={1} mb={-1} pl={1}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          onClick={() => data.onAddNode(button.nodeType, id, data.position)}
        >
          {button.label}
        </Button>
      ))}
    </Stack>
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </NodeWrapper>
);
