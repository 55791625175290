import { type CSSProperties, useCallback, useMemo, useState } from "react";

import { Rnd } from "react-rnd";
import { type DraggableAttributes } from "@dnd-kit/core";
import { type SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { type EmptyReportFallback } from "@doitintl/cmp-models";
import ResizeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicatorOutlined";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as Sentry from "@sentry/react";

import RollUpsCard from "../../Components/Dashboard/BigQueryLens/RollUps";
import SuperqueryTableTypeCard from "../../Components/Dashboard/BigQueryLens/TableTypeCard";
import RecommenderCard from "../../Components/Dashboard/RecommenderCard";
import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import { type DashboardsContextType } from "../../Context/DashboardContext";
import { GSuiteAssetCard } from "../../Pages/Assets/Cards/GSuiteAssetCard";
import { Office365AssetCard } from "../../Pages/Assets/Cards/Office365AssetCard";
import mixpanel from "../../utils/mixpanel";
import AccountManagersCard from "./AccountManagersCard";
import BudgetsCard from "./Analytics/BudgetsCard";
import CloudReportCard from "./Analytics/CloudReportCard";
import RecommendationsTable from "./BigQueryLens/RecommendationsTable";
import { SlotExplorer } from "./BigQueryLens/SlotExplorer";
import CloudIncidentsCard from "./CloudIncidentsCard";
import { CloudSpendSummaryCards } from "./CloudSpendSummaryCards";
import CommitmentContractsCard from "./CommitmentContractsCard";
import CostAnomaliesWidgetCard from "./CostAnomaliesWidgetCard";
import CreditsCard from "./CreditsCard";
import EmptyWidgetCard from "./EmptyWidgetCard";
import EntitiesCard from "./EntitiesCard";
import ErrorCard from "./ErrorCard";
import { FlexsaveCardAWS, FlexsaveCardGCP } from "./Flexsave/FlexsaveCard";
import { FlexsaveWidgetKey } from "./Flexsave/types";
import InvoicesCard from "./InvoicesCard";
import { LicensesCard } from "./LicensesCard";
import RampPlansWidgetCard from "./RampPlansWidgetCard";
import RenewalsCard from "./RenewalsCard";
import { ServiceLimitsAWS, ServiceLimitsGCP } from "./ServiceLimitsCard";
import SupportCard from "./SupportCard";

const useStyles = makeStyles((theme) => ({
  resizeHandleClass: {
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -14,
    marginTop: 128,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 2px 3px darkgrey",
  },
}));

const DragHandle = ({
  attributes,
  listeners,
}: {
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}) => (
  <div
    style={{
      position: "relative",
      top: 45,
      marginTop: -50,
      height: 50,
      cursor: "grab",
      textAlign: "right",
      width: "-webkit-fill-available",
    }}
  >
    <DragIndicatorIcon {...attributes} {...listeners} style={{ color: "#808080", marginTop: 8, marginRight: 8 }} />
  </div>
);

const ResizeHandler = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.resizeHandleClass}>
      <ResizeIcon style={{ color: theme.palette.text.primary, fontSize: 16 }} />
    </div>
  );
};

const ComponentsTypes = {
  accountManagers: AccountManagersCard,
  entities: EntitiesCard,
  invoices: InvoicesCard,
  commitmentContracts: CommitmentContractsCard,
  credits: CreditsCard,
  renewals: RenewalsCard,
  licenseGraph: LicensesCard,
  supportGraph: SupportCard,
  serviceLimits: ServiceLimitsAWS,
  serviceLimitsGoogleCloud: ServiceLimitsGCP,
  recommender: RecommenderCard,
  superqueryTableType: SuperqueryTableTypeCard,
  rollUps: RollUpsCard,
  bigQueryrecommendationsTable: RecommendationsTable,
  gsuiteAssetCard: GSuiteAssetCard,
  office365AssetCard: Office365AssetCard,
  cloudReports: CloudReportCard,
  budgets: BudgetsCard,
  costAnomalies: CostAnomaliesWidgetCard,
  slotExplorer: SlotExplorer,
  [FlexsaveWidgetKey.AWS]: FlexsaveCardAWS,
  [FlexsaveWidgetKey.GCP]: FlexsaveCardGCP,
  cloudSpendSummary: CloudSpendSummaryCards,
  activeCloudIncidents: CloudIncidentsCard,
  rampPlans: RampPlansWidgetCard,
};

const WidgetItem = ({
  widget,
  selectedIndex,
  index,
  isCustomizeMode,
  customEmptyReportMessage,
  widgetHeight,
}: {
  widget: DashboardsContextType["dashboards"][number]["widgets"][number];
  selectedIndex: number;
  index: number;
  isCustomizeMode: boolean;
  customEmptyReportMessage?: EmptyReportFallback; // NOTE: pulled by widget/report ID instead of pulling for all widgets.
  widgetHeight: number;
}) => {
  const [maxWidth, setMaxWidth] = useState<string | null>(null);
  const theme = useTheme();

  const { changeWidgetWidth, removeWidgetFromDashboard } = useCurrentDashboardContext();
  const childWithProps = useMemo(() => {
    const isTemplateWidget = widget.name.includes("::");

    const widgetBaseName = isTemplateWidget ? widget.name.substring(0, widget.name.indexOf("::")) : widget.name;
    const widgetId = isTemplateWidget ? widget.name.substring(widget.name.lastIndexOf(":") + 1) : widget.name;

    const Component = ComponentsTypes[widgetBaseName];
    const fallbackComponent = (
      <EmptyWidgetCard
        widgetHeight={widgetHeight}
        name={widgetBaseName}
        customMessageSettings={customEmptyReportMessage}
      />
    );

    if (!Component) {
      return fallbackComponent;
    }

    return (
      <Component
        raised={index === selectedIndex}
        isCustomizeMode={isCustomizeMode}
        fallbackComponent={fallbackComponent}
        widgetId={widgetId}
        customEmptyReportMessage={customEmptyReportMessage}
        widgetHeight={widgetHeight}
      />
    );
  }, [widgetHeight, customEmptyReportMessage, index, isCustomizeMode, selectedIndex, widget.name]);

  const gridProps = {
    item: true,
    xs: 12,
    md: 4,
  };

  if (widget.cardWidth) {
    gridProps.md = widget.cardWidth;
  }

  const newBreakPoint = (percentage: number) => {
    const breakpoints = [0.25, 0.33, 0.4166, 0.5, 0.66, 0.833, 1];
    const closest = breakpoints.reduce((prev, curr) =>
      Math.abs(curr - percentage) < Math.abs(prev - percentage) ? curr : prev
    );
    switch (closest) {
      case breakpoints[0]:
        return 3;
      case breakpoints[1]:
        return 4;
      case breakpoints[2]:
        return 5;
      case breakpoints[3]:
        return 6;
      case breakpoints[4]:
        return 8;
      case breakpoints[5]:
        return 10;
      case breakpoints[6]:
        return 12;
      default:
        return 4;
    }
  };

  const Enable = {
    bottom: false,
    bottomLeft: false,
    bottomRight: false,
    left: false,
    right: true,
    top: false,
    topLeft: false,
    topRight: false,
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled: !isCustomizeMode,
    id: widget.name,
  });

  const fallback = useCallback(({ error }) => <ErrorCard error={error} widget={widget} />, [widget]);

  const getWidgets = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Sentry.ErrorBoundary
        fallback={fallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        {isCustomizeMode && (
          <div
            style={{
              display: "flex",
              top: 10,
              right: 10,
              position: "absolute",
              zIndex: 90,
              justifyContent: "flex-end",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <DragHandle attributes={attributes} listeners={listeners} />

            <IconButton onClick={() => removeWidgetFromDashboard(widget.name)} aria-label="delete" size="small">
              <DeleteIcon />
            </IconButton>
          </div>
        )}

        {childWithProps}
      </Sentry.ErrorBoundary>
    </div>
  );

  const style: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const divStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onResizeStop = useCallback(
    async (name: string, widgetWidth: number) => {
      await changeWidgetWidth(widget.name, widgetWidth);
      setMaxWidth(null);
      mixpanel.track("dashboard.customize.resize");
    },
    [changeWidgetWidth, widget.name]
  );

  return (
    <Grid {...gridProps} ref={setNodeRef} style={{ maxWidth: maxWidth ?? undefined, ...divStyle }}>
      <Rnd
        style={style}
        size={{ width: "auto", height: "100%" }}
        disableDragging={true}
        resizeHandleComponent={{ right: <ResizeHandler /> }}
        resizeHandleStyles={{ right: { right: -2 } }}
        enableResizing={isCustomizeMode ? Enable : false}
        resizeGrid={[1, 1]}
        onResizeStart={() => {
          setMaxWidth("none");
        }}
        onResizeStop={async (e, direction, ref) => {
          const pixels = parseInt(ref.style.width);
          const screenWidth = ref.parentElement?.parentElement?.offsetWidth
            ? ref.parentElement.parentElement.offsetWidth
            : window.screen.width;
          const percentage = (screenWidth - pixels) / screenWidth;
          const widgetWidth = newBreakPoint(1 - percentage);
          gridProps.md = widgetWidth;
          await onResizeStop(widget.name, widgetWidth);
        }}
      >
        {getWidgets()}
      </Rnd>
    </Grid>
  );
};

export default WidgetItem;
