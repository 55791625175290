import { createContext, type ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";
import { type UnregisterCallback } from "history";
import noop from "lodash/noop";

import { UnsavedChangesPrompt } from "../Components/UnsavedChanges/UnsavedChangesPrompt";
import { useHotkeyContext } from "./HotkeyContext";

type ConfirmCallbackType = (() => Promise<void>) | (() => void);

type UnsavedChangesConfigType = {
  onConfirmCallback?: ConfirmCallbackType;
  onCancelCallback?: () => void;
  onSaveCallback?: () => Promise<boolean>;
  exceptionsPath?: (string | RegExp)[];
};

type UnsavedChangesContextType = {
  activatePendingPrompt: (config?: UnsavedChangesConfigType) => void;
  clearPendingPrompt: () => void;
  isDirty: boolean;
};

const unsavedChangesContext = createContext<UnsavedChangesContextType>({
  isDirty: false,
  activatePendingPrompt: noop,
  clearPendingPrompt: noop,
});

export const UnsavedChangesContextProvider = ({ children }: { children?: ReactNode }) => {
  const history = useHistory();
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();

  const [isDirty, setIsDirty] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [loading, setLoading] = useState(false);

  const unblock = useRef<UnregisterCallback>();
  const changesConfig = useRef<UnsavedChangesConfigType>();

  // Used for navigating to a different route
  useEffect(() => {
    // history.block lets you block navigation away from the current page
    unblock.current = history.block(({ pathname }) => {
      if (
        changesConfig.current?.exceptionsPath?.some((path) => {
          if (typeof path === "string") {
            // Exact match for string paths
            return path === pathname;
          } else {
            // Use RegExp.test() to match against regex paths
            return path.test(pathname);
          }
        })
      ) {
        setIsDirty(false);
        return;
      }
      // If the user opens a new tab, we don't show the prompt
      if (history.location.pathname === pathname) {
        return;
      }
      if (isDirty) {
        setCurrentPath(pathname);
        setShowPrompt(true);
        return false;
      }
    });
    return () => {
      unblock.current?.();
    };
  }, [history, isDirty]);

  // Used for refresh, back page button, tab close
  useEffect(() => {
    const beforeUnloadHandler = () => isDirty;

    const unloadHandler = async () => {
      await changesConfig.current?.onConfirmCallback?.();
      return isDirty;
    };

    window.onbeforeunload = isDirty ? beforeUnloadHandler : null;
    // eslint-disable-next-line deprecation/deprecation
    window.onunload = isDirty ? unloadHandler : null;

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      window.removeEventListener("onunload", unloadHandler);
    };
  }, [isDirty]);

  useEffect(() => {
    if (showPrompt) {
      setHotKeysDisabled();
    } else {
      setHotKeysEnabled();
    }
  }, [setHotKeysDisabled, setHotKeysEnabled, showPrompt]);

  const activatePendingPrompt = useCallback((config?: UnsavedChangesConfigType) => {
    changesConfig.current = config;
    setIsDirty(true);
  }, []);

  const clearPendingPrompt = useCallback(() => {
    setIsDirty(false);
    setShowPrompt(false);
  }, []);

  const onCancel = async () => {
    changesConfig.current?.onCancelCallback?.();
    setShowPrompt(false);
  };

  const onConfirm = useCallback(async () => {
    if (unblock) {
      unblock.current?.();
    }
    setLoading(true);
    await changesConfig.current?.onConfirmCallback?.();

    setShowPrompt(false);
    history.push(currentPath);
    setIsDirty(false);
    setLoading(false);
  }, [currentPath, history]);

  return (
    <unsavedChangesContext.Provider
      value={{
        isDirty,
        activatePendingPrompt,
        clearPendingPrompt,
      }}
    >
      {showPrompt && (
        <UnsavedChangesPrompt
          loading={loading}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onSave={changesConfig.current?.onSaveCallback}
        />
      )}
      {children}
    </unsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = (): UnsavedChangesContextType => useContext(unsavedChangesContext);
