import { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Divider, Stack, TextField, Typography } from "@mui/material";

import { type AwsResource, type ResourceDetailType } from "./resourceDetailType";

export const splitArn = (arn: string, assetIndex: string): AwsResource => {
  if (!arn) {
    return {
      arn: "",
      region: "",
      accountId: "",
      instanceId: "",
      assetIndex,
    };
  }

  const arnSubStrings = arn.split(":");
  return {
    arn,
    region: arnSubStrings[3] ?? "",
    accountId: arnSubStrings[4] ?? "",
    instanceId: arnSubStrings[5]?.split("/")[1] ?? arnSubStrings[6] ?? "",
    assetIndex,
  };
};
type Props = {
  index: number;
  values: AwsResource;
  onDelete: (index: string) => void;
  onChange: (index: string, data: ResourceDetailType) => void;
};

const AwsResourceDetails = ({ index, values, onDelete, onChange }: Props) => {
  const assetIndex = values.assetIndex;
  const [arn, setArn] = useState<string>(values?.arn ?? "");
  const [instanceId, setInstanceId] = useState<string>(values?.instanceId ?? "");
  const [region, setRegion] = useState<string>(values?.region ?? "");

  return (
    <Stack>
      {index > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
          data-cy={`awsResourceHeaderBox-${index}`}
        >
          <Typography>Resource {index + 1}</Typography>
          <Button
            onClick={() => onDelete(assetIndex)}
            data-cy={`awsResourceDeleteButton-${index}`}
            sx={{ mb: 0.5 }}
            color="error"
          >
            <DeleteIcon />
            Remove resource
          </Button>
        </Box>
      )}
      <TextField
        label="AWS ARN"
        InputLabelProps={{ shrink: true }}
        value={arn}
        onChange={(e) => {
          const newValue = e.target.value;
          if (!newValue) {
            setArn("");
            setRegion("");
            setInstanceId("");
            onChange(assetIndex, { assetIndex, arn, instanceId, region });
            return;
          }

          const splitArnObj = splitArn(newValue, assetIndex);
          setRegion(splitArnObj.region);
          setInstanceId(splitArnObj.instanceId);
          setArn(newValue);
          onChange(assetIndex, { assetIndex, arn: newValue, instanceId, region });
        }}
        size="small"
        sx={{ my: 1 }}
        data-cy={`awsArnField-${index}`}
      />
      <Divider>
        <Typography component="span" variant="subtitle1" color="textSecondary">
          OR
        </Typography>
      </Divider>
      <TextField
        label="Instance ID"
        InputLabelProps={{ shrink: true }}
        value={instanceId}
        onChange={(e) => {
          setInstanceId(e.target.value);
          onChange(assetIndex, { assetIndex, arn, instanceId: e.target.value, region });
        }}
        size="small"
        disabled={arn !== ""}
        sx={{ my: 1 }}
        data-cy={`instanceIdField-${index}`}
      />
      <TextField
        label="Region"
        InputLabelProps={{ shrink: true }}
        value={region}
        onChange={(e) => {
          setRegion(e.target.value);
          onChange(assetIndex, { assetIndex, arn, instanceId, region: e.target.value });
        }}
        size="small"
        disabled={arn !== ""}
        sx={{ my: 1 }}
        data-cy={`regionField-${index}`}
      />
    </Stack>
  );
};

export default AwsResourceDetails;
