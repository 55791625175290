import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";

export const EmptyAlertList = () => {
  const { customer } = useCustomerContext();
  const settingsUrl = `/customers/${customer.id}/notifications`;
  const costAnomaliesUrl = "https://help.doit.com/docs/governance/cloud-anomalies";

  return (
    <Stack alignItems="center" my="70px" spacing={2}>
      <Typography fontWeight={500} fontSize="24px" lineHeight="32px">
        No cost anomalies detected
      </Typography>
      <Typography fontSize="16px" lineHeight="24px">
        Great news, we haven’t detected any cost anomalies yet! When we do, they will be listed here.
      </Typography>
      <Typography fontSize="16px" lineHeight="24px">
        In the meantime, learn about{" "}
        <Link to={{ pathname: costAnomaliesUrl }} target="_blank">
          cost anomalies
        </Link>{" "}
        or review your <Link to={settingsUrl}>notification settings</Link>.
      </Typography>
    </Stack>
  );
};
