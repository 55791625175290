import { AppModel, InviteModel, PermissionModel, RoleModel, UserModel } from "@doitintl/cmp-models";
import { type DocumentSnapshotModel, getCollection, type ModelReference } from "@doitintl/models-firestore";
import type * as axios from "axios";

import { type Customer, type Invite, type Role, RoleCustomerType, type User } from "../../types";
import { increment } from "../../utils/firebase";

export const updateRoleInUse = async (
  oldRole?: Pick<Role, "type" | "ref" | "inUse"> | null,
  newRole?: Pick<Role, "type" | "ref">
) =>
  Promise.all([
    oldRole?.type === "custom" &&
      oldRole?.inUse > 0 &&
      (await getCollection(RoleModel)
        .doc(oldRole?.ref.id)
        .update({ inUse: increment(-1) })),
    newRole?.type === "custom" &&
      (await getCollection(RoleModel)
        .doc(newRole?.ref.id)
        .update({ inUse: increment(1) })),
  ]);

export const observeUserData = (
  id: User["id"],
  onSnapshotCallback: (snapshot: DocumentSnapshotModel<UserModel>) => void,
  onErrorCallback: () => void
) => getCollection(UserModel).doc(id).onSnapshot(onSnapshotCallback, onErrorCallback);

export const observeInviteData = (
  id: User["id"],
  onSnapshotCallback: (snapshot: DocumentSnapshotModel<InviteModel>) => void,
  onErrorCallback?: () => void
) => getCollection(InviteModel).doc(id).onSnapshot(onSnapshotCallback, onErrorCallback);

export const updateUserField = async (
  id: string,
  { isInvite, updateData }: { isInvite: boolean; updateData: Partial<Invite | User> }
) => {
  if (isInvite) {
    const inviteQuery = await getCollection(InviteModel).doc(id).get();

    return inviteQuery.modelRef.update(updateData as Partial<Invite>);
  }

  const userQuery = await getCollection(UserModel).doc(id).get();

  return userQuery.modelRef.update(updateData as Partial<User>);
};

export const getAllCustomerRoles = async (customer: Customer, isProductOnlyCustomer: boolean): Promise<Role[]> => {
  let presetRolesQuery = getCollection(RoleModel).where("type", "==", "preset").where("customer", "==", null);

  if (!isProductOnlyCustomer) {
    presetRolesQuery = presetRolesQuery.where("customerType", "!=", RoleCustomerType.STANDALONE);
  }
  const customRolesQuery = getCollection(RoleModel).where("type", "==", "custom").where("customer", "==", customer.ref);

  const [allRolesQuery, customerRolesQuery] = await Promise.all([
    await presetRolesQuery.get(),
    await customRolesQuery.get(),
  ]);

  const allRoles = allRolesQuery.docs.map(
    (roleDoc): Role => ({
      ...roleDoc.asModelData(),
      id: roleDoc.id,
      ref: roleDoc.modelRef,
    })
  );

  const customerRoles = customerRolesQuery.docs.map(
    (roleDoc): Role => ({
      ...roleDoc.asModelData(),
      id: roleDoc.id,
      ref: roleDoc.modelRef,
    })
  );

  return allRoles.concat(customerRoles).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

export const getPermissionsNamesOfRole = async (permissionsRefs: ModelReference<PermissionModel>[] = []) =>
  Promise.all(
    permissionsRefs.map(async (permissionsRef) => {
      const permissionQuery = await getCollection(PermissionModel).doc(permissionsRef.id).get();
      const permission = permissionQuery.data() as { title: string };

      return permission.title;
    })
  );

export const getUserNotifications = async () => {
  const userNotificationsQuery = await getCollection(AppModel).doc("userNotifications").get();
  return userNotificationsQuery.asModelData()?.notifications ?? [];
};

export const getUserIdByEmail = async (email: string) => {
  const userQuery = await getCollection(UserModel).where("email", "==", email).get();

  return userQuery.docs[0].id;
};

export const updateFirebaseDisplayName = async (
  email: string,
  displayName: string,
  api: axios.AxiosInstance,
  customerId: string
): Promise<void> => {
  await api.request({
    method: "post",
    data: { email, displayName, customerId },
    url: "/v1/users/updateDisplayName",
  });
};
