import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type InvoiceFAQCategory } from "./InvoicesFAQ";

type Props = {
  category: InvoiceFAQCategory;
  text: string;
  onCategoryClicked: (category: InvoiceFAQCategory) => void;
};

export const FaqCategory = ({ text, category, onCategoryClicked }: Props) => (
  <Stack
    onClick={() => onCategoryClicked(category)}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    py={0.5}
    px={2}
    sx={{
      cursor: "pointer",
      ":hover": {
        backgroundColor: (theme) => theme.palette.action.hover,
      },
    }}
  >
    <Typography variant="subtitle1" fontWeight={500}>
      {text}
    </Typography>
    <Box py={1}>
      <KeyboardArrowRightIcon />
    </Box>
  </Stack>
);
