import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Divider, Tooltip, Typography } from "@mui/material";

import { type CategoryContext, type CategoryItemContext, type HeaderDropdownMenuItem } from "../../../types";
import HeaderMenuItem from "../HeaderMenuItem";
import { SettingsHeader } from "./SettingsHeader";
import { getCategoryItemsByPageId } from "./utils";

type Props = {
  settingsCategory: CategoryContext;
};

export const SettingsMenu = ({ settingsCategory }: Props) => {
  const history = useHistory();

  const menuItems = useMemo(() => {
    const buildSettingsSection = (title: string, subtitle: string, categoryItems: CategoryItemContext[]) => {
      let resultItems: HeaderDropdownMenuItem[] = [];

      resultItems.push({
        type: "component",
        component: <SettingsHeader title={title} subtitle={subtitle} />,
      });

      resultItems = resultItems.concat(
        categoryItems.map((item) => ({
          component: (
            <Typography sx={{ color: "navigation.subItemColor" }} variant="body2">
              {item.displayName}
            </Typography>
          ),
          handler: () => history.push(item.fullRoute),
          route: item.fullRoute,
          dataCy: item.displayName,
        }))
      );

      return resultItems;
    };

    let items: HeaderDropdownMenuItem[] = [];
    const accountSection = getCategoryItemsByPageId(settingsCategory, [
      "settings:general",
      "notifications",
      "iam:sso",
      "iam:users",
      "settings:account-managers",
      "integrations:zapier",
      "datasets",
    ]);
    const cloudSection = getCategoryItemsByPageId(settingsCategory, ["assets", "settings:aws", "settings:gcp"]);

    if (accountSection.length > 0) {
      items = items.concat(buildSettingsSection("Account settings", "Manage account settings", accountSection));
    }

    if (cloudSection.length > 0) {
      items.push({
        type: "component",
        component: (
          <Box px={2} py={1}>
            <Divider />
          </Box>
        ),
      });
      items = items.concat(buildSettingsSection("Cloud settings", "Manage access to cloud accounts", cloudSection));
    }

    return items;
  }, [history, settingsCategory]);

  return (
    <HeaderMenuItem
      placement="top-end"
      isSelected={settingsCategory.isSelected}
      innerMenuItemComponent={
        <Tooltip title="Settings" placement="bottom">
          <SettingsIcon />
        </Tooltip>
      }
      items={menuItems}
      id="settings-menu"
    />
  );
};
