import { useEffect, useState } from "react";

import {
  type ApiServiceModelDescriptor,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import * as yup from "yup";

export function useApiActionParametersSchema(inputModel: UnwrappedApiServiceModelDescriptor) {
  const [validationSchema, setValidationSchema] = useState(generateApiActionParametersSchema(inputModel));

  useEffect(() => setValidationSchema(generateApiActionParametersSchema(inputModel)), [inputModel]);

  return validationSchema;
}

export type ApiActionParametersSchema = yup.Schema<any, any, any, "" | "d">;

export function generateApiActionParametersSchema(
  model: UnwrappedApiServiceModelDescriptor,
  label?: string,
  isRequired?: boolean
): ApiActionParametersSchema {
  switch (model.type) {
    case ModelType.STRING: {
      let schema = yup.string();
      if (label) {
        schema = schema.label(label);
      }
      if (model.minLength !== undefined) {
        schema = schema.max(model.minLength);
      }
      if (model.maxLength !== undefined) {
        schema = schema.max(model.maxLength);
      }
      if (model.pattern !== undefined) {
        schema = schema.matches(new RegExp(model.pattern));
      }
      if (model.enum !== undefined) {
        schema = schema.oneOf(model.enum);
      }
      if (isRequired) {
        return schema.required().default("");
      }

      return schema;
    }

    case ModelType.BOOLEAN: {
      let schema = yup.boolean();
      if (label) {
        schema = schema.label(label);
      }
      return schema;
    }

    case ModelType.INTEGER: {
      let schema = yup.number().integer();
      if (label) {
        schema = schema.label(label);
      }
      if (model.min !== undefined) {
        schema = schema.min(model.min);
      }
      if (model.max !== undefined) {
        schema = schema.max(model.max);
      }
      if (isRequired) {
        return schema.required().default(null);
      }
      return schema;
    }

    case ModelType.FLOAT: {
      let schema = yup.number();
      if (label) {
        schema = schema.label(label);
      }
      if (model.min !== undefined) {
        schema = schema.min(model.min);
      }
      if (model.max !== undefined) {
        schema = schema.max(model.max);
      }
      if (isRequired) {
        return schema.required().default(null);
      }
      return schema;
    }

    case ModelType.TIMESTAMP: {
      let schema: yup.Schema;

      if ([undefined, "X", "x"].includes(model.timestampFormat)) {
        schema = yup.number().integer();
        if (isRequired) {
          schema = schema.required().default(null);
        }
      } else {
        schema = yup.string();
        if (isRequired) {
          schema = schema.required().default("");
        }
      }

      if (label) {
        schema = schema.label(label);
      }
      return schema;
    }

    case ModelType.LIST: {
      let schema = yup.array().of(generateApiActionParametersSchema(model.member.model, model.memberName));
      if (label) {
        schema = schema.label(label);
      }
      if (model.min !== undefined) {
        schema = schema.min(model.min);
      }
      if (model.max !== undefined) {
        schema = schema.min(model.max);
      }
      return schema;
    }

    case ModelType.STRUCTURE: {
      let schema = yup.object(
        Object.fromEntries(
          Object.entries(model.members).map(([memberName, member]) => [
            memberName,
            generateApiActionParametersSchema(member.model, memberName, model.requiredMembers?.includes(memberName)),
          ])
        )
      );
      if (label) {
        schema = schema.label(label);
      }
      return schema;
    }
    default:
      throw new Error("schema generation error");
  }
}

export function getInitialValue(model: ApiServiceModelDescriptor) {
  switch (model.type) {
    case ModelType.STRING:
      return "";
    case ModelType.STRUCTURE:
      return {};
    case ModelType.LIST:
      return [];
    case ModelType.INTEGER:
    case ModelType.FLOAT:
    case ModelType.TIMESTAMP:
      return null;
    case ModelType.BOOLEAN:
      return false;
  }
}
