import { useEffect, useMemo, useState } from "react";

import { makeStyles } from "@mui/styles";
import { getCollection } from "@doitintl/models-firestore";
import { DashboardModel } from "@doitintl/cmp-models";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";

import DownloadIcon from "@mui/icons-material/GetAppRounded";
import { DateTime } from "luxon";
import { red } from "@mui/material/colors";
import orderBy from "lodash/orderBy";
import { formatCurrency, getCurrencyLabelByCode } from "../../utils/common";
import { useUserContext } from "../../Context/UserContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { SkeletonCard } from "./SkeletonCard";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    paddingBottom: 0,
    height: 201,
  },
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  invoiceTitle: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  invoiceTitleSelected: {},
  cardHeaderAction: {
    marginTop: 0,
  },
  chip: {
    backgroundColor: red[200],
    color: theme.palette.getContrastText(red[200]),
    marginLeft: theme.spacing(0.5),
  },
}));

const InvoicesCard = ({ raised, isCustomizeMode, fallbackComponent, widgetHeight = 1 }) => {
  const classes = useStyles();
  const { entities } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const [invoices, setInvoices] = useState();
  const [invoicesOverdue, setInvoicesOverdue] = useState();
  const [type, setType] = useState(1);
  const [totals, setTotals] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!userRoles.invoicesViewer) {
      setInvoices([]);
      setInvoicesOverdue([]);
      setTotals({});
      return;
    }

    const promises = [
      ...entities.map((e) =>
        getCollection(DashboardModel).doc("invoices-latest").collection("invoicesLatest").doc(e.id).get()
      ),
      ...entities.map((e) =>
        getCollection(DashboardModel).doc("invoices-overdue").collection("invoicesOverdue").doc(e.id).get()
      ),
    ];

    Promise.all(promises).then((snapshots) => {
      let invoices = [];
      let invoicesOverdue = [];

      for (const docSnapshot of snapshots) {
        const customer = docSnapshot.get("customer");
        const entity = docSnapshot.get("entity");
        if (docSnapshot.exists()) {
          const entityInvoices = (docSnapshot.get("invoices") || []).map((d) => ({
            ...d,
            customer,
            entity,
          }));

          if (docSnapshot.ref.parent?.id === "invoicesLatest") {
            invoices = invoices.concat(entityInvoices);
          } else {
            invoicesOverdue = invoicesOverdue.concat(entityInvoices);
          }
        }
      }

      invoices = orderBy(invoices, ["date", "id"], ["desc", "desc"]);
      invoicesOverdue = orderBy(invoicesOverdue, ["date", "id"], ["desc", "desc"]);

      const totals = invoicesOverdue.reduce((memo, invoice) => {
        // eslint-disable-next-line no-prototype-builtins
        if (memo.hasOwnProperty(invoice.code)) {
          memo[invoice.code] += invoice.debit;
        } else {
          memo[invoice.code] = invoice.debit;
        }
        return memo;
      }, {});

      setInvoices(invoices);
      setInvoicesOverdue(invoicesOverdue);
      setTotals(totals);
      setType(invoicesOverdue.length > 0 ? 2 : 1);
    });
  }, [entities, userRoles.invoicesViewer]);

  const getTitles = useMemo(
    () => (
      <div>
        {invoicesOverdue && invoicesOverdue.length > 0 && (
          <span>
            <span
              className={type === 2 ? classes.invoiceTitleSelected : classes.invoiceTitle}
              onClick={() => setType(2)}
            >
              Overdue Payments
            </span>
            <span> | </span>
          </span>
        )}
        <span className={type === 1 ? classes.invoiceTitleSelected : classes.invoiceTitle} onClick={() => setType(1)}>
          Latest Invoices
        </span>
      </div>
    ),
    [classes.invoiceTitle, classes.invoiceTitleSelected, invoicesOverdue, type]
  );

  const getAction = useMemo(
    () =>
      type === 2
        ? Object.keys(totals).map((key, i) => (
            <Tooltip key={i} title={`Total ${getCurrencyLabelByCode(key)} overdue payments`}>
              <Chip size="small" className={classes.chip} label={formatCurrency(totals[key], key)} />
            </Tooltip>
          ))
        : null,
    [classes.chip, totals, type]
  );

  useEffect(() => {
    switch (widgetHeight) {
      case 2: {
        setRowsPerPage(9);
        break;
      }
      case 1.5: {
        setRowsPerPage(6);
        break;
      }
      case 1:
      default: {
        setRowsPerPage(3);
      }
    }
  }, [widgetHeight]);

  const data = type === 1 ? invoices : invoicesOverdue;
  const emptyRows = data ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) : 0;

  if (data && data.length === 0) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Grid>
      <Card raised={raised} className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={getTitles}
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader={type === 1 ? "Last 12 invoices" : "Invoices past their pay date"}
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
          action={!isCustomizeMode && getAction}
          classes={{
            action: classes.cardHeaderAction,
          }}
        />

        <CardContent
          style={{
            height: 200 * widgetHeight,
          }}
          className={classes.cardContent}
        >
          {data && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell padding="none" align="left" size="small" />
                  <TableCell padding="none" size="small">
                    Invoice Date
                  </TableCell>
                  <TableCell padding="none" size="small">
                    Invoice
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {type === 1 ? "Total" : "Balance"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invoice) => {
                  const invoiceDate = DateTime.fromISO(invoice.date);
                  return (
                    <TableRow key={invoice.id} className={classes.tableRow} hover>
                      <TableCell padding="none" align="left" size="small">
                        {invoice.url && (
                          <IconButton
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                            component="a"
                            href={invoice.url}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell padding="none" size="small">
                        {invoiceDate.toLocaleString(DateTime.DATE_MED)}
                      </TableCell>
                      <TableCell padding="none" size="small">
                        <Link
                          component={RouterLink}
                          to={`/customers/${invoice.customer.id}/invoices/${invoice.entity.id}/${invoice.id}`}
                          variant="body2"
                          color="inherit"
                          sx={{ textDecoration: "none" }}
                        >
                          {invoice.id}
                        </Link>
                      </TableCell>
                      <TableCell padding="none" size="small">
                        {type === 1
                          ? formatCurrency(invoice.sum, invoice.code)
                          : formatCurrency(invoice.debit, invoice.code)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 32 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[rowsPerPage]}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onPageChange={(_event, page) => setPage(page)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </CardContent>
        <div />
      </Card>
    </Grid>
  );
};

export default InvoicesCard;
