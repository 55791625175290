import { useCallback } from "react";

import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider, FormGroup, IconButton, Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { useCustomerData } from "../../../../../../Components/hooks/useCustomerData";
import { CustomerNotes } from "../../../../../Customer/CustomerNotes/CustomerNotes";
import { formatAsDollars } from "../../../../utils/costUtils";
import { toggleBulkExclusion } from "../../db";
import { type CustomerAggregatedPurchaseItem } from "../../types";
import { getFormattedCoverage } from "../../utils/common";
import { CustomerInformation } from "./CustomerInformation";
import { CustomerNotesIcon } from "./CustomerNotesIcon";

type Props = {
  aggregatedPurchase: CustomerAggregatedPurchaseItem;
};

export const PurchaseDetailsHeader = ({ aggregatedPurchase }: Props) => {
  const history = useHistory();
  const customerData = useCustomerData(aggregatedPurchase.customerId);
  const returnToCustomersPage = useCallback(() => history.push("./"), [history]);
  const handleBulkRecommendationUpdate = useCallback(
    async (checked: boolean) => {
      await toggleBulkExclusion(aggregatedPurchase.customerId, !checked);
    },
    [aggregatedPurchase]
  );

  return (
    <>
      <Stack direction="column" py={3}>
        <Stack direction="row" alignItems="center" ml={-1}>
          <IconButton
            aria-label="Back to customers list"
            onClick={returnToCustomersPage}
            data-cy="backToCustomersButton"
            sx={{ color: "text.primary" }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">{aggregatedPurchase?.primaryDomain}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-start" py={3} gap={3}>
          <CustomerInformation customerInfoLabel="Customer type" customerInfoValue={aggregatedPurchase?.customerType} />
          <CustomerInformation
            customerInfoLabel="Coverage"
            customerInfoValue={getFormattedCoverage(aggregatedPurchase?.currentOverallCoverage || 0)}
          />
          <CustomerInformation
            customerInfoLabel="FlexCUDs"
            customerInfoValue={
              aggregatedPurchase?.flexCud ? `${formatAsDollars(aggregatedPurchase?.flexCud, 2)}/hr` : undefined
            }
          />
          <FormGroup sx={{ mt: -1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!aggregatedPurchase.excludeCustomerFromBulk}
                  onChange={(event) => handleBulkRecommendationUpdate(event.target.checked)}
                  value="allowToEdit"
                  color="primary"
                />
              }
              label="Display recommendations in bulk purchase"
            />
          </FormGroup>
          <CustomerNotesIcon customerData={customerData} />
        </Stack>
        <Divider />
      </Stack>
      {!!customerData && <CustomerNotes customer={customerData} defaultTag="flexsave" />}
    </>
  );
};
