import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";
import upperCase from "lodash/upperCase";

import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type AlgoliaPlugin, type InvoiceHit } from "../types";
import {
  composeInvoicesSubtitle,
  filterByCustomer,
  getAlgoliaAnalyticsProps,
  getIconByProducts,
  isSearchForCustomer,
  partsToString,
} from "../utils";

const createInvoicesPlugin: AlgoliaPlugin<InvoiceHit> = () => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const invoiceSource: AutocompleteSource<InvoiceHit> = {
      sourceId: "invoices",
      templates: {
        item({ item }) {
          const titleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["IVNUM"],
          });

          return (
            <AlgoliaResultListItem
              highlightedTitle={titleParts}
              highlightedSubtitle={composeInvoicesSubtitle(item)}
              avatarLogo={getIconByProducts(context.isDarkMode, item.PRODUCTS)}
              avatarFallback={`
              ${upperCase(item.IVNUM?.substring(0, 1))}
              ${upperCase(item.CUSTNAME?.substring(0, 1))}`}
            />
          );
        },
      },
      onSelect({ item, event }) {
        const url = `/customers/${item.customerId}/invoices/${item.entityId}/${item.IVNUM}`;
        context.onSelectRow({
          id: item.objectID,
          category: "invoices",
          label: item.objectID,
          avatarLogo: getIconByProducts(context.isDarkMode, item.PRODUCTS),
          highlightedTitle: [{ value: item.IVNUM, isHighlighted: false }],
          highlightedSubtitle: [{ value: partsToString(composeInvoicesSubtitle(item)), isHighlighted: false }],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              ...getAlgoliaAnalyticsProps(context),
              indexName: "invoices",
              query,
              params: {
                hitsPerPage: 10,
              },
              filters: filterByCustomer(context),
            },
          ],
          transformResponse({ hits }: { hits: Hit<InvoiceHit>[][] }) {
            return hits.map((indexResults) =>
              indexResults.filter(
                (hit) => hit.customerId !== undefined && hit.entityId !== undefined && hit.IVNUM !== undefined
              )
            );
          },
        });
      },
      getItemUrl({ item }) {
        return `/customers/${item.customerId}/invoices/${item.entityId}/${item.IVNUM}`;
      },
    };
    return [invoiceSource];
  },
});

export const useInvoicesPlugin = (
  restrictedIndices?: AlgoliaIndexType[]
): AutocompletePlugin<InvoiceHit, undefined> | null =>
  useMemo(() => {
    if (restrictedIndices?.includes("invoices")) {
      return null;
    }
    return createInvoicesPlugin();
  }, [restrictedIndices]);
