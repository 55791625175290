import { type MouseEventHandler, useMemo, useState } from "react";

import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { type DateTime } from "luxon";

import { useFullScreen } from "../../../utils/dialog";
import { type KnownIssue } from "../types";
import { formatDateToKnownIssueDate } from "../utils";
import { sampleKnownIssue } from "./consts";
import { KnownIssueDialogContent, type KnownIssueDialogContentProps } from "./KnownIssueDialogContent";
import { KnownIssueDialogFooter, type KnownIssueDialogFooterProps } from "./KnownIssueDialogFooter";
import { KnownIssueDialogHeader } from "./KnownIssueDialogHeader";

export type KnownIssueDialogProps = {
  open: boolean;
  productsSuggestions: KnownIssueDialogContentProps["productsSuggestions"];
  title: string;
  onClose: MouseEventHandler;
  onKnownIssueSubmit: (knownIssueData: KnownIssue) => Promise<void>;
  submitButtonText: KnownIssueDialogFooterProps["submitButtonText"];
  knownIssueData?: KnownIssue;
};

export const KnownIssueDialog = ({
  open,
  productsSuggestions,
  title,
  onClose,
  onKnownIssueSubmit,
  submitButtonText,
  knownIssueData,
}: KnownIssueDialogProps) => {
  const { fullScreen } = useFullScreen();

  const [knownIssueFormData, setKnownIssueFormData] = useState(knownIssueData ?? sampleKnownIssue);

  const isKnownIssueFormDataValidCheck = (knownIssueFormData: Partial<KnownIssue>): knownIssueFormData is KnownIssue =>
    Boolean(
      knownIssueFormData.title &&
        knownIssueFormData.affectedProduct &&
        knownIssueFormData.dateTime &&
        knownIssueFormData.platform &&
        knownIssueFormData.status
    );

  const isKnownIssueFormDataValid = useMemo(
    () => isKnownIssueFormDataValidCheck(knownIssueFormData),
    [knownIssueFormData]
  );

  const handleDateChange = (dateValue: DateTime) => {
    const dateTime = formatDateToKnownIssueDate(dateValue.toJSDate());
    setKnownIssueFormData({ ...knownIssueFormData, dateTime });
  };

  const handleProductChange = (input: string) =>
    setKnownIssueFormData({ ...knownIssueFormData, affectedProduct: input });

  return (
    <Dialog fullScreen={fullScreen} aria-labelledby="known-issue-dialog" open={open} maxWidth="sm" fullWidth>
      <KnownIssueDialogHeader title={title} onClose={onClose} />
      <KnownIssueDialogContent
        productsSuggestions={productsSuggestions}
        knownIssueFormData={knownIssueFormData}
        onFormInputChange={(input) => {
          setKnownIssueFormData({ ...knownIssueFormData, [input.target.name]: input.target.value });
        }}
        onProductChange={handleProductChange}
        onDateChange={handleDateChange}
      />
      <Divider />
      <KnownIssueDialogFooter
        onSubmit={async () => {
          if (!isKnownIssueFormDataValidCheck(knownIssueFormData)) {
            return;
          }
          await onKnownIssueSubmit(knownIssueFormData);
        }}
        onClose={onClose}
        submitButtonText={submitButtonText}
        isSubmitAllowed={isKnownIssueFormDataValid}
      />
    </Dialog>
  );
};
