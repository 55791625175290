import { useEffect, useState } from "react";

import { CustomerModel, type CustomerModelJiraInstancesModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerContext } from "../../../Context/CustomerContext";

export const useJiraApi = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const [jiraInstanceData, setJiraInstanceData] = useState<CustomerModelJiraInstancesModel | null | undefined>(
    undefined
  );

  const createJiraInstance = async (url: string) =>
    api.request({
      method: "POST",
      url: `/v1/customers/${customer.id}/jira/instances`,
      data: {
        url,
        deleteIfExistingPendingInstance: true,
      },
    });

  useEffect(
    () =>
      getCollection(CustomerModel)
        .doc(customer.id)
        .collection("jiraInstances")
        .limit(1)
        .onSnapshot((snapDoc) => {
          if (snapDoc.empty) {
            setJiraInstanceData(null);
            return;
          }

          snapDoc.docs.forEach((doc) => {
            const data = doc.asModelData();
            setJiraInstanceData(data);
          });
        }),
    [customer.id]
  );

  return {
    jiraInstanceData,
    createJiraInstance,
  };
};
