import { type JSX, useEffect, useMemo } from "react";

import { useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { TierPackageTypes } from "@doitintl/cmp-models";
import { Box, StyledEngineProvider } from "@mui/material";
import * as Sentry from "@sentry/react";

import { AVADialog } from "../../Components/Ava/Messenger/AVADialog";
import ErrorBoundaryFallback from "../../Components/ErrorBoundaryFallback";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useFirestoreContext } from "../../Context/FirestoreContext";
import { useTier } from "../../Context/TierProvider";
import { CustomerNotes } from "../../Pages/Customer/CustomerNotes/CustomerNotes";
import { useEKSClusters } from "../../Pages/EKS/hooks";
import { hasUnconfirmedClusters } from "../../Pages/EKS/utils";
import { useFullScreen } from "../../utils/dialog";
import { getCurrentCategories } from "../core/categories";
import { Header } from "./Header/Header";
import { RealTimeNotifications } from "./Header/InAppNotifications/RealTimeNotifications";
import KeyboardShortcuts from "./Header/KeyboardShortcuts/KeyboardShortcuts";
import { ClientSuspendedOrTerminatedAlert } from "./HeaderAlerts/ClientSuspendedAlert";
import { EKSAlert } from "./HeaderAlerts/EKSAlert";
import { LegacySsoAlert } from "./HeaderAlerts/LegacySsoAlert";
import { PayOverdueAlert } from "./HeaderAlerts/PayOverdueAlert";
import { ScheduledPriorityMaintenanceAlert } from "./HeaderAlerts/ScheduledMaintenanceAlert";
import TierTrialEndAlert from "./HeaderAlerts/TrialEnd/TierTrialEndAlert";
import { TrialEndAlert } from "./HeaderAlerts/TrialEnd/TrialEndAlert";
import { useCurrentPageIdInfo, useLoginContext, useNavConfig, useRoutesConfig } from "./hooks";
import NavTabs from "./NavTabs/NavTabs";
import { PageContent } from "./PageContent";
import { SideNavigationLayout } from "./ThirdLevelNav/SideNavigationLayout";
import { thirdLevelItemsFromCategory } from "./ThirdLevelNav/utils";
import { useNavigationVariant } from "./variantHooks";

type Props = {
  onSignOut: any;
  children: JSX.Element;
};

export const MainLayout = ({ onSignOut, children }: Props) => {
  const { firestoreInitialized } = useFirestoreContext();
  const loginContext = useLoginContext();
  const routesConfig = useRoutesConfig();
  const currentPageInfo = useCurrentPageIdInfo(routesConfig);
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext();
  const [clusters] = useEKSClusters();
  const showEKSAlert = useMemo(() => !!clusters && hasUnconfirmedClusters(clusters), [clusters]);
  const { isActiveTrialCustomer } = useTier();
  const { isMobile } = useFullScreen();
  useEffect(() => {}, [
    firestoreInitialized,
    loginContext,
    currentPageInfo,
    customer,
    customerOrPresentationModeCustomer,
    isDoitEmployee,
    clusters,
    showEKSAlert,
    isActiveTrialCustomer,
    isMobile,
  ]);

  // decide about the variant using context and screen resolution
  const navigationVariant = useNavigationVariant();

  const { navCategories } = useNavConfig();
  const categories = useMemo(() => {
    if (routesConfig) {
      return getCurrentCategories(routesConfig, navCategories, loginContext, currentPageInfo?.pageId);
    }
  }, [routesConfig, navCategories, loginContext, currentPageInfo?.pageId]);

  const firstLevelCategory = useMemo(() => {
    if (categories) {
      return categories.firstLevel.find((category) => category.isSelected);
    }
  }, [categories]);

  const thirdLevelData = useMemo(() => {
    if (categories?.secondLevel && currentPageInfo?.pageId) {
      return thirdLevelItemsFromCategory(currentPageInfo?.pageId, categories.secondLevel);
    }
  }, [categories, currentPageInfo?.pageId]);

  // for MVP we will have different background color for dashboards
  const backgroundColor = useMemo(() => {
    const specialPages = new Map([
      ["home-page", "general.backgroundDark"],
      ["cloudflow", "general.backgroundDark"],
    ]);
    const color = specialPages.get(`${currentPageInfo?.pageId}`);
    if (color) {
      return color;
    }

    if (firstLevelCategory?.displayName === "Dashboards") {
      return "general.backgroundDark";
    }
    return "background.paper";
  }, [currentPageInfo?.pageId, firstLevelCategory?.displayName]);

  const presentationModeActive = !!customer?.presentationMode?.enabled && !!currentPageInfo?.presentationModeSupported;
  const { isAvaDialogOpen, handleCloseAvaDialog, dashboardId } = useAvaDialogContext();

  if (!firestoreInitialized) {
    return null;
  }

  const customerOnNavigatorTrial = isActiveTrialCustomer(TierPackageTypes.NAVIGATOR);
  const customerOnSolveTrial = isActiveTrialCustomer(TierPackageTypes.SOLVE);

  return (
    <Box minHeight="100vh" bgcolor={backgroundColor}>
      {loginContext && categories && (
        <>
          <ClientSuspendedOrTerminatedAlert />
          <ScheduledPriorityMaintenanceAlert hide={navigationVariant === "small"} />
          <PayOverdueAlert hide={navigationVariant === "small"} pageId={currentPageInfo?.pageId} />
          <EKSAlert hide={!showEKSAlert || navigationVariant === "small"} />
          <LegacySsoAlert hide={navigationVariant === "small"} pageId={currentPageInfo?.pageId} />
          {loginContext.customerState?.trialEndDate && (
            <TrialEndAlert
              trialEndDate={loginContext.customerState.trialEndDate}
              showShortMessage={navigationVariant === "small"}
            />
          )}
          {customer && customerOrPresentationModeCustomer && <RealTimeNotifications />}

          {(customerOnNavigatorTrial || customerOnSolveTrial) && (
            <TierTrialEndAlert
              navigatorTrialStartDate={
                customerOnNavigatorTrial ? loginContext.customerState?.navigatorTierTrialStartDate : undefined
              }
              navigatorTrialEndDate={
                customerOnNavigatorTrial ? loginContext.customerState?.navigatorTierTrialEndDate : undefined
              }
              solveTrialStartDate={
                customerOnSolveTrial ? loginContext.customerState?.solveTierTrialStartDate : undefined
              }
              solveTrialEndDate={customerOnSolveTrial ? loginContext.customerState?.solveTierTrialEndDate : undefined}
            />
          )}
          <StyledEngineProvider injectFirst>
            {isAvaDialogOpen && !isMobile && <AVADialog handleClose={handleCloseAvaDialog} dashboardId={dashboardId} />}
            <Header
              presentationModeActive={presentationModeActive}
              variant={navigationVariant}
              categoriesContext={categories.firstLevel}
              onSignOut={onSignOut}
            >
              {categories && firstLevelCategory && (
                <StyledEngineProvider injectFirst>
                  <NavTabs
                    presentationModeActive={presentationModeActive}
                    isMobile={navigationVariant === "small" || navigationVariant === "smallWithOptions"}
                    isTrialMode={Boolean(loginContext.customerState?.trialEndDate)}
                    categoryContext={firstLevelCategory}
                  />
                </StyledEngineProvider>
              )}
            </Header>
            <KeyboardShortcuts />
          </StyledEngineProvider>
        </>
      )}
      <Box pt={1} px={2}>
        <Sentry.ErrorBoundary
          fallback={({ error }: { error: any }) => {
            const shouldShowError = loginContext?.isDoitEmployee || !process.env.REACT_APP_SENTRY_RELEASE;
            return <ErrorBoundaryFallback error={error} shouldShowError={shouldShowError} />;
          }}
        >
          <Box display="flex">
            <Box width="100%">
              <SideNavigationLayout data={thirdLevelData} title={categories?.secondLevel?.displayName}>
                <PageContent loginContext={loginContext} routesConfig={routesConfig}>
                  {children}
                </PageContent>
              </SideNavigationLayout>
            </Box>
            {isDoitEmployee && customer && customerOrPresentationModeCustomer && <CustomerNotes customer={customer} />}
          </Box>
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  );
};
