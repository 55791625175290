import { useEffect, useState } from "react";

import { EntityModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";

type Entities = {
  entities: Entity[];
  entitiesLoading: boolean;
};

const EMPTY_COLLECTION = [];

export function useEntitiesOrPresentationModeEntities(): Entities {
  const {
    entities: genuineEntities,
    entitiesLoading: genuineEntitiesLoading,
    customerOrPresentationModeCustomer,
    customer,
  } = useCustomerContext();

  const [resultSet, setEntitiesToShow] = useState<Entities>({
    entities: [],
    entitiesLoading: true,
  });

  const [presentationModeEntities, presentationModeEntitiesLoading] = useCollectionData(
    isCustomerInPresentationMode(customer)
      ? getCollection(EntityModel).where("customer", "==", customerOrPresentationModeCustomer.ref)
      : undefined,
    {
      idField: "id",
      refField: "ref",
    }
  );

  useEffect(
    () =>
      setEntitiesToShow(
        isCustomerInPresentationMode(customer)
          ? {
              entities: presentationModeEntities ?? EMPTY_COLLECTION,
              entitiesLoading: presentationModeEntitiesLoading,
            }
          : {
              entities: genuineEntities,
              entitiesLoading: genuineEntitiesLoading,
            }
      ),
    [customer, genuineEntities, genuineEntitiesLoading, presentationModeEntities, presentationModeEntitiesLoading]
  );

  return resultSet;
}
