import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import capitalize from "lodash/capitalize";

type StackCreationDialogProps = {
  open: boolean;
  operationName: string;
  setOpen: (boolean) => void;
  linkAccountHandler: () => void;
};

export const StackCreationDialog = ({ open, setOpen, operationName, linkAccountHandler }: StackCreationDialogProps) => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>AWS CloudFormation stack creation</DialogTitle>
    <DialogContent>
      <DialogContentText>
        When you click "{capitalize(operationName)}", you will be taken to the AWS console to create a CloudFormation
        stack template. Please acknowledge the action, and click "Create stack".
      </DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={linkAccountHandler} color="primary" variant="contained">
        {capitalize(operationName)}
      </Button>
    </DialogActions>
  </Dialog>
);
