import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import LoadingButton from "../../../Components/LoadingButton";
import { useFullScreen } from "../../../utils/dialog";

const useStyles = makeStyles(() => ({
  root: {},
  content: {},
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function ConfirmationDialog({ open, onClose, onConfirmDelete, isDeletionInProgress }) {
  const classes = useStyles();
  const { fullScreen } = useFullScreen("sm");

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Warning</DialogTitle>
      <DialogContent>
        Removing the integration with Google Cloud will disable the following features:
        <ul>
          <li>BigQuery Lens</li>
          <li>IAM Folder support in Cloud Analytics</li>
          <li>Disposable Cloud Environments (DCEs)</li>
          <li>Compute Rightsizing Insights & Recommendations</li>
          <li>GKE Cost Analytics</li>
        </ul>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <LoadingButton
          onClick={onConfirmDelete}
          variant="contained"
          color="error"
          loading={isDeletionInProgress === true}
          mixpanelEventId="settings.gcp-confirmation.confirm"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
