import { type AssetType } from "@doitintl/cmp-models";

import { formatFirestoreDate } from "../../../CloudAnalytics/utilities";
import { type Invoice, type InvoiceStatus, type InvoiceTableRow } from "./types";

const issuableInvoiceTypes: AssetType[] = ["amazon-web-services", "navigator", "solve", "solve-accelerator"];

export const getStatus = (invoice: Invoice): InvoiceStatus => {
  if (invoice.canceledAt) {
    return "Canceled";
  }
  if (invoice.issuedAt) {
    return "Issued";
  }
  if (issuableInvoiceTypes.includes(invoice.type)) {
    return "Non Issued";
  }

  return "-";
};

export const formatInvoice = (invoice: Invoice): InvoiceTableRow => ({
  ...invoice,
  total: Math.round(invoice.rows.reduce((a, b) => a + b.total, 0) * 100) / 100,
  status: getStatus(invoice),
  transform: {
    createdAt: formatFirestoreDate(invoice.timestamp),
    issuedAt: formatFirestoreDate(invoice.issuedAt),
    canceledAt: formatFirestoreDate(invoice.canceledAt),
  },
});
