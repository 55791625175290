import { Stack } from "@mui/material";

import EmptyWidgetCard from "../../../../Components/Dashboard/EmptyWidgetCard";
import { useCurrentDashboardContext } from "../../../../Context/CurrentDashboardContext";
import { useEmptyReportWidgetCustomMessages } from "../../../../Pages/Customer/hooks/useEmptyReportWidgetCustomMessages";
import { getWidgetBaseName, getWidgetId } from "../../../../utils/widgets";
import { type EnticementState } from "../../../LensEnticement/types";
import CloudReportCard from "../../Analytics/CloudReportCard";
import { BQLensEnticement } from "../BQEmptyStateDashboard/BQLensEnticement";

const BQInactiveStateDashboard = ({ enticementState }: { enticementState: EnticementState }) => {
  const { currentDashboard } = useCurrentDashboardContext();
  const { emptyReportWidgetSettingsMap } = useEmptyReportWidgetCustomMessages();
  const cloudReportWidget = currentDashboard?.widgets?.find((widget) => widget.name.includes("cloudReports"));

  const widgetId = () => {
    if (cloudReportWidget) {
      return getWidgetId(cloudReportWidget);
    }
  };

  const id = widgetId();
  if (!currentDashboard || !cloudReportWidget || !id) {
    return null;
  }

  const customEmptyReportMessage =
    emptyReportWidgetSettingsMap[cloudReportWidget?.name?.split("_")[1]]?.emptyReportFallback ?? undefined;

  return (
    <Stack data-cy="bqEmptyStateDashboard" spacing={1} sx={{ width: "100%", p: 0 }}>
      <BQLensEnticement state={enticementState} />
      <CloudReportCard
        raised={false}
        isCustomizeMode={false}
        fallbackComponent={
          <EmptyWidgetCard
            widgetHeight={1}
            name={getWidgetBaseName(cloudReportWidget)}
            customMessageSettings={customEmptyReportMessage}
          />
        }
        widgetId={id}
        customEmptyReportMessage={customEmptyReportMessage}
      />
    </Stack>
  );
};

export default BQInactiveStateDashboard;
