import { useCallback, useState } from "react";

import { Button, Tooltip } from "@mui/material";

import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useTier } from "../../../../Context/TierProvider";
import { type AnalyticsResources } from "../../analyticsResources/types";
import { type AssignLabelsRequest } from "../api";
import { useAssignLabels } from "../hooks";
import { type LabelWithRef } from "../types";
import { AssignLabelsMenu } from "./AssignLabelMenu";

type AssignLabelsButtonProps = {
  labels: LabelWithRef[];
  selectedResources: AnalyticsResources[];
  disabled?: boolean;
};

const AssignLabelsButton = ({ labels, selectedResources, disabled = false }: AssignLabelsButtonProps) => {
  const { isFeatureEntitled } = useTier();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const assignLabels = useAssignLabels();
  const snackbar = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSave = useCallback(
    ({ add, remove }: { add: string[]; remove: string[] }) => {
      if (add.length === 0 && remove.length === 0) {
        setAnchorEl(null);
        return;
      }

      const req: AssignLabelsRequest = {
        addLabels: add,
        removeLabels: remove,
        objects: [],
      };

      selectedResources?.forEach((resource) => {
        req.objects.push({ objectId: resource.id, objectType: resource.contentType });
      });

      assignLabels(
        req,
        () => {
          snackbar.onOpen({
            message: "Successfully assigned labels",
            variant: "success",
            autoHideDuration: 5000,
          });
        },
        () => {
          snackbar.onOpen({
            message: "There was an error assigning label(s) to selected resource(s)",
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      );

      setAnchorEl(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedResources, setAnchorEl]
  );

  const tooltipTitle =
    selectedResources.length > 0 && disabled ? "You do not have permissions to update the selected resource(s)" : "";

  const buttonDisabled = !isFeatureEntitled("analytics:labels") || disabled;

  return (
    <>
      <Tooltip arrow placement="top" title={tooltipTitle}>
        <span>
          <Button
            id="assign-labels-button"
            aria-controls={open ? "assign-labels-button" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disabled={buttonDisabled}
          >
            Assign labels
          </Button>
        </span>
      </Tooltip>
      {open && (
        <AssignLabelsMenu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          labels={labels}
          selectedResources={selectedResources}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default AssignLabelsButton;
