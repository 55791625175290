import { useCallback, useMemo, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import LaunchIcon from "@mui/icons-material/Launch";
import sumBy from "lodash/sumBy";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { Loader } from "../../../../../Components/Loader";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { PurchaseConfirmationDialog } from "../Common/PurchaseConfirmationDialog";
import { type BulkPurchaseItem } from "../types";
import { allWorkloadsReportUrl } from "../utils/linkUtils";
import { BulkPurchasesRow } from "./BulkPurchasesRow";
import { defaultFilters, filters, headerColumns } from "./columns";

type Props = {
  bulkPurchaseItems: BulkPurchaseItem[];
  isBulkUpdateRequired: boolean;
};

const BulkPurchases = ({ bulkPurchaseItems, isBulkUpdateRequired }: Props) => {
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);
  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const [selectedPurchaseItems, setSelectedPurchaseItems] = useState<BulkPurchaseItem[]>([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const [individualPurchaseSelect, setIndividualPurchaseSelect] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const handleApproveDialogClose = useCallback(() => {
    setApproveDialogOpen(false);
    if (individualPurchaseSelect) {
      setSelectedPurchaseItems([]);
    }
  }, [individualPurchaseSelect]);

  const handlePurchaseButtonClick = useCallback(() => setApproveDialogOpen(true), []);

  const handlePurchaseOptionClick = useCallback((selectedItem: BulkPurchaseItem) => {
    setIndividualPurchaseSelect(true);
    setSelectedPurchaseItems([selectedItem]);
    setApproveDialogOpen(true);
  }, []);

  const BulkPurchasesRowWrapper = useCallback(
    (props: { row: BulkPurchaseItem }) => (
      <BulkPurchasesRow
        row={props.row}
        allowPurchase={!isBulkUpdateRequired}
        onPurchaseButtonClick={handlePurchaseOptionClick}
      />
    ),
    [isBulkUpdateRequired, handlePurchaseOptionClick]
  );

  const canApproveAllSelectedItems = useMemo(() => {
    if (selectedPurchaseItems.length === 0 || !bulkPurchaseItems) {
      return false;
    }
    return selectedPurchaseItems.every((selectedItem) => selectedItem.canBeApproved);
  }, [selectedPurchaseItems, bulkPurchaseItems]);

  const onDialogApproveClicked = useCallback(async () => {
    if (selectedPurchaseItems.length > 0) {
      const workloads = selectedPurchaseItems.map((item) => ({
        region: item.region,
        hardware: item.hardware,
        type: item.familyType,
      }));
      try {
        await flexSaveOpsApi.approveBulk(workloads);
        successSnackbar("Purchase initiated");
      } catch (e) {
        errorSnackbar("Purchase failed to initiate. Please try again.");
      }
    }
  }, [selectedPurchaseItems, flexSaveOpsApi, successSnackbar, errorSnackbar]);

  return (
    <>
      <PurchaseConfirmationDialog
        open={approveDialogOpen}
        handleClose={handleApproveDialogClose}
        handleApprove={onDialogApproveClicked}
        totalCost={sumBy(selectedPurchaseItems, "purchasePlanPrice")}
        customerCount={sumBy(selectedPurchaseItems, "customersCount")}
        cudCount={sumBy(selectedPurchaseItems, "purchaseRecommendations")}
        purchaseType="BulkPurchase"
      />
      <Loader loading={!bulkPurchaseItems}>
        <FilterTable<BulkPurchaseItem>
          showRowsSelection={isFlexsaveAdmin}
          onRowsSelected={setSelectedPurchaseItems}
          tableItems={bulkPurchaseItems}
          rowComponent={BulkPurchasesRowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          defaultFilters={defaultFilters}
          persistenceKey="flexsave_gcp_bulk_purchase_prices"
          toolbarProps={{
            title: "Bulk purchase",
            allowToEditColumns: true,
            primaryButton: {
              text: "Purchase",
              onClick: handlePurchaseButtonClick,
              disabled: !isFlexsaveAdmin || !canApproveAllSelectedItems || isBulkUpdateRequired,
            },
            secondaryButtons: [
              {
                text: "Open in Data Studio",
                startIcon: <LaunchIcon />,
                onClick: () => window.open(allWorkloadsReportUrl, "_blank"),
              },
            ],
          }}
        />
      </Loader>
    </>
  );
};

export default BulkPurchases;
