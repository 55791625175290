import { useEffect, useState } from "react";

import { type BQLensBillingMode, BQLensBillingModes, BQLensTimeFrames, SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { billingModeChanger } from "../../../assets/texts/BQLens/billingModeChanger";
import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { CircularProgressLoader } from "../../Loader";
import { useCheckEditionDataExists } from "./hooks";
import { getFilteredUnitsBasedOnMetricValue, useSelectStyles } from "./utils";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

export const BillingModeChanger = () => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  const [isAvailable, setIsAvailable] = useState(false);
  const { customer } = useCustomerContext();
  const { selectedBillingMode, selectedTimeframe, setDashboardWidgetContextMultipleValues } =
    useCurrentDashboardContext();

  const { isFeatureEntitled, loading: tiersLoading } = useTier();
  const { exists: hasStandardEdition, loading: standardLoading } = useCheckEditionDataExists(
    BQLensBillingModes.standardEdition
  );
  const { exists: hasEnterpriseEdition, loading: enterpriseLoading } = useCheckEditionDataExists(
    BQLensBillingModes.enterpriseEdition
  );
  const { exists: hasEnterprisePlusEdition, loading: enterprisePlusLoading } = useCheckEditionDataExists(
    BQLensBillingModes.enterprisePlusEdition
  );

  const showEditionTab = isFeatureEntitled("lens:bigquery:editions");

  useEffect(() => {
    getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("flat-rate")
      .doc(customer.id)
      .collection("rollUps")
      .doc(BQLensTimeFrames.pastThirtyDays)
      .collection("user")
      .doc("slots")
      .get()
      .then((result) => {
        setIsAvailable(result.exists());
      });
  }, [customer.id]);

  const loading = tiersLoading || standardLoading || enterpriseLoading || enterprisePlusLoading;

  if (loading) {
    return <CircularProgressLoader />;
  }

  if (!isAvailable) {
    return null;
  }

  return (
    <>
      <Box p={1} />

      <FormControl margin="dense" className={classes.formControl}>
        <InputLabel>Usage type</InputLabel>
        <Select
          className={selectClasses.select}
          label="Usage type"
          value={selectedBillingMode}
          data-cy="billing-mode"
          onChange={async (e) => {
            const newBillingMode = e.target.value as BQLensBillingMode;

            const metricType = "billingProject";
            const filteredUnits = await getFilteredUnitsBasedOnMetricValue({
              metricType,
              selectedBillingMode: newBillingMode,
              customerId: customer.id,
              selectedTimeframe,
            });

            if (filteredUnits.length === 0) {
              throw new Error(
                `No units available for the selected metric and billing mode ${metricType} ${newBillingMode}`
              );
            }

            const measurement = filteredUnits[0];

            setDashboardWidgetContextMultipleValues({
              selectedMeasurement: measurement,
              selectedMetric: metricType,
              selectedBillingMode: newBillingMode,
              availableUnitOptions: filteredUnits,
            });
          }}
        >
          <MenuItem value={BQLensBillingModes.onDemand} className={selectClasses.select}>
            {billingModeChanger.ON_DEMAND}
          </MenuItem>

          {!showEditionTab && (
            <MenuItem value={BQLensBillingModes.flatRate} className={selectClasses.select}>
              {billingModeChanger.FLAT_RATE_LEGACY}
            </MenuItem>
          )}

          {showEditionTab && hasStandardEdition && (
            <MenuItem value={BQLensBillingModes.standardEdition} className={selectClasses.select}>
              {billingModeChanger.STANDARD_EDITION}
            </MenuItem>
          )}

          {showEditionTab && hasEnterpriseEdition && (
            <MenuItem value={BQLensBillingModes.enterpriseEdition} className={selectClasses.select}>
              {billingModeChanger.ENTERPRISE_EDITION}
            </MenuItem>
          )}

          {showEditionTab && hasEnterprisePlusEdition && (
            <MenuItem value={BQLensBillingModes.enterprisePlusEdition} className={selectClasses.select}>
              {billingModeChanger.ENTERPRISE_PLUS_EDITION}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
