import { useCallback } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";

import { globalText, unsavedChangesPromptText } from "../../assets/texts";
import LoadingButton from "../LoadingButton";

type Props = {
  loading: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  onSave?: () => Promise<boolean>;
};

export const UnsavedChangesPrompt = ({ loading, onConfirm, onCancel, onSave }: Props) => {
  const handleSave = useCallback(async () => {
    if (onSave) {
      const success = await onSave();
      if (success) {
        await onConfirm();
      } else {
        onCancel();
      }
    }
  }, [onCancel, onConfirm, onSave]);

  const marginRight = onSave ? "auto" : 0;

  return (
    <Dialog onClose={onCancel} open={true} data-cy="unsaved-changes-prompt">
      <DialogTitle>{unsavedChangesPromptText.DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{unsavedChangesPromptText.DIALOG_CONTENT}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {!!onSave && (
          <Button onClick={handleSave} data-cy="save-changes-exit-page-button" sx={{ marginRight }}>
            {globalText.SAVE_CHANGES}
          </Button>
        )}
        <Button onClick={onCancel} data-cy="cancel-exit-page-button" disabled={loading}>
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          loading={loading}
          onClick={onConfirm}
          sx={{ marginLeft: "auto" }}
          data-cy="proceed-with-exit-button"
          mixpanelEventId="unsaved-changes-prompt.discard"
        >
          {globalText.DISCARD_CHANGES}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
