import { type ReactNode, useMemo } from "react";

import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";

import { useFullScreen } from "../../../utils/dialog";
import { NavigationSelect } from "./NavigationSelect";
import { type SideNavigationData } from "./SideNavigationLayout";
import { SideTab, SideTabs } from "./SideTabs";
import { Title } from "./Title";

type Props = {
  title?: string;
  data?: SideNavigationData;
  backButtonRoute?: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export const ThirdLevelNav = ({ title, backButtonRoute, data, children, sx }: Props) => {
  const { isMobile } = useFullScreen();

  const showNav = title && data;

  const currentTabValue = useMemo(() => {
    if (!data) {
      return false;
    }
    const allValues = data.items.map((item) => item.value);
    return allValues.includes(data.value.toString()) ? data.value : false;
  }, [data]);

  if (isMobile && showNav) {
    return (
      <Box>
        <Box pt={2} pb={3}>
          <NavigationSelect title={title} data={data} />
        </Box>
        <Box width="100%">{children}</Box>
      </Box>
    );
  }

  return (
    <Box display="flex">
      {!!showNav && (
        <Box bgcolor="background.paper" minHeight="85vh">
          <Title title={title} backButtonRoute={backButtonRoute} />
          <Box pr={1}>
            <SideTabs value={currentTabValue} onChange={data.onChange}>
              {data.items.map((item) => (
                <SideTab
                  key={`sideTab-${item.value}`}
                  data-cy={`sideTab-${item.value}`}
                  value={item.value}
                  label={item.label}
                  {...(!data.onChange
                    ? {
                        component: Link,
                        to: item.to,
                        tabChildren: item.children,
                        sx,
                      }
                    : {})}
                />
              ))}
            </SideTabs>
          </Box>
        </Box>
      )}
      <Box width="100%" overflow={showNav ? "auto" : undefined}>
        {children}
      </Box>
    </Box>
  );
};
