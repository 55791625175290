import { Box, Divider, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useTier } from "../../Context/TierProvider";
import { type Insight } from "../Insights/types";
import { useInsightThread, useJiraIssue } from "./api";
import { CreateThread } from "./CreateThread/CreateThread";
import { ErroredLinkedThread } from "./LinkedThread/ErroredLinkedThread";
import { LinkedThread } from "./LinkedThread/LinkedThread";
import { SkeletonLinkedThread } from "./LinkedThread/SkeletonLinkedThread";

type Props = {
  insight: Insight;
};

export const Threads = ({ insight }: Props) => {
  const { isFeatureEntitled } = useTier();
  const isEntitled = isFeatureEntitled("governance:threads");

  const { thread, isFetching } = useInsightThread(insight.key);
  const { jiraIssue, isErrored } = useJiraIssue(thread?.targetData.issueKey);

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={6}>
        <CircularProgress />
      </Box>
    );
  }

  const ThreadsContent = () => {
    if (!isEntitled || thread === null || thread === undefined) {
      // There's no thread in Firestore
      return <CreateThread isEntitled={isEntitled} insight={insight} />;
    }

    if (jiraIssue === null || jiraIssue === undefined) {
      if (isErrored) {
        // We didn't get a Jira Issue back, and there was an error
        return <ErroredLinkedThread threadID={thread.id} />;
      }

      // We didn't get a Jira Issue back yet, but there hasn't been an error, so we'll keep waiting
      // At the moment, we assume that if there's a thread in Firestore, there must be a Jira issue for it
      return <SkeletonLinkedThread />;
    }

    // We have both the thread from Firestore and its related Jira Issue
    return <LinkedThread issue={jiraIssue} issueURL={thread.targetData.issueURL} threadId={thread.id ?? ""} />;
  };

  return (
    <>
      <Typography variant="h4" color="inherit" sx={{ textDecoration: "none", fontWeight: 500 }} mb={1}>
        {thread === null || thread === undefined ? "Get started" : "Linked thread"}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <ThreadsContent />
        <Divider />
      </Box>
    </>
  );
};
