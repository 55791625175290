import { createContext, type ReactNode, useCallback, useContext, useMemo } from "react";

import { AppModel, type CurrencyCode, CurrencyCodes, type FixerExchangeDailyRatesModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { formatCurrency } from "../utils/common";
import { type CurrencyDateType } from "../utils/currencyConverter";
import DateType from "../utils/dateType";

class RateNotFound extends Error {
  constructor(message: string) {
    super(message);
    this.name = "RateNotFound";
  }
}

type AsyncCurrencyConverterContextType = {
  currencyConvert: (amount: number, date: CurrencyDateType, from: CurrencyCode, to: CurrencyCode) => Promise<number>;
  convertAndFormatCurrency: (
    amount: number,
    dt: CurrencyDateType,
    from: CurrencyCode,
    to: CurrencyCode,
    maxFracDigits?: number,
    minFracDigits?: number
  ) => Promise<string>;
};

const asyncCurrencyConverterContext = createContext<AsyncCurrencyConverterContextType>({
  currencyConvert: () => Promise.reject(new Error("currency context not init")),
  convertAndFormatCurrency: () => Promise.reject(new Error("currency context not init")),
});

enum OperatorValue {
  multiplication = "*",
  division = "/",
}

type FixerRate = {
  rate: number;
  operator: OperatorValue;
};

class RateConverter {
  private rates: FixerExchangeDailyRatesModel = {};

  private currentYearListenerUnsubscribe: (() => void) | undefined;

  private currentYearListenerOnYear: number | undefined;

  private readonly yearsLoaded: Record<number, Promise<void>> = {};

  public hasRate(dt: CurrencyDateType) {
    const formattedDate = DateType.getFormattedStringDate(dt, "yyyy-LL-dd");

    return Boolean(this.rates[formattedDate]);
  }

  public async convert(amount: number, date: CurrencyDateType, from: CurrencyCode, to: CurrencyCode) {
    if (from === to) {
      return amount;
    }

    const formattedDate = DateType.getFormattedStringDate(date, "yyyy-LL-dd");
    if (!this.hasRate(date)) {
      await this.loadYear(DateType.getDateTime(date).year);
    }

    if (!this.hasRate(date)) {
      throw new RateNotFound(`Rates of ${to} for ${formattedDate} not found, can't convert from ${from}`);
    }

    // convert to USD first and then convert to the target currency
    const tempUSDRate = this.getRate(from, CurrencyCodes.USD, formattedDate);
    const tempAmount = this.calculateRate(tempUSDRate.operator, tempUSDRate.rate, amount);
    const targetToRate = this.getRate(CurrencyCodes.USD, to, formattedDate);

    return this.calculateRate(targetToRate.operator, targetToRate.rate, tempAmount);
  }

  private calculateRate(operator: OperatorValue, rate: number, amount: number): number {
    return operator === OperatorValue.multiplication ? amount * rate : amount / rate;
  }

  private getRate(from: CurrencyCode, to: CurrencyCode, formattedDate: string): FixerRate {
    let operator = OperatorValue.multiplication;
    if (to === CurrencyCodes.USD) {
      operator = OperatorValue.division;
    }
    const selectedRate = operator === OperatorValue.division ? from : to;
    return { rate: this.rates[formattedDate][selectedRate], operator };
  }

  private async loadYear(year: number) {
    const load = async () => {
      if (year === DateTime.utc().year) {
        this.listenToCurrentYearIfNeeded();
      }

      const doc = await getCollection(AppModel)
        .doc("fixer")
        .collection("fixerExchangeRates")
        .doc(year.toString())
        .narrow<FixerExchangeDailyRatesModel>()
        .get();

      const data = doc.asModelData();

      this.rates = { ...this.rates, ...data };
    };

    if (this.yearsLoaded[year] === undefined) {
      // Initiate loading for this year and store the promise
      this.yearsLoaded[year] = load();
    }

    await this.yearsLoaded[year];
  }

  private listenToCurrentYearIfNeeded() {
    const currentYear = DateTime.utc().year;

    if (this.currentYearListenerOnYear === currentYear) {
      return;
    }

    if (this.currentYearListenerUnsubscribe) {
      this.currentYearListenerUnsubscribe();
    }

    this.currentYearListenerUnsubscribe = getCollection(AppModel)
      .doc("fixer")
      .collection("fixerExchangeRates")
      .doc(currentYear.toString())
      .narrow<FixerExchangeDailyRatesModel>()
      .onSnapshot((snapshot) => {
        const data = snapshot.asModelData();
        if (data) {
          this.rates = { ...this.rates, ...data };
        }
      });

    this.currentYearListenerOnYear = currentYear;
  }
}

const rateConverter: RateConverter = new RateConverter();

export const asyncConvertCurrencyTo = (
  amount: number,
  date: CurrencyDateType,
  from: CurrencyCode,
  to: CurrencyCode
): Promise<number> => rateConverter.convert(amount, date, from, to);

export const asyncConvertAndFormatCurrency = async (
  amount: number,
  dt: CurrencyDateType,
  from: CurrencyCode,
  to: CurrencyCode,
  maxFracDigits = 2,
  minFracDigits = undefined
) => {
  try {
    const converted = await asyncConvertCurrencyTo(amount, dt, from, to);

    return formatCurrency(converted, to, maxFracDigits, minFracDigits);
  } catch (error: unknown) {
    if (error instanceof RateNotFound) {
      return formatCurrency(amount, from, maxFracDigits, minFracDigits);
    }

    throw error;
  }
};

export const AsyncCurrencyConverterContextProvider = ({ children }: { children?: ReactNode }) => {
  const currencyConvert = useCallback(
    async (amount: number, date: CurrencyDateType, from: CurrencyCode, to: CurrencyCode) =>
      asyncConvertCurrencyTo(amount, date, from, to),
    []
  );

  const convertAndFormatCurrency = useCallback(
    async (
      amount: number,
      dt: CurrencyDateType,
      from: CurrencyCode,
      to: CurrencyCode,
      maxFracDigits = 2,
      minFracDigits = undefined
    ) => asyncConvertAndFormatCurrency(amount, dt, from, to, maxFracDigits, minFracDigits),
    []
  );

  const value = useMemo(
    () => ({ currencyConvert, convertAndFormatCurrency }),
    [currencyConvert, convertAndFormatCurrency]
  );

  return <asyncCurrencyConverterContext.Provider value={value}>{children}</asyncCurrencyConverterContext.Provider>;
};

export const useAsyncCurrencyConverter = () => useContext(asyncCurrencyConverterContext);

export const hasConversionRate = (date: CurrencyDateType): boolean => rateConverter.hasRate(date);
