import LaunchIcon from "@mui/icons-material/Launch";
import { ListItemIcon, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import TableCell from "@mui/material/TableCell";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { LinkCell } from "../../../Common/TableCell/LinkCell";
import { RiskTableCell } from "../../../Common/TableCell/RiskTableCell";
import { ThreeDotsMenuCell } from "../../../Common/TableCell/ThreeDotsMenuCell";
import { FamilyTypeTableCell } from "../Common/commonTableCells";
import RiskyPurchaseStatus from "../Common/Status/RiskyPurchaseStatus";
import { type ApprovalsItem } from "../types";
import { buildDetailsUrl } from "../utils/linkUtils";

type Props = {
  row: ApprovalsItem;
  onPurchaseButtonClick: (row: ApprovalsItem) => void;
  onRejectButtonClick: (row: ApprovalsItem) => void;
  allowPurchase: boolean;
};

export const ApprovalsRow = ({ row, onPurchaseButtonClick, onRejectButtonClick, allowPurchase }: Props) => {
  const actionOptions: ThreeDotsMenuOption[] = [
    {
      label: (
        <Typography sx={row.canBeApproved && allowPurchase ? {} : { opacity: 0.15, cursor: "not-allowed" }}>
          Purchase
        </Typography>
      ),
      action: row.canBeApproved && allowPurchase ? () => onPurchaseButtonClick(row) : () => void 0,
      key: "purchase",
      dataCy: "purchase",
    },
    {
      label: (
        <>
          <Typography pr={1}>Open in Data Studio</Typography>
          <ListItemIcon>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
        </>
      ),
      action: () => window.open(buildDetailsUrl(row), "_blank"),
      key: "dataStudioLink",
      dataCy: "data-studio-link",
    },
    {
      label: (
        <Typography color={red[700]} sx={row.canBeApproved ? {} : { opacity: 0.15, cursor: "not-allowed" }}>
          Reject
        </Typography>
      ),
      action: row.canBeApproved ? () => onRejectButtonClick(row) : () => void 0,
      key: "reject",
      dataCy: "reject",
    },
  ];

  return (
    <CellsWrapper>
      <LinkCell linkText={row.primaryDomain} to={buildDetailsUrl(row)} />
      <TableCell>{row.region}</TableCell>
      <FamilyTypeTableCell type={row.type} />
      <TableCell>{row.hardware}</TableCell>
      <TableCell>
        <RiskyPurchaseStatus workload={row} />
      </TableCell>
      <TableCell>{row.purchaseRecommendations || "-"}</TableCell>
      <RiskTableCell
        fieldValue={row.customerShareWithPurchasePlan}
        hasRisk={!!row.purchaseRisk?.causes?.customerShare}
        riskType="coverage"
      />
      <RiskTableCell
        fieldValue={row.globalCoverageWithPurchasePlan}
        hasRisk={!!row.purchaseRisk?.causes?.newTotalCoverage}
        riskType="coverage"
      />
      <RiskTableCell
        fieldValue={row.customerTotalCost}
        hasRisk={!!row.purchaseRisk?.causes?.customerTotalCost}
        riskType="cost"
      />
      <RiskTableCell
        fieldValue={row.purchasePlanPrice}
        hasRisk={!!row.purchaseRisk?.causes?.customerTotalCost}
        riskType="cost"
      />
      <ThreeDotsMenuCell options={actionOptions} style={{ padding: 0 }} />
    </CellsWrapper>
  );
};
