import React from "react";

import { type DeliveryFrequency } from "@doitintl/cmp-models";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import { getScheduleText } from "../helpers";
import { type SubscriptionFormValues } from "../types";

export function ScheduleInfoBar() {
  const { values } = useFormikContext<SubscriptionFormValues>();
  const { isValid } = useFormikContext<SubscriptionFormValues>();
  const isDarkTheme = useDarkThemeCheck();

  if (!isValid) {
    return null;
  }

  const scheduleText = getScheduleText({
    customPeriodAmount: values.customPeriodAmount,
    customPeriodUnit: values.customPeriodUnit,
    deliveryFrequency: values.deliveryFrequency as DeliveryFrequency,
    startDate: values.startDate,
    time: values.time,
    format: "long",
  });

  if (!scheduleText) {
    return null;
  }

  return (
    <Paper
      sx={{
        p: 1,
        mt: -1.5,
        backgroundColor: isDarkTheme ? "#2D2D39" : "#FAFAFA",
      }}
      elevation={0}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="body2">
            <Box component="span" sx={{ mr: 1, display: "inline-flex", alignItems: "center" }}>
              <Typography component="span" sx={{ fontSize: 20, mr: 1, mt: 0.5 }}>
                <AccessTimeIcon sx={{ fontSize: 20 }} />
              </Typography>
              {scheduleText}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
