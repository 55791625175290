import { Box, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";

export type WorkflowStepsProps = {
  title: string;
  stepNames: string[];
  disableOnLastStep?: boolean;
  resetOnOpen?: boolean;
};

type Props = WorkflowStepsProps & {
  step: number;
  onStepChanged: (step: number) => void;
  disabled?: boolean;
};

export function WorkflowSteps({ title, stepNames, step, onStepChanged, disabled = false }: Props) {
  const showStepper = stepNames.length > 1;

  const handleStepClicked = (index: number) => {
    if (!disabled && index < step) {
      onStepChanged(index);
    }
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Stack spacing={2} pb={2}>
        <Typography variant="h3">{title}</Typography>
      </Stack>
      <Stepper activeStep={step} orientation="vertical">
        {stepNames.map((name, index) => (
          <Step key={name} disabled={disabled}>
            <StepLabel
              sx={{
                cursor: index < step ? "pointer" : undefined,
                "& .MuiStepLabel-iconContainer": {
                  display: showStepper ? "block" : "none",
                },
              }}
              onClick={() => handleStepClicked(index)}
            >
              {name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
