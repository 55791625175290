import { useState } from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import GuidedExperienceDialog from "../../../Pages/CloudAnalytics/guidedExperience/GuidedExperienceDialog";
import { QuickLinkCard } from "./QuickLinkCard";
import { useGridColumnTemplate } from "./useGridColumnTemplate";
import { useUserQuickLinks } from "./useUserQuickLinks";

export const QuickLinkList = () => {
  const {
    breakpoints: { down: isBelowBreakpoint },
  } = useTheme();
  const columnTemplate = useGridColumnTemplate();
  const [quickLinks] = useUserQuickLinks();

  const isMobileScreen = useMediaQuery(isBelowBreakpoint("md"));

  const [isGuidedExperienceDialogOpened, setIsGuidedExperienceDialogOpened] = useState(false);

  if (quickLinks.length === 0 || isMobileScreen) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" mb={1}>
        Quick links
      </Typography>
      <Box display="grid" gridTemplateColumns={columnTemplate} gap={1} pr={1}>
        {quickLinks.map((quickLink) => (
          <QuickLinkCard
            quickLink={quickLink}
            key={quickLink.name}
            setIsGuidedExperienceDialogOpened={setIsGuidedExperienceDialogOpened}
          />
        ))}
      </Box>
      {isGuidedExperienceDialogOpened && (
        <GuidedExperienceDialog onClose={() => setIsGuidedExperienceDialogOpened(false)} origin="quicklinks" />
      )}
    </>
  );
};
