import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { DateTime } from "luxon";
import find from "lodash/find";
import { transformStatus } from "../../utils/common";
import { useTranslation } from "react-i18next";

export const getStatusColor = (status) => {
  switch (status) {
    case "new":
      return "#ff9100";
    case "open":
      return "#00b0ff";
    case "pending":
      return "#d500f9";
    case "hold":
      return "#2979ff";
    case "solved":
      return "#00e083";
    case "closed":
      return "#9e9e9e";
    default:
      return "";
  }
};
const getUser = (userList, userID) => {
  if (userList && userList.length > 0) {
    const userD = find(userList, { id: userID });
    if (userD) {
      return userD.email;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
const styles = (theme) => ({
  gridContainer: {
    flex: 1,
    width: "100%",
    display: "flex",
    margin: 0,
  },
  detailsRow: {
    padding: theme.spacing(1.5, 1.875),
  },
  detailsName: {
    fontSize: theme.typography.pxToRem(15),
    width: 107,
  },
  detailsValue: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.8),
  },
});
const TicketDetailsTable = (props) => {
  const { classes } = props;
  const { t } = useTranslation("services");

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12}>
        <Grid container className={classes.detailsRow}>
          <Grid item>
            <Typography variant="body2" className={classes.detailsName}>
              {t("TicketsTableColumns.status")}
            </Typography>
          </Grid>
          <Grid item>
            <div style={{ display: "flex" }}>
              <div style={{ backgroundColor: getStatusColor(props.ticketDetails.status) }} className={classes.circle} />
              <Typography variant="body2" className={classes.detailsValue}>
                {transformStatus(props.ticketDetails.status)}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Divider />
        <Grid container className={classes.detailsRow}>
          <Grid item>
            <Typography variant="body2" className={classes.detailsName}>
              {t("TicketDetailsTable.requester")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.detailsValue}>
              {getUser(props.users, props.ticketDetails.requester_id)}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container className={classes.detailsRow}>
          <Grid item>
            <Typography variant="body2" className={classes.detailsName}>
              {t("TicketDetailsTable.updatedAt")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.detailsValue}>
              {DateTime.fromISO(props.ticketDetails.updated_at).toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <Grid container className={classes.detailsRow}>
          <Grid item>
            <Typography variant="body2" className={classes.detailsName}>
              {t("TicketDetailsTable.createdAt")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.detailsValue}>
              {DateTime.fromISO(props.ticketDetails.created_at).toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TicketDetailsTable);
