import { useState } from "react";

import { Button } from "@mui/material";

import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";
import { CustomerEditPackagePreferencesDialog } from "./CustomerEditPackagePreferencesDialog";

export function CustomerEditPackagePreferencesBtn() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="text"
        onClick={() => setIsDialogOpen(true)}
        data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.EDIT_INTERESTED_PACKAGES_BTN}
      >
        {text.EDIT_INTERESTED_PACKAGES_BTN_TITLE}
      </Button>
      {isDialogOpen && (
        <CustomerEditPackagePreferencesDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      )}
    </>
  );
}
