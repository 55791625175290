import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel, type CustomerModelOrganizationModel } from "./Customer";
import { type DashboardModelAttributionModel } from "./Dashboard";
import { type UserEnrichment } from "./Enrichment";
import { type EntityModel } from "./Entity";
import { type UserPersonalNotificationModel } from "./Notifications";
import { type RoleModel } from "./Role";
import { type UserPermission } from "./User";

export type InvitedBy = {
  email: string;
  name: null | string;
};

type UserSettings = {
  anomalyAbsolute?: number;
  anomalyAlerts?: number;
  anomalyScope?: Reference<DashboardModelAttributionModel>[];
};

@collection("invites")
export class InviteModel {
  customer!: {
    _name: string;
    name: string;
    ref: Reference<CustomerModel>;
  };

  dailyDigests?: Reference<DashboardModelAttributionModel>[];

  weeklyDigests?: Reference<DashboardModelAttributionModel>[];

  defaultDashboard?: string;

  displayName?: string;

  email!: string;

  enrichment!: UserEnrichment;

  entities?: Reference<EntityModel>[] | null;

  entityCreateTemplate!: boolean;

  firstName?: string;

  invitedBy!: InvitedBy | string;

  jobFunction?: number;

  lastName?: string;

  organizations?: Reference<CustomerModelOrganizationModel>[];

  permissions!: UserPermission[];

  phone?: null | string;

  phoneExtension?: null | string;

  role?: number | string;

  roles?: Reference<RoleModel>[];

  sendEmail?: boolean;

  timestamp!: Timestamp;

  userNotifications?: number[];

  userSettings?: UserSettings;

  invitationSentTimestamp?: Timestamp;

  subCollections?: {
    userPersonalNotifications: UserPersonalNotificationModel;
  };
}
