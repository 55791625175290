import { type SyntheticEvent, useCallback } from "react";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import { Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import { metricSplittingTxt } from "../../../../assets/texts/CloudAnalytics";
import { type Split } from "../../../../Components/SplitCostModal/types";
import { checkedTargetsLength } from "../../../../Components/SplitCostModal/utils";
import { type MetadataOption } from "../../../../types";
import { useMetricSplitsContext } from "../../Context";

type Props = {
  open: boolean;
  anchorEl: Element | null;
  closeSplitMenu: () => void;
  metricSplitModalClicked: () => void;
  selectedSplitItem?: MetadataOption;
};

const text = {
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const SplitCostMenu = ({ open, anchorEl, closeSplitMenu, metricSplitModalClicked, selectedSplitItem }: Props) => {
  const { metricSplitLabel, deleteSplit, editSplit, metricSplits } = useMetricSplitsContext();
  const splits = Object.values(metricSplits ?? []).filter((split) => split.id === selectedSplitItem?.id);

  const onDeleteClick = useCallback(
    (event: SyntheticEvent, split: Split) => {
      event.stopPropagation();
      deleteSplit(split);
    },
    [deleteSplit]
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeSplitMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {splits.length > 0 && (
          <Typography sx={{ px: 2, py: 0.75 }} variant="caption" color="text.secondary">
            {metricSplittingTxt.SPLIT} {metricSplitLabel}
          </Typography>
        )}

        {splits.map((split) => {
          const description = `${capitalize(split.mode)} ${
            split.mode !== "proportional" ? "splits" : ""
          } between ${checkedTargetsLength(split.targets)} items`;
          return (
            <MenuItem sx={{ pr: 0.5 }} key={`split-menu-${split.origin?.id}`} onClick={() => editSplit(split)}>
              <Stack width="100%" direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                <Stack sx={{ maxWidth: "200px" }}>
                  <Typography variant="body1" sx={text}>
                    {split.origin?.label}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={text}>
                    {description}
                  </Typography>
                </Stack>
                <IconButton onClick={(event) => onDeleteClick(event, split)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            </MenuItem>
          );
        })}
        {splits.length > 0 && <Divider />}
        <MenuItem onClick={metricSplitModalClicked}>
          {metricSplittingTxt.SPLIT_MENU_SPLIT_NEW} {metricSplitLabel}
        </MenuItem>
      </Menu>
    </>
  );
};

export default SplitCostMenu;
