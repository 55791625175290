import React from "react";

import { type SlackChannel } from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";
import { useField } from "formik";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { SlackChannelSelection } from "../../Slack/ChannelSelection";
import { useSetFieldValueTyped } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function SlackChannelsInput({ disabled }: SubscriptionInputProps) {
  const { customer } = useCustomerContext();
  const [channels, meta] = useField<SubscriptionFormValues["slackChannels"]>("slackChannels");
  const setFieldValueTyped = useSetFieldValueTyped();

  const setChannels = (recipientsSlackChannels: SlackChannel[]) =>
    setFieldValueTyped("slackChannels", recipientsSlackChannels);

  return (
    <Box
      sx={{
        border: meta.error ? "1.5px solid red" : "none",
        borderRadius: 1,
      }}
    >
      <SlackChannelSelection
        customerId={customer.id}
        disabled={disabled}
        channels={channels.value}
        setChannels={setChannels}
        updateSelection={setChannels}
        includeRefresh={false}
        includeNoOptionsBox={true}
        includeSlackLink={false}
      />
      {!!meta.error && (
        <Typography variant="body1" color="error">
          {getFirstError(meta.error as any)}
        </Typography>
      )}
    </Box>
  );
}

function getFirstError(errors: { [key: string]: string }[]): string | undefined {
  for (const errorObject of errors) {
    for (const key in errorObject) {
      if (errorObject[key]) {
        return errorObject[key];
      }
    }
  }
  return undefined; // Return undefined if no error is found
}
