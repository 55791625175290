import { Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { SafeHtml } from "../../../../Components/SafeHtml";
import StepIcon from "./StepIcon";
import SubStep from "./SubStep";
import { type GKEConfigStep } from "./types";

type Props = { step: GKEConfigStep };

const Step = ({ step }: Props) => {
  const theme = useTheme();
  return (
    <Grid container id="gke-step" spacing={2} style={{ marginTop: theme.spacing(1) }} columns={{ xs: 12, sm: 14 }}>
      <Grid
        item
        id="gke-step-icon"
        sm={1}
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <StepIcon index={step.index} status={step.status} />
      </Grid>
      <Grid item id="gke-step-text" sm={7} xs={5} style={{ paddingLeft: 0 }}>
        <Typography component="div" variant="body2" color="textPrimary" style={{ fontSize: "14px" }}>
          <SafeHtml html={step.text} />
        </Typography>
        {step.subSteps?.map((subStep, j) => <SubStep key={j} subStep={subStep} />)}
      </Grid>
      <Grid item sm={6} xs={6} style={{ paddingLeft: theme.spacing(1) }}>
        {step.learnMore && (
          <Link href={step.learnMore.url} variant="body2" style={{ textDecoration: "none", fontSize: "14px" }}>
            {step.learnMore.text}
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default Step;
