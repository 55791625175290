import "./reactRefresh"; // must be the first file

import ReactDOM from "react-dom";

import AppIndex from "./AppIndex";
import { fullStoryInit } from "./utils/fullStoryInit";
import { sentryInit } from "./utils/sentryInit";
import { windowInit } from "./utils/windowInit";

fullStoryInit();
sentryInit();
windowInit();

ReactDOM.render(<AppIndex />, document.getElementById("root"));
