import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import cfLogo from "../../../assets/cloud-formation-logo.svg";
import terraformLogo from "../../../assets/terraformio.svg";
import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";
import {
  type ClusterConfig,
  type DeploymentMethod,
  DeploymentMethodCloudFormation,
  DeploymentMethodTerraform,
} from "../types";

type Props = {
  clusterConfig: ClusterConfig;
  handleDeploymentSelected: (deployment: DeploymentMethod | "") => void;
  deploymentSelected: DeploymentMethod | "";
};

export const DeploymentStep = ({ clusterConfig, handleDeploymentSelected, deploymentSelected }: Props) => {
  const {
    palette: { primary },
  } = useTheme();
  const [selectedValue, setSelectedValue] = useState<DeploymentMethod | "">(deploymentSelected ?? "");

  const deploymentMethod = useMemo(() => {
    if (
      clusterConfig.cloudFormationStatus !== "deleted" &&
      (clusterConfig.deploymentMethod === "helm" || clusterConfig.deploymentMethod === "kubectl")
    ) {
      return DeploymentMethodCloudFormation;
    } else if (clusterConfig.deploymentMethod === DeploymentMethodTerraform) {
      return DeploymentMethodTerraform;
    }

    return "";
  }, [clusterConfig]);

  useEffect(() => {
    if (clusterConfig.deploymentMethod && deploymentMethod) {
      setSelectedValue(deploymentMethod);
      handleDeploymentSelected(deploymentMethod);
    }
  }, [clusterConfig?.deploymentMethod, deploymentMethod, handleDeploymentSelected, selectedValue]);

  const isTerraformDisabled = deploymentMethod === DeploymentMethodCloudFormation;
  const isCloudFormationDisabled = deploymentMethod === DeploymentMethodTerraform;

  const handleRadioChange = (value: string) => {
    const deployment = value as DeploymentMethod;
    setSelectedValue(deployment);
    handleDeploymentSelected(deployment);
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h1">{onboardingTxt.SELECT_DEPLOYMENT_METHOD}</Typography>
        <Typography color="text.secondary">
          Account: {clusterConfig.accountId}, Region: {clusterConfig.region}, Cluster: {clusterConfig.clusterName},{" "}
          Deployment ID: {clusterConfig.desiredDeploymentId}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Tooltip
          title={isTerraformDisabled ? onboardingTxt.DEPLOYMENT_METHOD_DISABLE(DeploymentMethodCloudFormation) : ""}
        >
          <Box sx={{ width: "49%", height: "150px", display: "flex" }}>
            <Card
              onClick={() => handleRadioChange(DeploymentMethodTerraform)}
              sx={{
                "&:hover": { borderColor: primary.main },
                cursor: "pointer",
                pointerEvents: isTerraformDisabled ? "none" : "auto",
                opacity: isTerraformDisabled ? 0.5 : 1,
              }}
            >
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column" justifyContent="space-between">
                    <RadioGroup value={selectedValue} onChange={(e) => handleRadioChange(e.target.value)}>
                      <FormControlLabel
                        label={<Typography variant="h6">{onboardingTxt.TERRAFORM}</Typography>}
                        value={DeploymentMethodTerraform}
                        control={<Radio />}
                      />
                    </RadioGroup>
                    <Box sx={{ ml: 4 }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        {onboardingTxt.DEPLOYMENT_TERRAFORM}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box sx={{ width: "36px" }}>
                    <Box component="img" src={terraformLogo} sx={{ height: "36px", width: "36px" }} aria-hidden />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Tooltip>
        <Tooltip
          title={isCloudFormationDisabled ? onboardingTxt.DEPLOYMENT_METHOD_DISABLE(DeploymentMethodTerraform) : ""}
        >
          <Box sx={{ width: "49%", height: "150px", display: "flex" }}>
            <Card
              onClick={() => handleRadioChange(DeploymentMethodCloudFormation)}
              sx={{
                "&:hover": { borderColor: primary.main },
                cursor: "pointer",
                pointerEvents: isCloudFormationDisabled ? "none" : "auto",
                opacity: isCloudFormationDisabled ? 0.5 : 1,
              }}
            >
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column" justifyContent="space-between">
                    <RadioGroup value={selectedValue} onChange={(e) => handleRadioChange(e.target.value)}>
                      <FormControlLabel
                        label={<Typography variant="h6">{onboardingTxt.CLOUD_FORMATION}</Typography>}
                        value={DeploymentMethodCloudFormation}
                        control={<Radio />}
                      />
                    </RadioGroup>
                    <Box sx={{ ml: 4 }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        {onboardingTxt.DEPLOYMENT_CLOUD_FORMATION}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box sx={{ width: "36px" }}>
                    <Box component="img" src={cfLogo} sx={{ height: "36px", width: "36px" }} aria-hidden />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
