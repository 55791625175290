import { type AxiosInstance } from "axios";

import {
  type CloudflowDTO,
  type CreateCloudflowInput,
  type DeleteCloudflowInput,
  type TriggerCloudflowResponce,
  type UpdateCloudflowInput,
} from "./types";

export const createCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  createCloudflowInput: CreateCloudflowInput
) => api.post<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow`, createCloudflowInput);

export const deleteCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  deleteCloudflowInput: DeleteCloudflowInput
) => api.delete<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${deleteCloudflowInput.id}`);

export const updateCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  flowId: string,
  updateCloudflowInput: UpdateCloudflowInput
) => api.put<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}`, updateCloudflowInput);

export const triggerCloudflow = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.post<TriggerCloudflowResponce>(`/orchestrator/customers/${customerId}/trigger-cloudflow/${flowId}`);
