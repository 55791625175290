import { useCallback, useState } from "react";

import {
  type AccountManagerModel,
  type AccountManagersCompany,
  AppModel,
  AssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  CustomerModel,
  type CustomersColumnId,
  CustomersColumnsID,
  ProductEnum,
  TierPackageTypes,
  UserModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference, type QueryModel } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import useAccountMangersTeam from "../../Components/hooks/useAccountMangersTeam";
import { useAuthContext } from "../../Context/AuthContext";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { TrendType } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { partnerAssetsMap, ticketNotification } from "../../utils/common";
import { type FirestoreTimestamp } from "../../utils/firebase";
import {
  AwsPartnerAccessHeaders,
  baseColumns,
  columnsConfig,
  columnsPromises,
  GCPPartnerAccessHeaders,
} from "./consts";
import { asyncCalculateTrend, asyncSumCustomersMonthSpend } from "./db";
import { findLastLoginUser, getAWSPermissions, getCustomerTierTrialStatus, getGcpPermissions } from "./helpers";
import {
  type AccountHealthModel,
  type BaseCustomerRowListItem,
  type CloudConnectGCPAccess,
  type Column,
  type ColumnActionType,
  type ColumnTypeAccountManagers,
  type ColumnTypeBigQueryLens,
  type ColumnTypeFlexsave,
  type ColumnTypeGkeCostAllocation,
  type ColumnTypeMonthReport,
  type CustomerListItem,
  type RampPlanAttainment,
  RampPlanStatus,
} from "./types";

export const useSupportAPI = (customerId: string) => {
  const api = useApiContext();

  const fetchActiveTickets = useCallback(async (): Promise<number> => {
    try {
      const { data } = await api.request({
        method: "get",
        url: `support/customers/${customerId}/tickets?quickLoad=true`,
      });
      const allOpenTickets = data.tickets.filter(
        (ticket: { status: string }) => ticket.status !== "closed" && ticket.status !== "solved"
      );
      return allOpenTickets?.length ?? 0;
    } catch (error) {
      return 0;
    }
  }, [api, customerId]);

  return { fetchActiveTickets };
};

export function useCustomerAccountHealth(customerId: string) {
  const [activeUsers, setActiveUsers] = useState<number | null>(null);
  const [lastLogin, setLastLogin] = useState<FirestoreTimestamp | null>(null);
  const [lastLoginUser, setLastLoginUser] = useState<string>("");
  const [gcpAccess, setGcpAccess] = useState<CloudConnectGCPAccess>();
  const [awsAccess, setAwsAccess] = useState<CloudConnectGCPAccess>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTickets, setActiveTickets] = useState<number | null>(null);
  const { fetchActiveTickets } = useSupportAPI(customerId);

  const activeUsersPromise = useCallback(() => {
    const customerRef = getCollection(CustomerModel).doc(customerId);
    return getCollection(UserModel).where("customer.ref", "==", customerRef).get();
  }, [customerId]);

  const customerCloudConnectPromise = useCallback(
    () =>
      getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeGoogleCloud)
        .limit(1)
        .get(),
    [customerId]
  );

  const customerCloudConnectAWSPromise = useCallback(
    () =>
      getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeAmazonWebServices)
        .get(),
    [customerId]
  );

  const customerAWSAssetsPromise = useCallback(() => {
    const customerRef = getCollection(CustomerModel).doc(customerId);
    return getCollection(AssetModel)
      .where("customer", "==", customerRef)
      .where("type", "==", ProductEnum.AmazonWebServices)
      .get();
  }, [customerId]);

  const fetchAsyncActiveTickets = useCallback(() => {
    fetchActiveTickets()
      .then((t) => setActiveTickets(t))
      .catch(() => {
        setActiveTickets(0);
      });
  }, [fetchActiveTickets]);

  const fetchAccountHealthData = useCallback(async () => {
    setLoading(true);
    try {
      fetchAsyncActiveTickets();
      const [users, cloudConnectGCP, cloudConnectAWS, awsAssets] = await Promise.all([
        activeUsersPromise(),
        customerCloudConnectPromise(),
        customerCloudConnectAWSPromise(),
        customerAWSAssetsPromise(),
      ]);
      const lastLoginUserObj = findLastLoginUser(users.docs);
      const gcpAccessData = getGcpPermissions(cloudConnectGCP?.docs[0]?.asModelData());
      const awsAccessData = getAWSPermissions(cloudConnectAWS, awsAssets);
      setActiveUsers(users.size);
      setLastLogin(lastLoginUserObj?.lastLogin ?? null);
      setLastLoginUser(lastLoginUserObj?.id ?? "");
      setGcpAccess(gcpAccessData);
      setAwsAccess(awsAccessData);
    } finally {
      setLoading(false);
    }
  }, [
    fetchAsyncActiveTickets,
    activeUsersPromise,
    customerCloudConnectPromise,
    customerCloudConnectAWSPromise,
    customerAWSAssetsPromise,
  ]);

  const accountHealth: AccountHealthModel & { lastLoginUserId: string } = {
    activeUsers,
    loading,
    lastLogin,
    lastLoginUserId: lastLoginUser,
    gcpAccess,
    awsAccess,
    activeTickets,
  };

  return {
    accountHealth,
    fetchAccountHealthData,
  };
}

export const useMixpanelAPI = (customerId: string) => {
  const api = useApiContext();

  const query = useCallback(
    () =>
      api.request({
        method: "get",
        url: `/v1/mixpanel/active-users-report`,
        params: {
          customerId,
        },
      }),
    [api, customerId]
  );

  return { query };
};

/**
 * Get customer details
 * @param customerId
 */
export function useCustomerDetails(customerId: string) {
  const [activeUsersChart, setActiveUsersChart] = useState(null);
  const { accountHealth, fetchAccountHealthData } = useCustomerAccountHealth(customerId);
  const { query } = useMixpanelAPI(customerId);

  const fetchCustomerDetails = useCallback(async () => {
    await fetchAccountHealthData();
    try {
      const res = await query();
      setActiveUsersChart(res.data);
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [fetchAccountHealthData, query]);

  return {
    accountHealth,
    fetchCustomerDetails,
    activeUsersChart,
  };
}

export const useCustomersEnrichment = (isMyCustomersPage: boolean) => {
  const { partnerCompany, userId, currentUser, isDoitEmployee, isDoitPartner } = useAuthContext({ mustHaveUser: true });
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const { getAccountMangersHierarchy } = useAccountMangersTeam();
  const [userColumns, setUserColumns] = useState<Column[]>([]);
  const [customers, setCustomers] = useState<BaseCustomerRowListItem[]>();

  const getColumnsConfig = useCallback(() => {
    //  Ny Team's Customers mode should has Assigned Account Managers column by default
    const accountManagersColumn = columnsConfig.findIndex(({ id }) => id === CustomersColumnsID.AccountManagers);
    columnsConfig[accountManagersColumn].customizable = isMyCustomersPage;

    return columnsConfig;
  }, [isMyCustomersPage]);

  const getColumnsPerMode = useCallback(
    (columns: CustomersColumnId[]): CustomersColumnId[] => {
      //  Ny Team's Customers mode should has Assigned Account Managers column by default
      if (!isMyCustomersPage && !columns?.includes(CustomersColumnsID.AccountManagers)) {
        return [...columns, CustomersColumnsID.AccountManagers];
      }

      return columns;
    },
    [isMyCustomersPage]
  );

  const getBaseColumns = useCallback((): CustomersColumnId[] => getColumnsPerMode(baseColumns), [getColumnsPerMode]);

  const updateUserColumns = useCallback(
    async (newColumns: CustomersColumnId[]) =>
      getCollection(AppModel).doc("my-customers").collection("doersColumnsConfig").doc(userId).set({
        columns: newColumns,
      }),
    [userId]
  );

  const loadUserColumns = useCallback(async () => {
    if (isDoitPartner) {
      if (partnerAssetsMap[partnerCompany] === AssetTypeAmazonWebServices) {
        return AwsPartnerAccessHeaders;
      }
      if (partnerAssetsMap[partnerCompany] === AssetTypeGoogleCloud) {
        return GCPPartnerAccessHeaders;
      }
      return getBaseColumns();
    }

    try {
      const tryDoc = async (docId: string) => {
        const userColumnsSnap = await getCollection(AppModel)
          .doc("my-customers")
          .collection("doersColumnsConfig")
          .doc(docId)
          .get();

        if (userColumnsSnap.exists()) {
          const userConfigColumnData = userColumnsSnap.asModelData();
          return getColumnsPerMode(userConfigColumnData?.columns ?? []);
        }
      };

      const configByUserId = await tryDoc(userId);
      if (configByUserId) {
        return configByUserId;
      }

      const configByCurrentUser = await tryDoc(currentUser.uid);
      if (configByCurrentUser) {
        return configByCurrentUser;
      }

      return getBaseColumns();
    } catch (error) {
      consoleErrorWithSentry(error);
      throw error;
    }
  }, [isDoitPartner, partnerCompany, getBaseColumns, userId, currentUser.uid, getColumnsPerMode]);

  const getUserColumnsConfig = useCallback(
    (columnsArr: CustomersColumnId[]): Column[] =>
      // load user columns
      columnsArr.map((column) => getColumnsConfig().find((c) => c.id === column) ?? getColumnsConfig()[0]) ?? [],
    [getColumnsConfig]
  );

  const fetchColumnsActions = useCallback(
    (columnsConfigArr: Column[]) => {
      const actionsToTrigger = new Set(
        columnsConfigArr.flatMap((column) => {
          if (column.id in columnsPromises) {
            return [column.id];
          }

          return [];
        })
      );

      return Array.from(actionsToTrigger).map((action) => async (ids: string[]) => {
        const data = await columnsPromises[action](ids, partnerCompany, isDoitEmployee);
        return data.map((d) => (d ? ({ ...d, columnId: action } as const) : null));
      });
    },
    [isDoitEmployee, partnerCompany]
  );

  const getRampPlanStatus = (
    customerActionsColumns: ColumnActionType[],
    colData: RampPlanAttainment,
    contractColumnId: CustomersColumnsID.AwsContracts | CustomersColumnsID.GcpContracts
  ): RampPlanStatus => {
    const OnTime = colData.onTrack ? RampPlanStatus.OnTime : RampPlanStatus.Lagging;
    const rampPlanStatus = colData.status ? OnTime : RampPlanStatus.InActive;
    const contracts = customerActionsColumns.filter((c) => {
      if (c.columnId === contractColumnId) {
        return c.contract.isCommitment;
      }
    });

    if (!contracts.length) {
      return RampPlanStatus.Na;
    }

    return rampPlanStatus;
  };

  const getCustomerItem = async (
    customer: CustomerListItem,
    columnsConfigData: Column[]
  ): Promise<BaseCustomerRowListItem> => ({
    ...customer,
    trialStatus: getCustomerTierTrialStatus(customer, TierPackageTypes.NAVIGATOR),
    columnsConfig: columnsConfigData,
  });

  const buildColumns = useCallback(
    async (
      customer: CustomerListItem,
      customerActionsColumns: ColumnActionType[],
      columnsConfigData: Column[]
    ): Promise<BaseCustomerRowListItem> => {
      const customerItem = await getCustomerItem(customer, columnsConfigData);

      const addThisMonthData = async (colData: ColumnTypeMonthReport) => {
        const report = colData.report;
        const trend = (await asyncCalculateTrend(report)) ?? 0;
        const trendDirection = trend !== null && trend >= 0 ? TrendType.Up : TrendType.Down;

        customerItem.thisMonth =
          (await asyncSumCustomersMonthSpend(report, { lastMonth: false, countAllDays: true })) ?? 0;
        customerItem.lastMonth = (await asyncSumCustomersMonthSpend(report, { lastMonth: true })) ?? 0;
        customerItem.trend = trend;
        customerItem.trendDirection = trendDirection;
      };

      for (const configColumn of getColumnsConfig()) {
        if (configColumn.id === CustomersColumnsID.Checkbox) {
          continue;
        }

        const colData = customerActionsColumns.find(
          (customerActionsColumn) => customerActionsColumn.columnId === configColumn.id
        );

        if (!colData) {
          continue;
        }

        switch (configColumn.id) {
          case CustomersColumnsID.AccountManagers:
            customerItem.accountManagers = (colData as ColumnTypeAccountManagers).accountManagers;
            break;

          case CustomersColumnsID.FlexsaveAWS:
            customerItem.flexsaveAWS = (colData as ColumnTypeFlexsave).aws;
            break;

          case CustomersColumnsID.BigqueryLens:
            customerItem.bigqueryLens = (colData as ColumnTypeBigQueryLens).bigQueryLens;
            break;

          case CustomersColumnsID.GkeCostAllocation:
            customerItem.gkeCostAllocation = (colData as ColumnTypeGkeCostAllocation).gkeCostAllocation;
            break;

          case CustomersColumnsID.AwsRampPlanAttainment:
            customerItem[CustomersColumnsID.AwsRampPlanStatus] = getRampPlanStatus(
              customerActionsColumns,
              colData as RampPlanAttainment,
              CustomersColumnsID.AwsContracts
            );

            (customerItem as any)[configColumn.id] = colData ?? null;
            break;

          case CustomersColumnsID.GcpRampPlanAttainment:
            customerItem[CustomersColumnsID.GcpRampPlanStatus] = getRampPlanStatus(
              customerActionsColumns,
              colData as RampPlanAttainment,
              CustomersColumnsID.GcpContracts
            );
            (customerItem as any)[configColumn.id] = colData ?? null;
            break;

          case CustomersColumnsID.ThisMonth:
            await addThisMonthData(colData as ColumnTypeMonthReport);
            break;

          default:
            customerItem[configColumn.id] = colData;
            break;
        }
      }
      return customerItem;
    },
    [getColumnsConfig]
  );

  const loadCustomerDataEnrichment = useCallback(
    async (customersList: CustomerListItem[], userConfiguredColumns: CustomersColumnId[]) => {
      const customersId = customersList.map((c) => c.id);
      const columnsConfigData = getUserColumnsConfig(userConfiguredColumns);
      setUserColumns(columnsConfigData);

      const columnsActions = fetchColumnsActions(columnsConfigData);

      const columnsData = await Promise.all(columnsActions.map((columnsAction) => columnsAction(customersId)));

      const enriched = await Promise.all(
        customersList.map(async (customer, customerIndex) => {
          const customerColumns = columnsData.flatMap((columnData) => {
            const actionData = columnData[customerIndex];

            if (!actionData) {
              return [];
            }

            return actionData;
          });

          return buildColumns(customer, customerColumns, columnsConfigData);
        })
      );

      setCustomers(enriched);
    },
    [buildColumns, fetchColumnsActions, getUserColumnsConfig]
  );

  const commonGetCustomerQueries = useCallback(
    async (getAccountManagers: () => Promise<ModelReference<AccountManagerModel>[] | undefined>) => {
      const accountManagersRefs = await getAccountManagers();

      if (accountManagersRefs === undefined) {
        return undefined;
      }

      const q: QueryModel<CustomerModel>[] = [];

      if (isDoitEmployee) {
        q.push(getCollection(CustomerModel).where("subscribers", "array-contains", currentUser.email));
      }

      //  followed customers
      for (const amRef of accountManagersRefs) {
        const company: AccountManagersCompany = partnerCompany ?? "doit";
        q.push(
          getCollection(CustomerModel).where(
            "accountTeam",
            "array-contains-any",
            ticketNotification.map((n) => ({
              company,
              ref: amRef,
              supportNotificationLevel: n.value,
            }))
          )
        );
      }

      return q;
    },
    [currentUser.email, isDoitEmployee, partnerCompany]
  );

  const getMyTeamCustomerQueries = useCallback(
    async () => commonGetCustomerQueries(getAccountMangersHierarchy),
    [commonGetCustomerQueries, getAccountMangersHierarchy]
  );

  const getMyCustomerQueries = useCallback(
    async () => commonGetCustomerQueries(async () => (accountManager?.ref ? [accountManager.ref] : [])),
    [commonGetCustomerQueries, accountManager?.ref]
  );

  return {
    userColumns,
    loadCustomerDataEnrichment,
    loadUserColumns,
    updateUserColumns,
    customers,
    getMyTeamCustomerQueries,
    getMyCustomerQueries,
    getBaseColumns,
    getColumnsConfig,
  };
};
