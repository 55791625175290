import { useCallback, useEffect, useMemo, useState } from "react";

import { Redirect, useHistory } from "react-router-dom";
import { CurrencyCodes, Renderer, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import CopyIcon from "@mui/icons-material/FileCopyRounded";
import ShareIcon from "@mui/icons-material/ShareRounded";
import { Box, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { isAxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { attributionGroupsText } from "../../../assets/texts";
import { useBackUrl } from "../../../Components/hooks/useBackUrl";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import SaveAsComponent from "../../../Components/SaveComponents/SaveAsComponent";
import SaveComponent from "../../../Components/SaveComponents/SaveComponent";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import Title from "../../../Components/Title/Title";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useTierLimitReachedAnalyticsAttributionGroups } from "../../../Context/AnalyticsTierProvider";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { useErrorSnackbar } from "../../Integrations/Slack/utils";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import PreviewReport from "../previewReport/PreviewReport";
import { getCols, getFilteredAttributionGroups, getRows } from "../previewReport/utils";
import { type CloudAnalyticsHistoryState } from "../types";
import { isEditor, isOwner } from "../utilities";
import { copyAttributionGroup, createAttributionGroup, deleteAttributionGroup, editAttributionGroup } from "./api";
import { AttributionGroupDragAndDrop } from "./AttributionGroupDragGroup";
import { AttributionGroupShareDialog } from "./AttributionGroupsShareDialog";
import { type AttributionGroupWithRef } from "./types";
import { getAttributionsIDsFromAG } from "./utils";

const maxDescriptionLength = 1000;
const maxTitleLength = 64;
const NOT_ALLOWED_CHARS_REGEX = /[^a-zA-Z0-9-_.,:()[\]%\s]/g;

const styles = {
  cardHeader: {
    flexWrap: { xs: "wrap", sm: "nowrap" },
    "& .MuiCardHeader-content": {
      minWidth: 0,
    },
    "& .MuiCardHeader-action": {
      width: { xs: "100%", sm: "unset" },
      mt: { xs: 1, sm: "auto" },
      mb: { sm: "auto" },
    },
  },
  cardHeaderActionStyles: { mr: { sm: 0.5, md: 3.0 } },
};

type Props = {
  selectedAttributionGroup?: AttributionGroupWithRef;
  refreshAttributionGroupsMetadata: () => void;
};

export const AttributionGroups = ({ selectedAttributionGroup, refreshAttributionGroupsMetadata }: Props) => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const { isFeatureEntitled } = useTier();
  const api = useApiContext();
  const backURL = useBackUrl();
  const errorSnackbar = useErrorSnackbar();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const routeMatchURL = useRouteMatchURL();
  const successSnackbar = useSuccessSnackbar();
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { handleDeleteAttributionGroupValidationResponse } = useAnalyticsContext();
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();

  const [description, setDescription] = useState(selectedAttributionGroup?.data.description ?? "");
  const [draft] = useState(!selectedAttributionGroup);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(selectedAttributionGroup?.data.name ?? "");
  const [nullFallback, setNullFallback] = useState(selectedAttributionGroup?.data?.nullFallback ?? "");
  const [runQuery, setRunQuery] = useState(false);
  const [renderer, setRenderer] = useState(Renderer.STACKED_COLUMN_CHART);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [dragAndDropGroups, setDragAndDropGroups] = useState<Record<string, string[]>>({
    main: getAttributionsIDsFromAG(selectedAttributionGroup),
  });
  const [isCreating, setIsCreating] = useState(false);
  const isCreatePage = routeMatchURL.includes("create");

  const isUserEditor =
    draft || (!!currentUser && selectedAttributionGroup && isEditor(currentUser.email, selectedAttributionGroup.data));

  const isUserOwner = useMemo(
    () => draft || (selectedAttributionGroup && isOwner(currentUser.email, selectedAttributionGroup.data)),
    [draft, selectedAttributionGroup, currentUser.email]
  );

  const attributionGroupsTier = useTierLimitReachedAnalyticsAttributionGroups();
  const isValidAttributionGroup = dragAndDropGroups && dragAndDropGroups.main.length > 0;

  const enableSave =
    isFeatureEntitled("analytics:attributionGroups") &&
    hasUnsavedChanges &&
    isValidAttributionGroup &&
    name.length > 0 &&
    isUserEditor;

  const handleChange = (values: { title?: string; description?: string }) => {
    if (values.title !== undefined) {
      setName(values.title);
    } else if (values.description !== undefined) {
      setDescription(values.description);
    }
  };

  const handleNullFallbackChange = ({ type, target: { value } }) => {
    if (value?.length <= maxTitleLength) {
      setNullFallback(value);
      setHasUnsavedChanges(true);
      if (type === "blur") {
        setRunQuery(true);
      }
    }
  };

  const handleDelete = useCallback(async () => {
    try {
      if (selectedAttributionGroup?.ref.id) {
        setIsDeleting(true);
        await deleteAttributionGroup({
          attributionGroupId: selectedAttributionGroup.ref.id,
          api,
          customerId: customer.id,
        });
        refreshAttributionGroupsMetadata();
        successSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUP_SUCCESS);
        mixpanel.track("analytics.attributionGroup.delete.single", {
          attributionGroupId: selectedAttributionGroup?.ref.id,
        });
        clearPendingPrompt();
      }
    } catch (error) {
      consoleErrorWithSentry(error);

      if (isAxiosError(error) && error.response?.status === 409) {
        handleDeleteAttributionGroupValidationResponse(error.response?.data);
      } else {
        errorSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUPS_ERROR);
      }
    }
    setIsDeleting(false);
  }, [
    selectedAttributionGroup?.ref?.id,
    api,
    customer.id,
    refreshAttributionGroupsMetadata,
    handleDeleteAttributionGroupValidationResponse,
    successSnackbar,
    clearPendingPrompt,
    errorSnackbar,
  ]);

  const handleCopyAttribution = useCallback(async () => {
    try {
      if (selectedAttributionGroup) {
        const attributionGroupId = await copyAttributionGroup(api, selectedAttributionGroup, customer.id);
        refreshAttributionGroupsMetadata();
        successSnackbar(attributionGroupsText.CLONE_ATTRIBUTION_GROUP_SUCCESS);
        mixpanel.track("analytics.attribution-groups.duplicate", {
          attributionGroupId: selectedAttributionGroup?.ref.id,
        });
        setHasUnsavedChanges(false);
        history.push(routeMatchURL.replace(selectedAttributionGroup.ref.id, attributionGroupId));
      }
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUPS_ERROR);
    }
  }, [
    api,
    customer.id,
    errorSnackbar,
    history,
    refreshAttributionGroupsMetadata,
    routeMatchURL,
    selectedAttributionGroup,
    successSnackbar,
  ]);

  const handleSave = useCallback(async () => {
    try {
      if (!selectedAttributionGroup?.ref?.id) {
        setIsCreating(true);
        const attributionGroupId = await createAttributionGroup({
          api,
          customerId: customer.id,
          attributions: dragAndDropGroups.main,
          name,
          description,
          ...(nullFallback && { nullFallback }),
        });
        refreshAttributionGroupsMetadata();
        mixpanel.track("analytics.attribution-groups.create", { attributionGroupId, from: "attribution-groups" });
        successSnackbar(attributionGroupsText.CREATE_ATTRIBUTION_GROUP_SUCCESS);
        setHasUnsavedChanges(false);
        setIsCreating(false);
        clearPendingPrompt();
        history.push(routeMatchURL.replace("create", attributionGroupId));
      } else {
        await editAttributionGroup({
          api,
          attributionGroupId: selectedAttributionGroup.ref.id,
          customerId: customer.id,
          attributions: dragAndDropGroups.main,
          name,
          description,
          ...(nullFallback && { nullFallback }),
        });
        refreshAttributionGroupsMetadata();
        successSnackbar(attributionGroupsText.UPDATE_ATTRIBUTION_GROUP_SUCCESS);
        mixpanel.track("analytics.attribution-groups.update", {
          attributionGroupId: selectedAttributionGroup.ref.id,
        });
      }
      setHasUnsavedChanges(false);
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errorSnackbar(attributionGroupsText.CREATE_ATTRIBUTION_GROUP_ERROR);
      throw new Error(error.message);
    }
  }, [
    selectedAttributionGroup?.ref.id,
    api,
    customer.id,
    dragAndDropGroups.main,
    name,
    description,
    nullFallback,
    refreshAttributionGroupsMetadata,
    clearPendingPrompt,
    successSnackbar,
    history,
    routeMatchURL,
    errorSnackbar,
  ]);

  const handleSaveAs = useCallback(
    async (AGname: string) => {
      try {
        const attributionGroupId = await createAttributionGroup({
          api,
          customerId: customer.id,
          attributions: dragAndDropGroups.main,
          name: AGname,
          description,
        });
        refreshAttributionGroupsMetadata();
        mixpanel.track("analytics.attribution-groups.save-as", { attributionGroupId });
        setHasUnsavedChanges(false);
        if (selectedAttributionGroup?.ref.id) {
          history.push(routeMatchURL.replace(selectedAttributionGroup.ref.id, attributionGroupId));
        }
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(attributionGroupsText.CREATE_ATTRIBUTION_GROUP_ERROR);
        throw new Error(error.message);
      }
    },
    [
      api,
      customer.id,
      dragAndDropGroups.main,
      description,
      refreshAttributionGroupsMetadata,
      selectedAttributionGroup?.ref.id,
      history,
      routeMatchURL,
      errorSnackbar,
    ]
  );

  const handleOpenShareDialog = useCallback(() => {
    setShareDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? backURL);
  }, [history, backURL]);

  const handleDragAndDropChange = useCallback(
    (newGroups: Record<string, string[]>) => {
      setDragAndDropGroups(newGroups);
      setHasUnsavedChanges(true);
      setRunQuery(true);
    },
    [setDragAndDropGroups]
  );

  useEffect(() => {
    if (
      (selectedAttributionGroup?.data.name ?? "") !== name ||
      (selectedAttributionGroup?.data.description ?? "") !== description
    ) {
      setHasUnsavedChanges(true);
    }
  }, [selectedAttributionGroup, name, description]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      activatePendingPrompt();
    } else {
      clearPendingPrompt();
    }
  }, [hasUnsavedChanges, activatePendingPrompt, clearPendingPrompt]);

  useMountEffect(() => {
    if (selectedAttributionGroup?.ref.id) {
      mixpanel.track("analytics.attribution-groups.open", {
        attributionGroupId: selectedAttributionGroup.ref.id,
      });
    }
  });

  const deleteButton = (
    <Box>
      <IconButton size="small" onClick={handleDelete} disabled={!isUserOwner} data-cy="delete-icon">
        {isDeleting ? (
          <CircularProgress disableShrink={true} color="inherit" size={24} thickness={4.5} />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>
    </Box>
  );

  const titleTransformation = (title: string) => title.replace(NOT_ALLOWED_CHARS_REGEX, "");
  const attributionGroupsForPreview = [
    {
      id: selectedAttributionGroup?.ref.id,
      name: selectedAttributionGroup?.data.name,
      attributions: dragAndDropGroups.main,
      ...(nullFallback && { nullFallback }),
    },
  ];
  const filteredAttributionGroups = getFilteredAttributionGroups(attributionGroupsForPreview, attributions);

  if (isCreatePage && attributionGroupsTier.limitReached && !isCreating) {
    return <Redirect to="." />;
  }

  if (isCreatePage && !isCreating && attributionGroupsTier.loading) {
    return null;
  }

  return (
    <>
      <CardHeader
        data-cy="attributionGroups-header"
        sx={styles.cardHeader}
        disableTypography={true}
        title={
          <Title
            data-cy="attributionGroups-name"
            values={{ title: name, description }}
            onChange={handleChange}
            handleBack={handleClose}
            placeholder={attributionGroupsText.ATTRIBUTION_GROUP_NAME}
            disabled={!isUserEditor || !isFeatureEntitled("analytics:attributionGroups")}
            maxTitleLength={maxTitleLength}
            maxDescriptionLength={maxDescriptionLength}
            titleTransformation={titleTransformation}
          />
        }
        action={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {!draft && [
              isUserOwner && (
                <Tooltip
                  title={attributionGroupsText.SHARE_ATTRIBUTION_GROUP}
                  sx={styles.cardHeaderActionStyles}
                  key="share"
                >
                  <IconButton size="small" onClick={handleOpenShareDialog} data-cy="share-icon">
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              ),
              <Tooltip
                title={attributionGroupsText.CLONE_ATTRIBUTION_GROUP}
                sx={styles.cardHeaderActionStyles}
                key="clone"
              >
                <IconButton
                  data-testid="attributionClone"
                  onClick={handleCopyAttribution}
                  disabled={Boolean(attributionGroupsTier.limitReached)}
                  size="small"
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>,
              <Tooltip
                title={attributionGroupsText.DELETE_ATTRIBUTION_GROUP}
                sx={styles.cardHeaderActionStyles}
                key="delete"
              >
                {deleteButton}
              </Tooltip>,
            ]}
            {!isUserEditor && isFeatureEntitled("analytics:attributionGroups") ? (
              <SaveAsComponent
                disabled={!isValidAttributionGroup || Boolean(attributionGroupsTier.limitReached)}
                onSaveAs={handleSaveAs}
                saveAsTitle={attributionGroupsText.SAVE_AS_TITLE}
                textFieldProps={{
                  helperText: attributionGroupsText.SAVE_AS_HELPER_TEXT,
                  label: attributionGroupsText.SAVE_AS_LABEL,
                }}
                successMessage={attributionGroupsText.CREATE_ATTRIBUTION_GROUP_SUCCESS}
              />
            ) : (
              <SaveComponent disabled={!enableSave} onSave={handleSave} />
            )}
          </Box>
        }
      />
      <Grid container pl="20px">
        <Grid item mb={2} xs={12}>
          <AttributionGroupDragAndDrop
            attributions={attributions}
            attributionsInGroup={dragAndDropGroups}
            setAttributionsInGroup={handleDragAndDropChange}
            handleNullFallbackChange={handleNullFallbackChange}
            nullFallback={nullFallback}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewReport
            previewData={{
              attributionGroupsPayload: filteredAttributionGroups,
              attributionGroups: attributionGroupsForPreview,
              currency: CurrencyCodes.USD,
              timeInterval: TimeInterval.DAY,
              timeRangeOptions: {
                mode: TimeSettingsMode.Last,
                time: TimeInterval.MONTH,
                amount: 3,
                includeCurrent: true,
              },
              scope: [],
              rows: getRows(attributions.length, filteredAttributionGroups),
              filters: [],
              cols: getCols(metadataSnapshots ?? [], TimeInterval.DAY),
            }}
            attributions={attributions}
            runQuery={runQuery}
            setRunQuery={setRunQuery}
            renderer={renderer}
            setRenderer={setRenderer}
          />
        </Grid>
      </Grid>
      {shareDialogOpen && selectedAttributionGroup && (
        <AttributionGroupShareDialog
          title={attributionGroupsText.SHARE_ATTRIBUTION_GROUP}
          attributionGroups={[{ ...selectedAttributionGroup.data, id: selectedAttributionGroup.ref.id }]}
          shareDialogOpen={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
