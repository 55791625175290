import { Box, Checkbox, FormControlLabel, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { type WidgetDescription } from "../../../../../Context/DashboardContext";

type Props = {
  widget: WidgetDescription;
  disabled: boolean;
  selected: boolean;
  onClick: (name: string) => void;
};

export function AddReportCard({ widget, selected, disabled, onClick }: Props) {
  const details = [
    {
      label: "Last modified",
      value:
        widget.timeUpdated &&
        DateTime.fromJSDate(widget.timeUpdated).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
    },
    {
      label: "Owner",
      value: widget?.owner,
    },
  ];

  return (
    <Stack>
      <Tooltip title={disabled ? "This report has already been added to this dashboard" : ""}>
        <Box>
          <FormControlLabel
            disabled={disabled}
            onChange={() => onClick(widget.name)}
            control={<Checkbox checked={selected} />}
            label={
              <Typography color={disabled ? "text.disabled" : undefined} variant="body2">
                {widget.title}
              </Typography>
            }
          />
          <Stack pl={4}>
            <Typography variant="body2" color={disabled ? "text.disabled" : "text.secondary"}>
              {widget.description}
            </Typography>
            <Stack direction="row" spacing={1} color={disabled ? "text.disabled" : "text.secondary"} pt={1}>
              {details.map(({ label, value }) => (
                <Typography key={label} display="inline" variant="caption" minWidth="48%">
                  <Typography fontWeight="500" variant="caption">
                    {label}:
                  </Typography>{" "}
                  {value}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Tooltip>
    </Stack>
  );
}
