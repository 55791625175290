import { type ReactNode, useMemo, useState } from "react";

import { useHistory } from "react-router";
import {
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  type EntityModel,
  type GoogleCloudAssetModel,
  type GoogleCloudDirectAssetModel,
  type GoogleCloudProjectAssetModel,
  type GoogleCloudStandaloneAssetModel,
  SaaSConsoleType,
} from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";
import find from "lodash/find";

import { GCTabAssetsHeaderText } from "../../../assets/texts";
import { type FilterTableToolbarProps } from "../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { type ToolbarButton } from "../../../Components/FilterTable/types";
import Hide from "../../../Components/HideChildren/Hide";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type AnyAsset, type GoogleCloudAssetsType } from "../../../types";
import { assetTypeName, clouds, getSaaSConsoleOnboardingRoute } from "../../../utils/common";
import CreateGoogleCloudAsset from "../Forms/CreateGoogleCloudAsset";
import GcAssetTableDirect from "../GCTab/Table/Direct/GcAssetTableDirect";
import GcAssetTablePartner from "../GCTab/Table/Partner/GcAssetTablePartner";
import GcAssetTableProjects from "../GCTab/Table/Projects/GcAssetTableProjects";
import { type GcpTableMode, titleAppendText } from "../GCTab/utils";
import { useAllowedToAddAssets, useIsThereAnySignedContract } from "../hooks";
import { type AssetsTabPropsOuterProps } from "../types";
import CreateGoogleCloudDirectAssetForm from "./components/CreateGoogleDirectAssetForm";

const GoogleCloudAssetsTab = ({
  assets,
  assetTypeKey,
  tableMode = AssetTypeGoogleCloud,
  onRemoveAsset,
}: AssetsTabPropsOuterProps<GoogleCloudAssetsType, GcpTableMode>) => {
  const { entities, customer } = useCustomerContext();
  const [createBillingAccountDialogOpen, setCreateBillingAccountDialogOpen] = useState(false);
  const [createDirectAccountAssetDialogOpen, setCreateDirectAccountAssetDialogOpen] = useState(false);
  const eligibleEntities = useMemo<EntityModel[]>(() => entities?.filter((e) => e.active), [entities]);
  const { userRoles } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const history = useHistory();
  const { allowedToAddResoldAssets, allowedToAddStandaloneAssets } = useAllowedToAddAssets(AssetTypeGoogleCloud);

  const tableAssetsGoogleCloud: AnyAsset<GoogleCloudAssetModel>[] = useMemo(() => {
    if (!assets) {
      return [];
    }
    if (tableMode !== AssetTypeGoogleCloud) {
      return [];
    }
    return assets.filter((asset): asset is AnyAsset<GoogleCloudAssetModel> => asset.data.type === AssetTypeGoogleCloud);
  }, [assets, tableMode]);

  const tableAssetsGoogleCloudDirect = useMemo(() => {
    if (!assets) {
      return [];
    }
    if (tableMode !== AssetTypeGoogleCloudDirect) {
      return [];
    }
    return assets?.filter((asset): asset is AnyAsset<GoogleCloudDirectAssetModel | GoogleCloudStandaloneAssetModel> =>
      [AssetTypeGoogleCloudDirect, AssetTypeGoogleCloudStandalone].includes(asset.data.type)
    );
  }, [assets, tableMode]);

  const tableAssetsGoogleCloudProject: AnyAsset<GoogleCloudProjectAssetModel>[] = useMemo(() => {
    if (!assets) {
      return [];
    }
    if (tableMode !== AssetTypeGoogleCloudProject) {
      return [];
    }
    return assets.filter(
      (asset): asset is AnyAsset<GoogleCloudProjectAssetModel> => asset.data.type === AssetTypeGoogleCloudProject
    );
  }, [assets, tableMode]);

  const tableAssetsGoogleCloudProjectStandalone: AnyAsset<GoogleCloudProjectAssetModel>[] = useMemo(() => {
    if (!assets) {
      return [];
    }
    if (tableMode !== AssetTypeGoogleCloudProjectStandalone) {
      return [];
    }
    return assets.filter(
      (asset): asset is AnyAsset<GoogleCloudProjectAssetModel> =>
        asset.data.type === AssetTypeGoogleCloudProjectStandalone || asset.data.type === AssetTypeGoogleCloudProject
    );
  }, [assets, tableMode]);

  const createGCAssetDialogs = (
    <>
      {createBillingAccountDialogOpen && (
        <CreateGoogleCloudAsset
          onClose={() => {
            setCreateBillingAccountDialogOpen(false);
          }}
          entities={eligibleEntities}
        />
      )}

      {createDirectAccountAssetDialogOpen && (
        <CreateGoogleCloudDirectAssetForm
          onClose={() => {
            setCreateDirectAccountAssetDialogOpen(false);
          }}
        />
      )}
    </>
  );

  const shouldCreateDialogsOpen = createBillingAccountDialogOpen || createDirectAccountAssetDialogOpen;
  const assetTypeText: string = assetTypeName(assetTypeKey);

  const imgSrc: string = find(clouds, { name: assetTypeText })?.icon || "";

  const { isThereAnySignedContract } = useIsThereAnySignedContract({
    entityIds: entities?.map((e) => e.id) ?? [],
    typeFilter: ["google-cloud", "looker", "google-geolocation-services"],
  });

  const toolbarProps: FilterTableToolbarProps = {
    title: (
      <Hide mdDown>
        <Typography variant="h1" sx={{ mr: "auto", fontWeight: "medium" }} data-cy="gcAssetsTitle">
          <Box
            component="img"
            src={imgSrc}
            sx={{ height: "25px", mb: "-4px", mr: 1 }}
            alt="Google Cloud icon"
            aria-hidden
            data-cy="gcAssetsIconImage"
          />
          {`${assetTypeText} - ${titleAppendText[tableMode]}`}
        </Typography>
      </Hide>
    ),
  };

  if (userRoles?.assetsManager) {
    let toolbarButtons: ToolbarButton[] = [];

    if (
      allowedToAddResoldAssets &&
      (tableMode === AssetTypeGoogleCloud ||
        tableMode === AssetTypeGoogleCloudProject ||
        tableMode === AssetTypeGoogleCloudDirect)
    ) {
      toolbarButtons = [
        {
          text: GCTabAssetsHeaderText.NEW_BILLING_ACCOUNT,
          onClick: () => setCreateBillingAccountDialogOpen(true),
          "data-cy": "gcAssetsNewButton",
          disabled: !isThereAnySignedContract && !isDoitEmployee,
          tooltipTitle:
            !isThereAnySignedContract && !isDoitEmployee
              ? "Finalize your contract with DoiT to add billing account"
              : "",
        },
        {
          text: GCTabAssetsHeaderText.LINK_DIRECT_ACCOUNT,
          onClick: () => setCreateDirectAccountAssetDialogOpen(true),
          "data-cy": "gcAssetsLinkButton",
          disabled: !isThereAnySignedContract && !isDoitEmployee,
          tooltipTitle:
            !isDoitEmployee && !isThereAnySignedContract
              ? "Finalize your contract with DoiT to link direct account"
              : "",
        },
      ];
    }

    if (
      (tableMode === AssetTypeGoogleCloudDirect || tableMode === AssetTypeGoogleCloudProjectStandalone) &&
      allowedToAddStandaloneAssets
    ) {
      toolbarButtons = [
        ...toolbarButtons,
        {
          text: GCTabAssetsHeaderText.CONNECT_NEW_ACCOUNT,
          onClick: () => history.push(getSaaSConsoleOnboardingRoute(customer, SaaSConsoleType.GCP)),
          "data-cy": "gcAssetsConnectNewButton",
        },
      ];
    }

    toolbarProps.primaryButton = toolbarButtons[0];
    toolbarProps.secondaryButtons = toolbarButtons.slice(1);
  }

  const tables: Record<
    "google-cloud" | "google-cloud-direct" | "google-cloud-project" | "google-cloud-project-standalone",
    ReactNode
  > = {
    [AssetTypeGoogleCloud]: (
      <GcAssetTablePartner items={tableAssetsGoogleCloud} onRemoveAsset={onRemoveAsset} toolbar={toolbarProps} />
    ),
    [AssetTypeGoogleCloudDirect]: <GcAssetTableDirect items={tableAssetsGoogleCloudDirect} toolbar={toolbarProps} />,
    [AssetTypeGoogleCloudProject]: (
      <GcAssetTableProjects
        items={tableAssetsGoogleCloudProject}
        onRemoveAsset={onRemoveAsset}
        toolbar={toolbarProps}
      />
    ),
    [AssetTypeGoogleCloudProjectStandalone]: (
      <GcAssetTableProjects
        items={tableAssetsGoogleCloudProjectStandalone}
        onRemoveAsset={onRemoveAsset}
        toolbar={toolbarProps}
      />
    ),
  };

  if (assets === undefined) {
    return null;
  }
  return (
    <Box>
      {tables[tableMode]}
      {shouldCreateDialogsOpen && createGCAssetDialogs}
    </Box>
  );
};

export default GoogleCloudAssetsTab;
