import { useCallback, useState } from "react";

import ClearIcon from "@mui/icons-material/ClearRounded";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { type CloudflowTemplate } from "../../types";

type FromTemplateStepProps = {
  templateId: string | null;
  setTemplateId: (templateId: string | null) => void;
  templates: CloudflowTemplate[];
};
const FromTemplateStep = ({ templateId, setTemplateId, templates }: FromTemplateStepProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState(templates);

  const onSearchTextChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
      setFilteredTemplates(
        templates.filter((template) => template.name.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    },
    [templates]
  );

  return (
    <Box mb={-2.5}>
      <Typography variant="subtitle1" fontWeight={500}>
        {cloudflowTexts.SELECT_YOUR_TEMPLATE}
      </Typography>
      <Box mb={1} />
      <Box>
        <Box component={FormControl} width="100%">
          <TextField
            data-cy="cloudflow-templates-search"
            fullWidth
            onChange={onSearchTextChange}
            placeholder={cloudflowTexts.SEARCH_TEMPLATES}
            value={searchValue}
            InputProps={{
              endAdornment: searchValue && (
                <IconButton data-cy="report-templates-clear-search" size="small" onClick={() => setSearchValue("")}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
          <Box mb={1} />
          <Box height={66 * 3 + 2 * 8 + 30} overflow="scroll">
            <Box
              component={RadioGroup}
              value={templateId}
              name="cloudflowKind"
              onChange={(e) => setTemplateId(e.target.value)}
              gap={1}
              mb={1}
            >
              {filteredTemplates.map((template) => (
                <Box
                  width="100%"
                  component={Card}
                  key={template.id}
                  raised={template.id === templateId}
                  px={1}
                  sx={{
                    borderColor: template.id === templateId ? "primary.main" : undefined,
                  }}
                >
                  <Box
                    component={FormControlLabel}
                    display="flex"
                    p={1}
                    mr={0}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                        m: 0,
                      },
                    }}
                    label={
                      <Box px={1} width="100%">
                        <Box component={Typography} variant="body2">
                          {template.name}
                        </Box>
                        <Box component={Typography} variant="caption" color="text.secondary">
                          {template.description}
                        </Box>
                      </Box>
                    }
                    control={<Radio sx={{ width: 38, height: 38 }} />}
                    value={template.id}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FromTemplateStep;
