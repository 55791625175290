import { AssetModel, AssetSettingModel, AssetTypeLooker, type LookerSku } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Contracts } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { type PayerAccountOption } from "./types";

export const accNameNum = (accName: string): string => accName?.slice(accName?.indexOf("#") + 1);

export const sortPayersByFriendlyName = (accountsList: PayerAccountOption[]): PayerAccountOption[] =>
  [...accountsList].sort((a, b) => (parseInt(accNameNum(a.label)) > parseInt(accNameNum(b.label)) ? 1 : -1));

export function toSentenceCase(str: string) {
  try {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  } catch (e) {
    return str;
  }
}

export const extractLookerSkus = (contracts: Contracts): LookerSku[] =>
  contracts.filter((c) => c.type === AssetTypeLooker).flatMap((lc) => lc.properties?.skus ?? []);

type OutcomeCallbacks = {
  success?: () => void;
  failure?: (message?: string) => void;
};

const handleAnyFailures = (
  results: PromiseSettledResult<void>[],
  saasCustomer: boolean,
  assetId: string,
  callbacks?: OutcomeCallbacks
): void =>
  results.forEach((result, index) => {
    const settingsError = result.status === "rejected" && index === 1;
    const ignoreRejection = settingsError && saasCustomer;
    if (result.status === "rejected" && !ignoreRejection) {
      consoleErrorWithSentry(
        `Unable to update ${index === 0 ? "asset" : "asset settings for asset"} ${assetId}. Reason: "${result.reason}"`
      );
    }
    if (callbacks?.failure && !ignoreRejection) {
      callbacks.failure(`Problem updating ${index === 0 ? "asset" : "asset settings for asset"} ${assetId}`);
    }
  });

/**
 * Updates the {@link AssetModel} and the {@link AssetSettingModel} for a given asset ID.<br />
 * Because some assets have no corresponding assetSettings, the queries are separated to prevent one failure blocking both.
 * @param assetId - ID of the asset to be updated
 * @param updateData - fields to change
 * @param saasCustomer - whether the customer is a SaaS customer
 * @param callbacks - Set of callback functions; usually used to display a snackbar or other feedback
 * @param callbacks.success - callback to be called on success
 * @param callbacks.failure - callback to be called on failure
 */
export const updateAssetAndSettings = async (
  assetId: string,
  updateData: any,
  saasCustomer: boolean = false,
  callbacks?: OutcomeCallbacks
): Promise<void> => {
  const promises: Promise<void>[] = [
    getCollection(AssetModel).doc(assetId).update(updateData),
    getCollection(AssetSettingModel).doc(assetId).update(updateData),
  ];

  const results: PromiseSettledResult<void>[] = await Promise.allSettled(promises);

  // note
  handleAnyFailures(results, saasCustomer, assetId, callbacks);

  if (results.some((result) => result.status === "rejected")) {
    return;
  }

  if (callbacks?.success) {
    callbacks.success();
  }
};
