import { type ChangeEvent, Fragment, useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Checkbox, CircularProgress, FormControlLabel, Portal, Tooltip } from "@mui/material";
import { grey, orange } from "@mui/material/colors";

import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import mixpanel from "../../../../utils/mixpanel";
import { updateFallbackOnDemandStatus } from "../../db";
import { type SpotScalingApi } from "../../spotScalingApi";
import { checkFBODStrings } from "../../strings";
import { type AsgItem } from "../../types";
import { FallbackToOnDemandAlerts } from "./FallbackToOnDemandAlerts";

export enum FallbackToOnDemandState {
  View,
  InconsistencyWithConfig,
  WaitingForAddConfigResponse,
  SuccessfullyAddedConfig,
  FailedToAddConfig,
}

type FallbackToOnDemandProps = {
  asg: AsgItem;
  spotScalingApi: SpotScalingApi;
  notificationContainer: any;
  disabled?: boolean;
};

export const FallbackToOnDemand = ({
  spotScalingApi,
  disabled = false,
  asg,
  notificationContainer,
}: FallbackToOnDemandProps) => {
  const [isConfigEnabled, setIsConfigEnabled] = useState<boolean | null>(null);
  const [displayState, setDisplayState] = useState<FallbackToOnDemandState>(FallbackToOnDemandState.View);

  const [alertsDetailedMessage, setAlertsDetailedMessage] = useState<string>("");

  const snackbar = useSnackbar();

  useMountEffect(() => {
    (async () => {
      const isConfigEnabled = await spotScalingApi.checkFallbackOnDemandConfig(asg.accountId, asg.region);
      setIsConfigEnabled(isConfigEnabled);
    })();
  });

  const errorInFallbackOnDemand =
    displayState === FallbackToOnDemandState.InconsistencyWithConfig ||
    displayState === FallbackToOnDemandState.FailedToAddConfig;

  async function tryAddingFallbackOnDemandConfig() {
    setDisplayState(FallbackToOnDemandState.WaitingForAddConfigResponse);

    const response = await spotScalingApi.addFallbackOnDemandConfig(asg.accountId, asg.region);

    if (response.done) {
      setIsConfigEnabled(true);
      setDisplayState(FallbackToOnDemandState.SuccessfullyAddedConfig);
    } else {
      setDisplayState(FallbackToOnDemandState.FailedToAddConfig);
      if (response.error) {
        setAlertsDetailedMessage(response.error);
      }
    }
  }

  useEffect(() => {
    if (isConfigEnabled !== null) {
      const fallbackOnDemandEnabled = asg.config.fallbackOnDemand;
      if (!isConfigEnabled && displayState === FallbackToOnDemandState.View && fallbackOnDemandEnabled) {
        setDisplayState(FallbackToOnDemandState.InconsistencyWithConfig);
      } else if (displayState !== FallbackToOnDemandState.WaitingForAddConfigResponse && !fallbackOnDemandEnabled) {
        setDisplayState(FallbackToOnDemandState.View);
      }
    }
    return setDisplayState(FallbackToOnDemandState.View);
  }, [asg, displayState, isConfigEnabled]);

  async function handleFallbackOnDemandChange(event: ChangeEvent<HTMLInputElement>) {
    const enabled = event.target.checked;
    if (enabled) {
      mixpanel.track("spot-scaling.apply-fbod");
    }
    await updateFallbackOnDemandStatus(asg.id, enabled);

    if (!isConfigEnabled && enabled) {
      await tryAddingFallbackOnDemandConfig();

      if (FallbackToOnDemandState.FailedToAddConfig) {
        snackbar.onOpen({
          message: checkFBODStrings.Failed,
          variant: "error",
          withClose: true,
        });
      }
    }

    if (isConfigEnabled) {
      snackbar.onOpen({
        message: enabled ? checkFBODStrings.Enabled : checkFBODStrings.Disabled,
        variant: "success",
        withClose: true,
      });
    }
  }

  return (
    <>
      {displayState !== FallbackToOnDemandState.View && (
        <Portal container={notificationContainer.current}>
          <FallbackToOnDemandAlerts
            state={displayState}
            detailedMessage={alertsDetailedMessage}
            addConfigurationCallback={tryAddingFallbackOnDemandConfig}
          />
        </Portal>
      )}
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              data-cy="fallback-checkbox"
              icon={isConfigEnabled === null ? <CircularProgress size={24} color="inherit" /> : undefined}
              disabled={disabled || isConfigEnabled === null}
              color={errorInFallbackOnDemand ? "secondary" : "primary"}
              checked={asg.config.fallbackOnDemand}
              onChange={handleFallbackOnDemandChange}
            />
          }
          label="Fallback to On-Demand"
        />
        <Box display="flex" alignItems="center">
          {errorInFallbackOnDemand ? (
            <Tooltip title="Could not apply Fallback to On-Demand">
              <WarningIcon style={{ color: orange[500] }} />
            </Tooltip>
          ) : (
            <Tooltip title="If there are no available spot instances Spot Scaling will automatically fallback to on-demand to ensure availability, reverting to spot instances as soon as possible.">
              <InfoOutlinedIcon htmlColor={grey[600]} fontSize="small" />
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
};
