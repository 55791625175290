import { type FC, useEffect } from "react";

import { type CloudFlowNodeType, type NodeConfigApiParametersBase } from "@doitintl/cmp-models";
import { useFormikContext } from "formik";

import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";

export const FormValuesChangeListener: FC<{
  formKey: keyof NodeConfigApiParametersBase;
}> = ({ formKey }) => {
  const { values, isValid, touched } = useFormikContext();
  const { dispatch } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  useEffect(() => {
    if (isValid && Object.keys(touched).length > 0) {
      dispatch({ type: "UPDATE_PARAMETERS_AT_KEY", payload: { parameterKey: formKey, values } });
    }
  }, [values, isValid, touched, formKey, dispatch]);

  return null;
};
