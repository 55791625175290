import { consoleErrorWithSentry } from "./errorHandling";

type ExecuteMethod = PromiseLike<void>;

export function fireAndForget(...functions: ExecuteMethod[]): void {
  Promise.all(functions).catch(consoleErrorWithSentry);
}

export function fireAndForgetSettled(...functions: ExecuteMethod[]): void {
  Promise.allSettled(functions).catch(consoleErrorWithSentry);
}
