import { type JSX, useCallback, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  type DialogProps,
  DialogTitle,
  Divider,
  type SxProps,
  type Theme,
} from "@mui/material";

import { globalText } from "../assets/texts";
import LoadingButton from "./LoadingButton";

export type SimpleDialogProps = {
  open: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  title: string;
  titleCy?: string;
  onConfirm: (() => Promise<void>) | (() => void);
  onCancel: () => void;
  confirmButtonText?: string;
  confirmButtonIcon?: JSX.Element;
  cancelButtonText?: string;
  children?: React.ReactNode;
  showCancel?: boolean;
  disableConfirm?: boolean;
  confirmButtonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  cancelButtonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  dialogProps?: Partial<DialogProps>;
  confirmButtonHref?: string;
  confirmButtonSx?: SxProps<Theme>;
  cancelButtonSx?: SxProps<Theme>;
  confirmButtonCy?: string;
  headingDividerVisible?: boolean;
  headingDividerProps?: any;
};

export const SimpleDialog = ({
  open,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  title,
  titleCy,
  onConfirm,
  onCancel,
  confirmButtonText = globalText.CONFIRM,
  cancelButtonText = globalText.CANCEL,
  children,
  showCancel = true,
  disableConfirm = false,
  confirmButtonColor = "primary",
  confirmButtonIcon,
  cancelButtonColor = "primary",
  dialogProps = {},
  confirmButtonHref,
  confirmButtonSx,
  cancelButtonSx,
  confirmButtonCy,
  headingDividerVisible,
  headingDividerProps,
}: SimpleDialogProps) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }, [onConfirm]);

  const handleClose = useCallback(
    (_, reason: "backdropClick" | "escapeKeyDown") => {
      switch (reason) {
        case "backdropClick":
          if (loading || disableBackdropClick) {
            return;
          }
          break;
        case "escapeKeyDown":
          if (loading || disableEscapeKeyDown) {
            return;
          }
          break;
        default:
          break;
      }

      onCancel();
    },
    [disableBackdropClick, disableEscapeKeyDown, loading, onCancel]
  );

  useEffect(() => {
    setLoading(false);
  }, [open]);

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && open) {
        handleClose(undefined, "escapeKeyDown");
      }
    });
  });

  return (
    <Dialog open={open} onClose={handleClose} {...dialogProps}>
      <DialogTitle sx={{ pb: 0 }} data-cy={titleCy}>
        {title}
      </DialogTitle>
      {headingDividerVisible && <Divider {...headingDividerProps} />}
      {children}
      <Divider />
      <DialogActions>
        {showCancel && (
          <Button color={cancelButtonColor} onClick={onCancel} variant="text" disabled={loading} sx={cancelButtonSx}>
            {cancelButtonText}
          </Button>
        )}
        <LoadingButton
          color={confirmButtonColor}
          onClick={handleConfirm}
          disabled={loading || disableConfirm}
          loading={loading}
          variant="contained"
          href={confirmButtonHref}
          sx={confirmButtonSx}
          data-cy={confirmButtonCy}
          mixpanelEventId={`simple-dialog.${confirmButtonText}`}
        >
          {confirmButtonText}
          {!!confirmButtonIcon && confirmButtonIcon}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
