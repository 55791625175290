import { type AssetModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

type Props = {
  options: ModelIdRef<AssetModel>[];
  value: ModelIdRef<AssetModel>[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  setFieldValue: (field: "assets", value: ModelIdRef<AssetModel>[]) => void;
};

const formatAssetLabel = (asset: ModelIdRef<AssetModel>, defaultValue = "") => {
  switch (asset.type) {
    case "google-cloud":
      return `${asset.properties.billingAccountId} (${asset.properties.displayName})`;
    case "amazon-web-services":
      return `${asset.properties.accountId} (${asset.properties.name})`;
    default:
      return defaultValue;
  }
};

const AssetsChipsSelect = ({ options, value, disabled, error, helperText, setFieldValue }: Props) => (
  <Autocomplete
    multiple
    options={options}
    value={value}
    onChange={(_event, newValue) => setFieldValue("assets", newValue)}
    disabled={disabled}
    getOptionLabel={(option) => formatAssetLabel(option)}
    renderTags={(value, getTagProps) =>
      value.map((asset, index) => (
        <Chip
          {...getTagProps({ index })}
          label={formatAssetLabel(asset)}
          onDelete={() =>
            setFieldValue(
              "assets",
              value.filter((v) => v !== asset)
            )
          }
          deleteIcon={<CancelIcon />}
          key={asset.id}
        />
      ))
    }
    renderInput={(params) => (
      <TextField {...params} label="Assets" variant="outlined" margin="dense" error={error} helperText={helperText} />
    )}
    noOptionsText="No match found"
  />
);

export default AssetsChipsSelect;
