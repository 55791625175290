import { useCallback, useEffect, useMemo, useState } from "react";

import { KnownIssueModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, CardHeader } from "@mui/material";

import { FilterContextProvider } from "../../Components/FilterTable/Context";
import { isServiceUsedByCustomer } from "../../Components/KnownIssues/KnownIssuesUptimeChart/funcs";
import { useGetCustomerServices } from "../../Components/KnownIssues/KnownIssuesUptimeChart/hooks";
import KnownIssuesUptimeChart from "../../Components/KnownIssues/KnownIssuesUptimeChart/KnownIssuesUptimeChart";
import { CircularProgressLoader } from "../../Components/Loader";
import { useCustomerContext } from "../../Context/CustomerContext";
import { AddKnownIssue } from "./AddKnownIssue";
import {
  checkRegion,
  getAllProducts,
  getCustomerLocations,
  getCustomerRegions,
  reduceDocData,
  removeKnownIssuesDocuments,
  updateKnownIssuesDocuments,
} from "./db";
import { DeleteKnownIssues } from "./DeleteKnownIssues";
import { EditKnownIssue } from "./EditKnownIssue";
import { KnownIssuesFilterbar } from "./KnownIssuesFilterbar";
import {
  onlyOngoingKnownIssuesFilter,
  platformColumn,
  productColumn,
  statusColumn,
  titleColumn,
} from "./KnownIssuesFilterbar/consts";
import { KnownIssuesTable } from "./KnownIssuesTable";
import { KnownIssuesHeader } from "./style";
import { type KnownIssue, type Product } from "./types";

type KnownIssuesProps = {
  isAllowedToModify?: boolean;
  hideGraph?: boolean;
};

export const KnownIssues = ({ isAllowedToModify, hideGraph }: KnownIssuesProps) => {
  const { customer } = useCustomerContext({ allowNull: true });
  const [products, setProducts] = useState<Product[]>([]);
  const [issuesList, setIssuesList] = useState<KnownIssue[]>([]);
  const [filteredKnownIssues, setFilteredKnownIssues] = useState<KnownIssue[]>([]);
  const [selectedKnownIssuesIds, setSelectedKnownIssuesIds] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[] | undefined>();
  const [locations, setLocations] = useState<string[] | undefined>();
  const [loading, setLoading] = useState(true);

  const { customerServices } = useGetCustomerServices();

  const isCustomerPage = Boolean(customer?.id);

  const applyRegionCheck = useCallback(
    (issues: KnownIssue[]): KnownIssue[] => {
      if (!regions || !locations) {
        return [];
      }
      return reduceDocData(issues, (doc) => checkRegion(doc, regions, locations, isCustomerPage));
    },
    [regions, locations, isCustomerPage]
  );

  const columns = [statusColumn, platformColumn, productColumn, titleColumn];

  useEffect(() => {
    if (isCustomerPage && (!regions || !locations || !customerServices)) {
      return;
    }

    return getCollection(KnownIssueModel).onSnapshot((issuesSnapshots) => {
      const allIssues = issuesSnapshots.docs.map((doc) => ({
        ...doc.asModelData(),
        id: doc.id,
      }));
      if (isCustomerPage) {
        const issuesDocumentsData = applyRegionCheck(allIssues);
        const issuesAffectingTheCustomer = customerServices
          ? isServiceUsedByCustomer(issuesDocumentsData, customerServices)
          : [];
        setIssuesList(issuesAffectingTheCustomer);
      } else {
        setIssuesList(allIssues);
      }

      setLoading(false);
    });
  }, [applyRegionCheck, customerServices, isCustomerPage, locations, regions]);

  useEffect(() => {
    if (!customer?.id) {
      return;
    }
    Promise.all([getCustomerRegions(customer.id), getCustomerLocations(customer.id)]).then(([regions, locations]) => {
      setRegions(regions);
      setLocations(locations);
    });
  }, [customer?.id]);

  useEffect(() => {
    getAllProducts().then((products) => {
      setProducts(products);
    });
  }, []);

  const updateKnownIssues = (knownIssueData: KnownIssue) =>
    updateKnownIssuesDocuments(knownIssueData, selectedKnownIssuesIds);

  const removeKnownIssues = async () => {
    await removeKnownIssuesDocuments(selectedKnownIssuesIds);
    setSelectedKnownIssuesIds([]);
  };

  const handleFilter = useCallback(
    (filterFunction?: (issuesList: KnownIssue[]) => KnownIssue[]) => {
      setFilteredKnownIssues(filterFunction ? filterFunction(issuesList) : issuesList);
    },
    [issuesList]
  );

  const handleAllKnownIssuesSelect = (checked: boolean) =>
    setSelectedKnownIssuesIds(checked ? filteredKnownIssues.map(({ id }) => id) : []);

  const handleKnownIssueSelect = (knownIssueId: KnownIssue["id"], checked: boolean) => {
    if (isAllowedToModify) {
      setSelectedKnownIssuesIds(
        checked
          ? selectedKnownIssuesIds.concat(knownIssueId)
          : selectedKnownIssuesIds.filter((selectedKnownIssueId) => selectedKnownIssueId !== knownIssueId)
      );
    }
  };

  const selectedKnownIssueDataForEdit = useMemo(() => {
    const [selectedKnownIssueId] = selectedKnownIssuesIds;
    return filteredKnownIssues.find((knownIssue) => knownIssue.id === selectedKnownIssueId);
  }, [selectedKnownIssuesIds, filteredKnownIssues]);

  const isEditOn = isAllowedToModify && Boolean(selectedKnownIssuesIds.length === 1);

  const isDeleteOn = isAllowedToModify && selectedKnownIssuesIds.length > 0;

  if (loading) {
    return <CircularProgressLoader />;
  }

  return (
    <FilterContextProvider columns={columns} defaultValue={[onlyOngoingKnownIssuesFilter]} persistenceKey={undefined}>
      <Box ml={-2} mt={-1}>
        {!hideGraph && (
          <CardHeader id="dialog-title" titleTypographyProps={{ variant: "h1" }} title="Cloud incidents" />
        )}

        {!hideGraph && <KnownIssuesUptimeChart applyRegionCheck={isCustomerPage ? applyRegionCheck : null} />}

        <CardHeader id="dialog-title" titleTypographyProps={{ variant: "h3" }} title="Cloud incident details" />

        <KnownIssuesHeader>
          <KnownIssuesFilterbar knownIssues={issuesList} onFilter={handleFilter} />
          {isAllowedToModify && <AddKnownIssue productsSuggestions={products} />}
          {isEditOn && (
            <EditKnownIssue
              knownIssueData={selectedKnownIssueDataForEdit}
              updateKnownIssue={updateKnownIssues}
              productsSuggestions={products}
            />
          )}
          {isDeleteOn && <DeleteKnownIssues removeKnownIssues={removeKnownIssues} />}
        </KnownIssuesHeader>
        <KnownIssuesTable
          knownIssues={filteredKnownIssues}
          selectedKnownIssuesIds={selectedKnownIssuesIds}
          onAllKnownIssuesSelect={handleAllKnownIssuesSelect}
          onKnownIssueSelect={handleKnownIssueSelect}
          isAllowedToModify={isAllowedToModify}
        />
      </Box>
    </FilterContextProvider>
  );
};
