import { useCallback, useEffect, useState } from "react";

import { useCountUp } from "react-countup";

import { formatDecimalNumber } from "../../utils/common";

export type CountUpContentProps = {
  number: number;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  duration?: number;
};

function CountUpContent({ number, prefix = "", suffix = "", decimals, duration = 0.1 }: CountUpContentProps) {
  const [activeReset, setActiveReset] = useState(false);
  const { countUp, update, reset } = useCountUp({
    start: 0,
    end: number,
    separator: ",",
    prefix,
    suffix,
    duration,
    decimals,
    onStart: () => setActiveReset(false),
    onEnd: () => setActiveReset(true),
  });
  const updateSavings = useCallback(() => {
    if (activeReset) {
      reset();
    }
    update(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    updateSavings();
  }, [updateSavings]);
  return <>{formatDecimalNumber(countUp)}</>;
}

export default CountUpContent;
