import { type Renderer } from "@doitintl/cmp-models";
import { Card, CardHeader, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CloudReportCard from "../../Dashboard/Analytics/CloudReportCard";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import { type AvaWidgetData } from "../types";
import { AvaWidgetHeader } from "./AvaWidgetHeader";
import { WidgetViewSelect } from "./WidgetViewSelect";

type AvaWidgetCardProps = {
  widgetKey: string;
  onWidgetRendererChange: (rendererOption: Renderer, widgetKey: string) => void;
  widgetData: AvaWidgetData;
  handleReportCreate: (widgetKey: string) => Promise<void>;
};

export const AvaWidgetCard = ({
  widgetKey,
  onWidgetRendererChange,
  widgetData,
  handleReportCreate,
}: AvaWidgetCardProps) => {
  const isDarkMode = useDarkThemeCheck();
  const useStyles = makeStyles({
    hoverUnderline: {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    clickableHeader: {
      "&:hover": {
        backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <Stack width="100%" alignItems="flex-end">
        <Stack width="40%">
          <WidgetViewSelect
            widgetKey={widgetKey}
            currentWidget={widgetData.widget}
            currentWidgetIndex={widgetKey}
            onWidgetRendererChange={onWidgetRendererChange}
          />
        </Stack>
      </Stack>
      <Card>
        <CardHeader
          className={classes.clickableHeader}
          action={<AvaWidgetHeader widgetKey={widgetKey} handleReportCreate={handleReportCreate} />}
          title={
            <Typography className={classes.hoverUnderline} color="inherit">
              Open this Ava generated report in Cloud Analytics
            </Typography>
          }
          onClick={() => handleReportCreate(widgetKey)}
        />
        <CloudReportCard
          key={widgetKey}
          customEmptyReportMessage={{ text: "Sorry, no data returned", url: "" }}
          widgetId=""
          height={widgetData.height ?? 400}
          reportWithData={widgetData.widget}
          fallbackComponent={<Stack />}
        />
      </Card>
    </>
  );
};
