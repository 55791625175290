import noop from "lodash/noop";

import { type NodeData } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: NodeData;
};

export const ConditionNode = ({ id, data }: Props) => (
  <>
    <NodeWrapper
      title={data.name}
      status={data.status}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={data.isActive}
      onClick={noop}
    />
    <SourceHandle id={id} idSuffix="true" style={{ left: "45%" }} />
    <SourceHandle id={id} idSuffix="false" style={{ left: "55%" }} />
    <TargetHandle id={id} />
  </>
);
