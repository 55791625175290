import { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { OPERATOR_AND } from "../../../Components/FilterTable/constants";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { Comparators, type DefaultFilterColumn } from "../../../types/FilterTable";
import { transformStatus } from "../../../utils/common";
import NoTickets from "../NoTickets";
import { filterColumns, ticketsTableColumnsData, ticketsTableColumnsHeaders } from "./TicketsTableColumns";
import TicketsTableRow from "./TicketsTableRow";

type Props = {
  isDoitEmployee: boolean;
  currentUser: any;
  currentTicketList: any[];
  orgClassification?: string;
  customerClassification?: string;
  customerId?: string;
  loading: boolean;
};

const TicketsFilterTable = ({ isDoitEmployee, currentUser, currentTicketList, customerId, loading }: Props) => {
  const { t } = useTranslation("services");

  const rowComponent = useCallback(
    ({ row }) => (
      <TicketsTableRow isDoitEmployee={isDoitEmployee} ticket={row} headerConfigs={ticketsTableColumnsHeaders(t)} />
    ),
    [isDoitEmployee, t]
  );

  const getFilterDefaultValue = (): readonly ("AND" | "OR" | DefaultFilterColumn<any>)[] => {
    const values = [
      {
        column: filterColumns[0],
        comparator: Comparators.NOT_EQUAL_TO,
        value: "closed",
        label: transformStatus("closed"),
      },
      OPERATOR_AND,
      {
        column: filterColumns[2],
        value: currentUser.email,
        label: currentUser.email,
      },
    ];

    return isDoitEmployee ? [values[0]] : values;
  };

  return (
    <>
      {loading ? (
        <FilterTableSkeleton />
      ) : (
        <FilterTable
          toolbarProps={{
            allowToEditColumns: false,
          }}
          rowComponent={rowComponent}
          emptyTableComponent={<NoTickets />}
          defaultFilters={getFilterDefaultValue()}
          headerColumns={ticketsTableColumnsHeaders(t)}
          filterColumns={ticketsTableColumnsData(t)}
          tableItems={currentTicketList}
          defaultSortDirection="desc"
          defaultSortingColumnValue="updated_at"
          persistenceKey={`support_filter_${customerId}`}
          data-cy="supportTicketsTable"
        />
      )}
    </>
  );
};

export default TicketsFilterTable;
