import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router";
import { AnalyticsResourceType } from "@doitintl/cmp-models";
import {
  AddchartRounded as AddWidgetIcon,
  Delete,
  DeviceHub,
  FileCopyRounded as CopyIcon,
  Flag,
  InfoOutlined,
  Schedule as ScheduleIcon,
  ShareRounded as ShareIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import { Grid, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { globalText, reportText } from "../../../../assets/texts";
import { templateCreateText } from "../../../../assets/texts/CloudAnalytics";
import DeleteDialog from "../../../../Components/DeleteDialog";
import useReportSaver, { NewReportType } from "../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useShareReport } from "../../../../Components/hooks/cloudAnalytics/reports/useShareReport";
import useQueryString from "../../../../Components/hooks/useQueryString";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useHotkeyContext } from "../../../../Context/HotkeyContext";
import { useTier } from "../../../../Context/TierProvider";
import { type ReportWSnap } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import mixpanel from "../../../../utils/mixpanel";
import { type RequiredProperties } from "../../../../utils/RequiredProperties";
import { useQuery } from "../../../../utils/useQuery";
import { useCreateTemplateContext, useReportConfig } from "../../Context";
import ScheduleDialog from "../../dialogs/scheduleDialog/ScheduleDialog";
import ShareDialog from "../../dialogs/shareDialog/ShareDialog";
import WidgetPopover from "../../dialogs/widgets/WidgetPopover";
import { deleteWidget } from "../../handlers/updateWidgets";
import { CloudAnalyticsEntities, isTable } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ExportMenuButton from "./ExportMenuButton";
import HelpIconButton from "./HelpIconButton";
import { getScheduleButtonTooltip, isScheduledButtonDisabled } from "./ScheduleButton";

type Props = {
  report: ReportWSnap;
  handleRevert: () => void;
  disableShare: boolean;
  popoverAnchorEl: any;
  reportError?: string;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setGoogleDialogOpen: Dispatch<SetStateAction<boolean>>;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl) => void;
  setRightPanelOpen: Dispatch<SetStateAction<boolean>>;
  wasRun: boolean;
  hasUnsavedChanges: boolean;
  isCurrentUserOwner: boolean;
  handleSaveDefaultConfig: () => Promise<void>;
};

const ShareDialogWrapper = ({ report, onClose }: { report: ReportWSnap; onClose: () => void }) => {
  const [shareDialogLoading, setShareDialogLoading] = useState(false);
  const handleChangeSharing = useShareReport(report, setShareDialogLoading, onClose);

  return (
    <ShareDialog
      open={true}
      onClose={onClose}
      title={reportText.MENU.SHARE}
      entity={CloudAnalyticsEntities.REPORT}
      handleChangeSharing={handleChangeSharing}
      loading={shareDialogLoading}
      organization={report.data.type === AnalyticsResourceType.CUSTOM ? report.data?.organization : null}
      shareEntities={[report.data]}
    />
  );
};

const ActionButtons = ({
  report,
  handleRevert,
  disableShare,
  popoverAnchorEl,
  reportError,
  setIsDeleting,
  setGoogleDialogOpen,
  setPopoverAnchorEl,
  setRightPanelOpen,
  wasRun,
  hasUnsavedChanges,
  isCurrentUserOwner,
  handleSaveDefaultConfig,
}: Props) => {
  const { isFeatureEntitled } = useTier();
  const api = useApiContext();
  const qs = useQueryString();
  const query = useQuery();
  const history = useHistory();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(qs.openTo === "unsubscribe");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [helpWidgetOpen, setHelpWidgetOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customer, userOrganization } = useCustomerContext();
  const { dispatchCreateTemplateData } = useCreateTemplateContext();

  const { createNewReport, getNewReportURL } = useReportSaver();
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();
  const { reportConfig } = useReportConfig();
  const { renderer } = reportConfig;
  const threeDotsRef = useRef<HTMLDivElement | null>(null);
  const hideAddToDashboardIcon =
    userOrganization?.data?.allowCustomDashboards === false || !!userOrganization?.data?.disableAccountDashboard;
  const documentationId =
    query.get("documentation") || (report.data.type === AnalyticsResourceType.PRESET ? report.snapshot.id : undefined);

  // Hotkeys disabled when popover/dialogs open
  useEffect(() => {
    if (shareDialogOpen || scheduleDialogOpen || deleteDialogOpen || exportAnchorEl || helpWidgetOpen) {
      setHotKeysDisabled();
    } else {
      setHotKeysEnabled();
    }
  }, [
    shareDialogOpen,
    scheduleDialogOpen,
    deleteDialogOpen,
    exportAnchorEl,
    helpWidgetOpen,
    setHotKeysDisabled,
    setHotKeysEnabled,
  ]);

  const handleClose = useCallback(() => {
    setShareDialogOpen(false);
    setScheduleDialogOpen(false);
    setDeleteDialogOpen(false);
    setHelpWidgetOpen(false);
  }, []);

  const onShareClick = useCallback(() => {
    setShareDialogOpen(true);
  }, [setShareDialogOpen]);

  const onScheduleClick = useCallback(() => {
    setScheduleDialogOpen(true);
  }, [setScheduleDialogOpen]);

  const onDashboardClick = useCallback(() => {
    setPopoverAnchorEl({ widget: threeDotsRef.current });
  }, [setPopoverAnchorEl]);

  const onDeleteClick = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);

  const onCopyClick = useCallback(async () => {
    const newReportID = await createNewReport(report.data.config, NewReportType.Duplicate);
    history.replace(getNewReportURL(newReportID));
  }, [createNewReport, report.data.config, history, getNewReportURL]);

  const onReportConfigClick = useCallback(() => {
    setRightPanelOpen(true);
  }, [setRightPanelOpen]);

  const onSetAsDefaultClick = useCallback(async () => {
    handleSaveDefaultConfig();
  }, [handleSaveDefaultConfig]);

  const handleDeleteReport = useCallback(async () => {
    try {
      setIsDeleting(true);
      await deleteWidget(api, customer.id, report.snapshot.id);
      await report.snapshot.modelRef.delete();
      showSuccessSnackbar(reportText.DELETE_REPORT_SUCCESS);
      mixpanel.track("analytics.report.delete", [report.snapshot.id]);
    } catch (error) {
      showErrorSnackbar(reportText.DELETE_ERROR);
      consoleErrorWithSentry(error);
      setIsDeleting(false);
    }
  }, [
    setIsDeleting,
    api,
    customer.id,
    report.snapshot.id,
    report.snapshot.modelRef,
    showSuccessSnackbar,
    showErrorSnackbar,
  ]);

  const menuOptions: ThreeDotsMenuOption[] = useMemo(() => {
    const setAsDefaultMenuItem = (
      <MenuItem key="set-default" onClick={onSetAsDefaultClick}>
        <ListItemIcon>
          <Flag fontSize="small" />
        </ListItemIcon>
        <ListItemText>{reportText.SET_AS_DEFAULT}</ListItemText>
      </MenuItem>
    );

    const reportConfig = (
      <MenuItem key="config" onClick={onReportConfigClick}>
        <ListItemIcon>
          <InfoOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>{reportText.REPORT_CONFIG}</ListItemText>
      </MenuItem>
    );

    if (report.data.draft) {
      return [
        {
          menuItem: setAsDefaultMenuItem,
        },
        {
          menuItem: reportConfig,
        },
      ];
    }

    return [
      {
        menuItem: (
          <MenuItem key="copy" onClick={onCopyClick}>
            <ListItemIcon>
              <CopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{reportText.DUPLICATE_REPORT}</ListItemText>
          </MenuItem>
        ),
      },
      report.data.type === "custom" && {
        menuItem: (
          <MenuItem key="share" onClick={onShareClick} disabled={disableShare}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{reportText.MENU.SHARE}</ListItemText>
          </MenuItem>
        ),
      },
      {
        menuItem: (
          <Tooltip
            key="schedule"
            title={getScheduleButtonTooltip(report, isCurrentUserOwner, currentUser)}
            placement="left"
          >
            <span>
              <MenuItem onClick={onScheduleClick} disabled={isScheduledButtonDisabled(report, isCurrentUserOwner)}>
                <ListItemIcon>
                  <ScheduleIcon color={report?.data.schedule ? "primary" : "inherit"} fontSize="small" />
                </ListItemIcon>
                <ListItemText>{reportText.SCHEDULE_EMAIL_DELIVERY}</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        ),
      },
      !hideAddToDashboardIcon && {
        menuItem: (
          <MenuItem key="dashboard" onClick={onDashboardClick}>
            <ListItemIcon>
              <AddWidgetIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{reportText.ADD_TO_DASHBOARD}</ListItemText>
          </MenuItem>
        ),
      },
      {
        menuItem: setAsDefaultMenuItem,
      },
      {
        menuItem: reportConfig,
      },
      isDoitEmployee && {
        menuItem: (
          <MenuItem
            key="createTemplate"
            onClick={() => dispatchCreateTemplateData({ payload: { isCreateTemplateOpen: true } })}
          >
            <ListItemIcon>
              <DeviceHub fontSize="small" />
            </ListItemIcon>
            <ListItemText>{templateCreateText.CREATE_TEMPLATE}</ListItemText>
          </MenuItem>
        ),
      },
      {
        menuItem: (
          <MenuItem key="revert" onClick={handleRevert} disabled={!hasUnsavedChanges}>
            <ListItemIcon>
              <UndoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{reportText.REVERT_ALL_CHANGES}</ListItemText>
          </MenuItem>
        ),
      },
      {
        menuItem: (
          <MenuItem key="delete" onClick={onDeleteClick} disabled={!isCurrentUserOwner}>
            <ListItemIcon>
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="error">{globalText.DELETE}</Typography>
            </ListItemText>
          </MenuItem>
        ),
      },
    ].filter((item): item is RequiredProperties<ThreeDotsMenuOption, "menuItem"> => !!item);
  }, [
    currentUser,
    disableShare,
    dispatchCreateTemplateData,
    handleRevert,
    hasUnsavedChanges,
    hideAddToDashboardIcon,
    isCurrentUserOwner,
    isDoitEmployee,
    onCopyClick,
    onDashboardClick,
    onDeleteClick,
    onReportConfigClick,
    onScheduleClick,
    onSetAsDefaultClick,
    onShareClick,
    report,
  ]);

  return (
    <Grid container item spacing={2} wrap="nowrap">
      {!!documentationId && (
        <Grid item>
          <HelpIconButton
            documentationId={documentationId}
            helpWidgetOpen={helpWidgetOpen}
            setHelpWidgetOpen={setHelpWidgetOpen}
            onClose={handleClose}
            aria-label="help icon"
          />
        </Grid>
      )}
      <Grid item>
        <ExportMenuButton
          exportAnchorEl={exportAnchorEl}
          setExportAnchorEl={setExportAnchorEl}
          report={report}
          setGoogleDialogOpen={setGoogleDialogOpen}
          wasRun={wasRun}
          reportError={reportError}
        />
      </Grid>
      <Grid item ref={threeDotsRef}>
        <ThreeDotsMenu
          data-cy="three-dots-menu"
          size="small"
          options={menuOptions}
          closeAfterSelect
          menuSx={{ ".MuiTypography-root": { fontSize: "14px" } }}
          disabled={!isFeatureEntitled("analytics:reports") && !isDoitEmployee}
        />
      </Grid>
      <WidgetPopover
        anchorEl={popoverAnchorEl?.widget}
        onClose={() => setPopoverAnchorEl({ widget: null })}
        report={report}
        isTable={isTable(renderer)}
      />
      {shareDialogOpen && <ShareDialogWrapper onClose={handleClose} report={report} />}
      {scheduleDialogOpen && (
        <ScheduleDialog
          open={scheduleDialogOpen}
          onClose={handleClose}
          reportId={report.snapshot.id}
          reportData={report.data}
          isEditMode={!!report.data.schedule}
        />
      )}
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={reportText.DELETE_REPORT}
          message={reportText.DELETE_CURRENT_MESSAGE}
          onDelete={handleDeleteReport}
          onClose={handleClose}
        />
      )}
    </Grid>
  );
};

export default ActionButtons;
