import { useMemo } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

import { useSessionStorage } from "../../../../../Components/hooks/storageHooks";
import { formatCurrency } from "../../../../../utils/common";
import { type CostDifferencesData } from "../../types";
import { discrepancyAbbreviations } from "../Pages/utils";
import { ExplainerTableCell } from "./ExplainerTableCell";

type Props = {
  data: CostDifferencesData[];
  currency: CurrencyCode;
  costNameLabel: string;
  storageKey: string;
};

const RowComponent = ({ row, currency }: { row: CostDifferencesData; currency: CurrencyCode }) => {
  const [expanded, setExpanded] = useSessionStorage(
    `explainer_${row.costName.replace(" ", "_").toLowerCase()}_expanded`,
    false
  );

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Table>
        <TableRow hover>
          <ExplainerTableCell sx={{ pl: 0, width: "45%" }}>
            <Stack direction="row" alignItems="center">
              <IconButton
                size="small"
                sx={{ width: "24px", height: "24px", marginX: "12px" }}
                onClick={handleToggleExpand}
              >
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {row.costName}
            </Stack>
          </ExplainerTableCell>
          <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
            {formatCurrency(row.doitCost, currency)}
          </ExplainerTableCell>
          <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
            {formatCurrency(row.awsCost, currency)}
          </ExplainerTableCell>
          <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
            {row.doitCost < row.awsCost && "-"}
            {formatCurrency(row.costDifference, currency)}
          </ExplainerTableCell>
        </TableRow>
      </Table>

      {Object.keys(row.discrepancies).length > 0 && (
        <Collapse in={expanded}>
          <Table>
            <TableBody>
              {Object.entries(row.discrepancies).map(([discrepancy, { doitCost, awsCost, totalDifference }]) => (
                <TableRow key={discrepancy} hover>
                  <ExplainerTableCell sx={{ paddingLeft: 8, width: "45%" }}>
                    <Box sx={{ textDecoration: "underline dotted", textUnderlineOffset: "5px" }}>
                      <Tooltip title={discrepancyAbbreviations[discrepancy].tooltip}>
                        {discrepancyAbbreviations[discrepancy].label}
                      </Tooltip>
                    </Box>
                  </ExplainerTableCell>
                  <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
                    {formatCurrency(doitCost, currency)}
                  </ExplainerTableCell>
                  <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
                    {formatCurrency(awsCost, currency)}
                  </ExplainerTableCell>
                  <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
                    {formatCurrency(totalDifference, currency)}
                  </ExplainerTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      )}
    </>
  );
};

const ExplainerCostDifferencesTableComponent = ({ data, currency, costNameLabel, storageKey }: Props) => {
  const [orderBy, setOrderBy] = useSessionStorage(`${storageKey}_orderBy`, "costDifference");
  const [sortDirection, setSortDirection] = useSessionStorage<"asc" | "desc">(`${storageKey}_sortDirection`, "desc");

  const handleSort = (column: string) => {
    if (orderBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(column);
      setSortDirection("asc");
    }
  };

  const sortedData = useMemo(() => {
    if (orderBy) {
      const sorted = [...data].sort((a, b) => {
        if (orderBy === "costName") {
          if (sortDirection === "asc") {
            return a[orderBy].localeCompare(b[orderBy]);
          } else {
            return b[orderBy].localeCompare(a[orderBy]);
          }
        }
        if (sortDirection === "asc") {
          return a[orderBy] - b[orderBy];
        } else {
          return b[orderBy] - a[orderBy];
        }
      });
      return sorted;
    }
    return data;
  }, [data, orderBy, sortDirection]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <ExplainerTableCell sx={{ pl: "48px", width: "45%" }}>
              <TableSortLabel
                active={orderBy === "costName"}
                direction={orderBy === "costName" ? sortDirection : "asc"}
                onClick={() => handleSort("costName")}
              >
                {costNameLabel}
              </TableSortLabel>
            </ExplainerTableCell>
            <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
              <TableSortLabel
                active={orderBy === "doitCost"}
                direction={orderBy === "doitCost" ? sortDirection : "asc"}
                onClick={() => handleSort("doitCost")}
              >
                DoiT cost
              </TableSortLabel>
            </ExplainerTableCell>
            <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
              <TableSortLabel
                active={orderBy === "awsCost"}
                direction={orderBy === "awsCost" ? sortDirection : "asc"}
                onClick={() => handleSort("awsCost")}
              >
                AWS cost
              </TableSortLabel>
            </ExplainerTableCell>
            <ExplainerTableCell align="right" sx={{ width: "18.3%" }}>
              <TableSortLabel
                active={orderBy === "costDifference"}
                direction={orderBy === "costDifference" ? sortDirection : "asc"}
                onClick={() => handleSort("costDifference")}
              >
                Difference
              </TableSortLabel>
            </ExplainerTableCell>
          </TableRow>
        </TableHead>
      </Table>
      {sortedData.map((row) => (
        <RowComponent key={row.costName} row={row} currency={currency} />
      ))}
    </>
  );
};

export default ExplainerCostDifferencesTableComponent;
