import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

type TemplateAutocompleteProps = {
  options: {
    label: string;
    selected: boolean;
  }[];
  onChange: (value) => void;
  titleLabel: string;
  disabled?: boolean;
};

const TemplateAutocomplete = ({ options, onChange, titleLabel, disabled }: TemplateAutocompleteProps) => (
  <Autocomplete
    ChipProps={{
      deleteIcon: <></>,
    }}
    disableCloseOnSelect
    disabled={disabled}
    getOptionLabel={(option) => option.label}
    multiple
    onChange={(_, value) => onChange(value)}
    options={options}
    renderOption={(props, option) => (
      <li {...props}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={option.selected}
        />
        {option.label}
      </li>
    )}
    renderInput={(params) => <TextField {...params} label={titleLabel} InputLabelProps={{ shrink: true }} required />}
    value={options.filter((c) => c.selected)}
  />
);

export default TemplateAutocomplete;
