import axios from "axios";

import { autoHideDuration } from "../../../../constants";
import { consoleErrorWithSentry } from "../../../../utils";

export const handleChangeSchedule = async ({
  api,
  reportId,
  customerId,
  schedule,
  isEditMode = false,
  onClose,
  showSharedSnackbar,
  handleSetLoading,
  customerPrimaryDomain,
  message = "Schedule saved successfully",
}) => {
  try {
    handleSetLoading?.({ newValue: true, isDelete: false });
    await api.request({
      method: isEditMode ? "PATCH" : "POST",
      url: `v1/customers/${customerId}/analytics/reports/${reportId}/schedule`,
      data: { schedule, customerPrimaryDomain },
    });
    onClose();
    handleSetLoading?.({ newValue: false, isDelete: false });
    showSharedSnackbar({ message, variant: "success", withClose: true, autoHideDuration });
  } catch (error) {
    handleSetLoading?.({ newValue: false, isDelete: false });

    if (axios.isAxiosError(error)) {
      if ((error.response?.status || 0) >= 400) {
        message = error.response?.data || "Failed to set schedule";
        showSharedSnackbar({ message, variant: "error", withClose: true, autoHideDuration });
        return;
      }
    }

    consoleErrorWithSentry(error);
  }
};

export const handleDeleteSchedule = async ({
  api,
  reportId,
  customerId,
  onClose,
  showSharedSnackbar,
  handleSetLoading,
}) => {
  try {
    handleSetLoading?.({ newValue: true, isDelete: true });
    await api.request({
      method: "DELETE",
      url: `v1/customers/${customerId}/analytics/reports/${reportId}/schedule`,
    });
    onClose();
    handleSetLoading?.({ newValue: false, isDelete: true });
    showSharedSnackbar({ message: "Schedule deleted successfully" });
  } catch (error) {
    handleSetLoading?.({ newValue: false, isDelete: true });
    consoleErrorWithSentry(error);
  }
};
