import { type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type AssetType } from "./AssetSetting";
import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

type ServiceCharge = {
  cost: number;
  cost_type: string;
};

type SupportCharges = {
  cost: number;
  details: Detail[] | null;
};

type Detail = {
  base_cost: number;
  cost: number;
  description: string;
  project_id: string;
  service_description: string;
};

type SummaryAws = {
  credits: ServiceCharge[] | null;
  discounts: ServiceCharge[] | null;
  otherCharges: ServiceCharge[] | null;
  refunds: ServiceCharge[] | null;
  savings: ServiceCharge[] | null;
  serviceCharges: ServiceCharge[];
  supportCharges: SupportCharges;
  tax: ServiceCharge[] | null;
  total: number;
};

type Summary = {
  aws: SummaryAws;
  doit: SummaryAws;
};

type CostDifference = Record<
  string,
  {
    aws: Record<string, number>;
    doit: Record<string, number>;
  }
>;

type Explainer = {
  service: CostDifference;
  account: CostDifference;
  summary: Summary;
};

enum InconclusiveInvoiceReasons {
  CurrencyError = "currencyError",
  LowCost = "lowCost",
}

type InconclusiveInvoiceReason = `${InconclusiveInvoiceReasons}`;

export type EntityInvoicesModelRow = {
  SKU: string;
  currency: CurrencyCode;
  custBillingTblSessionId: string;
  description: string;
  details: string;
  detailsSuffix: string | null;
  discount: number;
  final: boolean;
  period: null;
  quantity: number;
  rank: number;
  tags: string[] | null;
  total: number;
  type: AssetType;
  unitPrice: number;
};

export type EntityInvoicesModel = {
  canceledAt?: Timestamp | null;
  currency: CurrencyCode;
  date: Timestamp;
  details: string;
  expireBy: Timestamp | null;
  explainer?: Explainer;
  final: boolean;
  group: null | string;
  inconclusiveInvoiceReason: InconclusiveInvoiceReason | null;
  issuedAt?: Timestamp | null;
  note?: string;
  rows: EntityInvoicesModelRow[];
  timestamp: Timestamp;
  type: AssetType;
  cancellationReason?: string;
};

type MonthInvoicesCollection = {
  subCollections: {
    entityInvoices: EntityInvoicesModel;
  };

  customer: Reference<CustomerModel>;
  entity: Reference<EntityModel>;
  stats?: {
    "amazon-web-services": {
      numIssued: number;
    };
  };
  timestamp: Timestamp;
};

type InvoicingMonthsCollection = {
  subCollections: {
    monthInvoices: MonthInvoicesCollection;
  };
};

type InvoicingDoc = {
  subCollections: {
    invoicingMonths: InvoicingMonthsCollection;
  };
};

export type JobsModel = {
  action: "recalculation" | "issue";
  type: AssetType;
  comment: string;
  createdAt: Timestamp;
  customer: Reference<CustomerModel>;
  invoiceMonth: string;
  reason: string;
  status: "pending" | "completed" | "failed";
  updatedAt: Timestamp | null;
  email: string;
};

type ManualOperationsDoc = {
  subCollections: {
    jobs: JobsModel;
  };
};

type CogsLineCollection = {
  company?: string;
  date: Timestamp;
  description?: string;
  timestamp: Timestamp;
  type: AssetType;
  value: number;
};

type CogsDoc = {
  subCollections: {
    cogsLines: CogsLineCollection;
  };
};
@subCollection("billing")
export class BillingModel {
  docs?: {
    invoicing: InvoicingDoc;
    cogs: CogsDoc;
    manualOperations: ManualOperationsDoc;
  };
}
