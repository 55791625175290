import { useCallback, useEffect, useMemo } from "react";

import { Aggregator, EarlyAccessFeature, Feature, Metric, Renderer, TimeInterval } from "@doitintl/cmp-models";
import { Checkbox, ListItemSecondaryAction, MenuItem, TextField, Tooltip } from "@mui/material";

import { reportText } from "../../../../assets/texts";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useTier } from "../../../../Context/TierProvider";
import { getCurrencySymbol } from "../../../../utils/common";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { useStyles } from "../../ReportStyles";
import {
  advancedOptionsRenderValue,
  advancedOptionsTitle,
  AggregatorOptions,
  CurrencyOptions,
  FeatureOptions,
  getAvailableAggregators,
  isComparative,
  isTimeSeriesReport,
  IsValidAggregator,
  MenuProps,
} from "../../utilities";
import { IncludeCreditsSwitch } from "./IncludeCreditsSwitch";
import ReportsAccordion from "./ReportsAccordion";

export type AdvancedOptionsProps = {
  handleAggregatorSelect: (aggregator: Aggregator) => void;
  queryRunning: boolean;
};

const AdvancedOptions = ({ handleAggregatorSelect, queryRunning }: AdvancedOptionsProps) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { isFeatureEntitled } = useTier();
  const classes = useStyles();
  const isCountAggregatorVisible = useFeatureFlag(EarlyAccessFeature.COUNT_AGGREGATION);
  const { dimensions = [] } = useReportDimensionsContext();
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { currency, aggregator, renderer, features, timeInterval, metric, comparative } = reportConfig;
  const onChange = useCallback(
    (event) => {
      dispatchReportConfig({ payload: { features: event.target.value } });
    },
    [dispatchReportConfig]
  );

  const onChangeAggregation = useCallback(
    (event) => {
      handleAggregatorSelect(event.target.value as Aggregator);
    },
    [handleAggregatorSelect]
  );

  const handleChangeCurrency = useCallback(
    (event) => {
      dispatchReportConfig({ payload: { currency: event.target.value } });
    },
    [dispatchReportConfig]
  );

  useEffect(() => {
    if (aggregator === Aggregator.TOTAL_OVER_TOTAL && (metric === Metric.EXTENDED || metric === Metric.CALCULATED)) {
      dispatchReportConfig({ payload: { aggregator: Aggregator.TOTAL } });
    }
  }, [metric, aggregator, dispatchReportConfig]);

  const featureOptions = useMemo<{ value: Feature; label: string }[]>(() => {
    if (!isDoitEmployee && !isFeatureEntitled("analytics:advanced")) {
      return [
        {
          value: Feature.FORECAST,
          label: "Forecast",
        },
      ];
    }
    return FeatureOptions;
  }, [isFeatureEntitled, isDoitEmployee]);

  return (
    <ReportsAccordion title={reportText.ADVANCED_OPTIONS_SECTION_LABEL} numOfItems={3}>
      {isDoitEmployee || isFeatureEntitled("analytics:advanced") || isFeatureEntitled("analytics:forecasts") ? (
        <Tooltip title={advancedOptionsTitle(dimensions, features)} placement="bottom-start">
          <TextField
            label="Advanced Analysis"
            data-cy="advanced-analysis"
            value={features}
            disabled={!isTimeSeriesReport(dimensions)}
            onChange={onChange}
            variant="outlined"
            margin="dense"
            select
            fullWidth
            InputLabelProps={{ shrink: true, margin: "dense" }}
            SelectProps={{
              multiple: true,
              displayEmpty: true,
              renderValue: (values: any) => advancedOptionsRenderValue(dimensions, values),
              classes: {
                select: classes.selectOutlined,
              },
              MenuProps: {
                ...MenuProps,
                PopoverClasses: {
                  root: classes.customMenu,
                },
              },
            }}
          >
            {featureOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={
                  (timeInterval === TimeInterval.YEAR || aggregator !== Aggregator.TOTAL) &&
                  option.value === Feature.FORECAST
                }
                dense
                data-cy={option.value}
              >
                <Checkbox size="small" checked={features?.includes(option.value)} />
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Tooltip>
      ) : (
        <></>
      )}
      <TextField
        label="Aggregation"
        data-cy="aggregation"
        value={aggregator}
        onChange={onChangeAggregation}
        variant="outlined"
        disabled={renderer === Renderer.TREEMAP || !!(comparative && isComparative(comparative))}
        margin="dense"
        select
        fullWidth
        SelectProps={{
          classes: {
            select: classes.selectOutlined,
          },
          MenuProps,
        }}
      >
        {getAvailableAggregators(AggregatorOptions, aggregator, isCountAggregatorVisible, metric).map(
          (aggregatorOption) => (
            <MenuItem
              key={aggregatorOption.value}
              value={aggregatorOption.value}
              disabled={!IsValidAggregator(aggregatorOption.value, metric)}
              dense
              data-cy={
                typeof aggregatorOption.label === "function" ? aggregatorOption.label(metric) : aggregatorOption.label
              }
            >
              {typeof aggregatorOption.label === "function" ? aggregatorOption.label(metric) : aggregatorOption.label}
            </MenuItem>
          )
        )}
      </TextField>
      <TextField
        size="small"
        label={reportText.CURRENCY}
        data-cy={reportText.CURRENCY}
        value={currency}
        disabled={false}
        onChange={handleChangeCurrency}
        variant="outlined"
        margin="dense"
        select
        fullWidth
        SelectProps={{
          classes: {
            select: classes.selectOutlined,
          },
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        }}
      >
        {CurrencyOptions.map((option) => (
          <MenuItem key={option} value={option} dense data-cy={option}>
            {option}
            <ListItemSecondaryAction className={classes.currencySymbol}>
              {getCurrencySymbol(option)}
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </TextField>

      <IncludeCreditsSwitch disabled={queryRunning} />
    </ReportsAccordion>
  );
};
export default AdvancedOptions;
