import { useEffect, useMemo, useState } from "react";

import { Renderer, TimeSettingsMode } from "@doitintl/cmp-models";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton, InputAdornment, MenuItem, Popover, Stack, TextField } from "@mui/material";

import { useKeyPress } from "../../../../../Components/hooks/useKeyPress";
import { useReportConfig, useReportDimensionsContext } from "../../../Context";
import { ReportConfigKind } from "../../../reducers/ReportConfigReducer";
import { useStyles } from "../../../ReportStyles";
import { MenuProps, timeIntervalOptions, timeRangeInputValue } from "../../../utilities";
import { ExcludePartialSwitch } from "../ExcludePartialSwitch";
import TimeRangeSelection from "./TimeRangeSelection";

export type TimeSelectionProps = {
  queryRunning: boolean;
};

const TimeSelection = ({ queryRunning }: TimeSelectionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { handleTimeIntervalChange } = useReportDimensionsContext();
  const { timeRangeOption } = reportConfig;
  const [timeRangeOptionTemp, setTimeRangeOptionTemp] = useState(timeRangeOption);
  const value = useMemo(() => timeRangeInputValue(timeRangeOption), [timeRangeOption]);
  const enterKey = useKeyPress("enter");
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (updateTimeRangeLocal?: boolean) => {
    setAnchorEl(null);
    if (updateTimeRangeLocal) {
      setTimeRangeOptionTemp(timeRangeOption);
    }
  };

  const onSave = () => {
    if (timeRangeOptionTemp.mode === TimeSettingsMode.Fixed) {
      if (timeRangeOptionTemp.range?.start && timeRangeOptionTemp.range?.end) {
        const { start, end } = timeRangeOptionTemp.range;
        dispatchReportConfig({ payload: { customTimeRange: { from: start, to: end } } });
      } else {
        return;
      }
    } else {
      dispatchReportConfig({ payload: { customTimeRange: null } });
    }
    dispatchReportConfig({ payload: { timeRangeOption: timeRangeOptionTemp } });
    handleClose();
  };

  const handleChangeTimeInterval = (event) => {
    const newTimeInterval = event.target.value;
    dispatchReportConfig({ type: ReportConfigKind.UPDATE_TIME_INTERVAL, payload: { timeInterval: newTimeInterval } });
    handleTimeIntervalChange?.(newTimeInterval);
  };

  useEffect(() => {
    if (enterKey) {
      onSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterKey]);

  return (
    <Stack alignItems="flex-start" mb={3}>
      <TextField
        data-cy="time-range-button"
        InputProps={{
          readOnly: true,
          classes: {
            root: classes.timeRangeRoot,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.timeRangeEndAdornment}>
                {open ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        label="Time Range"
        margin="dense"
        onClick={handleClick}
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        value={value}
        variant="outlined"
      />

      <Popover
        anchorEl={anchorEl}
        onClose={() => handleClose(true)}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <TimeRangeSelection
          handleClose={handleClose}
          onSave={onSave}
          setTimeRangeOption={setTimeRangeOptionTemp}
          timeRangeOption={timeRangeOptionTemp}
        />
      </Popover>

      <TextField
        SelectProps={{
          classes: {
            select: classes.selectOutlined,
            outlined: classes.outlined,
          },
          MenuProps,
        }}
        disabled={reportConfig.renderer === Renderer.TREEMAP}
        fullWidth
        label="Time Interval"
        onChange={handleChangeTimeInterval}
        select
        value={reportConfig.timeInterval}
        variant="outlined"
        sx={{ mt: 2, mb: 1 }}
      >
        {timeIntervalOptions.map((interval) => (
          <MenuItem dense key={interval.value} value={interval.value}>
            {interval.label}
          </MenuItem>
        ))}
      </TextField>
      <ExcludePartialSwitch disabled={queryRunning} />
    </Stack>
  );
};

export default TimeSelection;
