import { cloudflowTexts } from "../../../../assets/texts";
import { type Step, type StepState } from "../../../../Components/Stepper";

type ProductType = "gcp" | "aws" | "doit";

export type NewDialogState = {
  currentStep: number;
  steps: ({ title: string } & Step)[];
  productType?: ProductType;
  service?: string;
  action?: string;
};

export type NewDialogAction =
  | {
      type: "prevStep" | "close";
    }
  | {
      type: "productStepHandler";
      payload: {
        productType: ProductType;
        status: StepState;
      };
    }
  | {
      type: "serviceStepHandler";
      payload: {
        service: string;
        status: StepState;
      };
    }
  | {
      type: "actionStepHandler";
      payload: {
        action: string;
        status: StepState;
      };
    };

export const defaultState: NewDialogState = {
  currentStep: 0,
  steps: [
    {
      title: cloudflowTexts.CREATE_NEW_NODE.SELECT_PRODUCT,
      children: null,
      label: cloudflowTexts.CREATE_NEW_NODE.SELECT_PRODUCT_LABEL,
      order: 0,
      required: true,
      state: "incomplete",
    },
    {
      title: cloudflowTexts.CREATE_NEW_NODE.SELECT_SERVICE,
      children: null,
      label: cloudflowTexts.CREATE_NEW_NODE.SELECT_SERVICE_LABEL,
      order: 1,
      required: true,
      state: "incomplete",
    },
    {
      title: cloudflowTexts.CREATE_NEW_NODE.SELECT_ACTION,
      children: null,
      label: cloudflowTexts.CREATE_NEW_NODE.SELECT_ACTION_LABEL,
      order: 1,
      required: true,
      state: "incomplete",
    },
  ],
};

export const updateStatus = ({ state, status }: { state: NewDialogState; status: StepState }) => {
  state.steps = state.steps.map((step, index) => {
    if (index === state.currentStep) {
      return {
        ...step,
        state: status,
      };
    }
    return step;
  });

  return state;
};

export const newNodeDialogReducer = (state: NewDialogState, action: NewDialogAction): NewDialogState => {
  switch (action.type) {
    case "prevStep":
      return {
        ...state,
        ...updateStatus({ state, status: "incomplete" }),
        currentStep: state.currentStep - 1,
      };
    case "productStepHandler":
      return {
        ...state,
        ...updateStatus({ state, status: action.payload.status }),
        productType: action.payload.productType,
        currentStep: state.currentStep + 1,
      };
    case "serviceStepHandler":
      return {
        ...state,
        ...updateStatus({ state, status: action.payload.status }),
        service: action.payload.service,
        currentStep: state.currentStep + 1,
      };
    case "actionStepHandler":
      return {
        ...state,
        ...updateStatus({ state, status: action.payload.status }),
        action: action.payload.action,
      };
    case "close":
      return defaultState;
    default:
      return state;
  }
};
