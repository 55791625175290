import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

import { cloudflowTexts } from "../../../../assets/texts";
import { type CloudflowLink } from "../../types";

type FromScratchStepProps = { links: CloudflowLink[] };

const FromScratchStep = ({ links }: FromScratchStepProps) => (
  <Box>
    <Typography variant="subtitle1" fontWeight={500}>
      {cloudflowTexts.LEARN_HOW_TO_BUILD}
    </Typography>
    <Box
      component="ul"
      p={0}
      sx={{
        listStyle: "none",
      }}
    >
      {links.map((link, i) => (
        <Box component="li" key={i} mb={1}>
          <Link
            href={link.url}
            sx={{
              textDecoration: "none",
            }}
          >
            {link.title}
          </Link>
        </Box>
      ))}
    </Box>
  </Box>
);

export default FromScratchStep;
