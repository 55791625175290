import { useCallback, useMemo, useRef, useState } from "react";

import { CloudflowEngineModel, type NodeModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type Edge, type Node } from "@xyflow/react";
import isEqual from "lodash/isEqual";

import { useRoles } from "../../../../Components/hooks/IAM/useRoles";
import { useUsers } from "../../../../Components/hooks/IAM/useUsersOrInvites";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { applyGraphLayout } from "../utils/layoutUtils";
import {
  type CloudFlowNode,
  mapCloudFlowNodes,
  mapLeafNodesWithGhosts,
  mapTransitionsToEdges,
  transformNodeData,
} from "../utils/nodeTransformUtils";

export const useCloudflowNodes = (flowId: string) => {
  const { customer } = useCustomerContext();
  const { roles } = useRoles();
  const { users } = useUsers(roles);

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const previousNodesRef = useRef<CloudFlowNode[]>([]);

  const transformCloudflowNode = useCallback(
    (
      data: WithFirebaseModel<NodeModel>,
      snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>
    ) => transformNodeData(data, snapshot, users, customer.ref),
    [customer.ref, users]
  );

  const cloudflowNodesCollectionRef = getCollection(CloudflowEngineModel)
    .doc("cloudflows")
    .collection("cloudflowEntities")
    .doc(flowId)
    .collection("nodes");

  const [fetchedNodes, cloudflowNodesLoading] = useCollectionData(cloudflowNodesCollectionRef, {
    transform: transformCloudflowNode,
  });

  const nodesWithGhosts = useMemo(() => mapLeafNodesWithGhosts(fetchedNodes), [fetchedNodes]);

  const { cloudflowNodes, cloudflowEdges } = useMemo(() => {
    // do not update the graph if the data hasn't changed
    if (!isEqual(nodesWithGhosts, previousNodesRef.current) && !cloudflowNodesLoading) {
      // Update previous nodes ref with the new fetched nodes
      previousNodesRef.current = nodesWithGhosts;

      // Map nodes to React Flow format
      const flowNodes = mapCloudFlowNodes(nodesWithGhosts);
      // Map edges based on node transitions
      const flowEdges = mapTransitionsToEdges(nodesWithGhosts);

      // Apply graph layout to nodes and edges
      if (flowNodes && flowEdges) {
        const { positionedNodes, positionedEdges } = applyGraphLayout(flowNodes, flowEdges);
        setNodes(positionedNodes);
        setEdges(positionedEdges);

        return { cloudflowNodes: positionedNodes, cloudflowEdges: positionedEdges };
      }
      return { cloudflowNodes: [], cloudflowEdges: [] };
    }
    return { cloudflowNodes: nodes, cloudflowEdges: edges };
  }, [nodesWithGhosts, cloudflowNodesLoading, nodes, edges]);

  return { cloudflowNodes, cloudflowEdges, cloudflowNodesLoading };
};
