import { useMemo } from "react";

import { type AWSFlexsaveConfigurationModel, type FlexsaveRDS, type FlexsaveSageMaker } from "@doitintl/cmp-models";
import { Card, CardHeader, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { type XAxisOptions } from "highcharts";
import cloneDeep from "lodash/cloneDeep";

import { ColumnsChart } from "../../../Components/Charts/ColumnsChat/ColumnsChart";
import { useCurrency } from "../../../Components/hooks/useCurrency";
import { type OptionsSeriesColumn } from "../../../utils/Highcharts";
import {
  generateMonthlyOptions,
  getSeriesData,
  getUniqueValues,
  sortMonths,
} from "../Resold/Overview/Chart/SavingsChartUtilsAWS";

type Props = {
  computeData: AWSFlexsaveConfigurationModel;
  rdsData: FlexsaveRDS | undefined;
  sageMakerData: FlexsaveSageMaker | undefined;
};

export const MonthlySavingsChart = ({ computeData, rdsData, sageMakerData }: Props) => {
  const theme = useTheme();
  const { customerCurrencySymbol } = useCurrency();

  const computeOptions = useMemo(() => {
    if (!computeData.savingsHistory || !computeData.savingsSummary) {
      return null;
    }

    return generateMonthlyOptions(
      computeData.savingsHistory,
      computeData.savingsSummary.currentMonth.month,
      computeData.timeEnabled?.toDate() || null,
      theme,
      customerCurrencySymbol
    );
  }, [computeData, theme, customerCurrencySymbol]);

  const sageMakerOptions = useMemo(() => {
    if (!sageMakerData?.savingsHistory || !sageMakerData?.savingsSummary) {
      return null;
    }

    return generateMonthlyOptions(
      sageMakerData.savingsHistory,
      sageMakerData.savingsSummary.currentMonth,
      sageMakerData.timeEnabled?.toDate() || null,
      theme,
      customerCurrencySymbol
    );
  }, [sageMakerData, theme, customerCurrencySymbol]);

  const rdsOptions = useMemo(() => {
    if (!rdsData?.savingsHistory || !rdsData?.savingsSummary) {
      return null;
    }

    return generateMonthlyOptions(
      rdsData.savingsHistory,
      rdsData.savingsSummary.currentMonth,
      rdsData.timeEnabled?.toDate() || null,
      theme,
      customerCurrencySymbol
    );
  }, [rdsData, theme, customerCurrencySymbol]);

  const options = useMemo(() => {
    if (!sageMakerOptions && !computeOptions && !rdsOptions) {
      return null;
    }

    const mergedOption = cloneDeep(computeOptions || sageMakerOptions || rdsOptions) as OptionsSeriesColumn;

    const allMonths: string[] = getUniqueValues(
      (computeOptions?.xAxis as XAxisOptions | null)?.categories || [],
      (sageMakerOptions?.xAxis as XAxisOptions | null)?.categories || [],
      (rdsOptions?.xAxis as XAxisOptions | null)?.categories || []
    );

    mergedOption.xAxis = { ...mergedOption.xAxis, categories: sortMonths(allMonths) };

    const allSeriesNames: string[] = getUniqueValues(
      computeOptions?.series?.map((series) => series.name).filter((name): name is string => Boolean(name)) || [],
      sageMakerOptions?.series?.map((series) => series.name).filter((name): name is string => Boolean(name)) || [],
      rdsOptions?.series?.map((series) => series.name).filter((name): name is string => Boolean(name)) || []
    );

    mergedOption.series = allSeriesNames.map((name: string) => {
      const data = getSeriesData(name, allMonths, computeOptions, sageMakerOptions, rdsOptions);
      const series1 = computeOptions?.series?.find((series) => series.name === name);
      const rest = series1 || { type: "column" };
      return { ...rest, name, data };
    });

    return mergedOption;
  }, [sageMakerOptions, computeOptions, rdsOptions]);

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: `1px solid ${theme.palette.general.divider}`,
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "h4" }}
          title="Flexsave summary"
          subheader="The data in this graph is subject to a delay of between 1-34 days"
          subheaderTypographyProps={{ variant: "body2" }}
        />
      </Stack>
      <ColumnsChart options={options} />
    </Card>
  );
};
