import { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionOnce } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { customersRoute } from "../../Navigation/core/utils";

export const CustomerRoot = () => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();

  const [snap, isLoading] = useCollectionOnce(
    getCollection(DashboardModel)
      .doc("customization")
      .collection("users")
      .doc(currentUser.uid)
      .collection("duc")
      .doc(customerId)
      .collection("dashboards")
      .where("defaultDashboard", "==", true)
      .limit(1)
  );

  useEffect(() => {
    history.replace(customersRoute(customerId, snap?.size === 1 ? "dashboards" : "home"));
  }, [customerId, history, snap?.size]);

  if (isLoading) {
    return null;
  }

  return null;
};
