import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { type AccountManagerModel, type AccountManagersCompany } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";
import { Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { ticketNotification } from "../../utils/common";
import { amNamesComparator, noRole } from "./utils";
import type { OptionalAmWithIndex } from "./utils";

type Props = {
  handleChange: (accountManager: ModelIdRef<AccountManagerModel> | null, id: number) => void;
  updateSupportNotificationLevel: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: number) => void;
  company: AccountManagersCompany;
  accountManager: Omit<OptionalAmWithIndex, "company">;
  formLoading: boolean;
  accountManagers: OptionalAmWithIndex[];
};

const AccountManagerRow = ({
  handleChange,
  updateSupportNotificationLevel,
  company,
  accountManager,
  formLoading,
  accountManagers,
}: Props) => {
  const [availableAMs, setAvailableAMs] = useState<ModelIdRef<AccountManagerModel>[]>([]);
  const [allAccountManagers] = AccountManagersHooks.useAllAccountManagers();
  const [roles] = AccountManagersHooks.useAccountManagersRoles();
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedAM, setSelectedAM] = useState<string>(accountManager.ref?.id || noRole.value);
  const [notificationLevel, setNotificationLevel] = useState<number>(accountManager.supportNotificationLevel ?? 0);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");

  const listAMsForRole = useCallback(
    (role: string) => allAccountManagers?.filter((am) => am.role === role),
    [allAccountManagers]
  );

  useEffect(() => {
    const amId = accountManager.ref?.id;
    if (amId) {
      const accountManagerData = allAccountManagers?.find((am) => am.ref.id === amId);
      setSelectedRole(accountManagerData?.role || noRole.value);
    }
  }, [accountManager, allAccountManagers]);

  useEffect(() => {
    const amsForCompanyRole = listAMsForRole(selectedRole);

    if (amsForCompanyRole === undefined) {
      return;
    }
    const dedupedAmsForCompanyRole = amsForCompanyRole.filter((am) => {
      if (company !== "doit") {
        return am.company === company;
      }
      return (
        am.company === company &&
        typeof am.email === "string" &&
        // removing AMs with emails address@doit-intl.com that has an occurrence of address@doit.com as well
        (!am.email.endsWith("@doit-intl.com") ||
          !amsForCompanyRole.some((other) => other.email === `${am.email.split("@")[0]}@doit.com`))
      );
    });

    setAvailableAMs(dedupedAmsForCompanyRole);
  }, [company, listAMsForRole, selectedRole]);

  const accountManagersIds = accountManagers.filter((am) => !!am.ref).map((am) => am.ref?.id);
  const handleRoleChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: string }>) => {
      const roleValue = event.target.value;
      setSelectedRole(roleValue);
      if (roleValue === noRole.value) {
        handleChange(null, accountManager.index);
        setNotificationLevel(0);
      }
    },
    [handleChange, accountManager.index]
  );

  const amValue = useMemo(
    () => availableAMs.find((am) => am.ref.id === selectedAM) || null,
    [availableAMs, selectedAM]
  );

  const options = useMemo(() => {
    const optionsToSet = [...availableAMs.filter((am) => !accountManagersIds.includes(am.ref.id))].sort(
      amNamesComparator(autocompleteInput)
    );
    if (amValue) {
      optionsToSet.unshift(amValue);
    }
    return optionsToSet;
  }, [amValue, availableAMs, accountManagersIds, autocompleteInput]);

  const getOptionLabel = useCallback((option: ModelIdRef<AccountManagerModel>) => {
    const opt = option?.name === "" ? option?.email : option?.name;
    return option ? opt : "";
  }, []);

  return (
    <Stack direction="row" gap={1} data-cy="item">
      <TextField
        select
        label="Role"
        name={company}
        sx={{ width: 200 }}
        value={selectedRole}
        onChange={handleRoleChange}
        margin="dense"
        variant="outlined"
        disabled={formLoading}
        data-cy="roleDropdown"
      >
        {[noRole, ...(roles ?? [])]
          .filter((r) => r.vendors.includes(company))
          .map((r, index) => (
            <MenuItem key={`${company}_${index}`} value={r.value}>
              {r.name}
            </MenuItem>
          ))}
      </TextField>
      <Autocomplete
        disablePortal
        value={amValue}
        options={options}
        renderOption={(props, option) => (
          // Workaround for AutoComplete issue duplicated options - https://github.com/mui/material-ui/issues/26492
          <li {...props} key={option.ref.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => getOptionLabel(option)}
        sx={{ width: 380 }}
        onChange={(_, val) => {
          setSelectedAM(val?.ref?.id || "");
          handleChange(val, accountManager.index);
        }}
        disabled={formLoading || !selectedRole}
        renderInput={(params) => <TextField margin="dense" {...params} label="Account Manager" />}
        onInputChange={(_, inputValue) => setAutocompleteInput(inputValue)}
        data-cy="amDropdown"
      />
      <TextField
        select
        label="Ticket Alerts"
        name={company}
        value={notificationLevel}
        onChange={(event) => {
          setNotificationLevel(parseInt(event.target.value));
          updateSupportNotificationLevel(event, accountManager.index);
        }}
        margin="dense"
        variant="outlined"
        sx={{ width: 170 }}
        disabled={formLoading || !selectedRole || !selectedAM}
        data-cy="notificationDropdown"
      >
        {ticketNotification.map((priority) => (
          <MenuItem key={priority.name} value={priority.value}>
            {priority.icon} {priority.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

export default AccountManagerRow;
