import { type JSX } from "react";

import { SyncLoader } from "react-spinners";
import { Stack, useTheme } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { useCustomerIntegrations } from "../../../Pages/CloudAnalytics/utilities";
import { SnowflakeLensEnticement } from "./SnowflakeLensEnticement";

type Props = {
  children: JSX.Element;
};

export const SnowflakeLensGuard = ({ children }: Props) => {
  const { isFeatureEntitled } = useTier();
  const isEntitled = isFeatureEntitled("snowflake:connect");
  const { customerIntegrations, loading } = useCustomerIntegrations();
  const theme = useTheme();
  const { customer } = useCustomerContext();

  if (loading || !customerIntegrations) {
    return (
      <Stack flex={1} justifyContent="center" height="70vh" alignItems="center">
        <SyncLoader size={10} color={theme.palette.primary.main} loading />
      </Stack>
    );
  }

  if (customer.presentationMode?.enabled || customer.presentationMode?.isPredefined) {
    return children;
  }

  const hasSnowflakeIntegration = customerIntegrations.includes("snowflake");

  if (isEntitled && hasSnowflakeIntegration) {
    return children;
  }

  return <SnowflakeLensEnticement state={isEntitled ? "activate" : "upgrade"} />;
};
