import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  Metadata,
  Renderer,
  Sort,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, CircularProgress, DialogContent, MenuItem, TextField, Typography } from "@mui/material";
import { DateTime, type DateTimeFormatOptions } from "luxon";

import { globalText } from "../../../assets/texts";
import { invoicesTxt } from "../../../assets/texts/Billing/invoices";
import { type InvoiceBucketOption, InvoiceBucketSelect } from "../../../Components/Selects/InvoiceBucketSelect";
import SimpleDialog from "../../../Components/SimpleDialog";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { assetTypeName } from "../../../utils/common";
import { type AttributionGroupWithRef } from "../../CloudAnalytics/attributionGroups/types";
import { getAttributionsIDsFromAG } from "../../CloudAnalytics/attributionGroups/utils";
import useGenerateReport from "../../CloudAnalytics/generateReport";
import { type createReportParams } from "../../CloudAnalytics/generateReport/types";

type Props = {
  invoiceAG: AttributionGroupWithRef | undefined;
  onClose: () => void;
};

const notSupportedAssets = [AssetTypeMicrosoftAzure, AssetTypeGSuite, AssetTypeOffice365];

const SavingsSourceAGId = "Ey3UnvQ7jA06K2pbTVTm";
const InvoiceBreakdownAGId = "Up44UNhsdbYW6ACYrWny";

export const InvoiceInReportsDialog = ({ invoiceAG, onClose }: Props) => {
  const { customer, assets } = useCustomerContext();
  const generateReport = useGenerateReport();
  const { filteredAttributions: attributions } = useAttributionsContext();
  const [numOfMonths, setNumOfMonths] = useState(1);
  const [invoiceBucketOptions, setInvoiceBucketOptions] = useState<InvoiceBucketOption[]>();
  const [invoiceBucketSelectedIds, setInvoiceBucketSelectedIds] = useState<string[]>([]);

  const timeFrameOptions = useMemo(() => {
    const dt = DateTime.now();
    const format: DateTimeFormatOptions = { month: "long", year: "numeric" };
    const oneMonthAgo = dt.minus({ month: 1 }).toLocaleString(format);
    const threeMonthsAgo = dt.minus({ month: 3 }).toLocaleString(format);
    const sixMonthsAgo = dt.minus({ month: 6 }).toLocaleString(format);
    return {
      1: `${invoicesTxt.LAST_MONTH} (${oneMonthAgo})`,
      3: `${invoicesTxt.LAST_THREE_MONTHS} (${threeMonthsAgo} - ${oneMonthAgo})`,
      6: `${invoicesTxt.LAST_SIX_MONTHS} (${sixMonthsAgo} - ${oneMonthAgo})`,
    };
  }, []);

  const getOptionsFromAttributions: () => InvoiceBucketOption[] = useCallback(() => {
    const attributionsIds = getAttributionsIDsFromAG(invoiceAG);
    const filteredAttributions = attributions.filter((attribution) => attributionsIds.includes(attribution.ref.id));
    return filteredAttributions.map((attr) => ({
      id: attr.ref.id,
      name: attr.data.name,
      data: attr.data,
      group: invoicesTxt.SUPPORTED_ASSETS,
      level: 1,
      icon: attr.data.cloud?.[0],
    }));
  }, [attributions, invoiceAG]);

  const getNotSupportedOptions = useCallback(() => {
    const customerAssetsValues = Object.values(assets).flatMap((asset) => asset);

    return notSupportedAssets.reduce((result: InvoiceBucketOption[], item) => {
      if (customerAssetsValues.findIndex((asset) => asset.data.type === item) !== -1) {
        const name = assetTypeName(item);
        result.push({
          id: name,
          name,
          group: invoicesTxt.NOT_SUPPORTED_ASSETS,
          level: 0,
          disabled: true,
          icon: item,
        });
      }
      return result;
    }, []);
  }, [assets]);

  useEffect(() => {
    if (!invoiceAG) {
      return;
    }
    const supportedOptions = getOptionsFromAttributions();
    const notSupportedOptions = getNotSupportedOptions();
    setInvoiceBucketOptions([...supportedOptions, ...notSupportedOptions]);
    setInvoiceBucketSelectedIds(supportedOptions.map((option) => option.id));
  }, [invoiceAG, getNotSupportedOptions, getOptionsFromAttributions]);

  const selectScope = useMemo(
    () =>
      (invoiceBucketOptions || []).filter(
        (option) => invoiceBucketSelectedIds.findIndex((bucketId) => bucketId === option.id) !== -1
      ),
    [invoiceBucketOptions, invoiceBucketSelectedIds]
  );

  const handleCreateReport = useCallback(async () => {
    if (!invoiceAG?.ref.id || !numOfMonths || !invoiceBucketSelectedIds.length) {
      return;
    }

    const enabledOptions = invoiceBucketOptions?.filter((option) => !option.disabled) || [];
    const timeInterval = TimeInterval.MONTH;
    const timeSettings = {
      amount: numOfMonths,
      mode: TimeSettingsMode.Last,
      unit: timeInterval,
      includeCurrent: false,
    };

    const createReportPayload: createReportParams = {
      name: invoicesTxt.INVOICE_BREAKDOWN_PRESET_REPORT,
      description: "",
      config: {
        fields: [
          {
            type: Metadata.ATTRIBUTION_GROUP,
            id: invoiceAG.ref.id,
            values: enabledOptions.length !== invoiceBucketSelectedIds.length ? invoiceBucketSelectedIds : [],
            groupBy: true,
          },
          {
            type: Metadata.ATTRIBUTION_GROUP,
            id: InvoiceBreakdownAGId,
            values: [],
            groupBy: true,
          },
          {
            type: Metadata.ATTRIBUTION_GROUP,
            id: SavingsSourceAGId,
            values: [],
            groupBy: true,
          },
        ],
        renderer: Renderer.TABLE,
        rowOrder: Sort.A_TO_Z,
        timeInterval,
        timeSettings,
      },
    };

    const newReportId = await generateReport(createReportPayload);
    window.open(`/customers/${customer.id}/analytics/reports/${newReportId}?documentation=invoice-explainer`, "_blank");
    onClose();
  }, [
    customer.id,
    generateReport,
    invoiceAG?.ref.id,
    invoiceBucketOptions,
    invoiceBucketSelectedIds,
    numOfMonths,
    onClose,
  ]);

  const onInvoiceBucketsChange = useCallback((values: InvoiceBucketOption[]) => {
    const newValues = values.map((value) => value.id);
    setInvoiceBucketSelectedIds(newValues);
  }, []);

  return (
    <SimpleDialog
      title={invoicesTxt.OPEN_INVOICE_IN_REPORTS}
      open={true}
      onConfirm={handleCreateReport}
      onCancel={onClose}
      confirmButtonText={globalText.OPEN}
      confirmButtonIcon={<OpenNewIcon sx={{ fontSize: 21, ml: 1 }} />}
      disableConfirm={!invoiceBucketOptions || !invoiceBucketSelectedIds.length || !numOfMonths}
      dialogProps={{ fullWidth: true }}
    >
      <DialogContent>
        <Typography variant="body2" color="text.secondary">
          {invoicesTxt.EXPLORE_COMPREHENSIVE}
        </Typography>

        {!invoiceBucketOptions ? (
          <Box mt={2} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress disableShrink={true} size={24} thickness={4.5} />
          </Box>
        ) : (
          <>
            <TextField
              select
              margin="dense"
              variant="outlined"
              size="medium"
              label={invoicesTxt.TIME_FRAME}
              fullWidth
              value={numOfMonths}
              onChange={(e) => setNumOfMonths(+e.target.value)}
              sx={{ mt: 2 }}
            >
              {Object.entries(timeFrameOptions).map(([value, text]) => (
                <MenuItem key={`time-frame-${value}`} value={value}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
            <InvoiceBucketSelect
              options={invoiceBucketOptions}
              scope={selectScope}
              onChange={onInvoiceBucketsChange}
              hasAllOption={true}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </DialogContent>
    </SimpleDialog>
  );
};
