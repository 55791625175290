import { type JSX, useCallback, useState } from "react";

import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { MPARetireDialog } from "../MPARetireDialog/MPARetireDialog";
import { useMPARetireRequest } from "./useMPARetireRequest";

type MPARetireDialogFacade = {
  showMPARetireDialog: (masterPayerAccount: MasterPayerAccountsModelSnapshot) => void;
  MPARetireDialogComponent: () => JSX.Element | null;
};

export const useMPARetireDialogFacade = (): MPARetireDialogFacade => {
  const retireMasterPayerAccount = useMPARetireRequest();
  const [masterPayerAccount, setMasterPayerAccount] = useState<MasterPayerAccountsModelSnapshot | null>(null);
  const onCancel = () => setMasterPayerAccount(null);

  const MPARetireDialogComponent = useCallback(
    () =>
      masterPayerAccount && (
        <MPARetireDialog
          onCancel={onCancel}
          onRetire={() => retireMasterPayerAccount(masterPayerAccount)}
          masterPayerAccount={masterPayerAccount}
        />
      ),
    [retireMasterPayerAccount, masterPayerAccount]
  );

  return {
    showMPARetireDialog: setMasterPayerAccount,
    MPARetireDialogComponent,
  };
};
