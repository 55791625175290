import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { CustomerModel, type RoleModel, UserModel, type UserPermission } from "@doitintl/cmp-models";
import { getCollection, type ModelId, type WithFirebaseModel } from "@doitintl/models-firestore";
import { useQueryClient } from "@tanstack/react-query";
import { type IdTokenResult, type User } from "firebase/auth";

import { useSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { sendEmailVerification } from "../Pages/Login/auth";
import { emailNotVerifiedMessage } from "../Pages/Login/emailNotVerifiedMessage";
import { paths } from "../Pages/Login/helpers";
import { consoleErrorWithSentry } from "../utils";
import { fireAndForgetSettled } from "../utils/fireAndForget";
import { customAuth, rootAuth } from "../utils/firebase";
import { incrementSessionCount } from "../utils/incrementSessionCount";
import { type FirebaseUserWithEmail, type UserModelType } from "./UserContext";

const getUserRoles = async (userData: WithFirebaseModel<UserModel>): Promise<ModelId<RoleModel>[]> =>
  (
    await Promise.all(
      (userData.roles ?? []).flatMap(async (userRole) => {
        const role = await userRole.get();
        if (!role) {
          return;
        }
        return {
          id: role.id,
          ...role.asModelData(),
        };
      })
    )
  ).filter((role): role is ModelId<RoleModel> => !!role);

const sameUserRoles = (prevRoles: Array<ModelId<RoleModel>>, roles: Array<ModelId<RoleModel>>) => {
  for (const role of roles) {
    if (!prevRoles.find((prevRole) => prevRole.id === role.id)) {
      return false;
    }
  }

  return true;
};

const sameUserPermissions = (permissions: Array<UserPermission>, roles: Array<UserPermission>) => {
  for (const role of roles) {
    if (!permissions.includes(role)) {
      return false;
    }
  }

  return true;
};

export const useAppIndexUserState = () => {
  const [user, setUser] = useState<UserModelType | null | undefined>();
  const [currentUserToken, setCurrentUserToken] = useState<IdTokenResult | null>();
  const queryClient = useQueryClient();

  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();

  const handleSignOut = useCallback(async () => {
    try {
      queryClient.removeQueries({
        type: "all",
      });
      queryClient.clear();

      await Promise.all([rootAuth().signOut(), customAuth?.signOut()]);
      setUser(null);

      // when the user press the signout button, we want to reset the redirect when he logs in again
      // reset the location to root so after re-login he will be redirected to root
      history.push("/");
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [history, queryClient]);

  const handlerUserLogin = useCallback(
    (userId: string, customerId: string) =>
      getCollection(UserModel)
        .doc(userId)
        .onSnapshot(
          async (docSnapshot) => {
            const data = docSnapshot.asModelData();
            if (!data) {
              await handleSignOut();
              return;
            }

            const customerRef = getCollection(CustomerModel).doc(customerId);
            const customerModelData = (await customerRef.get()).asModelData();
            const trialUser = !!customerModelData && !!customerModelData.trialEndDate;

            if (
              trialUser &&
              customerModelData.trialEndDate &&
              customerModelData.trialEndDate.seconds * 1000 < Date.now()
            ) {
              await handleSignOut();
              showSnackbar({
                message: `${customerModelData.name} trial period has expired`,
                variant: "error",
                autoHideDuration: 10000,
              });
              history.push("/");
              return;
            }

            const roles = await getUserRoles(data);

            setUser((prevState) => ({
              ...data,
              permissions:
                prevState?.permissions &&
                data.permissions &&
                sameUserPermissions(prevState.permissions, data.permissions)
                  ? prevState.permissions
                  : data.permissions,
              id: docSnapshot.id,
              ref: prevState?.id === docSnapshot.id ? prevState.ref : docSnapshot.modelRef,
              roles: prevState?.roles && sameUserRoles(prevState.roles, roles) ? prevState.roles : roles,
            }));
          },
          () => {
            handleSignOut().catch(consoleErrorWithSentry);
          }
        ),
    [handleSignOut, history, showSnackbar]
  );

  const handlePartnerLogin = useCallback(
    (userId: string) =>
      getCollection(UserModel)
        .doc(userId)
        .onSnapshot(
          async (docSnapshot) => {
            const data = docSnapshot.asModelData();
            if (!data) {
              consoleErrorWithSentry("didn't find account manager user record");
              await handleSignOut();
              return;
            }

            const roles = await getUserRoles(data);

            setUser((prevState) => ({
              ...data,
              id: docSnapshot.id,
              ref: prevState?.id === docSnapshot.id ? prevState.ref : docSnapshot.modelRef,
              roles: prevState?.roles && sameUserRoles(prevState.roles, roles) ? prevState.roles : roles,
            }));
          },
          () => {
            handleSignOut().catch(consoleErrorWithSentry);
          }
        ),
    [handleSignOut]
  );

  const handleDoerLogin = useCallback(
    (userId: string) =>
      getCollection(UserModel)
        .doc(userId)
        .onSnapshot(
          async (docSnapshot) => {
            const data = docSnapshot.asModelData();
            if (!data) {
              await handleSignOut();
              return;
            }

            const roles = await getUserRoles(data);

            setUser((prevState) => ({
              ...data,
              id: docSnapshot.id,
              ref: prevState?.id === docSnapshot.id ? prevState.ref : docSnapshot.modelRef,
              roles: prevState?.roles && sameUserRoles(prevState.roles, roles) ? prevState.roles : roles,
            }));
          },
          () => {
            handleSignOut().catch(consoleErrorWithSentry);
          }
        ),
    [handleSignOut]
  );

  const validateEmailVerified = useCallback(
    async (currentUser: User | FirebaseUserWithEmail, currentUserToken: IdTokenResult) => {
      if (currentUserToken.claims.email_verified) {
        return true;
      }

      if (location.pathname !== paths.loginSuccess) {
        // Send email verification automatically only once per user
        sendEmailVerification(currentUser, true).catch(consoleErrorWithSentry);

        emailNotVerifiedMessage({
          showSnackbar,
          hideSnackbar,
          onSendEmailVerification: async () => {
            sendEmailVerification(currentUser).catch(consoleErrorWithSentry);
          },
        });
      }
      await handleSignOut();
      return false;
    },
    [handleSignOut, hideSnackbar, location.pathname, showSnackbar]
  );

  // doer login effect
  useEffect(() => {
    if (currentUserToken?.claims.doitEmployee) {
      return handleDoerLogin(currentUserToken.claims.userId as string);
    }
  }, [currentUserToken?.claims.doitEmployee, currentUserToken?.claims.userId, handleDoerLogin]);

  // regular user login effect
  useEffect(() => {
    if (currentUserToken?.claims.userId && currentUserToken.claims.customerId) {
      return handlerUserLogin(currentUserToken.claims.userId as string, currentUserToken.claims.customerId as string);
    }
  }, [currentUserToken?.claims.customerId, currentUserToken?.claims.userId, handlerUserLogin]);

  // partner login
  useEffect(() => {
    if (currentUserToken?.claims.doitPartner) {
      return handlePartnerLogin(currentUserToken.claims.userId as string);
    }
  }, [currentUserToken?.claims.doitPartner, currentUserToken?.claims.userId, handlePartnerLogin]);

  const onAuthStateChangedGotUser = useCallback(
    async (currentUser: User | FirebaseUserWithEmail | null, currentUserToken: IdTokenResult | null) => {
      if (!currentUser || !currentUserToken) {
        return;
      }

      if (process.env.NODE_ENV === "development") {
        (window as any).refreshToken = () => currentUser.getIdTokenResult(true);
      }

      if (!(await validateEmailVerified(currentUser, currentUserToken))) {
        return;
      }

      // TODO: should happen server side
      fireAndForgetSettled(incrementSessionCount(currentUserToken.claims.userId as string, currentUser));

      setCurrentUserToken(currentUserToken);
    },
    [validateEmailVerified]
  );

  const onAuthStateChangedNullUser = useCallback((currentUser: FirebaseUserWithEmail | null) => {
    if (!currentUser) {
      setUser(null);
      setCurrentUserToken(null);
    }
  }, []);

  useEffect(() => {
    if (user?.disabled) {
      handleSignOut().catch(consoleErrorWithSentry);
    }
  }, [handleSignOut, user?.disabled]);

  return useMemo(
    () => ({
      onAuthStateChangedGotUser,
      onAuthStateChangedNullUser,
      handleSignOut,
      user,
    }),
    [onAuthStateChangedGotUser, onAuthStateChangedNullUser, handleSignOut, user]
  );
};
