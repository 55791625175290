import { cloneElement, useEffect, useMemo, useState } from "react";

import { type SimulationRecommenderExplorerDoc, SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Card, CardContent, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getTreeMapColors } from "../../../Pages/CloudAnalytics/utilities";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "../Analytics/cloudCardStyle";
import { SkeletonCard } from "../SkeletonCard";
import { type WidgetItemProps } from "../types";
import { SlotGroupingMode } from "./constants";
import TimeSelect from "./TimeSelect";

const useStyles = makeStyles(() => ({
  cardContent: {
    height: 300,
  },
  cardHeader: {
    height: CARD_HEADER_HEIGHT,
    padding: HEADER_PADDING,
  },
  action: {
    marginTop: 8,
    marginRight: 8,
  },
}));

export const SlotExplorer = ({ fallbackComponent, widgetHeight = 1 }: WidgetItemProps) => {
  const classes = useStyles();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const theme = useTheme();
  const [selectedMode, setSelectedMode] = useState(SlotGroupingMode.hour);
  const [data, setData] = useState<SimulationRecommenderExplorerDoc | null>();
  const { selectedBillingMode, selectedTimeframe } = useCurrentDashboardContext();

  useEffect(() => {
    getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection(selectedBillingMode)
      .doc(customer.id)
      .collection("explorer")
      .doc(selectedTimeframe)
      .get()
      .then((doc) => {
        setData(doc.asModelData() ?? null);
      });
  }, [selectedBillingMode, selectedTimeframe, customer.id]);

  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    const usedData = data[selectedMode];
    return {
      chart: {
        zoomType: "xy",
        height: widgetHeight * 185,
        backgroundColor: "transparent",
        animation: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        x: 0,
        verticalAlign: "top",
        y: -14,
        itemStyle: {
          fontSize: 10,
          color: theme.palette.text.primary,
        },
        floating: false,
        backgroundColor: "transparent",
      },
      yAxis: [
        {
          gridLineColor: theme.palette.general.divider,
          title: {
            text: null,
          },
          labels: {
            style: {
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      xAxis: [
        {
          categories: usedData.xAxis,
          crosshair: true,
          labels: {
            style: {
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      series: [
        {
          name: "Peak usage",
          type: "line",
          color: getTreeMapColors(theme.palette.mode)[1],
          animation: true,
          data: usedData.line,
        },

        {
          name: "Average usage",
          type: "column",
          color: getTreeMapColors(theme.palette.mode)[0],
          animation: true,
          data: usedData.bar,
        },
      ],
      tooltip: {
        valueDecimals: 0,
      },
    };
  }, [data, selectedMode, widgetHeight, theme.palette.text.primary, theme.palette.mode, theme.palette.general.divider]);

  const subheader = "Slots consumption per hour or day";
  const title = "Slots Consumption";
  if (data === null) {
    if (!fallbackComponent) {
      return null;
    }
    return cloneElement(fallbackComponent, { title, subheader, noData: true });
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Grid>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={title}
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader={subheader}
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
          action={
            !data ? undefined : (
              <div className={classes.action}>
                <TimeSelect selectedValue={selectedMode} onChange={setSelectedMode} />
              </div>
            )
          }
        />

        <CardContent style={{ height: widgetHeight * 200 }} className={classes.cardContent}>
          <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} />
        </CardContent>
      </Card>
    </Grid>
  );
};
