import { AssetTypeDoiTCloudNavigator, AssetTypeDoiTCloudSolve, type LookerSku } from "@doitintl/cmp-models";

export const fieldRange = {
  discount: {
    min: 0,
    max: 100,
  },
  partnerMargin: {
    min: 0,
    max: 100,
  },
  rebaseModifier: {
    min: -Infinity,
    max: 100,
  },
  estimatedValue: {
    min: 0,
    max: Infinity,
  },
  gcpFlexSaveOverwrite: {
    default: 0,
    min: 0,
    max: 100,
  },
  plpsPercent: {
    default: 4,
    min: 0,
    max: 10,
  },
  monthlyFlatRate: {
    min: 0,
    max: 100,
  },
};

export const gsuiteOffers = [
  {
    value: 100 * (1 - 6 / 12),
    label: "12 months for 6 [50% discount]",
  },
  {
    value: 100 * (1 - 7 / 12),
    label: "12 months for 7 [41.67% discount]",
  },
  {
    value: 100 * (1 - 8 / 12),
    label: "12 months for 8 [33.34% discount]",
  },
  {
    value: 100 * (1 - 9 / 12),
    label: "12 months for 9 [25% discount]",
  },
  {
    value: 100 * (1 - 10 / 12),
    label: "12 months for 10 [16.67% discount]",
  },
  {
    value: 100 * (1 - 11 / 12),
    label: "12 months for 11 [8.34% discount]",
  },
  {
    value: 100 * (1 - 12 / 15),
    label: "15 months for 12 [20% discount]",
  },
  {
    value: 100 * (1 - 12 / 18),
    label: "18 months for 12 [33.34% discount]",
  },
  {
    value: 100 * (1 - 14 / 24),
    label: "24 months for 14 [41.67% discount]",
  },
  {
    value: 100 * (1 - 15 / 24),
    label: "24 months for 15 [37.5% discount]",
  },
  {
    value: 100 * (1 - 16 / 24),
    label: "24 months for 16 [33.34% discount]",
  },
  {
    value: 100 * (1 - 17 / 24),
    label: "24 months for 17 [29.17% discount]",
  },
  {
    value: 100 * (1 - 18 / 24),
    label: "24 months for 18 [25% discount]",
  },
  {
    value: 100 * (1 - 19 / 24),
    label: "24 months for 19 [20.84% discount]",
  },
  {
    value: 100 * (1 - 20 / 24),
    label: "24 months for 20 [16.67% discount]",
  },
  {
    value: 100 * (1 - 21 / 24),
    label: "24 months for 21 [12.5% discount]",
  },
  {
    value: 100 * (1 - 22 / 24),
    label: "24 months for 22 [8.34% discount]",
  },
];

export const lookerSkuDefaultValues: LookerSku = {
  skuName: { label: "", monthlyListPrice: 0, googleSku: "" },
  months: 0,
  quantity: 0,
  monthlySalesPrice: 0,
};

export const contractFileSizeBytes = 20 * 1024 * 1024;

export const subscriptionTypeOptions = [
  { label: "On-demand", value: "on-demand" },
  { label: "Commitment", value: "commitment" },
];

export const commitmentTermOptions = [3, 6, 9, 12];
export const onDemandTermOptions = ["Monthly automatic renewal"];

export const paymentTermOptions = [
  { label: "Annual Pre-payment", value: "annual" },
  { label: "Month-to-Month payment", value: "monthly" },
];
export const chargePerTermLabelMap = {
  ["annual"]: {
    label: "Annual price",
    text: "annual price",
  },
  ["monthly"]: {
    label: "Monthly price",
    text: "per month",
  },
};
export const queryTypeMap = {
  [AssetTypeDoiTCloudNavigator]: "navigator",
  [AssetTypeDoiTCloudSolve]: "solve",
};
export const pointOfSaleMap = [
  { value: "gcp-marketplace", label: "GCP marketplace" },
  { value: "aws-marketplace", label: "AWS marketplace" },
  { value: "doit", label: "Direct" },
];
