import LaunchIcon from "@mui/icons-material/Launch";
import { ListItemIcon, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { formatDecimalNumber } from "../../../../../utils/common";
import { FormattedCostCell } from "../../../Common/TableCell/FormattedCostCell";
import { CoverageCell } from "../../../Common/TableCell/FormattedCoverageCell";
import { LinkCell } from "../../../Common/TableCell/LinkCell";
import { ThreeDotsMenuCell } from "../../../Common/TableCell/ThreeDotsMenuCell";
import { FamilyTypeTableCell } from "../Common/commonTableCells";
import { AggregatedPurchaseStatus } from "../Common/Status/AggregatedPurchaseStatus";
import { type BulkPurchaseItem } from "../types";
import { getFormattedCoverage } from "../utils/common";
import { buildWorkloadsSkuDataStudioUrl } from "../utils/linkUtils";

type Props = {
  row: BulkPurchaseItem;
  onPurchaseButtonClick: (row: BulkPurchaseItem) => void;
  allowPurchase: boolean;
};

export const BulkPurchasesRow = ({ row, onPurchaseButtonClick, allowPurchase }: Props) => {
  const actionOptions: ThreeDotsMenuOption[] = [
    {
      label: (
        <Typography
          sx={row.status === "purchase-required" && allowPurchase ? {} : { opacity: 0.15, cursor: "not-allowed" }}
        >
          Purchase
        </Typography>
      ),
      action: row.status === "purchase-required" && allowPurchase ? () => onPurchaseButtonClick(row) : () => void 0,
      key: "purchase",
      dataCy: "purchase",
    },
    {
      label: (
        <>
          <Typography pr={1}>Open in Data Studio</Typography>
          <ListItemIcon>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
        </>
      ),
      action: () => window.open(buildWorkloadsSkuDataStudioUrl(row), "_blank"),
      key: "dataStudioLink",
      dataCy: "data-studio-link",
    },
  ];

  return (
    <CellsWrapper>
      <LinkCell linkText={row.region} to={buildWorkloadsSkuDataStudioUrl(row)} />
      <FamilyTypeTableCell type={row.familyType} />
      <TableCell>{row.hardware}</TableCell>
      <TableCell>
        <AggregatedPurchaseStatus status={row.status} workloads={row.workloads} />
      </TableCell>
      <TableCell>{row.totalCustomers || "-"}</TableCell>
      <TableCell>{formatDecimalNumber(row.totalOnDemand, 0)}</TableCell>
      <CoverageCell coverage={row.coverage} />
      <TableCell>
        {["purchase-required", "in-progress", "purchased"].includes(row.status)
          ? getFormattedCoverage(row.globalCoverageAfterPurchase)
          : "-"}
      </TableCell>
      <TableCell>{row.totalCuds}</TableCell>
      <TableCell>{row.doitCuds}</TableCell>
      <TableCell>{row.purchaseRecommendations || "-"}</TableCell>
      <TableCell>{row.customersCount || "-"}</TableCell>
      <FormattedCostCell cost={row.purchasePlanPrice || 0} />
      <ThreeDotsMenuCell options={actionOptions} style={{ padding: 0 }} />
    </CellsWrapper>
  );
};
