import { useMemo } from "react";

import { InternalTierNames, TierPackageTypes } from "@doitintl/cmp-models";
import Grid from "@mui/material/Grid";

import { homeTexts } from "../../assets/texts";
import { useContractsContext } from "../../Context/customer/ContractsContext";
import { useTier } from "../../Context/TierProvider";
import { PackageCard } from "./PackageCard/PackageCard";
import SectionHeader from "./SectionHeader";

const packageCards = {
  advantage: {
    title: "DoiT Cloud Advantage",
    subheader: "Cloud Procurement",
    description:
      "Buy your public cloud through DoiT and get access to volume pricing, automated cost savings, billing support, and more.",
    learnMoreLink: "https://www.doit.com/pricing/#advantage",
  },
  navigator: {
    title: "DoiT Cloud Navigator",
    subheader: "Product",
    description: "With DoiT’s intelligent technology, you can manage, analyze and optimize your public cloud costs.",
    learnMoreLink: "https://www.doit.com/pricing/#navigator",
  },
  solve: {
    title: "DoiT Cloud Solve",
    subheader: "Services",
    description:
      "Access DoiT’s global team of experts with decades of experience in cloud architecture, Kubernetes, machine learning, and more.",
    learnMoreLink: "https://www.doit.com/pricing/#solve",
  },
};

export const DoitPackagesSection = () => {
  const { getCustomerTier } = useTier();
  const { contracts } = useContractsContext();

  const navigatorCustomerTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const solveCustomerTier = getCustomerTier(TierPackageTypes.SOLVE);
  const isAdvantage = contracts.some((c) =>
    ["amazon-web-services", "google-cloud", "microsoft-azure"].includes(c.type)
  );

  const cardsToShow = useMemo(
    () =>
      [
        { cardId: "advantage", isShowing: !isAdvantage },
        {
          cardId: "navigator",
          isShowing: !navigatorCustomerTier,
        },
        { cardId: "solve", isShowing: !solveCustomerTier },
      ]
        .filter(({ isShowing }) => !isShowing)
        .map(({ cardId }) => {
          const card = packageCards[cardId];

          return (
            <Grid item xs={12} md={4} display="flex" key={cardId}>
              <PackageCard
                title={card.title}
                subheader={card.subheader}
                description={card.description}
                learnMoreLink={card.learnMoreLink}
              />
            </Grid>
          );
        }),
    [isAdvantage, navigatorCustomerTier, solveCustomerTier]
  );

  if (
    navigatorCustomerTier?.name.startsWith(InternalTierNames.HERIGATE) ||
    solveCustomerTier?.name.startsWith(InternalTierNames.HERIGATE) ||
    !cardsToShow.length
  ) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionHeader text={homeTexts.PACKAGE_CARD_SECTION_TITLE} />
      </Grid>
      {cardsToShow}
    </Grid>
  );
};
