import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { Alert, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDebtContext } from "../../../Context/customer/CustomerDebt";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { assetTypeName } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { type PageId } from "../../config/pages";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

type Props = {
  hide: boolean;
  pageId?: PageId;
};

const hideInPages: PageId[] = ["analytics:reports:details"];

export const PayOverdueAlert = ({ hide, pageId }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const customerDebt = useDebtContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const { userRoles } = useUserContext();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!hide && !!customer?.id && !!userRoles?.invoicesViewer && !!customerDebt.date?.isValid);
  }, [hide, customer?.id, userRoles, customerDebt.date?.isValid, customer?.classification]);

  const productsList = useMemo(() => {
    const products = customerDebt?.products ?? [];

    switch (products.length) {
      case 0:
        return "subscriptions";
      case 1:
        return `${assetTypeName(products[0])} subscription`;
      default:
        return `${products.map((p) => assetTypeName(p)).join(", ")} subscriptions`;
    }
  }, [customerDebt?.products]);

  const handlePayClick = useCallback(() => {
    if (!customer?.id) {
      return;
    }

    mixpanel.track("customer.open.overdue.fixit");

    history.push(`/customers/${customer.id}/invoices?overdue`);
  }, [customer?.id, history]);

  const shouldHideInPage = pageId && hideInPages.includes(pageId);

  return (
    <>
      {open && (
        <Alert
          classes={{
            root: classes.root,
          }}
          onClose={() => {
            setOpen(false);
          }}
          severity="error"
          sx={{ display: shouldHideInPage ? "none" : "flex" }}
        >
          Overdue and outstanding invoices - We are unable to renew your {productsList}. Please pay overdue invoices or
          you may not be able to use these services.{" "}
          <Button
            classes={{
              root: classes.root,
            }}
            color="inherit"
            variant="text"
            onClick={() => {
              handlePayClick();
            }}
          >
            Pay now
          </Button>
        </Alert>
      )}
    </>
  );
};
