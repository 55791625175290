import { useMemo } from "react";

import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useFullScreen } from "../../utils/dialog";
import { InfoPanel } from "../Alerts/InfoPanel";
import { type Step } from "./Types";

type Props = {
  steps?: Step[];
  activeStep?: number;
  setActiveStep: (step: number) => void;
};

export const StepperBreadcrumbs = ({ steps, activeStep, setActiveStep }: Props) => {
  const { isMobile } = useFullScreen();
  const theme = useTheme();

  const previousSteps = useMemo(() => {
    if (steps === undefined || activeStep === undefined) {
      return [];
    }
    return steps.filter((step, index) => index < activeStep);
  }, [steps, activeStep]);

  if (previousSteps.length === 0) {
    return null;
  }

  if (!previousSteps.find((step) => step.breadcrumbValue)) {
    return null;
  }

  if (steps && activeStep && steps[activeStep].breadcrumbValue === false) {
    return null;
  }

  return (
    <InfoPanel style={{ bgcolor: theme.palette.mode === "light" ? "#FAFAFA" : "#2D2D39", marginTop: 2 }}>
      {previousSteps.map((step, index) => (
        <Box key={index} display="flex" justifyContent="space-between">
          <Box display="flex">
            {!isMobile && (
              <Typography fontSize={14} sx={{ fontWeight: 500 }} variant="body1">
                {step.label}:&nbsp;
              </Typography>
            )}
            <Typography fontSize={14} variant="body1">
              {step?.breadcrumbValue}
            </Typography>
          </Box>
          <Link
            sx={{ cursor: "pointer", fontSize: 14 }}
            color="inherit"
            onClick={() => setActiveStep(index)}
            variant="body1"
          >
            Edit
          </Link>
        </Box>
      ))}
    </InfoPanel>
  );
};
