import { useEffect, useMemo, useState } from "react";

import debounce from "lodash/debounce";

export function useDebounce<T>(value: T) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const debounceFn = useMemo(() => debounce((value) => setDebouncedValue(value), 500, { maxWait: 1000 }), []);
  useEffect(() => {
    debounceFn(value);
  }, [value, debounceFn]);
  return debouncedValue;
}
