import { useCallback } from "react";

import {
  type CommonSubscriptionNotificationSettings,
  type CustomerModel,
  customPeriodUnits,
  type DeliveryFrequency,
  type NotificationModel,
  NotificationProviderType,
  type UserModel,
  type UserNotification,
} from "@doitintl/cmp-models";
import { type Reference } from "@doitintl/models-types";
import { useTheme } from "@mui/material/styles";
import { type FormikErrors, useFormikContext } from "formik";
import isEmail from "is-email";
import { type DateObjectUnits, DateTime, type DurationUnit, type WeekdayNumbers } from "luxon";

import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import { isDoitEmployeeEmail } from "../../utils/email";
import { useHasAdminOrSaasAdminRole } from "../../utils/permissionHooks";
import { getActualOrg, getUserOrInviteByEmail } from "./db";
import {
  type SubscriptionByType,
  type SubscriptionFormMode,
  type SubscriptionFormValues,
  type SubscriptionType,
  type ValidatedEmail,
} from "./types";

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

export function isThereInputError(errors: FormikErrors<SubscriptionFormValues>) {
  if (!errors) {
    return false;
  }

  return Object.keys(errors).some((key) => errors[key]);
}

export function useSetPartialValues() {
  const { values, setValues } = useFormikContext<SubscriptionFormValues>();

  return useCallback(
    (newValues: Partial<SubscriptionFormValues>) => {
      setValues(
        {
          ...values,
          ...newValues,
        },
        true
      );
    },
    [values, setValues]
  );
}

export function useSetFieldValueTyped() {
  const { setFieldValue } = useFormikContext<SubscriptionFormValues>();

  return useCallback(
    <T extends keyof SubscriptionFormValues>(field: T, value: SubscriptionFormValues[T]) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );
}

const externalDomainError = (email: string) => `${email.split("@")[1]} is not a valid domain`;
const orgMismatchError = (email: string) => `${email} is not part of your organization`;
const isInvalidEmailAddressError = (email: string) => `${email} is not a valid email address`;
const unexpectedError = (email: string) => `failed to validate ${email} with:`;

export async function validateEmail({
  email,
  customerRef,
  requiredOrg,
  allowedDomains,
  ownerEmail,
  initialEmails,
}: {
  ownerEmail: string;
  email: string;
  customerRef: Reference<CustomerModel>;
  requiredOrg: CommonSubscriptionNotificationSettings["orgRef"];
  allowedDomains: string[];
  initialEmails: string[];
}): Promise<ValidatedEmail> {
  {
    try {
      if (!isEmail(email)) {
        return {
          email,
          error: isInvalidEmailAddressError(email),
        };
      }

      if (email.toLowerCase() === ownerEmail.toLowerCase()) {
        return {
          email,
        };
      }

      if (initialEmails.includes(email)) {
        return {
          email,
        };
      }

      if (!allowedDomains.some((domain) => email.endsWith(`@${domain}`))) {
        return {
          email,
          error: externalDomainError(email),
        };
      }

      const user = await getUserOrInviteByEmail({ email, customerRef });
      if (!user) {
        return {
          email,
          error: orgMismatchError(email),
        };
      }

      if (!doesUserInRequiredOrg({ user, requiredOrg, customerId: customerRef.id })) {
        return {
          email,
          error: orgMismatchError(email),
        };
      }

      return {
        email,
      };
    } catch (e: any) {
      consoleErrorWithSentry(e);
      return {
        email,
        error: `${unexpectedError(email)} ${e.message}`,
      };
    }
  }
}

export function calcNextAt({
  deliveryFrequency,
  startDate,
  currentDateTime,
  time,
  timeZone,
  customPeriodAmount,
  customPeriodUnit,
}: Omit<SubscriptionFormValues, "slackChannels" | "emails"> & { currentDateTime: DateTime }) {
  if (!startDate || !time || !timeZone || !deliveryFrequency) {
    throw new Error(
      `Missing required fields: ${startDate ? "" : "startDate"} ${time ? "" : "time"} ${
        timeZone ? "" : "timeZone"
      } ${deliveryFrequency ? "" : "deliveryFrequency"}`
    );
  }

  if (!startDate.isValid) {
    throw new Error("Invalid startDate");
  }

  if (!time.isValid) {
    throw new Error("Invalid time");
  }

  if (!currentDateTime.isValid) {
    throw new Error("Invalid currentTime");
  }

  const periodAmount = deliveryFrequency === "Custom" ? customPeriodAmount : 1;
  if (!periodAmount) {
    throw new Error("Missing customPeriodAmount");
  }
  if (periodAmount < 1) {
    throw new Error(`Invalid customPeriodAmount value: ${customPeriodAmount}`);
  }

  let periodUnit: DurationUnit;
  const overrides: DateObjectUnits = {
    hour: time.hour,
    minute: time.minute,
    second: 0,
    millisecond: 0,
  };

  switch (deliveryFrequency) {
    case "Daily":
      {
        periodUnit = "days";
      }
      break;
    case "Weekly":
      {
        periodUnit = "weeks";
        overrides.weekday = startDate.weekday as any as WeekdayNumbers;
      }
      break;
    case "Monthly":
      {
        periodUnit = "months";
      }
      break;
    case "Annually":
      {
        periodUnit = "years";
      }
      break;
    case "Custom":
      {
        if (!customPeriodUnit) {
          throw new Error("Missing customPeriodUnit");
        }
        if (!customPeriodUnits.includes(customPeriodUnit as any)) {
          throw new Error(`Invalid customPeriodUnit value: ${customPeriodUnit}`);
        }
        periodUnit = `${customPeriodUnit}s`.toLowerCase() as DurationUnit;
      }
      break;
    default:
      throw new Error("Unknown frequency");
  }

  let nextAt = startDate.set(overrides).setZone(timeZone, { keepLocalTime: true });

  if (currentDateTime > nextAt) {
    // Calculate the difference to add to the nextAt
    const timeDiff = currentDateTime.diff(nextAt, periodUnit).toObject();
    const timeDiffAmount = timeDiff[periodUnit];
    if (typeof timeDiffAmount !== "number") {
      throw new Error(`Invalid timeDiffAmount: ${timeDiffAmount} for timeDiff: ${JSON.stringify(timeDiff)}`);
    }

    const periodsToAdd = Math.floor(timeDiffAmount / periodAmount);

    nextAt = nextAt.plus({ [periodUnit]: periodsToAdd * periodAmount });
  }

  // If the calculated next occurrence is in the past, add one custom period
  if (nextAt < currentDateTime) {
    nextAt = nextAt.plus({ [periodUnit]: periodAmount });
  }

  if (!nextAt.isValid) {
    throw new Error("Calculated invalid nextAt");
  }
  return nextAt;
}

export function doesUserInRequiredOrg({
  user,
  requiredOrg,
  customerId,
}: {
  user: Pick<UserModel, "organizations" | "customer" | "email">;
  requiredOrg: CommonSubscriptionNotificationSettings["orgRef"];
  customerId: string;
}) {
  const actualUserOrg = getActualOrg({
    orgs: user.organizations,
    customerId,
    isDoitEmployee: isDoitEmployeeEmail(user.email) && !user.customer,
  });

  return requiredOrg.path === actualUserOrg.path;
}

function getDayOfMonthSuffix(day: string) {
  switch (day) {
    case "1":
    case "21":
    case "31":
      return "st";
    case "2":
    case "22":
      return "nd";
    case "3":
    case "23":
      return "rd";
    default:
      return "th";
  }
}

export function getScheduleText({
  format,
  startDate,
  time,
  deliveryFrequency,
  customPeriodAmount,
  customPeriodUnit,
}: {
  format: "long" | "short";
  startDate: DateTime;
  time: DateTime;
  deliveryFrequency: DeliveryFrequency;
  customPeriodAmount: number | null;
  customPeriodUnit: string | null;
}) {
  if (!startDate.isValid || !time.isValid || !deliveryFrequency) {
    return "Schedule not set";
  }

  let scheduleText: string;
  let longPreamble: string;
  let longFinale = "";

  switch (deliveryFrequency) {
    case "Daily":
      {
        longPreamble = "scheduled to send";
        scheduleText = `daily at ${time.toLocaleString(timeFormat)}`;
      }
      break;
    case "Weekly":
      {
        longPreamble = "scheduled to send";
        scheduleText = `every ${startDate.weekdayLong} at ${time.toLocaleString(timeFormat)}`;
      }
      break;
    case "Monthly":
      {
        const dayOfMonth = startDate.toFormat("d");
        const suffix = getDayOfMonthSuffix(dayOfMonth);
        longPreamble = "scheduled to send";
        scheduleText = `on the ${dayOfMonth}${suffix} of every month`;
      }
      break;
    case "Annually":
      {
        longPreamble = "scheduled to send";
        const suffix = getDayOfMonthSuffix(startDate.toFormat("d"));
        scheduleText = `on ${startDate.toFormat("MMMM d'")}${suffix} every year`;
      }
      break;
    case "Custom":
      {
        if (!customPeriodAmount || !customPeriodUnit) {
          return "Schedule not set";
        }

        longPreamble = "scheduled to send";
        scheduleText =
          customPeriodAmount > 1
            ? `every ${customPeriodAmount} ${customPeriodUnit.toLowerCase()}s`
            : `every ${customPeriodUnit.toLowerCase()}`;

        switch (customPeriodUnit) {
          case "Day":
            {
              longFinale = `at ${time.toLocaleString(timeFormat)}`;
            }
            break;
          case "Week":
            {
              longFinale = `on ${startDate.weekdayLong} at ${time.toLocaleString(timeFormat)}`;
            }
            break;
          case "Month":
            {
              const dayOfMonth = startDate.toFormat("d");
              const suffix = getDayOfMonthSuffix(dayOfMonth);
              longFinale = `on the ${dayOfMonth}${suffix} at ${time.toLocaleString(timeFormat)}`;
            }
            break;
          case "Year":
            {
              const suffix = getDayOfMonthSuffix(startDate.toFormat("d"));
              longFinale = `on ${startDate.toFormat("MMMM d'")}${suffix}`;
            }
            break;
        }
      }
      break;
  }

  if (format === "long") {
    scheduleText = `${longPreamble} ${scheduleText} ${longFinale}`;
  }
  // capitalize the first letter
  scheduleText = scheduleText.charAt(0).toUpperCase() + scheduleText.slice(1);

  return scheduleText;
}

export function constructFormInitialValues<ST extends SubscriptionType>({
  mode,
  subscription,
  userEmail,
  subscriptionType,
}: {
  mode: SubscriptionFormMode;
  subscription: SubscriptionByType<ST> | undefined;
  userEmail: string;
  subscriptionType: ST;
}): SubscriptionFormValues {
  if (mode === "create") {
    return {
      emails: [userEmail.toLowerCase()],
      slackChannels: [],
      timeZone: DateTime.local().zoneName,
      time: DateTime.local().startOf("hour").plus({ hours: 1 }),
      startDate: DateTime.local().startOf("day"),
      deliveryFrequency: "Daily",
      customPeriodAmount: null,
      customPeriodUnit: null,
    };
  }

  if (!subscription) {
    throw new Error("Missing subscription in edit mode");
  }
  const settings = subscription.selectedNotifications[subscriptionType as UserNotification];
  if (!settings) {
    throw new Error("Missing notification settings");
  }

  return {
    emails: subscription.providerTarget?.[NotificationProviderType.EMAIL] || [],
    slackChannels: subscription.providerTarget?.[NotificationProviderType.SLACK] || [],
    timeZone: settings.timeZone,
    time: DateTime.fromMillis(settings.time.toMillis(), { zone: settings.timeZone }),
    startDate: DateTime.fromMillis(settings.startDate.toMillis(), { zone: settings.timeZone }),
    deliveryFrequency: settings?.frequency,
    customPeriodAmount: settings?.customPeriodAmount || null,
    customPeriodUnit: settings?.customPeriodUnit || null,
  };
}

export function useSubscriptionPermissions(
  subscription: Pick<NotificationModel, "createdBy" | "providerTarget"> | undefined
) {
  const { userModel } = useUserContext({ allowNull: false });
  const { isDoitEmployee } = useAuthContext();

  const isAdmin = useHasAdminOrSaasAdminRole();

  const permissions = {
    allowedToEdit: false,
    allowedToDelete: false,
    canSubscribe: false,
    isSubscribed: false,
  };

  if (!subscription) {
    return permissions;
  }

  const isOwner = subscription.createdBy.toLowerCase() === userModel.email.toLowerCase();

  const subscriptionHasEmailTarget = !!subscription.providerTarget?.[NotificationProviderType.EMAIL]?.length;
  const subscriptionHasSlackTarget = !!subscription.providerTarget?.[NotificationProviderType.SLACK]?.length;
  const isSlackOnly = subscriptionHasSlackTarget && !subscriptionHasEmailTarget;

  permissions.isSubscribed = !!subscription.providerTarget?.[NotificationProviderType.EMAIL]?.some(
    (target) => target.toLowerCase() === userModel.email.toLowerCase()
  );

  permissions.canSubscribe = !permissions.isSubscribed && !isSlackOnly;
  permissions.allowedToEdit = isOwner || isAdmin || !!isDoitEmployee;
  permissions.allowedToDelete = isOwner || isAdmin || !!isDoitEmployee;

  return permissions;
}

export function RequiredLabel(label: string) {
  const theme = useTheme();
  return (
    <span>
      {label}
      <span style={{ color: theme.palette.error.main }}> *</span>
    </span>
  );
}
