import { CloudflowKind, type CloudflowKindText, type CloudflowLink } from "../../types";

export const links: CloudflowLink[] = [
  {
    title: "Link 1",
    url: "#",
  },
  {
    title: "Link 2",
    url: "#",
  },
  {
    title: "Link 3",
    url: "#",
  },
];

export const cloudflowKindTexts: {
  [key: string]: CloudflowKindText;
} = {
  [CloudflowKind.fromScratch]: { title: "Start from scratch", subtitle: "Build your own unique CloudFlow" },
  [CloudflowKind.template]: {
    title: "Use a template",
    subtitle: "Make a head start and choose from over 20 templates",
  },
};

export const cloudflowTemplates = [
  {
    id: "1",
    name: "Template 1",
    description: "Description 1",
  },
  {
    id: "2",
    name: "Template 2",
    description: "Description 2",
  },
  {
    id: "3",
    name: "Template 3",
    description: "Description 3",
  },
  {
    id: "4",
    name: "Template 4",
    description: "Description 4",
  },
  {
    id: "5",
    name: "Template 5",
    description: "Description 5",
  },
];
