import { type ReactNode, useEffect, useState } from "react";

import { DoitRole, EntityInvoicingModes } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";

import { useApiContext } from "../../../api/context";
import { billingProfileHeadingId } from "../../../constants";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { usePriorityErpStatus } from "../../../Context/usePriorityErpStatus";
import { syncEntityInvoiceAttributions } from "../../../Pages/Entity/api";
import { priorityCompanyTransform } from "../../../utils/common";
import { DoitConsoleTitle } from "../../DoitConsoleTitle";
import { useDoitRoleCheck } from "../../hooks/useDoitRoles";
import { Loader } from "../../Loader";
import { type EditBillingProfileData } from "../BillingProfileForm.models";
import { useEditBillingProfileSubmitFunction } from "../useEditBillingProfileSubmitFunction";
import { useFetchEntityFromPriority } from "../useFetchEntityFromPriority";
import { useFetchSavedPaymentMethods } from "../useFetchSavedPaymentMethods";
import { PaymentMethodCategory } from "./api";
import { BillingForm } from "./BillingForm";
import { AnchorLink } from "./common/AnchorLink";
import { CustomInput } from "./common/CustomInput";
import { ChangeBillingProfileStatusButton } from "./components/ChangeBillingProfileStatusButton";
import { DetachBillingProfileButton } from "./components/DetachBillingProfileButton";
import { PaymentMethodsPanel } from "./components/payment-methods/PaymentMethodsPanel";
import { PaymentTermsField } from "./components/PaymentTermsField";
import { createBillingProfileFromValidationSchema } from "./CreateBillingProfileForm";
import { EditBillingProfileContextProvider } from "./EditBillingProfileFormContext";
import { ADDRESS_PANEL_ID } from "./panels/AddressPanel";
import { CONTACT_PANEL_ID } from "./panels/ContactPanel";
import { INVOICE_SETTINGS_PANEL_ID, InvoiceSettingsPanel } from "./panels/InvoiceSettingsPanel";
import { PAYMENT_PANEL_ID } from "./panels/PaymentPanel";
import { PriorityMaintenance } from "./PriorityMaintenance";

const PageContainer = ({ children }: { children: ReactNode }) => (
  <Container component="div" maxWidth="md" disableGutters sx={{ "&.MuiContainer-maxWidthMd": { maxWidth: 700 } }}>
    {children}
  </Container>
);

const CenteredHeader = ({ children }: { children: ReactNode }) => (
  <Box display="flex" justifyContent="center" pl={4} pr={4}>
    <PageContainer>{children}</PageContainer>
  </Box>
);

export const editBillingProfileFromValidationSchema: Yup.ObjectSchema<EditBillingProfileData> =
  createBillingProfileFromValidationSchema.concat(
    Yup.object({
      payCode: Yup.string().required().trim(),
      paymentMethodCategory: Yup.mixed<PaymentMethodCategory>().oneOf(Object.values(PaymentMethodCategory)).required(),
      paymentMethodType: (Yup.string() as Yup.StringSchema<string>).when("paymentMethodCategory", {
        is: (paymentMethodType: PaymentMethodCategory) => paymentMethodType === PaymentMethodCategory.STRIPE,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
      paymentMethodId: (Yup.string() as Yup.StringSchema<string>).when("paymentMethodCategory", {
        is: (paymentMethodType: PaymentMethodCategory) => paymentMethodType === PaymentMethodCategory.STRIPE,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
      invoicingMode: (Yup.string() as Yup.StringSchema<EntityInvoicingModes>)
        .required()
        .oneOf(Object.values(EntityInvoicingModes)),
      autoAssignGCP: (Yup.boolean() as Yup.BooleanSchema<boolean>).required(),
      buckets: Yup.array()
        .of(
          Yup.object({
            id: Yup.string(),
            name: (Yup.string() as Yup.StringSchema<string>).required().trim(),
            isDefault: (Yup.boolean() as Yup.BooleanSchema<boolean>).required(),
            assets: Yup.array().required(),
            ref: Yup.mixed(),
          })
        )
        .required(),
      invoicePerService: Yup.boolean().required(),
      separateInvoice: Yup.boolean().required(),
    })
  );

type Props = { entity: Entity; onClose: () => void };

export const EditBillingProfileForm = ({ entity, onClose }: Props) => {
  const [error, setError] = useState<string>();
  const [dividerElement, setDividerElement] = useState<HTMLHRElement | null>(null);
  const [billingProfile, entityFromPriority] = useFetchEntityFromPriority(entity);
  const [isLoadingSavePaymentMethods, savedPaymentMethods] = useFetchSavedPaymentMethods(entity, setError);
  const [isSubmitting, editBillingProfile] = useEditBillingProfileSubmitFunction(
    entity,
    entityFromPriority,
    billingProfile,
    onClose
  );
  const [isPristine, setIsPristine] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const isBillingProfileAdmin = useDoitRoleCheck(DoitRole.BillingProfileAdmin);
  const showDetachButton = useDoitRoleCheck(DoitRole.Developers);
  const priorityErpStatus = usePriorityErpStatus();
  const showChangeStatusButton = isBillingProfileAdmin || !entity.active;

  useEffect(() => {
    if (dividerElement !== null) {
      const scrollListener = () => {
        dividerElement.style.opacity =
          document.scrollingElement && document.scrollingElement?.scrollTop > 98 ? "1" : "0";
      };
      window.addEventListener("scroll", scrollListener);

      return () => window.removeEventListener("scroll", scrollListener);
    }
  }, [dividerElement]);

  const theme = useTheme();
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  const jumpLinksVerticalMargin = lessThanMedium ? 1 : 2;
  const api = useApiContext();

  const onSubmitBillingProfileForm = async (editBillingProfileData: EditBillingProfileData) => {
    await editBillingProfile(editBillingProfileData);

    const customerId = entity.customer?.id;
    const entityId = entity.id;

    if (customerId && entityId) {
      syncEntityInvoiceAttributions({ api, customerId, entityId });
    }
  };

  if (priorityErpStatus.available === false) {
    return <PriorityMaintenance />;
  }

  if (error || entityFromPriority === null) {
    return (
      <Alert severity="error">
        <AlertTitle>An error occurred, please try again or contact support</AlertTitle>
        {error}
      </Alert>
    );
  }

  return (
    <Loader loading={!billingProfile || isLoadingSavePaymentMethods}>
      <DoitConsoleTitle pageName="Billing profiles" pageLevel1={billingProfile?.companyName} />
      {billingProfile && (
        <Box data-cy="edit-billing-profile-form">
          <Paper
            sx={{ position: "sticky", top: 64, left: 0, right: 0, zIndex: 2 }}
            elevation={0}
            id={billingProfileHeadingId}
          >
            <IconButton
              aria-label="Back"
              onClick={onClose}
              size="large"
              sx={{ position: "absolute", top: 40, left: 0 }}
            >
              <BackIcon color="primary" />
            </IconButton>
            <CenteredHeader>
              <Typography variant="h2" mt={1} mb={2}>
                Edit billing profile
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mt={2}
                mb={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body2">{entity.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {entity.priorityId}
                </Typography>
                {showChangeStatusButton && (
                  <ChangeBillingProfileStatusButton entity={entity} onStatusChanged={onClose} />
                )}
                {showDetachButton && <DetachBillingProfileButton entity={entity} />}
              </Stack>
            </CenteredHeader>
            <Divider ref={setDividerElement} sx={{ transition: "opacity .25s", opacity: 0 }} />
            <CenteredHeader>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2.5}
                mt={jumpLinksVerticalMargin}
                mb={jumpLinksVerticalMargin}
              >
                <Typography variant="subtitle2" sx={{ flexShrink: 0 }}>
                  Jump to:
                </Typography>
                <Grid container columnSpacing={{ xs: 1, sm: 2.5 }} columns={{ xs: 2, sm: 12 }}>
                  <AnchorLink id={ADDRESS_PANEL_ID}>Billing and company details</AnchorLink>
                  <AnchorLink id={CONTACT_PANEL_ID}>Contact details</AnchorLink>
                  <AnchorLink id={PAYMENT_PANEL_ID}>Payment method</AnchorLink>
                  <AnchorLink id={INVOICE_SETTINGS_PANEL_ID}>Invoice settings</AnchorLink>
                </Grid>
              </Stack>
            </CenteredHeader>
            <Divider />
          </Paper>
          <Box display="flex" justifyContent="center" pt={3}>
            <PageContainer>
              <BillingForm
                initialValues={billingProfile}
                onSubmit={onSubmitBillingProfileForm}
                onPristineStateChange={setIsPristine}
                inEditMode
                generalPanelChildren={
                  <CustomInput
                    label="Billed by"
                    helperText="This billing profile is billed by this priority company"
                    defaultValue={priorityCompanyTransform(entity.priorityCompany)}
                    fullWidth
                    maxLength={48}
                    disabled
                  />
                }
                paymentsPanelChildren={
                  <EditBillingProfileContextProvider>
                    <PaymentTermsField priorityCompany={entity.priorityCompany ?? ""} />
                    <PaymentMethodsPanel entity={entity} savedPaymentMethods={savedPaymentMethods} />
                  </EditBillingProfileContextProvider>
                }
                validationSchema={editBillingProfileFromValidationSchema}
              >
                <InvoiceSettingsPanel entity={entity} setIsInvalid={setIsInvalid} />
              </BillingForm>
              <Box m={3} />
            </PageContainer>
          </Box>
          <Paper sx={{ position: "sticky", bottom: 0, left: 0, right: 0, zIndex: 1 }} elevation={0}>
            <Divider />
            <PageContainer>
              <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1} py={2}>
                <Button color="primary" aria-label="cancel" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  form="billingForm"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isPristine || isInvalid}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </PageContainer>
          </Paper>
        </Box>
      )}
    </Loader>
  );
};
