import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  AppModel,
  CustomerModel,
  type PerkField,
  PerkModel,
  type PerkModule,
  perkPlatformList,
  perkPlatformListShortened,
  perkSolutionTypeList,
  type PerkType,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  type AutocompleteChangeReason,
  type AutocompleteValue,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { type AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";
import { type StorageReference } from "firebase/storage";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { string as YupString } from "yup";

import { type Customer } from "../../../../src/types/Customer";
import { PerkSolutionTypeText, PerkTypeText, PlatformText, SavePerkText } from "../../../assets/texts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { AlgoliaSearchClientContextProvider } from "../../../Context/algoliaContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import Editor from "../../../Support/Components/Editor/Editor";
import { useCustomerPerksBucket } from "../../../Support/Components/Editor/hooks";
import { type FirestoreTimestamp, firestoreTimestamp } from "../../../utils/firebase";
import { getPropsWrapper } from "../../../utils/muiUtils";
import { useSnackbar } from "../../Integrations/Slack/utils";
import { perkTypeByPageIdForNewPerk } from "../helpers";
import ModuleList from "../ModuleList";
import { DomainInput } from "./DomainInput";
import { usePerkFormValidator } from "./usePerkFormValidator";

const styles = {
  input: {
    maxWidth: 500,
  },
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: "background.default",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    marginX: "-16px",
  },
  footerActionButtons: {
    height: "68px",
    maxWidth: 800,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const defaultSectionTitles = {
  checklist: "Pricing",
  agenda: "Support",
  modules: "Features",
};

const incorrectUrlErrorText = "Incorrect URL";

const solutionTypesMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

export const tagDefaultValues: Record<"training" | "pro-serv", string[]> = {
  training: ["cre/training"],
  "pro-serv": ["cre/pro-serv"],
};

const buildFieldsDefaultValues = (type: PerkType, createMode: boolean, customer: Customer): PerkField => ({
  active: false,
  type,
  name: "",
  title: type === "isv-solution" && createMode ? customer.primaryDomain : "",
  shortDescription: "",
  description: "",
  platform: [],
  platformUrl: {},
  videoUrl: "",
  checklist: "",
  solutionType: [],
  deleted: false,
  zendeskTags: createMode && tagDefaultValues[type] ? tagDefaultValues[type] : [],
  acceleratorProgram: false,
});

const buildPerkDefaultValues = (type: PerkType, createMode: boolean, customer: Customer) => ({
  agenda: "",
  modules: [{ title: "", description: "" }],
  fields: buildFieldsDefaultValues(type, createMode, customer),
});

export const PerkForm = ({
  bucketRef,
  isLoadingBucket,
}: {
  bucketRef: StorageReference | null;
  isLoadingBucket: boolean;
}) => {
  const history = useHistory();
  const { customerId, perkId } = useParams<{ customerId: string; perkId: string }>();
  const pathname = history.location.pathname?.replace(/\/+$/, "");
  const perkListPath = pathname.substring(0, (perkId ? pathname.indexOf("edit") : pathname.indexOf("create")) - 1);
  const pageId = perkListPath.substring(perkListPath.lastIndexOf("/") + 1);
  const perkType = perkTypeByPageIdForNewPerk?.[pageId];
  const perkTypeName = PerkTypeText[perkType];
  const allowTagging = useMemo(() => ["pro-serv", "training"].includes(perkType), [perkType]); // 20404 accelerator?
  const { customer } = useCustomerContext();
  const perkDefaultValues = useMemo(
    () => buildPerkDefaultValues(perkType, !perkId, customer),
    [perkId, perkType, customer]
  );
  const [agenda, setAgenda] = useState<string>(perkDefaultValues.agenda);
  const [modules, setModules] = useState<PerkModule[]>(cloneDeep(perkDefaultValues.modules));
  const [fields, setFields] = useState<PerkField>(cloneDeep({ ...perkDefaultValues.fields }));
  const [logoUrl, setLogoUrl] = useState("");
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDoitCustomer, setIsDoitCustomer] = useState(true);
  const [isvDomainCustomerId, setIsvDomainCustomerId] = useState("");
  const [isAcceleratorProgram, setAcceleratorProgram] = useState(false);
  const snackbar = useSnackbar();

  const [currentPerk, setCurrentPerk] = useState({
    agenda: perkDefaultValues.agenda,
    modules: cloneDeep(perkDefaultValues.modules),
    fields: cloneDeep({ ...perkDefaultValues.fields }),
  });
  const [firstTimePublished, setFirstTimePublished] = useState<FirestoreTimestamp | null>();

  const [sectionTitles, setSectionTitles] = useState<{
    checklist: string;
    agenda: string;
    modules: string;
  }>(defaultSectionTitles);
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();

  const getPerksConfig = useCallback(async () => {
    const perksConfig = await getCollection(AppModel).doc("perks").get();
    const configs = perksConfig.data()?.perksType;
    const selectedPerkConfig = configs?.find((c) => c.id === perkType);

    if (selectedPerkConfig) {
      Object.entries(defaultSectionTitles).forEach(([key, title]) => {
        if (!selectedPerkConfig[key]) {
          selectedPerkConfig[key] = title;
        }
      });
      setSectionTitles(selectedPerkConfig);
    }
  }, [perkType]);

  useEffect(() => {
    if (!fields.deleted && !isEqual({ modules, agenda, fields }, currentPerk)) {
      activatePendingPrompt();
    } else {
      clearPendingPrompt();
    }
  }, [activatePendingPrompt, clearPendingPrompt, agenda, fields, modules, currentPerk]);

  const [isFormValid] = usePerkFormValidator({ agenda, fields, isDoitCustomer, logoUrl, modules });

  useEffect(() => {
    let isMounted = true;
    const fetchPerk = async () => {
      const perk = (await getCollection(PerkModel).doc(perkId).get()).asModelData();

      if (!perk) {
        history.push(perkListPath);
      }

      if (!isMounted) {
        // preventing updating state on unmounted component
        return;
      }

      const { modules, agenda, fields, timePublished } = perk ?? {};
      setFirstTimePublished(timePublished);

      const existentPerk = {
        modules: [] as PerkModule[],
        agenda: agenda ?? perkDefaultValues.agenda,
        fields: perkDefaultValues.fields,
      };

      if (modules?.length) {
        setModules(modules);
        modules.forEach((module) => existentPerk.modules?.push(module));
      } else {
        existentPerk.modules.push(...perkDefaultValues.modules);
      }

      if (agenda) {
        setAgenda(agenda);
      }

      const formattedFields = Object.entries(perkDefaultValues.fields).reduce(
        (next, [key, value]) => ({ ...next, [key]: fields?.[key] ?? value }),
        {}
      ) as PerkField;

      // NOTE - does this whole block go away in 20404?
      if (["promotion", "workshop", "accelerator"].includes(formattedFields.type)) {
        formattedFields.type = "training"; // support legacy type
      }

      existentPerk.fields = formattedFields;
      setFields(cloneDeep(formattedFields));

      setCurrentPerk(existentPerk);

      if (perk?.fields.title) {
        const querySnapshot = await getCollection(CustomerModel).where("primaryDomain", "==", perk?.fields.title).get();
        setIsvDomainCustomerId(querySnapshot.docs[0]?.id ?? "");
      }

      setLogoUrl(perk?.enrichment?.logoUrl ?? "");
      setIsDoitCustomer(!!perk?.enrichment?.providerIsDoitCustomer);
      setAcceleratorProgram(!!perk?.fields.acceleratorProgram);
    };

    if (perkId) {
      fetchPerk();
    } else {
      setLogoUrl(customer.enrichment.logo ?? "");
      if (perkType === "isv-solution") {
        setIsvDomainCustomerId(customer.id);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    perkType,
    customerId,
    history,
    perkId,
    customer,
    perkListPath,
    perkDefaultValues.agenda,
    perkDefaultValues.fields,
    perkDefaultValues.modules,
  ]);

  useEffect(() => {
    getPerksConfig();
  }, [getPerksConfig]);

  const handleChange = useCallback((event) => {
    const name = event.target?.name;
    const inputValue = event.target?.value ?? event;

    setFields((f) => ({
      ...f,
      [name]: inputValue,
    }));
  }, []);

  const handleMarketplaceSelection = useCallback(
    (event, platform) => {
      const checked = event.target.checked;
      const newFields = { ...fields };

      if (checked) {
        newFields.platform.push(platform);
        allowTagging && newFields.zendeskTags.push(perkPlatformListShortened[platform]);
      } else {
        newFields.platform = newFields.platform.filter((x) => x !== platform);
        allowTagging &&
          (newFields.zendeskTags = newFields.zendeskTags.filter((x) => x !== perkPlatformListShortened[platform]));
        delete newFields.platformUrl?.[platform];
      }

      setFields(newFields);
    },
    [allowTagging, fields]
  );

  const handleMarketplaceUrlChange = useCallback((event, platform) => {
    const inputValue = event.target?.value;

    setFields((f) => {
      const tmp = { ...f };
      if (tmp.platformUrl) {
        if (inputValue) {
          tmp.platformUrl[platform] = inputValue;
        } else {
          delete tmp.platformUrl[platform];
        }
      } else {
        tmp.platformUrl = { [platform]: inputValue };
      }
      return tmp;
    });
  }, []);

  const removeEmptyModules = useCallback(
    (): PerkModule[] => modules.filter((m) => m.title || m.description),
    [modules]
  );

  const createPerk = useCallback(
    async (active) => {
      const newModules = removeEmptyModules();
      const timeNow = firestoreTimestamp();
      const perk = {
        agenda: agenda ?? "",
        enrichment: { logoUrl: logoUrl ?? "", providerIsDoitCustomer: isDoitCustomer },
        fields: { ...fields, active, acceleratorProgram: isAcceleratorProgram },
        modules: newModules,
        timeModified: timeNow,
        timePublished: (firstTimePublished ?? active) ? timeNow : null,
      } as const;

      if (!perkId) {
        await getCollection(PerkModel).add({ ...perk, timeCreated: timeNow });
        if (active) {
          snackbar.onOpen({
            message: SavePerkText.PUBLISH_SUCCESS(perkTypeName),
            variant: "success",
          });
        } else {
          snackbar.onOpen({
            message: SavePerkText.SAVE_DRAFT_SUCCESS(perkTypeName),
            variant: "success",
          });
        }
      } else {
        await getCollection(PerkModel).doc(perkId).update(perk);
        snackbar.onOpen({
          message: SavePerkText.UPDATE_SUCCESS(perkTypeName),
          variant: "success",
        });
      }
      clearPendingPrompt();
      history.push(perkListPath);
    },
    [
      removeEmptyModules,
      fields,
      agenda,
      logoUrl,
      isDoitCustomer,
      firstTimePublished,
      perkId,
      clearPendingPrompt,
      history,
      perkListPath,
      snackbar,
      perkTypeName,
      isAcceleratorProgram,
    ]
  );

  const handleDeletePerk = useCallback(async () => {
    await getCollection(PerkModel).doc(perkId).update("fields.deleted", true);
    setDeleteDialogOpen(false);
    setFields({ ...fields, deleted: true });
    history.push(perkListPath);
  }, [fields, history, perkId, perkListPath]);

  const handleEditorChange = (html, delta, source, editor, setter: () => void) => {
    // Blocking first api change
    if (source === "api") {
      return;
    }
    setter();
  };

  const handleIsDoitCustomerChange = useCallback(
    (value) => {
      setIsDoitCustomer(value);
      if (value) {
        setIsvDomainCustomerId(customer.id);
        setFields({ ...fields, title: customer.primaryDomain });
        setLogoUrl(customer.enrichment.logo ?? "");
      }
    },
    [customer.enrichment.logo, customer.id, customer.primaryDomain, fields]
  );

  const handleAcceleratorProgramChange = useCallback(
    (value) => {
      setAcceleratorProgram(value);
      if (value) {
        setFields({ ...fields, acceleratorProgram: isAcceleratorProgram });
      }
    },
    [fields, isAcceleratorProgram]
  );

  const handleTagsChange = useCallback<
    (
      event: React.SyntheticEvent,
      value: AutocompleteValue<string, true, false, true>,
      reason: AutocompleteChangeReason
    ) => void
  >(
    (_event, newTags) => {
      const zendeskTags = newTags.map((tag) => tag.trim().toLowerCase());
      setFields({ ...fields, zendeskTags });
    },
    [fields]
  );

  const wrapGetProps: (
    func: AutocompleteRenderGetTagProps,
    index: number
  ) => ReturnType<AutocompleteRenderGetTagProps> = useCallback(getPropsWrapper, []);

  return (
    <>
      <Stack sx={{ maxWidth: 800, marginX: "auto" }} data-cy="create-update-perk">
        {isDeleteDialogOpen && (
          <DeleteDialog
            open={true}
            title={`Delete ${perkTypeName}?`}
            message={`This ${perkTypeName} will be permanently deleted and won't be able to be recovered.`}
            onDelete={handleDeletePerk}
            onClose={() => setDeleteDialogOpen(false)}
          />
        )}
        <Stack direction="row" mt={6} mb={4} alignItems="center" gap={3}>
          <Typography variant="h1">
            {perkId ? "Edit" : "Create new"} {perkTypeName}
          </Typography>
          {!!perkId && (
            <>
              {!fields.active && (
                <Chip label="Draft" data-cy="draft-chip" sx={{ background: "#D51A4E", color: "white" }} />
              )}
              {fields.active && (
                <Chip label="Published" data-cy="published-chip" sx={{ background: "#4CAF50", color: "white" }} />
              )}

              <Button
                variant="text"
                data-cy="delete-perk-button"
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
                <Typography>Delete {perkTypeName}</Typography>
              </Button>
            </>
          )}
        </Stack>

        <Typography variant="h4" mb={3}>
          General
        </Typography>

        <TextField
          label="Title"
          sx={styles.input}
          name="name"
          value={fields.name}
          onChange={handleChange}
          helperText={`${fields.name?.length ?? 0}/60`}
          FormHelperTextProps={{ sx: { textAlign: "right", mb: "1rem" } }}
          inputProps={{ maxLength: 60, "data-cy": "name-textfield" }}
          required
        />
        <TextField
          label="Short description"
          sx={styles.input}
          name="shortDescription"
          value={fields.shortDescription}
          onChange={handleChange}
          helperText={`${fields.shortDescription?.length ?? 0}/60`}
          FormHelperTextProps={{ style: { textAlign: "right" } }}
          inputProps={{ maxLength: 60, "data-cy": "short-description-textfield" }}
          required
        />

        {fields.type === "isv-solution" && (
          <Stack mt={3} mb={1}>
            <Typography variant="subtitle1" fontWeight={500}>
              Is the provider a DoiT customer?
            </Typography>
            <RadioGroup
              sx={{ mb: 3 }}
              value={isDoitCustomer}
              onChange={(_, value) => handleIsDoitCustomerChange(value === "true")}
              name="is-doit-customer-radio-group"
            >
              <FormControlLabel value={true} control={<Radio data-cy="is-doit-customer-radio" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio data-cy="is-not-doit-customer-radio" />} label="No" />
            </RadioGroup>

            <DomainInput
              isDoitCustomer={isDoitCustomer}
              isvDomainCustomerId={isvDomainCustomerId}
              title={fields.title}
              handleDomainChange={(value) =>
                setFields((f) => ({
                  ...f,
                  title: value,
                }))
              }
              logoURL={logoUrl}
              handleLogoURLChange={(value) => setLogoUrl(value)}
            />
          </Stack>
        )}

        {!isDoitCustomer && (
          <TextField
            label="Customer logo URL"
            sx={{ ...styles.input, mt: 2, mb: 2 }}
            value={logoUrl}
            onChange={(e) => setLogoUrl(e.target.value)}
            data-cy="isv-non-customer-logo-textfield"
            required
            error={!YupString().url().isValidSync(logoUrl)}
            helperText={!YupString().url().isValidSync(logoUrl) && "Incorrect URL"}
          />
        )}

        {allowTagging && (
          <Autocomplete
            multiple
            freeSolo
            filterSelectedOptions
            autoSelect
            options={tagDefaultValues[perkType]} // populate list with default tags so that people can put them back
            value={fields.zendeskTags}
            onChange={handleTagsChange}
            renderTags={(value, getTagProps) =>
              value.map((tag, index) => (
                <Box key={tag}>
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label={tag}
                    data-cy={`zendesk-tag-${tag}`}
                    {...wrapGetProps(getTagProps, index)}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ ...styles.input, py: "8.5px", minHeight: "1.5em" }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                label="Zendesk tags"
                multiline
                variant="outlined"
                data-cy="zendesk-tags-textfield"
              />
            )}
          />
        )}

        {fields.type === "pro-serv" && (
          <Stack mt={3}>
            <Typography variant="subtitle1" fontWeight={500}>
              Accelerator program
            </Typography>
            <RadioGroup
              sx={{ mb: 3 }}
              value={isAcceleratorProgram}
              onChange={(_, value) => handleAcceleratorProgramChange(value === "true")}
              name="acceleratorProgram"
            >
              <FormControlLabel value={false} control={<Radio data-cy="no-accelerator-program-radio" />} label="No" />
              <FormControlLabel value={true} control={<Radio data-cy="yes-accelerator-program-radio" />} label="Yes" />
            </RadioGroup>
          </Stack>
        )}

        <FormControl error={false} sx={{ mt: 3, mb: 4 }}>
          <Typography color="text.secondary">Cloud providers</Typography>
          <FormGroup>
            {perkPlatformList.map((p) => (
              <FormControlLabel
                key={p}
                control={
                  <Checkbox
                    onChange={(e) => handleMarketplaceSelection(e, p)}
                    checked={fields.platform?.includes(p)}
                    data-cy={`${p}-checkbox`}
                  />
                }
                label={PlatformText[p]}
              />
            ))}
          </FormGroup>
        </FormControl>

        {fields.type === "isv-solution" &&
          fields.platform?.map(
            (p) =>
              (p === "amazon-web-services" || p === "google-cloud") && (
                <TextField
                  key={p}
                  label={`${PlatformText[p]} marketplace URL`}
                  sx={{ ...styles.input, mb: 3 }}
                  value={fields.platformUrl?.[p] ?? ""}
                  onChange={(e) => handleMarketplaceUrlChange(e, p)}
                  inputProps={{ "data-cy": `${p}-url-textfield` }}
                  error={!YupString().url().isValidSync(fields.platformUrl?.[p])}
                  helperText={!YupString().url().isValidSync(fields.platformUrl?.[p]) && incorrectUrlErrorText}
                />
              )
          )}

        {fields.type === "isv-solution" && (
          <FormControl fullWidth sx={{ ...styles.input, mb: "20px" }} size="small" variant="outlined" required>
            <InputLabel id="type_id" shrink>
              Solution type(s)
            </InputLabel>
            <Select
              label="Solution type(s)"
              labelId="solution_type_id"
              id="solution-type-select"
              name="solutionType"
              data-cy="solution-type-select"
              value={fields.solutionType}
              onChange={handleChange}
              renderValue={(selected) => selected.map((x) => PerkSolutionTypeText[x]).join(", ")}
              MenuProps={solutionTypesMenuProps}
              notched
              multiple
            >
              {perkSolutionTypeList.map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={fields.solutionType && fields.solutionType.indexOf(type) > -1} />
                  <ListItemText primary={PerkSolutionTypeText[type]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="YouTube video URL"
          sx={styles.input}
          name="videoUrl"
          value={fields.videoUrl}
          onChange={handleChange}
          inputProps={{ "data-cy": "videourl-textfield" }}
          error={!YupString().url().isValidSync(fields.videoUrl)}
          helperText={!YupString().url().isValidSync(fields.videoUrl) && incorrectUrlErrorText}
        />

        <Typography variant="h4" mt={4} mb={2} data-cy="description-title">
          Overview
        </Typography>
        <Editor
          bucketRef={bucketRef}
          isLoadingBucket={isLoadingBucket}
          resourceName={`perks/${perkId || "new "}/description`}
          value={fields.description}
          onChange={(html, delta, source, editor) =>
            handleEditorChange(html, delta, source, editor, () =>
              setFields({
                ...fields,
                description: html,
              })
            )
          }
        />

        <Typography variant="h4" mt={4} mb={2} data-cy="checklist-title">
          {sectionTitles.checklist}
        </Typography>
        <Editor
          bucketRef={bucketRef}
          isLoadingBucket={isLoadingBucket}
          resourceName={`perks/${perkId || "new "}/checklist`}
          value={fields.checklist}
          onChange={(html, delta, source, editor) =>
            handleEditorChange(html, delta, source, editor, () =>
              setFields({
                ...fields,
                checklist: html,
              })
            )
          }
        />

        <Typography variant="h4" mt={4} mb={2} data-cy="agenda-title">
          {sectionTitles.agenda}
        </Typography>
        <Editor
          bucketRef={bucketRef}
          isLoadingBucket={isLoadingBucket}
          resourceName={`perks/${perkId || "new "}/agenda`}
          value={agenda}
          onChange={(html, delta, source, editor) =>
            handleEditorChange(html, delta, source, editor, () => setAgenda(html))
          }
        />

        <Typography variant="h4" mt={4} mb={3} data-cy="modules-title">
          {sectionTitles.modules}
        </Typography>
        <ModuleList
          modulesData={modules}
          onUpdate={setModules}
          sectionTitle={sectionTitles.modules}
          perkId={perkId}
          bucketRef={bucketRef}
          isLoadingBucket={isLoadingBucket}
        />
      </Stack>
      <Box sx={styles.footer}>
        <Box sx={styles.footerActionButtons}>
          <Button data-cy="exit-page-button" onClick={() => history.push(perkListPath)}>
            Cancel
          </Button>
          <Stack direction="row" gap={2}>
            <Button variant="outlined" onClick={() => createPerk(false)}>
              Save as draft
            </Button>
            <Button
              variant="contained"
              onClick={() => createPerk(true)}
              data-cy="publish-button"
              disabled={!isFormValid}
            >
              Publish
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default function PerkFormComponent() {
  const { bucketRef, isLoadingBucket } = useCustomerPerksBucket();
  return (
    <AlgoliaSearchClientContextProvider>
      <PerkForm bucketRef={bucketRef} isLoadingBucket={isLoadingBucket} />
    </AlgoliaSearchClientContextProvider>
  );
}
