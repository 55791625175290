import { type ChangeEvent, useCallback, useState } from "react";

import {
  type AccessLevel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  type CloudConnectAmazonWebServices,
  type CloudConnectCategory,
  type CloudConnectGoogleCloud,
  CustomerModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

import { useApiContext } from "../../api/context";
import { CloudConnectCardText } from "../../assets/texts";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type CloudConnectData } from "../../Pages/Settings/GCP/CloudConnector";
import PlatformConfigurationDialog from "../../Pages/Settings/GCP/PlatformConfigurationDialog";
import { consoleErrorWithSentry } from "../../utils";
import mixpanel from "../../utils/mixpanel";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    paddingBottom: 0,
    height: 57,
  },
  cardContent: {
    paddingBottom: 0,
    // height: 201,
  },
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
    visibility: "hidden",
  },
  logo: {
    width: 16,
    height: 16,
  },
}));

type PropsGoogle = {
  data: CloudConnectData<CloudConnectGoogleCloud>[];
  type: "google-cloud";
};

type PropsAWS = {
  data: CloudConnectData<CloudConnectAmazonWebServices>[];
  type: "amazon-web-services";
};

type Props = (PropsGoogle | PropsAWS) & {
  raised?: boolean;
  settings: boolean;
  closeDialog?: (isUpdate?: boolean) => void;
  isDialogOpen?: boolean;
  selectedCategories?: CloudConnectCategory[];
  onDeleteServiceAccount?: () => void;
  onAddServiceAccount?: () => void;
  selectedOrg?: number;
  handleChangeOrg?: (e: ChangeEvent<HTMLInputElement>) => void;
  accessLevel?: AccessLevel;
  onComplete?: (load?: boolean) => void;
  loading?: boolean;
};
const CloudConnectCard = ({
  data,
  raised,
  settings,
  type,
  closeDialog,
  isDialogOpen,
  selectedCategories,
  onDeleteServiceAccount,
  onAddServiceAccount,
  selectedOrg,
  handleChangeOrg,
  accessLevel,
  onComplete = () => {},
  loading = false,
}: Props) => {
  const classes = useStyles();
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const [openConfiguration, setOpenConfiguration] = useState({
    gcp: false,
    aws: false,
    azure: false,
  });

  const deleteServiceAccount = useCallback(
    async (service: CloudConnectData) => {
      if (type === AssetTypeGoogleCloud) {
        try {
          await api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/cloudconnect/google-cloud/remove`,
            data: { client_id: service.data.clientId },
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (error) {
          consoleErrorWithSentry(error);
        }

        onDeleteServiceAccount?.();
      }
      await getCollection(CustomerModel).doc(customer.id).collection("cloudConnect").doc(service.id).delete();

      switch (service.data.cloudPlatform) {
        case AssetTypeGoogleCloud:
          mixpanel.track("credentials.google-cloud.remove");
          break;
        case AssetTypeAmazonWebServices:
          mixpanel.track("credentials.amazon-web-services.remove");
          break;
        default:
          break;
      }
    },
    [api, customer.id, onDeleteServiceAccount, type]
  );

  const closeGcpConnectDialog = useCallback(() => {
    setOpenConfiguration({ ...openConfiguration, gcp: false });
    closeDialog?.();
  }, [closeDialog, openConfiguration]);

  const closeAwsConnectDialog = useCallback(() => {
    setOpenConfiguration({ ...openConfiguration, aws: false });
    closeDialog?.();
  }, [closeDialog, openConfiguration]);

  const addServiceButton = useCallback(
    () => (
      <Tooltip title={loading ? CloudConnectCardText.disableAddServiceTooltip : ""} placement="top" arrow>
        <span>
          <IconButton style={{ margin: 6 }} size="small" onClick={onAddServiceAccount} disabled={loading}>
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
    ),
    [loading, onAddServiceAccount]
  );

  return (
    <Card raised={raised} className={classes.root}>
      {type === AssetTypeGoogleCloud && !settings && (
        <CardHeader
          className={classes.cardHeader}
          title="Configured accounts"
          titleTypographyProps={{ variant: "h1" }}
          subheader="Manage service accounts"
          subheaderTypographyProps={{ variant: "body2" }}
          action={addServiceButton()}
        />
      )}
      <CardContent
        style={{ padding: type === AssetTypeGoogleCloud && settings ? 0 : "auto" }}
        className={classes.cardContent}
      >
        {type === AssetTypeGoogleCloud && (
          <PlatformConfigurationDialog
            title=""
            awsAccountId={undefined}
            subTitle=""
            onClose={closeGcpConnectDialog}
            type="google-cloud"
            open={isDialogOpen}
            customerID={customer.id}
            data={data.length > 0 ? data.filter((d) => d.data.cloudPlatform === AssetTypeGoogleCloud) : [{}]}
            onDelete={deleteServiceAccount}
            selectedOrg={selectedOrg}
            handleChangeOrg={handleChangeOrg}
            settings={settings}
            selectedCategories={selectedCategories}
            accessLevel={accessLevel}
            onComplete={onComplete}
          />
        )}
        {type === AssetTypeAmazonWebServices && (
          <PlatformConfigurationDialog
            title="Connect Amazon Web Services"
            subTitle=""
            onClose={closeAwsConnectDialog}
            type={AssetTypeAmazonWebServices}
            open={openConfiguration.aws}
            customerID={customer.id}
            data={data.length > 0 ? data.filter((d) => d.data.cloudPlatform === AssetTypeAmazonWebServices) : [{}]}
            onDelete={deleteServiceAccount}
            awsAccountId="068664126052"
            selectedCategories={selectedCategories}
            selectedOrg={0}
            settings={false}
            handleChangeOrg={undefined}
            accessLevel={undefined}
            onComplete={undefined}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default CloudConnectCard;
