import { CloudFlowNodeType, type CustomerModel, NODE_STATUS, type NodeModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  type QueryDocumentSnapshotModel,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import { type Edge, type Node } from "@xyflow/react";

import { type UserWithRole } from "../../../../types/User";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "../utils/edgeUtils";

export type CloudFlowNode = {
  id: string;
  data: NodeModel;
};

export const transformNodeData = (
  data: WithFirebaseModel<NodeModel>,
  snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>,
  users: UserWithRole[] | undefined,
  customerRef: FirebaseModelReference<CustomerModel>
) => {
  const owner = users?.find((user) => user.id === data.createdBy.id)?.email || "";
  return {
    data: { ...data, owner, customer: customerRef },
    id: snapshot.id,
    ref: snapshot.ref,
  };
};

export const isLeafNode = (node: CloudFlowNode): boolean =>
  !node.data.transitions || node.data.transitions.length === 0;

export const mapLeafNodesWithGhosts = (cloudflowNodes: CloudFlowNode[] = []): CloudFlowNode[] => {
  const nodesWithGhosts: CloudFlowNode[] = [...cloudflowNodes];

  cloudflowNodes.forEach((node: CloudFlowNode) => {
    if (isLeafNode(node)) {
      const ghostNodeId = `${node.id}-ghost`;
      const ghostNode = {
        data: {
          type: CloudFlowNodeType.GHOST,
        },
        id: ghostNodeId,
      };
      nodesWithGhosts.push(ghostNode as unknown as CloudFlowNode);
      node.data.transitions = [
        ...(node.data.transitions || []),
        {
          targetNodeId: ghostNode.id,
          label: "ghost",
        },
      ];
    }
  });

  return nodesWithGhosts;
};

// TODO: Implement the mapping more accurately according to the node types
export const mapCloudFlowNodes = (cloudflowNodes?: CloudFlowNode[]): Node[] =>
  cloudflowNodes?.map((node: CloudFlowNode) => ({
    id: node.id,
    type: node.data.type || CloudFlowNodeType.ACTION,
    position: node.data.display?.position || { x: 0, y: 0 },
    data: {
      name: node.data.name,
      position: node.data.display?.position || { x: 0, y: 0 },
      status: node.data.status || NODE_STATUS.PENDING,
      statusMessage: node.data.statusMessage,
    },
    parameters:
      node.data.type === CloudFlowNodeType.CONDITION
        ? { conditions: node.data.parameters || null }
        : node.data.parameters || null,
  })) || [];

// Maps cloudflow transitions to edges
export const mapTransitionsToEdges = (cloudflowNodes?: CloudFlowNode[]) =>
  cloudflowNodes?.reduce((edges: Edge[], node: CloudFlowNode) => {
    if (node.data.transitions) {
      const nodeEdges = node.data.transitions.map((transition: any) => {
        if (node.data.type === CloudFlowNodeType.CONDITION) {
          return transition.label === "True"
            ? createTruePathEdge(node.id, transition.targetNodeId)
            : createFalsePathEdge(node.id, transition.targetNodeId);
        }
        return createEdge(node.id, transition.targetNodeId, transition.label);
      });
      return edges.concat(nodeEdges);
    }
    return edges;
  }, []);
