import { Grid, Typography } from "@mui/material";

export const LinkedThreadField = ({ label, element }) => (
  <Grid container spacing={2} sx={{ alignItems: "center" }}>
    <Grid item xs={6} md={6}>
      <Typography variant="body2" sx={{ fontSize: 12 }}>
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
      {element}
    </Grid>
  </Grid>
);
