import { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Collapse, Typography } from "@mui/material";

import { alertTexts } from "../../assets/texts";
import { Alert } from "./Alert";

export type LearnMoreAlertProps = {
  text: string;
  url?: string;
  btnText?: string;
  onClose?: () => void;
  onClickInner?: () => void;
  innerBtnText?: string;
};

export const LearnMoreAlert = ({ text, url, onClose, btnText, onClickInner, innerBtnText }: LearnMoreAlertProps) => {
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    if (onClose && hidden) {
      onClose();
    }
  }, [hidden, onClose]);

  return (
    <Collapse in={!hidden}>
      <Alert
        data-testid="learn-more-alert"
        severity="info"
        onClose={() => setHidden(true)}
        sx={{ ".MuiAlert-message": { display: "flex" } }}
        icon={<InfoIcon fontSize="inherit" />}
        buttonProps={{
          text: btnText ?? alertTexts.LEARN_MORE,
          href: url,
          startIcon: url ? <OpenNewIcon /> : null,
          key: "info",
          color: "inherit",
          size: "small",
          target: "_blank",
          rel: "noopener noreferrer",
          sx: { pl: "10px" },
        }}
      >
        <Typography color="inherit" variant="body2">
          {text}
        </Typography>
        {!!innerBtnText && (
          <Typography
            component={Button}
            color="inherit"
            variant="body2"
            sx={{ ml: 2, p: 0, fontWeight: 500 }}
            onClick={onClickInner}
          >
            {innerBtnText}
          </Typography>
        )}
      </Alert>
    </Collapse>
  );
};
