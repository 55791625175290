import { Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";

import { FocusedOn } from "./RampCellDialog";

type RampCellDialogContentProps = {
  onInputChange: (e) => void;
  isCheckboxTicked: boolean;
  focusOn: FocusedOn | null;
  changeFocusTo: (focusTo: FocusedOn | null) => void;
  inputValue: string;
  isDialogOpen: boolean;
  onClose: () => void;
  classes: Record<string, string>;
  onCheckboxClick: (e) => void;
  onGrowthPerMonthChange: (percent: number) => void;
  inputError: string | null;
  inputErrorString: {
    DIGITS: string;
    RANGE: string;
  };
  onCBClick: (e) => void;
  closeDialogWithoutSaving: () => void;
  onDialogClose: () => void;
};

const RampCellDialogContent = (props: RampCellDialogContentProps) => {
  const {
    onInputChange,
    isCheckboxTicked,
    changeFocusTo,
    inputValue,
    classes,
    inputError,
    inputErrorString,
    closeDialogWithoutSaving,
    onCBClick,
    focusOn,
    onDialogClose,
  } = props;

  const percentageInput = (
    <Grid item xs={11}>
      <TextField
        onChange={onInputChange}
        margin="dense"
        id="name"
        name="gpm"
        label="Ramp growth per month"
        type="text"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        data-testid="ramp-cell-dialog-percent-input"
        aria-labelledby="ramp cell dialog percent input"
        onClick={() => !isCheckboxTicked && changeFocusTo(FocusedOn.TEXT)}
        disabled={isCheckboxTicked}
        value={inputValue}
      />
      <div
        className={classes.errorText}
        data-testid="ramp-cell-dialog-error-message"
        aria-labelledby="ramp cell dialog error message"
      >
        {inputError !== null && <Typography variant="subtitle1">{inputErrorString[inputError]}</Typography>}
      </div>
    </Grid>
  );

  const checkBoxInput = (
    <Grid item xs={12} style={{ marginTop: 10 }} onClick={() => changeFocusTo(FocusedOn.CHECKBOX)}>
      <Checkbox
        onClick={onCBClick}
        inputProps={{
          "aria-label": "ramp cell dialog checkbox",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "data-testid": "ramp-cell-dialog-checkbox",
        }}
        disabled={focusOn === FocusedOn.TEXT}
      />
      <Typography color={focusOn === FocusedOn.TEXT ? "textSecondary" : "textPrimary"} display="inline">
        Set current month as baseline
      </Typography>
      <Divider />
    </Grid>
  );

  const dialogBtn = (
    <Grid container className={classes.cellDialogFooter}>
      <DialogActions data-testid="ramp-cell-dialog-footer">
        <Button onClick={closeDialogWithoutSaving} color="primary" data-testid="ramp-cell-dialog-close-btn">
          Cancel
        </Button>
        <Button onClick={onDialogClose} color="primary" variant="contained" disabled={!isCheckboxTicked && !inputValue}>
          Set
        </Button>
      </DialogActions>
    </Grid>
  );

  return (
    <Grid container>
      {percentageInput}
      {checkBoxInput}
      {dialogBtn}
    </Grid>
  );
};

export default RampCellDialogContent;
