import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Alert, Link as MUILink } from "@mui/material";
import filter from "lodash/filter";

import { useCloudConnectContext } from "../../../Context/customer/CloudCOnnectContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { CategoryStatus } from "../../../Pages/Settings/GCP/GKEConfig/enums";

const MissingPermissionsBanner = () => {
  const { customer } = useCustomerContext();
  const cloudConnect = useCloudConnectContext();
  const [showBanner, setShowBanner] = useState(false);
  const url = `/customers/${customer.id}/settings/gcp`;

  useEffect(() => {
    const gcpServices = filter(cloudConnect, { cloudPlatform: "google-cloud" });
    if (gcpServices.length > 0) {
      const gcpCloudConnection = gcpServices[0];
      const { categoriesStatus } = gcpCloudConnection;

      if (categoriesStatus && "bigquery-finops-editions" in categoriesStatus) {
        setShowBanner(categoriesStatus["bigquery-finops-editions"] !== CategoryStatus.Healthy);
      }
    }
  }, [cloudConnect]);

  if (!showBanner) {
    return null;
  }

  return (
    <Alert severity="error">
      Your Google Cloud service account is missing permissions.{" "}
      <MUILink to={url} component={Link} underline="hover">
        Click here
      </MUILink>{" "}
      to see details of BQ Lens Editions configuration.
    </Alert>
  );
};

export default MissingPermissionsBanner;
