import { type JSX, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";

import BigQueryLensAlert from "./BigQueryLensAlert";

type Props = {
  widgets: JSX.Element;
  noBQUsage: boolean;
};

const BigQueryLensGuard = ({ widgets }: Props) => {
  const [hideWidgets, setHideWidgets] = useState(false);
  const hideWidgetsDisplay = useCallback(() => setHideWidgets(true), []);
  const showWidgetsDisplay = useCallback(() => setHideWidgets(false), []);

  return (
    <>
      <Grid item xs={12} ml={-1} mb={1}>
        <BigQueryLensAlert hideWidgetsDisplay={hideWidgetsDisplay} showWidgetsDisplay={showWidgetsDisplay} />
      </Grid>
      {!hideWidgets && widgets}
    </>
  );
};
export default BigQueryLensGuard;
