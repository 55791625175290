import { collection } from "@doitintl/models-types";

@collection("cre")
export class CreModel {
  avatar!: string;

  email!: string;

  is_busy!: boolean;

  is_focus_time!: boolean;

  is_on_vacation!: boolean;

  is_out_of_office!: boolean;

  is_working_hours!: boolean;

  name!: string;

  primary_skills!: string[];

  secondary_skills!: string[];

  slack!: string;

  timezone!: string;

  title!: string;

  zendesk_id!: string;
}
