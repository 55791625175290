import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import { Box, Button, CardHeader, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { navigationCategories } from "../../Navigation/config/navigationCategory";
import { type PerksWData } from "../../types";
import { perkCreateTitleByPageId } from "./helpers";
import { usePerks } from "./hooks";
import PerkCard from "./PerkCard";
import { PerkFilter } from "./PerkFilter";

const Perks = () => {
  const history = useHistory();
  const isPerksAdmin = useDoitRoleCheck(DoitRole.PerksAdmin);
  const [filteredPerks, setFilteredPerks] = useState<PerksWData[]>([]);
  const pathname = history.location.pathname?.replace(/\/+$/, "");
  const pageId = pathname.substring(pathname.lastIndexOf("/") + 1);
  const { t } = useTranslation("navigation");
  const catTitle = navigationCategories(t)
    .find((cat) => cat.displayName === "Services")
    ?.items.find((item) => item.pageId === pageId)?.displayName;
  const perks = usePerks(pageId);

  const openCreatePerk = () => {
    history.push(`${history.location.pathname?.replace(/\/+$/, "")}/create`);
  };

  return (
    <Box sx={(theme) => ({ padding: theme.spacing(5, 12) })}>
      <Grid item>
        <CardHeader
          title={catTitle}
          subheader={
            pageId === "isv-solutions" ? "Enjoy exclusive offers from other members of the DoiT community" : ""
          }
          titleTypographyProps={{ variant: "h1", paddingBottom: 1 }}
          action={
            isPerksAdmin && (
              <Button onClick={openCreatePerk} color="primary" variant="contained" sx={{ marginTop: 3 }}>
                Create new {perkCreateTitleByPageId[pageId]}
              </Button>
            )
          }
          sx={{ padding: "inherit" }}
        />
      </Grid>
      <br />
      <Stack direction="row" spacing={2}>
        <PerkFilter perks={perks} setFilteredPerks={setFilteredPerks} />
        <Grid container spacing={2} alignContent="flex-start">
          {(filteredPerks.length > 0 ? filteredPerks : perks).map((perk) => (
            <Grid key={perk.id} item lg={4} md={6} sm={12}>
              <PerkCard perk={perk} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Perks;
