import { useMemo } from "react";

import { DoitRole, TemplateVisibility } from "@doitintl/cmp-models";
import { Card, FormControl, RadioGroup, Stack, TextField, Typography } from "@mui/material";

import { globalText } from "../../assets/texts";
import { templateCreateText } from "../../assets/texts/CloudAnalytics";
import { useAuthContext } from "../../Context/AuthContext";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import {
  CreateTemplateDataKind,
  useCreateTemplateContext,
  useReportTemplateContext,
} from "../../Pages/CloudAnalytics/Context";
import { EDIT_TEMPLATE_QUERY_PARAM } from "../../types";
import { useQuery } from "../../utils/useQuery";
import { useDoitRoleCheck } from "../hooks/useDoitRoles";
import TemplateAlert from "./TemplateAlert";
import TemplateAutocomplete from "./TemplateAutocomplete";
import { getTemplateVisibilityLabel } from "./utils";
import VisibilityRadioBtn from "./VisibilityRadioBtn";

const visibilityOptions = [
  { value: TemplateVisibility.PRIVATE, description: templateCreateText.VISIBILITY_DESCRIPTIONS.PRIVATE },
  { value: TemplateVisibility.INTERNAL, description: templateCreateText.VISIBILITY_DESCRIPTIONS.DOIT },
  { value: TemplateVisibility.GLOBAL, description: templateCreateText.VISIBILITY_DESCRIPTIONS.PUBLIC },
];

const TemplateCreate = () => {
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const query = useQuery();
  const isTemplateLibraryAdmin = useDoitRoleCheck(DoitRole.TemplateLibraryAdmin);
  const { createTemplateData, dispatchCreateTemplateData } = useCreateTemplateContext();
  const { reportTemplateCards, reportTemplateId } = useReportTemplateContext();
  const isEditMode = query.has(EDIT_TEMPLATE_QUERY_PARAM) && !!reportTemplateId;

  const reportTemplate = useMemo(
    () => reportTemplateCards.find((rt) => rt.templateRefId === reportTemplateId),
    [reportTemplateCards, reportTemplateId]
  );

  const isVersionOwner = reportTemplate?.visibleVersion.createdBy === currentUser.email;
  const disabled = isEditMode && !isTemplateLibraryAdmin && !isVersionOwner && !reportTemplate?.visibleVersion.active;

  const showAlertOrDescription = () => {
    if (isEditMode && reportTemplate) {
      return <TemplateAlert isVersionOwner={isVersionOwner} reportTemplate={reportTemplate} />;
    } else {
      return <Typography variant="body2">{templateCreateText.HEADER_DESCRIPTION}</Typography>;
    }
  };

  return (
    <Card data-cy="template-create-form" sx={{ width: 390, p: 2, height: "100%", overflow: "auto" }}>
      <Typography variant="h4" mb={2}>
        {templateCreateText.TITLE}
      </Typography>
      {showAlertOrDescription()}
      <Stack direction="column" rowGap={2} mb={2} mt={3}>
        <TextField
          data-cy="template-name"
          disabled={disabled}
          onFocus={setHotKeysDisabled}
          onBlur={setHotKeysEnabled}
          inputProps={{ maxLength: 100 }}
          label={globalText.NAME}
          onChange={(e) => dispatchCreateTemplateData({ payload: { name: e.target.value } })}
          required
          value={createTemplateData.name}
        />
        <TextField
          data-cy="template-description"
          disabled={disabled}
          onFocus={setHotKeysDisabled}
          onBlur={setHotKeysEnabled}
          label={globalText.DESCRIPTION}
          required
          multiline
          value={createTemplateData.description}
          onChange={(e) => dispatchCreateTemplateData({ payload: { description: e.target.value } })}
          maxRows={4}
          inputProps={{ maxLength: 2000 }}
        />
        <TemplateAutocomplete
          data-cy="template-clouds"
          disabled={disabled}
          options={createTemplateData.clouds}
          onChange={(value: any) => {
            dispatchCreateTemplateData({
              type: CreateTemplateDataKind.UPDATE_CLOUDS,
              payload: { clouds: value },
            });
          }}
          titleLabel={templateCreateText.CLOUDS}
        />
        <TemplateAutocomplete
          data-cy="template-categories"
          disabled={disabled}
          options={createTemplateData.categories}
          onChange={(value: any) => {
            dispatchCreateTemplateData({
              type: CreateTemplateDataKind.UPDATE_CATEGORIES,
              payload: { categories: value },
            });
          }}
          titleLabel={templateCreateText.CATEGORIES}
        />
      </Stack>
      <Stack>
        <Typography variant="subtitle1">{templateCreateText.VISIBILITY}</Typography>
        <FormControl margin="dense" disabled={disabled}>
          <RadioGroup
            data-cy="template-visibility"
            value={createTemplateData.visibility}
            onChange={(_, value) =>
              dispatchCreateTemplateData({ payload: { visibility: value as TemplateVisibility } })
            }
          >
            {visibilityOptions.map(({ value, description }) => (
              <VisibilityRadioBtn
                key={value}
                value={value}
                label={getTemplateVisibilityLabel(value)}
                description={description}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>
    </Card>
  );
};

export default TemplateCreate;
