import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import AWSLogo from "../../../assets/aws-logo.svg";
import AzureLogo from "../../../assets/azure-logo.svg";
import GCPLogo from "../../../assets/gcp-logo.svg";
import { type Cloud, type CloudflowTemplate } from "../types";

type TemplateProps = {
  template: CloudflowTemplate;
  onTemplateClick: (template: CloudflowTemplate) => void;
  clouds: Map<string, Cloud[]>;
};
const ICON_HEIGHT = 20;

const marketplaceMap = {
  aws: AWSLogo,
  gcp: GCPLogo,
  azure: AzureLogo,
};

const Template = ({ template, onTemplateClick, clouds }: TemplateProps) => {
  const theme = useTheme();

  return (
    <Card
      onClick={() => onTemplateClick(template)}
      sx={{
        py: 2,
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
        height: "100%",
      }}
    >
      <CardHeader
        title={template.name}
        sx={{
          pb: 0.5,
          pt: 0,
        }}
        titleTypographyProps={{
          variant: "subtitle1",
          fontWeight: 500,
        }}
      />
      <CardContent
        sx={{
          py: 0,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {template.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ pb: 0 }}>
        <Stack gap={1} direction="row" px={1} mt={1}>
          {[...(clouds.get(template.id) || []), ...template.integrations].map((brandIcon, i) => {
            const logo = marketplaceMap[brandIcon as string];
            if (!logo) {
              return null;
            }
            return (
              <Box key={i} sx={{ bgcolor: theme.palette.grey[100], borderRadius: 6 }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: ICON_HEIGHT,
                    width: ICON_HEIGHT,
                    margin: theme.spacing(0.25, 1, -0.5, 1),
                  }}
                />
              </Box>
            );
          })}
        </Stack>
      </CardActions>
    </Card>
  );
};

export default Template;
