import { type JSX, useEffect, useState } from "react";

import * as runtime from "react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { compile, run } from "@mdx-js/mdx";
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { consoleErrorWithSentry } from "../../utils";

export const useMdxContent = (mdxStringifyContent: string) => {
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const compileMdx = async () => {
      const code = String(await compile(mdxStringifyContent, { outputFormat: "function-body" }));
      const { default: Content } = await run(code, { ...runtime, Fragment: Box } as any);

      setContent(
        <ErrorBoundary
          fallback={<p>Error showing content, </p>}
          onError={(error) => {
            consoleErrorWithSentry(error);
          }}
        >
          <Content
            components={{
              // Any headings within the text should be formatted the same way
              h1: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              h2: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              h5: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              h6: ({ children }) => (
                <Typography variant="h4" mb={2} mt={3}>
                  {children}
                </Typography>
              ),
              p: ({ children, style }) => (
                <Typography variant="body1" style={style} sx={{ mb: 2 }}>
                  {children}
                </Typography>
              ),
              a: ({ children, href }) => (
                <Link target="_blank" href={href}>
                  {children}
                </Link>
              ),
              Table: (props) => <Table sx={{ mb: 2 }} {...props} />,
              TableHead: (props) => <TableHead {...props} />,
              TableBody: (props) => <TableBody {...props} />,
              TableRow: (props) => <TableRow {...props} />,
              TableCell: (props) => <TableCell {...props} />,
            }}
          />
        </ErrorBoundary>
      );
    };

    compileMdx().catch((e) => {
      setContent(e.message);
    });
  }, [mdxStringifyContent]);

  return content;
};
