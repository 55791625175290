import { AwsMarketplaceAccountStatus, CustomerModel, MarketplaceModel, type UserModel } from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { consoleErrorWithSentry } from "../../../utils";
import { serverTimestamp } from "../../../utils/firebase";

/**
 * @description: creates a new awsMarketplaceAccount doc on firestore and triggers integration service for further handling
 */
export async function createNewAwsMarketplaceAccount({
  awsToken,
  customerId,
  userRef,
  api,
}: {
  awsToken: string;
  customerId: string;
  userRef: ModelReference<UserModel>;
  api: AxiosInstance;
}): Promise<string | null> {
  try {
    const customerRef = getCollection(CustomerModel).doc(customerId);
    const awsMarketplaceAccountColl = getCollection(MarketplaceModel)
      .doc("aws-marketplace")
      .collection("awsMarketplaceAccounts");

    const existDocs = (await awsMarketplaceAccountColl.where("customer", "==", customerRef).get()).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (existDocs.some((doc) => doc.status === "ACTIVE")) {
      throw new Error("given customer already have an active awsMarketplaceAccount");
    }

    // if we already have a pending awsMarketplaceAccount for this customer, we don't create a new one
    let awsMpSubscriptionDocId = existDocs.find((doc) => doc.awsToken === awsToken && doc.status === "PENDING")?.id;
    if (!awsMpSubscriptionDocId) {
      awsMpSubscriptionDocId = (
        await awsMarketplaceAccountColl.add({
          awsToken,
          customer: customerRef,
          status: AwsMarketplaceAccountStatus.PENDING,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          createdBy: userRef,
        })
      ).id;
    }

    // 1. get aws customer identifier
    await api.post("/v1/marketplace/aws/resolve-customer", { awsMpSubscriptionDocId });

    // 2. ensure that customer has enabled subscription
    await api.post("/v1/marketplace/aws/entitlement-validation", { awsMpSubscriptionDocId });

    return awsMpSubscriptionDocId;
  } catch (e: any) {
    e.message = `Activation of AWS Marketplace subscription failed: ${e.message}`;
    consoleErrorWithSentry(e);
    return null;
  }
}
