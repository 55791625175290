import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { type StandaloneOnboardingAWS } from "@doitintl/cmp-models";
import { Container } from "@mui/material";
import differenceBy from "lodash/differenceBy";

import { Loader } from "../../../../Components/Loader";
import { useAWSStandaloneOnboarding } from "../hooks";
import { AccountSelection } from "./AccountSelection";
import { SingleAccountOnboarding } from "./SingleAccountOnboarding";

type Props = {
  filterCompletedAccounts?: boolean;
};

export const Standalone = ({ filterCompletedAccounts = false }: Props) => {
  const history = useHistory();
  const location = useLocation<{ accountId: string | undefined }>();

  const [currentAccount, setCurrentAccount] = useState<StandaloneOnboardingAWS | undefined>(undefined);

  const [accountFromStateSet, setAccountFromStateSet] = useState<boolean>(false);

  // hold the current account of the customer
  const [accounts] = useAWSStandaloneOnboarding(filterCompletedAccounts);

  // will be used to detect new changes to accounts during the onboarding
  const [previousAccounts, setPreviousAccounts] = useState<StandaloneOnboardingAWS[]>([]);

  const currentAccountId = useMemo(() => (currentAccount ? currentAccount.accountId : undefined), [currentAccount]);

  const navigateToFlexsave = useCallback(() => {
    history.push("./flexsave-aws");
  }, [history]);

  useEffect(() => {
    if (accounts && accounts?.length > 0) {
      if (previousAccounts.length === 0) {
        // save the current accounts to detect new adding later
        setPreviousAccounts(accounts);
        setCurrentAccount(accounts[0]);
      } else if (accounts.length > previousAccounts.length) {
        // new account additional detected, in this case select the new account as selected
        const newAccount = differenceBy(
          accounts,
          previousAccounts,
          (account: StandaloneOnboardingAWS) => account.accountId
        );

        setCurrentAccount(newAccount.length > 0 ? newAccount[0] : accounts[0]);
        setPreviousAccounts(accounts);
      } else if (accounts.length < previousAccounts.length) {
        // existing account removed, this means he was completed
        navigateToFlexsave();
      } else {
        // set current account again so it trigger props dependencies update
        setCurrentAccount(accounts.find((account) => account.accountId === currentAccountId));
      }
    }
  }, [previousAccounts, accounts, currentAccountId, currentAccount, navigateToFlexsave]);

  useEffect(() => {
    // set specific account by the state, in case of undefined it will show "new estimation"
    if (accounts && location.state && !accountFromStateSet) {
      if (location.state.accountId) {
        setCurrentAccount(accounts.find((account) => account.accountId === location.state.accountId));
      } else {
        setCurrentAccount(undefined);
      }
      setAccountFromStateSet(true);
    }
  }, [accountFromStateSet, accounts, location]);

  const handleAccountChange = (accountId?: string) => {
    if (accountId) {
      setCurrentAccount(accounts?.find((account) => account.accountId === accountId));
    } else {
      setCurrentAccount(undefined);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <Loader loading={!accounts}>
        <SingleAccountOnboarding
          currentAccount={currentAccount}
          onBackButtonClick={navigateToFlexsave}
          unsetCurrentAccount={() => setCurrentAccount(undefined)}
        >
          <AccountSelection
            currentAccountId={currentAccountId}
            accounts={accounts ?? []}
            onAccountChanged={handleAccountChange}
          />
        </SingleAccountOnboarding>
      </Loader>
    </Container>
  );
};
