import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type NotificationSettings, type UserPersonalNotificationModel } from "@doitintl/cmp-models";
import { Stack, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { formatToLocaleTimestamp } from "../../../utils/common";
import { type LabelWithRef } from "../labels/types";
import { type AttributionWRefRowItem } from "./AttributionBrowser";
import { useAttributionsThreeDotsMenu } from "./hooks";

const { attributions: attributionIds } = cloudAnalytics;

export type AttributionRowData = {
  row: AttributionWRefRowItem;
  labels: LabelWithRef[] | undefined;
  handleShare: () => void;
  handleSettings: () => void;
  userEmailNotification?: UserPersonalNotificationModel;
  updateUserEmailNotification: (selectedNotifications: NotificationSettings) => Promise<void>;
};

export const AttributionRow = ({
  row,
  labels,
  handleShare,
  handleSettings,
  userEmailNotification,
  updateUserEmailNotification,
}: AttributionRowData) => {
  const routeMatchURL = useRouteMatchURL();
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const threeDotsMenu = useAttributionsThreeDotsMenu({
    row,
    handleShare,
    handleSettings,
    userEmailNotification,
    updateUserEmailNotification,
  });

  const nameAndDescCell = (
    <TableCell style={{ maxWidth: "50vw", overflowWrap: "break-word" }}>
      <Stack spacing={1}>
        <Stack>
          <Typography
            component={RouterLink}
            to={`${routeMatchURL}/${row.ref.id}`}
            color="inherit"
            variant="body2"
            data-cy={attributionIds.browser.attributionName}
          >
            {row.data.name}
          </Typography>
          {!!row.data.description && (
            <Typography
              color="textSecondary"
              variant="body2"
              sx={{
                whiteSpace: "noWrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "calc(100vw/4)",
              }}
              title={row.data.description}
            >
              {row.data.description}
            </Typography>
          )}
        </Stack>
        <LabelList sx={{ maxWidth: "25vw" }} labels={rowLabels} />
      </Stack>
    </TableCell>
  );

  return (
    <>
      {nameAndDescCell}
      <TableCell>
        <Typography color="inherit" variant="body2" data-cy={attributionIds.browser.attributionOwner}>
          {row.owner}
        </Typography>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={attributionIds.browser.attributionType}>{capitalize(row.data.type)}</TableCell>
        <TableCell data-cy="anomalyDetection">{row.anomalyDetectionText}</TableCell>
        <TableCell data-cy={attributionIds.browser.attributionLastModified}>
          {formatToLocaleTimestamp(row.data.timeModified)}
        </TableCell>
      </Hide>
      <TableCell padding="none">{threeDotsMenu}</TableCell>
    </>
  );
};
