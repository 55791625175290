import { Component } from "react";
import { compose } from "recompose";
import isEqual from "lodash/isEqual";
import keys from "lodash/keys";
import values from "lodash/values";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListSubheader from "@mui/material/ListSubheader";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import LoadingButton from "../../Components/LoadingButton";
import { preventOnCloseWhile, withFullScreen } from "../../utils/dialog";
import { globalText } from "../../assets/texts";

import charities from "../charities";
import DonationQualifier from "./DonationQualifier";
import FeedbackTitle from "./FeedbackTitle";
import VendorScore from "./VendorScore";

const styles = (theme) => ({
  textField: {
    margin: theme.spacing(2, 0),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
});

export const CHARITY_PREFIX = "csat_charity:";
export const VENDOR_SCORE_PREFIX = "csat_vendor:";

const getCharityTag = (tags) => tags?.find((tag) => tag.startsWith(CHARITY_PREFIX));
const getVendorScoreTag = (tags) => tags?.find((tag) => tag.startsWith(VENDOR_SCORE_PREFIX));
const getCharityFromList = (tag) =>
  values(charities)
    .flat()
    .find((charity) => charity.tag === tag.replace(CHARITY_PREFIX, ""));

const disabledSubmission = (score, loading) => loading || !["good", "bad"].includes(score);

class SurveyDialog extends Component {
  originalTagValues = {
    charityTag: "",
    vendorScore: "",
  };

  state = {
    score: this.props.satisfaction?.score || "",
    comment: this.props.satisfaction?.comment || "",
    error: false,
    loading: false,
    charityTag: getCharityTag(this.props.tags) || "",
    vendorScore: getVendorScoreTag(this.props.tags) || "",
    charityChanged: false,
    vendorScoreChanged: false,
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (!isEqual(prevProps.satisfaction, this.props.satisfaction)) {
      this.setState({
        score: this.props.satisfaction?.score || "",
        comment: this.props.satisfaction?.comment || "",
      });
    }
    if (!isEqual(prevProps.tags, this.props.tags)) {
      const charityTag = getCharityTag(this.props.tags) || "";
      const vendorScore = getVendorScoreTag(this.props.tags) || "";
      this.originalTagValues.charityTag = charityTag;
      this.originalTagValues.vendorScore = vendorScore;
      this.setState({
        charityTag: getCharityTag(this.props.tags) || "",
        vendorScore: getVendorScoreTag(this.props.tags) || "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { score, comment, charityTag, vendorScore, charityChanged, vendorScoreChanged } = this.state;
    this.setState({
      loading: true,
    });
    this.props.handleSubmit({
      score,
      comment,
      charityTag: comment.length >= 15 ? charityTag : "",
      vendorScore,
      charityChanged,
      vendorScoreChanged,
    });
  };

  buildCharityList = () => {
    const makeSubheader = (charityGeo) => (
      <ListSubheader key={charityGeo} sx={{ px: "1ch" }}>
        <Typography variant="subtitle" component="span">
          {charityGeo}
        </Typography>
      </ListSubheader>
    );

    const buildCharitiesSublist = (charitiesFromGeo) =>
      charitiesFromGeo.map((charity) => (
        <MenuItem key={charity.label} value={`${CHARITY_PREFIX}${charity.tag}`}>
          <Typography variant="body2" component="span">
            {charity.label}
          </Typography>
        </MenuItem>
      ));

    const charityItemList = [
      <MenuItem key="[none]" value="">
        <Typography variant="body2" component="span">
          [None]
        </Typography>
      </MenuItem>,
      <Divider key="divider" />,
    ];

    const geos = keys(charities);

    geos.forEach((geo) => {
      charityItemList.push(makeSubheader(geo));
      charityItemList.push(...buildCharitiesSublist(charities[geo]));
    });

    return charityItemList;
  };

  handleCharityChange = (event) =>
    this.setState({
      charityTag: event.target.value,
      charityChanged: event.target.value !== this.originalTagValues.charityTag,
    });

  handleVendorScoreChange = (event) =>
    this.setState({
      vendorScore: event.target.value,
      vendorScoreChanged: event.target.value !== this.originalTagValues.vendorScore,
    });

  render() {
    const {
      classes,
      fullScreen,
      requestID,
      open,
      isRequester,
      onClose,
      requester,
      impersonateFeatureActive,
      requestSummary,
      tags,
    } = this.props;
    const { score, comment, loading, vendorScore } = this.state;

    const charityIntro = (
      <Stack gap={3} marginBottom={1}>
        <Box>
          <Typography variant="subtitle1" marginBottom="0.25rem">
            Share more details, and together, we'll support a charitable cause
          </Typography>
          <Typography variant="body2" color="textSecondary">
            For your feedback, we'll donate $5 to a charity from the list below (
            <Link target="_blank" href="https://www.doit.com/feedback-for-a-cause/">
              More info
              <OpenInNewIcon
                sx={{
                  mb: -0.25,
                  width: 14,
                  height: 14,
                }}
              />
            </Link>
            )
          </Typography>
        </Box>
        <Stack direction="row">
          <FormControl sx={{ minWidth: "50%" }} variant="outlined">
            <InputLabel id="charityLabel" shrink sx={{ backgroundColor: "background.paper", px: 0.8 }}>
              Charity
            </InputLabel>
            <Select
              labelId="charityLabel"
              value={this.state.charityTag}
              name="charityTag"
              size="small"
              onChange={this.handleCharityChange}
              MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
              variant="outlined"
            >
              {this.buildCharityList()}
            </Select>
          </FormControl>
          {this.state.charityTag && (
            <Box justifyContent="center" width="50%" pt={0.2} pl={1} alignContent="center">
              <Link target="_blank" href={getCharityFromList(this.state.charityTag)?.link}>
                <Box
                  component="img"
                  src={getCharityFromList(this.state.charityTag)?.logo}
                  alt={getCharityFromList(this.state.charityTag)?.label}
                  maxHeight="38px"
                  maxWidth="100%"
                  mt={0}
                  p={0.3}
                  backgroundColor="white"
                />
              </Link>
            </Box>
          )}
        </Stack>
      </Stack>
    );

    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={preventOnCloseWhile(isRequester, onClose)}
        aria-labelledby="form-dialog-satisfaction"
      >
        <form onSubmit={this.handleSubmit}>
          <FeedbackTitle ticketId={requestID} ticketSummary={requestSummary} handleClose={onClose} />
          <Divider variant="fullWidth" />
          <DialogContent>
            <FormControl
              disabled={!isRequester || impersonateFeatureActive}
              component="fieldset"
              required
              sx={{ mb: "1rem" }}
            >
              <FormLabel component="legend">Please rate the support you received from the DoiT specialist</FormLabel>
              <RadioGroup
                aria-required
                aria-label="score"
                name="score"
                className={classes.group}
                value={score}
                onChange={this.handleChange}
              >
                <FormControlLabel value="good" control={<Radio />} label="Good" />
                <FormControlLabel value="bad" control={<Radio />} label="Bad" />
              </RadioGroup>
            </FormControl>
            <VendorScore
              tags={tags}
              vendorScore={vendorScore}
              handleVendorScoreChange={this.handleVendorScoreChange}
              classNames={{ radioGroup: classes.group }}
              disabled={!isRequester || impersonateFeatureActive}
            />
            {charityIntro}
            <TextField
              id="comment"
              name="comment"
              label="Feedback"
              value={comment}
              onChange={this.handleChange}
              type="text"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              fullWidth
              rows={3}
              margin="dense"
              disabled={!isRequester || impersonateFeatureActive}
            />
            <DonationQualifier qualifies={comment.length >= 15} />
            {requester && (impersonateFeatureActive || !isRequester) && (
              <Alert severity="error">
                For security reasons, only ticket requester ({requester}) can rate this ticket.
              </Alert>
            )}
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button onClick={onClose} color="primary">
              {globalText.CANCEL}
            </Button>
            {isRequester && !impersonateFeatureActive && (
              <LoadingButton
                size="medium"
                color="primary"
                variant="contained"
                type="submit"
                loading={loading}
                disabled={disabledSubmission(score, loading)}
                mixpanelEventId="support.survey.submit"
              >
                Submit feedback
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

SurveyDialog.propTypes = {
  requestID: PropTypes.number,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isRequester: PropTypes.bool,
  requester: PropTypes.string,
  impersonateFeatureActive: PropTypes.bool,
  charitableFeedback: PropTypes.bool,
  requestSummary: PropTypes.string,
  tags: PropTypes.array,
  satisfaction: PropTypes.object,
};

export default compose(withFullScreen, withStyles(styles))(SurveyDialog);
