import { useCallback, useMemo, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import uniq from "lodash/uniq";

import LoadingButton from "../../../Components/LoadingButton";
import timezones from "../../../utils/timezones.json";
import { type TrainingRequest } from "../types";

type Props = {
  onConfirm: (fields: TrainingRequest) => Promise<void>;
  onClose: () => void;
  name: string;
  loading: boolean;
};

const texts = {
  DIALOG_TITLE: "Request Training",
  DIALOG_TEXT: "Please provide our team with some additional details.",
  CANCEL_BUTTON: "Cancel",
  REQUEST_TRAINING_REQUEST: "Request Training",
};

const defaultFieldValues = {
  reason: "",
  numberOfAttendees: 0,
  timezone: "UTC",
};

const fieldLabels = {
  reason: "Why would you like this training?",
  numberOfAttendees: "Number of attendees",
  timezone: "Timezone",
};

export const RequestTrainingDialog = ({ onConfirm, onClose, name, loading }: Props) => {
  const [fields, setFields] = useState<TrainingRequest>(defaultFieldValues);
  const timezonesList = useMemo(() => uniq(timezones.map((element) => element.utc).flat()), []);

  const handleChange = useCallback((event) => {
    const name = event.target?.name;
    const inputValue = event.target?.value;

    setFields((f) => ({
      ...f,
      [name]: inputValue,
    }));
  }, []);

  const handleTimezoneChange = useCallback(
    (_event, value) =>
      setFields((f) => ({
        ...f,
        timezone: value,
      })),
    []
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={true} data-cy="request-training-dialog">
      <DialogTitle>{texts.DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          Training for: <b>{name}</b>
        </Box>
        <Box mb={4}>{texts.DIALOG_TEXT}</Box>

        <Stack gap={3}>
          <TextField
            label={fieldLabels.reason}
            name="reason"
            value={fields.reason}
            onChange={handleChange}
            inputProps={{ maxLength: 1000, "data-cy": "reason-textfield" }}
            multiline
            rows={4}
            required
          />
          <TextField
            label={fieldLabels.numberOfAttendees}
            name="numberOfAttendees"
            value={fields.numberOfAttendees}
            onChange={handleChange}
            type="number"
            inputProps={{ "data-cy": "number-of-attendees-textfield" }}
          />
          <Autocomplete
            filterSelectedOptions
            disableClearable
            value={fields.timezone}
            options={timezonesList}
            onChange={handleTimezoneChange}
            id="timezone-field"
            data-cy="timezone-field"
            renderInput={(params) => (
              <TextField
                {...params}
                data-cy="timezone-field-input"
                variant="outlined"
                label="Timezone"
                fullWidth
                size="small"
              />
            )}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} data-cy="cancel-request-button">
          {texts.CANCEL_BUTTON}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading || !fields.reason}
          loading={loading}
          onClick={() => onConfirm(fields)}
          data-cy="proceed-with-request-button"
          mixpanelEventId="perks.request-training.request"
        >
          {texts.REQUEST_TRAINING_REQUEST}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
