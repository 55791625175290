import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Alert, Box, Stack, Typography } from "@mui/material";

import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { trackConnectInfo } from "../Common/mixpanelEvents";
import { UsefulLinks } from "../Common/UsefulLinks";

export const RoleItem = ({ roleName, roleDescription }) => (
  <Box pl={2}>
    <Typography sx={{ display: "inline" }}>{roleName} </Typography>
    <Typography sx={{ display: "inline" }} fontFamily="monospace" color="text.secondary">
      {roleDescription}
    </Typography>
  </Box>
);

const Overview = () => {
  useMountEffect(() => trackConnectInfo(SaaSConsoleType.GCP));

  return (
    <Stack spacing={4}>
      <Typography variant="h1">Required roles to connect your Google Cloud account</Typography>
      <Stack spacing={2}>
        <Typography variant="body1">On the billing account or the organization the account belongs to:</Typography>
        <RoleItem roleName="• Billing Account Administrator" roleDescription="(roles/billing.admin)" />
      </Stack>
      <Stack spacing={2}>
        <Typography variant="body1">On the dataset containing billing, one of the following roles:</Typography>
        <RoleItem roleName="• Owner" roleDescription="(roles/owner)" />
        <RoleItem roleName="• BigQuery Admin" roleDescription="(roles/bigquery.admin)" />
        <RoleItem roleName="• BigQuery Data Owner" roleDescription="(roles/bigquery.dataOwner)" />
      </Stack>
      <Alert severity="info">We recommend you have all the correct roles before starting</Alert>
      <UsefulLinks cloud={SaaSConsoleType.GCP} />
    </Stack>
  );
};
export default Overview;
