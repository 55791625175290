import { useCallback, useState } from "react";

import { type PerkType } from "@doitintl/cmp-models";

import LoadingButton from "../../../Components/LoadingButton";
import { useTier } from "../../../Context/TierProvider";

type Props = {
  isDoitEmployee: boolean;
  disabledPerk: boolean;
  registerInterest: () => Promise<void>;
  perkType: PerkType;
};

export const RegisterInterestButton = ({ registerInterest, isDoitEmployee, disabledPerk, perkType }: Props) => {
  const [loading, setLoading] = useState(false);

  const onRegisterInterest = useCallback(async () => {
    setLoading(true);
    await registerInterest();
    setLoading(false);
  }, [registerInterest]);

  const { isFeatureEntitled } = useTier();
  const hasEntitlement =
    (perkType === "pro-serv" && isFeatureEntitled("services:pro-serv")) ||
    (perkType === "training" && isFeatureEntitled("services:training")) ||
    (perkType === "accelerator" && isFeatureEntitled("services:training")) || // NOTE: 20404 new entitlement needed?
    (perkType === "workshop" && isFeatureEntitled("services:workshop")) ||
    (perkType !== "pro-serv" && perkType !== "training");

  const disabled = disabledPerk || (isDoitEmployee && perkType !== "pro-serv") || !hasEntitlement;

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      onClick={onRegisterInterest}
      variant="contained"
      data-cy="register-interest-button"
      mixpanelEventId="perks.register-interest-button"
    >
      {hasEntitlement ? "Register interest" : "Contact your Account Manager"}
    </LoadingButton>
  );
};
