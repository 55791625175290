import { AccountManagerCompanies } from "@doitintl/cmp-models";
import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { getAccountTeamRole } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import Hide from "../HideChildren/Hide";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";
import { useRowPerPage } from "./hooks/useRowPerPage";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";

const AccountManagerCompaniesEnum = {
  [AccountManagerCompanies.GCP]: "Google",
  [AccountManagerCompanies.AWS]: "Amazon",
};

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 28,
    height: 28,
    fontSize: 14,
    color: "#fff",
    padding: 0.5,
  },
  avatarColor: {
    backgroundColor: "#FC3165",
    width: 28,
    height: 28,
    fontSize: 14,
    color: "#fff",
    padding: 0.5,
  },
  gcpColor: {
    backgroundColor: "#4285f4",
  },
  awsColor: {
    backgroundColor: "#ff9900",
  },
  cardHeader: {
    // padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
    paddingLeft: HEADER_PADDING,
    paddingRight: HEADER_PADDING,
    paddingTop: HEADER_PADDING,
    paddingBottom: HEADER_PADDING,
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    height: 201,
  },
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  companyLogoWrapper: {
    verticalAlign: "bottom",
  },
  companyLogo: {
    margin: theme.spacing(0.5, 0.5, 0, 0.5),
    height: 24,
  },
}));

const AccountManagersCard = ({ raised, widgetHeight = 1 }: WidgetItemProps) => {
  const classes = useStyles();
  const [roles] = AccountManagersHooks.useAccountManagersRoles();
  const accountManagers = AccountManagersHooks.useAccountManagersForCustomer();
  const { fullScreen: matches } = useFullScreen("xl");
  const { emptyRows, page, rowsPerPage, handleChangePage } = useRowPerPage({
    totalRows: accountManagers?.length ?? 0,
    widgetHeight,
  });

  if (accountManagers === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card raised={raised} className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title="Essential Contacts"
        titleTypographyProps={{ variant: HEADING_VARIANT }}
        subheader="List of essential contacts for your company"
        subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
      />
      <CardContent style={{ height: 200 * widgetHeight }} className={classes.cardContent}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell padding="none" size="small" />
              <TableCell padding="none" size="small">
                Name
              </TableCell>
              <Hide xlDown>
                <TableCell padding="none" size="small">
                  Email
                </TableCell>
              </Hide>
              <Hide smDown>
                <TableCell padding="none" size="small">
                  Role
                </TableCell>
              </Hide>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountManagers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow className={classes.tableRow} key={row.id}>
                <TableCell className={classes.companyLogoWrapper} padding="none" size="small">
                  {row.photoURL ? (
                    <Avatar alt={row.name} src={row.photoURL} className={classes.avatar} sx={{ my: 0.5 }} />
                  ) : (
                    <Avatar
                      alt={row.name}
                      className={clsx(classes.avatarColor, {
                        [classes.gcpColor]: row?.company === AccountManagerCompanies.GCP,
                        [classes.awsColor]: row?.company === AccountManagerCompanies.AWS,
                      })}
                      sx={{ my: 0.5 }}
                    >
                      {row.name.split(" ").map((item, idx) => (idx < 2 ? item[0] : ""))}
                    </Avatar>
                  )}
                </TableCell>

                <TableCell padding="none" size="small">
                  {row.email && matches ? (
                    <Link href={`mailto:${row.email}`} color="inherit" sx={{ textDecoration: "none" }}>
                      {row.name}
                    </Link>
                  ) : (
                    row.name
                  )}
                </TableCell>
                <Hide xlDown>
                  <TableCell padding="none" size="small">
                    {!!row.email && (
                      <Link href={`mailto:${row.email}`} color="inherit" sx={{ textDecoration: "none" }}>
                        {row.email}
                      </Link>
                    )}
                  </TableCell>
                </Hide>
                <Hide smDown>
                  <TableCell padding="none" size="small">
                    {getAccountTeamRole(roles ?? [], row)}
                    {row.company && AccountManagerCompaniesEnum[row.company]
                      ? ` at ${AccountManagerCompaniesEnum[row.company]}`
                      : ""}
                  </TableCell>
                </Hide>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[rowsPerPage]}
                count={accountManagers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                  size: "small",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                  size: "small",
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
};

export default AccountManagersCard;
