import { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";

import { type GcpGkeResource, type ResourceDetailType } from "./resourceDetailType";

type Props = {
  index: number;
  values: GcpGkeResource;
  onDelete: (index: string) => void;
  onChange: (index: string, data: ResourceDetailType) => void;
};

const GcpGkeResourceDetails = ({ index, values, onDelete, onChange }: Props) => {
  const assetIndex = values.assetIndex;
  const [clusterName, setClusterName] = useState<string>(values?.clusterName ?? "");
  const [namespaceWorkload, setNamespaceWorkload] = useState<string>(values?.namespaceWorkload ?? "");

  return (
    <Stack>
      {index > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
          data-cy={`gcpGkeResourceHeaderBox-${index}`}
        >
          <Typography>Resource {index + 1}</Typography>
          <Button
            onClick={() => onDelete(assetIndex)}
            data-cy={`gcpGkeResourceDeleteButton-${index}`}
            sx={{ mb: 0.5 }}
            color="error"
          >
            <DeleteIcon />
            Remove resource
          </Button>
        </Box>
      )}
      <TextField
        label="Cluster name"
        InputLabelProps={{ shrink: true }}
        value={clusterName}
        onChange={(e) => {
          setClusterName(e.target.value);
          onChange(assetIndex, { assetIndex, clusterName: e.target.value, namespaceWorkload });
        }}
        size="small"
        sx={{ my: 1 }}
        data-cy={`clusterNameField-${index}`}
      />
      <TextField
        label="Namespace/Workload name"
        InputLabelProps={{ shrink: true }}
        value={namespaceWorkload}
        onChange={(e) => {
          setNamespaceWorkload(e.target.value);
          onChange(assetIndex, { assetIndex, clusterName, namespaceWorkload: e.target.value });
        }}
        size="small"
        sx={{ my: 1 }}
        data-cy={`namespaceWorkloadField-${index}`}
      />
    </Stack>
  );
};

export default GcpGkeResourceDetails;
