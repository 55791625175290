import { Stack } from "@mui/material";

import { SideDrawer } from "../../../../Components/SideDrawer";
import { type NodeConfigs } from "../../types";
import { ActionBar } from "./ActionBar";
import { Header } from "./Header";
import { NodeConfigurationProvider } from "./NodeConfigurationContext";
import { useTabs } from "./Tabs/hooks";
import { TabContent } from "./Tabs/TabContent";
import { TabNavigation } from "./Tabs/TabNavigation";

type Props = {
  open: boolean;
  onClose: () => void;
  nodeConfig: any;
  onUpdateNode: (nodeConfig: NodeConfigs) => void;
};

const NodeConfigurationPanel = ({ open, onClose, nodeConfig, onUpdateNode }: Props) => {
  const handleDoneClick = (nodeConfig: NodeConfigs) => {
    onUpdateNode(nodeConfig);
    onClose();
  };

  const tabs = useTabs(nodeConfig);

  return (
    <NodeConfigurationProvider nodeConfig={nodeConfig} handleDoneClick={handleDoneClick}>
      <SideDrawer
        open={open}
        title=""
        onClose={onClose}
        variant="persistent"
        sideDrawerSx={{
          my: 7.1,
          borderRadius: 0,
          mx: 0,
          "& .MuiStack-root:nth-of-type(1) > .MuiStack-root:nth-of-type(1)": {
            py: 0.5,
          },
          "& .MuiDivider-root": {
            marginBottom: 0,
          },
        }}
        disableGutters={true}
        headerElement={<Header />}
      >
        <Stack height="100%">
          {tabs.length > 1 && <TabNavigation tabs={tabs} />}
          <Stack sx={{ flexGrow: 1, overflowY: "auto" }}>
            <TabContent />
          </Stack>
          <Stack sx={{ p: 1, borderTop: 1, borderColor: "divider", bgcolor: "background.paper", mb: 5.5 }}>
            <ActionBar />
          </Stack>
        </Stack>
      </SideDrawer>
    </NodeConfigurationProvider>
  );
};

export default NodeConfigurationPanel;
