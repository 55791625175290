import { Container, Stack, Typography } from "@mui/material";

import { ServiceCard } from "../Components/ServiceCard";
import { type ServiceType } from "../types";

type Props = {
  selectedServiceType: ServiceType;
  setSelectedServiceType: (serviceType: ServiceType) => void;
};

export const ServiceTypeStep = ({ selectedServiceType, setSelectedServiceType }: Props) => (
  <Container maxWidth="sm" sx={{ pt: 4 }}>
    <Stack>
      <Typography variant="h1" pb={1}>
        Select service type
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Choose how you'd like to work with DoiT's senior cloud architects, from one-off collaboration to ongoing access
        for continuous cloud guidance.
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" spacing={4} pt={4}>
        <ServiceCard
          active={selectedServiceType === "one-time"}
          onClick={() => setSelectedServiceType("one-time")}
          title="One-time payment"
          subtitle="For when you want to engage with a cloud
  expert on a one-off ticket or expedite new
  cloud technology workloads with
  DoiT's structured approach."
        />
        <ServiceCard
          active={selectedServiceType === "subscription"}
          onClick={() => setSelectedServiceType("subscription")}
          title="Monthly subscription"
          subtitle="For when you want unlimited access to over
        200 senior cloud architects for architecture
        brainstorm sessions, how-to's, break-fix
        troubleshooting, and more."
        />
      </Stack>
    </Stack>
  </Container>
);
