import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { queryKeys } from "../constants";
import { useCustomerContext } from "../Context/CustomerContext";
import { useApiContext } from "./context";
import { getSauronManagementArn, sauronUrl } from "./utils";

export const useOneTimeCopyRequest = () => {
  const api = useApiContext();

  return useCallback(
    (data: { customerId: string; billingAccountId: string }) =>
      api.request({
        method: "put",
        url: `/v1/customers/${data.customerId}/backfill/one-time-copy`,
        data: {
          billingAccountId: data.billingAccountId,
        },
      }),
    [api]
  );
};

export const useGetBigQueryJobQuery = (jobId: string, location: string, project: string) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const customerId = customer.id;

  return useQuery<{ query: string }, AxiosError>({
    queryKey: [queryKeys.bigQueryGetQuery, customerId, location, jobId],
    queryFn: async () => {
      const { data } = await api.request({
        method: "get",
        url: `/v1/customers/${customerId}/bigquery/query/${location}/${project}/${jobId}`,
      });

      return data;
    },
  });
};

export const useDeleteGoogleCloudDirectAsset = () => {
  const api = useApiContext();

  return useCallback(
    ({ customerId, id }: { customerId: string; id: string }) =>
      api.request({
        method: "delete",
        url: `/v1/customers/${customerId}/assets/google-cloud-direct/${id}`,
      }),
    [api]
  );
};

export const useSauronAPI = () => {
  const api = useApiContext();

  const account = useCallback(
    (accountId: string, isStaff: boolean) =>
      api.request({
        method: "get",
        url: `/${isStaff ? "staff_" : ""}account/${accountId}/`,
        baseURL: sauronUrl,
      }),
    [api]
  );

  const supportConsole = useCallback(
    (accountId: string, isStaff: boolean) =>
      api.request({
        method: "get",
        url: `/${isStaff ? "staff_" : ""}account/${accountId}/support_console/?url=${encodeURIComponent(
          "https://console.aws.amazon.com/support/home"
        )}`,
        baseURL: sauronUrl,
      }),
    [api]
  );

  return { account, supportConsole };
};

export const useSauronStaffApi = (accountId: string) => {
  const api = useApiContext();
  const baseURI = `${sauronUrl}staff_account`;

  const orthanc = useCallback(
    ({ create }: { create: boolean }) => {
      if (create) {
        const arn = getSauronManagementArn(accountId);

        return api.request({
          method: "put",
          url: `/${accountId}/orthanc/`,
          data: { management_arn: arn },
          baseURL: baseURI,
        });
      }

      return api.request({
        method: "get",
        url: `/${accountId}/orthanc/`,
        baseURL: baseURI,
      });
    },
    [api, baseURI, accountId]
  );

  const getSupportHatch = useCallback(
    () =>
      api.request({
        method: "get",
        url: `/${accountId}/support_hatch/`,
        baseURL: baseURI,
      }),
    [api, baseURI, accountId]
  );

  const getSupportCredentials = useCallback(
    () =>
      api.request({
        method: "get",
        url: `/${accountId}/support_credentials/`,
        baseURL: baseURI,
      }),
    [api, baseURI, accountId]
  );

  const installSupportHatch = useCallback(
    () =>
      api.request({
        method: "put",
        url: `/${accountId}/support_hatch/`,
        baseURL: baseURI,
      }),
    [api, baseURI, accountId]
  );

  const updateSupportHatch = useCallback(
    () =>
      api.request({
        method: "post",
        url: `/${accountId}/support_hatch/`,
        baseURL: baseURI,
      }),
    [api, baseURI, accountId]
  );

  const deleteSupportHatch = useCallback(
    () =>
      api.request({
        method: "delete",
        url: `/${accountId}/support_hatch/`,
        baseURL: baseURI,
      }),
    [api, baseURI, accountId]
  );

  return {
    orthanc,
    getSupportHatch,
    installSupportHatch,
    updateSupportHatch,
    deleteSupportHatch,
    getSupportCredentials,
  };
};
