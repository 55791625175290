import React, { useEffect, useMemo, useState } from "react";

import { NavigatorTierPackageSKUs } from "@doitintl/cmp-models";
import { type TypographyProps } from "@mui/material";

import NoEntitlement, { CardsComponent, TiersType } from "../../../Components/BlankStateUpsell/NoEntitlement";
import { useTier } from "../../../Context/TierProvider";
import { formatDecimalNumber } from "../../../utils/common";
import { useInsights } from "../api";

const descriptionTypographyProps: TypographyProps = {
  align: "center",
  fontSize: "2.25rem",
  fontWeight: "500",
  my: 3,
};
const titleTypographyProps: TypographyProps = {
  align: "center",
};
export const InsightsUpsell: React.FC = () => {
  const { nonEntitledSummary, isFetching } = useInsights();
  const { getTierFeature } = useTier();
  const [enhancedTierInsightsCount, setEnhancedTierInsightsCount] = useState(0);
  const customProblemsInsights = nonEntitledSummary?.customProblems.numberOfActionableInsights || 0;
  const costSavingInsights = nonEntitledSummary?.potentialDailySavings.numberOfActionableInsights || 0;
  const relevantInsights =
    (nonEntitledSummary?.customProblems.numberOfRelevantInsights || 0) +
    (nonEntitledSummary?.potentialDailySavings.numberOfRelevantInsights || 0);
  const monthlySavings = (nonEntitledSummary?.potentialDailySavings.value || 0) * 30.4;

  useEffect(() => {
    const feature = getTierFeature(NavigatorTierPackageSKUs.ENHANCED, "governance:insights");
    if (Array.isArray(feature?.limit)) {
      setEnhancedTierInsightsCount(feature?.limit.length || 0);
    } else {
      setEnhancedTierInsightsCount(Number(feature?.limit || 0));
    }
  }, [getTierFeature]);

  const cards = useMemo(() => {
    if (isFetching) {
      return null;
    }
    // Minimum savings to show the cards, equal to 50 per day
    if (monthlySavings < 250) {
      return (
        <CardsComponent
          title="How Insights could help you"
          bgcolor="background.paper"
          cards={[
            {
              title: "Optimize cloud costs",
              description:
                "Insights continuously monitors your cloud workloads, and provides ongoing recommendations for lowering your spend.",
            },
            {
              title: "Save time and effort",
              description:
                "Avoid the hassle of running intermittent reviews or audits of your cloud architecture, and leave the investigation work to us.",
            },
            {
              title: "Streamline your FinOps practices",
              description:
                "Connect Cloud Navigator to your project management platform with DoiT Threads, making it easy to take action on recommendations.",
            },
          ]}
        />
      );
    }
    return (
      <CardsComponent
        cards={[
          {
            title: "Potential monthly savings",
            description: `$${formatDecimalNumber(monthlySavings, 0)}`,
            titleTypographyProps,
            descriptionTypographyProps,
          },
          {
            title: "Total relevant insights",
            description: String(relevantInsights),
            titleTypographyProps,
            descriptionTypographyProps,
          },
          {
            title: "Actionable Insights",
            description: String(customProblemsInsights + costSavingInsights),
            titleTypographyProps,
            descriptionTypographyProps,
          },
        ]}
        title="How Insights could help you"
        bgcolor="background.paper"
      />
    );
  }, [costSavingInsights, customProblemsInsights, isFetching, monthlySavings, relevantInsights]);

  return (
    <NoEntitlement
      {...{
        tiers: [
          {
            title: "Premium (All Insights)",
            tierType: TiersType.PREMIUM,
          },
          {
            title: enhancedTierInsightsCount === 0 ? "Enhanced" : `Enhanced (${enhancedTierInsightsCount} Insights)`,
            tierType: TiersType.ENHANCED,
          },
        ],
        cards,
        title: "Uncover optimization opportunities with Insights",
        description:
          "DoiT Insights leverages cloud billing data to uncover optimization opportunities in your cloud. It generates actionable recommendations and provides information to help you make informed decisions.",
      }}
    />
  );
};
