import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { InvoiceModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import AttachmentIcon from "@mui/icons-material/AttachmentRounded";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrowRounded";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import SnoozeIcon from "@mui/icons-material/PauseRounded";
import PayIcon from "@mui/icons-material/PaymentRounded";
import {
  Alert,
  Badge,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import { invoicesText } from "../../../assets/texts";
import Hide from "../../../Components/HideChildren/Hide";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { usePriorityErpStatus } from "../../../Context/usePriorityErpStatus";
import { useUserContext } from "../../../Context/UserContext";
import { formatCurrency, sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { handleDownloadInvoice } from "../downloadInvoice";
import { InvoicesFaqButton } from "../FAQ/InvoicesFAQ";
import { type Invoice } from "../types";
import { ExplainerDialog } from "./Explainer/ExplainerDialog";
import { useInvoiceRow } from "./invoiceRow";
import InvoicePayDialog from "./PayDialog/InvoicePayDialog";
import { useInvoiceExplainer } from "./useInvoiceExplainer";
import { useStripeClient } from "./useStripeClient";
import { findDraftInvoice, findIssuedInvoice } from "./utils";

const columns = [
  {
    id: "PDES",
    align: "left",
    label: "Description",
    tooltip: "Item Description",
    hidden: {},
  },
  {
    id: "DESCRIPTION",
    align: "left",
    label: "Details",
    tooltip: "Detailed Item Description",
    hidden: { xsDown: true },
  },
  {
    id: "PARTNAME",
    align: "left",
    label: "SKU",
    tooltip: "Stock Keeping Unit",
    hidden: { xsDown: true },
  },
  {
    id: "QUANT",
    align: "right",
    label: "Units",
    tooltip: "Amount of Units",
    hidden: { xsDown: true },
  },
  {
    id: "PRICE",
    align: "right",
    label: "PPU",
    tooltip: "Price Per Unit",
    hidden: { xsDown: true },
  },
  {
    id: "EXCH",
    align: "right",
    label: "Rate",
    tooltip: "Exchange Rate",
    hidden: { xsDown: true },
  },
  {
    id: "PERCENT",
    align: "right",
    label: "Discount",
    tooltip: "Discount Percentage",
    hidden: { xsDown: true },
  },
  {
    id: "QPRICE",
    align: "right",
    label: "Price",
    tooltip: "Total Price",
    hidden: {},
  },
] as const;

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  cardActions: {
    justifyContent: "space-between",
    padding: theme.spacing(0, 1, 1),
  },
  headerTableRow: {
    background: theme.palette.background.default,
  },
  footerTableRow: {
    background: theme.palette.background.default,
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
  },
  actionButton: {
    width: 128,
  },
  badge: {
    top: "15%",
    right: -6,
    backgroundColor: theme.palette.text.secondary,
  },
  mirrored: {
    transform: "scaleX(-1)",
  },
}));

type Props = {
  invoices: readonly Invoice[];
  ivnum: string;
  entityId: string;
};

export const InvoiceDetails = ({ invoices, ivnum, entityId }: Props) => {
  const classes = useStyles();
  const { isMobile: xsDown } = useFullScreen("sm");
  const { customer, entities } = useCustomerContext();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { enrichInvoice } = useInvoiceRow();
  const history = useHistory();
  const [payDialogOpen, setPayDialogOpen] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [entity, setEntity] = useState<Entity | null>(null);
  const { stripeClient } = useStripeClient(entity?.currency);
  const [sorting, setSorting] = useState<{ active: string; direction: "asc" | "desc" }>({
    active: "IVDATE",
    direction: "desc",
  });
  const [paging, setPaging] = useState({ page: 0, rowsPerPage: 50 });
  const [error, setError] = useState(false);
  const [snoozeLoading, setSnoozeLoading] = useState(false);
  const errorSnackbar = useErrorSnackbar();

  const { search } = useLocation();
  const [showExplainer, setShowExplainer] = useState(new URLSearchParams(search).get("explain") === "true");

  const invoiceHasDiscount = invoice?.INVOICEITEMS?.some((item) => item.PERCENT !== 0) ?? false;
  const filteredColumns = columns.filter((column) => column.id !== "PERCENT" || invoiceHasDiscount);
  const summaryColSpan = xsDown ? 1 : filteredColumns.length - 2;

  const { explainer, setCurrency } = useInvoiceExplainer(invoice);
  const priorityErpStatus = usePriorityErpStatus();

  useEffect(() => {
    mixpanel.track("invoices.invoice.open", {
      id: ivnum,
    });
  }, [ivnum]);

  useEffect(() => {
    const entity = entities.find((e) => e.id === entityId);
    setEntity(entity ?? null);
    if (!entity) {
      return;
    }

    const invoice = invoices.find(
      (currentInvoice) =>
        currentInvoice.customer.id === customer.id &&
        currentInvoice.entity.id === entityId &&
        currentInvoice.IVNUM === ivnum
    );

    if (invoice) {
      setInvoice(invoice);
      return;
    }

    Promise.all([findIssuedInvoice(entity, ivnum), findDraftInvoice(customer, ivnum)]).then(([issued, draft]) => {
      if (issued) {
        setInvoice(issued);
        return;
      }

      if (draft) {
        setInvoice(draft);
        return;
      }

      setError(true);
    });
  }, [customer.id, entities, invoices, entityId, ivnum, customer]);

  const handleChangePage = (_event, page: number) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPaging((prevState) => ({ ...prevState, page: 0, rowsPerPage: event.target.value }));
  };

  const handleRequestSort = (active: string) => () => {
    let direction: "desc" | "asc" = "desc";
    if (sorting.active === active && sorting.direction === "desc") {
      direction = "asc";
    }
    setSorting({ active, direction });
  };

  const onOpenPayDialog = async () => {
    mixpanel.track("invoices.invoice.pay.open", {
      id: ivnum,
    });
    setPayDialogOpen(true);
  };

  const navigateInvoices = (offset: number) => () => {
    const invoiceIndex = invoices.findIndex((i) => i.id === invoice?.id);
    if (invoiceIndex !== -1) {
      setPaging((prevState) => ({ ...prevState, page: 0 }));
      const len = invoices.length;
      const nextInvoiceIndex = (((invoiceIndex - offset) % len) + len) % len;
      const nextInvoice = invoices[nextInvoiceIndex];
      history.push(`/customers/${nextInvoice.customer.id}/invoices/${nextInvoice.entity.id}/${nextInvoice.IVNUM}`);
    }
  };

  const handleSnoozeInvoice = useCallback(async () => {
    if (!invoice?.id) {
      return;
    }
    setSnoozeLoading(true);
    await getCollection(InvoiceModel)
      .doc(invoice.id)
      .update({
        reminderSettings: {
          snoozeUntil: DateTime.utc().plus({ days: 5 }).startOf("day").toJSDate(),
          updatedBy: currentUser.email,
        },
      });
    setSnoozeLoading(false);
  }, [currentUser.email, invoice?.id]);

  const snoozeButton = useMemo(() => {
    if (!invoice) {
      return;
    }
    const today = DateTime.local().startOf("day");
    let until = today;
    if (invoice.reminderSettings) {
      const snoozeUntilDate = DateTime.fromJSDate(invoice.reminderSettings.snoozeUntil.toDate());
      if (snoozeUntilDate.isValid) {
        until = snoozeUntilDate.toLocal();
      } else {
        return;
      }
    }
    const isSnoozed = today < until;
    return (
      <LoadingButton
        key="snooze-reminder"
        variant="outlined"
        aria-label="Snooze Invoice Reminder"
        color="primary"
        size="small"
        onClick={handleSnoozeInvoice}
        className={classes.actionButton}
        disabled={snoozeLoading || isSnoozed}
        loading={snoozeLoading}
        startIcon={<SnoozeIcon />}
        mixpanelEventId="invoices.details.snooze"
      >
        {isSnoozed ? until.toLocaleString(DateTime.DATE_MED) : "Snooze"}
      </LoadingButton>
    );
  }, [classes.actionButton, handleSnoozeInvoice, invoice, snoozeLoading]);

  const rowData = useMemo(() => (invoice ? enrichInvoice(invoice) : null), [enrichInvoice, invoice]);

  if (error) {
    return (
      <>
        <Typography variant="h6" color="error">
          ERROR
        </Typography>
        <Typography variant="body2">Please try again later or contact our support.</Typography>
      </>
    );
  }

  if (!rowData || !entity) {
    return null;
  }

  const isWireTransfer =
    entity?.payment && (entity.payment.type === "wire_transfer" || entity.payment.type === "bill_com");

  const onOpenExplainerDialog = () => {
    mixpanel.track("invoices.invoice.explain.open", { invoice: invoice?.IVNUM });
    setShowExplainer(true);
    history.push({ search: "?explain=true" });
  };

  const onCloseExplainerDialog = () => {
    mixpanel.track("invoices.invoice.explainer.close");
    setShowExplainer(false);
    history.push({ search: "?explain=false" });
  };

  return (
    <>
      <Elements stripe={stripeClient}>
        {payDialogOpen && (
          <InvoicePayDialog
            onClose={() => {
              setPayDialogOpen(false);
            }}
            entity={entity}
            invoice={rowData}
            maxAmount={rowData._BALANCE}
          />
        )}
      </Elements>

      {showExplainer && explainer && (
        <ExplainerDialog
          open={showExplainer}
          explainer={explainer}
          onClose={onCloseExplainerDialog}
          onCurrencyChange={setCurrency}
        />
      )}

      <CardHeader
        avatar={
          <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/invoices`} size="large">
            <BackIcon color="primary" />
          </IconButton>
        }
        title={xsDown ? "" : "Invoice Details"}
        subheader={xsDown ? "" : customer.name}
        action={
          <Grid container spacing={1} sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <Stack direction="row">
                <Tooltip title={!explainer ? "The explainer data for this invoice isn't available yet" : ""}>
                  <div>
                    <Button variant="outlined" disabled={!explainer} onClick={onOpenExplainerDialog}>
                      Understand this invoice
                    </Button>
                  </div>
                </Tooltip>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                  <InvoicesFaqButton />
                  <Button
                    href={invoicesText.INVOICE_HELP_URL}
                    target="_blank"
                    variant="text"
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                      },
                    }}
                    endIcon={<OpenNewIcon sx={{ fontSize: 14 }} />}
                  >
                    {invoicesText.LEARN_MORE}
                  </Button>
                </Stack>
              </Stack>
            </Grid>

            {isDoitEmployee && isWireTransfer && rowData._STATUS_ID !== 1 && rowData._STATUS_ID !== 7 && (
              <Grid item>{snoozeButton}</Grid>
            )}

            {userRoles.billingProfilesAdmin &&
              userRoles.invoicesViewer &&
              !rowData.PAID &&
              !rowData.CANCELED &&
              rowData._STATUS_ID !== 7 &&
              rowData._BALANCE > 0 && (
                <Tooltip
                  title={
                    priorityErpStatus.available === false
                      ? "This action is temporarily unavailable. We apologize for any inconvenience"
                      : ""
                  }
                >
                  <Grid item>
                    <LoadingButton
                      key="pay-now"
                      variant="contained"
                      aria-label="Pay Invoice Now"
                      color="primary"
                      size="small"
                      onClick={onOpenPayDialog}
                      className={classes.actionButton}
                      disabled={rowData.stripeLocked || priorityErpStatus.available === false}
                      loading={false}
                      startIcon={<PayIcon />}
                      mixpanelEventId="invoices.details.pay"
                    >
                      Pay now
                    </LoadingButton>
                  </Grid>
                </Tooltip>
              )}
          </Grid>
        }
      />

      {invoices?.length > 0 && (
        <CardActions className={classes.cardActions}>
          <Tooltip title="Previous invoice" placement="right">
            <IconButton onClick={navigateInvoices(-1)} color="primary" size="large" data-cy="navigate-previous-invoice">
              <DoubleArrowIcon className={classes.mirrored} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next invoice" placement="left">
            <IconButton onClick={navigateInvoices(1)} color="primary" size="large" data-cy="navigate-next-invoice">
              <DoubleArrowIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      )}

      <CardContent className={classes.cardContent}>
        <Toolbar disableGutters>
          <Grid container spacing={1}>
            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Invoice Number:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1" data-cy="ivnum">
                  {rowData.IVNUM}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Billing Profile:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1" data-cy="billing-profile">
                  {rowData.CDES}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Billing Profile ID:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1" data-cy="billing-profile-id">
                  {rowData.CUSTNAME}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Fragment>
                <Grid item xs={6} lg={5}>
                  <Typography variant="button">Invoice Details:</Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                  <Typography variant="body1" data-cy="details">
                    {rowData.DETAILS || "N/A"}
                  </Typography>
                </Grid>
              </Fragment>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Invoice Date:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1">
                  {sanitizeDate(DateTime.fromJSDate(rowData.IVDATE.toDate())).toLocaleString({
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Due Date:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1">
                  {rowData._STATUS === "Proforma"
                    ? "-"
                    : sanitizeDate(DateTime.fromJSDate(rowData.PAYDATE.toDate())).toLocaleString({
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Status:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                {rowData._SHOW_BADGE ? (
                  <Badge variant="dot" classes={{ badge: classes.badge }}>
                    <Typography variant="body1" className={rowData.statusClassName} data-cy="status">
                      {rowData._ICON} {rowData._STATUS}
                    </Typography>
                  </Badge>
                ) : (
                  <Typography variant="body1" className={rowData.statusClassName} data-cy="status">
                    {rowData._ICON} {rowData._STATUS}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Amount w/o Tax:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1" data-cy="amount">
                  {formatCurrency(rowData.QPRICE, rowData.CODE)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} lg={4}>
              <Grid item xs={6} lg={5}>
                <Typography variant="button">Balance:</Typography>
              </Grid>
              <Grid item xs={6} lg={7}>
                <Typography variant="body1" data-cy="balance">
                  {rowData._STATUS === "Proforma" ? "-" : formatCurrency(rowData._BALANCE, rowData.CODE)}
                </Typography>
              </Grid>
            </Grid>

            {(rowData.EXTFILES?.length ?? 0) > 0 && (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="button">Documents:</Typography>
                </Grid>
                <Grid item xs={12}>
                  {rowData
                    .EXTFILES!.filter((extFile) => extFile.url !== null)
                    .map((extFile) => (
                      <Chip
                        icon={<AttachmentIcon />}
                        color="primary"
                        variant="outlined"
                        key={extFile.key}
                        label={extFile.key}
                        className={classes.chip}
                        component="button"
                        onClick={handleDownloadInvoice(extFile, errorSnackbar)}
                        clickable
                      />
                    ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </CardContent>
      {invoice?.isDraft && (
        <Alert severity="info" sx={{ mb: 3, mt: 6, mx: 3 }}>
          This is a proforma invoice, which is a preliminary bill based on the data available until the current day of
          the month. We use the current exchange rate, which may differ in the final invoice.
          <br />
          This invoice is purely informational, you DO NOT need to pay it.
        </Alert>
      )}

      <Divider />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.headerTableRow}>
              {filteredColumns.map((column) => (
                <Hide {...column.hidden} key={column.id}>
                  <TableCell
                    align={column.align}
                    sortDirection={sorting.active === column.id ? sorting.direction : undefined}
                  >
                    <Tooltip title={column.tooltip} enterDelay={150}>
                      <TableSortLabel
                        active={sorting.active === column.id}
                        direction={sorting.direction}
                        onClick={handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </Hide>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(rowData.INVOICEITEMS, [sorting.active], [sorting.direction])
              .slice(paging.page * paging.rowsPerPage, paging.page * paging.rowsPerPage + paging.rowsPerPage)
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left" data-cy="description">
                    {item.PDES}
                  </TableCell>
                  <Hide smDown>
                    <TableCell align="left" data-cy="details">
                      {item.DETAILS || "-"}
                      {rowData._STATUS === "Proforma" && item.DETAILSSUFFIX}
                    </TableCell>
                    <TableCell align="left" data-cy="sku">
                      {item.PARTNAME}
                    </TableCell>
                    <TableCell align="right" data-cy="units">
                      {item.QUANT}
                    </TableCell>
                    <TableCell align="right" data-cy="ppu">
                      {formatCurrency(item.PRICE, item.ICODE)}
                    </TableCell>
                    <TableCell align="right" data-cy="rate">
                      {item.EXCH && item.ICODE !== rowData.CODE ? item.EXCH : 1}
                    </TableCell>
                    {invoiceHasDiscount && (
                      <TableCell align="right" data-cy="discount">
                        {item.PERCENT}%
                      </TableCell>
                    )}
                  </Hide>
                  <TableCell align="right" data-cy="price">
                    {formatCurrency(item.QPRICE, rowData.CODE)}
                  </TableCell>
                </TableRow>
              ))}

            {rowData.VAT ? (
              <>
                <TableRow className={classes.footerTableRow}>
                  <Hide smDown>
                    <TableCell rowSpan={3} />
                  </Hide>
                  <TableCell colSpan={summaryColSpan}>TOTAL</TableCell>
                  <TableCell align="right">{formatCurrency(rowData.QPRICE, rowData.CODE)}</TableCell>
                </TableRow>

                <TableRow className={classes.footerTableRow}>
                  <TableCell colSpan={summaryColSpan}>TAX</TableCell>
                  <TableCell align="right">{formatCurrency(rowData.VAT, rowData.CODE)}</TableCell>
                </TableRow>

                <TableRow className={classes.footerTableRow}>
                  <TableCell colSpan={summaryColSpan}>TOTAL W/ TAX</TableCell>
                  <TableCell align="right">{formatCurrency(rowData.TOTPRICE, rowData.CODE)}</TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow className={classes.footerTableRow}>
                <Hide mdDown>
                  <TableCell rowSpan={1} />
                </Hide>
                <TableCell colSpan={summaryColSpan}>TOTAL</TableCell>
                <TableCell align="right">{formatCurrency(rowData.TOTPRICE, rowData.CODE)}</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={filteredColumns.length}
                count={rowData.INVOICEITEMS.length}
                rowsPerPage={paging.rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
                page={paging.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="per page"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
