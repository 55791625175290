import {
  CustomerModel,
  type InsightThreadData,
  type JiraTargetThreadData,
  ThreadModel,
  UserModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export type SaveThreadInput = {
  customerID: string;
  reporterID: string;
  insightKey: string;
  providerID: string;
  jiraIssueURL: string;
  jiraIssueID: string;
  jiraIssueKey: string;
};

export async function saveThread(input: SaveThreadInput): Promise<void> {
  const newThread = getCollection(ThreadModel).newDoc();

  const sourceData: InsightThreadData = {
    insightKey: input.insightKey,
    providerID: input.providerID,
  };

  const targetData: JiraTargetThreadData = {
    issueID: input.jiraIssueID,
    issueKey: input.jiraIssueKey,
    issueURL: input.jiraIssueURL,
  };

  await newThread.set({
    customer: getCollection(CustomerModel).doc(input.customerID),
    reporter: getCollection(UserModel).doc(input.reporterID),
    source: "insight",
    sourceData,
    target: "jira",
    targetData,
    isDeleted: false,
  });
}

export async function deleteThread(threadID: string): Promise<void> {
  const thread = getCollection(ThreadModel).doc(threadID);

  await thread.update({
    isDeleted: true,
  });
}
