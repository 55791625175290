import { type StandaloneOnboardingAWS } from "@doitintl/cmp-models";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

const NEW_ESTIMATE_VALUE = "new-estimate";

type Props = {
  currentAccountId?: string;
  accounts: StandaloneOnboardingAWS[];
  onAccountChanged: (accountId?: string) => void;
};

export const AccountSelection = ({ currentAccountId = NEW_ESTIMATE_VALUE, accounts, onAccountChanged }: Props) => {
  const handleSelectionChange = (event: SelectChangeEvent) => {
    onAccountChanged(event.target.value);
  };

  const renderOptions = () => {
    if (accounts.length === 0) {
      return null;
    } else {
      return (
        <Stack direction="row" width="100%" spacing={2}>
          <FormControl data-cy="accounts-selection" sx={{ minWidth: 250 }} size="small">
            <InputLabel>Account ID</InputLabel>
            <Select value={currentAccountId} label="Account ID" onChange={handleSelectionChange}>
              {currentAccountId === NEW_ESTIMATE_VALUE && (
                <MenuItem value={NEW_ESTIMATE_VALUE}>
                  <Box sx={{ opacity: 0.4 }}>New estimate</Box>
                </MenuItem>
              )}

              {accounts.map((account) => (
                <MenuItem key={account.accountId} value={account.accountId}>
                  {account.accountId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {currentAccountId !== NEW_ESTIMATE_VALUE && (
            <Button data-cy="start-estimate" onClick={() => onAccountChanged()}>
              Start another estimate
            </Button>
          )}
        </Stack>
      );
    }
  };

  return renderOptions();
};
