import { Divider, Stack, type SxProps, type Theme, Typography } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

const UnlockSupportOptionsDropdownBox = ({ sx }: Props) => (
  // const { customer } = useCustomerContext();
  // const history = useHistory();
  <>
    <Divider />
    <Stack sx={sx} display="flex" justifyContent="center">
      <Typography textAlign="center" fontWeight={400}>
        Unlock all support options
      </Typography>
      {/* <Link
          variant="body1"
          textAlign="center"
          underline="none"
          sx={{ cursor: "pointer" }}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => history.push(`/customers/${customer.id}/solve-onboarding`)}
        >
          You can pay for a single CRE ticket or upgrade your plan
        </Link> */}
      <Typography textAlign="center" fontWeight={400}>
        Contact your Account Manager to upgrade your Solve plan
      </Typography>
    </Stack>
  </>
);

export default UnlockSupportOptionsDropdownBox;
