import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { type RouteComponentProps } from "react-router";
import { TierPackageTypes, type UserModel } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import Hide from "../../Components/HideChildren/Hide";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import TierTrialEndAlert from "../../Navigation/Components/HeaderAlerts/TrialEnd/TierTrialEndAlert";
import { getCustomerTierTrialDates } from "../../utils/tiers";
import {
  downloadTickets,
  initTicketList,
  loadTickets,
  sendNewDefaultEmailCC,
  toggleOrganizationSharing,
} from "../Actions/TicketAction";
import DefaultEmailCCDialog from "../Components/DefaultEmailCCDialog";
import { HeaderCards } from "../Components/HeaderCards";
import { PlatformFilters } from "../Components/PlatformFilters";
import { SettingsButton } from "../Components/SettingsButton";
import SnackbarLoader from "../Components/SnackbarLoader";
import TicketsFilterTable from "../Components/TicketsFilterTable/TicketsFilterTable";
import { ticketsCsvFileName } from "../consts";
import { useFilterCloud } from "../hooks/useFilterCloud";
import { type Organization, type Ticket, type User } from "../types";

type Params = {
  customerId: string;
};

type FunctionProps = {
  loadTickets: ({ isDoitEmployee, userId }: { isDoitEmployee: boolean; userId: string }) => void;
  downloadTickets: () => void;
  initTicketList: () => void;
  toggleOrganizationSharing: (org: Organization) => void;
  sendNewDefaultEmailCC: (data: string[]) => void;
};

type StateProps = {
  ticketList: Ticket[];
  isFetchingTicketList: boolean;
  organization: Organization;
  userList: User[];
  isDownloadingCSV: boolean;
  defaultEmailsList: string[];
  customerUsers: UserModel[];
};

type Props = RouteComponentProps<Params> & FunctionProps & StateProps;

const TicketsContainer = ({
  ticketList,
  isFetchingTicketList,
  organization,
  userList,
  isDownloadingCSV,
  defaultEmailsList,
  customerUsers,
  loadTickets,
  downloadTickets,
  initTicketList,
  toggleOrganizationSharing,
  sendNewDefaultEmailCC,
  match,
  history,
}: Props) => {
  const { currentUser, isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext();
  const { isActiveTrialCustomer } = useTier();

  const { t } = useTranslation("services");

  const [isDefaultEmailsDialogOpen, setIsDefaultEmailsDialogOpen] = useState(false);

  //* ******************* Paginate ****************** */

  const currentTicketList = useMemo(
    () =>
      ticketList
        .map((ticket) => {
          ticket.requester = userList.find((u) => ticket.requester_id === u.id)?.email ?? "";
          return ticket;
        })
        .filter((ticket) => ticket.is_public || isDoitEmployee),
    [ticketList, userList, isDoitEmployee]
  );

  //* ********************************************* */
  useEffect(() => {
    if (customer.id !== match.params.customerId) {
      initTicketList();
    }
    loadTickets({ isDoitEmployee, userId });
  }, [customer.id, initTicketList, loadTickets, match.params.customerId, isDoitEmployee, userId]);

  const isCurrentUserUserManager = useMemo(() => !!userRoles?.usersManager || !!userRoles?.doitEmployee, [userRoles]);

  const createNewRequest = () => history.push(`support/new`);

  // exclude tickets for ava conversations
  const prefiltered = useMemo(
    () => currentTicketList.filter((t) => !t.description.includes("created a new chat called")),
    [currentTicketList]
  );

  const { filteredTickets, onClickFilter, selectedFilter, platformsAvailable } = useFilterCloud(prefiltered);

  const { start, end } = getCustomerTierTrialDates(customer, TierPackageTypes.SOLVE);

  return (
    <>
      <HeaderCards
        onClickOpenRequest={createNewRequest}
        orgClassification={organization.organization_fields?.classification}
        customerClassification={customer?.classification}
      />
      <Container maxWidth="xl">
        {isActiveTrialCustomer(TierPackageTypes.SOLVE) && end && (
          <TierTrialEndAlert solveTrialStartDate={start} solveTrialEndDate={end} />
        )}
        <Snackbar
          open={isDownloadingCSV}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert icon={false} elevation={6} variant="filled" severity="info">
            Downloading {ticketsCsvFileName} ...
          </Alert>
        </Snackbar>

        <Stack justifyContent="space-between" direction="row" alignItems="center" pt={5}>
          <Typography variant="h3" fontWeight="500">
            {t("TicketsContainer.title")}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <PlatformFilters
              onClickFilter={onClickFilter}
              selectedFilter={selectedFilter}
              platformFilters={platformsAvailable}
            />
            {userRoles?.billingProfilesAdmin && (
              <Hide smDown>
                <SettingsButton
                  toggleOrganizationSharing={toggleOrganizationSharing}
                  organization={organization}
                  downloadTickets={downloadTickets}
                  setIsDefaultEmailsDialogOpen={setIsDefaultEmailsDialogOpen}
                  isCurrentUserUserManager={!!isCurrentUserUserManager}
                  loadTickets={loadTickets}
                />
              </Hide>
            )}
          </Stack>
        </Stack>
        <TicketsFilterTable
          isDoitEmployee={isDoitEmployee}
          currentUser={currentUser}
          currentTicketList={filteredTickets}
          customerClassification={customer?.classification}
          customerId={customer?.id}
          loading={isFetchingTicketList}
        />

        <DefaultEmailCCDialog
          users={customerUsers}
          open={isDefaultEmailsDialogOpen}
          onClose={() => setIsDefaultEmailsDialogOpen(false)}
          defaultEmailsListFromDB={defaultEmailsList}
          sendNewDefaultEmailCC={sendNewDefaultEmailCC}
        />

        <SnackbarLoader text="Loading requests" open={isFetchingTicketList} />
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadTickets: ({ isDoitEmployee, userId }: { isDoitEmployee: boolean; userId: string }) =>
    dispatch(loadTickets({ isDoitEmployee, userId })),
  downloadTickets: () => dispatch(downloadTickets()),
  initTicketList: () => dispatch(initTicketList()),
  toggleOrganizationSharing: (org) => dispatch(toggleOrganizationSharing(org)),
  sendNewDefaultEmailCC: (data) => dispatch(sendNewDefaultEmailCC(data)),
});

type TicketsStateProps = {
  tickets: StateProps;
};

const mapStateToProps = (state: TicketsStateProps) => ({
  ticketList: state.tickets.ticketList,
  isFetchingTicketList: state.tickets.isFetchingTicketList,
  organization: state.tickets.organization,
  userList: state.tickets.userList,
  isDownloadingCSV: state.tickets.isDownloadingCSV,
  defaultEmailsList: state.tickets.defaultEmailsList,
  customerUsers: state.tickets.customerUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsContainer);
