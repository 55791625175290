import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark as dark, twilight as light } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Link } from "@mui/material";
import remarkGfm from "remark-gfm";

type Props = {
  line: string;
  isDarkMode: boolean;
};

export const CustomMarkdown = ({ line, isDarkMode }: Props) => (
  <ReactMarkdown
    linkTarget="_blank"
    remarkPlugins={[remarkGfm]}
    components={{
      a: ({ ...props }) => <Link component="a" target="_blank" {...props} />,
      code({ inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter {...props} style={isDarkMode ? dark : light} language={match[1]} PreTag="div">
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code {...props} className={className}>
            {children}
          </code>
        );
      },
    }}
  >
    {line}
  </ReactMarkdown>
);
