import { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import { DoitRole, type PerkModel, TierPackageTypes } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Avatar, Box, Button, Container, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useTier } from "../../../Context/TierProvider";
import { useMarketplaceListingHandler } from "../hooks";
import { RegisterInterestFromType } from "../types";
import { getHelperText } from "../utils";
import { type SectionTitles } from "./index";
import { MarketplaceListingButton } from "./MarketplaceListingButton";
import { PerkNavBar } from "./PerkNavBar";
import { RegisterInterestButton } from "./RegisterInterestButton";

type Props = {
  isDoitEmployee: boolean;
  disabledPerk: boolean;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
  perk: PerkModel;
  containerWidth: number;
  sectionTitles: SectionTitles;
  backTo: string;
};

export const PerkHeader = ({
  isDoitEmployee,
  disabledPerk,
  registerInterest,
  perk,
  containerWidth,
  sectionTitles,
  backTo,
}: Props) => {
  const isPerksAdmin = useDoitRoleCheck(DoitRole.PerksAdmin);
  const pathname = useLocation().pathname?.replace(/\/+$/, "");
  const perkId = pathname.substring(pathname.lastIndexOf("/") + 1);
  const editUrl = `${pathname.substring(0, pathname.lastIndexOf("/"))}/edit/${perkId}`;
  const theme = useTheme();
  const onRegisterInterest = useMarketplaceListingHandler(registerInterest);
  const { getCustomerTier } = useTier();

  const solveTier = getCustomerTier(TierPackageTypes.SOLVE);

  const infoText = useMemo<string | undefined>(() => getHelperText(perk, solveTier), [perk, solveTier]);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        zIndex: 1014,
        marginLeft: "-16px",
        paddingTop: "24px",
      }}
    >
      <Container
        sx={{
          width: containerWidth,
          padding: "0px !important",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", ml: "-55px", mt: "7px" }}
          aria-label="Back"
          component={Link}
          to={backTo}
          size="large"
        >
          <BackIcon sx={{ color: "text.primary" }} />
        </IconButton>

        <Box display="inline-flex" alignItems="center" sx={{ height: 64 }}>
          {!!perk.enrichment?.logoUrl && (
            <Avatar
              alt="logo"
              src={perk.enrichment?.logoUrl}
              imgProps={{ sx: { objectFit: "contain", bgcolor: "white" } }}
              sx={{ width: 64, height: 64, border: `0.5px solid ${theme.palette.grey[300]}` }}
            />
          )}
          <Typography variant="body1" sx={{ ml: "8px" }}>
            {perk.fields?.title || perk.fields?.name}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h1" sx={{ mt: "18px", mb: "8px" }}>
            {perk.fields?.name}
          </Typography>

          {isPerksAdmin && (
            <Button component={Link} to={editUrl} color="primary" sx={{ marginTop: "8px" }}>
              <EditIcon color="primary" sx={{ width: "20px", height: "20px" }} />
              <span style={{ marginLeft: "5px", marginTop: "2px" }}>Edit</span>
            </Button>
          )}
        </Box>

        {perk.fields?.shortDescription ? (
          <Typography variant="body2" component="div" sx={{ color: "text.secondary" }}>
            {perk.fields?.shortDescription}
          </Typography>
        ) : (
          <Box height="28px" />
        )}

        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" sx={{ py: 3 }}>
          <RegisterInterestButton
            isDoitEmployee={isDoitEmployee}
            disabledPerk={disabledPerk}
            registerInterest={() => registerInterest(RegisterInterestFromType.InterestButton)}
            perkType={perk.fields.type}
          />

          {!!infoText && (
            <Stack direction="row" component={Typography} variant="body2">
              <InfoOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography component="span">{infoText}</Typography>
            </Stack>
          )}

          {Object.entries(perk.fields?.platformUrl || {}).map(([platform, url]) => (
            <MarketplaceListingButton
              key={platform}
              platform={platform}
              handler={() => onRegisterInterest(RegisterInterestFromType[platform], url)}
            />
          ))}
        </Stack>
      </Container>
      <PerkNavBar containerWidth={containerWidth} sectionTitles={sectionTitles} />
    </Box>
  );
};
