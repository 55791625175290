import { useCallback, useEffect, useRef, useState } from "react";

import { useParams } from "react-router";
import { CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  addEdge,
  type Connection,
  type Edge,
  getIncomers,
  getOutgoers,
  type Node,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { CHOICE_OPTIONS } from "../../Dialog/ManageIfNodeDialog";
import { EDGE_TYPE, type Position } from "../../types";
import utils from "../utils/conditionNodeUtils";
import { rearrangeEdgesWhenDeleteNode, rearrangeNodesWhenDeleteNode } from "../utils/deleteUtils";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "../utils/edgeUtils";
import { createConditionNodes, getNode, updateNodePositions } from "../utils/nodeUtils";
import { actionStepNodeId, ghostNodeId, initialNodes, startStepNodeId } from "./consts";
import { useCloudflowNodes } from "./useCloudflowNodes";

export const useNodeEdgeManager = () => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { cloudflowNodes, cloudflowEdges, cloudflowNodesLoading } = useCloudflowNodes(flowId);
  const store = useStoreApi();
  const handleAddRef = useRef<(nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => void>();
  const handleDeleteRef = useRef<(nodeId: string) => Promise<void>>();
  const handleAddActionStepRef = useRef<(sourceNode: Node, targetNode: Node, edgeData: Edge) => void>();

  const [activeNode, setActiveNode] = useState<Node>();
  const showSuccess = useSuccessSnackbar(3);
  const [showStepper, setShowStepper] = useState(false);
  const [manageIfActionsId, setManageIfActionsId] = useState<string>("");

  const { getEdges, getNodes, deleteElements } = useReactFlow();

  const removeTreeOfOutgoers = useCallback(
    (id: string) => {
      const outgoers = getOutgoers({ id } as Node, getNodes(), getEdges());

      if (outgoers.length) {
        deleteElements({ nodes: outgoers }).then(void 0);

        outgoers.forEach((outgoer) => {
          removeTreeOfOutgoers(outgoer.id);
        });
      }
    },
    [deleteElements, getEdges, getNodes]
  );
  const [deleteIfNodeId, setDeleteIfNodeId] = useState<string>("");

  const handleEditNode = useCallback((node) => {
    setActiveNode(node);
  }, []);

  const rearrangeFlowOnDelete = useCallback(
    async (nodeId: string) => {
      const { nodes, edges } = store.getState();
      const nodeToDelete = nodes.find((node) => node.id === nodeId);
      if (!nodeToDelete) return;
      const [incomer] = getIncomers(nodeToDelete, nodes, edges);

      if (incomer.type === CloudFlowNodeType.CONDITION) {
        const { newNodes, newEdges } = await utils.rearrangeFlowOnDeleteIfNode({
          nodeId,
          nodes,
          edges,
          handleDeleteRef,
          handleAddRef,
          deleteElements,
        });

        setNodes((prevNodes) => [...prevNodes, ...newNodes]);
        setEdges((prevEdges) => [...prevEdges, ...newEdges]);
      } else {
        setNodes((prevNodes) => rearrangeNodesWhenDeleteNode(prevNodes, nodeId));
        setEdges((prevEdges) => rearrangeEdgesWhenDeleteNode(prevEdges, nodeId));
      }
    },
    [deleteElements, setEdges, setNodes, store]
  );

  const handleDeleteNode = useCallback(
    async (nodeId: string) => {
      setActiveNode(undefined);
      const { nodes } = store.getState();
      const nodeToDelete = nodes.find((node) => node.id === nodeId);
      if (!nodeToDelete) return;

      if (nodeToDelete.type === CloudFlowNodeType.CONDITION) {
        setDeleteIfNodeId(nodeId);
        return;
      }

      await rearrangeFlowOnDelete(nodeId);

      showSuccess("Step successfully deleted");
    },
    [rearrangeFlowOnDelete, showSuccess, store]
  );

  const doesNodeHaveChildren = useCallback(
    (nodeId: string) => {
      const { nodes, edges } = store.getState();
      return utils.doesNodeHaveChildren(nodes, edges, nodeId);
    },
    [store]
  );

  // This function adds a node when any button is clicked in the action step card
  const handleAddNode = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => {
      if (nodeType === CloudFlowNodeType.ACTION_STEP) {
        setShowStepper(true);
      }

      const newNode = getNode(nodeType, nodeId, position);

      if (nodeType === CloudFlowNodeType.CONDITION) {
        if (doesNodeHaveChildren(nodeId)) {
          setManageIfActionsId(nodeId);
          return;
        }

        // TODO: may need modifications to allow adding the node in middle of the flow
        const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
          createConditionNodes(newNode, handleAddNode, handleDeleteNode);

        // Add the nodes and adjust other node positions if necessary
        setNodes((prevNodes) => {
          const updatedNodes = [...prevNodes, ...trueNodeTree, ...falseNodeTree];
          return updatedNodes.map((node: Node) => {
            if (node.id === nodeId) {
              return {
                ...newNode,
                data: {
                  ...newNode.data,
                  isActive: true,
                  onEditNode: () => handleEditNode(newNode),
                  onDeleteNode: () => handleDeleteNode(nodeId),
                },
              };
            }

            const requireNodePositionAdjustments =
              ![trueNodeId, falseNodeId, trueNodeGhostId, falseNodeGhostId].includes(node.id) &&
              node.position.y >= newNode.position.y;

            if (requireNodePositionAdjustments) {
              return updateNodePositions(node, 250, 250);
            }

            return node;
          });
        });

        // Reset and add new edges
        setEdges((prevEdges) => {
          // Remove any existing edges connected to the "If" node
          const filteredEdges = prevEdges.filter((edge) => edge.source !== nodeId);

          // Add edges to connect the "If" node to the "True" and "False" nodes
          return [
            ...filteredEdges,
            createTruePathEdge(nodeId, trueNodeId),
            createFalsePathEdge(nodeId, falseNodeId),
            createEdge(trueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST),
            createEdge(falseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST),
          ];
        });

        // Set the If node as active
        setActiveNode(newNode);
      } else {
        setNodes((prevNodes) =>
          prevNodes.map((node: Node) =>
            node.id === nodeId
              ? {
                  ...newNode,
                  data: {
                    ...newNode.data,
                    isActive: true,
                    onEditNode: () => handleEditNode(newNode),
                    onDeleteNode: () => handleDeleteNode(nodeId),
                  },
                }
              : node
          )
        );
      }
      setActiveNode(newNode);
    },
    [doesNodeHaveChildren, handleDeleteNode, setNodes, setEdges, handleEditNode]
  );

  const onConfirmDeleteIfNode = useCallback(async () => {
    setActiveNode(undefined);
    const { nodes, edges } = store.getState();
    const childNodes = utils.getNodeDescendants(nodes, edges, deleteIfNodeId);
    const nodeToDelete = nodes.find((node) => node.id === deleteIfNodeId);
    if (!nodeToDelete) return;

    await handleDeleteRef.current?.(deleteIfNodeId);
    const [incomer] = getIncomers(nodeToDelete, nodes, edges);
    if (incomer.type === CloudFlowNodeType.CONDITION) {
      const { newNodes, newEdges } = utils.handleDeleteIfNodeParent({
        currentNode: nodeToDelete,
        incomer,
        nodes,
        edges,
        handleDeleteRef,
        handleAddRef,
      });

      setNodes((prevNodes) => [
        ...prevNodes.filter((n) => !childNodes.includes(n) && n.id !== deleteIfNodeId),
        ...newNodes,
      ]);
      setEdges((prevEdges) => [
        ...prevEdges.filter((e) => !childNodes.map((n) => n.id).includes(e.source) && e.source !== deleteIfNodeId),
        ...newEdges,
      ]);
    } else {
      const newGhostNode = {
        id: uuidv4(),
        type: "ghost",
        position: { x: nodeToDelete.position.x, y: nodeToDelete.position.y },
        data: {},
      };

      const newEdge = createEdge(incomer.id, newGhostNode.id, EDGE_TYPE.GHOST);
      const newNodes = nodes.filter((node) => !childNodes.includes(node) && node.id !== deleteIfNodeId);
      const newEdges = edges.filter((edge) => !childNodes.map((node) => node.id).includes(edge.source));
      setEdges([...newEdges, newEdge]);
      setNodes([...newNodes, newGhostNode]);
    }

    setDeleteIfNodeId("");
    showSuccess("Step successfully deleted");
  }, [store, deleteIfNodeId, showSuccess, setNodes, setEdges]);

  // This function adds a new action step card between source and target
  const handleAddActionStepCard = useCallback(
    (sourceNode: Node, targetNode: Node, edgeData: Edge) => {
      const newNodePosition = {
        x: sourceNode.position.x,
        y: targetNode.position.y,
      };

      const newNodeId = uuidv4();
      const newNode = {
        id: newNodeId,
        type: CloudFlowNodeType.ACTION_STEP,
        data: {
          name: "What do you want to do?",
          onAddNode: handleAddNode,
          onDeleteNode: () => handleDeleteNode(newNode.id),
          position: newNodePosition,
        },
        position: newNodePosition,
      };

      setNodes((prevNodes) => {
        const updatedNodes: Node[] = [...prevNodes, newNode];
        return updatedNodes.map((node: Node) => {
          if (node.id !== newNode.id && node.position.y >= newNode.position.y) {
            return updateNodePositions(node, 250, 75);
          }
          return node;
        });
      });
      setEdges((prevEdges) => [
        ...prevEdges.filter((edge) => edge.id !== edgeData.id),
        createEdge(sourceNode.id, newNode.id),
        createEdge(
          newNode.id,
          targetNode.id,
          targetNode.type === CloudFlowNodeType.GHOST ? EDGE_TYPE.GHOST : EDGE_TYPE.CUSTOM
        ),
      ]);
      showSuccess("Step successfully added");
    },
    [handleAddNode, setNodes, setEdges, handleDeleteNode, showSuccess]
  );

  // Set the initial nodes and edges
  useEffect(() => {
    if (cloudflowNodesLoading) {
      return;
    }
    if (!cloudflowNodes || cloudflowNodes.length === 0) {
      const updatedNodes = initialNodes.map((node) => {
        if (node.type === CloudFlowNodeType.START_STEP) {
          return {
            ...node,
            data: {
              ...node.data,
              onAddNode: handleAddNode,
            },
          };
        }
        if (node.type === CloudFlowNodeType.ACTION_STEP) {
          return {
            ...node,
            data: {
              ...node.data,
              onAddNode: handleAddNode,
              onDeleteNode: () => handleDeleteNode(actionStepNodeId),
            },
          };
        }
        return node;
      });

      const initialEdges = [
        createEdge(startStepNodeId, actionStepNodeId),
        createEdge(actionStepNodeId, ghostNodeId, EDGE_TYPE.GHOST),
      ];
      setNodes(updatedNodes);
      setEdges(initialEdges);
    } else {
      // TODO: need to handle the sync in firestore and local state changes
      const uiEnrichedNodes = cloudflowNodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          onAddNode: handleAddNode,
          onDeleteNode: () => handleDeleteNode(node.id),
          onEditNode: () => handleEditNode(node),
        },
      }));
      setNodes(uiEnrichedNodes);
      setEdges(cloudflowEdges);
    }
  }, [
    cloudflowNodesLoading,
    cloudflowNodes,
    cloudflowEdges,
    handleAddNode,
    handleDeleteNode,
    setNodes,
    setEdges,
    handleEditNode,
  ]);

  // Manage active node state
  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node: Node) =>
        node.id === activeNode?.id
          ? {
              ...node,
              data: {
                ...node.data,
                isActive: true,
              },
            }
          : {
              ...node,
              data: {
                ...node.data,
                isActive: false,
              },
            }
      )
    );
  }, [activeNode, setNodes]);

  // these refs are to avoid circular dependencies, so every action is a ref ad could be accessible no matter where it is defined or called
  useEffect(() => {
    handleAddRef.current = handleAddNode;
    handleDeleteRef.current = handleDeleteNode;
    handleAddActionStepRef.current = handleAddActionStepCard;
  }, [handleAddActionStepCard, handleAddNode, handleDeleteNode]);

  const handleDeleteActions = useCallback(
    (manageIfActionsId: string) => {
      const { nodes, edges } = store.getState();
      const { newNodes, newEdges, newNode } = utils.handleDeleteActions({
        nodes,
        edges,
        manageIfActionsId,
        handleAddNode,
        handleDeleteNode,
        handleEditNode,
        removeTreeOfOutgoers,
      });

      setNodes(newNodes);
      setEdges(newEdges);
      setActiveNode(newNode);
    },
    [handleAddNode, handleDeleteNode, handleEditNode, removeTreeOfOutgoers, setEdges, setNodes, store]
  );

  const handleMoveActions = useCallback(
    (manageIfActionsId: string, moveToTrue: boolean) => {
      const { nodes, edges } = store.getState();
      const { newNodes, newEdges, newNode } = utils.handleMoveActions({
        nodes,
        edges,
        manageIfActionsId,
        moveToTrue,
        handleAddNode,
        handleDeleteNode,
        handleEditNode,
      });

      setNodes(newNodes);
      setEdges(newEdges);
      setActiveNode(newNode);
    },
    [handleAddNode, handleDeleteNode, handleEditNode, setEdges, setNodes, store]
  );

  const onSaveManageIfActionsDialog = useCallback(
    (choice: string) => {
      const { nodes } = store.getState();
      const currentNode = nodes.find((node) => node.id === manageIfActionsId);

      if (!currentNode) {
        return;
      }

      const { type: nodeType, id: nodeId, position } = currentNode;

      if (!nodeType || !nodeId || !position) {
        return;
      }

      switch (choice) {
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE:
          handleMoveActions(manageIfActionsId, true);
          break;
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE:
          handleMoveActions(manageIfActionsId, false);
          break;
        case CHOICE_OPTIONS.DELETE_ACTIONS:
          handleDeleteActions(manageIfActionsId);
          break;
      }

      setManageIfActionsId("");
    },
    [handleDeleteActions, handleMoveActions, manageIfActionsId, store]
  );

  const onEdgeClick = useCallback(
    (_event, edgeData: Edge) => {
      const sourceNode = nodes.find((node) => node.id === edgeData.source);
      const targetNode = nodes.find((node) => node.id === edgeData.target);
      if (sourceNode && targetNode && edgeData.type !== EDGE_TYPE.CONDITION) {
        handleAddActionStepCard(sourceNode, targetNode, edgeData);
      }
    },
    [nodes, handleAddActionStepCard]
  );

  return {
    nodes,
    setNodes,
    edges,
    setEdges,
    activeNode,
    setActiveNode,
    onNodesChange,
    onEdgesChange,
    handleEditNode,
    handleAddActionStepCard,
    onConnect: useCallback((params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)), [setEdges]),
    onEdgeClick,
    showStepper,
    closeStepper: () => setShowStepper(false),
    onConfirmDeleteIfNode,
    deleteIfNodeId,
    setDeleteIfNodeId,
    manageIfActionsId,
    setManageIfActionsId,
    onSaveManageIfActionsDialog,
  };
};
