import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomerModel, IntegrationModel, SandboxStatus } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CreateIcon from "@mui/icons-material/AddRounded";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { Button, Grid, Link, Tooltip } from "@mui/material";

import { globalText, sandboxText } from "../../assets/texts";
import DeleteDialog from "../../Components/DeleteDialog";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import SandboxCreateDialog from "./SandboxCreateDialog";
import SandboxList from "./SandboxList";
import SandboxPolicyDialog from "./SandboxPolicyDialog";
import { type SandboxAccountWRef, type SandboxPolicyWRef } from "./types";

const Sandbox = () => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();
  const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sandboxes, setSandboxes] = useState<SandboxAccountWRef[]>([]);
  const [selectedSandboxes, setSelectedSandboxes] = useState<SandboxAccountWRef[]>([]);
  const [policies, setPolicies] = useState<SandboxPolicyWRef[]>([]);

  useEffect(() => {
    const fetchSandboxes = () => {
      let query = getCollection(IntegrationModel)
        .doc("google-cloud")
        .collection("sandboxAccounts")
        .where("customer", "==", customer.ref);

      if (!(userRoles.sandboxAdmin || isDoitEmployee)) {
        query = query.where("email", "==", currentUser.email);
      }

      return query.onSnapshot((querySnapshot) => {
        setSandboxes((sandboxes) => {
          const newSandboxes = [...sandboxes];
          arrayFromDocChange(newSandboxes, querySnapshot, (doc) => ({
            data: doc.asModelData(),
            ref: doc.modelRef,
          }));
          return newSandboxes;
        });
      });
    };

    const fetchPolicy = () =>
      getCollection(CustomerModel)
        .doc(customer.id)
        .collection("sandboxPolicies")
        .where("type", "==", "google-cloud")
        .orderBy("timestamp", "desc")
        .onSnapshot((querySnapshot) => {
          setPolicies((policies) => {
            const newPolicies = [...policies];
            arrayFromDocChange(newPolicies, querySnapshot, (doc) => ({
              data: doc.asModelData(),
              ref: doc.modelRef,
            }));

            return newPolicies;
          });
        });

    const sandboxListener = fetchSandboxes();
    const policyListener = fetchPolicy();
    return () => {
      setSandboxes([]);
      sandboxListener();
      policyListener();
    };
  }, [customer, userRoles, currentUser.email, isDoitEmployee]);

  const policy = useMemo(() => policies?.[0] ?? null, [policies]);
  const currentMonth = new Date().toISOString().slice(0, 7);

  const currentUserSandboxes = useMemo(
    () =>
      sandboxes?.filter(
        (sandbox) =>
          sandbox.data.email === currentUser.email &&
          (sandbox.data.status === SandboxStatus.ACTIVE ||
            sandbox.data.status === SandboxStatus.ALERTED ||
            (sandbox.data.status === SandboxStatus.DISABLED && sandbox.data.utilization?.[currentMonth] > 0))
      ),
    [sandboxes, currentUser.email, currentMonth]
  );

  const maxSandboxesReached = policy?.data?.limit > 0 && currentUserSandboxes.length >= policy.data.limit;

  const deleteDisabled = !(userRoles?.sandboxAdmin || isDoitEmployee) || selectedSandboxes.length === 0;

  const handleDeleteSandboxAccounts = useCallback(async () => {
    const updatedSandboxes = selectedSandboxes.map((sandboxAccount) =>
      sandboxAccount.ref.update({ status: SandboxStatus.DELETED, email: currentUser.email })
    );
    try {
      await Promise.all(updatedSandboxes);
      showSuccessSnackbar(`Sandbox accounts successfully deleted`);
    } catch (err: any) {
      consoleErrorWithSentry(err);
      showErrorSnackbar(`Could not delete sandbox accounts: ${err.message}`);
    }
  }, [currentUser.email, selectedSandboxes, showErrorSnackbar, showSuccessSnackbar]);

  const createSandboxTooltip = isDoitEmployee
    ? sandboxText.EMPLOYEE_CANT_CREATE_SANDBOXES
    : maxSandboxesReached
      ? sandboxText.MAX_CAPACITY_REACHED
      : "";

  return (
    <>
      {policyDialogOpen && <SandboxPolicyDialog onClose={() => setPolicyDialogOpen(false)} policy={policy} />}
      {createDialogOpen && <SandboxCreateDialog onClose={() => setCreateDialogOpen(false)} policy={policy} />}

      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={sandboxText.DELETE_SANDBOX_TITLE}
          message={
            <span>
              {sandboxText.DELETE_SANDBOX_MESSAGE}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://cloud.google.com/resource-manager/docs/creating-managing-projects#shutting_down_projects"
                underline="always"
                color="primary"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                Google Cloud Docs.
                <OpenNewIcon fontSize="inherit" />
              </Link>
            </span>
          }
          onDelete={handleDeleteSandboxAccounts}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}

      <>
        <SandboxList policies={policies} sandboxes={sandboxes} setSelectedSandboxes={setSelectedSandboxes}>
          <Grid item>
            <Tooltip arrow disableHoverListener={!deleteDisabled} title="Only Sandbox admins can delete a sandbox">
              <span>
                <Button
                  disabled={deleteDisabled}
                  key="delete-sandbox"
                  color="primary"
                  aria-label="Delete sandbox project"
                  variant="outlined"
                  onClick={() => setDeleteDialogOpen(true)}
                  data-cy="delete-sandbox-button"
                >
                  Delete
                </Button>
              </span>
            </Tooltip>
          </Grid>

          <Grid item>
            {policy?.data.active && (
              <Tooltip arrow title={createSandboxTooltip}>
                <span>
                  <Button
                    key="create-sandbox"
                    color="primary"
                    aria-label="Create sandbox project"
                    variant="contained"
                    startIcon={<CreateIcon fontSize="small" />}
                    onClick={() => setCreateDialogOpen(true)}
                    disabled={maxSandboxesReached || isDoitEmployee}
                    data-cy="create-sandbox-button"
                  >
                    {sandboxText.CREATE_SANDBOX}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            {(userRoles.sandboxAdmin || isDoitEmployee) && (
              <Button
                key="set-policy"
                color="primary"
                aria-label="Set sandbox policy"
                variant="outlined"
                startIcon={<SettingsIcon fontSize="small" />}
                onClick={() => setPolicyDialogOpen(true)}
              >
                {policy?.data.active ? globalText.UPDATE : globalText.CONFIGURE} {globalText.POLICY}
              </Button>
            )}
          </Grid>
        </SandboxList>
      </>
    </>
  );
};

export default Sandbox;
