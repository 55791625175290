export const budgetTxt = {
  ALL_CHANGES_ARE_SAVED: "All changes are saved",
  BUDGET_NAME: "Budget name",
  SAVE_NEW: "Save new budget as",
  SUCCESSFULLY_SAVED: "Budget saved successfully",
  SUCCESSFULLY_CLONED: "Budget cloned successfully",
  DUPLICATE_SUCCESSFUL: "Budget duplicated successfully",
  FAILED_TO_CLONE: "Failed cloning budget",
  FAILED_TO_UPDATE: "Failed saving budget",
  SAVE_AS_LABEL: "Name",
  SAVE_AS_HELPER_TEXT: "This will create a new budget with you as the owner",
  NAME_BEFORE_SAVING: "Name before saving",
  BUDGET_IS_INVALID:
    "A budget must contain a name, a scope, an amount, an alert threshold and a collaborator (or a Slack channel)",
};
