import { type ChangeEvent, Fragment, useEffect, useState } from "react";

import { LimitAggregation, Metric } from "@doitintl/cmp-models";
import {
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { cloudAnalyticsText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { CSPMetric, MetricOptions } from "../../utilities";

export type Limit = {
  id: string;
  metricLabel: string;
  limitOrder: string;
  limit: string;
  total: number;
  limitMetric: number | undefined;
};

export type LimitsFilterDialogProps = {
  origLimits: MetadataOption[];
  isCSP: boolean;
  calculatedMetric: MetricWSnap | null;
  extendedMetric?: string;
  metric: number;
  onCancel: (item?: MetadataOption) => void;
  onConfirm: (filters: Limit[], aggregation?: LimitAggregation) => void;
  open: boolean;
  selected?: MetadataOption;
  initialAggregation?: LimitAggregation;
};

export const LimitsFilterDialog = ({
  origLimits,
  isCSP,
  calculatedMetric,
  extendedMetric,
  metric,
  onCancel,
  onConfirm,
  open,
  selected,
  initialAggregation,
}: LimitsFilterDialogProps) => {
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [limits, setLimits] = useState([] as Limit[]);
  const [aggregation, setAggregation] = useState(initialAggregation);

  const handleConfirm = () => {
    onConfirm(limits, aggregation);
  };

  const handleCancel = () => {
    setAggregation(initialAggregation);
    const hasLimits = origLimits.some((l) => !!l._limit) && limits.some((l) => !!l.limit);
    if (!hasLimits) {
      onCancel(selected);
      return;
    }
    onCancel();
  };

  useEffect(() => {
    setLimits(
      origLimits.map((al) => {
        const limitStr = al._limit?.toString() || "";
        return {
          id: al.id,
          metricLabel: al.data.label,
          limitOrder: al._limitOrder || "desc",
          limit: limitStr === "0" ? "" : limitStr,
          total: al?.data?.values?.length || 0,
          limitMetric: al._limitMetric !== null && al._limitMetric !== undefined ? al._limitMetric : metric,
        };
      })
    );
  }, [origLimits, metric]);

  const isLimitValid = (limit: string, totalLimit: number): boolean => {
    const val = Number(limit);
    if (!Number.isInteger(val) || val < 0 || val > totalLimit) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    let disableButton = true;
    if (limits.length === 0) {
      disableButton = false;
    } else {
      for (const l of limits) {
        if (!isLimitValid(l.limit, l.total)) {
          disableButton = true;
          break;
        }

        if (isLimitValid(l.limit, l.total) && parseInt(l.limit) > 0) {
          disableButton = false;
        }
      }
    }

    setDisableConfirm(disableButton);
  }, [limits]);

  const updateProperty = (property, value, idx) => {
    const newLimits = limits.map((item, i) => {
      if (idx === i) {
        return { ...item, [property]: value };
      }
      return item;
    });
    setLimits(newLimits);
  };

  const handleLimitOrderChange = (event, idx) => {
    const { value } = event.target;
    updateProperty("limitOrder", value.trim(), idx);
  };

  const handleLimitChange = (event, idx) => {
    const { value } = event.target;
    updateProperty("limit", value.trim(), idx);
  };

  const handleLimitMetricChange = (event, idx) => {
    const { value } = event.target;
    updateProperty("limitMetric", value, idx);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAggregation(event.target.value as LimitAggregation);
  };

  return (
    <SimpleDialog
      open={open}
      title={cloudAnalyticsText.LIMITS.TITLE}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonText={cloudAnalyticsText.LIMITS.DIALOG_CONFIRM}
      disableConfirm={disableConfirm}
      dialogProps={{
        fullWidth: true,
      }}
    >
      <DialogContent>
        <Grid container item xs={12} columnSpacing={1} justifyContent="center" data-testid="limits-filter-dialog">
          {limits.map((limit, idx) => (
            <Fragment key={limit.id}>
              <Grid container item xs={12} sm={12}>
                <Typography variant="subtitle1" fontWeight={500} display="inline" mt={3}>
                  {limit.metricLabel}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label={cloudAnalyticsText.LIMITS.LIMIT_TO_LABEL}
                  value={limit.limitOrder}
                  onChange={(event) => handleLimitOrderChange(event, idx)}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                >
                  <MenuItem key="top" value="desc" dense>
                    {cloudAnalyticsText.LIMITS.TOP}
                  </MenuItem>
                  <MenuItem key="bottom" value="asc" dense>
                    {cloudAnalyticsText.LIMITS.BOTTOM}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label={cloudAnalyticsText.LIMITS.SHOW_LABEL}
                  margin="normal"
                  variant="outlined"
                  value={limit.limit}
                  error={!isLimitValid(limit.limit, limit.total)}
                  onChange={(event) => handleLimitChange(event, idx)}
                  fullWidth
                  autoFocus={idx === 0}
                  data-testid={`limits-id-${idx}`}
                />
              </Grid>
              <Grid item xs={12} sm={2} container alignItems="center" sx={{ pr: "10px" }}>
                <Typography display="inline" sx={(theme) => ({ ml: theme.spacing(1), mt: "5px" })}>
                  {cloudAnalyticsText.LIMITS.OUT_OF} {limit.total}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1} container alignItems="center" sx={{ pr: "2px" }}>
                <Typography display="inline" sx={{ ml: "18px", mt: "5px" }}>
                  {cloudAnalyticsText.LIMITS.BY}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label={cloudAnalyticsText.LIMITS.METRIC_LABEL}
                  value={limit.limitMetric}
                  onChange={(event) => handleLimitMetricChange(event, idx)}
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                >
                  {MetricOptions.map((metricOption) => (
                    <MenuItem key={metricOption.value} value={metricOption.value} dense>
                      {metricOption.label}
                    </MenuItem>
                  ))}
                  {isCSP && (
                    <MenuItem key={CSPMetric.value} value={CSPMetric.value} dense>
                      {CSPMetric.label}
                    </MenuItem>
                  )}
                  {calculatedMetric && (
                    <MenuItem key={calculatedMetric.snapshot.id} value={Metric.CALCULATED} dense>
                      {calculatedMetric.data.name}
                    </MenuItem>
                  )}
                  {!!extendedMetric && (
                    <MenuItem key={Metric.EXTENDED} value={Metric.EXTENDED} dense>
                      {extendedMetric}
                    </MenuItem>
                  )}
                </TextField>
              </Grid>
            </Fragment>
          ))}
        </Grid>
        <Divider sx={{ mx: -3, my: 2 }} />
        <Stack>
          <Typography variant="subtitle1" fontWeight={500}>
            {reportTxt.REMAINING_RESULTS}
          </Typography>
          <RadioGroup value={aggregation} onChange={handleChange}>
            <FormControlLabel value={LimitAggregation.TOP} control={<Radio />} label={reportTxt.AGGREGATE_TOP} />
            <FormControlLabel value={LimitAggregation.ALL} control={<Radio />} label={reportTxt.AGGREGATE_ALL} />
            <FormControlLabel value={LimitAggregation.NONE} control={<Radio />} label={reportTxt.AGGREGATE_NONE} />
          </RadioGroup>
        </Stack>
      </DialogContent>
    </SimpleDialog>
  );
};

export default LimitsFilterDialog;
