import { useTier } from "../../Context/TierProvider";
import { type InsightsPageId } from "../../Navigation/config/pages";
import { InsightsContextProvider } from "./context";
import { InsightsDetails } from "./InsightDetails/InsightDetails";
import { InsightsList } from "./InsightsList/InsightsList";
import { InsightsUpsell } from "./InsightsUpsell/InsightsUpsell";

export function InsightsRouter({ pageId }: { pageId: InsightsPageId }) {
  const { isFeatureEntitled, loading } = useTier();
  const isEntitled = isFeatureEntitled("governance:insights");

  if (loading) return <></>;

  // Access to details blocked via featureGate on access control configuration
  if (pageId === "insights:details") {
    return (
      <InsightsContextProvider>
        <InsightsDetails />
      </InsightsContextProvider>
    );
  } else if (pageId === "insights" && isEntitled) {
    return (
      <InsightsContextProvider>
        <InsightsList />
      </InsightsContextProvider>
    );
  } else {
    return (
      <InsightsContextProvider>
        <InsightsUpsell />
      </InsightsContextProvider>
    );
  }
}
