import { type ChartSeriesRecord } from "../useHighchartsRenderer";
import { type FormatterFunc } from "./ECharts";

export function useForecastSeries(series: ChartSeriesRecord | undefined, formatter: FormatterFunc) {
  if (!series) {
    return [];
  }

  return [
    {
      type: "line",
      data: series.data,
      name: series.name,
      clip: true,
      barMinHeight: 1,
      symbol: "circle",
      symbolSize: 10,
      itemStyle: {
        color: "transparent",
      },
      emphasis: {
        itemStyle: {
          borderWidth: 10,
          borderColor: "rgba(128, 128, 128, 0.2)",
          color: "grey",
        },
      },
      lineStyle: {
        type: "dotted",
        width: 3,
        color: "grey",
      },
      tooltip: {
        valueFormatter: (value) => formatter(value, false),
      },
      universalTransition: true,
    },
  ];
}
