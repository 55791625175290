import { TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import useMountEffect from "../../../../../Components/hooks/useMountEffect";
import { type ContractStateType } from "../../types";
import ContractCurrencySelector from "../components/ContractCurrencySelector";
import { AcceleratorTypeSelector } from "./components/AcceleratorTypeSelector";
import { DateSelectorComponent } from "./components/DateSelectorComponent";

type Props = {
  pageTitle: string;
  state: ContractStateType;
  setState: React.Dispatch<React.SetStateAction<ContractStateType>>;
};

export const AcceleratorDetailsStep = ({ state, setState, pageTitle }: Props) => {
  const [tier] = useCollectionDataOnce(
    getCollection(TierModel).where("packageType", "==", "solve").where("name", "==", "standard"),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const handleChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    if (key === "chargePerTerm" || key === "estimatedFunding") {
      setState({ ...state, [key]: parseFloat(event.target.value as string) });
      return;
    }

    if (key === "showEstimatedFunding") {
      setState({ ...state, showEstimatedFunding: (event as React.ChangeEvent<HTMLInputElement>).target.checked });
      return;
    }

    setState({ ...state, [key]: event.target.value });
  };

  useMountEffect(() => {
    if (state.endDate === null) {
      setState({ ...state, endDate: state.startDate.plus({ months: 3 }) });
    }
  });

  return (
    <Container maxWidth="sm" data-cy="accelerator-details-step">
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {pageTitle} description
      </Typography>
      <Grid item xs={12}>
        <AcceleratorTypeSelector
          data-cy="accelerator-type-selector"
          value={state.typeContext}
          onChange={handleChange("typeContext")}
          error={state.errors.typeContext}
        />
      </Grid>
      <Grid item xs={12}>
        <DateSelectorComponent
          value={state.startDate}
          onChange={(date) => date && setState({ ...state, startDate: date })}
          label="Start Date"
          error={state.errors.startDate}
          disabled={state.isEditForbidden}
          helperText="Start Date should be when the Accelerator hits the CRE queue"
        />
      </Grid>
      <Grid item xs={12}>
        <DateSelectorComponent
          value={state.endDate ?? state.startDate}
          onChange={(date) => date && setState({ ...state, endDate: date })}
          label="End Date"
          error={state.errors.endDate}
          disabled={state.isEditForbidden}
          minDate={state.startDate}
          helperText="End Date is standard 90 days, but should be edited later when Accelerator is applied to the customer"
        />
      </Grid>
      <Grid display="flex">
        <ContractCurrencySelector
          value={state.currency}
          disabled={state.isEditForbidden}
          onChange={handleChange("currency")}
          options={tier ? Object.keys(tier[0].price) : []}
        />
        <TextField
          data-cy="accelerator-charge"
          label="Charge"
          margin="normal"
          value={state.chargePerTerm}
          onChange={handleChange("chargePerTerm")}
          type="number"
          fullWidth
          required
          error={state.errors.chargePerTerm}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl size="small" fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.showEstimatedFunding ?? false}
                onChange={handleChange("showEstimatedFunding")}
                data-cy="show-estimated-funding-checkbox"
              />
            }
            label={
              <>
                <Typography variant="body2">Anticipated Partner Funding</Typography>
                <Typography variant="caption" color="textSecondary">
                  Check this if the SOW details partner funding
                </Typography>
              </>
            }
          />
        </FormControl>
        {state.showEstimatedFunding && (
          <TextField
            label="Anticipated funding amount"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">{state.currency}</InputAdornment>,
            }}
            required
            type="number"
            value={state.estimatedFunding}
            onChange={handleChange("estimatedFunding")}
            data-cy="estimated-funding-amount"
            error={state.errors.estimatedFunding}
          />
        )}
      </Grid>
    </Container>
  );
};
