import { useCallback, useEffect, useState } from "react";

import { BillingModel, CustomerModel, EntityModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type DateTime } from "luxon";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils/index";
import { type Invoice } from "./types";

export const useInvoices = (customerId: string | undefined, month: DateTime<true>) => {
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const showError = useErrorSnackbar(7);

  const loadInvoices = useCallback(async () => {
    setInvoicesLoading(true);
    try {
      const customerDoc = getCollection(CustomerModel).doc(customerId as string);
      const formattedMonth = month.toFormat("yyyy-MM");

      const entityQuerySnapshot = await getCollection(BillingModel)
        .doc("invoicing")
        .collection("invoicingMonths")
        .doc(formattedMonth)
        .collection("monthInvoices")
        .where("customer", "==", customerDoc)
        .get();

      const invoicePromises = entityQuerySnapshot.docs.map(async (entityDocSnap) => {
        const entityP = getCollection(EntityModel).doc(entityDocSnap.id).get();

        const invoiceSnapsP = getCollection(BillingModel)
          .doc("invoicing")
          .collection("invoicingMonths")
          .doc(formattedMonth)
          .collection("monthInvoices")
          .doc(entityDocSnap.id)
          .collection("entityInvoices")
          .where("final", "==", true)
          .get();

        const [entity, invoiceSnaps] = await Promise.all([entityP, invoiceSnapsP]);

        const entityData = entity.asModelData();

        return {
          invoiceSnaps,
          entity: entityData ? { id: entityDocSnap.id, ...entityData } : undefined,
        };
      });

      const entityInvoiceSnaps = await Promise.all(invoicePromises);

      const invoices = entityInvoiceSnaps.flatMap(({ invoiceSnaps, entity }) => {
        if (!entity) {
          return [];
        }

        return invoiceSnaps.docs.map((invoiceSnap): Invoice => {
          const invoice = invoiceSnap.asModelData();

          return { ...invoice, entity, id: invoiceSnap.id };
        });
      });

      invoices.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));

      setInvoices(invoices);
      setInvoicesLoading(false);
    } catch (err) {
      showError(err);
      consoleErrorWithSentry(err);
      setInvoicesLoading(false);
    }
  }, [customerId, month, showError]);

  useEffect(() => {
    if (customerId) {
      loadInvoices();
    } else {
      setInvoices([]);
    }
  }, [customerId, loadInvoices, month, showError]);

  return { invoices, invoicesLoading, loadInvoices };
};
