import { useCallback, useEffect, useState } from "react";

import { BillingModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils/index";
import { type Job } from "./types";

const startOfLastMonth = DateTime.utc().startOf("month").minus({ months: 1 }).toJSDate();

export const useJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const showError = useErrorSnackbar(7);

  const loadJobs = useCallback(async () => {
    setLoading(true);
    try {
      const jobsSnaps = await getCollection(BillingModel)
        .doc("manualOperations")
        .collection("jobs")
        .where("createdAt", ">=", startOfLastMonth)
        .orderBy("createdAt", "desc")
        .get();

      const jobsPromises = jobsSnaps.docs.map(async (jobDocSnap) => {
        const job = jobDocSnap.asModelData();
        const customerModel = job.customer && (await job.customer.get()).asModelData();

        return {
          ...job,
          id: jobDocSnap.id,
          customerName: customerModel?.primaryDomain as string,
        };
      });

      const jobs = await Promise.all(jobsPromises);

      setJobs(jobs);
      setLoading(false);
    } catch (err) {
      showError(err);
      consoleErrorWithSentry(err);
      setLoading(false);
    }
  }, [showError]);

  useEffect(() => {
    loadJobs();
  }, [loadJobs, showError]);

  return { jobs, loadJobs, loading };
};
