import { useEffect } from "react";

import {
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { type CostAllocationState } from "../costAllocationReducer";
import { sendStepMixpanelEvent } from "../utils";

const STEP_NUMBER = 5;

type Props = {
  costAllocationState: CostAllocationState;
  handleGoToStep: (step: number) => void;
  origin: string;
};

const SummaryStep = ({ costAllocationState, handleGoToStep, origin }: Props) => {
  const attributionsNames = costAllocationState.newAttributions.map((attr) => attr.data?.name).filter(Boolean);

  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "summary", origin);
  }, [origin]);

  return (
    <Stack gap={3}>
      <Typography>
        {guidedExperienceText.summaryStep.summary}
        {guidedExperienceText.summaryStep.makeChangesOrClick}
        <Typography component="span" fontWeight={500}>
          {guidedExperienceText.summaryStep.confirm}
        </Typography>
        {guidedExperienceText.summaryStep.toCreateIt}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: 180 }}>
              <Typography fontWeight={500}>{guidedExperienceText.summaryStep.attributionGroup}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{costAllocationState.selectedAG.name}</Typography>
            </TableCell>
            <TableCell sx={{ width: 44 }}>
              <Link component="button" variant="body1" color="text.primary" onClick={() => handleGoToStep(1)}>
                {guidedExperienceText.summaryStep.edit}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: 180 }}>
              <Typography fontWeight={500}>{guidedExperienceText.summaryStep.attributions}</Typography>
            </TableCell>
            <TableCell>
              <List sx={{ listStyleType: "disc" }}>
                {attributionsNames.map((name, index) => (
                  <ListItem key={index} sx={{ py: 0 }}>
                    <ListItemText sx={{ display: "list-item" }} primary={name} />
                  </ListItem>
                ))}
              </List>
            </TableCell>
            <TableCell sx={{ width: 44 }}>
              <Link component="button" variant="body1" color="text.primary" onClick={() => handleGoToStep(2)}>
                {guidedExperienceText.summaryStep.edit}
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default SummaryStep;
