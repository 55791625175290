import { useMemo, useState } from "react";

import { useParams } from "react-router";
import {
  Alert,
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { CircularProgressLoader } from "../../Components/Loader";
import Payer from "./payer";
import { type OptimizerPlan, type Params } from "./types";
import { groupByDedicatedPayers } from "./utils";

const label = { inputProps: { "aria-label": "Expand all" } };

export default function OptimizerPlanPage() {
  const { planId } = useParams<Params>();

  const api = useApiContext();

  const { data: apiData, error } = useQuery<OptimizerPlan, AxiosError>({
    queryKey: ["optimizer-move", planId],
    queryFn: async () => {
      const { data } = await api.request({
        method: "get",
        url: `/v1/flexapi/plan/${planId}`,
      });

      return data;
    },
    refetchInterval: 3000,
  });

  const [expandAllChecked, setExpandAllChecked] = useState(false);

  const data = useMemo(() => {
    if (!apiData) {
      return null;
    }
    return groupByDedicatedPayers(apiData.Moves);
  }, [apiData]);

  if (!data || !apiData) {
    return <CircularProgressLoader />;
  }

  if (error) {
    return <Alert severity="error">There was an error getting the plan: {error.message}</Alert>;
  }

  return (
    <div data-cy="optimizer-plans-page">
      <Typography variant="h1" gutterBottom>
        {planId}
      </Typography>

      <Typography variant="body2" gutterBottom color="textSecondary">
        Last updated: {DateTime.fromISO(apiData.CreatedAt).toFormat("dd LLL yyyy, HH:mm")}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box display="flex" justifyContent="space-between">
                  <span>Payer ID</span>
                  <span>
                    Expand all{" "}
                    <Switch
                      {...label}
                      checked={expandAllChecked}
                      onChange={({ target: { checked } }) => setExpandAllChecked(checked)}
                    />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(data).map(([payerId, moves], index) => (
              <Payer
                payerId={payerId}
                moves={moves}
                key={payerId + index.toString()}
                expandAllChecked={expandAllChecked}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
