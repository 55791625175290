import { type FC } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Stack } from "@mui/material";

export const OptionalParamWrapper: FC<{ onRemove?: () => void }> = ({ children, onRemove }) => {
  if (!onRemove) {
    return <>{children}</>;
  }

  return (
    <Stack direction="row" alignItems="center">
      {children}
      <IconButton size="small" onClick={onRemove}>
        <ClearIcon />
      </IconButton>
    </Stack>
  );
};
