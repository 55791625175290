import { useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Button, Card, CardHeader, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const IconCard = ({
  card,
  clickHandler,
  cardState,
}: {
  card: any;
  clickHandler: () => void;
  cardState: "select" | "change";
}) => {
  const {
    palette: { primary },
  } = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const actionComponent =
    cardState === "select" ? isHovered ? <ArrowForwardIcon /> : <ArrowForwardIcon /> : <Button>Change</Button>;
  return (
    <Link
      key={card.title}
      onClick={clickHandler}
      style={{ textDecoration: "none", cursor: "pointer" }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Card sx={{ "&:hover": { borderColor: primary.main } }}>
        <CardHeader
          avatar={<Avatar sx={{ width: 32, height: 32 }} src={card.icon} />}
          subheader={card.text}
          title={card.title}
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
          action={actionComponent}
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
    </Link>
  );
};
