import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  ContractModel,
  type VendorContractModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, Container, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";

import { BottomAppBar } from "../../../Components/BottomAppBar";
import DataCouplet from "../../../Components/DataCouplet";
import { Loader } from "../../../Components/Loader";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { buildCommitmentPeriodBlock, contractDate, getAssetLabel } from "../utils";

export const cyIds = {
  container: "vendor-contract-view-container",
  title: "contract-title",
  sections: {
    details: "details-section",
    commitment: "commitment-section",
  },
};

export const platformMap = {
  [AssetTypeGoogleCloud]: "Google Cloud Platform",
  [AssetTypeAmazonWebServices]: "Amazon Web Services",
};

const VendorContractPage = () => {
  const history = useHistory();
  const { customerId, contractId } = useParams<{ contractId: string; customerId: string }>();
  const { assets, assetsLoading } = useCustomerContext();
  const [vendorContract, setVendorContract] = useState<VendorContractModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleBack = useCallback<() => void>(
    () => history.push(`/customers/${customerId}/contracts/contracts-list`),
    [customerId, history]
  );

  useEffect(() => {
    const loadContract = async () => {
      setIsLoading(true);
      const contract = (await getCollection(ContractModel).doc(contractId).get()).asModelData();

      const vendorContract = contract?.vendorContract ? (await contract.vendorContract.get()).data() : null;

      if (!contract || !vendorContract) {
        handleBack();
        return;
      }

      setVendorContract(vendorContract);
      setIsLoading(false);
    };

    loadContract();
  }, [contractId, handleBack]);

  const billingAccounts = useMemo(() => {
    if (vendorContract?.vendor !== AssetTypeGoogleCloud || !vendorContract?.assets?.length) {
      return [];
    }

    const assetsForTypeGoogleCloud =
      vendorContract?.entity?.id && assets[vendorContract?.entity?.id]
        ? assets[vendorContract?.entity?.id].filter((asset) => asset.data.type === AssetTypeGoogleCloud)
        : [];

    const contractAssetsIds = vendorContract?.assets.map((a) => a.id);
    return assetsForTypeGoogleCloud.filter((asset) => contractAssetsIds.includes(asset.id));
  }, [assets, vendorContract?.assets, vendorContract?.entity?.id, vendorContract?.vendor]);

  const billingAccountsJsx = useMemo(
    () => (
      <List>
        {billingAccounts.map((ba) => (
          <ListItem disablePadding disableGutters key={ba.id}>
            <ListItemText>{getAssetLabel(ba)}</ListItemText>
          </ListItem>
        ))}
      </List>
    ),
    [billingAccounts]
  );

  return (
    <Container maxWidth="sm" sx={{ pt: "3rem", pb: "6rem" }} data-cy={cyIds.container}>
      <Loader loading={isLoading}>
        {vendorContract ? (
          <Stack spacing={3}>
            <Typography variant="h1" data-cy={cyIds.title}>
              {platformMap[vendorContract.vendor]}
            </Typography>
            <Box component="section" data-cy={cyIds.sections.details}>
              <Typography variant="h2">Vendor Contract details</Typography>
              <Stack component="dl" spacing={2}>
                <DataCouplet key="commitment-type" field="Commitment type" value={vendorContract.commitmentType} />
                <DataCouplet
                  key="contract-start-date"
                  field="Start date"
                  value={contractDate(vendorContract.startDate)}
                />
                <DataCouplet key="contract-end-date" field="End date" value={contractDate(vendorContract?.endDate)} />
                <DataCouplet key="contract-discount" field="Discount" value={`${vendorContract.discount}%`} />
                {vendorContract.vendor === AssetTypeGoogleCloud && (
                  <DataCouplet
                    field="Billing accounts"
                    value={billingAccountsJsx}
                    key="contract-billing-accounts"
                    loading={assetsLoading}
                  />
                )}
              </Stack>
            </Box>
            <Box component="section" data-cy={cyIds.sections.commitment}>
              <Typography variant="h2">Commitment</Typography>
              {vendorContract.commitmentPeriods?.map((period, index) => buildCommitmentPeriodBlock(period, index))}
            </Box>
          </Stack>
        ) : null}
        <BottomAppBar
          disabled={false}
          handlePrimaryButtonClicked={handleBack}
          primaryButtonName="Close"
          maxWidth="md"
        />
      </Loader>
    </Container>
  );
};

export default VendorContractPage;
