import { type JSX, useCallback, useState } from "react";

import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { MPADeletionDialog } from "./MPADeletionDialog";
import { useMPADeletionRequest } from "./useMPADeletionRequest";

type MPADeletionDialogFacade = {
  showMPADeletionDialog: (masterPayerAccount: MasterPayerAccountsModelSnapshot) => void;
  MPADeletionDialogComponent: () => JSX.Element | null;
};

export const useMPADeletionDialogFacade = (): MPADeletionDialogFacade => {
  const deleteMasterPayerAccount = useMPADeletionRequest();
  const [masterPayerAccount, setMasterPayerAccount] = useState<MasterPayerAccountsModelSnapshot | null>(null);
  const onCancel = () => setMasterPayerAccount(null);

  const MPADeletionDialogComponent = useCallback(
    () =>
      masterPayerAccount && (
        <MPADeletionDialog
          onCancel={onCancel}
          onDelete={() => deleteMasterPayerAccount(masterPayerAccount)}
          masterPayerAccount={masterPayerAccount}
        />
      ),
    [deleteMasterPayerAccount, masterPayerAccount]
  );

  return {
    showMPADeletionDialog: setMasterPayerAccount,
    MPADeletionDialogComponent,
  };
};
