import { useCallback, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import { useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { TargetCoverageDialog } from "../Common/TargetCoverageDialog";
import { deleteWorkloadTargetCoverage, saveNote, updateWorkloadTargetCoverage } from "../db";
import { useSingleCustomerPurchases } from "../hooks/customerPurchasesHooks";
import { type CachedState, type CustomerAggregatedPurchaseItem, type WorkloadDetailsItem } from "../types";
import { PurchaseDetailsHeader } from "./Header/PurchaseDetailsHeader";
import { TableViews } from "./TableViews";

export const PurchaseDetails = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const [targetCoverageDialogOpen, setTargetCoverageDialogOpen] = useState<boolean>(true);
  const [itemToEdit, setItemToEdit] = useState<WorkloadDetailsItem | null>(null);
  const location = useLocation<CachedState>();
  const cachedAggregatedPurchase = location.state?.aggregatedPurchase;
  const successSnackbar = useSuccessSnackbar();
  const customerAggregatedPurchase = useSingleCustomerPurchases(customerId);
  const aggregatedPurchase: CustomerAggregatedPurchaseItem = useMemo(
    () => customerAggregatedPurchase || cachedAggregatedPurchase,
    [cachedAggregatedPurchase, customerAggregatedPurchase]
  );

  useEffect(() => {
    setTargetCoverageDialogOpen(!!itemToEdit);
  }, [itemToEdit]);
  const handleTargetCoverageDialogUpdate = useCallback(
    async (value: number | "", targetCoverageNote) => {
      if (itemToEdit) {
        if (value === "" && itemToEdit.workloadTargetCoverage !== undefined) {
          await deleteWorkloadTargetCoverage(itemToEdit.id);
          await flexSaveOpsApi.refreshCustomer(itemToEdit.customerId);
        } else if (value !== "") {
          await updateWorkloadTargetCoverage(itemToEdit.id, Number(value) / 100);
          await flexSaveOpsApi.refreshCustomer(itemToEdit.customerId);
        }
        const customerNote = `Target coverage for ${itemToEdit.region}-${itemToEdit.type}-${
          itemToEdit.hardware
        } changed to ${value}% from ${itemToEdit.displayTargetCoverage * 100}%.${targetCoverageNote}.`;
        await saveNote(itemToEdit.customerId, customerNote, ["flexsave"], currentUser);
        successSnackbar("Customer target coverage successfully updated");
      }
    },
    [currentUser, itemToEdit, flexSaveOpsApi, successSnackbar]
  );

  const handleTargetCoverageDialogClose = useCallback(() => setItemToEdit(null), []);
  if (!aggregatedPurchase) {
    return null;
  }
  return (
    <>
      <PurchaseDetailsHeader aggregatedPurchase={aggregatedPurchase} />
      <TableViews aggregatedPurchase={aggregatedPurchase} onEditClick={setItemToEdit} />
      {itemToEdit && (
        <TargetCoverageDialog
          handleClose={handleTargetCoverageDialogClose}
          handleCoverageApplied={handleTargetCoverageDialogUpdate}
          value={itemToEdit.displayTargetCoverage * 100}
          fallbackValue={itemToEdit.targetCoverage * 100}
          open={targetCoverageDialogOpen}
          coverageType="workload"
        />
      )}
    </>
  );
};
