import { useParams } from "react-router-dom";

import { default as saasConsoleMarketPlaceSuccessDark } from "../../../assets/standalone/aws-mp-success-dark.svg";
import { default as saasConsoleMarketPlaceSuccessLight } from "../../../assets/standalone/aws-mp-success-light.svg";
import { useCustomerRouteNavigation } from "../../../Components/hooks/useCustomerRouteNavigation";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { SubscriptionSuccessMsg } from "./SubscriptionSuccessMsg";
import { CustomerPageUrl, MarketplaceProduct } from "./type";

type MarketplaceSuccessParams = {
  product: MarketplaceProduct;
};

export const MarketplaceSuccessAWS = () => {
  const { product } = useParams<MarketplaceSuccessParams>();
  const navigateToCustomerRoute = useCustomerRouteNavigation();
  const isDark = useDarkThemeCheck();

  const { customer } = useCustomerContext();
  const isSaaSConsoleAWSEnabled = !!customer.enabledSaaSConsole?.AWS;

  const SuccessMessage = () => {
    switch (product) {
      case MarketplaceProduct.SAAS_CONSOLE:
        return (
          <SubscriptionSuccessMsg
            marketplaceSuccessImg={isDark ? saasConsoleMarketPlaceSuccessDark : saasConsoleMarketPlaceSuccessLight}
            subscriptionSuccessMsgSubtitle="You have subscribed to DoiT Console via the AWS Marketplace"
            linkText={isSaaSConsoleAWSEnabled ? "Go to Dashboard" : "Connect your cloud"}
            onLinkClick={
              isSaaSConsoleAWSEnabled
                ? () => navigateToCustomerRoute(CustomerPageUrl.DASHBOARDS)
                : () => navigateToCustomerRoute(`${CustomerPageUrl.SAAS_CONSOLE_AWS}?mpsubscribed=true`)
            }
          />
        );
      default:
        return null;
    }
  };

  return <SuccessMessage />;
};
