import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { DoitRole } from "@doitintl/cmp-models";
import LoadingButton from "@mui/lab/LoadingButton";
import { type SelectChangeEvent } from "@mui/material";
import sumBy from "lodash/sumBy";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { PurchaseConfirmationDialog } from "../Common/PurchaseConfirmationDialog";
import { approvePurchasePlan } from "../db";
import { type CustomerAggregatedPurchaseItem, type PurchaseViewType, type WorkloadDetailsItem } from "../types";
import { AllWorkloadDetailsTable } from "./AllWorkloadDetailsTable";
import { PendingWorkloadsDetailsTable } from "./PendingWorkloadsDetailsTable";
import PurchaseViewDropdown from "./PurchaseViewDropdown";

type Props = {
  aggregatedPurchase: CustomerAggregatedPurchaseItem;
  onEditClick: (workloadDetailsItem: WorkloadDetailsItem) => void;
};

export const TableViews = ({ aggregatedPurchase, onEditClick }: Props) => {
  const [disableViewSelection, setDisableViewSelection] = useState<boolean>(false);
  const [view, setView] = useState<PurchaseViewType>("PurchasePlans");
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);
  const [selectedWorkloads, setSelectedWorkloads] = useState<WorkloadDetailsItem[]>([]);
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const history = useHistory();

  const [workloadsToPurchase, allWorkloads] = useMemo(() => {
    const workloadsToPurchase = aggregatedPurchase?.workloads?.pending || [];
    const allWorkloads = aggregatedPurchase?.workloads?.all || [];
    return [workloadsToPurchase, [...workloadsToPurchase, ...allWorkloads]];
  }, [aggregatedPurchase?.workloads]);

  useEffect(() => {
    if (workloadsToPurchase.length > 0) {
      setView("PurchasePlans");
      setDisableViewSelection(false);
    } else {
      setView("Workloads");
      setDisableViewSelection(true);
    }
  }, [workloadsToPurchase]);

  const handleViewChange = useCallback(
    (event: SelectChangeEvent) => setView(event.target.value as PurchaseViewType),
    []
  );

  const onDialogApproveClicked = useCallback(async () => {
    if (selectedWorkloads.length > 0) {
      setLoadingApproval(true);
      const ids = [aggregatedPurchase.customerId];
      const workloads = selectedWorkloads.map((item) => ({
        billing_account_id: item.billingAccountId,
        region: item.region,
        hardware: item.hardware,
        type: item.type,
        usage: item.purchaseRecommendations,
      }));
      await Promise.all(
        selectedWorkloads.map(async (purchaseItem) =>
          purchaseItem.purchaseRisk ? approvePurchasePlan(purchaseItem.id, currentUser.email) : Promise.resolve(true)
        )
      );
      await flexSaveOpsApi.approveV2(ids, workloads);
    }
  }, [currentUser, flexSaveOpsApi, aggregatedPurchase, selectedWorkloads]);

  const handleApproveDialogClose = useCallback(() => setApproveDialogOpen(false), []);
  const handlePurchaseButtonClick = useCallback(() => setApproveDialogOpen(true), []);

  useEffect(() => {
    if (!approveDialogOpen && loadingApproval) {
      history.push("./");
    }
  }, [approveDialogOpen, history, loadingApproval]);

  const isPurchasePlanView = view === "PurchasePlans" && workloadsToPurchase.length > 0;

  const PurchaseButton = (
    <LoadingButton
      onClick={handlePurchaseButtonClick}
      disabled={selectedWorkloads.length === 0 || !isFlexsaveAdmin || loadingApproval || !isPurchasePlanView}
      loading={loadingApproval}
      variant="contained"
      color="warning"
    >
      Purchase
    </LoadingButton>
  );
  const title = (
    <PurchaseViewDropdown disabled={disableViewSelection} handleViewChange={handleViewChange} selectedView={view} />
  );

  return (
    <>
      {isPurchasePlanView ? (
        <>
          <PurchaseConfirmationDialog
            open={approveDialogOpen}
            handleClose={handleApproveDialogClose}
            handleApprove={onDialogApproveClicked}
            totalCost={sumBy(selectedWorkloads, "purchasePlanPrice")}
            customerCount={1}
            cudCount={sumBy(selectedWorkloads, "purchaseRecommendations")}
            customerName={aggregatedPurchase.primaryDomain}
          />
          <PendingWorkloadsDetailsTable
            onEditClick={onEditClick}
            onWorkloadSelect={(selectedWorkloads) => setSelectedWorkloads(selectedWorkloads)}
            workloadItems={workloadsToPurchase}
            purchaseButton={PurchaseButton}
            title={title}
          />
        </>
      ) : (
        <AllWorkloadDetailsTable workloadItems={allWorkloads} onEditClick={onEditClick} title={title} />
      )}
    </>
  );
};
