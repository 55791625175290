import { type ChangeEvent } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  type TextFieldProps,
} from "@mui/material";
import { Formik, type FormikProps } from "formik";
import { number, object, type ObjectSchema, ref, string } from "yup";

import { globalText } from "../../../../assets/texts";
import { useFullScreen } from "../../../../utils/dialog";
import { type AwsAccountBatchCreationRequest } from "../../data/aws-account-api";

export type AwsAccountCreationDialogProps = {
  onClose: () => void;
  onSubmit: (awsAccount: AwsAccountBatchCreationRequest) => Promise<unknown>;
};

const cypressFieldsIds: Record<keyof AwsAccountBatchCreationRequest, string> = {
  accountNamePrefix: "aws-generator-create-account-name-prefix-field",
  emailPrefix: "aws-generator-create-email-prefix-field",
  fromIndex: "aws-generator-create-from-index-field",
  toIndex: "aws-generator-create-to-index-field",
  zeroPadding: "aws-generator-create-zero-padding-field",
};

export const cypressIds = {
  cancelButton: "aws-generator-cancel-button",
  createButton: "aws-generator-activate-button",
  fields: cypressFieldsIds,
};

const FIELD_LABELS: Record<keyof AwsAccountBatchCreationRequest, string> = {
  accountNamePrefix: "Account name prefix",
  emailPrefix: "Account email prefix",
  fromIndex: "From index",
  toIndex: "To index",
  zeroPadding: "Zero padding",
};

const EMAIL_DOMAIN = "@aws-reg.doit-intl.com";

function getCommonFieldProps(
  { isSubmitting, errors, touched, handleChange, handleBlur, values }: FormikProps<AwsAccountBatchCreationRequest>,
  fieldName: keyof AwsAccountBatchCreationRequest,
  helperTextGenerator: (values: AwsAccountBatchCreationRequest) => string | undefined = () => undefined
): TextFieldProps & { "data-cy": string } {
  const errorMessage = errors[fieldName];
  const hasError = touched[fieldName] && errorMessage !== undefined;
  const trimValueOnChange = (changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeEvent.target.value = changeEvent.target.value.trim();
    handleChange(changeEvent);
  };

  return {
    name: fieldName,
    disabled: isSubmitting,
    helperText: hasError ? errorMessage : helperTextGenerator(values),
    error: hasError,
    value: values[fieldName],
    onChange: trimValueOnChange,
    onBlur: handleBlur,
    variant: "outlined",
    label: FIELD_LABELS[fieldName],
    InputLabelProps: { shrink: true },
    fullWidth: true,
    required: true,
    ["data-cy"]: cypressFieldsIds[fieldName],
  };
}

const validationSchema: ObjectSchema<AwsAccountBatchCreationRequest> = object({
  accountNamePrefix: string()
    .required()
    .label(FIELD_LABELS.accountNamePrefix)
    .min(3)
    .matches(/^[^&<>“\\%|\t]*$/i, { message: "Must not include a tab or the following symbols: &<>“%|" }),
  emailPrefix: string()
    .required()
    .label(FIELD_LABELS.emailPrefix)
    .min(1)
    .matches(/^[^@]*$/, { message: "Must not include @ symbol" }),
  fromIndex: number().label(FIELD_LABELS.fromIndex).required().moreThan(-1).integer(),
  toIndex: number().label(FIELD_LABELS.toIndex).required().moreThan(-1).integer().min(ref("fromIndex")),
  zeroPadding: number().label(FIELD_LABELS.zeroPadding).required().positive().integer(),
});

function getIndexExample(fromIndex: number, zeroPadding: number) {
  return fromIndex.toString().padStart(zeroPadding ?? 3, "0");
}

function accountNameExampleGenerator(values: AwsAccountBatchCreationRequest) {
  return values.accountNamePrefix
    ? `i.e.: ${values.accountNamePrefix}${getIndexExample(values.fromIndex, values.zeroPadding)}`
    : undefined;
}

function emailExampleGenerator(values: AwsAccountBatchCreationRequest) {
  return values.emailPrefix
    ? `i.e.: ${values.emailPrefix}${getIndexExample(values.fromIndex, values.zeroPadding)}${EMAIL_DOMAIN}`
    : undefined;
}

export const AwsAccountCreationDialog = ({ onClose, onSubmit }: AwsAccountCreationDialogProps) => {
  const { fullScreen } = useFullScreen();

  return (
    <Dialog fullScreen={fullScreen} aria-labelledby="aws-account-creation-dialog" open maxWidth="sm" fullWidth>
      <DialogTitle>Create AWS Accounts</DialogTitle>
      <Formik<AwsAccountBatchCreationRequest>
        initialValues={{
          accountNamePrefix: "",
          emailPrefix: "",
          fromIndex: 0,
          toIndex: 0,
          zeroPadding: 3,
        }}
        validateOnMount
        validateOnChange
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={async (submitData) => {
          await onSubmit(validationSchema.cast(submitData));
          onClose();
        }}
      >
        {(formProps) => (
          <>
            <DialogContent>
              <Stack gap={2} mt={1}>
                <TextField {...getCommonFieldProps(formProps, "accountNamePrefix", accountNameExampleGenerator)} />
                <TextField
                  {...getCommonFieldProps(formProps, "emailPrefix", emailExampleGenerator)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" variant="filled">
                        {EMAIL_DOMAIN}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField {...getCommonFieldProps(formProps, "fromIndex")} InputProps={{ inputMode: "numeric" }} />
                <TextField {...getCommonFieldProps(formProps, "toIndex")} InputProps={{ inputMode: "numeric" }} />
                <TextField {...getCommonFieldProps(formProps, "zeroPadding")} InputProps={{ inputMode: "numeric" }} />
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={onClose} color="primary" data-cy={cypressIds.cancelButton}>
                {globalText.CANCEL}
              </Button>
              <LoadingButton
                onClick={() => formProps.handleSubmit()}
                loading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting}
                variant="contained"
                color="primary"
                data-cy={cypressIds.createButton}
              >
                {globalText.SUBMIT}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
