import { Children, isValidElement, cloneElement, Component } from "react";
import { DateTime } from "luxon";
import { formatNumber } from "../../../utils/common";
import SparkLine from "./SparkLine";

class SparkLineTable extends Component {
  toSparkLine(children) {
    let header;

    return Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child;
      }

      if (child.type === "th") {
        header = child.props.children;
      }

      if (child.props["data-sparkline"]) {
        return this.sparkLine(child, header);
      }

      if (child.props.children) {
        child = cloneElement(child, {
          children: this.toSparkLine(child.props.children),
        });
      }

      return child;
    });
  }

  sparkLine(element) {
    const data = element.props["data-sparkline"];
    // const data = dataAttr[0].split(", ").map(Number);
    const options = {
      series: [
        {
          data,
          pointStart: 1,
        },
      ],
      exporting: {
        enabled: false,
      },
      tooltip: {
        style: {
          width: 130,
          zIndex: 900,
        },
        formatter() {
          return `<div><div style="text-align:center">${DateTime.fromMillis(this.point.category)
            .toUTC()
            .toFormat("dd LLLL yyyy hh:mm")}</div><div style="text-align:center"><b>$${formatNumber(
            this.point.y
          )}</b></div></div>`;
        },
      },
      chart: {
        type: "area",
        width: element.props.isMobile ? 75 : 120,
      },
    };

    return <SparkLine options={options} />;
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>{this.toSparkLine(this.props.children)}</tr>
        </tbody>
      </table>
    );
  }
}

export default SparkLineTable;
