import { type TierWithRef } from "../../Context/TierProvider";

export const isAllowedToOnboardStandaloneAsset = (
  permissions: { doitEmployee: boolean; billingProfilesAdmin: boolean },
  state: { startTrialInitiated?: boolean; isStandalone: boolean; navigatorTier?: TierWithRef }
) =>
  (permissions.doitEmployee || permissions.billingProfilesAdmin) &&
  (!state.isStandalone ||
    state.startTrialInitiated ||
    state.navigatorTier?.trialTier ||
    state.navigatorTier?.type !== "internal");
