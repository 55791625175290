import CheckIcon from "@mui/icons-material/Check";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { type SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import styled from "styled-components";

const CloseCircleSharpIcon = styled(CloseIcon)`
  background: #d32f30;
  border-radius: 100%;
  color: white;
`;

export enum IconTypes {
  Check = "check",
  Close = "close",
  Warning = "warning",
  CheckCircleSharp = "checkCircleSharp",
  CloseCircleSharp = "CloseCircleSharp",
}

export const Icons = {
  [IconTypes.Check]: CheckIcon,
  [IconTypes.CheckCircleSharp]: CheckCircleSharpIcon,
  [IconTypes.Close]: CloseIcon,
  [IconTypes.Warning]: WarningIcon,
  [IconTypes.CloseCircleSharp]: CloseCircleSharpIcon,
};

type IconProps = {
  icon: IconTypes;
  color: SvgIconTypeMap["props"]["color"];
  fontSize: SvgIconTypeMap["props"]["fontSize"];
};

export const Icon = ({ icon, color, fontSize }: IconProps) => {
  const I = Icons[icon];
  return <I {...{ color, fontSize }} />;
};

export const StepIcons = [
  undefined,
  <Icon key={IconTypes.Warning} icon={IconTypes.Warning} color="warning" fontSize="small" />,
  <CloseCircleSharpIcon key={IconTypes.CloseCircleSharp} fontSize="small" />,
  <Icon key={IconTypes.CheckCircleSharp} icon={IconTypes.CheckCircleSharp} color="success" fontSize="small" />,
];

export const SubStepIcons = [
  undefined,
  <Icon key={IconTypes.Check} icon={IconTypes.Check} color="success" fontSize="small" />,
  <Icon key={IconTypes.Close} icon={IconTypes.Close} color="error" fontSize="small" />,
];
