import { type FC } from "react";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Box, IconButton, useTheme } from "@mui/material";
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getBezierPath } from "@xyflow/react";

const GhostEdge: FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    palette: { primary, divider },
  } = useTheme();

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ stroke: divider }} />
      <EdgeLabelRenderer>
        <Box
          sx={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${targetX}px,${targetY}px)`,
          }}
        >
          <IconButton
            sx={{
              p: 0,
              "&:hover": {
                color: primary.main,
                backgroundColor: primary.contrastText,
              },
              pointerEvents: "all",
              backgroundColor: primary.contrastText,
            }}
          >
            <ControlPointIcon />
          </IconButton>
        </Box>
      </EdgeLabelRenderer>
    </>
  );
};

export default GhostEdge;
