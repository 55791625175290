import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router";
import { type LocationDescriptor, type LocationDescriptorObject } from "history";

export type ReferrerHistoryState = {
  referrer: string;
};

function isLocationDescriptorObject(
  maybeLocationDescriptor: LocationDescriptor<ReferrerHistoryState>
): maybeLocationDescriptor is LocationDescriptorObject<ReferrerHistoryState> {
  return typeof maybeLocationDescriptor === "object";
}

export function createLocationDescriptorWithReferrer(
  pathnameOrLocationDescriptor: LocationDescriptor<ReferrerHistoryState>
): LocationDescriptor<ReferrerHistoryState> {
  const stateWithReferrer = {
    referrer: location.pathname,
  };

  return isLocationDescriptorObject(pathnameOrLocationDescriptor)
    ? { ...pathnameOrLocationDescriptor, state: { ...pathnameOrLocationDescriptor.state, ...stateWithReferrer } }
    : {
        pathname: pathnameOrLocationDescriptor,
        state: stateWithReferrer,
      };
}

const referrerStorageKey = "go-back-to-referrer";

export function useGoToURLWithReferrer(url?: string): () => void {
  const history = useHistory<ReferrerHistoryState>();
  return useCallback(() => history.push(url || "", { referrer: history.location.pathname }), [history, url]);
}

export function useGoBackToReferrer(fallbackURL: string): () => void {
  const history = useHistory<ReferrerHistoryState>();
  const [referrer] = useState(
    history.location.state?.referrer || sessionStorage.getItem(referrerStorageKey) || fallbackURL
  );

  useEffect(() => {
    sessionStorage.setItem(referrerStorageKey, referrer);

    return () => sessionStorage.removeItem(referrerStorageKey);
  }, [referrer]);

  return useCallback(() => history.push(referrer), [history, referrer]);
}
