import { useMemo } from "react";

import { type AccessLevel, AccessLevels, type CloudConnectCategory } from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import find from "lodash/find";

import { tiersText } from "../assets/texts";

export type CategoryCheckboxProps = {
  category: CloudConnectCategory;
  accessLevel: AccessLevel;
  handleChange: (category: CloudConnectCategory, event: any) => void;
  selectedCategories: CloudConnectCategory[];
  isNotEntitledBQLens: boolean;
};

export const CategoryCheckbox = ({
  category,
  accessLevel,
  handleChange,
  selectedCategories,
  isNotEntitledBQLens,
}: CategoryCheckboxProps) => {
  const isCategoryDisabled = useMemo(() => {
    if (accessLevel === AccessLevels.PROJECT) {
      return !category.allowPartialAccessLevel;
    }
    return category.id === "core" || isNotEntitledBQLens;
  }, [accessLevel, category.allowPartialAccessLevel, category.id, isNotEntitledBQLens]);

  return (
    <Tooltip
      title={isNotEntitledBQLens ? tiersText.UPGRADE_SUBSCRIPTION(category.name) : ""}
      arrow={true}
      placement="top"
    >
      <span>
        <FormControlLabel
          aria-label={category.name}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          disabled={isCategoryDisabled}
          control={
            <Checkbox
              color="primary"
              checked={!isNotEntitledBQLens && !!find(selectedCategories, { name: category.name })}
              onChange={(e) => handleChange(category, e)}
            />
          }
          label={category.name}
        />
      </span>
    </Tooltip>
  );
};

export default CategoryCheckbox;
