import { type ReactNode, useState } from "react";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { type AccordionProps } from "@mui/material/Accordion";
import { type AccordionDetailsProps } from "@mui/material/AccordionDetails/AccordionDetails";
import { type AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { type Theme, useTheme } from "@mui/material/styles";
import Typography, { type TypographyProps } from "@mui/material/Typography";

export type FAQ = {
  question: string;
  answer: ReactNode;
};

type Decorations = {
  QuestionTypographyProps?: Omit<TypographyProps, "children">;
  AnswerTypographyProps?: Omit<TypographyProps, "children">;
  AccordionProps?: Omit<AccordionProps, "children">;
  AccordionSummaryProps?: Omit<AccordionSummaryProps, "children">;
  AccordionDetailsProps?: Omit<AccordionDetailsProps, "children">;
};

type Props = {
  faqs: FAQ[];
  showUnderlines?: boolean;
  props?: Decorations;
};

type Skin = (theme: Theme) => Decorations;

const originalSkin: Skin = (theme) => ({
  QuestionTypographyProps: {
    variant: "subtitle1",
    fontWeight: 500,
  },
  AnswerTypographyProps: {
    variant: "body2",
    color: "text.secondary",
  },
  AccordionSummaryProps: {
    expandIcon: <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />,
  },
  AccordionProps: {
    elevation: 0,
    square: true,
    disableGutters: true,
    sx: {
      justifyContent: "flex-start",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.divider,
      "&:before": {
        display: "none",
      },
    },
  },
});

export const newSkin: Skin = (theme) => ({
  QuestionTypographyProps: {
    variant: "body1",
    color: theme.palette.text.primary,
  },
  AnswerTypographyProps: {
    variant: "body2",
    color: theme.palette.text.secondary,
    mb: 1,
  },
  AccordionSummaryProps: {
    expandIcon: <ExpandMoreIcon />,
    sx: {
      boxShadow: 0,
    },
  },
  AccordionProps: {
    elevation: 1,
    disableGutters: true,
    sx: {
      borderStyle: "solid",
      boxShadow: 0,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 0,
      borderColor: theme.palette.general.divider,
      "&:last-child": {
        borderBottomWidth: 1,
      },
    },
  },
});

export const FAQAccordion = ({ faqs, showUnderlines = true, props }: Props) => {
  const theme = useTheme();
  const {
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummaryProps,
    AnswerTypographyProps,
    QuestionTypographyProps,
  } = props || originalSkin(theme);
  const [expanded, setExpanded] = useState<number | false>(false);
  const handleChange = (panel: number) => (_, newExpanded: boolean) => setExpanded(newExpanded ? panel : false);

  return (
    <Stack>
      {faqs.map(({ question, answer }, index) => (
        <Accordion
          key={question}
          expanded={expanded === index}
          onChange={handleChange(index)}
          {...AccordionProps}
          sx={{
            borderBottomWidth: showUnderlines ? 1 : 0,
            ...AccordionProps?.sx,
          }}
        >
          <AccordionSummary {...AccordionSummaryProps}>
            <Typography {...QuestionTypographyProps}>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails {...AccordionDetailsProps}>
            {typeof answer === "string"
              ? answer.split("\n").map((text, i) => (
                  <Typography key={i} {...AnswerTypographyProps}>
                    {text}
                  </Typography>
                ))
              : answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};
