import { type JSX, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { CurrencyCodes } from "@doitintl/cmp-models";
import { Box, Card, Grid, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

import AWSLogo from "../../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../../assets/aws-logo-dark-mode.svg";
import GCPLogo from "../../../../assets/gcp-logo.svg";
import Hide from "../../../../Components/HideChildren/Hide";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import useGetRampPlanChartData from "../../../../Pages/RampPlans/hooks/useGetRampPlanChartData";
import { formatCurrency, getDateTimeFromFirestoreTimestamp } from "../../../../utils/common";
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";
import FutureRampPlanAlert from "../../components/FutureRampPlanAlert";
import PerformanceChart from "../../components/PerformanceChart";
import { StatusChip } from "../../components/StatusChips";
import { scopes } from "../../consts";
import { computeTrueEndDate } from "../../RampPlan/PeriodTables/Tables/getInitPeriodsDataFromContract";
import { type RampPlanModel, type RampPlanStatus } from "../../types";
import { timestampToFormat } from "../../utils";
import { item } from "../itemDetails";

type RampPlanCardProps = {
  rampPlan: RampPlanModel;
  index: number;
};

const RampPlanCard = ({ rampPlan, index }: RampPlanCardProps) => {
  const {
    palette: { primary },
  } = useTheme();
  const isDarkMode = useDarkThemeCheck();
  const history = useHistory();

  const statusChip: Record<RampPlanStatus, JSX.Element> = {
    active: <StatusChip status="active" data-cy="statusChipActive" />,
    inactive: <StatusChip status="inactive" data-cy="statusChipInactive" />,
    notStarted: <StatusChip status="notStarted" data-cy="statusChipNotStarted" />,
  };

  const { customer } = useCustomerContext();
  const location = useLocation();

  const isCustomerPath = location.pathname.startsWith(`/customers/`);

  const rampPlanItem = useMemo(
    () => item(rampPlan, customer.id, isCustomerPath),
    [customer.id, isCustomerPath, rampPlan]
  );

  const timeRemaining = useMemo(() => {
    const timeDiffObject = DateTime.fromJSDate(rampPlan.origEstEndDate.toDate())
      .toUTC()
      .plus({ days: 1 }) // a 12-month plan starting on 1 Jan and ending 31 Dec shows "11 months"
      .diff(DateTime.now().toUTC(), "months")
      .toObject();

    let timeRemainingString = "";
    if (timeDiffObject.months && timeDiffObject.months < 0) {
      return "Completed";
    }
    if (timeDiffObject.months) {
      timeRemainingString += `${Math.floor(timeDiffObject.months)} months`;
    }
    return timeRemainingString;
  }, [rampPlan.origEstEndDate]);

  const { actualDataForChart, planDataForChart, originalPlanForChart } = useGetRampPlanChartData(rampPlan);
  const mtn = 1;
  const valueRemaining = useMemo(() => {
    if (!rampPlanItem.totalActuals) {
      return formatCurrency(rampPlan.targetAmount, CurrencyCodes.USD);
    }
    const remaining = rampPlan.targetAmount - rampPlanItem.totalActuals;
    const displayValue = formatCurrency(remaining >= 0 ? remaining : 0, CurrencyCodes.USD);

    return remaining > 0 ? displayValue : "Commitment reached";
  }, [rampPlan.targetAmount, rampPlanItem.totalActuals]);

  const logo = useMemo(() => {
    if (rampPlanItem.platform === "Google Cloud") {
      return GCPLogo;
    }
    return isDarkMode ? AWSLogoDark : AWSLogo;
  }, [isDarkMode, rampPlanItem.platform]);

  const startDateTime = useMemo<DateTime>(
    () => getDateTimeFromFirestoreTimestamp(rampPlan.startDate).toUTC().startOf("day"),
    [rampPlan.startDate]
  );

  const futureRampPlan = useMemo<boolean>(() => startDateTime > DateTime.now().toUTC(), [startDateTime]);

  const rampPlanStatus = useMemo<RampPlanStatus>(() => {
    if (futureRampPlan) {
      return "notStarted";
    }

    return timeRemaining === "Completed" ? "inactive" : "active";
  }, [futureRampPlan, timeRemaining]);

  const isAtTopOfScreen = index < 3;
  const [visible, setVisible] = useState(isAtTopOfScreen);

  const ref = useIntersectionObserver({
    onEnter: () => setVisible(true),
    onExit: () => setVisible(false),
  });

  return (
    <div ref={ref}>
      <Card
        sx={{ my: 2, padding: 3, cursor: "pointer", "&:hover": { borderColor: primary.main } }}
        onClick={() => history.push(`/customers/${rampPlan.customerRef?.id}/contracts/ramps/${rampPlan.id}`)}
        data-cy="rampPlanCard"
      >
        {futureRampPlan && (
          <Box mb={2}>
            <FutureRampPlanAlert startDateTime={startDateTime} />
          </Box>
        )}
        <Grid container xs={12}>
          <Grid item sm={12} md={5}>
            <Stack>
              <Typography variant="h4" data-cy="title" sx={{ mb: 2 }}>
                <Box component="img" src={logo} sx={{ height: "32px", width: "32px", mb: -1.23, mr: 1 }} />
                {rampPlan.name}
              </Typography>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="statusLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Status:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  {statusChip[rampPlanStatus]}
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="contractDurationLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Contract duration:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="contractDuration">{`${timestampToFormat(
                    rampPlan.startDate,
                    "d MMM yy"
                  )} to ${computeTrueEndDate(DateTime.fromSeconds(rampPlan.origEstEndDate.seconds).toUTC()).toFormat(
                    "d MMM yy"
                  )}`}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="timeRemainingLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Time remaining:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="timeRemaining">{timeRemaining}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="commitmentValueLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Commitment value:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="commitmentValue">
                    {formatCurrency(rampPlan.targetAmount, CurrencyCodes.USD)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="spendToDateLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Spend to date:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="spendToDate">
                    {formatCurrency(rampPlanItem.totalActuals, CurrencyCodes.USD)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="remainingCommitmentLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Remaining commitment:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="remainingCommitment">{valueRemaining}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: mtn }}>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="scopeLabel" sx={{ fontWeight: "medium", mr: 1 }}>
                    Scope:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography data-cy="scope">{`${scopes[rampPlan.platform]} ramp plans`}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item sm={12} md={7}>
            <Hide mdDown>
              <Box data-cy="rampPlanChart">
                {visible ? (
                  <PerformanceChart
                    actualDataForChart={actualDataForChart}
                    planDataForChart={planDataForChart}
                    originalPlanDataForChart={originalPlanForChart ?? null}
                    enableAnimations={false}
                    performanceChartProps={{
                      cardProps: { sx: { border: "none", width: "100%" } },
                      options: {},
                    }}
                  />
                ) : (
                  <div style={{ width: "100%", height: 320 }} />
                )}
              </Box>
            </Hide>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RampPlanCard;
