import { type Dispatch, type JSX, type SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import capitalize from "lodash/capitalize";

import { useKeyPress } from "../../../../Components/hooks/useKeyPress";
import { useHotkeyContext } from "../../../../Context/HotkeyContext";
import { usePrevious } from "../../../../utils/usePrevious";
import { Positions } from "../../utilities";

interface ReportsAccordionProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
  defaultExpanded?: boolean;
  appCues?: string;
  numOfItems: number;
  popoverComponent?: JSX.Element;
  popoverAnchorEl?: any;
  setPopoverAnchorEl?: Dispatch<SetStateAction<any>>;
  position?: string;
  disableExpand?: boolean;
  disableHotKeys?: boolean;
  disableAdd?: boolean;
  onExpand?: (state: boolean) => void;
  addIconTooltip?: string;
}

const ReportsAccordion = ({
  title,
  children,
  defaultExpanded,
  appCues,
  numOfItems,
  popoverComponent,
  popoverAnchorEl,
  setPopoverAnchorEl,
  position,
  disableExpand = false,
  disableHotKeys,
  disableAdd,
  onExpand,
  addIconTooltip,
}: ReportsAccordionProps) => {
  const [expanded, setExpanded] = useState(!!defaultExpanded);
  const popoverAnchorRef = useRef<HTMLButtonElement | null>(null);
  const { state: hotKeysEnabled } = useHotkeyContext();
  const rowShortcutKey = useKeyPress("g", { shiftKey: true });
  const columnShortcutKey = useKeyPress("d", { shiftKey: true });
  const filtersShortcutKey = useKeyPress("f", { shiftKey: true });
  const prevNumOfItems = usePrevious(numOfItems);
  const canExpand = !disableExpand && numOfItems > 0;

  useEffect(() => {
    if (prevNumOfItems !== undefined && numOfItems > prevNumOfItems) {
      setExpanded(true);
      onExpand?.(true);
    }
  }, [numOfItems, prevNumOfItems, onExpand]);

  useEffect(() => {
    if (disableHotKeys || !hotKeysEnabled || !popoverAnchorRef.current) {
      return;
    }
    // setTimeout used to prevent hotkey being typed into popover
    if (rowShortcutKey && !popoverAnchorEl?.mdRow && position === Positions.ROW) {
      setTimeout(() => popoverAnchorRef.current?.click(), 100);
    }
    if (columnShortcutKey && !popoverAnchorEl?.mdCol && position === Positions.COL) {
      setTimeout(() => popoverAnchorRef.current?.click(), 100);
    }
    if (filtersShortcutKey && !popoverAnchorEl?.mdUnused && position === Positions.UNUSED) {
      setTimeout(() => popoverAnchorRef.current?.click(), 100);
    }
    if (filtersShortcutKey && !popoverAnchorEl?.mdCount && position === Positions.COUNT) {
      setTimeout(() => popoverAnchorRef.current?.click(), 100);
    }
  }, [
    columnShortcutKey,
    disableHotKeys,
    filtersShortcutKey,
    hotKeysEnabled,
    popoverAnchorEl,
    position,
    rowShortcutKey,
  ]);

  const handleExpand = useCallback(() => {
    setExpanded((val) => {
      onExpand?.(!val);
      return !val;
    });
  }, [onExpand]);

  const handlePopover = useCallback(
    ({ currentTarget }) => {
      if (!setPopoverAnchorEl) {
        return;
      }
      if (position) {
        setPopoverAnchorEl({ [`md${capitalize(currentTarget.id)}`]: currentTarget });
        return;
      }
      setPopoverAnchorEl(currentTarget);
    },
    [setPopoverAnchorEl, position]
  );

  return (
    <Accordion
      data-cy="reports-accordion"
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      data-appcues={appCues}
      sx={{
        marginBottom: "1.5rem",
        "&:before": {
          display: "none",
        },
        "& > .MuiAccordionSummary-root": {
          padding: 0,
          minHeight: "1.5rem",
          height: "1.5rem",
          marginBottom: "0.5rem",
          cursor: "pointer",
          "&.Mui-focusVisible": {
            backgroundColor: "unset",
          },
          "& > .MuiAccordionSummary-content": {
            margin: 0,
            alignItems: "center",
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: 0,
        },
      }}
    >
      <AccordionSummary expandIcon={canExpand && !popoverAnchorEl && <ExpandMoreIcon onClick={handleExpand} />}>
        <Typography
          variant="subtitle2"
          data-cy="reports-accordion-title"
          onClick={canExpand ? handleExpand : undefined}
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        {!!popoverComponent && !!setPopoverAnchorEl && (
          <>
            <Tooltip title={addIconTooltip}>
              <IconButton
                size="small"
                id={position}
                ref={popoverAnchorRef}
                onClick={handlePopover}
                data-cy="add-icon-button"
                disabled={disableAdd}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            {popoverComponent}
          </>
        )}
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ReportsAccordion;
