import { UserPermissions } from "@doitintl/cmp-models";

import { useUserQuickLinks } from "../../Components/Dashboard/QuickLinks/useUserQuickLinks";
import { useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { QuickLinks } from "./QuickLinks";
import { FeatureCardContent } from "./QuickLinks/FeatureCardContent";

const QuickLinksSection = () => {
  const [quickLinks, quickLinksLoaded] = useUserQuickLinks();
  const { isFeatureEntitled } = useTier();
  const isCustomReportsAllowed = isFeatureEntitled("analytics:reports");
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasCloudAnalyticsPermission = userRoles.permissions.has(UserPermissions.CloudAnalytics);
  const hasAvaReportsGenerationPermission = isFeatureEntitled("platform:ava:reports");

  return (
    <QuickLinks quickLinks={quickLinks.slice(0, 4)} quickLinksLoaded={quickLinksLoaded}>
      <FeatureCardContent
        hasCloudAnalyticsPermission={hasCloudAnalyticsPermission && isCustomReportsAllowed}
        hasAvaReportsGenerationPermission={hasAvaReportsGenerationPermission}
      />
    </QuickLinks>
  );
};

export default QuickLinksSection;
