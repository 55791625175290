import { Box, Typography } from "@mui/material";

import { settingsGoogleCloudTexts } from "../../../../assets/texts";
import { GKEConfigSubStepStatus } from "./enums";
import { SubStepIcons } from "./Icons";
import { type GKEConfigSubStep } from "./types";

export type Props = {
  subStep: GKEConfigSubStep;
};

const SubStep = ({ subStep }: Props) => {
  const Icon = SubStepIcons[Object.values(GKEConfigSubStepStatus).findIndex((v) => v === subStep.status) || 0];
  return (
    <>
      <Box display="flex">
        {Icon}
        {!subStep.invalidDatasets && (
          <Typography component="div" variant="body2" color="textPrimary" sx={{ marginLeft: 1 }}>
            {subStep.name}
          </Typography>
        )}
        {subStep.invalidDatasets && (
          <Typography component="div" variant="body2" color="textPrimary" sx={{ marginLeft: 1 }}>
            {subStep.name} - {settingsGoogleCloudTexts.GKE_CONFIG_TEXTS.MISSING_DATA_VIEWER_ROLE_ON_DATASET}
          </Typography>
        )}
      </Box>
      {subStep.invalidDatasets?.map((sub, i) => (
        <Box key={i} display="flex" sx={{ marginLeft: 2 }}>
          {Icon}

          <Typography component="div" variant="body2" color="textPrimary" sx={{ marginLeft: 1 }}>
            {sub}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default SubStep;
