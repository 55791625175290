import { useCallback, useMemo } from "react";

import { Link as RouteLink } from "react-router-dom";
import { Box, TableCell, Typography } from "@mui/material";
import { useClipboard } from "use-clipboard-copy";

import { globalText, marketplaceText } from "../../../../assets/texts";
import { useInfoSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { type entitlementsTableRowType } from "../types";
import { entitlementOptions } from "./utils";

type propsType = {
  row: entitlementsTableRowType;
  setOpenRejectDialog: (open: boolean) => void;
  setOpenApproveDialog: (open: boolean) => void;
  setClickedRow: (row: entitlementsTableRowType) => void;
};

export function AWSEntitlementRow({ row, setOpenRejectDialog, setOpenApproveDialog, setClickedRow }: propsType) {
  const infoSnackbar = useInfoSnackbar();

  const clipboard = useClipboard({
    copiedTimeout: 1400,
    onSuccess: () => infoSnackbar(globalText.COPIED_TO_CLIPBOARD),
  });

  const handleApprove = useCallback(() => {
    setOpenApproveDialog(true);
    setClickedRow(row);
  }, [row, setClickedRow, setOpenApproveDialog]);

  const handleReject = useCallback(() => {
    setOpenRejectDialog(true);
    setClickedRow(row);
  }, [row, setClickedRow, setOpenRejectDialog]);

  const handleCopyEntitlementId = useCallback(() => {
    clipboard.copy(row.accountId);
  }, [clipboard, row.accountId]);

  const actionMenu = useMemo(
    () => ({
      [globalText.APPROVE]: handleApprove,
      [globalText.REJECT]: handleReject,
      [marketplaceText.COPY_ENTITLEMENT_ID]: handleCopyEntitlementId,
    }),
    [handleApprove, handleCopyEntitlementId, handleReject]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      entitlementOptions
        .map((option) => {
          if (option.status && !option.status.includes(row.state)) {
            return {};
          }
          return {
            label: <Typography color={option.color}>{option.value}</Typography>,
            action: actionMenu[option.value],
            key: option.value,
          };
        })
        .filter((option) => !!option.label),
    [actionMenu, row.state]
  );

  return (
    <>
      <TableCell>
        <Typography color="inherit" variant="body2" component={RouteLink} to={`/customers/${row.customerId}`}>
          {row.customerName}
        </Typography>
      </TableCell>
      <TableCell>{row.product}</TableCell>
      <TableCell>{row.createdAt}</TableCell>
      <TableCell>{row.updatedAt}</TableCell>
      <TableCell>{row.state}</TableCell>
      <TableCell>
        <Box m={-1.5}>
          <ThreeDotsMenu options={threeDotsMenuOptions} />
        </Box>
      </TableCell>
    </>
  );
}
