import { useMemo } from "react";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { isAllowedToOnboardStandaloneAsset } from "../../Navigation/config/utils";

export default function useIsAllowedToOnboardAzure() {
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext();

  return useMemo(
    () =>
      isAllowedToOnboardStandaloneAsset(
        { doitEmployee: isDoitEmployee, billingProfilesAdmin: !!userRoles?.billingProfilesAdmin },
        {
          startTrialInitiated: customer?.onboarding?.startTrialInitiated,
          isStandalone: isProductOnlyCustomer,
        }
      ),
    [customer, isDoitEmployee, userRoles?.billingProfilesAdmin, isProductOnlyCustomer]
  );
}
