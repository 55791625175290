import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  DoitRole,
} from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Avatar, Box, Card, CardContent, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import AWSLogo from "../../assets/aws-logo.svg";
import AzureLogo from "../../assets/azure-logo.svg";
import GSuiteLogo from "../../assets/g-suite.png";
import GCPLogo from "../../assets/gcp-logo.svg";
import DoitLogoWhite from "../../assets/logo-white.svg";
import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { type PerksWData } from "../../types";
import { formatFirestoreDate } from "../CloudAnalytics/utilities";

type Props = {
  perk: PerksWData;
};

const marketplaceMap = {
  [AssetTypeAmazonWebServices]: {
    title: "AWS",
    logo: AWSLogo,
  },
  [AssetTypeGoogleCloud]: { title: "GCP", logo: GCPLogo },
  [AssetTypeGSuite]: { title: "Google Workspace", logo: GSuiteLogo },
  [AssetTypeMicrosoftAzure]: { title: "Microsoft Azure", logo: AzureLogo },
};

export default function PerkCard({ perk }: Props) {
  const isPerksAdmin = useDoitRoleCheck(DoitRole.PerksAdmin);

  const history = useHistory();
  const theme = useTheme();

  const goToPerkPage = useCallback(() => {
    history.push(`${history.location.pathname?.replace(/\/+$/, "")}/${perk.id}`);
  }, [history, perk.id]);

  const infoTooltip = useMemo(
    () => (
      <>
        <div>Edited: {formatFirestoreDate(perk.data.timeModified, DateTime.DATETIME_MED)}</div>
        {perk.data.timePublished && (
          <div>First published: {formatFirestoreDate(perk.data.timePublished, DateTime.DATETIME_MED)}</div>
        )}
      </>
    ),
    [perk.data.timeModified, perk.data.timePublished]
  );

  return (
    <Card
      data-testid="perk-card"
      onClick={goToPerkPage}
      sx={{ cursor: "pointer", "&:hover": { border: "1px solid", borderColor: theme.palette.primary.main } }}
    >
      <CardContent sx={{ "&:last-child": { pb: 2 } }}>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" spacing={2} sx={{ minHeight: "85px" }}>
              <Avatar
                src={perk.data.enrichment?.logoUrl ? perk.data.enrichment?.logoUrl : DoitLogoWhite}
                imgProps={{ sx: { objectFit: "contain", bgcolor: "white" } }}
                sx={{
                  height: "64px",
                  width: "64px",
                  alignSelf: "center",
                  border: `0.5px solid ${theme.palette.grey[300]}`,
                }}
              />

              <Typography variant="body1" sx={{ alignSelf: "center" }}>
                {perk.data.fields.title}{" "}
                {isPerksAdmin && (
                  <Tooltip data-cy="timestamp-info" title={infoTooltip} placement="right">
                    <InfoOutlinedIcon color="action" fontSize="inherit" sx={{ marginBottom: "-3px" }} />
                  </Tooltip>
                )}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              {!perk.data.fields.active && (
                <Chip
                  label="Draft"
                  size="small"
                  sx={{
                    color: theme.palette.common.white,
                    backgroundColor: "navigation.accent",
                  }}
                />
              )}
            </Stack>
          </Box>
        </Stack>

        <Box sx={{ minHeight: "105px", marginTop: 1 }}>
          <Typography
            variant="h4"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {perk.data.fields.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: 1,
              color: "text.secondary",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {perk.data.fields.shortDescription}
          </Typography>
        </Box>
        <Box
          sx={{
            minHeight: "30px",
            display: "flex",
            flexFlow: "initial",
            alignItems: "self-end",
          }}
        >
          <Typography variant="caption">
            <Stack direction="row" spacing={1}>
              {perk.data.fields.platform.map((platform) => (
                <Box key={platform} sx={{ bgcolor: theme.palette.grey[100], borderRadius: 6 }}>
                  <img
                    src={marketplaceMap[platform]?.logo}
                    alt="logo"
                    height="20px"
                    width="20px"
                    style={{ margin: theme.spacing(0.25, 1, -0.5, 1) }}
                  />
                </Box>
              ))}
            </Stack>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
