export enum CurrencyCodes {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  ILS = "ILS",
  AUD = "AUD",
  CAD = "CAD",
  DKK = "DKK",
  NOK = "NOK",
  SEK = "SEK",
  BRL = "BRL",
  SGD = "SGD",
  MXN = "MXN",
  CHF = "CHF",
  MYR = "MYR",
  TWD = "TWD",
  EGP = "EGP",
  ZAR = "ZAR",
  JPY = "JPY",
  IDR = "IDR",
  THB = "THB",
  COP = "COP",
}
export type CurrencyCode = `${CurrencyCodes}`;
export type OldCurrency = "AUD" | "EUR" | "GBP" | "USD";

export const Currencies = [
  { value: CurrencyCodes.USD, symbol: "$" },
  { value: CurrencyCodes.ILS, symbol: "₪" },
  { value: CurrencyCodes.EUR, symbol: "€" },
  { value: CurrencyCodes.GBP, symbol: "£" },
  { value: CurrencyCodes.AUD, symbol: "A$" },
  { value: CurrencyCodes.CAD, symbol: "C$" },
  { value: CurrencyCodes.DKK, symbol: "kr" },
  { value: CurrencyCodes.NOK, symbol: "kr" },
  { value: CurrencyCodes.SEK, symbol: "kr" },
  { value: CurrencyCodes.BRL, symbol: "R$" },
  { value: CurrencyCodes.SGD, symbol: "S$" },
  { value: CurrencyCodes.MXN, symbol: "MX$" },
  { value: CurrencyCodes.CHF, symbol: "Fr." },
  { value: CurrencyCodes.MYR, symbol: "RM" },
  { value: CurrencyCodes.TWD, symbol: "NT$" },
  { value: CurrencyCodes.EGP, symbol: "E£" },
  { value: CurrencyCodes.ZAR, symbol: "R" },
  { value: CurrencyCodes.JPY, symbol: "¥" },
  { value: CurrencyCodes.IDR, symbol: "Rp" },
  { value: CurrencyCodes.THB, symbol: "฿" },
  { value: CurrencyCodes.COP, symbol: "COL$" },
] as const;

export type CurrencySymbol = (typeof Currencies)[number]["symbol"];
export const CurrenciesMap = {
  [CurrencyCodes.USD]: "$",
  [CurrencyCodes.ILS]: "₪",
  [CurrencyCodes.EUR]: "€",
  [CurrencyCodes.GBP]: "£",
  [CurrencyCodes.AUD]: "A$",
  [CurrencyCodes.CAD]: "C$",
  [CurrencyCodes.DKK]: "kr",
  [CurrencyCodes.NOK]: "kr",
  [CurrencyCodes.SEK]: "kr",
  [CurrencyCodes.BRL]: "R$",
  [CurrencyCodes.SGD]: "S$",
  [CurrencyCodes.MXN]: "MX$",
  [CurrencyCodes.CHF]: "Fr.",
  [CurrencyCodes.MYR]: "RM",
  [CurrencyCodes.TWD]: "NT$",
  [CurrencyCodes.EGP]: "E£",
  [CurrencyCodes.ZAR]: "R",
  [CurrencyCodes.JPY]: "¥",
  [CurrencyCodes.IDR]: "Rp",
  [CurrencyCodes.THB]: "฿",
  [CurrencyCodes.COP]: "COL$",
} satisfies Record<CurrencyCode, CurrencySymbol>;
