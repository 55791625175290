import { useCallback, useMemo, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { type EntitlementWithId } from "../types";
import { filters, headerColumns } from "./columns";
import { CreateEntitlementsDialog } from "./CreateEntitlementsDialog";
import EntitlementRowItem from "./EntitlementRowItem";

type Props = {
  entitlements?: EntitlementWithId[];
  loading: boolean;
};

const EntitlementsTable = ({ entitlements, loading }: Props) => {
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const [open, setOpen] = useState(false);
  const [selectedEntitlement, setSelectedEntitlement] = useState<EntitlementWithId | null>(null);
  const validTiers = useMemo(() => entitlements?.filter((tier: EntitlementWithId) => tier.key), [entitlements]);

  const editEntitlementDetails = useCallback((entitlement: EntitlementWithId) => {
    setSelectedEntitlement(entitlement);
    setOpen(true);
  }, []);

  const handleCloseTierDialog = useCallback(() => {
    setSelectedEntitlement(null);
    setOpen(false);
  }, []);

  const TiersRowWrapper = useCallback(
    (props: { row: EntitlementWithId }) => (
      <EntitlementRowItem row={props.row} onEditEntitlementDetails={editEntitlementDetails} />
    ),
    [editEntitlementDetails]
  );

  if (loading) {
    return <FilterTableSkeleton />;
  }

  return (
    <>
      <FilterTable<EntitlementWithId>
        toolbarProps={{
          title: "Entitlements",
          allowToEditColumns: true,
          primaryButton: {
            text: "Create new entitlement",
            onClick: () => setOpen(true),
            color: "primary",
            disabled: !isDoitOwnerRole,
          },
        }}
        defaultSortingColumnValue="type"
        defaultSortDirection="asc"
        tableItems={validTiers}
        showRowsSelection={false}
        rowComponent={TiersRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        persistenceKey="entitlements"
      />

      {open && (
        <CreateEntitlementsDialog
          handleClose={handleCloseTierDialog}
          open={open}
          selectedEntitlement={selectedEntitlement}
        />
      )}
    </>
  );
};

export default EntitlementsTable;
