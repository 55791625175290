import { useCallback, useState } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { DialogContent, Typography } from "@mui/material";

import { DATAHUB_LINK, datahubTxt } from "../../assets/texts/DataHub/datahub";
import { RadioCard } from "../../Components/RadioCard/RadioCard";
import SimpleDialog from "../../Components/SimpleDialog";

type ImportDataOption = {
  title: string;
  description: string;
  disabled: boolean;
  url?: string;
};

const IMPORT_DATA_OPTIONS: ImportDataOption[] = [
  {
    title: datahubTxt.DATAHUB_API_OPTION,
    description: datahubTxt.DATAHUB_API_OPTION_DESCRIPTION,
    disabled: false,
    url: DATAHUB_LINK,
  },
  {
    title: datahubTxt.CSV_OPTION,
    description: datahubTxt.CSV_OPTION_DESCRIPTION,
    disabled: false,
  },
  {
    title: datahubTxt.STORAGE_BUCKET_OPTION,
    description: datahubTxt.STORAGE_BUCKET_OPTION_DESCRIPTION,
    disabled: true,
  },
  {
    title: datahubTxt.BI_SYSTEM_OPTION,
    description: datahubTxt.BI_SYSTEM_OPTION_DESCRIPTION,
    disabled: true,
  },
];

type Props = {
  onClose: () => void;
  onOpenImportStepper: () => void;
};

const ImportDataDialog = ({ onClose, onOpenImportStepper }: Props) => {
  const [selectedOption, setSelectedOption] = useState(IMPORT_DATA_OPTIONS[0]);
  const isSendViaApi = selectedOption.title === datahubTxt.DATAHUB_API_OPTION;

  const onConfirm = useCallback(async () => {
    if (selectedOption.url) {
      window.open(selectedOption.url, "_blank");
    } else if (selectedOption.title === datahubTxt.CSV_OPTION) {
      onOpenImportStepper();
    }
    onClose();
  }, [onClose, onOpenImportStepper, selectedOption.title, selectedOption.url]);

  const onCardClick = useCallback(async (option: ImportDataOption) => {
    if (option.disabled) {
      return;
    }
    setSelectedOption(option);
  }, []);

  return (
    <SimpleDialog
      confirmButtonText={isSendViaApi ? datahubTxt.GET_STARTED : datahubTxt.CONTINUE}
      confirmButtonIcon={isSendViaApi ? <OpenNewIcon fontSize="small" sx={{ ml: 1 }} /> : undefined}
      cancelButtonText={datahubTxt.CANCEL}
      onCancel={onClose}
      onConfirm={onConfirm}
      open={true}
      title={datahubTxt.IMPORT_DATA}
    >
      <DialogContent>
        <Typography variant="body1" mt={3} mb={3}>
          {datahubTxt.SELECT_OPTION}
        </Typography>
        {IMPORT_DATA_OPTIONS.map((option, index) => (
          <RadioCard
            key={index}
            title={option.title}
            titleCaption={option.disabled ? datahubTxt.COMING_SOON : ""}
            description={option.description}
            selected={option.title === selectedOption.title}
            onClick={() => onCardClick(option)}
            descriptionTypographyProps={{ variant: "body2" }}
            disabled={option.disabled}
            cardSx={{ minWidth: "auto", borderLeftWidth: option.title === selectedOption.title ? 2 : 1 }}
          />
        ))}
      </DialogContent>
    </SimpleDialog>
  );
};

export default ImportDataDialog;
