import { type JSX, useCallback, useMemo, useState } from "react";

import { type EmptyReportFallback } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { jsUcfirst } from "../../utils/common";
import { SafeHtml } from "../SafeHtml";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";

export type OptionalEmptyWidgetHeaderConfigs = {
  widgetTitle: string;
  widgetSubtitle: string;
};

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
    borderBottomColor: theme.palette.general.divider,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

const EmptyWidgetCard = ({
  name,
  onBeforeNavigate,
  customMessageSettings,
  widgetHeaderConfigs,
  action,
  noData,
  title,
  subheader,
  widgetHeight,
}: {
  name: string;
  onBeforeNavigate?: (url: string) => void;
  action?: JSX.Element;
  customMessageSettings?: EmptyReportFallback;
  widgetHeaderConfigs?: OptionalEmptyWidgetHeaderConfigs;
  noData?: boolean;
  title?: string;
  subheader?: string;
  widgetHeight: number;
}) => {
  const classes = useStyles();
  const { customer } = useCustomerContext();
  const { widgetsConfig } = useDashboardsContext();
  const [showTooltip, setShowTooltip] = useState(false);

  const widgetConfig = useMemo(() => widgetsConfig[name] ?? {}, [name, widgetsConfig]);

  const URL = useMemo((): string => {
    const buttonLink = customMessageSettings?.url ?? widgetConfig?.emptyState?.buttonLink ?? "";

    if (buttonLink.includes("{{")) {
      return buttonLink.replace("{{customerId}}", customer.id);
    }
    return buttonLink ?? "";
  }, [customMessageSettings?.url, widgetConfig?.emptyState?.buttonLink, customer.id]);

  const navigateToUrl = useCallback(
    (url: string) => {
      onBeforeNavigate?.(url);
      window.open(url, "_blank");
    },
    [onBeforeNavigate]
  );

  const handlePopoverOpen = useCallback((event) => {
    if (event.currentTarget.offsetWidth < event.currentTarget.scrollWidth) {
      setShowTooltip(true);
    }
  }, []);

  const handlePopoverClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const learnMoreButton = useCallback(
    (fallbackText: string, customText?: string) => (
      <Box sx={{ mt: 1 }}>
        <Button
          onClick={() => {
            navigateToUrl(URL);
          }}
          endIcon={!URL.startsWith(window.location.origin) ? <OpenNewIcon /> : undefined}
        >
          {customText ?? fallbackText}
        </Button>
      </Box>
    ),
    [URL, navigateToUrl]
  );

  const standardEmptyWidgetOutput = (
    <CardContent style={{ height: 200 * widgetHeight }} className={classes.cardContent}>
      <Stack>
        <span>
          <Typography component="div" color="textSecondary">
            {widgetConfig?.emptyState?.message && !noData ? widgetConfig.emptyState.message : "No Data"}
          </Typography>
          {!!widgetConfig?.emptyState?.description && !noData && (
            <Typography component="span" variant="body2" color="textSecondary">
              {widgetConfig.emptyState.description}
            </Typography>
          )}
        </span>
        {!!widgetConfig?.emptyState?.buttonLink &&
          !noData &&
          learnMoreButton("Learn more", widgetConfig?.emptyState?.buttonText)}
      </Stack>
    </CardContent>
  );

  const emptyWidgetWithCustomMessage = (
    <CardContent style={{ height: 200 * (widgetHeight ?? 1) }} className={classes.cardContent}>
      <Stack>
        <span>
          <Typography component="span" sx={{ maxWidth: "559px" }} color="textSecondary">
            <SafeHtml html={customMessageSettings?.text} />
          </Typography>
        </span>
        {!!customMessageSettings?.url && learnMoreButton("Learn more")}
      </Stack>
    </CardContent>
  );

  const subheaderContent = useMemo(() => {
    const content = subheader ?? jsUcfirst(widgetHeaderConfigs?.widgetSubtitle ?? widgetConfig?.cardSubheader);

    if (showTooltip) {
      return (
        <Tooltip title={content} placement="bottom">
          <span>{content}</span>
        </Tooltip>
      );
    } else {
      return content;
    }
  }, [showTooltip, subheader, widgetConfig?.cardSubheader, widgetHeaderConfigs?.widgetSubtitle]);

  return (
    <Grid>
      <Card>
        <CardHeader
          sx={{
            ".MuiCardHeader-content": {
              maxWidth: "calc(100% - 40px)",
              cursor: "pointer",
            },
          }}
          className={classes.cardHeader}
          title={title ?? widgetHeaderConfigs?.widgetTitle ?? widgetConfig?.cardTitle}
          titleTypographyProps={{ variant: HEADING_VARIANT, color: "text.disabled" }}
          subheader={subheaderContent}
          subheaderTypographyProps={{
            variant: SUBHEADING_VARIANT,
            noWrap: true,
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          }}
          action={action}
        />
        {customMessageSettings ? emptyWidgetWithCustomMessage : standardEmptyWidgetOutput}
        <div />
      </Card>
    </Grid>
  );
};

export default EmptyWidgetCard;
