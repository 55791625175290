import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { Redirect, useHistory } from "react-router-dom";
import {
  AnalyticsDataSource,
  type BudgetAlert,
  type BudgetConfig,
  type CloudAnalyticsModelBudgetModel,
  type Collaborators,
  type CurrencyCode,
  type PublicAccess,
  Renderer,
  type SlackChannel,
  type TimeInterval,
} from "@doitintl/cmp-models";
import { type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";
import CopyIcon from "@mui/icons-material/FileCopyRounded";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/ShareRounded";
import { Alert, Card, CardHeader, Collapse, Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import { type Method } from "axios";
import cloneDeep from "lodash/cloneDeep";
import concat from "lodash/concat";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { budgetText, httpMethods } from "../../../assets/texts";
import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import useBudgetInvestigation from "../../../Components/hooks/cloudAnalytics/budgets/useBudgetInvestigation";
import useEqualityCheck from "../../../Components/hooks/cloudAnalytics/budgets/useEqualityCheck";
import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import useQueryString from "../../../Components/hooks/useQueryString";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import SaveAsComponent from "../../../Components/SaveComponents/SaveAsComponent";
import SaveComponent from "../../../Components/SaveComponents/SaveComponent";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import {
  useErrorSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import Title from "../../../Components/Title/Title";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useTierLimitReachedAnalyticsGovernanceBudgets } from "../../../Context/AnalyticsTierProvider";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { useUserContext } from "../../../Context/UserContext";
import { type AttributionWRef, type Budget as BudgetType, type DraftBudget } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { sanitizeDate, TimestampFromDateTime } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import Invites from "../../IAM/InviteUserDialog/handleInvite";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import PreviewReport, { type PreviewData } from "../previewReport/PreviewReport";
import { getCols, getFilters, getRows } from "../previewReport/utils";
import { type CloudAnalyticsHistoryState } from "../types";
import { BudgetTypes, checkAllowedSubdomains, CloudAnalyticsEntities, getNewUsers, isEditor } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import BudgetAlerts from "./BudgetAlerts";
import BudgetConfiguration from "./BudgetConfiguration";
import BudgetSharing from "./BudgetSharing";
import budgetStyles from "./budgetStyles";
import BudgetUtilization from "./BudgetUtilization";
import { createNewBudget, updateBudget } from "./db";
import { useCopyBudget } from "./hooks";
import { getAlertAmountFromPercentage, shareBudget } from "./shared";
import { budgetStateReducer, getFixedTimeInterval, getTimeRangeOption } from "./utils";

const useStyles = budgetStyles;

type Props =
  | {
      budget: DraftBudget;
      isNewBudget: true;
    }
  | { isNewBudget: false; budget: BudgetType };

const maxDescriptionLength = 1000;
const maxTitleLength = 64;

export const Budget = ({ budget, isNewBudget }: Props) => {
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();
  const { filteredAttributions: attributions } = useAttributionsContext();
  const api = useApiContext();
  const { customerOrPresentationModeCustomer: customer, customer: genuineCustomer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { entities } = useEntitiesContext();
  const analyticsGovernanceBudgets = useTierLimitReachedAnalyticsGovernanceBudgets();
  const classes = useStyles();
  const theme = useTheme();
  const qs = useQueryString();
  const getInvestigationReportUrl = useBudgetInvestigation(budget);
  const [name, setName] = useState(budget.data.name);
  const [description, setDescription] = useState(budget.data.description);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [config, setConfig] = useState<BudgetConfig>(budget.data.config);
  const [initialConfig, setInitialConfig] = useState<BudgetConfig>(budget.data.config);
  const [saveButtonTooltipText, setSaveButtonTooltipText] = useState<string>("");
  const { handleMissingPermission, hasDataHub } = useAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const [subscribeDialogOpen, setSubscribeDialogOpen] = useState(qs.openTo === "unsubscribe");
  const [loading, setLoading] = useState<boolean>(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(budget.data.config.amount || 1000);
  const [alerts, setAlerts] = useState<BudgetAlert[]>(
    budget.data.config.alerts.map((a) => ({ ...a, amount: getAlertAmountFromPercentage(a.percentage, amount) }))
  );
  const [scope, setScope] = useState<AttributionWRef[]>(getAttributionScope(budget.data.config.scope, attributions));
  const [metric, setMetric] = useState<number>(budget.data.config.metric || 0);
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(budget.data.config.timeInterval);
  const [saved, setSaved] = useState(false);

  const [type, setType] = useState<BudgetTypes>(budget.data.config.type);
  const [currency, setCurrency] = useState<CurrencyCode>(budget.data.config.currency);
  const [recipients, setRecipients] = useState<string[]>(cloneDeep(budget.data.recipients));
  const [recipientsSlackChannels, setRecipientsSlackChannels] = useState<SlackChannel[]>(
    cloneDeep(budget.data.recipientsSlackChannels) || []
  );
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [previousAmount, setPreviousAmount] = useState<number>(budget.data.config.amount);
  const [usePrevSpend, setUsePrevSpend] = useState<boolean>(budget.data.config.usePrevSpend ?? false);
  const [allowGrowth, setAllowGrowth] = useState<boolean>(budget.data.config.allowGrowth);
  const [growthPerPeriod, setGrowthPerPeriod] = useState<number>(budget.data.config.growthPerPeriod);
  const [startPeriod, setStartPeriod] = useState<DateTime>(
    budget.data.config.startPeriod
      ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc())
  );
  const [endPeriod, setEndPeriod] = useState<DateTime>(
    budget.data.config.endPeriod
      ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc())
  );
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const cloneBudget = useCopyBudget({ budget: budget.data });
  const [renderer, setRenderer] = useState(budget.data.config.renderer ?? Renderer.STACKED_COLUMN_CHART);
  const [isInit, setIsInit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const isCreatePage = routeMatchURL.includes("create");
  const [dataSource, setDataSource] = useState<AnalyticsDataSource>(
    budget.data.config.dataSource ?? AnalyticsDataSource.BILLING
  );

  const budgetId = useMemo(() => (isNewBudget ? "" : budget.id), [budget, isNewBudget]);

  const equalityCheck = useEqualityCheck({
    current: {
      name,
      description,
      config,
    },
    initial: {
      name: budget.data.name,
      description: budget.data.description,
      config: initialConfig,
    },
    draft: Boolean(isNewBudget),
  });

  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();

  const isCurrentUserEditor = useMemo(() => isEditor(currentUser.email, budget.data), [budget, currentUser.email]);

  const isCurrentUserUserManager = userRoles.usersManager || userRoles.doitEmployee;

  useEffect(() => {
    if (isCurrentUserEditor && saved) {
      if (!hasUnsavedChanges) {
        setSaveButtonTooltipText(budgetTxt.ALL_CHANGES_ARE_SAVED);
      } else {
        setSaveButtonTooltipText("");
      }
    }
  }, [isCurrentUserEditor, saved, hasUnsavedChanges]);

  useEffect(() => {
    setInitialConfig(budget.data.config);
  }, [budget.data.config]);

  const prepareConfig = useCallback(() => {
    const originalAmount = () => {
      if (isNaN(amount)) {
        return 0;
      }
      return amount !== budget.data.config.amount ? amount : budget.data.config.originalAmount;
    };
    const config: WithFirebaseModel<BudgetConfig> = {
      metric,
      timeInterval,
      growthPerPeriod,
      type,
      currency,
      usePrevSpend,
      allowGrowth,
      amount,
      startPeriod: TimestampFromDateTime(startPeriod),
      originalAmount: originalAmount(),
      alerts: alerts.map((a, i) => ({
        percentage: a.percentage,
        triggered: a.triggered,
        forecastedDate: budget.data.config.alerts[i].forecastedDate,
      })),
      scope: scope.map((s) => s.ref),
      renderer,
      dataSource,
    };

    if (type === BudgetTypes.FIXED) {
      config.endPeriod = TimestampFromDateTime(endPeriod);
    }

    return config;
  }, [
    alerts,
    allowGrowth,
    amount,
    budget.data.config.alerts,
    budget.data.config.amount,
    budget.data.config.originalAmount,
    currency,
    dataSource,
    endPeriod,
    growthPerPeriod,
    metric,
    renderer,
    scope,
    startPeriod,
    timeInterval,
    type,
    usePrevSpend,
  ]);

  const [previewData, setPreviewData] = useState<PreviewData>({
    currency,
    scope: scope.map((s) => s.ref.id),
    attributionGroups: [],
    timeInterval: type === BudgetTypes.RECURRING ? timeInterval : getFixedTimeInterval(startPeriod, endPeriod),
    timeRangeOptions: getTimeRangeOption(type, timeInterval, startPeriod, endPeriod),
    attributionGroupsPayload: [],
    rows: getRows(attributions?.length ?? 0, []),
    filters: getFilters(scope?.map((s) => s.ref.id)),
    cols: getCols(metadataSnapshots ?? [], timeInterval),
  });

  const isBudgetValid = useMemo(() => {
    const valid =
      !!scope.length &&
      (type === BudgetTypes.RECURRING || (type === BudgetTypes.FIXED && startPeriod < endPeriod)) &&
      !!amount &&
      alerts.length > 0 &&
      (recipients.length > 0 || recipientsSlackChannels.length > 0);

    setSaveButtonTooltipText(valid ? "" : budgetTxt.BUDGET_IS_INVALID);
    return valid;
  }, [
    scope.length,
    type,
    startPeriod,
    endPeriod,
    amount,
    alerts.length,
    recipients.length,
    recipientsSlackChannels.length,
  ]);

  const [budgetStates, dispatchBudgetStates] = useReducer(budgetStateReducer, {
    shouldRefreshData: false,
    shouldRefreshPreview: false,
    refreshPreview: false,
  });

  // Set as initialized once metadata is ready
  useEffect(() => {
    if (!isInit && metadataSnapshots !== null) {
      setIsInit(true);
    }
  }, [isBudgetValid, isInit, metadataSnapshots]);

  const refreshBudgetUsageData = useCallback(async () => {
    setLoading(true);
    try {
      if (!isBudgetValid || isNewBudget) {
        return;
      }
      dispatchBudgetStates({ type: "startedRefreshData" });
      await api.request({
        method: httpMethods.GET as Method,
        url: `/v1/customers/${genuineCustomer.id}/analytics/budgets/${budgetId}`,
      });
    } catch (error) {
      dispatchBudgetStates({ type: "shouldRefreshData" });
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  }, [api, budgetId, genuineCustomer.id, isBudgetValid, isNewBudget]);

  useEffect(() => {
    setPreviewData({
      currency,
      scope: scope.map((s) => s.ref.id),
      attributionGroups: [],
      timeInterval: type === BudgetTypes.RECURRING ? timeInterval : getFixedTimeInterval(startPeriod, endPeriod),
      timeRangeOptions: getTimeRangeOption(type, timeInterval, startPeriod, endPeriod),
      attributionGroupsPayload: [],
      rows: getRows(attributions.length, []),
      filters: getFilters(scope.map((s) => s.ref.id)),
      cols: getCols(metadataSnapshots ?? [], timeInterval),
    });
  }, [currency, endPeriod, scope, startPeriod, timeInterval, type, attributions, metadataSnapshots]);

  const editBudget = useCallback(
    async (ref: ModelReference<CloudAnalyticsModelBudgetModel>, newName?: string) => {
      await updateBudget({
        name: newName ?? name,
        description,
        config,
        ref,
      });
    },
    [config, description, name]
  );

  const createBudget = useCallback(
    async (newName?: string) => {
      setIsCreating(true);
      try {
        const newBudgetRef = await createNewBudget({
          name: newName ?? name,
          description,
          config,
          customer,
          email: currentUser.email,
        });
        mixpanel.track("analytics.budgets.create", { budgetId: newBudgetRef.id });
        setHasUnsavedChanges(false);
        history.push(routeMatchURL.replace("create", newBudgetRef.id));
      } finally {
        setIsCreating(false);
      }
    },
    [config, currentUser.email, customer, description, history, name, routeMatchURL]
  );

  const commitBudgetChanges = useCallback(
    async (name?: string) => {
      if (!name) {
        return;
      }

      try {
        if (isNewBudget) {
          await createBudget(name);
        } else {
          await editBudget(budget.ref, name);
        }
        setSaved(true);
        successSnackbar(budgetTxt.SUCCESSFULLY_SAVED);
        return true;
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(budgetTxt.FAILED_TO_UPDATE);
        return false;
      }
    },
    [isNewBudget, successSnackbar, createBudget, editBudget, budget, errorSnackbar]
  );

  useEffect(() => {
    void refreshBudgetUsageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refresh budget data
  useUpdateEffect(() => {
    dispatchBudgetStates({ type: "shouldRefreshData" });
  }, [currency, amount, type, timeInterval, metric, endPeriod, scope, alerts, startPeriod]);

  // refresh budget preview
  useUpdateEffect(() => {
    dispatchBudgetStates({ type: "shouldRefreshPreview" });
  }, [currency, type, timeInterval, metric, endPeriod, startPeriod, scope]);

  const handleCreateReport = useCallback(async () => {
    const reportUrl = await getInvestigationReportUrl();
    history.push(reportUrl);
  }, [getInvestigationReportUrl, history]);

  useEffect(() => {
    if (qs.action === "investigate") {
      void handleCreateReport();
    }
  }, [handleCreateReport, qs.action]);

  useEffect(() => {
    if (name === "" && scope !== null && scope.length > 0) {
      setName(scope[0].data.name);
    }
  }, [name, scope]);

  const handleCopyBudget = useCallback(
    async (name?: string) => {
      try {
        if (isNewBudget) {
          return;
        }
        setLoading(true);
        const docRefId = await cloneBudget(name);
        void refreshBudgetUsageData();
        setRecipients([currentUser.email]);
        setRecipientsSlackChannels([]);
        setName(name ?? `Copy of ${budget.data.name}`);
        history.push(routeMatchURL.replace(budgetId, docRefId));
        if (!name) {
          successSnackbar(budgetTxt.SUCCESSFULLY_CLONED);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar(budgetTxt.FAILED_TO_CLONE);
      } finally {
        setLoading(false);
      }
    },
    [
      isNewBudget,
      cloneBudget,
      refreshBudgetUsageData,
      currentUser.email,
      budget.data.name,
      budgetId,
      history,
      routeMatchURL,
      successSnackbar,
      errorSnackbar,
    ]
  );

  const handleRefresh = useCallback(async () => {
    if (budgetStates.shouldRefreshPreview) {
      dispatchBudgetStates({ type: "startedRefreshPreview" });
    }
    await refreshBudgetUsageData();
  }, [budgetStates.shouldRefreshPreview, refreshBudgetUsageData]);

  const onChangeTitleValues = (values: { title?: string; description?: string }) => {
    if (values.title !== undefined) {
      setName(values.title);
    } else if (values.description !== undefined) {
      setDescription(values.description);
    }
  };

  const handleSave = useCallback(
    async (newName?: string) => {
      if (!name) {
        onChangeTitleValues({ title: newName });
        setOpenNameDialog(false);
      }

      if (!isBudgetValid) {
        return;
      }

      await commitBudgetChanges(newName ?? name);
      await handleRefresh();
    },
    [commitBudgetChanges, name, handleRefresh, isBudgetValid]
  );

  const handleSaveAs = useCallback(
    async (name) => {
      await handleCopyBudget(name);
      setSaved(true);
      await handleRefresh();
    },
    [handleRefresh, handleCopyBudget]
  );

  const goBackToBudgets = useCallback(() => {
    history.push(`/customers/${genuineCustomer.id}/analytics/budgets`);
  }, [history, genuineCustomer.id]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      activatePendingPrompt({
        exceptionsPath: [new RegExp(`^${routeMatchURL.replace("create", "")}(\\w+)$`)],
        onSaveCallback: async () => {
          if (isCurrentUserEditor && name) {
            await handleSave();
            goBackToBudgets();
            return true;
          } else {
            setOpenNameDialog(true);
            return false;
          }
        },
      });
    } else {
      clearPendingPrompt();
    }
  }, [
    hasUnsavedChanges,
    activatePendingPrompt,
    clearPendingPrompt,
    isCurrentUserEditor,
    handleSave,
    goBackToBudgets,
    name,
    routeMatchURL,
  ]);

  useUpdateEffect(() => {
    setConfig(prepareConfig());
  }, [prepareConfig]);

  useEffect(() => {
    if (!budgetId) {
      return;
    }
    mixpanel.track("analytics.budgets.open", { budgetId });
  }, [budgetId]);

  useEffect(() => {
    setHasUnsavedChanges(!equalityCheck);
  }, [equalityCheck, prepareConfig, budget.data.config]);

  const addNewUsers = useCallback(
    async (collaborators) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: budget,
        allUsersAndInvites,
      });
      if (emails.length) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, budget, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );

  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${genuineCustomer.id}/analytics/budgets`);
  }, [history, genuineCustomer.id]);

  const handleSaveName = useCallback(
    async (newName) => {
      try {
        if (isCurrentUserEditor) {
          setName(newName);
          await handleSave(newName);
        } else {
          await handleSaveAs(newName);
        }
      } catch (e) {
        setHasUnsavedChanges(true);
      } finally {
        setOpenNameDialog(false);
      }
    },
    [handleSave, handleSaveAs, isCurrentUserEditor]
  );

  const handleChangeSharing = useCallback(
    async (
      collaborators: Collaborators,
      publicAccess: PublicAccess,
      newRecipients: string[] = recipients,
      newRecipientsSlackChannels: SlackChannel[] = recipientsSlackChannels
    ) => {
      if (isNewBudget) {
        return;
      }
      try {
        setShareLoading(true);
        if (isCurrentUserUserManager) {
          try {
            await addNewUsers(collaborators);
          } catch (e) {
            handleMissingPermission(e as string);
            setShareLoading(false);
            return;
          }
        }
        const verifiedRecipients = newRecipients.filter(
          (r) => checkAllowedSubdomains(r) || collaborators.find((c) => c.email === r)
        );
        const newCollabs = differenceWith(collaborators, budget.data.collaborators, isEqual);
        const recipientsWithNewCollaborators = uniq(
          concat(
            verifiedRecipients,
            newCollabs.map((r) => r.email)
          )
        );
        await shareBudget({
          api,
          customer,
          budget,
          publicAccess,
          collaborators,
          recipients: recipientsWithNewCollaborators,
          recipientsSlackChannels: newRecipientsSlackChannels,
        });
        showSharedSnackbar({ message: "Budget sharing and alerting saved successfully" });
        setShareDialogOpen(false);
        setRecipients(recipientsWithNewCollaborators);
      } catch (error) {
        setRecipients(budget.data.recipients);
        setRecipientsSlackChannels(budget.data.recipientsSlackChannels ?? []);
        consoleErrorWithSentry(error);
      } finally {
        setShareLoading(false);
      }
    },
    [
      recipients,
      recipientsSlackChannels,
      isNewBudget,
      isCurrentUserUserManager,
      budget,
      api,
      customer,
      showSharedSnackbar,
      addNewUsers,
      handleMissingPermission,
    ]
  );

  const newCollaborators = useMemo(
    () => recipients.filter((r) => !budget.data.collaborators.find((c) => c.email === r)),
    [recipients, budget.data.collaborators]
  );

  const saveButtonDisabled = useMemo(
    () => loading || !isCurrentUserEditor || !(isBudgetValid && hasUnsavedChanges),
    [loading, isCurrentUserEditor, isBudgetValid, hasUnsavedChanges]
  );

  useUpdateEffect(() => {
    if (usePrevSpend) {
      setAmount(budget.data.utilization?.lastPeriod ?? 0);
      setPreviousAmount(amount);
    } else {
      setAmount(previousAmount);
    }
  }, [usePrevSpend]);

  useEffect(() => {
    setAlerts((prevState) => {
      const newAlerts = prevState.slice();

      if (!amount) {
        return newAlerts.map((alert) => ({ ...alert, amount: 0 }));
      }

      return newAlerts.map((alert) => {
        if (alert.percentage) {
          return {
            amount: getAlertAmountFromPercentage(alert.percentage, amount),
            percentage: alert.percentage,
            triggered: alert.triggered,
          };
        }
        return alert;
      });
    });
  }, [amount]);

  // check if we can create new budget
  if (isCreatePage) {
    if (analyticsGovernanceBudgets.loading) {
      return null;
    }

    if (analyticsGovernanceBudgets.limitReached && !isCreating) {
      return <Redirect to="." />;
    }
  }

  if (qs.action) {
    return null;
  }

  if (isNewBudget && analyticsGovernanceBudgets.loading) {
    return null;
  }

  return (
    <>
      <Card className={classes.header}>
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": {
              minWidth: 0,
            },
            "& .MuiCardHeader-action": {
              mt: "auto",
              mb: "auto",
            },
          }}
          disableTypography={true}
          title={
            <Title
              data-cy="budget-name"
              values={{ title: name, description }}
              onChange={onChangeTitleValues}
              handleBack={handleClose}
              placeholder={budgetTxt.BUDGET_NAME}
              disabled={!isCurrentUserEditor}
              maxTitleLength={maxTitleLength}
              maxDescriptionLength={maxDescriptionLength}
            />
          }
          action={
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              {!isNewBudget && (
                <Grid container item spacing={2} wrap="nowrap">
                  <Grid item>
                    <Tooltip title="Investigate">
                      <IconButton
                        data-testid="budgetInvestigate"
                        className={classes.copy}
                        onClick={handleCreateReport}
                        size="small"
                        disabled={analyticsGovernanceBudgets.limitReached ?? true}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Clone Budget">
                      <IconButton
                        data-testid="budgetClone"
                        className={classes.copy}
                        onClick={() => handleCopyBudget()}
                        size="small"
                        disabled={analyticsGovernanceBudgets.limitReached ?? true}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Share Budget">
                      <IconButton
                        onClick={() => {
                          setShareDialogOpen(true);
                        }}
                        size="small"
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              <Grid item container spacing={1} wrap="nowrap">
                {!isNewBudget && (
                  <Grid item sx={{ whiteSpace: "nowrap" }}>
                    <SaveAsComponent
                      variant="outlined"
                      disabled={analyticsGovernanceBudgets.limitReached ?? true}
                      onSaveAs={handleSaveAs}
                      saveAsTitle={budgetTxt.SAVE_NEW}
                      textFieldProps={{
                        helperText: budgetTxt.SAVE_AS_HELPER_TEXT,
                        label: budgetTxt.SAVE_AS_LABEL,
                      }}
                      successMessage={budgetTxt.SUCCESSFULLY_SAVED}
                    />
                  </Grid>
                )}
                <Grid item>
                  <SaveComponent
                    disabled={saveButtonDisabled}
                    onSave={handleSave}
                    tooltip={saveButtonTooltipText}
                    showDialog={!name}
                    saveDialogLabel={budgetTxt.BUDGET_NAME}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Card>
      {/* TODO: show this alert only AFTER changing the scope and not WHILE changing it */}
      <Collapse in={hasUnsavedChanges && budgetStates.shouldRefreshData && isBudgetValid} timeout={500}>
        <Grid container style={{ marginBottom: theme.spacing(1) }}>
          <Grid item xs={12}>
            <Alert severity="info">{budgetText.REFRESH_BUDGET_ALERT}</Alert>
          </Grid>
        </Grid>
      </Collapse>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <BudgetConfiguration
            allowGrowth={allowGrowth}
            amount={amount}
            attributions={attributions}
            budget={budget}
            classes={classes}
            currency={currency}
            customerId={genuineCustomer.id}
            dataSource={dataSource}
            endPeriod={endPeriod}
            growthPerPeriod={growthPerPeriod}
            hasDataHub={hasDataHub}
            isCurrentUserEditor={isCurrentUserEditor}
            lastPeriodCost={budget.data.utilization?.lastPeriod || 0}
            loading={loading}
            metric={metric}
            scope={scope}
            setAllowGrowth={setAllowGrowth}
            setAmount={setAmount}
            setDataSource={setDataSource}
            setCurrency={setCurrency}
            setEndPeriod={setEndPeriod}
            setGrowthPerPeriod={setGrowthPerPeriod}
            setMetric={setMetric}
            setNewName={setName}
            setScope={setScope}
            setStartPeriod={setStartPeriod}
            setTimeInterval={setTimeInterval}
            setType={setType}
            setUsePrevSpend={setUsePrevSpend}
            startPeriod={startPeriod}
            timeInterval={timeInterval}
            type={type}
            usePrevSpend={usePrevSpend}
          />
        </Grid>

        <Grid container item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <BudgetSharing
                isCurrentUserEditor={isCurrentUserEditor}
                classes={classes}
                setShareDialogOpen={setShareDialogOpen}
                budget={budget}
                draft={Boolean(isNewBudget)}
                setRecipients={setRecipients}
                setRecipientsSlackChannels={setRecipientsSlackChannels}
                recipients={recipients}
                recipientsSlackChannels={recipientsSlackChannels}
                handleChangeSharing={handleChangeSharing}
                shareLoading={shareLoading}
                isCurrentUserUserManager={isCurrentUserUserManager}
              />
            </Grid>
            <Grid item xs={12}>
              <BudgetAlerts
                classes={classes}
                budget={budget}
                isCurrentUserEditor={isCurrentUserEditor}
                alerts={alerts}
                setAlerts={setAlerts}
                currency={currency}
                amount={amount}
                loading={loading}
                metric={metric}
                shouldRefreshData={budgetStates.shouldRefreshData}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BudgetUtilization
            utilization={budget.data.utilization?.current}
            forecasted={budget.data.utilization?.forecasted}
            loading={loading}
            alerts={alerts}
            amount={amount}
            currency={currency}
            metric={metric}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewReport
            attributions={attributions}
            displayForecast
            previewData={previewData}
            renderer={renderer}
            runQuery={budgetStates.refreshPreview}
            setRenderer={setRenderer}
            setRunQuery={(reset) => {
              dispatchBudgetStates({ type: "finishedRefreshPreview", payload: reset });
            }}
          />
        </Grid>
      </Grid>

      {!isNewBudget && !!budget.data.collaborators.length && (
        <ShareDialog
          open={shareDialogOpen}
          title="Share budget"
          onClose={() => {
            setShareDialogOpen(false);
            setRecipients(budget.data.recipients);
          }}
          entity={CloudAnalyticsEntities.BUDGET}
          handleChangeSharing={handleChangeSharing}
          predefinedCollaborators={newCollaborators}
          loading={shareLoading}
          shareEntities={[budget.data]}
        />
      )}

      <SimpleDialog
        open={subscribeDialogOpen}
        title="Unsubscribe from budget alerts"
        onCancel={() => {
          setSubscribeDialogOpen(false);
        }}
        onConfirm={async () => {
          await handleChangeSharing(
            budget.data.collaborators,
            budget.data.public,
            budget.data.recipients.filter((r) => r !== currentUser.email)
          );
          setSubscribeDialogOpen(false);
        }}
        confirmButtonText="unsubscribe"
      />

      {openNameDialog && (
        <SaveDialog
          title={budgetTxt.NAME_BEFORE_SAVING}
          open={openNameDialog}
          onClose={handleClose}
          onSave={handleSaveName}
          textFieldProps={{ label: budgetTxt.BUDGET_NAME }}
        />
      )}
    </>
  );
};
