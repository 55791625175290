import { useEffect, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { DateTime } from "luxon";

import { type OptimizerMove } from "./types";
import { getChipBackgroundColor } from "./utils";

type Props = {
  payerId: string;
  moves: OptimizerMove[];
  expandAllChecked: boolean;
};

export default function Payer({ payerId, moves, expandAllChecked }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(expandAllChecked);
  }, [expandAllChecked]);

  return (
    <>
      <TableRow>
        <TableCell scope="row">
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => setIsOpen(!isOpen)}
            style={{
              cursor: "pointer",
            }}
          >
            <IconButton size="small">{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
            {payerId}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row" style={{ padding: 0, border: "none" }}>
          <Collapse in={isOpen} unmountOnExit style={{ width: "100%" }}>
            <Stack p={2}>
              <Typography variant="h3">Moves</Typography>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Account ID</TableCell>
                      <TableCell>Source payer</TableCell>
                      <TableCell>Destination payer</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Last status change</TableCell>
                      <TableCell align="right">Commitment</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {moves.map((move, index) => (
                      <TableRow key={index.toString() + move.AccountID + move.SourcePayerID + move.DestinationPayerID}>
                        <TableCell>{move.AccountID}</TableCell>
                        <TableCell>{move.SourcePayerID}</TableCell>
                        <TableCell>{move.DestinationPayerID}</TableCell>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          <Chip
                            label={move.State}
                            style={{ color: "#FFFFFF", backgroundColor: getChipBackgroundColor(move.State) }}
                          />
                        </TableCell>
                        <TableCell>{DateTime.fromISO(move.LastUpdatedAt).toFormat("dd LLL yyyy, HH:mm")}</TableCell>
                        <TableCell align="right">{move.Commitment}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
