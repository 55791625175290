import { type Dispatch, type SetStateAction, useCallback, useState } from "react";

import { Positions, Sort } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { reportText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import { DraggableGroup } from "../../../../Components/DragAndDrop/DraggableGroup";
import { type KeyTypeValues } from "../../../../Pages/CloudAnalytics/api";
import { type ColKeySort } from "../../../../Pages/CloudAnalytics/ReportData";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { FilterPopover } from "../../dialogs/metadataFilter/FilterContent";
import { isTable, type RenderDraggableContent, type ReportAddDimension } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ReportsAccordion from "./ReportsAccordion";
import SortToggleGroup from "./SortToggleGroup";

export type GroupByProps = {
  popoverAnchorEl: any;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  handleAddDimension: ReportAddDimension;
  touched: Set<any>;
  setColKeySort: Dispatch<SetStateAction<ColKeySort | undefined>>;
  numOfItems: number;
  renderDraggableContent: RenderDraggableContent;
};

const position = Positions.ROW;

const GroupBy = ({
  handleAddDimension,
  handleChangeLabels,
  popoverAnchorEl,
  setPopoverAnchorEl,
  touched,
  setColKeySort,
  numOfItems,
  renderDraggableContent,
}: GroupByProps) => {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { includeSubtotals, rowOrder, excludeSelectMetadataIds, renderer } = reportConfig ?? {};
  const { dimensions = [] } = useReportDimensionsContext();

  const onExpand = useCallback((state: boolean) => {
    setAccordionExpanded(state);
  }, []);

  const onChangeSort = useCallback(
    (_, newOrder) => {
      dispatchReportConfig({ payload: { rowOrder: newOrder ?? Sort.A_TO_Z } });
      touched.add("rowOrder");
      setColKeySort(undefined);
    },
    [touched, setColKeySort, dispatchReportConfig]
  );

  const onChangeSubtotals = useCallback(
    (event) => {
      dispatchReportConfig({ payload: { includeSubtotals: event.target.checked } });
      touched.add("includeSubtotals");
      setColKeySort(undefined);
    },
    [touched, setColKeySort, dispatchReportConfig]
  );

  const showSubtotalsText = includeSubtotals && numOfItems > 1 && isTable(renderer);

  const popoverComponent = (
    <FilterPopover
      anchorEl={popoverAnchorEl?.mdRow}
      onClose={() => setPopoverAnchorEl(null)}
      metadata={dimensions}
      excludeSelectMetadataIds={excludeSelectMetadataIds}
      position={position}
      handleAddDimension={handleAddDimension}
      handleChangeLabels={handleChangeLabels}
      isOptionDisabled={undefined}
    />
  );

  return (
    <ReportsAccordion
      defaultExpanded
      title={reportText.GROUP_BY_SECTION_LABEL}
      numOfItems={numOfItems}
      popoverComponent={popoverComponent}
      popoverAnchorEl={popoverAnchorEl?.mdRow}
      setPopoverAnchorEl={setPopoverAnchorEl}
      position={position}
      onExpand={onExpand}
      addIconTooltip={reportTxt.ADD_GROUP_BY_TOOLTIP}
    >
      <>
        <Box data-cy="group-by">
          <DraggableGroup
            id={position}
            renderContent={renderDraggableContent(position, false, !accordionExpanded)}
            isHidden={!accordionExpanded}
          />
        </Box>
        {numOfItems > 0 && (
          <SortToggleGroup
            title={reportText.SORTֹ_GROUPS}
            disabled={false}
            value={rowOrder}
            onChange={onChangeSort}
            showSubtotalsText={showSubtotalsText}
          />
        )}
        {numOfItems > 1 && isTable(renderer) && (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name="includeSubtotalsCheckbox"
                onChange={onChangeSubtotals}
                checked={includeSubtotals}
                color="primary"
                disabled={!isTable(renderer)}
              />
            }
            value={reportTxt.INCLUDE_SUBTOTALS_LABEL}
            label={
              <Typography
                variant="caption"
                sx={(theme) => ({
                  marginLeft: theme.spacing(-0.5),
                  alignItems: "flex-start",
                })}
              >
                {reportTxt.INCLUDE_SUBTOTALS_LABEL}
                <Tooltip title={reportTxt.INCLUDE_SUBTOTALS_TOOLTIP}>
                  <InfoOutlinedIcon
                    htmlColor={grey[600]}
                    sx={{ fontSize: "16px", position: "relative", top: "3px", left: "3px" }}
                  />
                </Tooltip>
              </Typography>
            }
          />
        )}
      </>
    </ReportsAccordion>
  );
};

export default GroupBy;
