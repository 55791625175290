import { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import orderBy from "lodash/orderBy";
import { PaymentMethodType } from "@doitintl/cmp-models";

// Material-UI
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/EditRounded";
import EntityIcon from "@mui/icons-material/BusinessRounded";

import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import { getCurrencyByCode } from "../../utils/common";
import { useCustomerContext } from "../../Context/CustomerContext";
import Hide from "../HideChildren/Hide";
import { useFullScreen } from "../../utils/dialog";
import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import { createLocationDescriptorWithReferrer } from "../../Pages/Entity/useGoBackToReferrer";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";
import { useWidgetCustomer } from "./utils/useWidgetCustomer";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    paddingBottom: 0,
    height: 201,
  },
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  entityName: {
    maxWidth: 120,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  tableRowDisabled: {
    height: 32,
    "& td": {
      color: theme.palette.text.disabled,
    },
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  textContentContainer: {
    height: "100%",
    textAlign: "center",
  },
}));

const EntitiesCard = () => {
  const classes = useStyles();
  const { fullScreen: matches } = useFullScreen("lg");
  const { entities } = useCustomerContext();
  const customer = useWidgetCustomer();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const { widgetHeight } = useCurrentDashboardContext();

  useEffect(() => {
    switch (widgetHeight) {
      case 2: {
        setRowsPerPage(9);
        break;
      }
      case 1.5: {
        setRowsPerPage(6);
        break;
      }
      case 1:
      default: {
        setRowsPerPage(3);
      }
    }
  }, [widgetHeight]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const content = useMemo(() => {
    if (entities.length > 0) {
      const filteredEntities = orderBy(entities, ["active", "id"], ["desc", "asc"]).filter(
        (entity) => isDoitEmployee || entity.active
      );
      const entitiesPage = filteredEntities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const emptyRows = rowsPerPage - entitiesPage.length;
      return (
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell align="center" padding="none" size="small" />
              <TableCell padding="none" size="small">
                Name
              </TableCell>
              {isDoitEmployee && (
                <TableCell padding="none" size="small">
                  ID
                </TableCell>
              )}
              <Hide mdDown>
                <TableCell padding="none" size="small">
                  Payment Method
                </TableCell>
              </Hide>
            </TableRow>
          </TableHead>
          <TableBody>
            {entitiesPage.map((entity) => {
              const disabled = !(isDoitEmployee || (entity.active && userRoles.billingProfilesAdmin));
              let paymentMethod = "";
              if (entity.payment) {
                switch (entity.payment.type) {
                  case PaymentMethodType.CreditCard:
                    if (matches) {
                      paymentMethod = "CC";
                    } else {
                      paymentMethod = `Credit Card${entity.payment.card ? ` (${entity.payment.card.last4})` : ""}`;
                    }
                    break;
                  case PaymentMethodType.USBankAccount:
                  case PaymentMethodType.BankAccount:
                    if (matches) {
                      paymentMethod = "ACH";
                    } else {
                      paymentMethod = `ACH${
                        entity.payment.bankAccount ? ` (${entity.payment.bankAccount.last4})` : ""
                      }`;
                    }
                    break;
                  case PaymentMethodType.BillCom:
                    paymentMethod = "Bill.com";
                    break;
                  case PaymentMethodType.WireTransfer:
                    paymentMethod = matches ? "WT" : "Wire Transfer";
                    break;
                  case PaymentMethodType.SepaDebit:
                    paymentMethod = `SEPA Debit${
                      entity.payment.sepaDebit ? ` (${entity.payment.sepaDebit.last4})` : ""
                    }`;
                    break;
                  case PaymentMethodType.BacsDebit:
                    paymentMethod = `BACS Debit${
                      entity.payment.bacsDebit ? ` (${entity.payment.bacsDebit.last4})` : ""
                    }`;
                    break;
                  case PaymentMethodType.AcssDebit:
                    paymentMethod = `ACSS Debit${
                      entity.payment.acssDebit ? ` (${entity.payment.acssDebit.last4})` : ""
                    }`;
                    break;
                  default:
                    paymentMethod = "";
                }
              }

              return (
                <TableRow
                  key={entity.id}
                  className={clsx({
                    [classes.tableRow]: entity.active,
                    [classes.tableRowDisabled]: !entity.active,
                  })}
                >
                  <TableCell align="center" padding="none" size="small">
                    <Tooltip
                      title={!disabled ? "Update billing profile" : "Missing permissions to update billing profile"}
                    >
                      <span>
                        <IconButton
                          aria-label="Update billing profile"
                          disabled={disabled}
                          size="small"
                          component={Link}
                          to={createLocationDescriptorWithReferrer(
                            `/customers/${customer.id}/entities/${entity.id}/edit`
                          )}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.entityName} padding="none" size="small">
                    {entity.name}
                  </TableCell>
                  {isDoitEmployee && (
                    <TableCell padding="none" size="small">
                      {entity.priorityId}
                    </TableCell>
                  )}
                  <Hide mdDown>
                    <TableCell padding="none" size="small">
                      {getCurrencyByCode(entity.currency)} | {paymentMethod}
                    </TableCell>
                  </Hide>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[rowsPerPage]}
                count={filteredEntities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      );
    } else {
      return (
        <Grid
          container
          spacing={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.textContentContainer}
        >
          {userRoles.billingProfilesAdmin && (
            <>
              <Grid item>
                <Typography variant="h6">Please complete your company profile</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to={`/customers/${customer.id}/entities`}
                  startIcon={<EntityIcon />}
                >
                  SETUP COMPANY
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      );
    }
  }, [
    entities,
    page,
    rowsPerPage,
    classes.table,
    classes.tableHeader,
    classes.tablePagination,
    classes.tableRow,
    classes.tableRowDisabled,
    classes.entityName,
    classes.textContentContainer,
    isDoitEmployee,
    userRoles.billingProfilesAdmin,
    customer.id,
    matches,
  ]);

  return (
    <Grid>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title="Billing Profiles"
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader="Customize the assets on your invoice and your payment method"
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
        />
        <CardContent className={classes.cardContent} style={{ height: 200 * widgetHeight }}>
          {content}
        </CardContent>
        <div />
      </Card>
    </Grid>
  );
};

export default EntitiesCard;
