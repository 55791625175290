import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { type AlgoliaConfig, AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import algoliasearch, { type SearchClient } from "algoliasearch/lite";

import { useApiContext } from "../api/context";
import { type AlgoliaIndexType } from "../Components/AlgoliaSearch/types";
import { useInterval } from "../Components/hooks/useInterval";
import { isProduction } from "../constants";
import { consoleErrorWithSentry } from "../utils";
import { useAuthContext } from "./AuthContext";

// null = not configured and will never be ready
// undefined = not loaded yet
export const useAlgoliaConfig = () => {
  const { isDoitEmployee } = useAuthContext();
  const [config, setConfig] = useState<AlgoliaConfig | null>();
  const api = useApiContext();

  const updateAlgoliaConfig = useCallback(async () => {
    if (isDoitEmployee) {
      return;
    }
    try {
      const response = await api.get(`/v1/algolia/config`);
      setConfig(response.data);
    } catch (exception) {
      consoleErrorWithSentry(exception);
    }
  }, [api, isDoitEmployee]);

  useInterval(
    () => {
      updateAlgoliaConfig().catch(consoleErrorWithSentry);
    },
    1000 * 60 * 60 * 23
  );

  useEffect(() => {
    if (isDoitEmployee) {
      return getCollection(AppModel)
        .doc("algolia")
        .onSnapshot((docSnapshot) => {
          setConfig(docSnapshot.asModelData() ?? null);
        });
    }
    updateAlgoliaConfig().catch(consoleErrorWithSentry);
  }, [isDoitEmployee, updateAlgoliaConfig]);

  return useMemo(() => {
    if (config) {
      try {
        if (!isProduction && config.devAppId && config.devSearchKey) {
          return {
            searchClient: algoliasearch(config.devAppId, config.devSearchKey),
            restrictedIndices: config.restrictedIndices,
          };
        }
        return {
          searchClient: algoliasearch(config.appId, config.searchKey),
          restrictedIndices: config.restrictedIndices,
        };
      } catch (e) {
        consoleErrorWithSentry(e);
      }
    }
  }, [config]);
};

type ContextType = {
  searchClient?: SearchClient;
  restrictedIndices?: AlgoliaIndexType[];
};
const algoliaSearchClientContext = createContext<ContextType>({ restrictedIndices: [] });

export const AlgoliaSearchClientContextProvider = ({ children }) => {
  const algoliaSearchClient = useAlgoliaConfig();
  const providerValue = useMemo<ContextType>(
    () => ({
      searchClient: algoliaSearchClient?.searchClient,
      restrictedIndices: (algoliaSearchClient?.restrictedIndices as AlgoliaIndexType[]) || [],
    }),
    [algoliaSearchClient?.searchClient, algoliaSearchClient?.restrictedIndices]
  );
  return <algoliaSearchClientContext.Provider value={providerValue}>{children}</algoliaSearchClientContext.Provider>;
};

export const AlgoliaSearchClientContextForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<ContextType>;
}) => (
  <algoliaSearchClientContext.Provider value={value as ContextType}>{children}</algoliaSearchClientContext.Provider>
);

export const useAlgoliaSearchContext = () => useContext(algoliaSearchClientContext);
