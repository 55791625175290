import { createContext, type ReactNode, useContext, useMemo } from "react";

import { type ReportWSnap } from "../../../types";

type ReportContextType = {
  report: ReportWSnap;
};

export const ReportContext = createContext<ReportContextType>({} as ReportContextType);

export const ReportContextProvider = ({ children, report }: { children?: ReactNode; report: ReportWSnap }) => {
  const reportVal = useMemo(() => ({ report }), [report]);
  return <ReportContext.Provider value={reportVal}>{children}</ReportContext.Provider>;
};

export const useReportContext = (): ReportContextType => useContext(ReportContext);
