import { type Edge, type Node } from "@xyflow/react";

import { type EdgeType } from "../../types";
import { createEdge } from "./edgeUtils";

export const rearrangeNodesWhenDeleteNode = (prevNodes: Node[], nodeId: string) => {
  // Find the node to be deleted
  const nodeToDelete = prevNodes.find((node) => node.id === nodeId);
  if (!nodeToDelete) return prevNodes;

  // Height to adjust the positions, TODO: need to calculate the positions more accurately
  const heightAdjustment = Math.max(nodeToDelete.measured?.height ?? 250, 250);

  // Remove the node and adjust positions
  return prevNodes.reduce((acc, node) => {
    if (node.id !== nodeId) {
      // Adjust the position of nodes below the deleted node
      if (node.position.y > nodeToDelete.position.y) {
        acc.push({
          ...node,
          position: {
            ...node.position,
            y: node.position.y - heightAdjustment,
          },
        });
      } else {
        acc.push(node);
      }
    }
    return acc;
  }, [] as Node[]);
};

export const rearrangeEdgesWhenDeleteNode = (prevEdges: Edge[], nodeId: string) => {
  const parentEdge = prevEdges.find((edge) => edge.target === nodeId);
  const childEdges = prevEdges.filter((edge) => edge.source === nodeId);
  let remainingEdges = prevEdges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId);
  // Create new edge after delete if there is only one child
  if (parentEdge && childEdges.length && childEdges.length === 1) {
    const newEdge = createEdge(parentEdge.source, childEdges[0].target, childEdges[0].type as EdgeType);
    remainingEdges = [...remainingEdges, newEdge];
  }

  return remainingEdges;
};
