import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

export type Extfile = {
  key: null | string;
  storage: null | string;
  udate: Timestamp;
  url: null | string;
};

export type InvoiceProduct =
  | "amazon-web-services"
  | "g-suite"
  | "google-cloud"
  | "microsoft-azure"
  | "office-365"
  | "superquery"
  | "google-cloud-standalone"
  | "amazon-web-services-standalone"
  | "looker"
  | "navigator"
  | "solve"
  | "solve-accelerator"
  | "other";

type Ivtype = "A" | "C" | "F";

export type InvoiceItem = {
  DETAILS: string;
  DISPRICE: number;
  EXCH: number | null;
  ICODE: CurrencyCode;
  PARTNAME: string;
  PDES: string;
  PERCENT: number;
  PRICE: number;
  QPRICE: number;
  QUANT: number;
  SYMBOL: CurrencyCode;
  TYPE: InvoiceProduct;
  USDEXCH: number;
  DETAILSSUFFIX: string;
};

type Metadata = {
  customer: {
    name: string;
    primaryDomain: string;
  };
  entity: {
    name: string;
  };
};

type Notification = {
  created: Timestamp;
  sent: boolean;
};

export type ReminderSettings = {
  snoozeUntil: Timestamp;
  updatedBy: string;
};

export type StripePaymentIntent = {
  amount: number;
  amount_received: number;
  currency: "ils" | "usd";
  debit: number;
  id: string;
  payment_method_types?: ("card" | "ach_debit")[];
  ref: string;
  status: Status;
  timestamp: Timestamp;
  linked_invoice?: {
    amount_fees: number;
    ref: Reference<InvoiceModel>;
    invoice_id: string;
  };
};

type Status = "canceled" | "requires_capture" | "succeeded" | "processing";

@collection("invoices")
export class InvoiceModel {
  CANCELED!: boolean;

  CDES!: string;

  CODE!: CurrencyCode;

  COMPANY!: "doit";

  CUSTNAME!: string;

  DEBIT!: number;

  DETAILS!: string;

  ESTPAYDATE!: Timestamp | null;

  EXTFILES!: Readonly<Array<Extfile>>;

  INVOICEITEMS!: Readonly<Array<InvoiceItem>>;

  IVDATE!: Timestamp;

  IVDATE_STRING!: string;

  IVNUM!: string;

  IVTYPE!: Ivtype;

  PAID!: boolean;

  PAYDATE!: Timestamp;

  PAYDATE_STRING!: string;

  PRODUCTS!: Readonly<Array<InvoiceProduct>>;

  ROTL_CMP_NUMBER?: string;

  QPRICE!: number;

  STATDES!: string;

  SYMBOL!: CurrencyCode;

  TOTPRICE!: number;

  USDEXCH!: number;

  USDTOTAL!: number;

  VAT!: number;

  customer!: Reference<CustomerModel>;

  entity!: Reference<EntityModel>;

  isNoticeToRemedySent?: boolean;

  metadata!: Metadata;

  notification?: Notification;

  reminderSettings?: ReminderSettings | null;

  stripeLocked?: boolean;

  stripePaymentIntents?: StripePaymentIntent[] | null;
}
