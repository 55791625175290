import { useState } from "react";

import {
  DiscreteColorLegend,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import { Card, Typography } from "@mui/material";
import { amber, blue, grey, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { ThemeModes } from "../muiThemeTypes";
import { type DebtGraphData } from "../Pages/Collection/DebtGraphData";
import { formatNumber } from "../utils/common";
import { useFullScreen } from "../utils/dialog";

const colors = [
  {
    active: blue[600],
    inactive: blue[100],
  },
  {
    active: red[600],
    inactive: red[100],
  },
  {
    active: amber[600],
    inactive: amber[100],
  },
];
const titles = ["Not yet due", "Overdue < 90 days", "Overdue 90+ days"];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flex: 1,
  },
  bar: {
    marginRight: 50,
  },
  hintDiv: {
    background: "rgba(0,0,0,0.8)",
    padding: 5,
    borderRadius: 7,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 7,
    marginRight: 15,
  },
  card: {
    height: 260,
  },
  verticalColor: {
    color: theme.palette.text.secondary,
    float: "right",
    marginTop: "-250px",
    fontFamily: "Roboto",
    textAlign: "center",
    "& .rv-discrete-color-legend-item": {
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.mode === ThemeModes.DARK ? theme.palette.background.default : grey[50],
      },
    },
  },
}));

type Props = {
  data: DebtGraphData | undefined;
};

const DebtGraph = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile: matches } = useFullScreen("sm");
  const [value, setValue] = useState<{ title: string; subtitle: string; y: number }>();
  const [hoveredCell, setHoveredCell] = useState(false);
  const [seriesVisibility, setSeriesVisibility] = useState([true, true, true]);

  const tooltip = (val, title) => {
    if (val.y0) {
      val.y -= val.y0;
    }
    val.title = title;
    val.subtitle = DateTime.fromFormat(val.x, "yyyy-LL-dd").toFormat("dd LLL yyyy");
    setValue(val);
    setHoveredCell(true);
  };

  const xTickFormat = (value, index, scale, tickTotal) => {
    const dt = DateTime.fromFormat(value, "yyyy-LL-dd");
    if (index % tickTotal === 0) {
      return dt.toFormat("dd LLL");
    }
    return "";
  };

  const handleClick = (item, i) => {
    setSeriesVisibility((seriesVisibility) => {
      const newState = seriesVisibility.slice();
      newState[i] = !newState[i];
      if (newState.every((v) => !v)) {
        return [true, true, true];
      }
      return newState;
    });
  };

  const tickTotal = Math.floor((props.data?.[0]?.length ?? 0) / (matches ? 7 : 35)) + 1;

  const color = theme.palette.text.primary;

  return (
    <Card className={classes.card}>
      <FlexibleXYPlot xType="ordinal" stackBy="y" xDistance={0} margin={{ left: 50, right: 10, top: 80, bottom: 30 }}>
        <HorizontalGridLines
          style={{
            stroke: color,
            fill: "none",
          }}
        />
        <XAxis
          tickFormat={xTickFormat}
          style={{
            fontFamily: "Roboto",
            fontSize: 10,
            line: { stroke: color },
            text: { stroke: "none", fill: theme.palette.text.secondary },
          }}
          tickTotal={tickTotal}
          position="start"
        />
        <YAxis
          tickFormat={(v) => `$${formatNumber(v)}`}
          style={{
            fontFamily: "Roboto",
            fontSize: 10,
            line: { stroke: color },
            text: { stroke: "none", fill: theme.palette.text.secondary },
          }}
        />

        {props.data?.map((platform, i) => (
          <VerticalBarSeries
            key={`series-${i}`}
            className={classes.bar}
            color={colors[i].active}
            data={seriesVisibility[i] ? platform : []}
            onValueMouseOver={(value) => {
              tooltip(value, titles[i]);
            }}
            onValueMouseOut={() => {
              setHoveredCell(false);
            }}
          />
        ))}

        <DiscreteColorLegend
          className={classes.verticalColor}
          onItemClick={handleClick}
          orientation="horizontal"
          items={[0, 1, 2].map((i) => ({
            title: titles[i],
            color: seriesVisibility[i] ? colors[i].active : colors[i].inactive,
            strokeWidth: 12,
            width: "400px",
          }))}
        />

        {hoveredCell && value && (
          <Hint value={value}>
            <div className={classes.hintDiv}>
              <Typography color="inherit" variant="caption" noWrap display="block">
                {value.title}
              </Typography>
              <Typography color="inherit" variant="caption" noWrap display="block">
                {value.subtitle}
              </Typography>
              <Typography style={{ color: "white", fontWeight: "bold" }} variant="caption">
                {formatNumber(value.y)}
              </Typography>
            </div>
          </Hint>
        )}
      </FlexibleXYPlot>
    </Card>
  );
};

export default DebtGraph;
