import { useCallback, useMemo } from "react";

import { Link as RouteLink } from "react-router-dom";
import { Box, TableCell, Typography } from "@mui/material";
import { useClipboard } from "use-clipboard-copy";

import { globalText, marketplaceText } from "../../../../assets/texts";
import { useInfoSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { type accountsTableRowType } from "../types";

type propsType = {
  row: accountsTableRowType;
};

export function AccountRow({ row }: propsType) {
  const infoSnackbar = useInfoSnackbar();

  const clipboard = useClipboard({
    copiedTimeout: 1400,
    onSuccess: () => infoSnackbar(globalText.COPIED_TO_CLIPBOARD),
  });

  const handleCopyId = useCallback(() => {
    clipboard.copy(row.accountId);
  }, [clipboard, row.accountId]);

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () => [
      {
        label: <Typography>{marketplaceText.COPY_ACCOUNT_ID}</Typography>,
        action: handleCopyId,
        key: marketplaceText.COPY_ACCOUNT_ID,
      },
    ],
    [handleCopyId]
  );

  return (
    <>
      <TableCell>
        <Typography color="inherit" variant="body2" component={RouteLink} to={`/customers/${row.customerId}`}>
          {row.customerName}
        </Typography>
      </TableCell>
      <TableCell>{row.createdAt}</TableCell>
      <TableCell>{row.updatedAt}</TableCell>
      <TableCell>{row.state}</TableCell>
      <TableCell>{row.customerPrimaryDomain}</TableCell>
      <TableCell>{row.awsAccountId}</TableCell>
      <TableCell>
        <Box m={-1.5}>
          <ThreeDotsMenu options={threeDotsMenuOptions} />
        </Box>
      </TableCell>
    </>
  );
}
