import { useCallback, useEffect, useState } from "react";

import { type PerkModel } from "@doitintl/cmp-models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { SafeHtml } from "../../../Components/SafeHtml";
import { type RegisterInterestFromType } from "../types";
import { type SectionTitles } from "./index";
import { MarketplaceListings } from "./MarketplaceListings";
import { PerkVideo } from "./PerkVideo";

const scrollMarginTop = 479;
const scrollMarginTopStickyHeader = 238;
const nonStickyHeaderHeight = 312;
const offsetTopStickyHeader = 470;

export const Section = styled("div")({
  marginTop: 32,
  marginBottom: 16,
  "& h4": {
    paddingBottom: 16,
  },
});

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:before": {
      display: "none",
    },
  })
);

type Props = {
  perk: PerkModel;
  sectionTitles: SectionTitles;
  containerWidth: number;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
};

type NavSectionScrollOffsets = Partial<Record<string, number>>;

export const PerkContent = ({ perk, sectionTitles, containerWidth, registerInterest }: Props) => {
  const [navSectionScrollOffsets, setNavSectionScrollOffsets] = useState<NavSectionScrollOffsets>({});
  const [expandedModule, setExpandedModule] = useState<string>("");

  useEffect(() => {
    const offsets: NavSectionScrollOffsets = {};
    Object.entries(sectionTitles).forEach(([key, section]) => {
      const offsetTop = document.getElementById(section)?.offsetTop;
      if (offsetTop) {
        offsets[key] = offsetTop > offsetTopStickyHeader ? scrollMarginTopStickyHeader : scrollMarginTop;
      }
    });
    setNavSectionScrollOffsets(offsets);
  }, [sectionTitles]);

  const transform = useCallback((node: Element, data) => {
    if (data.tagName === "span") {
      node.setAttribute("style", "color: inherit");
    }
    return node;
  }, []);

  return (
    <Container
      sx={{
        width: `${containerWidth}px`,
        padding: `${nonStickyHeaderHeight}px 0px!important`,
      }}
    >
      <PerkVideo perk={perk} setExpandedModule={setExpandedModule} sectionTitles={sectionTitles} />

      <Section id={sectionTitles?.overview} sx={{ scrollMarginTop: navSectionScrollOffsets.overview }}>
        <Typography variant="h4">{sectionTitles.overview}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.fields?.description} transform={transform} />
        </Typography>
      </Section>

      <Section id={sectionTitles.checklist} sx={{ scrollMarginTop: navSectionScrollOffsets.checklist }}>
        <Typography variant="h4">{sectionTitles.checklist}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.fields?.checklist} transform={transform} />
        </Typography>
      </Section>

      <Section id={sectionTitles.agenda} sx={{ scrollMarginTop: navSectionScrollOffsets.agenda }}>
        <Typography variant="h4">{sectionTitles.agenda}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.agenda} transform={transform} />
        </Typography>
      </Section>

      {Array.isArray(perk.modules) && !!perk.modules.length && (
        <Section
          id={sectionTitles.modules}
          sx={{ scrollMarginTop: navSectionScrollOffsets.modules, overflowAnchor: "auto" }}
        >
          <Typography variant="h4">{sectionTitles.modules}</Typography>

          {perk.modules.map((module) => (
            <Accordion
              expanded={expandedModule === module.title}
              key={module.title}
              onChange={(_, expanded) => {
                if (expanded) {
                  setExpandedModule(module.title);
                } else {
                  setExpandedModule("");
                }
              }}
            >
              <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {module.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 0px 20px 0px" }}>
                <Typography component="div">
                  <SafeHtml html={module.description} transform={transform} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Section>
      )}

      <MarketplaceListings perk={perk} registerInterest={registerInterest} />
    </Container>
  );
};
