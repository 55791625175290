import { Checkbox, Stack, TextField, Typography } from "@mui/material";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { IconCard } from "./IconCard";

export const ServiceStep = ({ serviceCards, onClick }: { serviceCards: any; onClick: (title: string) => void }) => (
  <Stack gap={1} sx={{ mt: 1 }}>
    <VirtualizedAutocomplete
      options={["option 1"]}
      onChange={(e, filters) => filters}
      openOnFocus
      renderOption={(props, card, { selected }) => [
        props,
        <li key={card} {...props} data-custom-height={12}>
          <Checkbox checked={selected} />
          {card}
        </li>,
      ]}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" margin="dense" label="Filter available services" />
      )}
      size="small"
      limitTags={3}
      fullWidth
      value={["option 1"]}
    />
    <Typography variant="subtitle2">Popular services:</Typography>
    <>
      {serviceCards.map((card) => (
        <IconCard key={card.title} card={card} clickHandler={() => onClick(card.title)} cardState="select" />
      ))}
    </>
  </Stack>
);
