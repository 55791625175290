import { useCallback, useState, useMemo } from "react";

import startsWith from "lodash/startsWith";
import sortedUniqBy from "lodash/sortedUniqBy";
import sortBy from "lodash/sortBy";
import reject from "lodash/reject";
import get from "lodash/get";
import flatten from "lodash/flatten";

import { makeStyles } from "@mui/styles";
import { Chip, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography, Autocomplete } from "@mui/material";

import FilterIcon from "@mui/icons-material/FilterListRounded";
import { misc } from "../../constants/cypressIds";
import { getDefaultValue } from "../../types/FilterTable";
import useMountEffect from "../hooks/useMountEffect";
import { useFiltersContext } from "./Context";
import { OPERATOR_AND, OPERATOR_OR } from "./constants";
import { useTrackFilterEvent } from "./hook";
import { isLogicalOperator, toggleOperator } from "./filterTableUtils";
import { createFilter, createLogicalGroups } from "./utils";

// column options:
// transform:
// transforms a string value from user input (strings of numbers, dates etc.)
// to the matching data type in the source dataset it can compare the two values.

// validate:
// validates that the string value from the user input is a valid input
// which can later by transformed by the transform method.

// toOption:
// transforms a value to option (object with value and label keys)
// options presented to the user are sorted and unique.
// option.value and option.label must be strings
// if the field is an array, toOption must map it to an array of objects (value, label)

// ignoreInGeneralSearch (optional boolean):
// if true the column won't be used in the general search, by default every columns that is not of type "DateTime" or "Number"
// is used in the search

const useStyles = makeStyles((theme) => ({
  textTag: {
    paddingLeft: theme.spacing(1),
  },
  autocompleteOption: {
    padding: theme.spacing(0.25, 1),
  },
  autocompletePaper: {
    minWidth: 220,
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "15%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "10%",
    },
  },
}));

const SquarePaper = (props) => <Paper {...props} square />;

/* type Column = {
  comparators?: string[];
  label: string;
  path: string;
  toOption?: (value: any) => { label: string; value: any };
};

type FilterValue = {
  column: Column | null;
  comparator?: string | null;
  label: string | null;
  value: any;
};

export type EnhancedTableFilterProps = {
  style?: CSSProperties;
  data: any;
  columns: Column[];
  onFilter: Function;
  defaultValue?: (FilterValue | OPERATOR_OR)[];
  limitTags?: number;
  placeholder?: string;
}; */

const EnhancedTableFilter = ({
  tableName = "",
  style = undefined,
  data,
  columns,
  onFilter,
  defaultValue,
  limitTags,
  placeholder,
  skipOROperator = false,
  mixpanelEventName = "",
  // only true for tables with side filters
  reverseOperatorPrecedence = false,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const trackMixpanelEvent = useTrackFilterEvent({ mixpanelEventName });

  // decide if to close or not the options menu on click
  const [preventClosingOptionsMenu, setPreventClosingOptionsMenu] = useState(true);

  // retrieve and set filter from local storage with unique key for each use of filter
  const [filterValue, setFilterValue] = useFiltersContext();
  const [column, setColumn] = useState(null);
  const [comparator, setComparator] = useState(null);

  const comparatorSelect = column?.comparators?.length > 0 && !comparator;

  // group options by types (add options titles)
  const groupBy = (option) => {
    if (isLogicalOperator(option.label)) {
      return "Logical operation";
    } else if (startsWith(option?.label, "Contains:")) {
      if (column) {
        return column.label;
      } else {
        return "Any property";
      }
    } else {
      if (column) {
        if (comparatorSelect) {
          return "Select comparator";
        } else if (comparator) {
          return `${column.label} value`;
        } else {
          return `${column.label} equals to`;
        }
      } else {
        return "Filter by property";
      }
    }
  };

  // options that will be displayed in the drop menu
  const filterOptions = useMemo(() => {
    if (comparatorSelect) {
      return column.comparators.map((comparator) => ({ label: comparator, preventClosingOnSelect: true }));
    }

    if (comparator === "contains") {
      return [];
    }

    if (column) {
      // Don't show option for numbers
      if (column.type === "Number") {
        return [];
      }

      // show all possible values for selected column
      let options = sortedUniqBy(
        sortBy(
          flatten(
            data.reduce((opts, curr) => {
              const value = get(curr, column.path);
              if (value) {
                opts.push(column.toOption?.(value) ?? { value, label: value });
              }

              return opts;
            }, [])
          ),
          ["value"]
        ),
        "value"
      );

      // filter values with empty labels
      options = options.filter((option) => option.label?.trim().length > 0);

      // if user typed something add option for "contains" search
      if (inputValue.length > 0 && !comparator) {
        return [{ label: `Contains: "${inputValue}"`, value: inputValue }, ...options];
      } else {
        setPreventClosingOptionsMenu(false);
        // if typed nothing then only show column values
        return options;
      }
    } else {
      const allColumns = columns.map((column) => ({ ...column, preventClosingOnSelect: true }));

      const options = allColumns.filter((column) => {
        if (!column.allowedOnlyOnce) {
          return true;
        }

        return !filterValue.find((item) => item?.column?.path === column.path);
      });

      if (inputValue.length > 0) {
        options.unshift({
          label: `Contains: "${inputValue}"`,
          value: inputValue,
        });
      }

      if (!skipOROperator && filterValue.length > 0 && !isLogicalOperator(filterValue[filterValue.length - 1])) {
        options.unshift({ label: OPERATOR_OR, preventClosingOnSelect: true });
        options.unshift({ label: OPERATOR_AND, preventClosingOnSelect: true });
      }

      return options;
    }
  }, [comparatorSelect, comparator, column, data, inputValue, columns, skipOROperator, filterValue]);

  /*
   * A predicate is created per logical group, the predicate takes a row from the data
   * and runs the logical group's expressions against it, passing if one of the expressions returns true.
   *
   * Each row is evaluated against all the predicates where all predicates must pass for a row to be valid.
   * */

  const setValue = useCallback(
    (newValue) => {
      setFilterValue(newValue);

      if (newValue.length > 0) {
        // Stop execution when the last value is not completed or Logical Operator
        const lastVal = newValue[newValue.length - 1];
        if (isLogicalOperator(lastVal) || !lastVal?.complete) {
          return;
        }

        // Clear consecutive or leading OPERATOR_ORs
        const sanitizedValue = reject(newValue, (option, i) => {
          if (i === 0) {
            return isLogicalOperator(option);
          }

          return isLogicalOperator(newValue[i - 1]) && isLogicalOperator(option);
        });

        if (sanitizedValue.length !== newValue.length) {
          setValue(sanitizedValue);
          return;
        }
      }

      const logicalGroups = createLogicalGroups(newValue, columns, reverseOperatorPrecedence);

      if (logicalGroups.length > 0) {
        onFilter(createFilter(logicalGroups, reverseOperatorPrecedence));
      } else {
        onFilter(null);
      }
    },
    [columns, onFilter, reverseOperatorPrecedence, setFilterValue]
  );

  useMountEffect(() => {
    if (filterValue.length) {
      const logicalGroups = createLogicalGroups(filterValue, columns, reverseOperatorPrecedence);

      onFilter(createFilter(logicalGroups, reverseOperatorPrecedence), false);
    }
  });

  const handleOnChange = (event, newValue, reason) => {
    const opToPrepend = reverseOperatorPrecedence ? OPERATOR_OR : OPERATOR_AND;

    switch (reason) {
      case "createOption":
        if (column) {
          const addedValue = newValue[newValue.length - 1];
          if (column.comparators && !comparator) {
            if (column.comparators.includes(addedValue)) {
              const res = filterValue.slice();
              res.splice(res.length - 1, 1, {
                column,
                comparator: addedValue,
                value: null,
                label: null,
                complete: false,
              });
              setValue(res);
              setComparator(addedValue);
            }
          } else {
            const addedValue = newValue[newValue.length - 1];
            if (column.validate && !column.validate?.(addedValue)) {
              return;
            }
            const res = filterValue.slice();
            res.splice(res.length - 1, 1, {
              column,
              comparator,
              value: addedValue,
              label: addedValue,
              complete: true,
            });
            setValue(res);
            setColumn(null);
            setComparator(null);
          }
        }
        break;
      case "selectOption": {
        if (newValue.length === 0) {
          setValue(newValue);
          setColumn(null);
          setComparator(null);
          return;
        }

        const addedValue = newValue[newValue.length - 1];
        if (isLogicalOperator(addedValue?.label)) {
          const res = filterValue.slice();
          res.splice(res.length, 0, addedValue.label);
          setValue(res);
          setColumn(null);
          setComparator(null);
          trackMixpanelEvent(res);
          return;
        }

        // special contains option
        if (addedValue.label && addedValue.label?.indexOf("Contains") === 0) {
          const valueStr = addedValue.value;
          const res = filterValue.slice();

          if (column) {
            // add regular contains comparator by single column
            res.splice(res.length - 1, 1, opToPrepend, {
              column,
              comparator: "contains",
              value: valueStr,
              label: valueStr,
              complete: true,
            });
          } else {
            // special case of searching by all columns
            res.push(opToPrepend, {
              column: null,
              value: valueStr,
              label: valueStr,
              complete: true,
            });
          }

          setValue(res);
          setColumn(null);
          setComparator(null);
          trackMixpanelEvent(res);
          return;
        }

        if (!column && addedValue?.path) {
          const res = filterValue.slice();
          res.splice(res.length, 0, {
            column: addedValue,
            comparator: null,
            value: null,
            label: null,
            complete: false,
          });
          setValue(res);
          setColumn(addedValue);
          trackMixpanelEvent(res);
        } else if (comparatorSelect) {
          const res = filterValue.slice();
          res.splice(res.length - 1, 1, {
            column,
            comparator: addedValue.label,
            value: null,
            label: null,
            complete: false,
          });
          setValue(res);
          setComparator(addedValue.label);
          trackMixpanelEvent(res);
        } else {
          const res = filterValue.slice();
          res.splice(res.length - 1, 1, opToPrepend, {
            column,
            comparator,
            value: addedValue.value,
            label: addedValue.label,
            complete: true,
          });
          setValue(res);
          setColumn(null);
          setComparator(null);
          trackMixpanelEvent(res);
        }
        break;
      }
      case "removeOption":
        if (event.type === "keydown") {
          if (comparator) {
            const res = filterValue.slice();
            res.splice(res.length - 1, 1, {
              value: null,
              label: null,
              column,
              comparator: null,
              complete: false,
            });
            setValue(res);
            setComparator(null);
            return;
          } else if (column) {
            const res = filterValue.slice();
            res.splice(res.length - 1, 1);
            setValue(res);
            setColumn(null);
            setComparator(null);
            return;
          }
        }

        // remove dangling logical operator
        if (isLogicalOperator(newValue[newValue.length - 1])) {
          newValue.pop();
        }

        setValue(newValue);
        trackMixpanelEvent(newValue);
        break;
      case "clear":
        setValue(newValue);
        setColumn(null);
        setComparator(null);
        trackMixpanelEvent([]);
        break;
      default:
        break;
    }
  };

  const handleOnInputChange = (event, newValue, reason) => {
    switch (reason) {
      case "input":
        setInputValue(newValue);
        break;
      case "clear":
        setColumn(null);
        setComparator(null);
        setInputValue(newValue);
        break;
      case "reset":
        setInputValue(newValue);
        break;
      default:
    }
  };

  // remove dangling boolean operators on close
  const handleOnClose = (_event, reason) => {
    if (reason === "blur" && isLogicalOperator(filterValue.at(-1))) {
      setValue(filterValue.slice(0, -1));
    }
  };

  const handleRenderTags = (tagValue, getTagProps) => {
    let orOperators = 0;
    let labelOperators = 0;
    let notCompleteOperators = 0;
    return tagValue.map((option, index) => {
      const tagProps = { ...getTagProps({ index }) };
      if (isLogicalOperator(option)) {
        // AND OR chip
        return (
          <Chip
            key={`filter-or-${++orOperators}`}
            {...tagProps}
            size="small"
            onDelete={undefined}
            onClick={() => setValue(toggleOperator(index, filterValue))}
            variant="outlined"
            color="default"
            label={option}
            sx={{ minWidth: "3rem" }}
          />
        );
      } else if (!option.column) {
        // Search in in all columns tag
        return (
          <Chip
            key={`filter-label-${++labelOperators}-${option?.label}`}
            {...tagProps}
            size="small"
            color="default"
            label={`${option?.label}`}
          />
        );
      } else if (!option.complete) {
        // Incomplete filter
        delete tagProps.onDelete;
        return (
          <Typography
            key={`filter-nco-${++notCompleteOperators}-${option.column.label}-${option.column.comparators}`}
            {...tagProps}
            variant="subtitle2"
            className={classes.textTag}
          >
            {option.column.label} {option.column.comparators ? (option.comparator ?? "") : ":"}
          </Typography>
        );
      } else {
        // Complete filter
        return (
          <Chip
            key={`filter-${++notCompleteOperators}-${option.column.label}-${option.column.comparators}-${
              option?.label
            }`}
            {...tagProps}
            size="small"
            color="default"
            label={`${option?.column?.label} ${option.comparator ?? ":"} ${option?.label}`}
          />
        );
      }
    });
  };

  const handleRenderInput = (params) => {
    const { InputProps, ...others } = params;
    return (
      <TextField
        {...others}
        variant="outlined"
        data-cy="filter-table-filter-chip"
        fullWidth
        placeholder={!column ? (placeholder ?? "Filter table") : comparator && (column?.placeholder ?? "")}
        InputProps={{
          ...InputProps,
          startAdornment: (
            // show reset filters default icon only if default filters are defined
            <>
              {defaultValue?.length > 0 && (
                <InputAdornment position="start">
                  <Tooltip title="Restore to default">
                    <IconButton onClick={() => setValue(getDefaultValue(defaultValue))} size="small">
                      <FilterIcon color="action" data-cy="set-default-filters" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
              {InputProps.startAdornment}
            </>
          ),
        }}
      />
    );
  };

  const handleRenderOption = (props, option) => {
    if (isLogicalOperator(option?.label)) {
      return (
        <li {...props}>
          <Typography variant="subtitle2" color="primary">
            {option.label}
          </Typography>
        </li>
      );
    }

    return (
      <li {...props}>
        <Typography variant="body2">{option?.label ?? option.key}</Typography>
      </li>
    );
  };

  // for every option selected in the menu decide if to close or not the menu on click
  function onHighlightChange(event, option) {
    if (option?.preventClosingOnSelect) {
      setPreventClosingOptionsMenu(true);
    } else {
      setPreventClosingOptionsMenu(false);
    }
  }

  return (
    <Autocomplete
      style={style}
      freeSolo
      multiple
      autoHighlight
      fullWidth
      limitTags={limitTags}
      onHighlightChange={onHighlightChange}
      disableCloseOnSelect={preventClosingOptionsMenu}
      options={filterOptions ?? []}
      getOptionLabel={(option) => option?.label ?? option}
      value={filterValue}
      groupBy={groupBy}
      inputValue={inputValue}
      size="small"
      PaperComponent={SquarePaper}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onClose={handleOnClose}
      renderTags={handleRenderTags}
      renderInput={handleRenderInput}
      renderOption={handleRenderOption}
      classes={{
        paper: classes.autocompletePaper,
        option: classes.autocompleteOption,
      }}
      data-testid={`${misc.enhancedTableFilter}-${tableName}`}
      data-cy={misc.enhancedTableFilter}
    />
  );
};

export default EnhancedTableFilter;
