import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { type UserModel } from "@doitintl/cmp-models";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, LinearProgress, Paper, Slide, Snackbar, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useInAppNotifications } from "../../../../Context/inAppNotifications/InAppNotificationsContextProvider";
import { type InAppNotification, setLastReadBy } from "../../../../Context/inAppNotifications/types";
import { useUserContext } from "../../../../Context/UserContext";

const LinearProgressAnimation = ({ onDone }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 4);
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress > 100) {
      onDone();
    }
  }, [progress, onDone]);

  return <LinearProgress variant="determinate" value={progress} />;
};

const SnackbarWithProgress = ({
  open,
  popFromQueue,
  currentNotification,
  isDarkTheme,
  userModel,
  handleClickNotification,
}) => (
  <Snackbar
    open={open}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    TransitionComponent={(props) => <Slide {...props} direction="left" />}
  >
    <Paper elevation={10} sx={{ maxWidth: 400, backgroundColor: isDarkTheme ? "#2D2D39" : "#FAFAFA" }}>
      <LinearProgressAnimation onDone={popFromQueue} />
      <Stack direction="row" alignItems="center" gap={2} sx={{ padding: 1.5 }}>
        <CircleNotificationsIcon color="primary" fontSize="large" />
        <Typography variant="body2">{`${currentNotification.newOrUpdated}: ${currentNotification.title}`}</Typography>
        <Button
          variant="outlined"
          onClick={async () => {
            await handleClickNotification(currentNotification, userModel.ref);
            popFromQueue();
          }}
        >
          View
        </Button>
        <IconButton onClick={popFromQueue}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Paper>
  </Snackbar>
);

export const RealTimeNotifications = () => {
  const history = useHistory();
  const { userModel } = useUserContext({ allowNull: false });
  const { customer } = useCustomerContext();
  const isDarkTheme = useDarkThemeCheck();
  const { realTimeQueue, setRealTimeQueue } = useInAppNotifications();
  const [isTabFocused, setIsTabFocused] = useState<boolean | null>(null);
  const [isBackgroundNotificationsEnabled, setIsBackgroundNotificationsEnabled] = useState(false);

  const currentNotification = realTimeQueue[0];

  // mark notification as read and navigate to the url
  const handleClickNotification = useCallback(
    async (n: InAppNotification, userRef: FirebaseModelReference<UserModel>) => {
      await setLastReadBy(n, userRef, customer.ref);
      history.push(n.urlPath);
    },
    [history, customer.ref]
  );

  const popFromQueue = useCallback(() => {
    setRealTimeQueue((prev) => prev.slice(1));
  }, [setRealTimeQueue]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabFocused(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    if ("Notification" in window) {
      setIsBackgroundNotificationsEnabled(Notification.permission === "granted");
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!currentNotification) {
      return;
    }

    // handle the notification when the tab is not focused
    if (!isTabFocused) {
      if (isBackgroundNotificationsEnabled) {
        const notification = new Notification(currentNotification.newOrUpdated, {
          body: currentNotification.title,
          icon: "/favicon.svg",
          badge: "/favicon.svg",
        });
        notification.onclick = (event) => {
          event.preventDefault();
          window.focus();
          notification.close();
          handleClickNotification(currentNotification, userModel.ref);
        };
      }

      popFromQueue();
    }
  }, [
    handleClickNotification,
    userModel.ref,
    popFromQueue,
    currentNotification,
    isTabFocused,
    isBackgroundNotificationsEnabled,
  ]);

  // prevent re-rendering of the SnackbarWithProgress component when the queue changes before the animation is done
  const MemoSnackbarWithProgress = useMemo(
    () => (
      <SnackbarWithProgress
        open={true}
        popFromQueue={popFromQueue}
        currentNotification={currentNotification}
        isDarkTheme={isDarkTheme}
        userModel={userModel}
        handleClickNotification={handleClickNotification}
      />
    ),
    [currentNotification, isDarkTheme, userModel, handleClickNotification, popFromQueue]
  );

  return <Box>{currentNotification && isTabFocused && MemoSnackbarWithProgress}</Box>;
};
