import { useEffect, useMemo, useState } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCloudConnectContext } from "../../Context/customer/CloudCOnnectContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { getUserOrgID, PresetOrganizations, RootOrgId } from "../../utils/common";
import { getWidgetDocId } from "../../utils/widgets";
import { getServiceAccountsBQFinOps } from "../Dashboard/BigQueryLens/utils";

const bqSpendBySku = "IQYiclcHa3KdjWGV3Lph";

export const useIsCustomerUsingBQ = () => {
  const { isFeatureEntitled } = useTier();
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const [isCustomerUsingBQ, setIsCustomerUsingBQ] = useState<boolean>();
  const presentationMode = customer && isCustomerInPresentationMode(customer);
  const cloudConnect = useCloudConnectContext();
  const serviceAccountsWithBigQueryFinops = getServiceAccountsBQFinOps(cloudConnect);
  const isBQLensConfigured = useMemo(
    () => serviceAccountsWithBigQueryFinops && serviceAccountsWithBigQueryFinops?.length > 0,
    [serviceAccountsWithBigQueryFinops]
  );

  useEffect(() => {
    if (isDoitEmployee === undefined) {
      return;
    }

    if (presentationMode === undefined || presentationMode === null) {
      return;
    }

    if (userOrganization === undefined || !customerOrPresentationModeCustomer) {
      return;
    }

    const customerID = customerOrPresentationModeCustomer?.id;

    const cloudAnalyticsWidgets = getCollection(CloudAnalyticsModel).doc("widgets").collection("cloudAnalyticsWidgets");
    let orgId = getUserOrgID(isDoitEmployee, userOrganization);
    if (orgId === PresetOrganizations.DOITOrg) {
      orgId = RootOrgId;
    }
    const bqBySkuDocId = getWidgetDocId(customerID, bqSpendBySku, orgId);

    cloudAnalyticsWidgets
      .doc(bqBySkuDocId)
      .get()
      .then((bqBySkuDoc) => {
        const bqBySkuData = bqBySkuDoc.asModelData();
        const bqBySkuRowsLength =
          (bqBySkuData?.data?.rows && Object.entries(bqBySkuData?.data?.rows).reduce((prevR) => prevR + 1, 0)) || 0;

        setIsCustomerUsingBQ(
          isFeatureEntitled("lens:bigquery", true) && (bqBySkuRowsLength > 0 || presentationMode || isBQLensConfigured)
        );
      });
  }, [
    customerOrPresentationModeCustomer,
    userOrganization,
    isDoitEmployee,
    presentationMode,
    isBQLensConfigured,
    isFeatureEntitled,
  ]);

  return { isCustomerUsingBQ };
};
