import { useState } from "react";

import { useHistory } from "react-router-dom";
import { Hidden, Typography } from "@mui/material";

import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import EntityDetachDialog from "../../Components/EntityDetachDialog";
import EntityStatusDialog from "../../Components/EntityStatusDialog";
import { FilterTable } from "../../Components/FilterTable/FilterTable";
import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import EntitiesEmptyState from "../../Pages/Entities/EntitiesEmptyState";
import { type HeaderColumn, headerFromDataColumn } from "../../types/FilterTable";
import { threeDotHeaderSection } from "../Assets/assetUtils";
import {
  billingProfilesColumnHeaderHiddenMap,
  billingProfilesFilterColumns,
} from "./BillingProfilesFilterTableColumns";
import BillingProfilesTableRow from "./BillingProfilesTableRow";

type BillingProfilesFilterTableProps = {
  entities: Entity[];
};

const BillingProfilesFilterTable = ({ entities }: BillingProfilesFilterTableProps) => {
  const [entityStatusDialogOpen, setEntityStatusDialogOpen] = useState<boolean>(false);
  const [entityDetachDialogOpen, setEntityDetachDialogOpen] = useState<boolean>(false);
  const [editEntity, setEditEntity] = useState<Entity>();
  const history = useHistory();
  const { customer } = useCustomerContext();

  const onDetachClick = (entity: Entity) => {
    setEditEntity(entity);
    setEntityDetachDialogOpen(true);
    setEntityStatusDialogOpen(false);
  };
  const onActivateDeactivateClick = (entity: Entity) => {
    setEditEntity(entity);
    setEntityDetachDialogOpen(false);
    setEntityStatusDialogOpen(true);
  };
  const onReset = () => {
    setEditEntity(undefined);
    setEntityDetachDialogOpen(false);
    setEntityStatusDialogOpen(false);
  };
  const tableRowAsCallback = ({ row }) => (
    <BillingProfilesTableRow
      entity={row}
      onActivateDeactivateClick={onActivateDeactivateClick}
      onDetachClick={onDetachClick}
    />
  );

  const headerColumns: HeaderColumn[] = [
    ...billingProfilesFilterColumns.map((fc) =>
      headerFromDataColumn(fc, undefined, billingProfilesColumnHeaderHiddenMap)
    ),
    threeDotHeaderSection,
  ];

  return (
    <FilterTable<Entity>
      filterBarPlaceholder="Filter billing profiles"
      toolbarProps={{
        title: (
          <Hidden smDown>
            <Typography variant="h1">{`Billing profiles (${entities.length})`}</Typography>
          </Hidden>
        ),
        primaryButton: {
          text: "Create new billing profile",
          onClick: () =>
            history.push({
              pathname: `/customers/${customer.id}/entities`,
              state: { from: history.location.pathname },
            }),
        },
        allowToEditColumns: true,
      }}
      emptyTableComponent={<EntitiesEmptyState />}
      showFilterBar={true}
      rowComponent={tableRowAsCallback}
      headerColumns={headerColumns}
      filterColumns={billingProfilesFilterColumns}
      tableItems={entities}
      data-cy="billingProfilesFilterTable"
    >
      <DoitConsoleTitle pageName="Billing profiles" pageLevel1={editEntity?.id} />
      {editEntity && (
        <>
          {entityDetachDialogOpen && <EntityDetachDialog onClose={onReset} entity={editEntity} />}

          {entityStatusDialogOpen && (
            <EntityStatusDialog
              onClose={onReset}
              entity={editEntity}
              onRequestRefresh={onReset}
              newStatus={editEntity.active ? "Inactive" : "Active"}
            />
          )}
        </>
      )}
    </FilterTable>
  );
};

export default BillingProfilesFilterTable;
