import { useCallback, useMemo } from "react";

import { Link as RouteLink } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import { Box, TableCell, Typography } from "@mui/material";
import { useClipboard } from "use-clipboard-copy";

import { globalText, marketplaceText } from "../../../../assets/texts";
import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { useInfoSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { type DialogMode } from "../components/ApproveOrRejectDialog";
import { type AccountConfig } from "../hooks/useMarketplaceAccounts";
import usePopulateBillingAccount from "./usePopulateBillingAccount";
import { accountOptions, getBillingAccountType } from "./utils";

type Props = {
  row: AccountConfig;
  setDialogMode: (value: DialogMode) => void;
  setClickedRow: (row: AccountConfig) => void;
};

export const AccountRow = ({ row, setDialogMode, setClickedRow }: Props) => {
  const infoSnackbar = useInfoSnackbar();
  const populateBillingAccount = usePopulateBillingAccount();
  const isMarketplaceAdmin = useDoitRoleCheck(DoitRole.MarketplaceAdmin);

  const clipboard = useClipboard({
    copiedTimeout: 1400,
    onSuccess: () => infoSnackbar(globalText.COPIED_TO_CLIPBOARD),
  });

  const handleApprove = useCallback(() => {
    setDialogMode("approve");
    setClickedRow(row);
  }, [row, setClickedRow, setDialogMode]);

  const handleReject = useCallback(() => {
    setDialogMode("reject");
    setClickedRow(row);
  }, [row, setClickedRow, setDialogMode]);

  const handleCopyId = useCallback(() => {
    clipboard.copy(row.ref.id);
  }, [clipboard, row.ref.id]);

  const actionMenu = useMemo(
    () => ({
      [marketplaceText.COPY_ACCOUNT_ID]: handleCopyId,
      [marketplaceText.POPULATE_BILLING_ACCOUNT]: () => populateBillingAccount(row.ref.id),
      [globalText.APPROVE]: handleApprove,
      [globalText.REJECT]: handleReject,
    }),
    [handleCopyId, handleApprove, handleReject, populateBillingAccount, row.ref.id]
  );

  const actionHandler = useCallback(
    (action: string) => () => {
      actionMenu[action]();
    },
    [actionMenu]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      accountOptions
        .filter((option) => {
          switch (option.value) {
            case globalText.APPROVE:
              return (
                isMarketplaceAdmin && row.transform?.isPending && !!row.data.billingAccountId && !!row.data.customer
              );
            case globalText.REJECT:
              return false; // currently disabled always
            case marketplaceText.POPULATE_BILLING_ACCOUNT:
              return isMarketplaceAdmin && !row.data.billingAccountId && !!row.data.customer;
            default:
              return true;
          }
        })
        .map((option) => ({
          label: <Typography color={option.color}>{option.value}</Typography>,
          action: actionHandler(option.value),
          key: option.value,
        })),
    [actionHandler, isMarketplaceAdmin, row.data.billingAccountId, row.data.customer, row.transform?.isPending]
  );

  return (
    <CellsWrapper>
      <TableCell>
        {row.data.customer && row.customer ? (
          <Typography color="inherit" variant="body2" component={RouteLink} to={`/customers/${row.data.customer.id}`}>
            {row.customer.data.primaryDomain}
          </Typography>
        ) : (
          <Typography color="inherit" variant="body2">
            {globalText.NA}
          </Typography>
        )}
      </TableCell>
      <TableCell>{row.ref.id || ""}</TableCell>
      <TableCell>{getBillingAccountType(row.data?.billingAccountType)}</TableCell>
      <TableCell>{row.data.billingAccountId ?? globalText.NA}</TableCell>
      <TableCell>{row.transform?.createTime}</TableCell>
      <TableCell>{row.transform?.updateTime}</TableCell>
      <TableCell>{row.transform?.state}</TableCell>
      <TableCell>
        <Box m={-1.5}>
          <ThreeDotsMenu options={threeDotsMenuOptions} />
        </Box>
      </TableCell>
    </CellsWrapper>
  );
};
