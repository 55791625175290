import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import en from "./en/resource";
import ja from "./ja/resource";

export const resources = {
  en,
  ja,
} as const;

i18n.use(initReactI18next).init({
  resources,
  ns: ["navigation", "services"],
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "en",
});

export default i18n;
