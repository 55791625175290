import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { type AxiosError } from "axios";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../Components/Stepper";
import { useDataHubContext } from "../../../Context/DataHubContext";
import { consoleErrorWithSentry } from "../../../utils";
import { type CSVError } from "../utils";
import ImportCSV, { type CSVDataType } from "./ImportCSV";
import InformationalStep from "./InformationalStep";
import PreviewCSVData from "./PreviewCSVData";
import SuccessfulPage from "./SuccessfulPage";

interface ErrorResponse {
  errors: CSVError[];
}

type Props = {
  onClose: () => void;
  datasetName: string;
};

const ImportCSVStepper = ({ onClose, datasetName }: Props) => {
  const [stepperState, setStepperState] = useState<StepState[]>(["complete", "incomplete", "incomplete"]);
  const [csvData, setCsvData] = useState<CSVDataType[]>();
  const [currentStep, getCurrentStep] = useState(0);
  const [fileName, setFileName] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [errors, setErrors] = useState<CSVError[]>([]);
  const { uploadCSV } = useDataHubContext();
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [fileSizeLimitError, setFileSizeLimitError] = useState("");
  const errorSnackbar = useErrorSnackbar();

  const handleCSVData = (data: CSVDataType[], fileName: string) => {
    setCsvData(data);
    setFileName(fileName);
  };

  const handleUploadFileLoading = (isLoading: boolean) => {
    setUploadLoading(isLoading);
  };

  const stepComponents = [
    <InformationalStep key="informational-step" />,
    <ImportCSV
      datasetName={datasetName}
      handleCSVData={handleCSVData}
      errors={errors}
      setErrors={setErrors}
      fileName={fileName}
      setFileName={setFileName}
      onUploading={handleUploadFileLoading}
      key="import-csv"
    />,
    <PreviewCSVData key="preview-csv-data" data={csvData} errors={errors} />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: datahubTxt.PREPARE_FILE,
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: datahubTxt.UPLOAD_CSV,
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: stepComponents[2],
      label: datahubTxt.PREVIEW,
      order: 2,
      required: true,
      state: stepperState[2],
    },
  ];

  useEffect(() => {
    setStepperState((prev) =>
      prev.map((p, i) => {
        if (i < currentStep) {
          return "complete";
        } else if (i > currentStep) {
          return "incomplete";
        } else if (i === currentStep) {
          if (i === 1) {
            return csvData && fileName && !errors.length ? "complete" : "incomplete";
          } else if (i === 2) {
            return "complete";
          }
        }
        return p;
      })
    );
  }, [currentStep, csvData, fileName, errors.length]);

  const onSubmit = async () => {
    try {
      if (csvData) {
        setUploadLoading(true);
        const Schema = Object.keys(csvData[0]);
        const RawEvents = csvData
          .map((row) => Schema.map((header) => (row[header] !== undefined ? String(row[header]) : "")))
          .filter((row) => row.length > 0);

        // Remove the last line if it is empty
        if (RawEvents.length > 0 && RawEvents[RawEvents.length - 1].every((cell) => cell === "")) {
          RawEvents.pop();
        }

        const payload = {
          Source: "csv",
          Schema,
          RawEvents,
          Filename: fileName,
          Execute: true,
        };

        const { error } = await uploadCSV(payload, datasetName);
        if (error) {
          setFileSizeLimitError(error);
        }

        setSubmittedSuccessfully(!error);
      }
    } catch (error) {
      consoleErrorWithSentry(error);
      if ((error as AxiosError).response?.data) {
        const errorResponse = (error as AxiosError)?.response?.data as ErrorResponse;
        if (errorResponse.errors) {
          setErrors(errorResponse.errors);
        } else {
          errorSnackbar(datahubTxt.ERROR_UPLOADING_DATA);
        }
      } else {
        errorSnackbar(datahubTxt.ERROR_UPLOADING_DATA);
      }
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <Box sx={{ pt: 3, pb: 7 }}>
      {submittedSuccessfully ? (
        <SuccessfulPage datasetName={datasetName} onClose={onClose} />
      ) : (
        <Stepper
          maxWidth={800}
          loading={uploadLoading}
          onCancel={onClose}
          onSubmit={onSubmit}
          steps={steps}
          footerMaxWidth={800}
          contentSx={{
            px: 3,
            mb: 3,
          }}
          submitButtonLabel={datahubTxt.CONFIRM_UPLOAD}
          getCurrentStep={getCurrentStep}
          disableSubmit={errors.length > 0}
        />
      )}

      {!!fileSizeLimitError && errorSnackbar(fileSizeLimitError)}
    </Box>
  );
};

export default ImportCSVStepper;
