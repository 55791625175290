import { type ComponentType } from "react";

import { CloudFlowProvider, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { type FormikProps, useFormikContext } from "formik";

import AWSParameterForm from "./AWSParameterForm";
import GCPParameterForm from "./GCPParameterForm";

const providerComponents: Record<
  CloudFlowProvider,
  ComponentType<{
    inputModel: StructureApiServiceModelDescriptor<Member>;
    formikProps: FormikProps<any>;
  }>
> = {
  [CloudFlowProvider.AWS]: AWSParameterForm,
  [CloudFlowProvider.GCP]: GCPParameterForm,
};

const CloudSpecificParameterForm = ({
  provider,
  inputModel,
}: {
  provider: CloudFlowProvider;
  inputModel: StructureApiServiceModelDescriptor<Member>;
}) => {
  const formikProps = useFormikContext();

  const ProviderFormComponent = providerComponents[provider];

  return ProviderFormComponent ? <ProviderFormComponent inputModel={inputModel} formikProps={formikProps} /> : null;
};

export default CloudSpecificParameterForm;
