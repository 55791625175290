import { useEffect, useMemo, useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Typography } from "@mui/material";
import Autocomplete, {
  type AutocompleteOwnerState,
  type AutocompleteRenderGetTagProps,
} from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import isEqual from "lodash/isEqual";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type MultiselectGroupedCheckboxesProps<T> = {
  options: T[];
  optionDisplayLabel: (option: T) => string;
  optionGroupBy?: (option: T) => string;
  fieldLabel: string | React.ReactNode;
  fieldPlaceholder?: string;
  onChange: (val: T[]) => void;
  selectAllOption?: T;
  renderTags?:
    | ((
        selectedValues: T[],
        getTagProps?: AutocompleteRenderGetTagProps,
        ownerState?: AutocompleteOwnerState<T, true, false, false, "div">
      ) => React.ReactNode)
    | undefined;
};
export default function MultiselectGroupedCheckboxes<T>({
  options,
  optionDisplayLabel,
  optionGroupBy,
  onChange,
  fieldLabel,
  fieldPlaceholder,
  selectAllOption,
  renderTags,
}: MultiselectGroupedCheckboxesProps<T>) {
  const menuOptions = useMemo(
    () => (options.length === 0 ? [] : selectAllOption ? [selectAllOption, ...options] : options),
    [options, selectAllOption]
  );

  const [selectedOptions, setSelectedOptions] = useState<T[]>(options);
  const [initDone, setInitDone] = useState<boolean>(false);
  const allSelected = options.length === selectedOptions.length;
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(options);
      const result = options.filter((el) => !isEqual(el, selectAllOption));
      onChange(result);
    } else {
      setSelectedOptions([]);
      onChange([]);
    }
  };

  // on load, select all options only once
  useEffect(() => {
    if (!initDone && options.length > 0) {
      setSelectedOptions(options);
      onChange(options);
      setInitDone(true);
    }
  }, [initDone, onChange, options]);

  // detect options change (e.g. the interval was changed) --> reset initDone?
  useEffect(() => {
    setInitDone(false);
  }, [options]);

  const optionRenderer = (props, option: T, { selected }) => {
    const selectAllProps = isEqual(option, selectAllOption) ? { checked: allSelected } : {};
    return (
      <li {...props} key={optionDisplayLabel(option)} style={{ paddingLeft: 10 }}>
        <Checkbox icon={icon} size="small" checkedIcon={checkedIcon} checked={selected} {...selectAllProps} />
        <Typography variant="body2">{optionDisplayLabel(option)}</Typography>
      </li>
    );
  };

  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={1}
      options={menuOptions}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={optionDisplayLabel}
      onChange={(event, value, reason) => {
        setSelectedOptions(value);
        if (reason === "selectOption" || reason === "removeOption") {
          if (value.find((option) => isEqual(option, selectAllOption))) {
            handleToggleSelectAll();
          } else {
            onChange(value);
          }
        } else if (reason === "clear") {
          setSelectedOptions([]);
          onChange([]);
        }
      }}
      renderOption={optionRenderer}
      renderInput={(params) => <TextField {...params} label={fieldLabel} placeholder={fieldPlaceholder} />}
      style={{ minWidth: 240, maxWidth: 350, marginTop: 8 }}
      groupBy={optionGroupBy}
      renderTags={renderTags}
    />
  );
}
