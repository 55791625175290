import { useEffect, useState } from "react";

import { type IntegrationModelCloudhealthPricebooksModel, type Tenancy } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { fieldRange } from "../../../const";
import { costMode, type payerAccountObj, type SelectedPricebooks, type supportMapObj } from "../types";
import { accObj, costInputLabel, getPayerAccountById, isNumError, NEW_ACCOUNT_ID } from "../utils";
import { CreateNewMPAButton } from "./CreateNewMPAButton";
import PayerAccountField from "./PayerAccountField";
import { PricebookField } from "./PricebookField";
import SupportCostFields from "./SupportCostFields";

const TenancySortOrder: Record<Tenancy, number> = {
  ["dedicated"]: 0,
  ["shared"]: 1,
};

function getFirstAvailableDedicatedPayerIdFromList(
  payerList: payerAccountObj[],
  existsInSupport: (mpaId: string) => boolean
): string | null {
  return (
    Array.from(payerList)
      .sort((first, second) => TenancySortOrder[first.data.tenancyType] - TenancySortOrder[second.data.tenancyType])
      .find(({ ref: { id } }) => !existsInSupport(id))?.ref.id ?? null
  );
}

export const nonDigitsOutOfRange = (val: string) => {
  const value = String(val);
  return (
    val === null ||
    val === "" ||
    (val !== null &&
      (value.match(/^(100|(\d\d?(\.\d+)?))$/) === null || // 0-100 numbers only, decimal optional
        (value !== "" && isNaN(parseInt(value))) || // NaN
        (value !== "" &&
          !isNaN(parseInt(value)) &&
          (parseInt(value) < fieldRange.discount.min || parseInt(value) > fieldRange.discount.max)))) // not in range 0-100
  );
};

type Props = {
  payerList: payerAccountObj[];
  updateSupportObj: (obj: supportMapObj, i: number) => void;
  mpaId: string | null;
  index: number;
  handleDelete?: (index: number) => void;
  onEditDone: () => void;
  inEdit: () => void;
  contractSupportVal?: string;
  contractSupportChargeMode?: costMode;
  contractSupportErrorBool?: boolean;
  shouldDeleteBtnShow: boolean;
  existsInSupport: (s: string) => boolean;
  onChangeSelectedPricebooks: (p: IntegrationModelCloudhealthPricebooksModel | null, mpaId: string) => void;
  selectedPricebooks: SelectedPricebooks;
  doesNotHaveEditPermission: boolean;
};
const AccountBox = (props: Props) => {
  const {
    mpaId,
    index,
    payerList,
    handleDelete,
    onEditDone,
    inEdit,
    updateSupportObj,
    contractSupportVal,
    contractSupportChargeMode,
    contractSupportErrorBool,
    shouldDeleteBtnShow,
    existsInSupport,
    onChangeSelectedPricebooks,
    selectedPricebooks,
    doesNotHaveEditPermission,
  } = props;

  const theme = useTheme();
  const [chargeMode, setChargeMode] = useState(costMode.percentage);
  const [costNum, setCostNum] = useState("");
  const [selectedMpaId, setSelectedMpaId] = useState<string | null>(null);
  const [costNumError, setCostNumError] = useState(false);
  const [disablePricebook, setDisablePricebook] = useState(false);

  useEffect(() => {
    const isFreshForm: boolean = mpaId === NEW_ACCOUNT_ID;
    const numInputError: boolean = isNumError(
      isFreshForm,
      selectedMpaId,
      contractSupportErrorBool,
      costNum,
      mpaId,
      contractSupportVal
    );
    if (numInputError) {
      inEdit();
    }
    if (!numInputError && selectedMpaId?.length) {
      onEditDone();
    }
    setCostNumError(numInputError);
  }, [costNum, selectedMpaId, contractSupportVal, contractSupportErrorBool, mpaId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    contractSupportChargeMode && !costNumError && setChargeMode(contractSupportChargeMode);
  }, [contractSupportChargeMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    typeof contractSupportVal !== "undefined" && setCostNum(contractSupportVal);
  }, [contractSupportVal]);

  useEffect(() => {
    if (costNum && selectedMpaId) {
      const payerAcc: payerAccountObj | undefined = getPayerAccountById(payerList, selectedMpaId);
      if (payerAcc) {
        updateSupportObj({ [selectedMpaId]: accObj(costNum, chargeMode, payerAcc.ref) }, index);
      }
    }
  }, [costNum, selectedMpaId, chargeMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDeleteAccount = (): void => handleDelete?.(index);

  const changeRadioBtn = (label: string): void => {
    setChargeMode(costMode[label]);
  };

  useEffect(() => {
    // when no payer account is selected, select the first not already selected payer account from the list
    if (mpaId === null || mpaId === NEW_ACCOUNT_ID) {
      setSelectedMpaId(getFirstAvailableDedicatedPayerIdFromList(payerList, existsInSupport));
    }
    // if mpaId is an account number, select payer account by account number
    else if (!getPayerAccountById(payerList, mpaId)) {
      setSelectedMpaId(payerList.find(({ data: { accountNumber } }) => accountNumber === mpaId)?.ref.id ?? null);
    }
    // mpaId is an MPA ID
    else {
      setSelectedMpaId(mpaId);
    }
  }, [existsInSupport, mpaId, payerList]);

  useEffect(() => {
    const payerAccount = (selectedMpaId && getPayerAccountById(payerList, selectedMpaId)) || undefined;
    setDisablePricebook(payerAccount?.data.tenancyType !== "dedicated");
  }, [payerList, selectedMpaId]);

  const onPayerSelection = (mapId: string) =>
    setSelectedMpaId((previousMpaId) => {
      if (previousMpaId) {
        // unassign pricebook from previously selected MPA
        onChangeSelectedPricebooks(null, previousMpaId);
      }
      return mapId;
    });

  return (
    <Box sx={{ display: "flex", flexDirect: "column" }} data-testid="aws-contract-support-account-box">
      <Stack
        pb={6}
        mt={4}
        key={mpaId}
        data-testid="aws-contract-support-step-stack"
        sx={{ minWidth: "100%", borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Box sx={{ py: 2 }} data-testid="aws-contract-support-payer-box">
          <PayerAccountField
            selectedMpaId={selectedMpaId}
            payerList={payerList}
            onPayerSelection={onPayerSelection}
            existsInSupport={existsInSupport}
            doesNotHaveEditPermission={doesNotHaveEditPermission}
          />
          <CreateNewMPAButton onMpaCreate={setSelectedMpaId} disabled={doesNotHaveEditPermission} />
        </Box>

        <Box data-testid="aws-contract-support-cost-fields" mt={0}>
          <SupportCostFields
            costNum={costNum}
            costNumError={costNumError}
            chargeMode={chargeMode}
            costInputLabel={costInputLabel(chargeMode)}
            changeRadioBtn={changeRadioBtn}
            onCostNumChange={setCostNum}
            contractSupportErrorBool={contractSupportErrorBool ?? false}
            modeSelectionDisabled={doesNotHaveEditPermission}
          />
        </Box>
        <Box pt={3}>
          <Typography pb={2} variant="subtitle1">
            Special pricing
          </Typography>
          <PricebookField
            onChangeSelectedPricebooks={onChangeSelectedPricebooks}
            payerAccount={selectedMpaId}
            selectedPricebooks={selectedPricebooks}
            disabled={disablePricebook || doesNotHaveEditPermission}
          />
        </Box>
      </Stack>
      {shouldDeleteBtnShow && (
        <Box sx={{ ml: 1, mt: 4.4, py: 2 }} data-testid="aws-contract-support-remove-account-icon">
          <IconButton
            size="small"
            data-cy="closeButton"
            key="close"
            aria-label="Close"
            onClick={onDeleteAccount}
            disabled={doesNotHaveEditPermission}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
export default AccountBox;
