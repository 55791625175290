import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";

import { type Contract } from "../../../../../types";
import { assetTypeName, formatNumber } from "../../../../../utils/common";
import { useFullScreen } from "../../../../../utils/dialog";

type ContractSelectionFieldProps = {
  contractList: Contract[];
  saveSelectedContract: (contractTS: string) => void;
};
export const ContractSelectionField = (props: ContractSelectionFieldProps) => {
  const { contractList, saveSelectedContract } = props;
  const { isMobile } = useFullScreen();
  const dateFormat = isMobile ? "d.L.yy" : "dd.LL.yyyy";
  const listOfContracts = contractList.map((contract: Contract, index) =>
    contract?.startDate && contract?.endDate ? (
      <MenuItem key={index} value={contract?.startDate?.seconds}>
        {assetTypeName(contract?.type)}&nbsp; (
        {DateTime.fromSeconds(contract?.startDate?.seconds).startOf("day").toFormat(dateFormat)}
        {" - "}
        {DateTime.fromSeconds(contract?.endDate?.seconds).startOf("day").toFormat(dateFormat)}) - $
        {formatNumber(contract?.commitmentPeriods?.reduce((sum, x) => sum + x.value, 0))}
      </MenuItem>
    ) : (
      <></>
    )
  );
  return (
    <>
      <TextField
        id="contractId"
        name="contract"
        select
        label="Contract"
        required
        defaultValue={contractList[0]?.startDate?.seconds}
        onChange={(e) => saveSelectedContract(e.target.value)}
        variant="outlined"
        margin="normal"
        data-cy="contracts-menu"
      >
        {listOfContracts}
      </TextField>
    </>
  );
};

export default ContractSelectionField;
