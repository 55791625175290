import { type ReactNode, useMemo } from "react";

import { Card, CardContent, CardHeader, Stack, useTheme } from "@mui/material";
import { DateTime } from "luxon";

import { assetTypeName, getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { StatusChip } from "../components/StatusChips";
import { item } from "../RampList/itemDetails";
import { type RampPlanModel, type RampPlanStatus } from "../types";
import { formatUSDollars, timestampToFormat } from "../utils";
import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "./DefinitionList";
import { computeTrueEndDate } from "./PeriodTables/Tables/getInitPeriodsDataFromContract";

const MetadataCard = ({ title, children }: { title: string; children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        flex: 1,
        "& .MuiCardHeader-root": {
          backgroundColor: theme.palette.general.backgroundDark,
          borderBottom: `1px solid ${theme.palette.general.divider}`,
        },
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "body1",
          fontWeight: 500,
        }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

type RampPlanMetadataProps = {
  rampPlan: RampPlanModel;
  dateTimeEstEndDate: DateTime | undefined;
};

export const RampPlanMetadata = ({ rampPlan, dateTimeEstEndDate }: RampPlanMetadataProps) => {
  const { isMobile } = useFullScreen("lg");
  const rampPlanDetails = useMemo(() => item(rampPlan, "", false), [rampPlan]);
  const valueRemaining = useMemo(
    () => rampPlanDetails.targetAmount - (rampPlanDetails.totalActuals ?? 0),
    [rampPlanDetails.targetAmount, rampPlanDetails.totalActuals]
  );

  const timeDiffObject = useMemo(
    () =>
      DateTime.fromSeconds(rampPlan.origEstEndDate.seconds).toUTC().diff(DateTime.now().toUTC(), "months").toObject(),
    [rampPlan.origEstEndDate.seconds]
  );

  const dateTimePlanEndDate: DateTime = useMemo(
    () => DateTime.fromSeconds(rampPlan.origEstEndDate.seconds).toUTC(),
    [rampPlan.origEstEndDate.seconds]
  );

  // which is sooner, estEndDate or contract end date?
  const getSoonestEstEndDate: DateTime = useMemo(() => {
    const possibleEndDates = [dateTimePlanEndDate.toSeconds()];
    if (dateTimeEstEndDate) {
      possibleEndDates.push(dateTimeEstEndDate.toSeconds());
    }

    return DateTime.fromSeconds(possibleEndDates.sort((a, b) => a - b)[0]).toUTC();
  }, [dateTimeEstEndDate, dateTimePlanEndDate]);

  const avgMonthlySpendRequired: number = useMemo(() => {
    // goal already met or ramp plan is not active
    if (valueRemaining <= 0 || !timeDiffObject?.months || timeDiffObject.months <= 0) {
      return 0;
    }

    const monthsBetweenNowAndEstEnd = getSoonestEstEndDate.diff(DateTime.now().toUTC(), "months").months;

    return valueRemaining / monthsBetweenNowAndEstEnd;
  }, [getSoonestEstEndDate, timeDiffObject.months, valueRemaining]);

  const displayEndDate: string = useMemo(() => {
    if (valueRemaining <= 0) {
      return "Complete";
    }

    return computeTrueEndDate(getSoonestEstEndDate).toFormat("MMMM yyyy");
  }, [getSoonestEstEndDate, valueRemaining]);

  const startDateTime = useMemo<DateTime>(
    () => getDateTimeFromFirestoreTimestamp(rampPlan.startDate).toUTC().startOf("day"),
    [rampPlan.startDate]
  );

  const futureRampPlan = useMemo<boolean>(() => startDateTime > DateTime.now().toUTC(), [startDateTime]);

  const rampPlanStatus = useMemo<RampPlanStatus>(() => {
    if (futureRampPlan) {
      return "notStarted";
    }

    return !!timeDiffObject?.months && timeDiffObject.months > 0 ? "active" : "inactive";
  }, [futureRampPlan, timeDiffObject.months]);

  return (
    <Stack direction={isMobile ? "column" : "row"} justifyContent="stretch" spacing={2}>
      <MetadataCard title="Contract details">
        <DefinitionList>
          <DefinitionListTerm>Status:</DefinitionListTerm>
          <DefinitionListDesc>
            <StatusChip status={rampPlanStatus} data-cy="rampPlanStatusChip" />
          </DefinitionListDesc>
          <DefinitionListTerm>Contract duration:</DefinitionListTerm>
          <DefinitionListDesc>{`${timestampToFormat(rampPlan.startDate, "d MMMM yyyy")} to ${computeTrueEndDate(
            DateTime.fromSeconds(rampPlan.origEstEndDate.seconds).toUTC()
          ).toFormat("d MMMM yyyy")}`}</DefinitionListDesc>
          <DefinitionListTerm>Scope:</DefinitionListTerm>
          <DefinitionListDesc>{`${
            rampPlanDetails.platform === "Amazon Web Services" ? "AWS" : assetTypeName(rampPlan.platform)
          } ramp plans`}</DefinitionListDesc>
        </DefinitionList>
      </MetadataCard>
      <MetadataCard title="Commitment details">
        <DefinitionList>
          <DefinitionListTerm>Commitment value:</DefinitionListTerm>
          <DefinitionListDesc>{formatUSDollars(rampPlan.targetAmount)}</DefinitionListDesc>
          <DefinitionListTerm>Spend to date:</DefinitionListTerm>
          <DefinitionListDesc>{formatUSDollars(rampPlanDetails.totalActuals ?? 0)}</DefinitionListDesc>
          <DefinitionListTerm>Value remaining:</DefinitionListTerm>
          <DefinitionListDesc>
            {valueRemaining <= 0 ? "Commitment reached" : formatUSDollars(valueRemaining)}
          </DefinitionListDesc>
        </DefinitionList>
      </MetadataCard>
      <MetadataCard title="Forecast">
        <DefinitionList>
          <DefinitionListTerm>Estimated completion date:</DefinitionListTerm>
          <DefinitionListDesc>{displayEndDate}</DefinitionListDesc>
          <DefinitionListTerm>Average monthly spend required:</DefinitionListTerm>
          <DefinitionListDesc>
            {valueRemaining <= 0 ? "N/A" : formatUSDollars(avgMonthlySpendRequired)}
          </DefinitionListDesc>
        </DefinitionList>
      </MetadataCard>
    </Stack>
  );
};
