import { useCallback, useState } from "react";

import { Button, Hidden, TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { formatNumber } from "../../../../../utils/common";
import LookerLicenseRequestDialog from "./LookerLicenseRequestDialog";

export const lookerAssetRowCyIds = {
  sku: "looker-asset-sku",
  startDate: "looker-asset-start",
  quantity: "looker-asset-quantity",
  status: "looker-asset-status",
  request: "looker-asset-license-request",
};

export type LookerAssetRowData = {
  id: string;
  sku: string;
  serviceStartDate: DateTime;
  quantity: number;
  status: "Active" | "Inactive";
};

export type LookerAssetRowProps = { data: LookerAssetRowData };

const LookerAssetRow = ({ data }: LookerAssetRowProps) => {
  const [licenseDialogOpen, setLicenseDialogOpen] = useState<boolean>(false);
  const closeRequestDialog = useCallback<() => void>(() => setLicenseDialogOpen(false), []);
  const openRequestDialog = useCallback<() => void>(() => setLicenseDialogOpen(true), []);

  return (
    <>
      <TableCell>
        <Typography variant="body2" component="span" data-cy={lookerAssetRowCyIds.sku}>
          {data.sku}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" data-cy={lookerAssetRowCyIds.startDate}>
          {data.serviceStartDate.toLocaleString(DateTime.DATE_MED)}
        </Typography>
      </TableCell>
      <Hidden smDown>
        <TableCell>
          <Typography
            variant="body2"
            component="span"
            data-cy={lookerAssetRowCyIds.quantity}
            width="7ch"
            textAlign="right"
            display="inline-block"
          >
            {formatNumber(data.quantity, 0)}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell>
          <Typography variant="body2" component="span" data-cy={lookerAssetRowCyIds.status}>
            {data.status}
          </Typography>
        </TableCell>
      </Hidden>
      <TableCell align="right">
        <Typography variant="body2" component="span" data-cy={lookerAssetRowCyIds.request}>
          <Button variant="text" onClick={openRequestDialog}>
            Make license request
          </Button>
        </Typography>
      </TableCell>
      <LookerLicenseRequestDialog open={licenseDialogOpen} onClose={closeRequestDialog} sku={data.sku} />
    </>
  );
};

export default LookerAssetRow;
