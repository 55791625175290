import { Avatar } from "@mui/material";
import { grey } from "@mui/material/colors";
import isNumber from "lodash/isNumber";

import { GKEConfigStatus } from "./enums";
import { StepIcons } from "./Icons";

export type StepProps = {
  index: number;
  status?: GKEConfigStatus;
};

const gray = grey[500];
const StepIcon = ({ index, status }: StepProps) => {
  const statuses = Object.values(GKEConfigStatus);
  const i = statuses.findIndex((v) => v === status);
  if (!isNumber(i)) {
    return <></>;
  }
  const Icon = StepIcons[i];
  return (
    Icon || (
      <Avatar
        sx={{
          width: 24,
          height: 24,
          bgcolor: "transparent",
          color: gray,
          borderRadius: "100%",
          border: `1px solid ${gray}`,
          fontWeight: 400,
          fontSize: 12,
        }}
      >
        {index}
      </Avatar>
    )
  );
};

export default StepIcon;
