import * as Yup from "yup";

import { type CreateBillingProfileData } from "../BillingProfileForm.models";
import { BillingForm, type BillingFormSubmitFn } from "./BillingForm";
import { verifyTaxId } from "./utils";

const EMPTY_BILLING_PROFILE: CreateBillingProfileData = {
  address: "",
  state: "",
  stateCode: "",
  zip: "",
  country: "",
  city: "",

  companyName: "",
  taxId: "",

  firstName: "",
  lastName: "",
  email: "",
  phone: "",

  currency: "",
};

export const createBillingProfileFromValidationSchema: Yup.ObjectSchema<CreateBillingProfileData> = Yup.object({
  country: Yup.string().required(),
  address: Yup.string().trim().required(),
  zip: Yup.string().trim().required(),
  city: (Yup.string() as Yup.StringSchema<string>).trim().nullable(),
  state: (Yup.string() as Yup.StringSchema<string>).trim().nullable(),
  stateCode: (Yup.string() as Yup.StringSchema<string>).trim().nullable(),

  companyName: Yup.string().trim().required(),
  taxId: Yup.string()
    .trim()
    .test("is-valid-tax-id", "Must be a valid Tax ID", (_value, testContext) => verifyTaxId(testContext.parent)),

  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  email: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),

  currency: Yup.string().required(),
});

export type CreateBillingProfileFormProps = { onSubmit: BillingFormSubmitFn<CreateBillingProfileData> };

export const CreateBillingProfileForm = ({ onSubmit }: CreateBillingProfileFormProps) => (
  <BillingForm
    initialValues={EMPTY_BILLING_PROFILE}
    onSubmit={onSubmit}
    validationSchema={createBillingProfileFromValidationSchema}
  />
);
