import {
  AnalyticsDataSource,
  AnalyticsResourceType,
  type AttributionFilter,
  type EntitlementKey,
  Metadata,
  MetadataCloud,
  Positions,
  type ReportConfig,
  type ReportFilter,
  type ReportOrgMetadataModel,
  type TimeInterval,
} from "@doitintl/cmp-models";
import { type QueryDocumentSnapshotModel } from "@doitintl/models-firestore";
import groupBy from "lodash/groupBy";
import intersection from "lodash/intersection";
import partition from "lodash/partition";
import union from "lodash/union";
import uniq from "lodash/uniq";

import { type Dictionary } from "../../../../Pages/CloudAnalytics/types";
import { FixedFilters, timeIntervalOptions } from "../../../../Pages/CloudAnalytics/utilities";
import {
  type AttributionWRef,
  type MetadataOption,
  type MetadataOptionData,
  type MetadataOptionFilter,
  type MetricWSnap,
} from "../../../../types";

const mapValues = (docSnap) => docSnap.get("values");

export const getValuesField = (
  docSnaps: QueryDocumentSnapshotModel<ReportOrgMetadataModel>[],
  attributions?: AttributionWRef[]
): {
  valuesUnion: string[];
  gcpValues: string[];
  awsValues: string[];
  azureValues: string[];
  datahub: string[];
  snowflake: string[];
} => {
  const data = docSnaps[0].data();
  let valuesUnion: string[] = union(...docSnaps.map(mapValues));
  let awsValues: string[] = [];
  let gcpValues: string[] = [];
  let azureValues: string[] = [];
  let snowflake: string[] = [];
  let datahub: string[] = [];

  const metadataByCloud = groupBy(docSnaps, (docSnap) => docSnap.get("cloud"));
  if (data.type === Metadata.ATTRIBUTION) {
    valuesUnion = attributions?.filter((attr) => attr.data.filters?.length).map((attr) => attr.ref.id) ?? [];
  } else {
    awsValues = union(...(metadataByCloud?.[MetadataCloud.AMAZON_WEB_SERVICES] ?? []).map(mapValues));
    gcpValues = union(...(metadataByCloud?.[MetadataCloud.GOOGLE_CLOUD] ?? []).map(mapValues));
    azureValues = union(...(metadataByCloud?.[MetadataCloud.MICROSOFT_AZURE] ?? []).map(mapValues));
    datahub = union(...(metadataByCloud?.[MetadataCloud.DATAHUB] ?? []).map(mapValues));
    snowflake = union(...(metadataByCloud?.[MetadataCloud.SNOWFLAKE] ?? []).map(mapValues));
  }

  return {
    valuesUnion,
    gcpValues,
    awsValues,
    azureValues,
    datahub,
    snowflake,
  };
};

export const getUIVisibility = (
  id: string,
  type: Metadata | string,
  key: string,
  timeInterval?: TimeInterval,
  config?: ReportConfig,
  isCSP?: boolean
): {
  position: Positions;
  visible: boolean;
  available: boolean;
} => {
  let position = Positions.UNUSED;
  let visible = false;
  let available = false;
  if (!timeInterval) {
    visible = true;
    available = true;
  }
  const timeIntervalOption = timeIntervalOptions.find((t) => t.value === timeInterval);
  const filter = config?.filters?.find((f) => f.id === id);

  if (type === Metadata.DATETIME) {
    position = Positions.COL;
    visible = !!timeIntervalOption?.visible.includes(key);
    available = !!timeIntervalOption?.available.includes(key);
  } else if (id === `${Metadata.FIXED}:${FixedFilters.CUSTOMER}` && isCSP) {
    visible = true;
    position = Positions.ROW;
  }
  if (config) {
    // Chips with filter should be visible
    visible = !!filter;
    switch (true) {
      case config.rows?.includes(id):
        position = Positions.ROW;
        visible = true;
        break;
      case config.cols?.includes(id):
        position = Positions.COL;
        // Column dimensions (non dates) saved to report will be visible
        // DateTime fields should match the time interval option on other positions
        visible = type !== Metadata.DATETIME || !!timeIntervalOption?.available.includes(key);
        break;
      case config.count === id:
        position = Positions.COUNT;
        visible = true;
        break;
      case config.calculatedMetric && type === Metadata.ATTRIBUTION:
        position = Positions.UNUSED;
        break;
      default:
        position = Positions.UNUSED;
    }
  }
  return {
    position,
    visible,
    available,
  };
};

export const createFilter = (
  values?: string[] | null,
  filter?: AttributionFilter | ReportFilter
): MetadataOptionFilter => {
  if (filter) {
    return {
      _filter: values ?? null,
      _regexp: filter.regexp ?? null,
      _inverse: filter.inverse,
      _limit: "limit" in filter ? (filter.limit ?? null) : null,
      _limitOrder: "limitOrder" in filter ? (filter.limitOrder ?? null) : null,
      _limitMetric: "limitMetric" in filter ? (filter.limitMetric ?? null) : null,
    };
  }
  return {
    _filter: null,
    _regexp: null,
    _inverse: false,
    _limit: null,
    _limitOrder: null,
    _limitMetric: null,
  };
};

export const getFilterFromConfig = (
  type: Metadata | string,
  id: string,
  attributions: AttributionWRef[] = [],
  config?: ReportConfig,
  calculatedMetric?: MetricWSnap,
  existingFilter?: AttributionFilter,
  nullFallback?: string | null
): MetadataOptionFilter => {
  const mdFilter = existingFilter || (config?.filters ?? []).find((f) => f.id === id);
  if (mdFilter) {
    let filteredValues = mdFilter.values;
    if (type === AnalyticsResourceType.PRESET && mdFilter.id.startsWith(Metadata.ATTRIBUTION)) {
      filteredValues = intersection(
        filteredValues,
        attributions?.map((attr) => attr.ref.id)
      );
    }
    if (config?.calculatedMetric && type === Metadata.ATTRIBUTION) {
      // init the attribution filter for calc metric in case the metric itself was changed
      // the filter that we save on the report config itself may be different
      filteredValues = uniq(calculatedMetric?.data?.variables?.map((v) => v.attribution.id) ?? []);
    }

    if (mdFilter.allowNull && nullFallback) {
      filteredValues?.unshift(nullFallback);
    }
    return createFilter(filteredValues, mdFilter);
  } else {
    return createFilter();
  }
};

export const isDimensionDisabled = (md: MetadataOption, config?: ReportConfig): boolean => {
  const calculatedMetricDisabled = config?.calculatedMetric && md.data.type === Metadata.ATTRIBUTION;
  return !!calculatedMetricDisabled;
};

const modifyDataSourceQueriesWithAnomaly = (
  queries: Dictionary<QueryDocumentSnapshotModel<ReportOrgMetadataModel>[]>
) => {
  const [billing, customersFeature] = partition(
    queries[AnalyticsDataSource.BILLING],
    (b) => b.id !== `${Metadata.FIXED}:feature`
  );
  queries = {
    ...queries,
    [AnalyticsDataSource.BILLING]: billing,
    [AnalyticsDataSource.CUSTOMER_FEATURES]: customersFeature,
  };
  return queries;
};

export const groupByDataSource = (queries: QueryDocumentSnapshotModel<ReportOrgMetadataModel>[]) => {
  const metadataQueriesByDataSource = groupBy(queries, (mdSnap) => {
    const cloud = mdSnap.get("cloud");

    switch (cloud) {
      case MetadataCloud.BQLENS:
        return AnalyticsDataSource.BQLENS;
      case MetadataCloud.DATAHUB:
        return AnalyticsDataSource.BILLING_DATAHUB;
      default:
        return AnalyticsDataSource.BILLING;
    }
  });

  if (metadataQueriesByDataSource[AnalyticsDataSource.BILLING_DATAHUB]) {
    metadataQueriesByDataSource[AnalyticsDataSource.BILLING_DATAHUB] = metadataQueriesByDataSource[
      AnalyticsDataSource.BILLING_DATAHUB
    ].concat(metadataQueriesByDataSource[AnalyticsDataSource.BILLING] ?? []);
  }
  return modifyDataSourceQueriesWithAnomaly(metadataQueriesByDataSource);
};

export const getAttributionsWithAccess = (
  data: MetadataOptionData,
  attributions: AttributionWRef[] | undefined,
  config: ReportConfig | undefined,
  isFeatureEntitled: (feature: EntitlementKey) => boolean
): AttributionWRef[] | undefined => {
  let accessibleAttributions = attributions;

  if (data.type === Metadata.ATTRIBUTION) {
    const hasPresetAttributions = isFeatureEntitled("analytics:attributions:presets");
    const hasCustomAttributions = isFeatureEntitled("analytics:attributions");
    if (!hasPresetAttributions || !hasCustomAttributions) {
      const attributionIdsInConfig = config?.filters?.find((filter) =>
        filter.id.startsWith(Metadata.ATTRIBUTION)
      )?.values;
      accessibleAttributions = attributions?.filter((attr) => {
        const isAllowedType =
          (hasPresetAttributions && attr.data.type === "preset") ||
          (hasCustomAttributions && attr.data.type === "custom");
        const isInConfig = attributionIdsInConfig?.includes(attr.ref.id);
        return isAllowedType || isInConfig;
      });
    }
  }

  return accessibleAttributions;
};

export const isAccessibleAG = (
  data: MetadataOptionData,
  config: ReportConfig | undefined,
  isFeatureEntitled: (feature: EntitlementKey) => boolean
): boolean => {
  const hasPresetAttributionGroups = isFeatureEntitled("analytics:attributionGroups:presets");
  const hasCustomAttributionGroups = isFeatureEntitled("analytics:attributionGroups");
  const isAllowedType =
    (hasPresetAttributionGroups && data.objectType === "preset") ||
    (hasCustomAttributionGroups && (data.objectType === "custom" || data.objectType === "managed"));

  // check if the AG is in the report config and return a boolean
  const isAGInFilters = !!config?.filters?.find((filter) => filter.id === data.id);
  // check if the AG is in the rows or cols
  const isAGInRowsCols = !!config?.rows?.includes(data.id) || !!config?.cols?.includes(data.id);

  return isAllowedType || isAGInFilters || isAGInRowsCols;
};
