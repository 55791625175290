import { type AwsMarketplaceAccountModel } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import { type TierWithRef } from "../../../Context/TierProvider";

export const createMarketplaceContracts = async ({
  api,
  tiers,
  customerId,
  mpAccountData,
}: {
  api: AxiosInstance;
  tiers: TierWithRef[];
  customerId: string;
  mpAccountData: AwsMarketplaceAccountModel;
}) => {
  const startDate = mpAccountData.createdAt.toDate().toISOString();
  const dimensionsToData =
    mpAccountData.entitlementsData?.reduce(
      (acc, data) => ({
        ...acc,
        [data.Dimension]: data,
      }),
      {}
    ) ?? {};
  return Promise.all(
    tiers.map((tier) =>
      api.post("/v1/contract/create", {
        customerID: customerId,
        tier: tier.ref.id,
        startDate,
        endDate: dimensionsToData[tier.sku]?.ExpirationDate?.toDate().toISOString(),
        type: tier.packageType,
        pointOfSale: "aws-marketplace",
      })
    )
  ).catch(() => null);
};
