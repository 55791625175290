import { Alert, AlertTitle, Box, Link, List, ListItem, Stack, Typography } from "@mui/material";

import { NumberedList } from "../../../Flexsave/Common/NumberedList";
import { UsefulAzureLinks } from "./UsefulAzureLinks";
import { containerName, directoryName } from "./utils";

type Props = {
  storageAccountName: string;
};

export default function StepExport({ storageAccountName }: Props) {
  return (
    <Stack spacing={4} pb={5} data-cy="export-step">
      <Stack spacing={4}>
        <Typography variant="h1">Create a billing export</Typography>
        <Alert severity="info">
          <AlertTitle>Is your template deployed?</AlertTitle>
          Allow up to 15 minutes for the template to deploy before creating the billing export
        </Alert>

        <NumberedList
          list={[
            <Typography key="0" mb={2}>
              In{" "}
              <Link href="https://portal.azure.com" target="_blank">
                Azure Portal
              </Link>
              , go to COST MANAGEMENT + BILLING, make sure you selected correct billing account, and then select{" "}
              <Link href="https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/exports" target="_blank">
                EXPORTS
              </Link>
              .
            </Typography>,

            <Typography key="1" mb={2}>
              Select CREATE, and:
              <List sx={{ listStyleType: "disc", pl: 4, pt: 2 }}>
                {[
                  <Typography key="type-of-data">
                    In TYPE OF DATA field, select "
                    <Typography fontWeight={500} display="inline">
                      Cost and usage details (actual)
                    </Typography>
                    "
                  </Typography>,

                  <Typography key="name">
                    In the EXPORT NAME field, enter a name for the export such as "DoiTBillingDataExport".
                  </Typography>,

                  <Typography key="type-of-data">
                    In type DATASET VERSION field, select "
                    <Typography fontWeight={500} display="inline">
                      2021-10-01
                    </Typography>
                    "
                  </Typography>,

                  <Typography key="frequency">
                    In type FREQUENCY field, select "
                    <Typography fontWeight={500} display="inline">
                      Daily export of month-to-date costs
                    </Typography>
                    "
                  </Typography>,
                ].map((item, index) => (
                  <ListItem key={index} sx={{ display: "list-item", mt: -1, pl: 0 }}>
                    {item}
                  </ListItem>
                ))}
              </List>
            </Typography>,

            <Typography key="2" mb={2}>
              Select ADD.
            </Typography>,

            <Typography key="4" mb={2}>
              Select NEXT, and:
              <List sx={{ listStyleType: "disc", pl: 4, pt: 2 }}>
                {[
                  <Typography key="storage-type">
                    In the STORAGE TYPE field, select "
                    <Typography fontWeight={500} display="inline">
                      Azure blob storage
                    </Typography>
                    "
                  </Typography>,

                  <Typography key="storage-destination">
                    In the DESTINATION AND STORAGE field, select "
                    <Typography fontWeight={500} display="inline">
                      Use existing
                    </Typography>
                    "
                  </Typography>,

                  "In the SUBSCRIPTION dropdown, select your subscription.",

                  <Typography key={2}>
                    In the STORAGE ACCOUNT dropdown, select "
                    <Typography fontWeight={500} display="inline">
                      {storageAccountName}
                    </Typography>
                    ", which is the account specified in the template you deployed.
                  </Typography>,

                  <Typography key={3}>
                    In the CONTAINER field, enter “
                    <Typography fontWeight={500} display="inline">
                      {containerName}
                    </Typography>
                    ”.
                  </Typography>,

                  <Typography key="4">
                    In the DIRECTORY field, enter “
                    <Typography fontWeight={500} display="inline">
                      {directoryName}
                    </Typography>
                    ".
                  </Typography>,

                  <Typography key="4">Keep the OVERWRITE DATA field selected.</Typography>,
                ].map((item, index) => (
                  <ListItem key={index} sx={{ display: "list-item", mt: -1, pl: 0 }}>
                    {item}
                  </ListItem>
                ))}
              </List>
            </Typography>,

            <Typography key="5" mb={2}>
              Select NEXT.
            </Typography>,

            <Typography key="6" mb={2}>
              Select CREATE.
            </Typography>,
          ]}
        />

        <Stack gap={2}>
          <Typography key="7" variant="body1" fontWeight={500}>
            After your export is saved, you need to run it to send the billing data to the DoiT Console:
          </Typography>

          <Box pl={2}>
            <NumberedList
              list={[
                <Typography key="0" mb={2}>
                  Select the export you just created, and select RUN NOW.
                </Typography>,
              ]}
            />
          </Box>
        </Stack>
      </Stack>

      <UsefulAzureLinks azureLink="https://learn.microsoft.com/en-us/azure/cost-management-billing/costs/tutorial-export-acm-data?tabs=azure-portal#create-a-daily-export" />
    </Stack>
  );
}
