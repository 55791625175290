import { useCallback, useState } from "react";

export function useAsyncLoadingFunction<FunctionArgsType extends Array<any>, FunctionReturnType extends Promise<any>>(
  functionToWrap: (...args: FunctionArgsType) => FunctionReturnType
): [boolean, typeof functionToWrap] {
  const [isLoading, setLoading] = useState(false);

  const wrappedFunction = useCallback<typeof functionToWrap>(
    (...args: FunctionArgsType) => {
      setLoading(true);
      return functionToWrap(...args).finally(() => setLoading(false)) as FunctionReturnType;
    },
    [functionToWrap]
  );

  return [isLoading, wrappedFunction];
}
