import { useCallback, useEffect, useRef, useState } from "react";

import pako from "pako";

import { useApiContext } from "../../api/context";
import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import { useAuthContext } from "../../Context/AuthContext";
import { type DatasetItem } from "../../Pages/DataHub/DataHubSources";
import { type BatchItem } from "../../Pages/DataHub/DatasetDetails/DatasetDetails";
import { type Customer } from "../../types";
import { consoleErrorWithSentry } from "../../utils";

export type RawEventsReq = {
  Dataset: string;
  Source: string;
  Schema: string[];
  RawEvents: string[][];
  Filename: string;
  Execute: boolean;
};

const DATASET_REFRESH_TIME = 1000 * 60 * 1;
const MAX_FILE_SIZE_MB = 32;
const BYTES_IN_MB = 1024 * 1024;

export const useDataHubData = (customer?: Customer | undefined | null) => {
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [datahubItems, setDatahubItems] = useState<DatasetItem[]>([]);
  const [fetchedDataHubItems, setFetchedDataHubItems] = useState(false);
  const [prevCustomer, setPrevCustomer] = useState<Customer | undefined | null>(null);
  const isFetchingDataHubItems = useRef(false);
  const isFetchingDatasetBatches = useRef(false);
  const [loadingDatasetBatches, setLoadingDatasetBatches] = useState(false);
  const [datasetBatches, setDatasetBatches] = useState<BatchItem[]>([]);
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>();

  const clearDataHubItems = () => {
    setLoading(true);
    setDatahubItems([]);
    setFetchedDataHubItems(false);
  };

  const clearDatasetFetchingTimeout = () => {
    timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
  };

  const updateDatasetProcessingField = (datasetName: string, processing: number) => {
    setDatahubItems((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((item) => item.dataset === datasetName);
      if (index !== -1) {
        temp[index].processing = processing;
      }
      return temp;
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isDoitEmployee && prevCustomer?.id !== customer?.id) {
      clearDataHubItems();
      setPrevCustomer(customer);
    }

    return () => {
      isMounted = false;
      clearDatasetFetchingTimeout();
      if (!isMounted) {
        isFetchingDataHubItems.current = false;
      }
    };
  }, [customer, isDoitEmployee, prevCustomer]);

  const fetchDataHubItems = async (forceRefresh: boolean) => {
    if (!customer?.id || isFetchingDataHubItems.current || (fetchedDataHubItems && !forceRefresh)) {
      return;
    }

    try {
      setLoading(true);
      isFetchingDataHubItems.current = true;
      const force = forceRefresh ? "?forceRefresh=true" : "";
      const response = await api.get(`/v1/customers/${customer.id}/datahub/events/datasets${force}`);
      if (response.data?.items) {
        setDatahubItems(response.data.items);
      } else {
        setDatahubItems([]);
      }
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setFetchedDataHubItems(true);
      if (forceRefresh) {
        setTimeout(() => {
          isFetchingDataHubItems.current = false;
        }, 10000);
      } else {
        isFetchingDataHubItems.current = false;
      }
      setLoading(false);
    }
  };

  const deleteDataHubDatasets = async (datasets: string[]) => {
    try {
      const response = await api.delete(`/v1/customers/${customer?.id}/datahub/events/datasets`, {
        data: { datasets },
      });
      await fetchDataHubItems(true);
      return response;
    } catch (error) {
      consoleErrorWithSentry(error);
      return error;
    }
  };

  const createNewDataset = async (Name: string, Description: string) => {
    try {
      await api.post(`/v1/customers/${customer?.id}/datahub/dataset`, {
        Name,
        Description,
      });
      await fetchDataHubItems(true);
    } catch (error: any) {
      consoleErrorWithSentry(error);
      throw error;
    }
  };

  const createRawEvents = async (payload: RawEventsReq) => {
    const jsonStr = JSON.stringify(payload);
    const encoder = new TextEncoder();
    const jsonBytes = encoder.encode(jsonStr);
    const compressedFile = pako.gzip(jsonBytes);

    if (compressedFile.length / BYTES_IN_MB > MAX_FILE_SIZE_MB) {
      return {
        error: datahubTxt.LARGE_COMPRESSED_FILE_ERROR,
      };
    }
    const data: any = await api.post(`/datahub/events/raw?customerID=${customer?.id}`, compressedFile, {
      headers: {
        "Content-Encoding": "gzip",
      },
    });

    updateDatasetProcessingField(payload.Dataset, data.data.eventsCount);
    return { error: null };
  };

  const fetchDatasetBatches = useCallback(
    async (datasetName: string, forceRefresh: boolean) => {
      if (!customer?.id || isFetchingDatasetBatches.current) {
        return;
      }

      try {
        isFetchingDatasetBatches.current = true;
        setLoadingDatasetBatches(true);
        const force = forceRefresh ? "?forceRefresh=true" : "";
        const response = await api.get(
          `/v1/customers/${customer.id}/datahub/events/datasets/${datasetName}/batches${force}`
        );
        if (response.data?.items) {
          const batchProcessingTime = new Date(Date.now() - 15 * 60 * 1000);
          const isProcessingData = response.data.items.some((item: any) => {
            const { createdAt, eventsSubmitted, eventsProcessed, eventsDeleted } = item;
            const batchCreateAtDate = new Date(createdAt);
            return (
              (eventsProcessed as number) + (eventsDeleted as number) !== eventsSubmitted &&
              batchProcessingTime < batchCreateAtDate
            );
          });
          if (isProcessingData) {
            clearDatasetFetchingTimeout();
            timeoutIdRef.current = setTimeout(() => {
              fetchDatasetBatches(datasetName, true);
            }, DATASET_REFRESH_TIME);
          } else if (forceRefresh) {
            updateDatasetProcessingField(datasetName, 0);
          }

          setDatasetBatches(response.data.items);
        } else {
          setDatasetBatches([]);
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      } finally {
        setLoadingDatasetBatches(false);
        if (forceRefresh) {
          setTimeout(() => {
            isFetchingDatasetBatches.current = false;
          }, 10000);
        } else {
          isFetchingDatasetBatches.current = false;
        }
      }
    },
    [api, customer?.id]
  );

  const deleteDatasetBatches = useCallback(
    async (datasetName: string, batches: string[]) => {
      if (!customer?.id) {
        return;
      }

      try {
        const response = await api.delete(
          `/v1/customers/${customer.id}/datahub/events/datasets/${datasetName}/batches`,
          {
            data: { batches },
          }
        );
        await fetchDatasetBatches(datasetName, true);
        return response;
      } catch (error) {
        consoleErrorWithSentry(error);
        return error;
      }
    },
    [api, customer?.id, fetchDatasetBatches]
  );

  return {
    datahubItems,
    loading,
    fetchDataHubItems,
    deleteDataHubDatasets,
    createNewDataset,
    createRawEvents,
    datasetBatches,
    loadingDatasetBatches,
    fetchDatasetBatches,
    deleteDatasetBatches,
  };
};
