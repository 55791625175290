import { useEffect, useState } from "react";

import { Switch } from "react-router-dom";
import { type InviteModel, type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { TraceRoute } from "../../../Components/Routes/TraceRoutes";
import { DashboardsContextProvider } from "../../../Context/DashboardContext";
import { type RoleWithIdRef, type User, type UserOrInvite } from "../../../types";
import { useIsRestrictedIamDueToSso } from "../../IAM/hooks";
import { UserTab } from "../types";
import { UserApiTab } from "./UserApiTab";
import { UserNotifications } from "./UserNotifications";
import { UserPermissionsTab } from "./UserPermissionsTab";
import { UserProfileTab, type UserProfileTabProps } from "./UserProfileTab";

export type UserViewTabsProps = {
  userRef: ModelReference<UserModel> | ModelReference<InviteModel> | undefined;
  baseUrl: string;
  userOrInvite: UserOrInvite;
  userRole: RoleWithIdRef | undefined;
  updateUser: (updateData: Partial<User>, onError?: () => void, onSuccess?: () => Promise<void>) => Promise<void>;
  canManageUsers: boolean | undefined;
  onImpersonateUser: UserProfileTabProps["onImpersonateUser"] | undefined;
};

export const UserViewTabs = ({
  userRef,
  baseUrl,
  userOrInvite,
  userRole,
  updateUser,
  canManageUsers,
  onImpersonateUser,
}: UserViewTabsProps) => {
  const { isRestrictedIamDueToSso } = useIsRestrictedIamDueToSso();

  const [permissions, setPermissions] = useState(new Set<string>());
  useEffect(() => userRole && setPermissions(new Set(userRole.permissions?.map(({ id }) => id))), [userRole]);

  return (
    <DashboardsContextProvider>
      <Switch>
        <TraceRoute exact path={`${baseUrl}/${UserTab.Profile}`}>
          <UserProfileTab userOrInvite={userOrInvite} updateUser={updateUser} onImpersonateUser={onImpersonateUser} />
        </TraceRoute>

        <TraceRoute exact path={`${baseUrl}/${UserTab.Permissions}`}>
          <UserPermissionsTab
            user={userOrInvite}
            userRole={userRole}
            updateUser={updateUser}
            readOnlyMode={!canManageUsers || isRestrictedIamDueToSso}
            showDisabledDueToSsoMsg={isRestrictedIamDueToSso}
          />
        </TraceRoute>

        <TraceRoute exact path={`${baseUrl}/${UserTab.Notifications}`}>
          <UserNotifications userRef={userRef} userOrInvite={userOrInvite} permissions={permissions} />
        </TraceRoute>

        <TraceRoute exact path={`${baseUrl}/${UserTab.Api}`}>
          <UserApiTab user={userOrInvite} />
        </TraceRoute>
      </Switch>
    </DashboardsContextProvider>
  );
};
