import { type ChangeEvent, Fragment, type JSX, useMemo } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { VENDOR_SCORE_PREFIX } from "./SurveyDialog";

export type TagVendor = "cmp" | "gcp" | "aws" | "azure" | "gsuite" | "ms365";

export const vendorNames: Record<TagVendor, string> = {
  cmp: "DoiT Console",
  gcp: "Google Cloud Platform",
  aws: "Amazon Web Services",
  azure: "Microsoft Azure",
  gsuite: "Google Workspace / G Suite",
  ms365: "Microsoft Office 365",
};

export const cyIds = {
  formControl: "vendor-score-form-control",
  good: "vendor-score-good",
  bad: "vendor-score-bad",
  question: "vendor-score-question",
};

export type VendorScoreProps = {
  vendorScore: "csat_vendor:good" | "csat_vendor:bad";
  handleVendorScoreChange: (event: ChangeEvent<HTMLInputElement>) => void;
  classNames: Record<string, string>;
  disabled: boolean;
  tags?: string[];
};

const VendorScore = ({ tags, vendorScore, handleVendorScoreChange, classNames, disabled }: VendorScoreProps) => {
  const platform = useMemo<TagVendor | null>(() => {
    const vendorPlatformMatch = /(cmp|gcp|aws|azure|gsuite|ms365)\/platform/;
    const vendorsFromPlatformTag = tags
      ? tags.filter((tag) => vendorPlatformMatch.test(tag)).map((tag) => RegExp(vendorPlatformMatch).exec(tag)?.[1])
      : [];

    const vendorBillingMatch = /finance\/service\/(cmp|gcp|aws|azure|gsuite|ms365)-billing/;
    const vendorsFromBillingTag = tags
      ? tags.filter((tag) => vendorBillingMatch.test(tag)).map((tag) => RegExp(vendorBillingMatch).exec(tag)?.[1])
      : [];

    if (vendorsFromPlatformTag.length) {
      return vendorsFromPlatformTag[0] as TagVendor;
    }

    if (tags?.includes("finance/platform") && vendorsFromBillingTag.length) {
      return vendorsFromBillingTag[0] as TagVendor;
    }

    return null;
  }, [tags]);

  const label = useMemo<JSX.Element>(() => {
    if (!platform) {
      return <></>;
    }

    const externalPlatformString = `Please rate your experience with ${vendorNames[platform]}`;
    const doitPlatformString = "Please rate how the DoiT Console helped with your request";

    return (
      <FormLabel component="legend" data-cy={cyIds.question}>
        {platform === "cmp" ? doitPlatformString : externalPlatformString}
      </FormLabel>
    );
  }, [platform]);

  return platform ? (
    <FormControl disabled={disabled} component="fieldset" sx={{ mb: "1rem" }} data-cy={cyIds.formControl}>
      {label}
      <RadioGroup
        aria-required
        aria-label="vendor score"
        className={classNames.radioGroup}
        value={vendorScore}
        onChange={handleVendorScoreChange}
      >
        <FormControlLabel value={`${VENDOR_SCORE_PREFIX}good`} control={<Radio />} label="Good" data-cy={cyIds.good} />
        <FormControlLabel value={`${VENDOR_SCORE_PREFIX}bad`} control={<Radio />} label="Bad" data-cy={cyIds.bad} />
      </RadioGroup>
    </FormControl>
  ) : (
    <></>
  );
};

export default VendorScore;
