import { type CurrencyCode, Metadata, type ReportFilter, type TimeInterval } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";

import { cloudAnalyticsText } from "../../../assets/texts";
import { type AnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { consoleErrorWithSentry } from "../../../utils";
import { type AttributionGroupsPayload } from "../attributionGroups/attributionGroupsPayload";
import { executeQueryRequest, type QueryRequest, type QueryResponseData, QueryType } from "../report/ReportQuery";
import { type DataRecord } from "../ReportData";
import { FixedFilters, getTimeSettings, type TimeRangeOption } from "../utilities";
import { type AttributionsPayload } from "./utils";

type Props = {
  abortController?: AbortController;
  api: AxiosInstance;
  attributionGroups: AttributionGroupsPayload[];
  attributions: AttributionsPayload[];
  currency: CurrencyCode;
  customerId: string;
  filters: ReportFilter[];
  metadataSnapshots: AnalyticsMetadata;
  reportId?: string;
  timeInterval: TimeInterval;
  forecastMode: boolean;
  timeRangeOptions: TimeRangeOption;
  nullFallback?: string;
  rows: DataRecord[];
  cols: DataRecord[];
};

export type previewResult = {
  data?: QueryResponseData;
  cols?: DataRecord[];
  rows?: DataRecord[];
  code?: string;
};

export const runReport = async ({
  abortController,
  api,
  attributionGroups,
  attributions,
  currency,
  customerId,
  filters,
  metadataSnapshots,
  reportId,
  timeInterval,
  forecastMode = false,
  timeRangeOptions,
  rows,
  cols,
}: Props): Promise<previewResult> => {
  const accounts = uniq(
    flatten(
      metadataSnapshots
        .filter((md) => md.id === `${Metadata.FIXED}:${FixedFilters.ACCOUNT}`)
        .map((md) => md.get("values") ?? [])
    )
  );

  const timeSettings = getTimeSettings(timeRangeOptions);

  if (!timeSettings) {
    return { code: cloudAnalyticsText.PREVIEW.ERR_INVALID_TIME_SETTINGS };
  }
  timeSettings.interval = timeInterval;

  // const cols = getCols(metadataSnapshots, timeInterval);

  const request: QueryRequest = {
    accounts,
    attributionGroups,
    attributions,
    calculatedMetric: null,
    cloudProviders: null,
    cols,
    comparative: null,
    count: null,
    currency,
    excludePartialData: false,
    extendedMetric: "",
    filters,
    forecast: forecastMode,
    id: reportId ?? "preview",
    includeCredits: false,
    metric: 0,
    metricFilters: [],
    noAggregate: false,
    rows,
    timeSettings,
    trends: [],
    type: QueryType.ATTRIBUTION_GROUP,
    signal: abortController?.signal,
  };

  try {
    const response = await executeQueryRequest(api, customerId, request);
    if (response.status === 200) {
      return { data: response.data, cols, rows };
    } else {
      return { code: cloudAnalyticsText.PREVIEW.ERR_UKNOWN };
    }
  } catch (error: any) {
    if (error.name === "CanceledError") {
      return { code: cloudAnalyticsText.PREVIEW.ERR_CANCELED };
    }
    consoleErrorWithSentry(error);
    return (
      error ?? {
        code: cloudAnalyticsText.PREVIEW.ERR_UKNOWN,
      }
    );
  }
};
