import { useEffect, useState } from "react";

import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { useAuthContext } from "../../../Context/AuthContext";
import { usePriorityErpStatus } from "../../../Context/usePriorityErpStatus";
import { useUserContext } from "../../../Context/UserContext";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

type Props = {
  hide: boolean;
};

export const ScheduledPriorityMaintenanceAlert = ({ hide }: Props) => {
  const classes = useStyles();
  const { userRoles } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const [open, setOpen] = useState<boolean>(false);
  const priorityErpStatus = usePriorityErpStatus();
  const [scheduledDownTime, setScheduledDownTime] = useState<DateTime | null>(null);

  useEffect(() => {
    if (hide) {
      return;
    }

    const displayToUser = isDoitEmployee || userRoles?.billingProfilesAdmin || userRoles?.invoicesViewer;

    // Alert a week in advance
    if (priorityErpStatus.scheduledDownTime && displayToUser) {
      const dt = DateTime.fromJSDate(priorityErpStatus.scheduledDownTime.toDate());
      setScheduledDownTime(dt);
      const now = DateTime.now();
      const sameDay = dt.hasSame(now, "day");
      const differenceInDays = dt.diff(now, "days").days;
      if (sameDay || (differenceInDays >= 0 && differenceInDays <= 7)) {
        setOpen(true);
      }
    }
  }, [
    priorityErpStatus.scheduledDownTime,
    hide,
    setOpen,
    setScheduledDownTime,
    isDoitEmployee,
    userRoles?.billingProfilesAdmin,
    userRoles?.invoicesViewer,
  ]);
  if (!open || !scheduledDownTime) {
    return null;
  }

  return (
    <Alert
      classes={{
        root: classes.root,
      }}
      onClose={() => {
        setOpen(false);
      }}
      severity="info"
      sx={{ display: "flex" }}
    >
      Scheduled maintenance on {scheduledDownTime.toFormat("MMM d")} will affect billing profiles, delay invoice
      syncing, and pause payment features
    </Alert>
  );
};
