import { useCallback, useState } from "react";

import { Positions } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { appcuesText, reportText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import { DraggableGroup } from "../../../../Components/DragAndDrop/DraggableGroup";
import { type KeyTypeValues } from "../../api";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { FilterPopover } from "../../dialogs/metadataFilter/FilterContent";
import { type RenderDraggableContent, type ReportAddDimension } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ReportsAccordion from "./ReportsAccordion";

export type FilterResultsProps = {
  popoverAnchorEl: any;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  handleAddDimension: ReportAddDimension;
  numOfItems: number;
  renderDraggableContent: RenderDraggableContent;
};

const position = Positions.UNUSED;

const FilterResults = ({
  handleAddDimension,
  handleChangeLabels,
  popoverAnchorEl,
  setPopoverAnchorEl,
  numOfItems,
  renderDraggableContent,
}: FilterResultsProps) => {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const { reportConfig } = useReportConfig();
  const { dimensions = [] } = useReportDimensionsContext();

  const onExpand = useCallback((state: boolean) => {
    setAccordionExpanded(state);
  }, []);

  const popoverComponent = (
    <FilterPopover
      anchorEl={popoverAnchorEl?.mdUnused}
      onClose={() => setPopoverAnchorEl(null)}
      metadata={dimensions}
      excludeSelectMetadataIds={reportConfig?.excludeSelectMetadataIds}
      position={position}
      handleAddDimension={handleAddDimension}
      handleChangeLabels={handleChangeLabels}
      isOptionDisabled={undefined}
    />
  );

  return (
    <ReportsAccordion
      defaultExpanded
      title={reportText.FILTERֹֹ_RESULTS_SECTION_LABEL}
      numOfItems={numOfItems}
      appCues={appcuesText.FILTER_RESULTS}
      popoverComponent={popoverComponent}
      popoverAnchorEl={popoverAnchorEl?.mdUnused}
      setPopoverAnchorEl={setPopoverAnchorEl}
      position={position}
      onExpand={onExpand}
      addIconTooltip={reportTxt.ADD_FILTER_TOOLTIP}
    >
      <Box data-cy="filter-results">
        <DraggableGroup
          id={position}
          renderContent={renderDraggableContent(position, false, !accordionExpanded)}
          isHidden={!accordionExpanded}
        />
      </Box>
    </ReportsAccordion>
  );
};

export default FilterResults;
