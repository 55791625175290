import { useCallback, useEffect, useMemo } from "react";

import { Chip, darken, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import difference from "lodash/difference";
import sum from "lodash/sum";
import values from "lodash/values";

import { AlgoliaListSubHeaders } from "./consts";
import { type AlgoliaFilters, type AlgoliaIndexType } from "./types";

type Props = {
  collectionCount: Record<AlgoliaIndexType, number>;
  filters: AlgoliaFilters;
  setFilters: (filter: AlgoliaFilters) => void;
  restrictedIndices?: AlgoliaIndexType[];
  isSearchWithinCustomer: boolean;
};

const AlgoliaCustomChip = styled(Chip)(({ theme }) => ({
  "&:hover": {
    background: darken(theme.palette.primary.main, 0.3),
  },
}));

export const AlgoliaFilterChips = ({
  setFilters,
  filters,
  restrictedIndices = [],
  collectionCount,
  isSearchWithinCustomer,
}: Props) => {
  const allowedHeaders = useMemo(() => {
    const allAllowedIndices = difference(Object.keys(AlgoliaListSubHeaders), restrictedIndices);
    if (isSearchWithinCustomer && allAllowedIndices.includes("customers")) {
      allAllowedIndices.splice(allAllowedIndices.indexOf("customers"), 1);
    }
    return allAllowedIndices;
  }, [restrictedIndices, isSearchWithinCustomer]);

  const toggleFilterChip = useCallback(
    (key: string) => {
      const newFilters = {
        ...filters,
        [key]: !filters[key],
      };
      const isAnyFilterActive = allowedHeaders.find((key) => newFilters[key]);
      newFilters.allResults = !isAnyFilterActive;
      setFilters(newFilters);
    },
    [filters, setFilters, allowedHeaders]
  );

  const toggleAllFilterChips = useCallback(() => {
    setFilters({ allResults: true });
  }, [setFilters]);

  useEffect(() => {
    if (isSearchWithinCustomer && filters.customers) {
      toggleFilterChip("customers");
    }
  }, [filters.customers, isSearchWithinCustomer, toggleFilterChip]);

  return (
    <Stack direction="row" gap={1} sx={{ flexWrap: "wrap", mt: 1 }} data-testid="filter-chips">
      <AlgoliaCustomChip
        clickable={true}
        data-testid="filter-chip-all"
        onMouseDown={(e) => e.preventDefault()}
        onClick={toggleAllFilterChips}
        variant={filters.allResults ? "filled" : "outlined"}
        color="primary"
        size="small"
        label={`All results (${sum(values(collectionCount))})`}
      />
      {allowedHeaders.map((key) => (
        <AlgoliaCustomChip
          key={key}
          data-testid={`filter-chip-${key}`}
          clickable
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => toggleFilterChip(key)}
          variant={filters[key] ? "filled" : "outlined"}
          color="primary"
          size="small"
          label={`${AlgoliaListSubHeaders[key]} (${collectionCount[key]})`}
        />
      ))}
    </Stack>
  );
};
