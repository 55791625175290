import { Link } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";

export const ThreadsOverviewTableEmpty = () => {
  const { customer } = useCustomerContext();

  return (
    <Stack alignItems="center" my="70px" spacing={2}>
      <Typography variant="h1" color="inherit">
        Create your first thread
      </Typography>
      <Typography variant="body1" color="inherit">
        Use DoiT Threads to manage your team’s work on Insights.
      </Typography>
      <Box>
        <Button variant="contained" component={Link} to={`/customers/${customer.id}/insights`} sx={{ mt: 2 }}>
          Go to Insights
        </Button>
      </Box>
    </Stack>
  );
};
