import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType } from "@doitintl/cmp-models";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import uniq from "lodash/uniq";

import { useApiContext } from "../../../api/context";
import { globalText, metricText } from "../../../assets/texts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import Hide from "../../../Components/HideChildren/Hide";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useAuthContext } from "../../../Context/AuthContext";
import { useTier } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { type MetricWSnap, type ReportWSnap } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { addAnalyticsWindowFunction } from "../../../utils/windowInit";
import { metricsToAnalyticsResources } from "../analyticsResources/utils";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import { useLabels } from "../labels/hooks";
import { deleteMetrics } from "./api";
import { useCreateMetricHandler } from "./hooks";
import { filterColumns, headers } from "./MetricBrowserColumns";
import { MetricRow } from "./MetricBrowserRow";

const { metrics: metricIds } = cloudAnalytics;

type MetricBrowserProps = {
  metrics: MetricWSnap[];
  reports: ReportWSnap[];
};

export const MetricBrowser = ({ metrics, reports }: MetricBrowserProps) => {
  const { isFeatureEntitled } = useTier();

  const routeMatchURL = useRouteMatchURL();
  const snackbar = useSnackbar();
  const { userRoles } = useUserContext({ allowNull: false });
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const [selected, setSelected] = useState<MetricWSnap[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const customerId = useCustomerId();
  const api = useApiContext();
  const [labels, labelsLoading] = useLabels();

  useEffect(() => {
    mixpanel.track("analytics.metrics.list");
  }, []);

  useEffect(() => {
    const updatedSelectedMetrics = metrics.filter((metric) =>
      selected.some((x) => x.snapshot.id === metric.snapshot.id)
    );

    setSelected(updatedSelectedMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics, reports]);

  const handleDeleteMetrics = useCallback(async () => {
    try {
      await deleteMetrics({ metricIds: selected.map((x) => x.snapshot.id), customerId, api });
      mixpanel.track("analytics.metrics.delete", { metricIds: selected.map((m) => m.snapshot.id).join(",") });
      snackbar.onOpen({
        message: metricText.DELETE_METRIC_SUCCESS,
        variant: "success",
        autoHideDuration: 5000,
      });
      setSelected([]);
    } catch (e) {
      snackbar.onOpen({
        message: metricText.DELETE_METRIC_ERROR,
        variant: "error",
        autoHideDuration: 5000,
      });
      consoleErrorWithSentry(e);
    }
  }, [api, customerId, selected, snackbar]);

  const selectedUsedInReports = useMemo(() => {
    const result: { metrics: string[]; reports: string[] } = { metrics: [], reports: [] };
    if (selected?.length > 0) {
      reports.forEach((report) => {
        if (report.data.type === AnalyticsResourceType.CUSTOM && report.data.config?.calculatedMetric) {
          const usedMetric = selected.find((m) => m.snapshot.id === report.data.config?.calculatedMetric?.id);
          if (usedMetric) {
            result.metrics.push(`"${usedMetric.data.name}"`);
            result.reports.push(`"${report.data.name}"`);
          }
        }
      });
    }
    result.metrics = uniq(result.metrics);
    return result;
  }, [reports, selected]);

  const handleNewMetric = useCreateMetricHandler({
    mixpanelEventName: "analytics.metrics.new",
    baseUrl: routeMatchURL,
  });

  const canEditSelectedMetrics = useMemo<boolean>(
    () => selected.every((s) => s.data.owner === currentUser.email) && selected.length > 0,
    [currentUser.email, selected]
  );

  const filterActions = useMemo(() => {
    const selectedContainsPreset = () =>
      selected?.find((item) => item.data.type === AnalyticsResourceType.PRESET) !== undefined;
    const getDeleteTooltip = () => {
      if (selectedContainsPreset()) {
        return metricText.DISABLED_DELETE_PRESET_TOOLTIP;
      }
      if (selectedUsedInReports.metrics.length > 0) {
        let who = metricText.METRIC;
        let what = metricText.IT_IS;
        let byWhom = metricText.REPORT;
        if (selectedUsedInReports.metrics.length > 1) {
          who += metricText.PLURAL;
          what = metricText.THEY_ARE;
        }
        if (selectedUsedInReports.reports.length > 1) {
          byWhom += metricText.PLURAL;
        }
        return metricText.DISABLED_DELETE_USED_TOOLTIP(
          who,
          selectedUsedInReports.metrics.join(", "),
          what,
          selectedUsedInReports.reports.join(", "),
          byWhom
        );
      }
      return "";
    };

    addAnalyticsWindowFunction({ handleNewMetric });

    return (
      <>
        <Grid item>
          <Tooltip title={getDeleteTooltip()}>
            <div>
              <Button
                variant="contained"
                color="error"
                disabled={selectedContainsPreset() || selectedUsedInReports.metrics.length > 0 || !selected.length}
                onClick={() => setOpenDialog(true)}
                data-cy={metricIds.browser.delete}
              >
                {globalText.DELETE}
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <AssignLabelsButton
            labels={labels ?? []}
            selectedResources={metricsToAnalyticsResources(selected)}
            disabled={!canEditSelectedMetrics}
          />
        </Grid>
        <Grid item>
          <Hide mdDown>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewMetric}
              data-cy={metricIds.browser.newMetric}
              disabled={!isFeatureEntitled("analytics:calculatedMetrics")}
            >
              {metricText.CREATE_NEW_METRIC}
            </Button>
          </Hide>
        </Grid>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNewMetric, selectedUsedInReports.metrics, selectedUsedInReports.reports, selected, labels, userRoles]);

  const RowWrapper = useCallback(({ row }: { row: MetricWSnap }) => <MetricRow row={row} labels={labels} />, [labels]);

  const onFilterApplied = () => {
    mixpanel.track("analytics.metrics.filter");
  };

  if (labelsLoading) {
    return (
      <Box p={1}>
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      <FilterTable<MetricWSnap>
        tableItems={metrics}
        rowComponent={RowWrapper}
        showRowsSelection={true}
        onRowsSelected={setSelected}
        headerColumns={headers}
        filterColumns={filterColumns}
        filterBarPlaceholder={metricText.FILTER_METRICS}
        onFilterApplied={onFilterApplied}
        persistenceKey="cloud_analytics_metrics_v1"
        itemUniqIdentifierField="snapshot.id"
        defaultSortingColumnValue="data.timeModified"
        toolbarProps={{
          title: "Metrics",
        }}
      >
        {filterActions}
      </FilterTable>
      {openDialog && (
        <DeleteDialog
          open={openDialog}
          title={metricText.DELETE_SELECTED_METRICS}
          message={metricText.DELETE_SELECTED_BODY}
          onDelete={handleDeleteMetrics}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  );
};
