import { useCallback, useMemo } from "react";

import { Metric } from "@doitintl/cmp-models";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import get from "lodash/get";

import { templateLibraryTxt } from "../../../assets/texts/CloudAnalytics";
import { getMetricLabel } from "../utilities";
import { type SelectedFilters } from "./ReportTemplatesBrowser";
import { type ReportTemplateCard } from "./types";
import { getCloudLabel, getCloudValue, TemplatesCategories, TemplatesCloud } from "./utils";

type SideFilterItem = {
  label: string;
  count: number;
};

type SideFilter = {
  items: SideFilterItem[];
  sectionPath: string;
  title: string;
  toOptionKey?: (value) => string;
};

type SectionItem = {
  label: string;
  path: string;
  options: string[];
  toOptionKey?: (value) => string;
};

const sideFilterSections: SectionItem[] = [
  {
    label: templateLibraryTxt.CATEGORY,
    path: "visibleVersion.categories",
    options: TemplatesCategories,
  },
  {
    label: templateLibraryTxt.METRIC,
    path: "visibleVersion.config.metric",
    options: ["Cost", "Savings", "Usage"],
    toOptionKey: (label: string) => Metric[label.toUpperCase()],
  },
  {
    label: templateLibraryTxt.CLOUD,
    path: "visibleVersion.cloud",
    options: [TemplatesCloud.AWS, TemplatesCloud.AZURE, TemplatesCloud.GOOGLE_CLOUD],
    toOptionKey: (label: string) => getCloudValue(label),
  },
];

type Props = {
  reportTemplateCards: ReportTemplateCard[];
  setSelectedFilters: (value) => void;
  selectedFilters: SelectedFilters;
};

const ReportTemplatesSideFilters = ({ reportTemplateCards, setSelectedFilters, selectedFilters }: Props) => {
  const counted: Record<string, number>[] = useMemo(
    () =>
      sideFilterSections.map((section) =>
        reportTemplateCards.reduce((res, template) => {
          const value = get(template, section.path);

          if (Array.isArray(value)) {
            value.forEach((item) => {
              res[item] = res[item] || 0;
              res[item]++;
            });
          } else {
            res[value] = res[value] || 0;
            res[value]++;
          }

          return res;
        }, {})
      ),
    [reportTemplateCards]
  );

  const constructItems = useCallback(
    (section: SectionItem, index: number) =>
      section.options.map((label) => {
        const optionKey = section.toOptionKey?.(label) ?? label;
        return {
          label,
          count: counted[index]?.[optionKey] ?? 0,
        };
      }),
    [counted]
  );

  const sideFilters: SideFilter[] = useMemo(
    () =>
      sideFilterSections.map((section, index) => ({
        sectionPath: section.path,
        items: constructItems(section, index),
        title: section.label,
        toOptionKey: section.toOptionKey,
      })),
    [constructItems]
  );

  const handleFilterClick = useCallback(
    (sectionPath: string, optionLabel: string, toOptionKey?: (value) => string) => {
      const optionKey = toOptionKey ? toOptionKey(optionLabel) : optionLabel;

      setSelectedFilters((prevFilters) => {
        const newFilters = { ...prevFilters };

        if (newFilters[sectionPath]) {
          const labelIndex = newFilters[sectionPath].indexOf(optionKey);

          if (labelIndex > -1) {
            newFilters[sectionPath].splice(labelIndex, 1);
          } else {
            newFilters[sectionPath].push(optionKey);
          }
        } else {
          newFilters[sectionPath] = [optionKey];
        }
        return newFilters;
      });
    },
    [setSelectedFilters]
  );

  const currentLabel = (path: string, option: string | number) => {
    if (path === "visibleVersion.config.metric" && typeof option === "number") {
      return getMetricLabel(option, "", "");
    } else if (typeof option === "string") {
      if (path === "visibleVersion.cloud") {
        return getCloudLabel(option);
      }
      return option;
    }
  };

  return (
    <Stack data-cy="report-templates-filters" spacing={3}>
      {sideFilters.map((filter) => (
        <FormControl key={filter.title}>
          <Typography variant="subtitle2">{filter.title}</Typography>
          <FormGroup>
            {filter.items.map((item) => (
              <FormControlLabel
                key={item.label}
                control={
                  <Checkbox
                    checked={
                      !!selectedFilters[filter.sectionPath]?.some(
                        (sf) => currentLabel(filter.sectionPath, sf) === item.label
                      )
                    }
                    onChange={() => handleFilterClick(filter.sectionPath, item.label, filter.toOptionKey)}
                  />
                }
                label={
                  <Typography variant="body2">
                    {item.label}&nbsp;{`(${item.count})`}
                  </Typography>
                }
                sx={{ pl: 1 }}
              />
            ))}
          </FormGroup>
        </FormControl>
      ))}
    </Stack>
  );
};

export default ReportTemplatesSideFilters;
