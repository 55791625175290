import { type ChangeEvent, type Dispatch, type SetStateAction, useCallback, useEffect, useState } from "react";

import { AnalyticsDataSource, type CurrencyCode, type CurrencyCodes, Metric, TimeInterval } from "@doitintl/cmp-models";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { attributionText, budgetText, globalText } from "../../../assets/texts";
import DataHubCheckbox from "../../../Components/DataHubCheckbox/DataHubCheckbox";
import { AttributionSelectMulti } from "../../../Components/Selects/CloudAnalytics/AttributionSelectMulti";
import { type AttributionWRef, type DraftBudget } from "../../../types";
import {
  formatDecimalNumber,
  getCurrencySymbol,
  onKeyPressPreventNonNumeric,
  sanitizeDate,
} from "../../../utils/common";
import { dateFormatFullDateWithDay } from "../../../utils/dateTimeFormats";
import AttributionCreateDialog from "../attributions/attributionBuilder/AttributionCreateDialog";
import { BudgetTypes, BudgetTypesOptions, CurrencyOptions, MetricOptions, timeIntervalOptions } from "../utilities";
import { textFieldBaseProps, textFieldSelectProps } from "./shared";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type Props = {
  allowGrowth: boolean;
  amount: number;
  attributions: AttributionWRef[];
  budget: DraftBudget;
  classes;
  currency: CurrencyCode;
  customerId: string;
  dataSource: AnalyticsDataSource;
  endPeriod: DateTime;
  growthPerPeriod: number;
  hasDataHub: boolean;
  isCurrentUserEditor: boolean;
  lastPeriodCost: number;
  loading: boolean;
  metric: number;
  scope: AttributionWRef[];
  setAllowGrowth: Dispatch<SetStateAction<boolean>>;
  setAmount: Dispatch<SetStateAction<number>>;
  setCurrency: Dispatch<SetStateAction<CurrencyCode>>;
  setDataSource: (dataSource: AnalyticsDataSource) => void;
  setEndPeriod: Dispatch<SetStateAction<any>>;
  setGrowthPerPeriod: Dispatch<SetStateAction<number>>;
  setMetric: Dispatch<SetStateAction<number>>;
  setNewName: Dispatch<SetStateAction<string>>;
  setScope: Dispatch<SetStateAction<AttributionWRef[]>>;
  setStartPeriod: Dispatch<SetStateAction<DateTime>>;
  setTimeInterval: Dispatch<SetStateAction<TimeInterval>>;
  setType: Dispatch<SetStateAction<BudgetTypes>>;
  setUsePrevSpend: Dispatch<SetStateAction<boolean>>;
  startPeriod: DateTime;
  timeInterval: TimeInterval;
  type: number;
  usePrevSpend: boolean;
};

const BudgetConfiguration = ({
  allowGrowth,
  amount,
  attributions,
  budget,
  classes,
  dataSource,
  currency,
  customerId,
  endPeriod,
  growthPerPeriod,
  hasDataHub,
  isCurrentUserEditor,
  lastPeriodCost,
  loading,
  metric,
  scope,
  setAllowGrowth,
  setAmount,
  setDataSource,
  setCurrency,
  setEndPeriod,
  setGrowthPerPeriod,
  setMetric,
  setScope,
  setStartPeriod,
  setTimeInterval,
  setType,
  setUsePrevSpend,
  startPeriod,
  timeInterval,
  type,
  usePrevSpend,
}: Props) => {
  const [newAttributionDialogOpen, setNewAttributionDialogOpen] = useState(false);
  const [newAttributionId, setNewAttributionId] = useState("");

  useEffect(() => {
    if (newAttributionId) {
      const attribution = attributions.find((a) => a.ref.id === newAttributionId);
      if (attribution) {
        setScope([...scope, attribution]);
      }
      setNewAttributionId("");
    }
  }, [attributions, newAttributionId, scope, setScope]);

  const handleChangeType = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setType(event.target.value as unknown as BudgetTypes);
      if (parseInt(event.target.value) === BudgetTypes.RECURRING) {
        setStartPeriod((prevState) => prevState.startOf(timeInterval));
        setTimeInterval(TimeInterval.MONTH);
      }
    },
    [setType, setTimeInterval, timeInterval, setStartPeriod]
  );

  const getStartPeriodDatePickerProps = useCallback(() => {
    const shouldDisableDate = (day) => {
      if (type === BudgetTypes.RECURRING) {
        switch (timeInterval) {
          case TimeInterval.WEEK:
            return day.weekday !== 1;
          case TimeInterval.MONTH:
            return day.day !== 1;
          case TimeInterval.QUARTER:
            return day.day !== 1 || (day.month !== 1 && day.month !== 4 && day.month !== 7 && day.month !== 10);
          case TimeInterval.YEAR:
            return day.day !== 1 || day.month !== 1;
          default:
            return false;
        }
      }
      return false;
    };
    return {
      shouldDisableDate,
    };
  }, [timeInterval, type]);

  const handleChangePeriod = useCallback(
    (event) => {
      setStartPeriod((prevStartPeriod: DateTime) => {
        if (event.target.value === TimeInterval.WEEK) {
          const newDate = prevStartPeriod.startOf(event.target.value);
          if (newDate.month !== prevStartPeriod.month) {
            return newDate.plus({ weeks: 1 });
          }
        }
        return prevStartPeriod.startOf(event.target.value);
      });
      setTimeInterval(event.target.value);
    },
    [setTimeInterval, setStartPeriod]
  );

  const handleChangeStartPeriod = useCallback(
    (value: DateTime) => {
      if (type === BudgetTypes.RECURRING) {
        setStartPeriod(sanitizeDate(value).startOf(timeInterval));
      } else {
        setStartPeriod(sanitizeDate(value));
        const newStartPeriod = sanitizeDate(value);
        if (newStartPeriod > endPeriod) {
          setEndPeriod(newStartPeriod.plus({ days: 1 }));
        }
      }
    },
    [timeInterval, type, setStartPeriod, endPeriod, setEndPeriod]
  );

  const handleChangeAmount = useCallback(
    (event) => {
      const newAmount = parseFloat(event.target.value);
      if (isNaN(newAmount)) {
        setAmount(0);
      } else {
        setAmount(newAmount);
      }
    },
    [setAmount]
  );

  return (
    <Card>
      <CardHeader title="Budget Configuration" titleTypographyProps={{ variant: "subtitle2" }} />
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <AttributionSelectMulti
              attributions={attributions}
              onChange={(newScope) => setScope(newScope)}
              scope={scope}
              sx={{ width: "100%" }}
              disabled={!isCurrentUserEditor}
              textFieldProps={{
                required: false,
                label: budgetText.BUDGET_SCOPE,
                placeholder: budgetText.SELECT_ATTRIBUTION,
                // InputLabelProps - needed to override the default shrink behavior of the label
                InputLabelProps: {},
              }}
            />
            <Stack
              direction="row"
              mt={1}
              mb={1}
              divider={<Divider orientation="vertical" sx={{ height: "auto", mr: 2 }} />}
            >
              <DataHubCheckbox
                checked={dataSource === AnalyticsDataSource.BILLING_DATAHUB}
                customerId={customerId}
                dataSource={dataSource}
                disabled={!isCurrentUserEditor || !hasDataHub}
                onChange={(_, checked) =>
                  setDataSource(checked ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING)
                }
                showClouds={false}
              />
              <AttributionCreateDialog
                onCancel={() => setNewAttributionDialogOpen(false)}
                onSuccess={setNewAttributionId}
                open={newAttributionDialogOpen}
              >
                <Link
                  color="inherit"
                  variant="caption"
                  underline="always"
                  href="#"
                  onClick={() => setNewAttributionDialogOpen(true)}
                >
                  {attributionText.CREATE_NEW_ATTRIBUTION}
                </Link>
              </AttributionCreateDialog>
            </Stack>
          </Grid>
          <Grid item xs={type === BudgetTypes.RECURRING ? 6 : 12} sm={6}>
            <TextField
              label="Type"
              value={type}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={handleChangeType}
              disabled={!isCurrentUserEditor}
              data-testid="budgetType"
            >
              {BudgetTypesOptions.map((budgetType) => (
                <MenuItem key={budgetType.value} value={budgetType.value} dense>
                  {budgetType.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {type === BudgetTypes.RECURRING && (
            <Grid item sm={6} xs={6}>
              <TextField
                label={budgetText.BUDGET_TIME_INTERVAL}
                value={timeInterval}
                {...textFieldBaseProps}
                {...textFieldSelectProps}
                onChange={handleChangePeriod}
                disabled={!isCurrentUserEditor}
              >
                {timeIntervalOptions.slice(1).map((interval) => (
                  <MenuItem key={interval.value} value={interval.value} dense>
                    {interval.alternateLabel}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              label={budgetText.BUDGET_METRIC}
              value={metric}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(event) => {
                setMetric(event.target.value as unknown as number);
              }}
              disabled={true}
            >
              {MetricOptions.slice(0, 1).map((metricOption) => (
                <MenuItem key={metricOption.value} value={metricOption.value} dense>
                  {metricOption.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {type === BudgetTypes.RECURRING && <Grid item sm={6} xs={12} />}
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Start Date"
              renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
              value={startPeriod}
              onChange={handleChangeStartPeriod as any}
              components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
              inputFormat={dateFormatFullDateWithDay}
              disableMaskedInput
              componentsProps={{
                actionBar: {
                  actions: ["today"],
                },
              }}
              disabled={!isCurrentUserEditor}
              {...getStartPeriodDatePickerProps()}
            />
          </Grid>
          {type === BudgetTypes.RECURRING && <Grid item sm={6} xs={12} />}
          {type === BudgetTypes.FIXED && (
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                renderInput={(params) => <TextField fullWidth margin="dense" data-testid="endPeriod" {...params} />}
                value={endPeriod}
                disableMaskedInput
                onChange={(value) => {
                  if (value) {
                    setEndPeriod(sanitizeDate(value));
                  }
                }}
                components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
                inputFormat={dateFormatFullDateWithDay}
                componentsProps={{
                  actionBar: {
                    actions: ["today"],
                  },
                }}
                shouldDisableDate={(day) => !!day && day <= startPeriod}
                disabled={!isCurrentUserEditor}
              />
            </Grid>
          )}

          {metric === Metric.COST && (
            <Grid item xs={6} sm={3}>
              <TextField
                label="Currency"
                value={currency}
                disabled={!isCurrentUserEditor}
                onChange={(event) => {
                  setCurrency(event.target.value as CurrencyCodes);
                }}
                {...textFieldBaseProps}
                {...textFieldSelectProps}
              >
                {CurrencyOptions.map((currencyOption) => (
                  <MenuItem key={currencyOption} value={currencyOption} dense>
                    {currencyOption}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={6} sm={metric !== Metric.COST ? 6 : 3}>
            <TextField
              label={budgetText.BUDGET_AMOUNT}
              type="number"
              {...textFieldBaseProps}
              value={Math.round(amount) || ""}
              onChange={handleChangeAmount}
              onKeyUp={onKeyPressPreventNonNumeric}
              disabled={!isCurrentUserEditor || usePrevSpend}
              InputProps={{
                startAdornment:
                  metric === Metric.COST ? (
                    <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                  ) : null,
              }}
              inputProps={{ min: 0 }}
              data-testid="budgetAmount"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: "4px" }}>
            <Alert
              severity="info"
              className={classes.fitAlertPadding}
              icon={loading ? <CircularProgress size={20} thickness={5} color="inherit" /> : undefined}
            >
              {loading
                ? globalText.LOADING
                : `${budgetText.LAST_PERIOD_COST} ${
                    lastPeriodCost
                      ? `${metric === Metric.COST ? getCurrencySymbol(currency) : null}${(
                          Math.round(lastPeriodCost * 100) / 100
                        ).toLocaleString()}`
                      : globalText.NA
                  }`}
            </Alert>
          </Grid>

          {type === BudgetTypes.RECURRING && (
            <>
              <Grid item xs={12}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={usePrevSpend}
                  disabled={!isCurrentUserEditor}
                  onChange={(event) => {
                    setUsePrevSpend(event.target.checked);
                  }}
                  data-testid="usePrevSpend"
                />
                {budgetText.USE_LAST_SPEND_LABEL}
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={allowGrowth}
                  disabled={!isCurrentUserEditor}
                  onChange={(event) => {
                    setAllowGrowth(event.target.checked);
                  }}
                  data-testid="allowGrowth"
                />
                {budgetText.ALLOW_GROWTH_LABEL}
              </Grid>
            </>
          )}
          {type === BudgetTypes.RECURRING && allowGrowth && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={budgetText.GROWTH_PER_BUDGET_PERIOD}
                  type="number"
                  onKeyUp={onKeyPressPreventNonNumeric}
                  value={growthPerPeriod.toString()}
                  onChange={(event) => {
                    const val = parseInt(event.target.value);
                    const valNormal = isNaN(val) ? 0 : val;
                    setGrowthPerPeriod(valNormal);
                  }}
                  {...textFieldBaseProps}
                  disabled={!allowGrowth || !amount || !isCurrentUserEditor}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  inputProps={{ min: 0 }}
                  data-testid="growthPerPeriod"
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "4px" }}>
                <Alert
                  severity="info"
                  className={classes.fitAlertPadding}
                  icon={loading ? <CircularProgress size={20} thickness={5} color="inherit" /> : undefined}
                  data-testid="originalAmount"
                >
                  Original budget amount:
                  {metric === Metric.COST ? ` ${getCurrencySymbol(currency)}` : null}
                  {formatDecimalNumber(budget.data.config.originalAmount, 2, 0)}
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BudgetConfiguration;
