import { type MutableRefObject, useEffect, useMemo, useRef } from "react";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { getColors } from "../utilities";
import useTreemapChartRenderer from "./useTreeMapChartRenderer";
import type ReportData from "../ReportData";

const useStyles = makeStyles({
  container: {
    height: ({ height }: { height: string }) => height,
  },
});

type TreemapRendererTypes = {
  data: ReportData | null;
  height: string;
  formatter;
  isWidget: boolean;
  widgetsList: Array<any>;
  forceRender: boolean;
  isExact?: boolean;
  highchartRef?: MutableRefObject<any | null>;
};

const TreemapRenderer = ({
  data,
  height,
  formatter,
  isWidget,
  isExact,
  widgetsList,
  forceRender,
  highchartRef,
}: TreemapRendererTypes) => {
  const theme = useTheme();
  const classes: Record<"container", string> = useStyles({ height });
  const localChartRef = useRef<any | null>(null);
  const colors = getColors(theme.palette.mode);

  const options: any = useTreemapChartRenderer(data, colors, theme, formatter, isWidget, isExact) as Highcharts.Options;

  const ref = highchartRef ?? localChartRef;

  useEffect(() => {
    if (ref.current?.chart) {
      ref.current.chart.reflow();
    }
  }, [height, widgetsList, ref, theme.palette.mode]);

  const allowRender = useMemo(() => !(isWidget && !forceRender), [forceRender, isWidget]);

  return (
    <div className={classes.container}>
      <HighchartsReact
        ref={ref}
        containerProps={{ style: { height: "100%" } }}
        highcharts={Highcharts}
        allowChartUpdate={allowRender}
        options={options}
      />
    </div>
  );
};

export default TreemapRenderer;
