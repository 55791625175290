import { useCallback, useEffect, useMemo, useState } from "react";

import { EarlyAccessFeature, PerkModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import keys from "lodash/keys";

import { useApiContext } from "../../api/context";
import { useFeatureFlag } from "../../Components/hooks/useFeatureFlag";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type PerksWData } from "../../types";
import mixpanel from "../../utils/mixpanel";
import { PerksApi } from "./api";
import { perkTypeByPageId } from "./helpers";
import { type RegisterInterestFromType } from "./types";

export function usePerksApi() {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  return useMemo(() => new PerksApi(api, customer.id), [api, customer.id]);
}

export function filterPerks(pageId: string, allPerks: PerksWData[], acceleratorProgramEnabled: boolean) {
  const acceleratorPredicate = (perk: PerksWData) =>
    perkTypeByPageId[pageId].includes(perk.data.fields.type) || perk.data.fields.acceleratorProgram;
  const nonAcceleratorPredicate = (perk: PerksWData) =>
    perkTypeByPageId[pageId].includes(perk.data.fields.type) && !perk.data.fields.acceleratorProgram;

  return allPerks.filter(
    acceleratorProgramEnabled && pageId === "training" ? acceleratorPredicate : nonAcceleratorPredicate
  );
}

export const usePerks = (pageId: string): PerksWData[] => {
  const { isDoitEmployee } = useAuthContext();
  const acceleratorProgramEnabled = useFeatureFlag(EarlyAccessFeature.ACCELERATOR_PROGRAM);
  const [perks, setPerks] = useState<PerksWData[]>([]);

  useEffect(() => {
    const validPerkPageIds = keys(perkTypeByPageId);
    if (!validPerkPageIds.includes(pageId)) {
      // Nothing to see here, let's not waste time on queries as both predicates will be falsy.
      return;
    }

    let query = getCollection(PerkModel).where("fields.deleted", "==", false);

    if (!isDoitEmployee) {
      query = query.where("fields.active", "==", true);
    }

    return query.onSnapshot((snapshot) => {
      const allPerks = snapshot.docs.reduce((acc: PerksWData[], doc) => {
        const perk = {
          id: doc.id,
          data: doc.asModelData(),
        };
        if (
          isDoitEmployee ||
          acceleratorProgramEnabled ||
          (!acceleratorProgramEnabled && !perk.data.fields?.acceleratorProgram)
        ) {
          acc.push(perk);
        }
        return acc;
      }, []);

      const finalPerks = filterPerks(pageId, allPerks, acceleratorProgramEnabled);
      setPerks(finalPerks);

      mixpanel.track("perks.list", {});
    });
  }, [acceleratorProgramEnabled, isDoitEmployee, pageId]);
  return perks;
};

export const usePerkTagsHandler = ({
  perk,
  isDoitEmployee,
}: {
  perk: PerkModel | undefined;
  isDoitEmployee: boolean;
}) =>
  useCallback(() => {
    if (!perk?.fields) {
      return [];
    }

    const { type } = perk.fields;
    const tags: string[] = [];

    if (!["training", "pro-serv", "workshop"].includes(type)) {
      tags.push("cmp/service/perk");
    }

    if (isDoitEmployee) {
      tags.push("requested_by_doer");
    }

    return tags;
  }, [isDoitEmployee, perk?.fields]);

export const useMarketplaceListingHandler = (
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>
) =>
  useCallback(
    async (interestFrom: RegisterInterestFromType, url: string) => {
      await registerInterest(interestFrom);
      window.open(url, "_blank", "noopener,noreferrer");
    },
    [registerInterest]
  );
