import { useCallback, useEffect, useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import LaunchIcon from "@mui/icons-material/Launch";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useFormikContext } from "formik";

import { GetStripeCreditCardFeeAlert } from "../../../../../Pages/Entity/PaymentMethods/Stripe";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useToggle } from "../../../../../utils/useToggle";
import { useAsyncLoadingFunction } from "../../../../hooks/useAsyncLoadingFunction";
import { useErrorSnackbar } from "../../../../SharedSnackbar/SharedSnackbar.context";
import { type EditBillingProfileData } from "../../../BillingProfileForm.models";
import { PaymentMethodDisabledReason, type SavedPaymentMethod } from "../../api";
import { useV1Api } from "../../hooks";
import { getPaymentMethodDescriptor } from "./PaymentMethodDescriptor";

const PaymentMethodDeleteButton = ({
  entityId,
  paymentMethod,
  onDeleteComplete,
}: {
  entityId: string;
  paymentMethod: SavedPaymentMethod;
  onDeleteComplete: (paymentMethod: SavedPaymentMethod) => void;
}) => {
  const [isDeleteIntended, requestDelete, abandonDelete] = useToggle(false);
  const v1Api = useV1Api();
  const showErrorSnackbar = useErrorSnackbar();

  const [isDeleting, deletePaymentMethod] = useAsyncLoadingFunction(
    useCallback(async () => {
      try {
        await v1Api.deleteSavedPaymentMethod(entityId, paymentMethod.type, paymentMethod.id);
        abandonDelete();
        onDeleteComplete(paymentMethod);
      } catch (error) {
        consoleErrorWithSentry(error);

        let message = "Unable to load saved payment methods";
        if (axios.isAxiosError(error) && error.response?.data) {
          message = error.response?.data?.error || error.response?.data;
        } else if (error instanceof Error) {
          message = error.message;
        }
        showErrorSnackbar(message);
      }
    }, [abandonDelete, entityId, onDeleteComplete, paymentMethod, showErrorSnackbar, v1Api])
  );

  if (isDeleting) {
    return <LoadingButton color="secondary" loading={true} loadingPosition="start" variant="text" />;
  }

  if (isDeleteIntended) {
    return (
      <ClickAwayListener onClickAway={abandonDelete}>
        <Button
          color="error"
          onClick={(event) => {
            event.stopPropagation();
            deletePaymentMethod();
          }}
        >
          Delete forever
        </Button>
      </ClickAwayListener>
    );
  }

  return (
    <Button
      color="error"
      onClick={(event) => {
        event.stopPropagation();
        requestDelete();
      }}
    >
      Delete
    </Button>
  );
};

export type PaymentMethodSelectorProps = {
  entityId: string;
  currency: CurrencyCode;
  value: string | null;
  setFieldValue: (field: string, value: string | null, shouldValidate?: boolean | undefined) => void;
  savedPaymentMethods: SavedPaymentMethod[];
};

const DisabledAlert = ({
  paymentMethod,
  defaultPaymentMethodId,
}: {
  paymentMethod: SavedPaymentMethod;
  defaultPaymentMethodId: string | null;
}) => {
  if (!paymentMethod.disabledReason) {
    return null;
  }
  if (
    paymentMethod.id === defaultPaymentMethodId &&
    paymentMethod.disabledReason === PaymentMethodDisabledReason.EXPIRED
  ) {
    return <Alert severity="warning">The credit card has expired. Please set a new default payment method.</Alert>;
  } else if (
    paymentMethod.id === defaultPaymentMethodId &&
    paymentMethod.disabledReason === PaymentMethodDisabledReason.MANDATE_INACTIVE
  ) {
    return (
      <Alert severity="warning">
        The bank account mandate has been canceled. Please set a new default payment method.
      </Alert>
    );
  } else if (paymentMethod.disabledReason === PaymentMethodDisabledReason.REQUIRES_ACTION) {
    return (
      <Alert
        severity="info"
        action={
          paymentMethod.disabledReason === PaymentMethodDisabledReason.REQUIRES_ACTION &&
          !!paymentMethod.verificationUrl && (
            <Button
              variant="text"
              startIcon={<LaunchIcon />}
              color="primary"
              href={paymentMethod.verificationUrl}
              rel="noopener noreferrer"
            >
              Verify
            </Button>
          )
        }
      >
        The bank account details below require verification.
      </Alert>
    );
  } else if (paymentMethod.disabledReason === PaymentMethodDisabledReason.PROCCESSING) {
    return (
      <Alert severity="info">The bank account details below are pending validation. This could take up to 48hrs.</Alert>
    );
  }
  return null;
};

export const PaymentMethodSelector = ({ entityId, currency, savedPaymentMethods }: PaymentMethodSelectorProps) => {
  const [paymentMethods, setPaymentMethods] = useState<SavedPaymentMethod[]>([]);
  const theme = useTheme();

  const {
    values: { paymentMethodId, paymentMethodType },
    setFieldValue,
    initialValues,
  } = useFormikContext<EditBillingProfileData>();

  useEffect(() => setPaymentMethods(savedPaymentMethods), [savedPaymentMethods]);

  useEffect(() => {
    if (!paymentMethods.some(({ id }) => paymentMethodId === id) && paymentMethods.length > 0) {
      setFieldValue("paymentMethodId", paymentMethods[0].id);
      setFieldValue("paymentMethodType", paymentMethods[0].type);
    }
  }, [paymentMethods, setFieldValue, paymentMethodId]);

  const onDeleteComplete = useCallback(
    (deletedPaymentMethod: SavedPaymentMethod) =>
      setPaymentMethods((currentPaymentMethods) =>
        currentPaymentMethods.filter((paymentMethod) => paymentMethod !== deletedPaymentMethod)
      ),
    []
  );

  return (
    <>
      {paymentMethodType === "credit_card" && (
        <Alert severity="warning" sx={{ marginTop: 1 }}>
          {GetStripeCreditCardFeeAlert(currency)}
        </Alert>
      )}
      <List sx={{ marginTop: 0, display: "flex", flexDirection: "column", gap: 2 }}>
        {paymentMethods.length === 0 && <Alert severity="info">You don't have any payment method defined.</Alert>}
        {paymentMethods.map((paymentMethod) => {
          const { icon, primary, secondary } = getPaymentMethodDescriptor(paymentMethod);
          const isSelected = paymentMethod.id === paymentMethodId;
          return (
            <Card key={paymentMethod.id} sx={{ borderColor: isSelected ? theme.palette.primary.border : "default" }}>
              {paymentMethod.disabledReason && (
                <DisabledAlert paymentMethod={paymentMethod} defaultPaymentMethodId={paymentMethodId} />
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  if (paymentMethod?.disabledReason) {
                    return;
                  }
                  setFieldValue("paymentMethodId", paymentMethod.id);
                  setFieldValue("paymentMethodType", paymentMethod.type);
                }}
              >
                <Stack sx={{ width: "100%", p: 2 }}>
                  <Stack direction="row" alignItems="center">
                    <ListItemButton
                      disableRipple
                      role={undefined}
                      disabled={!!paymentMethod.disabledReason}
                      sx={{ p: 0, "&:hover": { backgroundColor: "inherit" } }}
                    >
                      <ListItemIcon>
                        <Radio
                          checked={isSelected}
                          value={paymentMethod.id}
                          tabIndex={-1}
                          disableRipple
                          name="paymentMethodId"
                          inputProps={{ "aria-labelledby": paymentMethod.id }}
                        />
                      </ListItemIcon>
                      <ListItemIcon sx={{ minWidth: 80, marginRight: 2 }}>{icon}</ListItemIcon>
                      <ListItemText
                        id={paymentMethod.id}
                        primary={primary}
                        primaryTypographyProps={{ fontWeight: 500 }}
                        secondary={secondary}
                      />
                    </ListItemButton>
                    {paymentMethod.id !== initialValues.paymentMethodId && (
                      <Box sx={{ ml: "auto" }}>
                        <PaymentMethodDeleteButton
                          entityId={entityId}
                          paymentMethod={paymentMethod}
                          onDeleteComplete={onDeleteComplete}
                        />
                      </Box>
                    )}
                  </Stack>
                  {isSelected && (
                    <Alert severity="info" sx={{ mt: 1 }}>
                      Payment will be automatically taken from this account for invoices
                    </Alert>
                  )}
                </Stack>
              </ListItem>
            </Card>
          );
        })}
      </List>
    </>
  );
};
