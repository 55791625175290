import { type Dispatch, memo, type SetStateAction, useCallback } from "react";

import { Aggregator, type ComparativeFeature, Metric, Renderer } from "@doitintl/cmp-models";

import { type ColKeySort } from "../../../../Pages/CloudAnalytics/ReportData";
import { useFullScreen } from "../../../../utils/dialog";
import { useReportConfig, useReportExportContext } from "../../Context";
import { ReportConfigKind } from "../../reducers/ReportConfigReducer";
import { isTable, updateColKeySort } from "../../utilities";
import HighchartsRenderer from "../HighchartsRenderer";
import TableRenderer from "../TableRenderer";
import TreemapRenderer from "../TreemapRenderer";
import type ReportData from "../../../../Pages/CloudAnalytics/ReportData";

type Props = {
  colKeySort?: ColKeySort | null;
  dataProp: ReportData;
  forceRender: boolean;
  forecastStart: number;
  formatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
  highchartsTitle: string;
  isForecast: boolean;
  isTreemapExact: boolean;
  renderKey: number;
  setColKeySort: Dispatch<SetStateAction<ColKeySort | undefined>>;
  showForecast: boolean;
};

const TableChartRenderer = ({
  colKeySort,
  dataProp,
  forceRender,
  forecastStart,
  formatter,
  highchartsTitle,
  isForecast,
  isTreemapExact,
  renderKey,
  setColKeySort,
  showForecast,
}: Props) => {
  const { isMobile: smDown } = useFullScreen();
  const {
    reportConfig: { includeSubtotals, comparative, logScale, renderer, metric, aggregator },
    dispatchReportConfig,
  } = useReportConfig();
  const { chartInstanceRef } = useReportExportContext();

  const isTableRenderer = isTable(renderer);
  const showSubtotals = includeSubtotals && isTableRenderer;
  const reportContainerHeight = "100%";
  const isTreemap = renderer === Renderer.TREEMAP;
  const heatmapReverseColor = metric === Metric.SAVINGS || metric === Metric.MARGIN;
  const totalsColHidden = aggregator === Aggregator.COUNT;

  const sortByCol = useCallback((colKey) => updateColKeySort(setColKeySort, colKey), [setColKeySort]);

  const changeAxisScale = useCallback(
    () => dispatchReportConfig({ type: ReportConfigKind.TOGGLE_LOG_SCALE, payload: {} }),
    [dispatchReportConfig]
  );

  if (isTableRenderer) {
    const heatmap = () => {
      switch (renderer) {
        case Renderer.TABLE:
          return "";
        case Renderer.HEATMAP:
          return "full";
        case Renderer.ROW_HEATMAP:
          return "row";
        case Renderer.COL_HEATMAP:
          return "col";
      }
    };
    return (
      <TableRenderer
        data={dataProp}
        isForecast={isForecast}
        forecastStart={forecastStart}
        formatter={formatter}
        heatmap={heatmap()}
        height={reportContainerHeight}
        reverseColors={heatmapReverseColor}
        smDown={smDown}
        sortByCol={sortByCol}
        colKeySort={colKeySort}
        totalsColHidden={totalsColHidden}
        showSubtotals={showSubtotals}
      />
    );
  }

  if (isTreemap) {
    return (
      <TreemapRenderer
        data={dataProp}
        forceRender={forceRender}
        formatter={formatter}
        height={reportContainerHeight}
        highchartRef={chartInstanceRef}
        isExact={isTreemapExact}
        isWidget={false}
        key={`treemap-renderer-key${renderKey}`}
        widgetsList={[]}
      />
    );
  }

  return (
    <HighchartsRenderer
      changeAxisScale={changeAxisScale}
      comparative={comparative ?? undefined}
      data={dataProp}
      forceRender={forceRender}
      formatter={formatter}
      height={reportContainerHeight}
      highchartRef={chartInstanceRef}
      isWidget={false}
      key={`highcharts-renderer-key${renderKey}`}
      logScale={logScale}
      showForecast={showForecast}
      title={highchartsTitle}
      type={renderer}
    />
  );
};

export default memo(TableChartRenderer);
