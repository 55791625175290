import { type ReactNode, useEffect, useState } from "react";

import { SyncLoader } from "react-spinners";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { type SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

import mixpanel from "../../../utils/mixpanel";
import { useInsights } from "../api";
import { useInsightsContext } from "../context";
import { NotFound } from "../NotFound/NotFound";
import { type CloudTag, type SavingsPeriod } from "../types";
import { isOptimized, sortByTotalPotentialDailySavings } from "../utils";
import { InsightsListRow } from "./InsightsListRow";
import { InsightsListRowUpsell } from "./InsightsListRowUpsell";

const VALUE_ASCENDING = "ascending";
const VALUE_DESCENDING = "descending";
const VALUE_ALL_CLOUDS = "all";

function PageWrapper({ children, controls }: Readonly<{ children: ReactNode; controls?: ReactNode }>) {
  return (
    <Container maxWidth="lg">
      {/* Space to top should be 6, but 1 is already added to the main page area */}
      <Box mt={5} mb={4}>
        <Stack direction={{ sm: "column", md: "row" }} mb={3} gap={2}>
          <Stack direction="column" spacing={2} width={{ sm: "100%", md: "50%" }}>
            <Typography variant="h1">Insights</Typography>
            <Typography variant="subtitle1" fontWeight={400}>
              View cloud optimization Insights from DoiT
            </Typography>
          </Stack>

          {controls ? (
            <Stack
              direction={{ sm: "column", md: "row" }}
              alignItems="end"
              justifyContent="end"
              width={{ sm: "100%", md: "50%" }}
              gap={2}
            >
              {controls}
            </Stack>
          ) : null}
        </Stack>
        {children}
      </Box>
    </Container>
  );
}

export function InsightsList() {
  const { insights, isFetching, nonEntitledSummary } = useInsights();
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState(VALUE_DESCENDING);
  const [selectedCloud, setSelectedCloud] = useState(VALUE_ALL_CLOUDS);
  const { savingsPeriod, setSavingsPeriod } = useInsightsContext();

  useEffect(() => {
    mixpanel.track("insights.list.open");
  }, []);

  const nonEntitledDailySavings = nonEntitledSummary?.potentialDailySavings.value || 0;
  const customProblems = nonEntitledSummary?.customProblems.numberOfActionableInsights || 0;
  const potentialDailySaving = nonEntitledSummary?.potentialDailySavings.numberOfActionableInsights || 0;
  const nonEntitledTotalInsights = customProblems + potentialDailySaving;

  if (isFetching) {
    return (
      <PageWrapper>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      </PageWrapper>
    );
  }

  if (insights?.length) {
    // For now, until we have different insight types:
    // - only display the successful ones
    // - sort them by savings (depending on user selection)
    const filteredInsights = insights.filter((insight) => insight.status === "success");
    const sortedInsights = sortByTotalPotentialDailySavings(filteredInsights, sortOrder === VALUE_ASCENDING);

    const availableClouds: CloudTag[] = [];

    for (const insight of sortedInsights) {
      for (const cloudTag of insight.cloudTags) {
        if (!availableClouds.includes(cloudTag)) {
          availableClouds.push(cloudTag);
        }
      }
    }

    const selectedInsights =
      selectedCloud === VALUE_ALL_CLOUDS
        ? sortedInsights
        : sortedInsights.filter((insight) => insight.cloudTags.includes(selectedCloud as CloudTag));

    const selectedRelevantNotOptimizedInsights = selectedInsights.filter(
      (insight) => insight.results?.isRelevant && !isOptimized(insight)
    );

    const selectedRelevantOptimizedInsights = selectedInsights.filter(
      (insight) => insight.results?.isRelevant && isOptimized(insight)
    );

    const selectedIrrelevantInsights = selectedInsights.filter((insight) => !insight.results?.isRelevant);

    const changeSavingsPeriod = (event: SelectChangeEvent) => {
      const newValue = event.target.value as SavingsPeriod;

      mixpanel.track("insights.list.change-savings-period", {
        oldValue: savingsPeriod,
        newValue,
      });

      setSavingsPeriod(newValue);
    };

    const changeCloud = (event: SelectChangeEvent) => {
      const newValue = event.target.value;

      mixpanel.track("insights.list.change-cloud", {
        oldValue: selectedCloud,
        newValue,
      });

      setSelectedCloud(newValue);
    };

    const changeSortOrder = (event: SelectChangeEvent) => {
      const newValue = event.target.value;

      mixpanel.track("insights.list.change-sort-order", {
        oldValue: sortOrder,
        newValue,
      });

      setSortOrder(newValue);
    };

    const controls = (
      <>
        <FormControl>
          <InputLabel>Savings period</InputLabel>
          <Select
            label="Savings period"
            size="small"
            value={savingsPeriod}
            onChange={changeSavingsPeriod}
            sx={{ minWidth: "120px" }}
          >
            <MenuItem value="yearly">Yearly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="daily">Daily</MenuItem>
          </Select>
        </FormControl>

        {/* Only display this if there's more than one option */}
        {availableClouds.length > 1 ? (
          <FormControl>
            <InputLabel>Cloud</InputLabel>
            <Select label="Cloud" size="small" value={selectedCloud} onChange={changeCloud} sx={{ minWidth: "120px" }}>
              <MenuItem value={VALUE_ALL_CLOUDS}>All clouds</MenuItem>
              {availableClouds.map((cloudTag) => (
                <MenuItem value={cloudTag} key={cloudTag}>
                  {cloudTag.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        <FormControl>
          <InputLabel>Sort by</InputLabel>
          <Select label="Sort by" size="small" value={sortOrder} onChange={changeSortOrder}>
            <MenuItem value={VALUE_DESCENDING}>Value high to low</MenuItem>
            <MenuItem value={VALUE_ASCENDING}>Value low to high</MenuItem>
          </Select>
        </FormControl>
      </>
    );

    return (
      <PageWrapper controls={controls}>
        <Stack spacing={2} sx={{ mb: 2 }}>
          {selectedRelevantNotOptimizedInsights.length > 0 && (
            <>
              <InsightsListRow insight={selectedRelevantNotOptimizedInsights[0]} />
              {nonEntitledTotalInsights > 0 && (
                <InsightsListRowUpsell totalInsights={nonEntitledTotalInsights} savings={nonEntitledDailySavings} />
              )}
              {selectedRelevantNotOptimizedInsights.slice(1).map((insight) => (
                <InsightsListRow key={`${insight.providerId}#${insight.key}`} insight={insight} />
              ))}
            </>
          )}
        </Stack>

        {selectedRelevantOptimizedInsights.length > 0 ? (
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="optimized-insights-content"
              id="optimized-insights-header"
              sx={{ paddingX: 0 }}
            >
              {selectedRelevantOptimizedInsights.length}{" "}
              {selectedRelevantOptimizedInsights.length === 1 ? "optimized insight" : "optimized insights"}
            </AccordionSummary>
            <AccordionDetails sx={{ paddingX: 0 }}>
              <Stack spacing={2}>
                {selectedRelevantOptimizedInsights.map((insight) => (
                  <InsightsListRow key={`${insight.providerId}#${insight.key}`} insight={insight} />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null}

        {selectedIrrelevantInsights.length > 0 ? (
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="irrelevant-insights-content"
              id="irrelevant-insights-header"
              sx={{ paddingX: 0 }}
            >
              {selectedIrrelevantInsights.length}{" "}
              {selectedIrrelevantInsights.length === 1
                ? "insight that does not apply to you"
                : "insights that do not apply to you"}
            </AccordionSummary>
            <AccordionDetails sx={{ paddingX: 0 }}>
              <Stack spacing={2}>
                {selectedIrrelevantInsights.map((insight) => (
                  <InsightsListRow key={`${insight.providerId}#${insight.key}`} insight={insight} />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <NotFound />
    </PageWrapper>
  );
}
