import { type SyntheticEvent, useEffect, useState } from "react";

import { type AssetModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField, { type TextFieldProps } from "@mui/material/TextField";

import { type Asset } from "../../../../../types";
import { getAssetLabel } from "../../../utils";

type AssetSelectorProps = {
  assets: Asset[];
  disabled: boolean;
  onChange: (event: SyntheticEvent, value: Asset[]) => void;
  TextFieldProps: TextFieldProps;
  value: ModelReference<AssetModel> | undefined;
};

export const AssetSelector = ({ assets, disabled, onChange, TextFieldProps, value }: AssetSelectorProps) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);

  useEffect(() => setSelectedAsset(assets.find(({ id }) => value?.id === id) ?? null), [assets, value]);
  useEffect(() => setInputValue(selectedAsset ? getAssetLabel(selectedAsset) : ""), [selectedAsset]);

  return (
    <Autocomplete<Asset>
      data-cy="asset-selector"
      options={assets}
      value={selectedAsset}
      onChange={(event, value) => onChange(event, value ? [value] : [])}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      getOptionLabel={getAssetLabel}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} {...TextFieldProps} fullWidth />}
    />
  );
};

export default AssetSelector;
