import { createContext, type ReactNode, useContext, useEffect, useState } from "react";

import {
  CustomerModel,
  type CustomerModelOrganizationModel,
  type CustomerModelOrganizationModel as Organization,
} from "@doitintl/cmp-models";
import { getCollection, modelFromPath } from "@doitintl/models-firestore";
import { type Unsubscribe } from "firebase/firestore";

import { globalText } from "../../assets/texts";
import { updateOrgLastAccessed } from "../../Pages/IAM/Organizations/db";
import { type OrganizationWSnap } from "../../Pages/IAM/Organizations/Types";
import { type Customer } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { useAuthContext } from "../AuthContext";
import { useUserContext } from "../UserContext";
import { arrayFromDocChange } from "./arrayFromDocChange";

export type OrgsContextType = {
  organizations: OrganizationWSnap[];
  userOrganization?: OrganizationWSnap | null;
  organizationsLoading: boolean;
};

const orgsCollection = (customerId: string) => getCollection(CustomerModel).doc(customerId).collection("customerOrgs");

const subscribeCustomerOrgs = (
  customerId: string,
  currentOrgId: string | null,
  setOrgs: (
    setter: ({ organizations, userOrganization, organizationsLoading }: OrgsContextType) => OrgsContextType
  ) => void
) => {
  const orgsMap: Record<string, Organization> = {};
  return orgsCollection(customerId).onSnapshot((snapshot) => {
    setOrgs(({ organizations: prevOrganizations }) => {
      const newOrgs = [...prevOrganizations];
      arrayFromDocChange(newOrgs, snapshot, (org) => {
        orgsMap[org.id] = org.asModelData();

        const data = org.asModelData();

        if (!data.allowCustomDashboards) {
          data.dashboards = [];
        }

        return {
          data,
          snapshot: org,
          ref: org.modelRef,
          users: 0,
          parentName: "",
        }; // users per orgs added in Orgs component
      });

      const organizations = newOrgs.map((o) => {
        o.parentName = o.data.parent?.id ? orgsMap[o.data.parent.id].name : globalText.NA;
        return o;
      });

      const userOrganization = currentOrgId ? organizations.find((o) => o.snapshot.id === currentOrgId) : null;

      return {
        organizations,
        userOrganization: userOrganization ?? null,
        organizationsLoading: false,
      };
    });
  });
};

const defaultOrgsContext = {
  organizations: [],
  userOrganization: undefined,
  organizationsLoading: true,
};

const orgsContext = createContext<OrgsContextType>({ ...defaultOrgsContext });

export const OrgsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const [orgsData, setOrgsData] = useState<OrgsContextType>({ ...defaultOrgsContext });

  const { userModel: user } = useUserContext();
  const { isDoitPartner } = useAuthContext();

  const firstOrgPath = user?.organizations?.[0]?.path;
  const userOrgOath = orgsData.userOrganization?.ref.path;
  /*
 If there are orgs set context to create/delete them in CreateDashboard useEffect
 This will set both the customer organizations and current user organization(s).
 */

  useEffect(() => {
    // Update last accessed prop when a user of an org logs in
    if (!userOrgOath) {
      return;
    }
    const userOrgRef = modelFromPath<CustomerModelOrganizationModel>(userOrgOath);

    updateOrgLastAccessed(userOrgRef).catch(consoleErrorWithSentry);
  }, [userOrgOath]);

  useEffect(() => {
    setOrgsData({ ...defaultOrgsContext });

    if (!customer?.id) {
      return;
    }

    // it is not enough to get the ref since in partner we don't have a customer id
    const firstOrgRef = firstOrgPath ? modelFromPath<CustomerModelOrganizationModel>(firstOrgPath) : undefined;

    let currentOrgId;
    if (firstOrgRef) {
      currentOrgId = firstOrgRef.id;
    }

    let organizationsUnsubscribe: Unsubscribe;

    if (isDoitPartner && firstOrgRef) {
      firstOrgRef
        .get()
        .then((docSnap) => {
          const data = docSnap.asModelData();
          if (!data) {
            return;
          }

          const organization: OrganizationWSnap = {
            data,
            ref: docSnap.modelRef,
            snapshot: docSnap,
            users: 0,
            parentName: "",
          };

          setOrgsData({
            organizations: [organization],
            userOrganization: organization,
            organizationsLoading: false,
          });
        })
        .catch((error) => {
          consoleErrorWithSentry(error);
        });
    } else if (!isDoitPartner) {
      organizationsUnsubscribe = subscribeCustomerOrgs(customer.id, currentOrgId, setOrgsData);
    }

    return () => {
      if (organizationsUnsubscribe) {
        organizationsUnsubscribe();
      }
    };
  }, [isDoitPartner, customer?.id, firstOrgPath]);

  return <orgsContext.Provider value={{ ...orgsData }}>{children}</orgsContext.Provider>;
};

export function useOrgsContext(): OrgsContextType {
  return useContext(orgsContext);
}

export const OrgsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<OrgsContextType>;
}) => {
  const actualValue = value ?? {};

  if (actualValue.organizations === undefined) {
    actualValue.organizations = [];
  }

  if (actualValue.userOrganization === undefined) {
    actualValue.userOrganization = null;
  }

  return <orgsContext.Provider value={actualValue as OrgsContextType}>{children}</orgsContext.Provider>;
};
