import { Box, MenuItem, TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { anomalyAlerts } from "../../../../utils/common";

type Props = {
  isThresholdOptionChecked: boolean;
  setThresholdOptionChecked: (val: boolean) => void;
  disabled: boolean;
  updateSeverityNotif: (cb: boolean, level: number) => void;
  severityInput: number;
  userSettingsAnomalyAlerts: number | undefined;
  onThresholdOptionsChange: (val: number) => void;
};

const SeverityOptions = (props: Props) => {
  const {
    isThresholdOptionChecked,
    setThresholdOptionChecked,
    disabled,
    updateSeverityNotif,
    severityInput,
    userSettingsAnomalyAlerts,
    onThresholdOptionsChange,
  } = props;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="baseline"
      pt={1}
      width="100%"
      data-cy="severity-notif"
      sx={{ fontSize: "inherit" }}
    >
      <Checkbox
        onClick={() => {
          setThresholdOptionChecked(!isThresholdOptionChecked);
          updateSeverityNotif(!isThresholdOptionChecked, severityInput);
        }}
        inputProps={{
          "aria-label": "anomalies notification severity checkbox",
        }}
        disabled={disabled}
        size="small"
        sx={{ alignSelf: "flex-start" }}
        checked={isThresholdOptionChecked}
        data-cy="severity-notif-cb"
      />
      <Box display="flex" justifyContent="flex-start" flexDirection="column" width="100%" sx={{ fontSize: "inherit" }}>
        <Typography color={disabled ? "textSecondary" : "textPrimary"} display="inline">
          Only send notifications with severity
        </Typography>
        <TextField
          select
          variant="outlined"
          label="Anomaly severity"
          value={severityInput || userSettingsAnomalyAlerts}
          onChange={(e) => onThresholdOptionsChange(Number(e.target.value))}
          size="small"
          margin="dense"
          disabled={disabled}
          sx={{ mt: 1 }}
          data-cy="severity-select"
        >
          {anomalyAlerts.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={disabled} data-cy={option.name}>
              <span> {`${option.name === "Critical" ? "" : ">="}${option.name}`}</span>
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};

export default SeverityOptions;
