import { Box, Container, Divider, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { type SectionTitles } from "./index";

const NavCont = styled(Box)({
  display: "flex",
  alignItems: "center",
  paddingTop: "14px",
  paddingBottom: "14px",
});

const NavTitle = styled(Typography)({
  paddingRight: 12,
  fontWeight: 500,
  fontSize: "14px",
});

export const NavLink = styled(Link)({
  padding: "0 12px",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  fontSize: "14px",
});

export const PerkNavBar = ({
  containerWidth,
  sectionTitles,
}: {
  containerWidth: number;
  sectionTitles: SectionTitles;
}) => (
  <Box>
    <Container sx={{ width: containerWidth, display: "flex" }} disableGutters>
      <NavCont data-cy="nav-bar">
        <NavTitle>Jump to:</NavTitle>
        {Object.values(sectionTitles).map((value) => (
          <NavLink key={value} href={`#${value}`}>
            {value}
          </NavLink>
        ))}
      </NavCont>
    </Container>
    <Divider />
  </Box>
);
