import { useMemo } from "react";

import { Budget } from "./Budget";
import { useNewBudgetData } from "./useNewBudgetData";

export const CreateBudgetWrapper = () => {
  const newBudgetData = useNewBudgetData();

  const budget = useMemo(
    () => ({
      data: newBudgetData,
    }),
    [newBudgetData]
  );

  return <Budget budget={budget} isNewBudget={true} />;
};
