import { useEffect, useState } from "react";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { type AwsAccount, AwsAccountStatusType, runCreateAwsAccountCommand } from "../../../data/aws-account-api";

const statusesWithRetryOption = [
  AwsAccountStatusType.COMPLETE,
  AwsAccountStatusType.INCOMPLETE,
  AwsAccountStatusType.FAILED,
];

export const ActionsCell = ({ row }: { row: AwsAccount }) => {
  const [options, setOptions] = useState<ThreeDotsMenuOption[]>([]);

  useEffect(
    () =>
      setOptions(
        statusesWithRetryOption.includes(row.statusDescriptor.status)
          ? [
              {
                key: "restart-process",
                label: "Restart process",
                action: () => runCreateAwsAccountCommand(row.id),
              },
            ]
          : []
      ),
    [row]
  );

  return options.length > 0 ? <ThreeDotsMenu options={options} /> : <></>;
};
