import {
  type ComponentType,
  createContext,
  type JSX,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DateTime } from "luxon";
import { getDisplayName } from "recompose";

import useMountEffect from "../Components/hooks/useMountEffect";

export enum Scopes {
  DriveReadonly = "https://www.googleapis.com/auth/drive.readonly",
  DriveFile = "https://www.googleapis.com/auth/drive.file",
}

type GapiContextProps = {
  accessToken?: string;
  getToken: (scope: string) => Promise<string>;
  isAuthenticated: (scope: string) => boolean;
};

export type WithGapiContextProps = {
  gapiContext: GapiContextProps;
};

const gapiContext = createContext<GapiContextProps>({
  accessToken: undefined,
  getToken: () => Promise.resolve(""),
  isAuthenticated: () => false,
});

type GapiContextInitProps = {
  children: JSX.Element;
};

export const GapiContextProvider = ({ children }: GapiContextInitProps) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [expiresAt, setExpiresAt] = useState<DateTime>();
  const [googleClient, setGoogleClient] = useState<any>();
  const [currentScope, setCurrentScope] = useState<string | undefined>(undefined);

  const isAuthenticated = useCallback(
    (scope) => !!(scope === currentScope && accessToken && expiresAt && expiresAt > DateTime.local()),
    [accessToken, currentScope, expiresAt]
  );

  const loadTokenClient = useCallback(
    (scope: string) =>
      new Promise<any>((resolve, reject) => {
        const script = document.createElement("script");

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        const onClientAuthLoad = async () => {
          const client = (google as any).accounts.oauth2.initTokenClient({
            client_id: process.env.REACT_APP_GAPI_CLIENT_ID,
            scope,
          });
          setGoogleClient(client);
          resolve(client);
        };

        script.onload = onClientAuthLoad;
        script.onerror = reject;

        document.body.appendChild(script);
      }),
    []
  );

  const loadGapiClient = () =>
    new Promise<void>((resolve, reject) => {
      const onClientLoad = async () => {
        await globalThis.gapi.client.init({
          apiKey: process.env.REACT_APP_GAPI_API_KEY,
          discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
        });
        resolve();
      };

      globalThis.gapi.load("client", { callback: onClientLoad, onerror: reject });
    });

  useMountEffect(() => {
    loadGapiClient();
  });

  const getToken = useCallback(
    async (scope: string): Promise<string> => {
      if (accessToken && isAuthenticated(scope)) {
        return accessToken;
      }

      let client = googleClient;
      if (!client) {
        client = await loadTokenClient(scope);
      }

      return new Promise((resolve, reject) => {
        client.callback = (tokenResponse) => {
          if (tokenResponse.error !== undefined) {
            reject(tokenResponse.error);
          }

          setExpiresAt(DateTime.local().plus({ seconds: tokenResponse.expires_in }));
          setAccessToken(tokenResponse.access_token);
          resolve(tokenResponse.access_token);
          setCurrentScope(scope);
        };

        client.requestAccessToken({
          scope,
          include_granted_scopes: false,
        });
      });
    },
    [accessToken, googleClient, isAuthenticated, loadTokenClient]
  );

  const value = useMemo(
    () => ({
      accessToken,
      getToken,
      isAuthenticated,
    }),
    [accessToken, getToken, isAuthenticated]
  );

  return <gapiContext.Provider value={value}>{children}</gapiContext.Provider>;
};

export const GapiContextConsumer = gapiContext.Consumer;

export const useGapiContext = () => useContext(gapiContext);

type PickerContextProps = {
  pickerApiLoaded: boolean;
};

export type WithPickerContextProps = PickerContextProps;

export const pickerContext = createContext<PickerContextProps>({ pickerApiLoaded: false });
export const PickerContextProvider = ({ children }: { children: JSX.Element }) => {
  const [pickerLoaded, setPickerLoaded] = useState(false);

  useEffect(() => {
    if (!globalThis.gapi) {
      return;
    }

    globalThis.gapi.load("picker", { callback: () => setPickerLoaded(true) });
  }, []);

  const value = useMemo(() => ({ pickerApiLoaded: pickerLoaded }), [pickerLoaded]);
  return <pickerContext.Provider value={value}>{children}</pickerContext.Provider>;
};

export function withGapiAndPicker<P extends object>(
  Component: ComponentType<P & WithGapiContextProps & WithPickerContextProps>
) {
  const WrappedComponent = (props: P) => (
    <GapiContextProvider>
      <PickerContextProvider>
        <gapiContext.Consumer>
          {(gapiContext) => (
            <pickerContext.Consumer>
              {({ pickerApiLoaded }) => (
                <Component gapiContext={gapiContext} pickerApiLoaded={pickerApiLoaded} {...props} />
              )}
            </pickerContext.Consumer>
          )}
        </gapiContext.Consumer>
      </PickerContextProvider>
    </GapiContextProvider>
  );

  WrappedComponent.displayName = `withGapiAndPicker(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}

export const usePickerContext = () => useContext(pickerContext);
