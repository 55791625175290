import { type FC } from "react";

import { TextField } from "@mui/material";
import { type FieldInputProps, type FormikProps } from "formik";

import { useFieldValidationProps } from "../useFieldValidationProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";

export const NumberParam: FC<{
  formikProps: FormikProps<unknown>;
  fieldProps: FieldInputProps<number>;
  label: string;
  onRemove?: () => void;
}> = ({ formikProps, fieldProps, label, onRemove }) => {
  const { showError, errorMessage } = useFieldValidationProps(formikProps, fieldProps);
  const required = onRemove === undefined;

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      <TextField
        type="number"
        value={fieldProps.value}
        label={label}
        name={fieldProps.name}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        required={required}
        helperText={errorMessage}
        error={showError}
        fullWidth
      />
    </OptionalParamWrapper>
  );
};
