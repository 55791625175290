import { type AccessLevel, AccessLevels, type CloudConnectCategory } from "@doitintl/cmp-models";

import { ActiveWidgetsListText } from "../../../../assets/texts";
import { getCloudConnectStatus } from "../../../../utils/common";
import { CategoryStatus, GKEConfigStatus, GKEConfigSubStepStatus, GKEFailSteps, GKEStepNames } from "./enums";
import { type ComposeStepsProps, type GKEConfigStep, type GKEConfigSubStep } from "./types";

type GKEStepsStatus = Partial<Record<GKEStepNames, GKEConfigSubStepStatus>>;

export const getSubStepStatus = (permission: string, missingPermissions?: string[]): GKEConfigSubStepStatus => {
  if (!missingPermissions) {
    return GKEConfigSubStepStatus.IDLE;
  }
  return missingPermissions.includes(permission) ? GKEConfigSubStepStatus.FAIL : GKEConfigSubStepStatus.OK;
};

export const filterPermission = (category: CloudConnectCategory | undefined, accessLevel: AccessLevel) =>
  category?.permissions.filter(
    (permission) =>
      accessLevel === AccessLevels.ORGANIZATION ||
      (category.orgLevelOnlyPermissions && !category.orgLevelOnlyPermissions.includes(permission))
  ) ?? [];

// computes only the permissions step!
export const getStepStatus = (permissions?: string[], missingPermissions?: string[]): GKEConfigStatus => {
  let status = GKEConfigStatus.IDLE;

  if (missingPermissions && permissions) {
    status = missingPermissions.length ? GKEConfigStatus.WARNING : GKEConfigStatus.OK;
    // in case we have a failed step- it's all permiossions missing
    if (missingPermissions.length === permissions.length) {
      status = GKEConfigStatus.FAIL;
    }
  }

  return status;
};

export const getGKESetupStatus = (steps: GKEStepsStatus, permissionsStatus: GKEConfigStatus): GKEConfigStatus => {
  if (permissionsStatus === GKEConfigStatus.FAIL) {
    return GKEConfigStatus.FAIL;
  }
  const subStatusVals: Array<GKEConfigSubStepStatus> = Object.values(steps);
  const checkIfIdle = (element) => element === GKEConfigSubStepStatus.IDLE;
  const checkIfFail = (element) => element === GKEConfigSubStepStatus.FAIL;

  if (subStatusVals.some(checkIfFail)) {
    return GKEConfigStatus.FAIL;
  } else if (subStatusVals.some(checkIfIdle)) {
    return GKEConfigStatus.WARNING;
  }
  return GKEConfigStatus.OK;
};

export const calculateFail = (result) => {
  if (result === undefined) {
    return GKEConfigSubStepStatus.IDLE;
  }

  return result ? GKEConfigSubStepStatus.OK : GKEConfigSubStepStatus.FAIL;
};

export const calculateStatuses = (permissions: string[], results?: (boolean | undefined)[]) =>
  Object.keys(Array(permissions.length).fill(undefined))
    .map((el) => Number(el))
    .reduce(
      (total, i) => {
        total[permissions[i]] = calculateFail(results ? results[i] : undefined);
        return total;
      },
      {} as { [key: string]: GKEConfigSubStepStatus }
    );

export const calculateMissing = (permissions: string[], fails?: (undefined | boolean)[]) => {
  if (!fails || fails.every((f) => f === undefined)) {
    return undefined;
  }
  return permissions.filter((_, i) => fails[i] !== true);
};

export const getGKEConfigStatus = (results?: (boolean | undefined)[]) => {
  if (!results || results?.every((r) => r === undefined)) {
    return GKEConfigStatus.IDLE;
  }

  if (results?.every((r) => r)) {
    return GKEConfigStatus.OK;
  }

  if (results?.every((r) => r === false)) {
    return GKEConfigStatus.FAIL;
  }

  return GKEConfigStatus.WARNING;
};

export const getGKECloudConnectStatus = (statusID: number) => getCloudConnectStatus(statusID);

export const getGKEValidStatus: (steps?: GKEConfigStep[]) => number = (steps) => {
  const [step1, step2] = steps || [{}, {}];
  let status = CategoryStatus.NotSetUp;
  if (step1?.status && step2?.status) {
    if (step1.status === GKEConfigStatus.OK && step2.status === GKEConfigStatus.OK) {
      status = CategoryStatus.Healthy;
    }

    if (step1.status === GKEConfigStatus.WARNING || step2.status === GKEConfigStatus.WARNING) {
      status = CategoryStatus.Unhealthy;
    }

    if (step1.status === GKEConfigStatus.IDLE && step2.status === GKEConfigStatus.IDLE) {
      status = CategoryStatus.NotSetUp;
    }

    if (step1.status === GKEConfigStatus.FAIL && step2.status === GKEConfigStatus.FAIL) {
      status = CategoryStatus.Critical;
    }
  }

  return status;
};

const getPermissionsList = (
  category: CloudConnectCategory | undefined,
  accessLevel: AccessLevel,
  missingPermissions: string[] | undefined,
  failedStep: GKEFailSteps | undefined
) =>
  filterPermission(category, accessLevel).map((permission) => ({
    name: permission,
    status: failedStep !== undefined ? getSubStepStatus(permission, missingPermissions) : GKEConfigSubStepStatus.IDLE,
  }));

export const convertBoolToStatus = (input?: boolean) => {
  if (input === undefined) {
    return GKEConfigSubStepStatus.IDLE;
  }

  return input ? GKEConfigSubStepStatus.OK : GKEConfigSubStepStatus.FAIL;
};

export const buildSubStep = (keys: string[], status: (boolean | undefined)[]) => {
  const total: GKEConfigSubStep[] = [];
  for (let i = 0; i < keys.length; i++) {
    total.push({ name: keys[i], status: convertBoolToStatus(status[i]) });
  }
  return total;
};

export const composeSteps = ({ status, category, accessLevel, invalidDatasets }: ComposeStepsProps) => {
  const { failedStep, missingPermissions } = status;
  const GKESteps: GKEStepsStatus = {};
  const isCritical = failedStep === GKEFailSteps.GKEOnboardStepCritical;

  GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.IDLE; // 4
  GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.IDLE; // 2
  GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.IDLE; // 3

  if (isCritical || failedStep === GKEFailSteps.GKEOnboardStepPermissions) {
    GKESteps[GKEStepNames.Permissions] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.FAIL;
  }

  if (failedStep === GKEFailSteps.GKEOnboardStepKubernetesAPI) {
    // 2 Kubernetes_Engine_API
    GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.FAIL;
  }

  if (failedStep === GKEFailSteps.GKEOnboardStepClusters) {
    GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.OK;
    GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.FAIL;
    GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.FAIL;
  }

  if (failedStep === GKEFailSteps.GKEOnboardStepDatasets) {
    GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.OK;
    GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.OK;
    GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.FAIL;
  }

  if (failedStep === GKEFailSteps.GKEOnboardStepSuccess) {
    GKESteps[GKEStepNames.Kubernetes_Engine_API] = GKEConfigSubStepStatus.OK;
    GKESteps[GKEStepNames.GKE_Usage_Metering] = GKEConfigSubStepStatus.OK;
    GKESteps[GKEStepNames.BigQuery_Data_Role] = GKEConfigSubStepStatus.OK;
  }

  const permissionsStep = {
    index: 1,
    text: ActiveWidgetsListText.gkePermissionsText,
    learnMore: {
      url: ActiveWidgetsListText.gkePermissionsLearnUrl,
      text: ActiveWidgetsListText.LearnMore,
    },
    subSteps: isCritical ? [] : getPermissionsList(category, accessLevel, missingPermissions, failedStep),
    status: GKEConfigStatus.IDLE,
  };

  if (failedStep === undefined) {
    permissionsStep.status = GKEConfigStatus.IDLE;
  } else if (failedStep === GKEFailSteps.GKEOnboardStepSuccess) {
    permissionsStep.status = GKEConfigStatus.OK;
  } else if (isCritical) {
    permissionsStep.status = GKEConfigStatus.FAIL;
  } else {
    permissionsStep.status = getStepStatus(category?.permissions, missingPermissions);
  }

  const manualStep = {
    index: 2,
    text: ActiveWidgetsListText.gkeStepsText,
    learnMore: {
      url: ActiveWidgetsListText.gkeStepsLearnUrl,
      text: ActiveWidgetsListText.LearnMore,
    },
    status: failedStep === undefined ? GKEConfigStatus.IDLE : getGKESetupStatus(GKESteps, permissionsStep.status),
    subSteps: [
      {
        status: GKESteps[GKEStepNames.Kubernetes_Engine_API],
        name: "Enable Kubernetes Engine API",
      },
      {
        status: GKESteps[GKEStepNames.GKE_Usage_Metering],
        name: "Enable GKE Usage metering",
      },
      {
        status: GKESteps[GKEStepNames.BigQuery_Data_Role],
        name: "Enable access to ‘BigQuery Data Viewer’ role",
        invalidDatasets,
      },
    ],
  };
  return [permissionsStep, manualStep];
};
