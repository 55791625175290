import "@xyflow/react/dist/style.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import { CLOUD_FLOW_CREATION_STATUS, type CloudflowCreationStatus, CloudFlowNodeType } from "@doitintl/cmp-models";
import { Box, Stack, useTheme } from "@mui/material";
import { type EdgeTypes, type NodeTypes, ReactFlow, ReactFlowProvider } from "@xyflow/react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import CreateNewActionNodeDialog from "../Dialog/CreateNewActionNodeDialog";
import { DeleteIfNodeDialog } from "../Dialog/CreateNewActionNodeDialog/DeleteIfNodeDialog";
import { ManageIfNodeDialog } from "../Dialog/ManageIfNodeDialog";
import { useCloudflow, useTriggerCloudflow, useUpdateCloudflow } from "../hooks";
import { EDGE_TYPE } from "../types";
import NodeConfigurationPanel from "./ConfigurationPanel/NodeConfigurationPanel";
import ConditionEdge from "./Edge/ConditionEdge";
import CustomEdge from "./Edge/CustomEdge";
import GhostEdge from "./Edge/GhostEdge";
import { useNodeEdgeManager } from "./hooks/useNodeEdgeManager";
import { ActionNode } from "./Node/ActionNode";
import { ActionStepConfigurator } from "./Node/ActionStepConfigurator";
import { ConditionNode } from "./Node/ConditionNode";
import { GhostNode } from "./Node/GhostNode";
import { StartStepConfigurator } from "./Node/StartStepConfigurator";
import { TriggerNode } from "./Node/TriggerNode";
import ConfirmEditDialog from "./Topbar/ConfirmEditDialog";
import ConfirmUnpublishDialog from "./Topbar/ConfirmUnpublishDialog";
import Topbar from "./Topbar/Topbar";

const edgeTypes: EdgeTypes = {
  [EDGE_TYPE.CUSTOM]: CustomEdge,
  [EDGE_TYPE.GHOST]: GhostEdge,
  [EDGE_TYPE.CONDITION]: ConditionEdge,
};

const nodeTypes: NodeTypes = {
  [CloudFlowNodeType.START_STEP]: StartStepConfigurator,
  [CloudFlowNodeType.ACTION_STEP]: ActionStepConfigurator,
  [CloudFlowNodeType.GHOST]: GhostNode,
  [CloudFlowNodeType.TRIGGER]: TriggerNode,
  [CloudFlowNodeType.ACTION]: ActionNode,
  [CloudFlowNodeType.CONDITION]: ConditionNode,
  [CloudFlowNodeType.APPROVAL]: ActionNode,
  [CloudFlowNodeType.LOOP]: ActionNode,
};

export const CloudflowEditorContent = () => {
  const theme = useTheme();
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { cloudflow, cloudflowLoading } = useCloudflow(flowId);
  const [updateCloudflow, updateCloudflowLoading] = useUpdateCloudflow();
  const [triggerCloudflow] = useTriggerCloudflow();
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confrimUnpublishOpen, setConfirmUnpublishOpen] = useState(false);
  const { customer } = useCustomerContext();
  const history = useHistory();
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeClick,
    activeNode,
    setActiveNode,
    setNodes,
    showStepper,
    closeStepper,
    manageIfActionsId,
    setManageIfActionsId,
    onSaveManageIfActionsDialog,
    onConfirmDeleteIfNode,
    deleteIfNodeId,
    setDeleteIfNodeId,
  } = useNodeEdgeManager();
  const [cloudflowName, setCloudflowName] = useState<string | undefined>();
  const [status, setStatus] = useState<CloudflowCreationStatus | undefined>();

  const handlePanelClose = () => {
    setActiveNode(undefined);
  };

  const onClose = () => {
    history.push(`/customers/${customer.id}/cloudflow`);
  };

  const onSaveCloudflow = useCallback(
    () =>
      updateCloudflow(customer.id, flowId, {
        name: cloudflowName,
      }),
    [cloudflowName, customer.id, flowId, updateCloudflow]
  );

  const onPublishCloudflow = useCallback(
    () =>
      updateCloudflow(customer.id, flowId, {
        status: CLOUD_FLOW_CREATION_STATUS.PUBLISHED,
      }),
    [customer.id, flowId, updateCloudflow]
  );

  const onUnpublishCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmUnpublishOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onEditCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmEditOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onRunCloudflow = useCallback(async () => {
    await triggerCloudflow(customer.id, flowId);
  }, [customer.id, flowId, triggerCloudflow]);

  const handleUpdateNodeConfig = useCallback(
    (updatedNodeConfig: any) => {
      setNodes((prev) => {
        const updatedNodes = prev.map((node) => {
          if (node.id === activeNode?.id) {
            return { ...node, ...updatedNodeConfig };
          }
          return node;
        });
        return updatedNodes;
      });
    },
    [activeNode?.id, setNodes]
  );

  useEffect(() => {
    if (!cloudflowLoading && cloudflow?.data.name) {
      setCloudflowName(cloudflow.data.name);
    }
    if (!cloudflowLoading && cloudflow?.data.status) {
      setStatus(cloudflow.data.status);
    }
  }, [cloudflow?.data.name, cloudflow?.data.status, cloudflowLoading]);

  // get the latest configs for the active node
  const activeNodeConfigs = useMemo(
    () => (activeNode ? nodes.find((node) => node.id === activeNode.id) : undefined),
    [activeNode, nodes]
  );

  return (
    <>
      <Stack
        sx={{
          backgroundColor: theme.palette.general.backgroundDark,
          mx: -2,
          mt: -1,
          pt: 0,
          pb: 1,
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <ConfirmEditDialog
          isDialogOpened={confirmEditOpen}
          handleCloseDialog={() => setConfirmEditOpen(false)}
          handleEdit={onEditCloudflow}
        />
        <ConfirmUnpublishDialog
          isDialogOpened={confrimUnpublishOpen}
          handleCloseDialog={() => setConfirmUnpublishOpen(false)}
          handleUnpublish={onUnpublishCloudflow}
        />
        <Topbar
          cloudflowLoading={cloudflowLoading}
          cloudflowUpdateLoading={updateCloudflowLoading}
          cloudflowName={cloudflowName}
          cloudflowStatus={status}
          onUpdateName={(title: string) => setCloudflowName(title)}
          onSaveCloudflow={onSaveCloudflow}
          isPublished={status === CLOUD_FLOW_CREATION_STATUS.PUBLISHED}
          onClose={onClose}
          onEditCloudflow={() => setConfirmEditOpen(true)}
          onUnpublishCloudflow={() => setConfirmUnpublishOpen(true)}
          onRunCloudflow={onRunCloudflow}
          onPublishCloudflow={onPublishCloudflow}
        />
        <Box width="100%" height="100%">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            onEdgeClick={onEdgeClick}
            fitView
            style={{ transition: "margin 0.3s", marginRight: activeNode ? "450px" : "0" }}
          />
        </Box>
      </Stack>
      {!!deleteIfNodeId && (
        <DeleteIfNodeDialog
          open={!!deleteIfNodeId}
          onConfirm={onConfirmDeleteIfNode}
          onCancel={() => setDeleteIfNodeId("")}
        />
      )}
      {!!manageIfActionsId && (
        <ManageIfNodeDialog
          open={!!manageIfActionsId}
          onCancel={() => setManageIfActionsId("")}
          onSave={onSaveManageIfActionsDialog}
        />
      )}
      {activeNode && activeNodeConfigs && (
        <NodeConfigurationPanel
          open={!!activeNode}
          onClose={handlePanelClose}
          nodeConfig={activeNodeConfigs}
          onUpdateNode={handleUpdateNodeConfig}
        />
      )}
      <CreateNewActionNodeDialog handleCloseDialog={closeStepper} isDialogOpened={showStepper} />
    </>
  );
};

export const CloudflowEditor = () => (
  <ReactFlowProvider>
    <CloudflowEditorContent />
  </ReactFlowProvider>
);
