export const BIG_QUERY_LINK = "https://help.doit.com/docs/cloud-analytics/bq-audit-logs";

export const reportTxt = {
  RUN_REPORT_SHORTCUT: "⇧R",
  ADD_FILTER_TOOLTIP: "Add filter (⇧F)",
  ADD_GROUP_BY_TOOLTIP: "Add group by (⇧G)",
  ADD_DIMENSION_TOOLTIP: "Add dimension (⇧D)",
  CREATE_REPORT_FAILED: "Failed to create report",
  REMAINING_RESULTS: "Remaining results",
  AGGREGATE_TOP: "Aggregate all together (default)",
  AGGREGATE_ALL: "Aggregate per each limit",
  AGGREGATE_NONE: "Hide",
  INSUFFICIENT_DATA_FOR_FORECAST:
    "We do not have enough data to generate a forecast for the selected time range and filters, try decreasing the time Interval or removing filters.",
  FILTER_GROUPS: {
    SYSTEM_LABELS: "System Labels",
    POPULAR: "Popular",
  },
  INCLUDE_LABELS: "Include specific values in my search results",
  ALLOW_VALUES_IN_SEARCH_TOOLTIP:
    "When checked, you can search for any specific value such as individual SKUs, services, labels and more",
  NAME_BEFORE_SAVING: "Name before saving",
  REPORT_NAME: "Report name",
  CREATE_AS_NEW_REPORT: "Create as new report",
  DATA_SOURCE: "Data source",
  CHANGE_DATA_SOURCE: "Change data source",
  DATA_SOURCE_DIALOG_ALERT_TEXT:
    "Select the data source you want to use with this report. Changing it will reset the report to a blank state as well as update the report metrics.",
  SORT_BY: "Sort by",
  CHART_EXPORT_FAILED: "Failed to export chart",
  ALL_SECTIONS: "All sections",
  INCLUDE_SUBTOTALS_LABEL: "Include subtotals",
  INCLUDE_SUBTOTALS_TOOLTIP: "Subtotals can only be shown in tabular formats (Table or Heatmap views)",
  SUBTOTALS_ASC: "Subtotals ascending",
  SUBTOTALS_DESC: "Subtotals descending",
  ADD_TO_MY_REPORTS: "Add to my reports",
  REMOVE_FROM_MY_REPORTS: "Remove from my reports",
  ADDED_TO_MY_REPORTS: "Added to my reports",
  REMOVED_FROM_MY_REPORTS: "Removed from my reports",
  ADD_TO_MY_REPORTS_CUSTOMER_TOOLTIP:
    "Templates are managed and updated by DoiT.\n\n" +
    "To add this template to your reports and get updates automatically, you must revert all changes.\n\n" +
    "To keep the changes you made, save it as a custom report. Custom reports are not updated.",
  ADD_TO_MY_REPORTS_DOER_SUBMIT_TOOLTIP:
    "You need to submit this template for approval before adding it to your reports",
  ADD_TO_MY_REPORTS_DOER_SAVE_TOOLTIP: "You need to save this template before adding it to your reports",
  DELETE: "Delete",
  APPROVE_AND_PUBLISH: "Approve and publish",
  SAVE_CHANGES: "Save changes",
  REJECT: "Reject",
  REPORT_CACHE_RUN_REPORT_BTN_TOOLTIP: "Cached reports cannot be run in real time, click on refresh to update the data",
  REPORT_CACHE_SNACKBAR_TEXT:
    "Started creating your report, this process can take a few minutes, please come back soon",
  REPORT_CACHE_REFRESH_BTN_TOOLTIP: "You report is caching at the moment, this can take up to 30 minutes",
  REPORT_CACHE_REFRESH_UNSAVED: "Cached reports can only be refreshed once changes are saved",
  CACHED: "Cached",
  MANAGED_REPORT_TOOLTIP:
    "This report is routinely updated and managed by DoiT to ensure you have access to the latest report configurations.",
  REMOVE: "Remove",
  REPORT_REMOVED_SUCCESSFULLY: "Report removed successfully",
  REPORT_REMOVE_ERROR: "Error removing report",
  REPORT_DISABLE_CHARTS:
    "This report configuration can have degraded performance in a chart view due to the amount of data points. Limiting the results will allow you to use charts.",
  LOADING: "Loading",
  STOP_GENERATING_REPORT: "Stop generating report",
};

export const reportExportTxt = {
  ACTUAL: "Actual",
  VALUE: "Value",
};

export const dataSourceDialogTxt = {
  BILLING_DATA_DESC:
    "Includes billing data from your cloud providers (Google, AWS and Azure), which can be delayed by up to 72 hours.",
  BIG_QUERY_DESC:
    "This data is available instantly but there might be a delay of up to 24 hours until new dimension values and labels can be used in Reports. Queries using this data source incur costs.",
  LEARN_MORE: "Learn more",
  CUSTOMER_FEATURES_DESC:
    "Aggregations at customer level for total anomaly counts per day and total cost of those anomalies per day.",
};
