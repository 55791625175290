import { useMemo } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";
import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { reportsHelpURLs } from "../../../assets/urls";
import { generateOptionsForChart } from "../../../Pages/Flexsave/Resold/Overview/Chart/SavingsChartUtils";
import { type FlexsaveData } from "../../../Pages/Flexsave/types";
import { useCurrency } from "../../hooks/useCurrency";
import { MenuWidgetCard } from "../Common/MenuWidgetCard";
import { WidgetCard } from "../Common/WidgetCard";
import { getFlexsaveWidgetTitle } from "./utils";

type Props = {
  type: FlexsaveType;
  flexSaveLink: string;
  data: FlexsaveData;
};

export const FlexsaveCardEnabled = ({ type, flexSaveLink, data }: Props) => {
  const theme = useTheme();
  const { customerCurrencySymbol } = useCurrency();

  const options = useMemo(
    () => generateOptionsForChart(data, theme, customerCurrencySymbol),
    [data, theme, customerCurrencySymbol]
  );

  const HeaderAction = <MenuWidgetCard helpUrl={reportsHelpURLs[`FLEXSAVE_${type}`]} reportPath={flexSaveLink} />;
  return (
    <WidgetCard
      title={getFlexsaveWidgetTitle(type)}
      subheader="Summary of your savings with Flexsave"
      headerAction={HeaderAction}
      widgetHeight={1.5}
    >
      <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
    </WidgetCard>
  );
};
