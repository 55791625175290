import { useEffect, useMemo } from "react";

import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { IconButton, Typography } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../../Components/Loader";
import { filters, headerColumns } from "./columns";
import { useJobs as _useJobs } from "./hooks";
import IssueForm from "./IssueForm";
import JobListRow from "./JobListRow";
import { type JobTableRow } from "./types";
import { formatJob } from "./utils";

let timeOut;
const numMs = 5 * 60 * 1000;

const BillingTools = ({ useJobs = _useJobs }) => {
  const { jobs, loading, loadJobs } = useJobs();

  useEffect(() => {
    const startTimeout = () => {
      clearTimeout(timeOut);
      loadJobs();
      timeOut = setTimeout(() => startTimeout(), numMs);
    };

    startTimeout();

    return () => {
      clearTimeout(timeOut);
    };
  }, [loadJobs]);

  const formattedJobs = useMemo(() => jobs.map(formatJob), [jobs]);

  return (
    <>
      <Typography variant="h1" data-cy="page-title" mt={2}>
        Billing Tools
        <IconButton onClick={() => loadJobs()} disabled={loading} color="primary" sx={{ ml: 2 }}>
          <RefreshIcon />
          <Typography fontWeight={500} sx={{ ml: 1 }}>
            Refresh
          </Typography>
        </IconButton>
      </Typography>
      <IssueForm loadJobs={loadJobs} />
      <Loader loading={loading}>
        <FilterTable<JobTableRow>
          showRowsSelection={false}
          tableItems={formattedJobs}
          wrapRowComponent={true}
          headerColumns={headerColumns}
          rowComponent={JobListRow}
          filterColumns={filters}
        />
      </Loader>
    </>
  );
};

export default BillingTools;
