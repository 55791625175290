import { type CurrencySymbol } from "@doitintl/cmp-models";

import { type Insight } from "../Insights/types";
import { totalCustomProblems, totalPotentialDailySavings } from "../Insights/utils";

type ImpactType = {
  value: number;
  units: string;
  isCurrency: boolean;
};

export const getImpactAndUnits = async (
  insight: Insight | undefined,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>,
  currencySymbol: CurrencySymbol
): Promise<ImpactType> => {
  let potentialMonthlySavings: number = 0;
  let customProblems: number | null = null;

  if (!insight) {
    return { value: 0.0, units: "", isCurrency: false };
  }

  const potentialDailySavings = totalPotentialDailySavings(insight);

  if (potentialDailySavings) {
    potentialMonthlySavings = potentialDailySavings * 30.4;
  }

  customProblems = totalCustomProblems(insight);

  let value: number;
  let units: string;
  let isCurrency = false;

  if (customProblems !== null) {
    value = customProblems;
    units = insight.results?.customProblems?.unitLongPlural ?? "";
  } else {
    value = await asyncCurrencyConvert(potentialMonthlySavings);
    units = currencySymbol;
    isCurrency = true;
  }

  return { value, units, isCurrency };
};

export function createImpactFormatter(impactUnits: string, isCurrency: boolean): (impact: number) => string {
  return (impact: number) => {
    if (isCurrency) {
      return `${impactUnits}${impact.toFixed(2)} monthly savings`;
    }
    return `${impact} ${impactUnits}`;
  };
}

export function formatDate(input: string | undefined): string {
  if (input === undefined || input === "") {
    return "n/a";
  }

  const date = new Date(input);

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", dateOptions).format(date);

  const includesTime = input.includes("T") && input.includes(":");

  if (includesTime) {
    const formattedTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(date);
    return `${formattedDate}, ${formattedTime}`;
  } else {
    return formattedDate;
  }
}
