import { useEffect, useMemo, useState } from "react";

import {
  CustomerModel,
  type CustomRoleModel,
  PermissionModel,
  type PresetRoleModel,
  RoleModel,
} from "@doitintl/cmp-models";
import { getCollection, type QueryDocumentSnapshotModel, type WithFirebaseModel } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { RoleCustomerType, type RoleWithPermissions } from "../../../types";
import { PresetRoles } from "../../../utils/permissions";

const prepareRoles = (
  rolesDocs: (QueryDocumentSnapshotModel<CustomRoleModel> | QueryDocumentSnapshotModel<PresetRoleModel>)[],
  allPermissions: Record<string, WithFirebaseModel<PermissionModel>>
): RoleWithPermissions[] =>
  rolesDocs
    .filter((roleDoc) => roleDoc.id !== PresetRoles.PARTNER_ACCOUNT_MANAGER_REF.id)
    .map((roleDoc) => {
      const data = roleDoc.asModelData();
      const permissions = data.permissions.map((per) => ({ id: per.id, ref: per, ...allPermissions[per.id] }));
      return {
        ...data,
        id: roleDoc.id,
        ref: roleDoc.modelRef,
        permissions,
      };
    });

export const useRoles = () => {
  const [presetRoles, setPresetRoles] = useState<RoleWithPermissions[]>();
  const [customerRoles, setCustomerRoles] = useState<RoleWithPermissions[]>();
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const [allPermissions, setAllPermissions] = useState<Record<string, WithFirebaseModel<PermissionModel>>>({});

  const customerRef = useMemo(() => getCollection(CustomerModel).doc(customer.id), [customer.id]);

  useEffect(
    () =>
      getCollection(PermissionModel).onSnapshot((permissions) => {
        setAllPermissions(
          permissions.docs.reduce(
            (acc, permission) => {
              acc[permission.id] = permission.asModelData();
              return acc;
            },
            {} as Record<string, WithFirebaseModel<PermissionModel>>
          )
        );
      }),
    []
  );

  useEffect(() => {
    let presetRolesQuery = getCollection(RoleModel)
      .narrow<PresetRoleModel>()
      .where("type", "==", "preset")
      .where("customer", "==", null);

    if (!isProductOnlyCustomer) {
      presetRolesQuery = presetRolesQuery.where("customerType", "!=", RoleCustomerType.STANDALONE);
    }

    return presetRolesQuery.onSnapshot((presetRolesSnapshot) => {
      setPresetRoles(prepareRoles(presetRolesSnapshot.docs, allPermissions));
    });
  }, [isProductOnlyCustomer, allPermissions]);

  useEffect(() => {
    let customerRolesRef = getCollection(RoleModel).narrow<CustomRoleModel>().where("customer", "==", customerRef);

    if (isProductOnlyCustomer) {
      customerRolesRef = customerRolesRef.where("customerType", "==", RoleCustomerType.STANDALONE);
    }

    return customerRolesRef.onSnapshot((customerRolesSnapshot) => {
      setCustomerRoles(prepareRoles(customerRolesSnapshot.docs, allPermissions));
    });
  }, [customerRef, isProductOnlyCustomer, allPermissions]);

  const roles = useMemo(() => [...(customerRoles ?? []), ...(presetRoles ?? [])], [presetRoles, customerRoles]);
  const loadingRoles = customerRoles === undefined || presetRoles === undefined;

  return { roles, loadingRoles };
};
