import { type ParsedQs } from "qs";

import { type UtmParams } from "./types";

const DEFAULT_SESSION_STORAGE_KEY = "utm_params";
const UTM_PARAMS = new Set(["utm_campaign", "utm_source", "utm_medium", "utm_term", "utm_content"]);

/**
 * Get and clean UTM parameters from Session Storage. If no key provided, used "utm_params"
 */
export const getUtmParams = (key = DEFAULT_SESSION_STORAGE_KEY): UtmParams => {
  const utmParams = sessionStorage.getItem(key);
  if (utmParams) {
    sessionStorage.removeItem(key);
    return JSON.parse(utmParams);
  }
  return {};
};

/**
 * Save stringified UTM parameters to Session Storage. If no key provided, used "utm_params"
 */
export const setUtmParams = (utmParams: UtmParams, key = DEFAULT_SESSION_STORAGE_KEY): void =>
  sessionStorage.setItem(key, JSON.stringify(utmParams));

/**
 * Create object that contains UTM parameters
 */
export const formatUtm = (qs: ParsedQs): UtmParams =>
  Object.entries(qs).reduce((acc, [key, value]) => {
    if (UTM_PARAMS.has(key) && value) {
      acc[key] = value;
    }
    return acc;
  }, {});
