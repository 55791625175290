import { useTranslation } from "react-i18next";
import { type KnownIssuePlatform, type SupportServiceModel, type ZendeskPlatformModel } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import Sauron from "../../../Components/Sauron";
import KnownIssuesList from "../KnownIssuesList";
import SelectCategory from "../SelectCategory";
import { ContentHeader } from "../Stepper/ContentHeader";
import SupportPlatformDropdown from "../SupportPlatformDropdown";

type ProductStepProps = {
  handleChangePlatform: (data: React.ChangeEvent<Element>) => void;
  platforms: ZendeskPlatformModel[];
  sauronRole: boolean;
  categories: SupportServiceModel[];
  platform: string;
  error: boolean;
  helperText: string;
  handleChangeCategory: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedPlatform: ZendeskPlatformModel;
  category: string;
  changeCategoryInfo: (event: React.ChangeEvent<{ value: unknown }>) => void;
  categoryInfo: string;
  categoryInfoList: { asset: string }[];
  knownIssues: { platform: KnownIssuePlatform; title: string; docId: string }[];
};

const ProductStep = ({
  handleChangePlatform,
  platforms,
  sauronRole,
  categories,
  platform,
  error,
  helperText,
  handleChangeCategory,
  selectedPlatform,
  category,
  changeCategoryInfo,
  categoryInfo,
  categoryInfoList,
  knownIssues,
}: ProductStepProps) => {
  const { t } = useTranslation("services");
  return (
    <Box sx={{ pl: 1 }}>
      <ContentHeader title={t("ProductStep.title")} />
      <Box sx={{ mt: 4 }}>
        <SupportPlatformDropdown
          platform={platform}
          platformsList={platforms}
          handleChangePlatformField={handleChangePlatform}
          error={error && !platform}
        />
        <SelectCategory
          helperText={helperText}
          handleChangeCategory={handleChangeCategory}
          platforms={platforms}
          selectedPlatform={selectedPlatform}
          category={category ? category : null}
          categories={categories}
          changeCategoryInfo={changeCategoryInfo}
          categoryInfo={categoryInfo}
          error={error}
          categoryInfoList={categoryInfoList}
        />
        <Box mt={5}>
          <KnownIssuesList platform={platform} issues={knownIssues} />
        </Box>
        {selectedPlatform?.value === "amazon_web_services" && (
          <Sauron accountId={categoryInfo} sauronRole={sauronRole}>
            <SelectCategory
              helperText={helperText}
              handleChangeCategory={handleChangeCategory}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              category={category ? category : null}
              categories={categories}
              changeCategoryInfo={changeCategoryInfo}
              categoryInfo={categoryInfo}
              categoryInfoList={categoryInfoList}
              step={2}
            />
          </Sauron>
        )}
      </Box>
    </Box>
  );
};

export default ProductStep;
