import { type JSX } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { EKSLensEnticement } from "./EKSLensEnticement";

type Props = {
  children: JSX.Element;
};

export const EKSLensGuard = ({ children }: Props) => {
  const { customer } = useCustomerContext();
  const { isFeatureEntitled } = useTier();
  const isEntitled = isFeatureEntitled("lens:eks");

  if (customer.presentationMode?.enabled || customer.presentationMode?.isPredefined) {
    return children;
  }

  if (isEntitled) {
    return children;
  }

  return <EKSLensEnticement state={isEntitled ? "activate" : "upgrade"} />;
};
