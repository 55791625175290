import { useEffect, useMemo, useState } from "react";

import { type PerkPlatform, type PerkSolutionType } from "@doitintl/cmp-models";
import { Box, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

import { type PerksWData } from "../../types";
import { generateChipList, groupPerks, isPerkPlatform, toBeautifiedName } from "./helpers";

const styles = {
  filterTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "text.primary",
    lineHeight: "24px",
    "&.Mui-focused": {
      color: "text.primary",
    },
    paddingBottom: 1,
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  formControlLabel: {
    whiteSpace: "nowrap",
  },
  formGroup: {
    whiteSpace: "nowrap",
  },
  chipBox: {
    display: "flex",
    width: "200px",
    justifyContent: "left",
    flexWrap: "wrap",
    gap: "5px",
    "& > *": {
      flex: "0 0 auto",
    },
  },
};

type Props = {
  perks: PerksWData[];
  setFilteredPerks: (perks: PerksWData[]) => void;
};

export const PerkFilter = ({ perks, setFilteredPerks }: Props) => {
  const [solutionFilter, setSolutionFilter] = useState<Record<PerkSolutionType, boolean>>(
    {} as Record<PerkSolutionType, boolean>
  );
  const [platformFilter, setPlatformFilter] = useState<Record<PerkPlatform, boolean>>(
    {} as Record<PerkPlatform, boolean>
  );

  const groupedPerks = useMemo(() => groupPerks(perks), [perks]);
  const solutionTouched = useMemo(() => Object.values(solutionFilter).some((o) => o), [solutionFilter]);
  const platformTouched = useMemo(() => Object.values(platformFilter).some((o) => o), [platformFilter]);

  useEffect(() => {
    if (!Object.keys(solutionFilter).length && !Object.keys(platformFilter).length) {
      return;
    }

    if (!solutionTouched && !platformTouched) {
      setFilteredPerks([]);
    }

    const foundPerks = perks.filter((p) => {
      const solutionContained = p.data.fields.solutionType?.some((elem) => solutionFilter[elem]);
      const cloudContained = p.data.fields.platform.some((elem) => platformFilter[elem]);

      if (solutionTouched && platformTouched) {
        return solutionContained && cloudContained;
      }
      return solutionContained || cloudContained;
    });

    setFilteredPerks(foundPerks);
  }, [solutionFilter, platformFilter, perks, solutionTouched, platformTouched, setFilteredPerks]);

  const handleCheckboxChange = (checked: boolean, key: PerkSolutionType | PerkPlatform) => {
    if (isPerkPlatform(key)) {
      setPlatformFilter({
        ...platformFilter,
        [key]: checked,
      });
      return;
    }
    setSolutionFilter({
      ...solutionFilter,
      [key]: checked,
    });
  };

  const generateFilterCheckboxes = <T extends PerkPlatform | PerkSolutionType>(
    entries: Record<T, PerksWData[]>,
    filter: Record<T, boolean>,
    filterTitle: string
  ) => {
    const entryList = Object.entries(entries);
    return (
      entryList.length > 0 && (
        <FormControl component="fieldset" variant="standard" data-testid="perk-solution-filter">
          <FormLabel component="legend" sx={styles.filterTitle}>
            {filterTitle}
          </FormLabel>
          <FormGroup sx={styles.formGroup}>
            {entryList.map(([key, value]) => (
              <FormControlLabel
                sx={styles.formControlLabel}
                key={key}
                control={
                  <Checkbox
                    data-testid={`${key}-perk-checkbox`}
                    checked={!!filter[key]}
                    onChange={(e) => handleCheckboxChange(e.target.checked, key as PerkSolutionType)}
                    sx={styles.checkbox}
                  />
                }
                label={`${toBeautifiedName(key)} (${(value as PerksWData[]).length})`}
              />
            ))}
          </FormGroup>
        </FormControl>
      )
    );
  };

  return (
    <Stack direction="column" gap={4} data-testid="perk-filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={styles.filterTitle}>
          Applied Filters
        </FormLabel>
        <FormGroup sx={styles.formGroup}>
          {!solutionTouched && !platformTouched && (
            <Typography variant="body2" component="span" color="text.secondary">
              No filters applied
            </Typography>
          )}
          <Box sx={styles.chipBox}>
            {generateChipList<PerkSolutionType>(
              solutionTouched,
              "Solution types",
              solutionFilter,
              handleCheckboxChange
            )}
            {generateChipList<PerkPlatform>(platformTouched, "Platform types", platformFilter, handleCheckboxChange)}
          </Box>
        </FormGroup>
      </FormControl>

      {generateFilterCheckboxes<PerkSolutionType>(groupedPerks.solution, solutionFilter, "Solution types")}
      {generateFilterCheckboxes<PerkPlatform>(groupedPerks.platform, platformFilter, "Cloud Marketplace")}
    </Stack>
  );
};
