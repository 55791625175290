import { useCallback, useMemo, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { type TierModelWithId } from "../types";
import { filters, headerColumns } from "./columns";
import { CreateTierDialog } from "./CreateTierDialog";
import TierRowItem from "./TierRowItem";

type Props = {
  tiers?: TierModelWithId[];
  loading: boolean;
};

const TiersTable = ({ tiers, loading }: Props) => {
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const [open, setOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<TierModelWithId | null>(null);

  const validTiers = useMemo(() => tiers?.filter((tier: TierModelWithId) => tier.name && tier.sku), [tiers]);

  const editTierDetails = useCallback((tier: TierModelWithId) => {
    setSelectedTier(tier);
    setOpen(true);
  }, []);

  const handleCloseTierDialog = useCallback(() => {
    setSelectedTier(null);
    setOpen(false);
  }, []);

  const TiersRowWrapper = useCallback(
    (props: { row: TierModelWithId }) => <TierRowItem row={props.row} onEditTierDetails={editTierDetails} />,
    [editTierDetails]
  );

  if (loading) {
    return <FilterTableSkeleton />;
  }

  return (
    <>
      <FilterTable<TierModelWithId>
        toolbarProps={{
          title: "Tiers",
          allowToEditColumns: true,
          primaryButton: {
            text: "Create new tier",
            onClick: () => setOpen(true),
            color: "primary",
            disabled: !isDoitOwnerRole,
          },
        }}
        defaultSortingColumnValue="packageType"
        defaultSortDirection="asc"
        tableItems={validTiers}
        showRowsSelection={false}
        rowComponent={TiersRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        persistenceKey="tiers"
      />
      {open && <CreateTierDialog handleClose={handleCloseTierDialog} open={open} selectedTier={selectedTier} />}
    </>
  );
};

export default TiersTable;
