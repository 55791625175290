import { type ChangeEvent, memo, useCallback } from "react";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import { authProviderTexts } from "../../../../assets/texts";
import { AuthProviderTypeLabels } from "../helpers";
import { type AllowedProvider, type AuthSettingsActionType, type AuthSettingsType } from "../types";
import { AUTH_DATA_TEST_IDS } from "./testIds";

export type AuthProviderProps = {
  allowedProvider: AuthSettingsType["allowedProvider"];
  dispatch: React.Dispatch<AuthSettingsActionType>;
};

function _AuthProvider({ allowedProvider, dispatch }: AuthProviderProps) {
  const onAuthProviderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch({ type: "SET_ALLOWED_PROVIDER", payload: event.target.value as AllowedProvider });
      }
    },
    [dispatch]
  );

  return (
    <FormControl style={{ marginBottom: 10 }}>
      <Stack direction="row" spacing={1.5}>
        <Typography variant="h4" sx={{ mb: 1.5, mt: -0.6 }}>
          {authProviderTexts.TITLE}
        </Typography>
        <Tooltip
          title={authProviderTexts.TOOLTIP}
          placement="right"
          arrow={true}
          PopperProps={{ sx: { maxWidth: 180 } }}
        >
          <InfoIcon fontSize="inherit" />
        </Tooltip>
      </Stack>
      <RadioGroup
        aria-labelledby="select-auth-provider-radio-buttons-group-label"
        value={allowedProvider}
        name="select-auth-provider-radio-buttons-group"
      >
        {Object.keys(AuthProviderTypeLabels)
          .sort((a, b) => AuthProviderTypeLabels[a].localeCompare(AuthProviderTypeLabels[b])) // sorting alphabetically according to label
          .map((provider) => (
            <FormControlLabel
              sx={{ position: "relative", left: 10, width: "fit-content" }}
              key={provider}
              value={provider}
              control={
                <Radio
                  onChange={onAuthProviderChange}
                  size="small"
                  data-testid={`${AUTH_DATA_TEST_IDS.AUTH_PROVIDER.RADIO_BUTTON}-${provider}`}
                />
              }
              label={AuthProviderTypeLabels[provider]}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
}

export const AuthProvider = memo(_AuthProvider);
