import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import APIParametersTab from "./APIParametersTab";
import { ConditionParametersTab } from "./ConditionParametersTab";
import PermissionsTab from "./PermissionsTab";
import TestsTab from "./TestsTab";
import type { NodeConfigs } from "../../../types";

const parameterTabContent = {
  [CloudFlowNodeType.ACTION]: APIParametersTab,
  [CloudFlowNodeType.CONDITION]: ConditionParametersTab,
};

export const TabContent = () => {
  const { activeTab, nodeConfig } = useNodeConfigurationContext();

  switch (activeTab) {
    case "Parameters": {
      const ParameterComponentByType = parameterTabContent[nodeConfig.type];

      if (!ParameterComponentByType) return null;

      return <ParameterComponentByType />;
    }
    case "Permissions":
      return <PermissionsTab nodeConfig={nodeConfig as NodeConfigs<CloudFlowNodeType.ACTION>} />;
    case "Test":
      return <TestsTab />;
    default:
      return null;
  }
};
