import { v4 as uuidv4 } from "uuid";

export const startStepNodeId = uuidv4();
export const actionStepNodeId = uuidv4();
export const ghostNodeId = uuidv4();

export const initialNodes = [
  {
    id: startStepNodeId,
    type: "startStep",
    position: { x: 0, y: 0 },
    data: { name: "What should start your flow?" },
  },
  {
    id: actionStepNodeId,
    type: "actionStep",
    position: { x: 0, y: 275 },
    data: {
      name: "What do you want to do?",
      position: { x: 0, y: 275 },
    },
  },
  {
    id: ghostNodeId,
    type: "ghost",
    position: { x: 0, y: 575 },
    data: {},
  },
];
