import { useEffect, useMemo, useRef } from "react";

import { Aggregator, type BudgetAlert, type CurrencyCode, Metric } from "@doitintl/cmp-models";
import { Card, CardHeader, Grid, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import bullet from "highcharts/modules/bullet";
import HighchartsReact from "highcharts-react-official";
import cloneDeep from "lodash/cloneDeep";

import useFormatter from "../../../Components/hooks/useFormatter";
import { getCurrencySymbol } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";

// Init highcharts bullet module
bullet(Highcharts);

type Props = {
  utilization?: number;
  forecasted?: number;
  loading: boolean;
  alerts: BudgetAlert[];
  amount: number;
  currency: CurrencyCode;
  metric: number;
};

const BudgetUtilization = ({ utilization, forecasted, loading, alerts, amount, currency, metric }: Props) => {
  const theme = useTheme();
  const { isMobile: smDown } = useFullScreen();
  const actualChartRef = useRef<any | null>(null);
  const forecastChartRef = useRef<any | null>(null);
  const sortedAlerts = useMemo(() => cloneDeep(alerts).sort((a, b) => a.percentage - b.percentage), [alerts]);
  const currencySymbol = useMemo(() => (metric === Metric.COST ? getCurrencySymbol(currency) : ""), [currency, metric]);
  const formatter = useFormatter(Aggregator.TOTAL, currency, Metric.COST);

  function tooltipFormatter(seriesName: string) {
    function formatterWrap(this: any) {
      if (!this.point.y) {
        return "N/A";
      }
      return `<span>${seriesName} spending: ${currencySymbol}${(
        Math.round(this.point.y * 100) / 100
      ).toLocaleString()} ${
        this.point.target ? `</span></br> Budget amount: ${currencySymbol}${this.point.target.toLocaleString()}` : ""
      }`;
    }

    return formatterWrap;
  }

  const seriesDataProps = {
    color: "#64B5F6",
    target: amount === 0 ? undefined : amount,
  };
  const baseOptions = {
    chart: {
      inverted: true,
      type: "bullet",
      marginLeft: 20,
      backgroundColor: theme?.palette?.background.paper,
      spacingLeft: 0,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [""],
      labels: {
        align: "left",
        reserveSpace: true,
        useHTML: true,
        style: {
          ...theme.typography.caption,
          color: theme?.palette?.text?.primary,
          fontSize: smDown ? "14px" : "16px",
        },
      },
    },
    yAxis: {
      endOnTick: false,
      maxPadding: 0,
      gridLineWidth: 0,
      labels: {
        enabled: true,
        formatter(this: any) {
          return formatter(this.value, true);
        },
        style: {
          color: theme?.palette?.text?.primary,
        },
      },
      plotBands: amount
        ? [
            {
              from: 0,
              to: (sortedAlerts[0].percentage * amount) / 100,
              color: "#a5d6a7",
            },
            {
              from: (sortedAlerts[0].percentage * amount) / 100,
              to: (sortedAlerts[1].percentage * amount) / 100,
              color: "#fff59d",
            },
            {
              from: (sortedAlerts[1].percentage * amount) / 100,
              to: (sortedAlerts[2].percentage * amount) / 100,
              color: "#ef9a9a",
            },
          ]
        : [],
      title: null,
    },
    plotOptions: {
      series: {
        pointPadding: 0.25,
        borderWidth: 0,
        color: "#000",
        targetOptions: {
          width: "200%",
        },
        animation: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };
  const actualOptions = {
    ...baseOptions,
    series: [
      {
        type: "bullet",
        data: [
          {
            ...seriesDataProps,
            y: utilization ? utilization : 0,
          },
        ],
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: tooltipFormatter("Current"),
    },
  };
  const forecastedOptions = {
    ...baseOptions,
    series: [
      {
        type: "bullet",
        data: [
          {
            ...seriesDataProps,
            y: forecasted ? forecasted : 0,
          },
        ],
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: tooltipFormatter("Forecasted"),
    },
  };

  useEffect(() => {
    actualChartRef?.current?.chart.reflow();
    forecastChartRef?.current?.chart.reflow();
  }, [smDown]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Current Spend" titleTypographyProps={{ variant: "subtitle2" }} />
          {loading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={56}
              width="96%"
              style={{
                margin: "10px 10px 33px",
              }}
            />
          ) : (
            <HighchartsReact
              ref={actualChartRef}
              containerProps={{ style: { height: smDown ? "80px" : "100px" } }}
              highcharts={Highcharts}
              allowChartUpdate={true}
              options={actualOptions}
            />
          )}
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Forecasted Spend" titleTypographyProps={{ variant: "subtitle2" }} />
          {loading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={56}
              width="96%"
              style={{
                margin: "10px 10px 33px",
              }}
            />
          ) : (
            <HighchartsReact
              ref={forecastChartRef}
              containerProps={{ style: { height: smDown ? "80px" : "100px" } }}
              highcharts={Highcharts}
              allowChartUpdate={true}
              options={forecastedOptions}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BudgetUtilization;
