import { useEffect } from "react";
import { Provider } from "react-redux";
import { getCollection } from "@doitintl/models-firestore";
import { KnownIssueModel } from "@doitintl/cmp-models";
import { useCustomerContext } from "../Context/CustomerContext";
import { useHotkeyContext } from "../Context/HotkeyContext";
import { useApiContext } from "../api/context";
import { useUserContext } from "../Context/UserContext";
import configureStore from "./store/configureStore";
import TicketsContainer from "./Containers/TicketsContainer";
import NewTicketContainer from "./Containers/NewTicketContainer";
import TicketDetailsContainer from "./Containers/TicketDetailsContainer";
import { setSagaAPI, setSagaCustomer } from "./sagas";
import { initTicketList, loadKnownIssue } from "./Actions/TicketAction";

const store = configureStore(window.__INITIAL_STATE__);

const Support = (props) => {
  const { disable: hotkeyDisable, enable: hotkeyEnable } = useHotkeyContext();
  const api = useApiContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { customer } = useCustomerContext();

  useEffect(() => {
    if (store.getState().tickets?.contextUser.id !== customer.id) {
      store.dispatch(initTicketList());
    }
  }, [customer.id]);

  setSagaAPI(api);

  useEffect(() => {
    if (!userRoles.issuesViewer) {
      store.dispatch(loadKnownIssue([]));
      return;
    }
    return getCollection(KnownIssueModel)
      .where("status", "==", "ongoing")
      .onSnapshot((issues) => {
        const knownIssuesArr = issues.docs.map((issue) => ({
          ...issue.asModelData(),
          docId: issue.id,
        }));
        store.dispatch(loadKnownIssue(knownIssuesArr));
      });
  }, [userRoles.issuesViewer]);

  // Disable SHIFT+/ hotkey in support page
  useEffect(() => {
    hotkeyDisable();
    return () => {
      hotkeyEnable();
    };
  }, [hotkeyDisable, hotkeyEnable]);

  setSagaCustomer(customer);

  return (
    <>
      <Provider {...props} store={store}>
        {props.pageId === "support" && <TicketsContainer {...props} />}

        {["support:new", "support:followup"].includes(props.pageId) && <NewTicketContainer {...props} />}

        {props.pageId === "support:tickets" && <TicketDetailsContainer {...props} />}
      </Provider>
    </>
  );
};

export default Support;
