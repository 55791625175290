import { useCallback, useEffect, useMemo, useState } from "react";

import { BillingModel, EntityModel, InvoiceModel, type InvoiceProduct } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import some from "lodash/some";
import { DateTime } from "luxon";

import { useLocalStorage } from "../../Components/FilterTable/hook";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type Customer } from "../../types";
import {
  asyncMapEntityInvoicesToInvoice,
  type Invoice,
  type InvoiceFilters,
  type InvoiceStatus,
  type SetFilters,
} from "./types";
import { getInvoiceStatus } from "./utils";

const today = DateTime.utc().startOf("day");

const defaultInitialFilters: InvoiceFilters = {
  showPaid: true,
  pastPayDate: false,
  products: [],
  startDate: today.startOf("year").minus({ year: 1 }),
  endDate: today.startOf("year"),
  status: ["Paid", "Partially Paid", "Open", "Past Due", "Processing"],
};

const getIssuedInvoices = async (customer: Customer, filters: InvoiceFilters): Promise<Invoice[]> => {
  const startDate = filters.startDate.startOf("year");
  const endDate = filters.endDate.endOf("year");

  let queryInvoices = getCollection(InvoiceModel)
    .orderBy("IVDATE", "desc")
    .where("IVDATE", ">", startDate.toJSDate())
    .where("IVDATE", "<", endDate.toJSDate())
    .where("customer", "==", customer.ref);

  if (!filters.showPaid) {
    queryInvoices = queryInvoices.where("PAID", "==", false);
  }

  if (filters.entityId) {
    queryInvoices = queryInvoices.where("entity", "==", getCollection(EntityModel).doc(filters.entityId));
  }

  const querySnapshot = await queryInvoices.get();

  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.asModelData(), isDraft: false }));
};

export const getDraftInvoicesMonth = (): string => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;

  if (today.getDate() < 11) {
    // If current day is less than 11, get the previous month
    if (month === 1) {
      // If it's January, go back to December of previous year
      year -= 1;
      month = 12;
    } else {
      month -= 1;
    }
  }

  // Pad month with leading zero if necessary
  let monthString = month.toString();
  if (month < 10) {
    monthString = `0${month}`;
  }

  return `${year}-${monthString}`;
};

export const getDraftInvoices = async (customer: Customer, filters?: InvoiceFilters): Promise<Invoice[]> => {
  const startDate = filters?.startDate.startOf("year");
  const endDate = filters?.endDate.endOf("year");
  const proformaInvoicesDate = DateTime.fromFormat(getDraftInvoicesMonth(), "yyyy-MM");
  if (filters && (startDate! > proformaInvoicesDate || endDate! < proformaInvoicesDate)) {
    return [];
  }

  let customerEntitiesQuery = getCollection(BillingModel)
    .doc("invoicing")
    .collection("invoicingMonths")
    .doc(getDraftInvoicesMonth())
    .collection("monthInvoices")
    .where("customer", "==", customer.ref);

  if (filters?.entityId) {
    customerEntitiesQuery = customerEntitiesQuery.where(
      "entity",
      "==",
      getCollection(EntityModel).doc(filters.entityId)
    );
  }

  const customerEntities = await customerEntitiesQuery.get();

  const allInvoices: Invoice[] = [];

  await Promise.all(
    customerEntities.docs.map(async (entityInvoices) => {
      const invoices = await entityInvoices.modelRef.collection("entityInvoices").orderBy("timestamp", "desc").get();

      if (invoices.empty) {
        return [];
      }
      const maxDate = invoices.docs[0].asModelData().timestamp;
      const entity = await entityInvoices.asModelData().entity.get();

      const entityData = entity.asModelData();

      if (!entityData) {
        return [];
      }

      const invoicesFiltered = await Promise.all(
        invoices.docs
          .filter((doc) => doc.asModelData().timestamp.isEqual(maxDate))
          .map(
            (doc, _): Promise<Invoice> =>
              asyncMapEntityInvoicesToInvoice(doc.asModelData(), entity.modelRef, entityData)
          )
      );

      allInvoices.push(...invoicesFiltered);
    })
  );

  return allInvoices;
};

const filterDraftInvoices = (draftInvoices: Invoice[], issuedInvoices: Invoice[]): Invoice[] => {
  const lastIssuedInvoices = issuedInvoices.slice(0, draftInvoices.length);

  return draftInvoices.filter(
    (draftInvoice) =>
      !lastIssuedInvoices.some(
        (issuedInvoice) =>
          issuedInvoice.IVDATE.isEqual(draftInvoice.IVDATE) &&
          issuedInvoice.CDES === draftInvoice.CDES &&
          issuedInvoice.PRODUCTS?.includes(draftInvoice.PRODUCTS?.[0])
      )
  );
};

export const useInvoices = (initialFilters?: Partial<InvoiceFilters>) => {
  const { customer } = useCustomerContext();
  const [storedStatus, setStoredStatus] = useLocalStorage<InvoiceStatus[]>(
    "invoice_status",
    defaultInitialFilters.status
  );
  const [filtersState, setFiltersState] = useState<InvoiceFilters>({
    ...defaultInitialFilters,
    ...initialFilters,
    status: storedStatus,
  });
  const [invoicesBeforeFilters, setInvoicesBeforeFilters] = useState<Invoice[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [invoicesProducts, setInvoicesProducts] = useState<InvoiceProduct[]>();
  const setFilters: SetFilters = useCallback(
    (filter: Partial<InvoiceFilters> | null) => {
      if (filter === null) {
        setFiltersState({
          ...defaultInitialFilters,
          ...initialFilters,
        });
        return;
      }

      setFiltersState((prevFilters) => ({ ...prevFilters, ...filter, ...initialFilters }));
    },
    [initialFilters]
  );

  useEffect(() => {
    setStoredStatus(filtersState.status);
  }, [filtersState.status, setStoredStatus]);

  useEffect(() => {
    setIsFetching(true);
    setInvoicesBeforeFilters([]);

    Promise.all([getDraftInvoices(customer, filtersState), getIssuedInvoices(customer, filtersState)]).then(
      ([draftInvoices, issuedInvoices]) => {
        const filteredDraftInvoices = filterDraftInvoices(draftInvoices, issuedInvoices);

        setInvoicesBeforeFilters([...filteredDraftInvoices, ...issuedInvoices]);
        setIsFetching(false);
      }
    );
  }, [customer, customer.id, customer.ref, filtersState]);

  useEffect(() => {
    setInvoices(() => {
      let moreFilteredResults =
        filtersState.products.length > 0
          ? invoicesBeforeFilters.filter((invoice) => some(invoice.PRODUCTS, (p) => filtersState.products.includes(p)))
          : invoicesBeforeFilters;

      if (filtersState.pastPayDate) {
        moreFilteredResults = moreFilteredResults.filter((invoice) => {
          const payDate = invoice.PAYDATE?.toDate();
          if (payDate) {
            return DateTime.fromJSDate(payDate) < today;
          }
          return false;
        });
      }

      return moreFilteredResults;
    });

    // return only the existing products in invoices
    setInvoicesProducts(() => {
      const uniqProducts: Set<InvoiceProduct> = invoicesBeforeFilters.reduce((acc, invoice) => {
        invoice.PRODUCTS?.forEach((product) => {
          acc.add(product);
        });

        return acc;
      }, new Set<InvoiceProduct>());

      return Array.from(uniqProducts).sort((a, b) => a.localeCompare(b));
    });
  }, [invoicesBeforeFilters, filtersState.products, filtersState.pastPayDate]);

  const filteredInvoices = useMemo(
    () => invoices.filter((invoice) => filtersState.status.includes(getInvoiceStatus(invoice)._STATUS)),
    [filtersState.status, invoices]
  );

  return {
    filters: filtersState,
    invoices: filteredInvoices,
    setFilters,
    isFetching,
    invoicesProducts: invoicesProducts ?? [],
  };
};
