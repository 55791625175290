import { useHistory } from "react-router";
import { Box, Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import DashboardEmptyStateLogo from "../../assets/dashboard-empty-state.svg";
import DashboardEmptyStateDarkLogo from "../../assets/dashboard-empty-state-dark.svg";
import { dashboardEmptyStateTexts, dashboardEmptyWidgetsTexts } from "../../assets/texts";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { ConfigControls } from "./ConfigControls";

const DashboardEmptyState = () => {
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const history = useHistory();
  const darkMode = useDarkThemeCheck();

  return (
    <Box>
      {isDoitEmployee && <ConfigControls />}
      <Box>
        <Box mt={8} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <img
            src={darkMode ? DashboardEmptyStateDarkLogo : DashboardEmptyStateLogo}
            alt="complete-profile-no-permissions"
          />
          <Typography mt={3} variant="h1">
            {dashboardEmptyStateTexts.TITLE}
          </Typography>
          <Typography mt={2} align="center" color="textSecondary" style={{ maxWidth: 600 }} variant="body1">
            {dashboardEmptyStateTexts.SUB_TITLE}
          </Typography>
          <Typography mt={4} align="center" variant="body1">
            <Link
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() => history.push(`/customers/${customer.id}/entities`)}
            >
              {dashboardEmptyStateTexts.BILLING_PROFILE_LINK}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const EmptyWidgets = ({ onClick, disabled }: { onClick: () => void; disabled: boolean }) => {
  const darkMode = useDarkThemeCheck();

  return (
    <Box mt={8} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <img
        src={darkMode ? DashboardEmptyStateDarkLogo : DashboardEmptyStateLogo}
        alt="complete-profile-no-permissions"
      />
      <Typography mt={3} variant="h1">
        {dashboardEmptyWidgetsTexts.TITLE}
      </Typography>
      <Typography mt={2} align="center" color="textSecondary" style={{ maxWidth: 600 }} variant="body1">
        {dashboardEmptyWidgetsTexts.SUB_TITLE}
      </Typography>
      <Typography mt={4} align="center" variant="body1">
        <Button onClick={onClick} disabled={disabled}>
          {dashboardEmptyWidgetsTexts.ACTION}
        </Button>
      </Typography>
      {disabled && <Typography>{dashboardEmptyWidgetsTexts.NOT_ALLOWED}</Typography>}
    </Box>
  );
};

export default DashboardEmptyState;
