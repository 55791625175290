import { useMemo } from "react";

import { CloudFlowNodeType, type ConditionParameter } from "@doitintl/cmp-models";
import { Button, Stack } from "@mui/material";

import { useNodeConfigurationContext } from "./NodeConfigurationContext";

// TODO: Handle the actions based on different Nodes setup. This is just a placeholder.
export const ActionBar = () => {
  const { activeTab, setActiveTab, nodeConfig, persistParameters, saveNode } = useNodeConfigurationContext();

  const areValidParameters = useMemo(() => {
    if (nodeConfig.type === CloudFlowNodeType.CONDITION) {
      // eslint-disable-next-line deprecation/deprecation
      const conditions = ((nodeConfig.parameters as any).conditions as ConditionParameter[]) ?? [];
      return (
        conditions.length > 0 &&
        conditions.every((condition, index) => {
          const { field, value, operator, logicalOperator } = condition;
          const isConditionValid = field && value && operator;
          const isLogicalOperatorValid = index === 0 || !!logicalOperator;
          return isConditionValid && isLogicalOperatorValid;
        })
      );
    }
    return true;
  }, [nodeConfig.parameters, nodeConfig.type]);

  switch (activeTab) {
    case "Parameters":
      return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={!areValidParameters}
            onClick={() => setActiveTab("Test")}
          >
            Go to test
          </Button>
        </Stack>
      );
    case "Permissions":
      return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              persistParameters();
              setActiveTab("Test");
            }}
          >
            Go to test
          </Button>
        </Stack>
      );
    case "Test":
      return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={() => setActiveTab("Parameters")}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={saveNode}>
            Done
          </Button>
        </Stack>
      );
    default:
      return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={saveNode}>
            Done
          </Button>
        </Stack>
      );
  }
};
