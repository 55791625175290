import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

export type SnowflakeError = {
  message: string | null;
  ts: Timestamp;
};
@subCollection("snowflake-configuration")
export class DataConnectorSnowflakeModel {
  accountId!: string;

  customerId!: string;

  error!: SnowflakeError;

  lastUpdated!: Timestamp;

  orgId!: string;

  status!: string;

  timeCreated!: Timestamp;

  user!: string;
}

@collection("dataConnectors")
export class DataConnectorModel {
  subCollections!: {
    "snowflake-configuration": DataConnectorSnowflakeModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
