import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { Roles } from "@doitintl/cmp-models";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { useQuery } from "../../../utils/useQuery";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import useGenerateReport from "../generateReport";
import { type PayloadConfig } from "../generateReport/types";
import { getOrganizationRef } from "../utilities";

export const useNewReportHandler = ({
  baseUrl,
  mixpanelEventName,
  prevPageUrl,
  additionalState = {},
}: {
  baseUrl: string;
  mixpanelEventName: string;
  prevPageUrl?: string;
  additionalState?: object;
}) => {
  const { customer, userOrganization, init: customerCtxInit } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { defaultReportConfig } = useAnalyticsContext();
  const history = useHistory();
  const generateReport = useGenerateReport();
  const routeMatchURL = useRouteMatchURL();
  const query = useQuery();

  return useCallback(async () => {
    if (customerCtxInit && currentUser.email) {
      const orgRef = getOrganizationRef(isDoitEmployee, userOrganization, customer.id);
      try {
        const newReportId = await generateReport(
          {
            description: "",
            customer: customer.ref,
            organization: orgRef,
            name: "",
            collaborators: [{ email: currentUser.email, role: Roles.OWNER }],
            config: defaultReportConfig as PayloadConfig,
            draft: true,
          },
          true
        );
        mixpanel.track(mixpanelEventName, { reportId: newReportId, draft: true });
        history.push(`${baseUrl}/${newReportId}?${query.toString()}`, {
          prevPage: prevPageUrl ? prevPageUrl : routeMatchURL,
          ...additionalState,
        });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }
  }, [
    customerCtxInit,
    currentUser.email,
    isDoitEmployee,
    userOrganization,
    customer.id,
    customer.ref,
    generateReport,
    defaultReportConfig,
    mixpanelEventName,
    history,
    baseUrl,
    query,
    prevPageUrl,
    routeMatchURL,
    additionalState,
  ]);
};
