import { stringify } from "qs";

import { AWSFeatureName } from "../../types";

const NEW_STACK_NAME = "DoitCmpNewRole";
const UPDATE_STACK_NAME = "DoitCmpExistingRole";
const DEFAULT_ROLE_NAME = "doitintl-cmp";

const SNS_ARN = "arn:aws:sns:us-east-1:068664126052:doitintl-aws-connect";

const AMAZON_TEMPLATE_URL_PREFIX = `https://doit-cmp-ops-pub.s3.amazonaws.com/${
  process.env.REACT_APP_FIREBASE_PROJECT_ID?.includes("dev") ? "dev" : "prod"
}`;
const UPDATE_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_policies.yml`;
const CREATE_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_role_N_policies.yml`;

const UPDATE_LAMBDA_PREFIX = `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.uc.r.appspot.com/webhooks/v1/aws`;
const UPDATE_ROLE_LAMBDA_URL = `${UPDATE_LAMBDA_PREFIX}/updateAccountRole`;
const UPDATE_FEATURE_LAMBDA_URL = `${UPDATE_LAMBDA_PREFIX}/updateAwsFeature`;

function generateUrl(params: any) {
  const paramsStr = stringify(params);
  return `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?${paramsStr}`;
}

function addTimeStamp(str: string) {
  return `${str}-${Date.now()}`;
}

export function getQuickCreateUrl(customerId: string, selectedFeatures: string[]) {
  const stackName = addTimeStamp(NEW_STACK_NAME);
  const params = {
    templateUrl: CREATE_TEMPLATE_URL,
    stackName,
    param_NotificationUrl: UPDATE_ROLE_LAMBDA_URL,
    param_SnsArn: SNS_ARN,
    param_RoleToAddPolicyTo: DEFAULT_ROLE_NAME,
    param_ExternalId: customerId,
    param_SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    param_Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
  };

  return [generateUrl(params), stackName];
}

export function getQuickUpdateUrl(roleName: string, selectedFeatures: string[]) {
  const stackName = addTimeStamp(UPDATE_STACK_NAME);
  const params = {
    templateUrl: UPDATE_TEMPLATE_URL,
    stackName,
    param_NotificationUrl: UPDATE_FEATURE_LAMBDA_URL,
    param_SnsArn: SNS_ARN,
    param_RoleToAddPolicyTo: roleName,
    param_SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    param_Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
  };

  return [generateUrl(params), stackName];
}

function generateCLICommand(stackName: string, templateUrl: string, params: Record<string, string | boolean>) {
  const paramsList: string[] = [];
  for (const [key, value] of Object.entries(params)) {
    paramsList.push(`ParameterKey=${key},ParameterValue=${value}`);
  }

  return `aws cloudformation create-stack --stack-name ${stackName} --template-url ${templateUrl} --parameters ${paramsList.join(
    " "
  )}  --region us-east-1 --capabilities CAPABILITY_NAMED_IAM`;
}

export function getCLICreateCommand(customerId: string, selectedFeatures: string[]) {
  const stackName = addTimeStamp(NEW_STACK_NAME);
  const params = {
    ExternalId: customerId,
    NotificationUrl: UPDATE_ROLE_LAMBDA_URL,
    RoleToAddPolicyTo: DEFAULT_ROLE_NAME,
    SnsArn: SNS_ARN,
    SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
  };

  return [generateCLICommand(stackName, CREATE_TEMPLATE_URL, params), stackName];
}

export function getCLIUpdateCommand(roleName: string, selectedFeatures: string[]) {
  const stackName = addTimeStamp(UPDATE_STACK_NAME);
  const params = {
    RoleToAddPolicyTo: roleName,
    NotificationUrl: UPDATE_FEATURE_LAMBDA_URL,
    SnsArn: SNS_ARN,
    SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
  };

  return [generateCLICommand(stackName, UPDATE_TEMPLATE_URL, params), stackName];
}
