import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource, getAlgoliaResults } from "@algolia/autocomplete-js";
import { parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";
import upperCase from "lodash/upperCase";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Customer } from "../../../types";
import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { CloudAnalyticsIndex } from "../consts";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type CloudAnalyticsHit } from "../types";
import {
  composeCloudAnalyticsSubtitle,
  filterByCustomer,
  getAlgoliaAnalyticsProps,
  isSearchForCustomer,
  partsToString,
  searchItemLogo,
} from "../utils";

const getCloudAnalyticsUrl = (item: CloudAnalyticsHit, indexName: AlgoliaIndexType, customerId: string | undefined) => {
  switch (indexName) {
    case "attributions":
      return `/customers/${customerId}/analytics/attributions/${item.objectID}`;
    case "attributionGroups":
      return `/customers/${customerId}/analytics/attribution-groups/${item.objectID}`;
    case "reports":
      return `/customers/${customerId}/analytics/reports/${item.objectID}`;
    case "budgets":
      return `/customers/${customerId}/analytics/budgets/${item.objectID}`;
    case "alerts":
      return `/customers/${customerId}/analytics/alerts/${item.objectID}`;
    case "metrics":
      return `/customers/${customerId}/analytics/metrics/${item.objectID}`;
    default:
      return `/customers/${customerId}`;
  }
};

type Props = {
  customer: Customer | null | undefined;
  indexName: AlgoliaIndexType;
};

const createCloudAnalyticsSource = ({ indexName, customer }: Props): AutocompleteSource<CloudAnalyticsHit> => ({
  sourceId: indexName,
  templates: {
    item({ item, state }) {
      const titleParts = parseAlgoliaHitHighlight({
        hit: item,
        attribute: ["name"],
      });

      const context = state.context as AlgoliaCustomContext;

      return (
        <AlgoliaResultListItem
          avatarLogo={state.context.isDarkMode ? searchItemLogo[indexName]?.dark : searchItemLogo[indexName]?.light}
          highlightedTitle={titleParts}
          highlightedSubtitle={composeCloudAnalyticsSubtitle(item, context.isDoitEmployee)}
          avatarFallback={upperCase(indexName?.substring(0, 2))}
        />
      );
    },
  },
  onSelect({ item, event, state }) {
    const context = state.context as AlgoliaCustomContext;

    context.onSelectRow({
      id: item.objectID,
      category: indexName,
      label: item.objectID,
      avatarFallback: `
          ${upperCase(item.name?.substring(0, 1))}`,
      highlightedTitle: [{ value: item.name, isHighlighted: false }],
      highlightedSubtitle: [
        {
          value: partsToString(composeCloudAnalyticsSubtitle(item, context.isDoitEmployee)),
          isHighlighted: false,
        },
      ],
      url: getCloudAnalyticsUrl(item, indexName, item.customerId ? item.customerId : customer?.id),
      withMetaKey: event.metaKey,
    });
  },
  getItems({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const filters = filterByCustomer(context);

    return getAlgoliaResults({
      searchClient: context.searchClient,
      queries: [
        {
          ...getAlgoliaAnalyticsProps(context),
          indexName,
          query,
          params: {
            hitsPerPage: 10,
          },
          filters: !context.inCustomerContext ? `NOT type:"preset" AND NOT type:"managed"` : filters,
        },
      ],
      transformResponse({ hits }: { hits: Hit<CloudAnalyticsHit>[][] }) {
        return hits;
      },
    });
  },
  getItemUrl({ item }) {
    return getCloudAnalyticsUrl(item, indexName, item.customerId ? item.customerId : customer?.id);
  },
});

export const useCloudAnalyticsPlugin = (
  restrictedIndices?: AlgoliaIndexType[]
): AutocompletePlugin<CloudAnalyticsHit, undefined> | null => {
  const { customer } = useCustomerContext({ allowNull: true });

  return useMemo(() => {
    const cloudAnalyticsSources: AutocompleteSource<CloudAnalyticsHit>[] = [];
    CloudAnalyticsIndex.forEach((index) => {
      if (!restrictedIndices?.includes(index as AlgoliaIndexType)) {
        cloudAnalyticsSources.push(
          createCloudAnalyticsSource({
            customer,
            indexName: index as AlgoliaIndexType,
          })
        );
      }
    });

    if (!cloudAnalyticsSources.length) {
      return null;
    }

    return {
      getSources({ query }) {
        if (!query) {
          return [];
        }
        return cloudAnalyticsSources;
      },
    };
  }, [customer, restrictedIndices]);
};
