import noop from "lodash/noop";

import { type NodeData } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: NodeData;
};

export const TriggerNode = ({ id, data }: Props) => (
  <>
    <NodeWrapper
      title={data.name}
      status={data.status}
      onEdit={data.onEditNode}
      isActive={data.isActive}
      onClick={noop}
    />
    <SourceHandle id={id} />
  </>
);
