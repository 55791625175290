import { type ChangeEvent, type FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { cloudflowTexts, globalText } from "../../../assets/texts";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { CloudflowKind, type CloudflowTemplate } from "../types";
import { cloudflowKindTexts, links } from "./CreateNewCloudflow/dummyData";
import FromScratchStep from "./CreateNewCloudflow/FromScratchStep";
import FromTemplateStep from "./CreateNewCloudflow/FromTemplateStep";

type CreateNewCloudflowDialogProps = {
  isDialogOpened: boolean;
  handleCloseDialog: () => void;
  templates: CloudflowTemplate[];
  handleCreateCloudflow: (cloudflowName: string, cloudflowKind: CloudflowKind, templateId?: string | null) => void;
};

const CreateNewCloudflowDialog = ({
  isDialogOpened,
  handleCloseDialog,
  templates,
  handleCreateCloudflow,
}: CreateNewCloudflowDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [cloudflowName, setCloudflowName] = useState<string | null>(null);
  const [cloudflowKind, setCloudflowKind] = useState<CloudflowKind>(CloudflowKind.fromScratch);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const errorSnackbar = useErrorSnackbar();
  const [submitButtonTooltip, setSubmitButtonTooltip] = useState<string>(cloudflowTexts.CLOUDFLOW_NAME_REQUIRED);

  const resetForm = useCallback(() => {
    setCloudflowName(null);
    setCloudflowKind(CloudflowKind.fromScratch);
    setTemplateId(null);
  }, []);

  const Step = useMemo(() => {
    if (cloudflowKind === CloudflowKind.fromScratch) {
      return <FromScratchStep links={links} />;
    }

    return <FromTemplateStep templateId={templateId} setTemplateId={setTemplateId} templates={templates} />;
  }, [cloudflowKind, templateId, templates]);

  useEffect(() => {
    setTemplateId(null);
  }, [cloudflowKind]);

  const onClose = useCallback(() => {
    handleCloseDialog();
    resetForm();
  }, [handleCloseDialog, resetForm]);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        if (!cloudflowName || (cloudflowKind === "template" && !templateId)) {
          return;
        }
        handleCreateCloudflow(cloudflowName, cloudflowKind, templateId);
        handleCloseDialog();
        resetForm();
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(cloudflowTexts.CREATE_CLOUDFLOW_FAILED);
      }
    },
    [cloudflowKind, cloudflowName, errorSnackbar, handleCloseDialog, handleCreateCloudflow, resetForm, templateId]
  );

  const onChangeCloudflowKind = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCloudflowKind(e.target.value as CloudflowKind);
  }, []);

  const isSubmitDisabled = useMemo(() => {
    let isDisabled = false;
    let tooltipText = "";
    if (!cloudflowName) {
      isDisabled = true;
      tooltipText = cloudflowTexts.CLOUDFLOW_NAME_REQUIRED;
    }

    if (cloudflowKind === CloudflowKind.template && !templateId) {
      isDisabled = true;
      tooltipText = cloudflowTexts.TEMPLATE_REQUIRED;
    }

    if (cloudflowKind === CloudflowKind.template && !templateId && !cloudflowName) {
      isDisabled = true;
      tooltipText = cloudflowTexts.CLOUDFLOW_NAME_REQUIRED_AND_TEMPLATE_REQUIRED;
    }

    setSubmitButtonTooltip(tooltipText);
    return isDisabled;
  }, [cloudflowKind, cloudflowName, templateId]);

  return (
    <Dialog
      open={isDialogOpened}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        component: "form",
        onSubmit,
      }}
    >
      <DialogTitle>{cloudflowTexts.CREATE_NEW_CLOUDFLOW}</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          fullWidth
          label={
            <Box sx={{ display: "flex" }}>
              {cloudflowTexts.CLOUDFLOW_NAME} &nbsp;
              <Box sx={{ color: "error.main" }}>*</Box>
            </Box>
          }
          value={cloudflowName ?? ""}
          onChange={(e) => {
            setCloudflowName(e.target.value.trimStart());
          }}
        />
        <Box mt={2} />
        <Typography variant="subtitle1" fontWeight={500}>
          {cloudflowTexts.WHAT_KIND_OF_CLOUDFLOW}
        </Typography>
        <FormControl>
          <RadioGroup
            defaultValue={CloudflowKind.fromScratch}
            value={cloudflowKind}
            name="cloudflowKind"
            onChange={onChangeCloudflowKind}
          >
            <FormControlLabel
              label={
                <Box pt={1}>
                  <Typography variant="body1">{cloudflowKindTexts[CloudflowKind.fromScratch].title}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {cloudflowKindTexts[CloudflowKind.fromScratch].subtitle}
                  </Typography>
                </Box>
              }
              control={<Radio />}
              value={CloudflowKind.fromScratch}
            />
            <FormControlLabel
              label={
                <Box pt={1}>
                  <Typography variant="body1">{cloudflowKindTexts[CloudflowKind.template].title}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {cloudflowKindTexts[CloudflowKind.template].subtitle}
                  </Typography>
                </Box>
              }
              control={<Radio />}
              value={CloudflowKind.template}
            />
          </RadioGroup>
        </FormControl>
        <Box mt={2} />
        {Step}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCloseDialog}>{globalText.CANCEL}</Button>
        <Tooltip describeChild title={submitButtonTooltip}>
          <Box>
            <Button type="submit" variant="contained" disabled={isSubmitDisabled}>
              {globalText.CREATE}
            </Button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewCloudflowDialog;
