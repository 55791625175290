import { common } from "@mui/material/colors";

import { domainConfig } from "./domainConfig";
import { type ThemeMode, ThemeModes } from "./muiThemeTypes";

export const mainColors = {
  violet: "#3B40B5",
  teal: "#2AB9BD",
  ink: "#000433",
  spark: "#FC3165",
};

export const secondaryColors = ["#272F75", "#F14271", "#43EFDE", "#6B6ECC", "#852DD3", "#A398DE", "#BEE1F5", "#F2C7CF"];

export const neutrals = ["#0E1721", "#555759", "#76787A", "#95999C", "#B1B2B3"];

function getCmpBaseColors() {
  const baseColors = {
    primary: mainColors.violet,
    primaryGradient: mainColors.violet,
    secondaryGradient: "#D51A4E",
    primaryDark: "#B3BEFF",
    primaryDarkDark: "#7D85B3",
    primaryButtonTextColor: common.white,
    navigationTextColor: common.white,
    darkContrast: "#353540",
    backgroundDark: "#2D2D39",
    navigationDark: "#252533",
    accent: "#D51A4E",
    accentDark: "#EB6087",
    navigation: mainColors.ink,
    successBackgroundDark: "#141F15",
    errorMainDark: "#F27A78",
    errorLightDark: "#FF9B99",
    errorDarkDark: "#ED4845",
    warningBackgroundDark: "#281D0B",
    errorBackgroundDark: "#331919",
    infoBackgroundDark: "#0B1F28",
    presentationModeOrange: "#FFC83A",
    greenLightHeatMap: "#A7D8BA",
    greenDarkHeatMap: "#4D8073",
    redLightHeatMap: "#EB939F",
    redDarkHeatMap: "#B47783",
    cellBackgroundLight: "#EFF0F9",
    cellBackgroundDark: "#3F404F",
    notOptimisedBackgroundLight: "#FFEB3B",
    notOptimisedBackgroundDark: "#FFEE58",
    notOptimisedColor: "rgba(0, 0, 0, 0.87)",
    standardLabel: "#E7DFFF",
    enhancedLabel: "#FEFFDF",
    premiumLabel: "#E1FFDF",
    enterpriseLabel: "#E0FFFF",
  };

  return { ...baseColors, ...domainConfig.theme };
}

export const cmpBaseColors = getCmpBaseColors();

const hcColors = [
  [
    ["#D8D0F8", "#D0E2F3"],
    ["#988FDD", "#A4A9DF"],
    ["#B9AEF1", "#BAC5E9"],
  ],
  [
    ["#FBEEB8", "#FFFFD9"],
    ["#F5D5AA", "#FEE3C6"],
    ["#E7A38F", "#F6AAA0"],
    ["#5856AD", "#7673CA"],
    ["#D66F75", "#E96D7D"],
    ["#CE5269", "#E04A6C"],
    ["#1B2473", "#3B40B5"],
  ],
  [
    ["#7871C9", "#8E8DD5"],
    ["#DF8981", "#F08C8E"],
    ["#393D90", "#5B59C0"],
    ["#EFBC9C", "#FBC6B3"],
  ],
];

export const hcColors3 = {
  light: [
    "#E4A3F5",
    "#461254",
    "#C8D148",
    "#EE7798",
    "#0090D6",
    "#94E1EB",
    "#997366",
    "#3B40B5",
    "#00CCB7",
    "#CC6D00",
    "#FFDE4C",
    "#931310",
  ],

  dark: [
    "#E4A3F5",
    "#8D24A8",
    "#D4DB70",
    "#EE7798",
    "#1AB4FF",
    "#B2E9F0",
    "#A97E6F",
    "#5C62FF",
    "#00E5CE",
    "#FFA533",
    "#FFF6CC",
    "#EB4B47",
  ],
};

export const generateHighchartList3 = (mode: string) => hcColors3[mode];

export const highchartColors = {
  light: generateHighchartList3("light"),
  dark: generateHighchartList3("dark"),
};

export const convertHighchartToTreemap = (mode: ThemeMode) => {
  const index = mode === ThemeModes.LIGHT ? 0 : 1;
  return [
    ...hcColors[1].reverse().map((h) => h[index]),
    ...hcColors[0].map((h) => h[index]),
    ...hcColors[2].map((h) => h[index]),
  ];
};
