import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { alertTexts, globalText, organizationsText } from "../../../assets/texts";
import { LearnMoreAlert } from "../../../Components/Alerts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { filterColumns, headers } from "./Columns";
import { deleteOrgs } from "./db";
import { OrgRow } from "./OrgRow";
import { type OrganizationWSnap, type OrgUser } from "./Types";
import { createUserOrgMap } from "./Utils";

const Orgs = ({ users }: { users: OrgUser[] }) => {
  const history = useHistory();
  const { customer, organizations } = useCustomerContext();
  const api = useApiContext();
  const routeMatchURL = useRouteMatchURL();
  const sharedSnackbar = useSnackbar();
  const [selected, setSelected] = useState<OrganizationWSnap[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    mixpanel.track("iam.organizations.list");
  }, []);

  const rootOrg = useMemo(() => organizations.find((o) => !o.data.parent), [organizations]);

  const showSnackbarError = useCallback(
    (e) => {
      sharedSnackbar.onOpen({
        message: globalText.GENERIC_ERROR,
        variant: "error",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(e);
    },
    [sharedSnackbar]
  );

  const removeOrgs = useCallback(
    async (orgIds: string[]) => {
      try {
        await deleteOrgs(api, customer.id, orgIds);
        mixpanel.track("iam.organizations.delete", { orgIds });
      } catch (e) {
        showSnackbarError(e);
      }
    },
    [api, customer.id, showSnackbarError]
  );

  const handleNewOrgClick = useCallback(async () => {
    if (rootOrg?.ref) {
      mixpanel.track("iam.organizations.create");
      history.push(`${routeMatchURL}/create`);
    }
  }, [history, rootOrg?.ref, routeMatchURL]);

  const handleDeleteClick = async () => {
    const orgIds = selected.map((o) => o.snapshot.id);
    try {
      await removeOrgs(orgIds);
    } catch (e) {
      showSnackbarError(e);
      return;
    }
    // allows the delete button loader to be removed
    setSelected([]);
    sharedSnackbar.onOpen({
      message:
        selected.length > 1
          ? organizationsText.MULTIPLE_DELETED(selected.length)
          : organizationsText.SUCCESSFULLY_DELETED(selected?.[0].data.name ?? ""),
      variant: "success",
      autoHideDuration: 5000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  };

  const isRootSelected = useMemo<boolean>(
    () => selected.findIndex((org) => org.snapshot.id === rootOrg?.snapshot.id) > -1,
    [rootOrg?.snapshot.id, selected]
  );

  const orgsWithUsers = useMemo<OrganizationWSnap[]>(() => {
    if (rootOrg?.snapshot?.id && users?.length > 0) {
      const usersOrgMap = createUserOrgMap(users);
      return organizations.map((o) => {
        o.users = usersOrgMap[o.snapshot.id] ?? 0;
        return o;
      });
    }
    return organizations;
  }, [rootOrg?.snapshot.id, users, organizations]);

  const { isFeatureEntitled } = useTier();

  const deleteOrgsMessage = (orgName: string) => (
    <>
      <span>{organizationsText.DELETE_SUBTITLE(orgName)}</span>
      <ul>
        <li>{organizationsText.DELETE_POINT_1}</li>
        <li>{organizationsText.DELETE_POINT_2}</li>
        <li>{organizationsText.DELETE_POINT_3}</li>
      </ul>
    </>
  );

  const deleteMsg = useMemo(() => {
    if (selected?.length > 0) {
      let exceptLast = "";
      let deleteText = "";
      if (selected.length > 1) {
        exceptLast = selected
          .slice(0, -1)
          .map((o) => `"${o.data.name}"`)
          .join(", ");
      }
      if (exceptLast) {
        deleteText = `${exceptLast} and `;
      }
      return `${deleteText}"${selected.slice(-1)[0].data.name}"`;
    }
    return "";
  }, [selected]);

  return (
    <>
      <Box pt={1} mb={-2}>
        <LearnMoreAlert
          text={alertTexts.ORGANIZATIONS_OVERVIEW}
          url="https://help.doit.com/user-management/manage-organizations"
        />
      </Box>
      <FilterTable<OrganizationWSnap>
        tableItems={orgsWithUsers}
        rowComponent={OrgRow}
        showRowsSelection={true}
        onRowsSelected={setSelected}
        headerColumns={headers}
        filterColumns={filterColumns}
        filterBarPlaceholder="Filter organizations"
        persistenceKey="organization_tab"
        itemUniqIdentifierField="snapshot.id"
        defaultSortingColumnValue="data.name"
      >
        <Grid item>
          <Tooltip title={isRootSelected ? organizationsText.DELETE_ROOT : ""}>
            <div>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenDialog(true)}
                disabled={selected?.length === 0 || isRootSelected}
              >
                {organizationsText.DELETE_ORG}
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewOrgClick}
            startIcon={<AddIcon />}
            disabled={!isFeatureEntitled("platform:IAMOrg")}
          >
            {organizationsText.CREATE_NEW_ORG}
          </Button>
        </Grid>
      </FilterTable>

      <DeleteDialog
        open={openDialog}
        title={`${organizationsText.DELETE_ORG}?`}
        message={deleteOrgsMessage(deleteMsg)}
        onDelete={handleDeleteClick}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};
export default Orgs;
