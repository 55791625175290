import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeGSuite, AssetTypeMicrosoftAzure } from "./Asset";

export type PerkModule = {
  description: string;
  title: string;
};

export const perkPlatformList = [
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
] as const;
export type PerkPlatform = (typeof perkPlatformList)[number];
export const perkPlatformListShortened: Record<PerkPlatform, string> = {
  [AssetTypeAmazonWebServices]: "aws/platform",
  [AssetTypeGoogleCloud]: "gcp/platform",
  [AssetTypeGSuite]: "gsuite/platform",
  [AssetTypeMicrosoftAzure]: "azure/platform",
};
export type PerkPlatformUrl = Partial<Record<PerkPlatform, string>>;
export type PerkType = "accelerator" | "training" | "promotion" | "pro-serv" | "isv-solution" | "workshop";

export const perkSolutionTypeList = [
  "analytics",
  "big-data",
  "developer-tools",
  "security-and-monitoring",
  "networking",
  "ml-and-ai",
  "database",
  "compute",
  "blog-and-cms",
  "data-sources",
  "operating-systems",
  "storage",
  "maps",
  "advertising-and-gtm",
  "other",
] as const;

export type PerkSolutionType = (typeof perkSolutionTypeList)[number];

export type PerkEnrichment = {
  logoUrl?: string;
  providerIsDoitCustomer?: boolean;
};

export type PerkField = {
  active: boolean;
  checklist: string;
  description: string;
  shortDescription?: string;
  title: string;
  name: string;
  platform: PerkPlatform[];
  platformUrl?: PerkPlatformUrl;
  solutionType?: PerkSolutionType[];
  type: PerkType;
  videoUrl?: string;
  deleted: boolean;
  zendeskTags: string[];
  acceleratorProgram?: boolean;
};

type CustomerId = string;

type TicketId = number;

@subCollection("requestedUsers")
export class PerkRequestedUsersModel {
  ticketId?: number;

  ticketsOnBehalfOfCustomers?: Record<CustomerId, TicketId>;
}

@collection("perks")
export class PerkModel {
  agenda!: string;

  enrichment?: PerkEnrichment;

  fields!: PerkField;

  modules?: PerkModule[];

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  timePublished!: Timestamp | null;

  subCollections?: {
    requestedUsers: PerkRequestedUsersModel;
  };
}
