import { type Asset } from "../../../types";
import { type Breakdown, type BreakdownData } from "../types";

export interface BreakdownTreeNode {
  dimension: string;
  dimensionValue: string;
  value: number;
  baseValue: number;
  children: BreakdownTreeNode[];
}

export const sortRecursively = (node: BreakdownTreeNode) => {
  if (!node.children.length) {
    return;
  }

  // Sort by value (highest first), then alphabetically
  node.children.sort((a, b) => {
    const valueCompare = b.value - a.value;
    return valueCompare !== 0 ? valueCompare : a.dimensionValue.localeCompare(b.dimensionValue);
  });

  node.children.forEach(sortRecursively);
};

export function findOrCreateNode(
  parent: BreakdownTreeNode,
  dimensions: string[],
  breakdownData: BreakdownData,
  assets: Asset[],
  dimensionIndex: number = 0
): BreakdownTreeNode {
  // We are iterating over each of the dimensions - pick the correct values depending on how deep we are
  const dimension = dimensions[dimensionIndex];
  let dimensionValue = breakdownData.dimensionValues[dimensionIndex];

  // Special handling for AWS account IDs, which are expected in the first dimension - add the account name if we can find it in assets
  if (dimension === "Account") {
    const matchingAsset = assets.find((asset) => asset.data.properties.accountId === dimensionValue);

    if (matchingAsset) {
      dimensionValue = `${dimensionValue} (${matchingAsset.data.properties.name})`;
    }
  }

  // See if we have a child node already for the dimension we're looking for
  let childNode = parent.children.find(
    (node) => node.dimension === dimension && node.dimensionValue === dimensionValue
  );

  // If not, create it, and add it to the parent's children
  if (!childNode) {
    childNode = {
      dimension,
      dimensionValue,
      value: 0,
      baseValue: 0,
      children: [],
    };

    parent.children.push(childNode);
  }

  // For each of the dimensions, we want to know what the totals are for all items contained in them (recursively) -
  // so we increment the parent here (and we will do that for each level for this data point)
  parent.value += breakdownData.value;
  parent.baseValue += breakdownData.baseValue;

  // If we have more dimensions to go => recursion, to the next dimension
  const nextDimensionIndex = dimensionIndex + 1;
  if (nextDimensionIndex < breakdownData.dimensionValues.length) {
    return findOrCreateNode(childNode, dimensions, breakdownData, assets, nextDimensionIndex);
  }

  // If not, we're at the last level - increment this node too (as it won't be incremented by children), and return it
  childNode.value += breakdownData.value;
  childNode.baseValue += breakdownData.baseValue;

  return childNode;
}

export function toTree(breakdown: Breakdown, assets: Asset[]): BreakdownTreeNode {
  // We are going to build a tree to drill down into the given dimensions
  const rootNode: BreakdownTreeNode = {
    dimension: "",
    dimensionValue: "Total",
    value: 0,
    baseValue: 0,
    children: [],
  };

  // For each value, place it in the tree
  for (const breakdownData of breakdown.data) {
    findOrCreateNode(rootNode, breakdown.dimensions, breakdownData, assets);
  }

  // Make sure the tree is sorted
  sortRecursively(rootNode);

  return rootNode;
}

export const numberOfNestedChildren = (node: BreakdownTreeNode): number => {
  if (!node.children.length) {
    return 1;
  }

  let total = 0;

  for (const child of node.children) {
    total += numberOfNestedChildren(child);
  }

  return total;
};
