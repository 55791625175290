export const DATAHUB_LINK = "https://developer.doit.com/docs/datahub-api";
export const SASS_EMAIL = "mailto:team-saas@doit.com";
export const DATAHUB_EVENTS_URL = "https://developer.doit.com/docs/datahub-api";

export const datahubTxt = {
  EXPLORE: "Explore",
  DELETE: "Delete",
  DELETE_TITLE: "Delete selected datasets",
  DELETE_DISABLED_NO_SELECT: "A dataset must be selected to delete",
  EXPLORE_DISABLED_NO_SELECT: "A dataset must be selected to open in reports",
  DELETE_DISABLED_NO_SELECT_BATCH: "A batch must be selected to delete",
  FILTER_PLACEHOLDER: "Filter datasets",
  REFRESH: "Refresh",
  DELETE_CONFIRM: "Are you sure you want to delete the selected datasets?",
  TABLE_HEADERS: {
    NAME: "Dataset",
    UPDATED_BY: "Updated by",
    RECORDS: "Records",
    LAST_UPDATED: "Last updated",
  },
  OPEN_IN_REPORTS: "Open in Reports",
  EXPLORE_DATASETS_NAME: "Explore DataHub datasets",
  DELETE_SELECTED_DATASETS: "Delete selected datasets",
  REFRESH_DATASET: "Check for newly ingested data",
  EXPLORE_DATASETS_REPORT: "Open report with dataset details",
  CREATE_DATASET: "Create new dataset",
  INVALID_DATASET_NAME: "Invalid input. Only alphanumeric characters, hyphens, underscores, and spaces are allowed.",
  CREATE_DATASET_DIALOG_DESCRIPTION: "Enter the details of your new dataset:",
  FAILED_TO_CREATE_DATASET: "Failed to create new dataset",
  DATASET_NAME_ALREADY_EXISTS: "This dataset name already exists in your organization",
  TITLE: "Datasets",
  DATASETS_DELETED_SUCCESSFULLY: "Datasets deleted successfully",
  DATASET_FAILED_DELETE: "Failed to delete datasets",
  INCLUDE: "Include ",
  DATA_HUB_DATA: "DataHub data",
  BILLING_TOOLTIP_TITLE: "Go beyond your cloud costs with DataHub data",
  DATA_HUB_TOOLTIP_TITLE: "Go beyond your cloud costs with your DataHub data",
  DATA_HUB_TOOLTIP_TEXT:
    "You have some datasets of your own data currently available, below are some examples. They can be shown or filtered using the Cloud dimension.",
  DATA_HUB_QUERY_TOOLTIP_TEXT:
    "You have some datasets of your own data currently available, selecting this option will query them as well.",
  MANAGE_DATASETS: "To manage your datasets, ",
  CLICK_HERE: "click here.",
  BILLING_TOOLTIP_DESC:
    "Combine cloud billing data with data from your own sources to build reports that provide visibility into all aspects of your organization's spend, such as:",
  INCLUDE_IN_PLAN_LINK_TEXT: "Learn how to use the DataHub API",
  NOT_INCLUDE_IN_PLAN_LINK_TEXT: "Contact us to upgrade",
  BILLING_DESC_OPTIONS: [
    "SaaS and other subscription costs (e.g. OpenAI)",
    "On-premises monthly costs",
    "Fixed costs such as headcount",
  ],
  CSV_UPLOADED_SUCCESSFULLY: "CSV preview loaded and is now ready to view",
  NO_DATA_FOUND: "No data found in the file",
  MISSING_REQUIRED_HEADER: "Missing required header",
  DIMENSION_OR_LABEL_REQUIRED: "At least one fixed dimension or label is required",
  AT_LEAST_ONE_METRIC_REQUIRED: "At least one metric is required",
  INVALID_HEADER: "Invalid header",
  BROWSE: "Browse...",
  IMPORT_DATA: "Import data",
  CONTINUE: "Continue",
  GET_STARTED: "Get started",
  CANCEL: "Cancel",
  NOT_NOW: "Not now",
  SELECT_OPTION: "Select the option that works best for you:",
  DATAHUB_API_OPTION: "Send it via DataHub API",
  DATAHUB_API_OPTION_DESCRIPTION:
    "Follow our integration guide to connect your third-party data sources to DoiT DataHub.",
  CSV_OPTION: "Upload CSV file",
  CSV_OPTION_DESCRIPTION:
    "Manually upload CSV files to DoiT Cloud Navigator with your third-party cost, usage, or other metric-based data.",
  STORAGE_BUCKET_OPTION: "Storage bucket",
  STORAGE_BUCKET_OPTION_DESCRIPTION: "Follow our integration guide to import your storage bucket data.",
  BI_SYSTEM_OPTION: "BI System",
  BI_SYSTEM_OPTION_DESCRIPTION: "Follow our integration guide to connect your BI System data with DoiT.",
  COMING_SOON: "Coming soon",
  UPLOAD_CSV: "Upload CSV",
  UPLOAD_CSV_DESC_1: "The file uploaded will be added as a new batch to the dataset below.",
  UPLOAD_CSV_DESC_2: "The data will be available in the DoiT Cloud Navigator within 15 minutes.",
  SELECT_FILE: "Select the file to upload:",
  DATASET: "Dataset",
  CSV_FILE: "CSV file",
  FILE_SIZE_EXCEEDED: "File size exceeded the limit of 100MB",
  INVALID_FILE_TYPE: "Invalid file type",
  PREVIEW: "Preview",
  PREVIEW_DESC: (rowNum, colNum) =>
    `We found ${rowNum} rows and ${colNum} columns in the csv that will be parsed by DoiT DataHub. 
    The following is a preview of your CSV file (showing up to 100 rows):`,
  PREPARE_FILE: "Prepare file",
  UPLOAD: "Upload",
  CONFIRM_UPLOAD: "Confirm upload",
  DATASET_RECENTLY_INGESTED: "your data was ingested within the last 90 minutes and is still being processed",
  DATASET_REPORT_DATA_UNAVAILABLE:
    "Data in analytics reports is not yet available for this dataset, ingestion will complete within 10 minutes.",
  DATASET_NO_RECORDS:
    "There are no records for this dataset, if you recently ingested data it may still be processing.",
  BATCH_REPORT_DATA_UNAVAILABLE:
    "Data in analytics reports is not yet available for some of the batches, ingestion will complete within 10 minutes.",
  INVALID_FORMAT: "Invalid format",
  SHOW_MORE: "Show more",
  SUCCESS_PAGE_TITLE: "Your data was uploaded successfully",
  SUCCESS_PAGE_DESCRIPTION:
    "Please note that we are currently processing your data and it might take up to 15 minutes to become available in DoiT Navigator.",
  RETURN_TO_OVERVIEW: "Return to overview",
  ERROR_UPLOADING_DATA: "An error occurred while uploading your data",
  DATASET_DETAILS: "Dataset details",
  DELETE_BATCHES_TITLE: "Delete selected batches",
  DELETE_BATCHES_DISABLED: "A batch must be selected to delete",
  DELETE_BATCHES_CONFIRM: "Are you sure you want to delete the selected batches?",
  FILTER_BATCHES_PLACEHOLDER: "Filter batches",
  DATASET_DETAILS_HEADERS: {
    BATCH: "Batch",
    ORIGIN: "Origin",
    RECORDS: "Records",
    SUBMITTED_BY: "Submitted by",
    SUBMITTED_ON: "Submitted on",
  },
  DATASET_DETAILS_EMPTY_TITLE: "Start importing data to this dataset",
  DATASET_DETAILS_EMPTY_DESC:
    "Get started now to seamlessly integrate cost, usage, and metric data from various third-party sources.",
  VIEW_DETAILS: "View details",
  BATCHES_DELETED_SUCCESSFULLY: "Batches deleted successfully",
  BATCHES_FAILED_DELETE: "Failed to delete batches",
  BATCH_RECENTLY_INGESTED: "your data was ingested within the last 90 minutes and is still being processed",
  INFORMATIONAL_STEP: {
    TITLE: "How to format your file",
    SUB_HEADER: "Format your CSV as below:",
    EXAMPLE: "Example",
    SYNTAX: "-- Syntax",
    CODE_BLOCK_1: "`usage_date[,id],label.KEY...[,fixed.KEY],metric.KEY...`",
    CODE_BLOCK_2: [
      "usage_date,label.territory,label.team,fixed.project_name,metric.cost",
      "2024-03-01T00:00:00Z,AMER,Sales,Data migration,52345",
      "2024-04-01T00:00:00Z,AMER,Sales,AI-powered search,65700",
      "2024-05-01T00:00:00Z,EMEA,R&D,Data migration,86900",
    ],
    DOWNLOAD_BTN: "Download sample file",
    LINK_TEXT: ["See", "Documentation", "for more details."],
  },
  NO_ENTITLEMENT_TITLE: "Go beyond your cloud costs with DataHub data",
  NO_ENTITLEMENT_LIST_ITEMS: [
    "SaaS and other subscription costs (e.g. OpenAI)",
    "On-premises monthly costs",
    "Operational costs",
  ],
  NO_ENTITLEMENT_TEXT_TEXT_BEFORE_LIST:
    "Combine cloud billing data with your own internal or third-party data sources to build reports that provide visibility into all aspects of your organization's spend, such as:",
  HOW_DATAHUB_CAN_HELP: "How DataHub can help you",
  NO_ENTITLEMENT_CARDS: [
    {
      title: "Unit economics",
      description:
        "With all your data in one place, understand how your organization’s IT spend affects the overall business goals.",
    },
    {
      title: "Improved governance",
      description:
        "Get alerted to cost spikes across your IT spend (not just your cloud billing data) and drive your engineers to take ownership of their costs.",
    },
    {
      title: "Accurate budgeting & forecasting",
      description:
        "Understand how cloud spend compares to your other billing sources and create more reliable P&L reports.",
    },
  ],
  PROCESSING: "Processing",
  PARTIALLY_DELETED: "Partially deleted",
  DATA_SET_UPLOAD_ERROR_TOOLTIP:
    "An error has occurred and the upload could not be completed. Please try uploading a csv again.",
  ERROR: "Error",
  LARGE_COMPRESSED_FILE_ERROR:
    "It seems we had some issue compressing your file it is still too large to upload, please reduce the file size",
};
