import { useEffect } from "react";

import { Link as InternalLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Divider, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { useCustomerContext } from "../../../Context/CustomerContext";
import mixpanel from "../../../utils/mixpanel";
import { type JiraIssue } from "../types";
import { JiraStatusChip } from "./JiraStatusChip";
import { LinkedThreadField } from "./LinkedThreadField";
import { LinkedThreadPaper } from "./LinkedThreadPaper";
import { truncateText } from "./utils";

type Props = {
  issue: JiraIssue;
  issueURL: string;
  threadId: string;
};

export const LinkedThread = ({ issue, issueURL, threadId }: Props) => {
  const theme = useTheme();
  const { customer } = useCustomerContext();

  useEffect(() => {
    mixpanel.track("threads.insight-linked.view");
  }, []);

  return (
    <LinkedThreadPaper color={cmpBaseColors.primaryDark} topBorderColor={theme.palette.primary.main}>
      <Box sx={{ p: 2 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: 14,
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {truncateText(issue.summary, 100)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: 12,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            mt: 0.5,
            mb: 2,
          }}
        >
          {truncateText(issue.description.replace("This issue was created from a DoiT Insight.\n\n", ""), 200)}
        </Typography>
        <Divider sx={{ my: 0.5 }} />
        <LinkedThreadField
          label="Status"
          element={<JiraStatusChip status={issue.status} statusCategory={issue.statusCategory} />}
        />
        <Divider sx={{ my: 0.5 }} />
        <LinkedThreadField
          label="Priority"
          element={
            <Typography
              variant="body2"
              sx={{
                fontSize: 12,
              }}
            >
              {issue.priority !== "" ? issue.priority : "N/A"}
            </Typography>
          }
        />
        <Divider sx={{ my: 0.5 }} />
        <LinkedThreadField
          label="Assigned to"
          element={
            <Typography
              variant="body2"
              sx={{
                fontSize: 12,
              }}
            >
              {issue.assignee !== "" ? issue.assignee : "Unassigned"}
            </Typography>
          }
        />
        <Button
          component={InternalLink}
          to={`/customers/${customer.id}/threads/${threadId}`}
          variant="contained"
          size="medium"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
        >
          View details
        </Button>
        <Button
          component={Link}
          href={issueURL}
          target="_blank"
          rel="noreferrer"
          variant="outlined"
          size="medium"
          color="primary"
          sx={{ mt: 1 }}
          fullWidth
        >
          Manage in Jira
          <OpenInNewIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
    </LinkedThreadPaper>
  );
};
