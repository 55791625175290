import { type Dispatch, type SetStateAction, type SyntheticEvent, useCallback, useState } from "react";

import { Aggregator, type Metric, Positions, type ReportConfig } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import findIndex from "lodash/findIndex";
import sortBy from "lodash/sortBy";

import { reportText, tooltipText } from "../../../../assets/texts";
import { DraggableContainer } from "../../../../Components/DragAndDrop/DraggableContainer";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import MetricSelect from "../../../../Components/Selects/CloudAnalytics/MetricSelect";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { type KeyTypeValues } from "../../api";
import { useMetricSplitsContext, useReportConfig, useReportDimensionsContext } from "../../Context";
import { type ColKeySort } from "../../ReportData";
import { limitResultsIds, type ReportAddDimension } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import AdvancedOptions from "./AdvancedOptions";
import AggregatorCount from "./AggregatorCount";
import { DataSourceSection } from "./DataSourceSection";
import Dimensions from "./Dimensions";
import FilterResults from "./FilterResults";
import GroupBy from "./GroupBy";
import LimitResults from "./LimitResults";
import ReportItem from "./ReportItem";
import SplitCostMenu from "./SplitCostMenu";
import TimeSelection from "./TimeSelection";

const mouseSensorOptions = {
  activationConstraint: {
    delay: 100,
    tolerance: 50,
  },
};

const disabledDropItems = [
  "datetime:year",
  "datetime:month",
  "datetime:day",
  "datetime:hour",
  "datetime:week",
  "datetime:quarter",
  "datetime:week_day",
];

type Props = {
  handleMetricSelect: (metricVal: Metric, value: MetricWSnap | string) => void;
  handleAggregatorSelect: (aggregator: Aggregator) => void;
  isCSP: boolean;
  queryRunning: boolean;
  handleResetLimits: () => void;
  popoverAnchorEl: any;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  handleFilterOperation: () => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  handleAddDimension: ReportAddDimension;
  handleDelete: (option: MetadataOption[]) => void;
  touched: Set<any>;
  setColKeySort: Dispatch<SetStateAction<ColKeySort | undefined>>;
  colSortDisabled: boolean;
  resetReport: (newConfig: ReportConfig) => void;
};

const DraggableGroupWrapper = ({
  handleMetricSelect,
  handleAggregatorSelect,
  isCSP,
  queryRunning,
  handleResetLimits,
  handleDelete,
  handleAddDimension,
  handleChangeLabels,
  handleFilterOperation,
  popoverAnchorEl,
  setPopoverAnchorEl,
  touched,
  setColKeySort,
  colSortDisabled,
  resetReport,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [selectedSplitItem, setSelectedSplitItem] = useState<MetadataOption | undefined>();

  const { metricSplits, openSplitModal, onIncludeOrigin } = useMetricSplitsContext();
  const { dimensions = [], handleDraggableItemsUpdate } = useReportDimensionsContext();
  const {
    reportConfig: {
      metric,
      calculatedMetric,
      extendedMetric: selectedExtendedMetric,
      calcMetrics,
      extendedMetrics,
      datahubMetrics,
      aggregator,
      rows,
      cols,
      filters,
      dataSource,
    },
  } = useReportConfig();

  const disabledDrop = useCallback(
    (id: string, section: string) => section === Positions.UNUSED && disabledDropItems.includes(id),
    []
  );

  const openSplitMenu = (event: SyntheticEvent, item?: MetadataOption) => {
    setAnchorEl(event.currentTarget);
    setSelectedSplitItem(item);
  };

  const closeSplitMenu = () => {
    setAnchorEl(null);
  };

  const metricSplitModalClicked = useCallback(() => {
    if (selectedSplitItem && openSplitModal) {
      openSplitModal(selectedSplitItem);
      closeSplitMenu();
    }
  }, [selectedSplitItem, openSplitModal]);

  const calculateDraggableItems = useCallback(
    () => ({
      [Positions.ROW]: dimensions
        .filter((option) => option._visible && option._position === Positions.ROW)
        .map((item) => item.id),
      [Positions.COL]: dimensions
        .filter((option) => option._visible && option._position === Positions.COL)
        .map((item) => item.id),
      [Positions.UNUSED]: dimensions
        .filter(
          (option) => option._visible && option._position === Positions.UNUSED && !limitResultsIds.includes(option.id)
        )
        .map((item) => item.id),
    }),
    [dimensions]
  );

  const initCalculateDraggableItems = useCallback(() => {
    const items = calculateDraggableItems();
    items[Positions.ROW] = sortBy(items[Positions.ROW], (i) => findIndex(rows, (r) => i === r));
    items[Positions.COL] = sortBy(items[Positions.COL], (i) => findIndex(cols, (c) => i === c));
    items[Positions.UNUSED] = sortBy(items[Positions.UNUSED], (i) => findIndex(filters, (f) => i === f.id));
    return items;
  }, [rows, cols, filters, calculateDraggableItems]);

  const [draggableItems, setDraggableItems] = useState<Record<string, string[]>>(initCalculateDraggableItems());

  useUpdateEffect(() => {
    setDraggableItems(calculateDraggableItems());
  }, [calculateDraggableItems]);

  const updateDraggableItems = useCallback(
    (items: Record<string, string[]>) => {
      handleDraggableItemsUpdate(items);
      setDraggableItems(items);
    },
    [handleDraggableItemsUpdate]
  );

  const getItemTooltip = useCallback(
    (itemDisabled: boolean) => (itemDisabled ? reportText.DISABLED_CHIP_CALC_METRIC : ""),
    []
  );

  const renderDraggingItem = useCallback(
    (id: string) => (
      <ReportItem
        item={dimensions.find((option) => option.id === id)}
        id={id}
        onItemRemove={handleDelete}
        handleAddDimension={handleAddDimension}
        isDraggable={true}
        grabbing
      />
    ),
    [dimensions, handleAddDimension, handleDelete]
  );

  const renderSplitMenu = useCallback(
    () => (
      <>
        <SplitCostMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          closeSplitMenu={closeSplitMenu}
          metricSplitModalClicked={metricSplitModalClicked}
          selectedSplitItem={selectedSplitItem}
        />
      </>
    ),
    [anchorEl, metricSplitModalClicked, selectedSplitItem]
  );

  const renderReportItems = useCallback(
    (sectionId: string, items: string[], disabled: boolean, isHidden: boolean) => (
      <Box sx={{ display: isHidden ? "none" : "block" }}>
        {items.map((id) => {
          const item = dimensions.find((option) => option.id === id);
          const splits = Object.values(metricSplits ?? []).filter((split) => split.id === item?.id);
          return (
            <ReportItem
              key={id}
              item={item}
              id={id}
              onItemRemove={handleDelete}
              handleAddDimension={handleAddDimension}
              isDraggable={true}
              disabled={disabled}
              tooltip={getItemTooltip(!!item?._disabled)}
              openSplitMenu={openSplitMenu}
              splitsLen={splits.length}
              includeOrigin={splits.length > 0 && splits[0].includeOrigin}
              onIncludeOrigin={onIncludeOrigin}
            />
          );
        })}
        {renderSplitMenu()}
      </Box>
    ),
    [dimensions, handleAddDimension, handleDelete, getItemTooltip, metricSplits, renderSplitMenu, onIncludeOrigin]
  );

  const renderDraggableContent = useCallback(
    (sectionId: string, disabled: boolean, isHidden: boolean) => (items: string[]) =>
      renderReportItems(sectionId, items, disabled, isHidden),
    [renderReportItems]
  );

  const popoverForFilter = (anchorEl: PopoverAnchorEl | null) => {
    setPopoverAnchorEl(anchorEl);
    handleFilterOperation();
  };

  return (
    <DraggableContainer
      values={draggableItems}
      onItemsOrderChanged={updateDraggableItems}
      renderDraggingItem={renderDraggingItem}
      mouseSensorOptions={mouseSensorOptions}
      disabledDrop={disabledDrop}
    >
      <DataSourceSection resetReport={resetReport} />

      <MetricSelect
        calculatedMetrics={calcMetrics}
        extendedMetrics={extendedMetrics}
        datahubMetrics={datahubMetrics}
        handleMetricSelect={handleMetricSelect}
        isCSP={isCSP}
        selectedCalculatedMetric={calculatedMetric}
        selectedExtendedMetric={selectedExtendedMetric}
        selectedMetric={metric}
        handleResetLimits={handleResetLimits}
        dataSource={dataSource}
        disabled={aggregator === Aggregator.COUNT}
        disabledTooltip={tooltipText.METRIC_FILTER_DISABLED}
        sx={{ mb: 4 }}
      />

      <TimeSelection queryRunning={queryRunning} />

      <FilterResults
        handleAddDimension={handleAddDimension}
        handleChangeLabels={handleChangeLabels}
        popoverAnchorEl={popoverAnchorEl}
        setPopoverAnchorEl={popoverForFilter}
        numOfItems={draggableItems[Positions.UNUSED].length}
        renderDraggableContent={renderDraggableContent}
      />

      <GroupBy
        handleAddDimension={handleAddDimension}
        handleChangeLabels={handleChangeLabels}
        popoverAnchorEl={popoverAnchorEl}
        setPopoverAnchorEl={popoverForFilter}
        touched={touched}
        setColKeySort={setColKeySort}
        numOfItems={draggableItems[Positions.ROW].length}
        renderDraggableContent={renderDraggableContent}
      />

      <AggregatorCount
        handleChangeLabels={handleChangeLabels}
        setPopoverAnchorEl={setPopoverAnchorEl}
        popoverAnchorEl={popoverAnchorEl}
        handleDelete={handleDelete}
        handleAddDimension={handleAddDimension}
        getItemTooltip={getItemTooltip}
      />

      <LimitResults
        handleAddDimension={handleAddDimension}
        handleDelete={handleDelete}
        getItemTooltip={getItemTooltip}
      />

      <AdvancedOptions handleAggregatorSelect={handleAggregatorSelect} queryRunning={queryRunning} />

      <Dimensions
        handleAddDimension={handleAddDimension}
        handleChangeLabels={handleChangeLabels}
        popoverAnchorEl={popoverAnchorEl}
        setPopoverAnchorEl={setPopoverAnchorEl}
        colSortDisabled={colSortDisabled}
        numOfItems={draggableItems[Positions.COL].length}
        renderDraggableContent={renderDraggableContent}
      />
    </DraggableContainer>
  );
};

export default DraggableGroupWrapper;
