import PerksView from "./index";
import PerkForm from "./PerkForm/PerkForm";
import PerkView from "./PerkView";
import type { PerkPageId } from "../../Navigation/config/pages";

const PerksRouter = ({ pageId }: { pageId: PerkPageId }) => {
  const perkPageAction = pageId?.split(":")?.[1];

  return (
    <>
      {perkPageAction === "view" && <PerkView />}
      {(perkPageAction === "create" || perkPageAction === "edit") && <PerkForm />}
      {(pageId === "training" || pageId === "isv-solutions" || pageId === "professional-services") && <PerksView />}
    </>
  );
};

export default PerksRouter;
