import React from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import { SubscriptionForm } from "./SubscriptionForm";
import { SubscriptionPanelItem } from "./SubscriptionPanelItem";
import { type SubscriptionByType, type SubscriptionParentByType, type SubscriptionType } from "./types";

export function SubscriptionsPanel<ST extends SubscriptionType>({
  subscriptions,
  handleClose,
  subscriptionParent,
  subscriptionType,
}: {
  subscriptionType: ST;
  subscriptions: SubscriptionByType<ST>[];
  subscriptionParent: SubscriptionParentByType<ST>;
  handleClose: () => void;
}) {
  const [isSubscriptionFormOpen, setIsSubscriptionFormOpen] = React.useState(false);

  const onCreate = () => {
    setIsSubscriptionFormOpen(true);
  };

  const onClose = () => {
    setIsSubscriptionFormOpen(false);
    handleClose();
  };

  const panelPadding = 2;
  const panelWidth = 300;

  return (
    <Box
      sx={{
        width: panelWidth,
        minHeight: 450,
        maxHeight: 600,
        p: panelPadding,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body2" gutterBottom fontWeight={500}>
        Subscriptions ({subscriptions.length})
      </Typography>
      <Divider sx={{ mt: 1, width: panelWidth, mx: -panelPadding }} />
      {subscriptions.length > 0 ? (
        <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
          {subscriptions.map((subscription) => (
            <SubscriptionPanelItem
              key={subscription.id}
              subscription={subscription}
              subscriptionType={subscriptionType}
              subscriptionParent={subscriptionParent}
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body2" color="textSecondary">
            No current subscriptions
          </Typography>
        </Box>
      )}

      <Box>
        <Divider sx={{ mt: -2, mb: 2, width: panelWidth, mx: -panelPadding }} />
        <Button variant="contained" color="primary" fullWidth onClick={onCreate}>
          Create new subscription
        </Button>
      </Box>

      {isSubscriptionFormOpen && (
        <SubscriptionForm
          isOpen={isSubscriptionFormOpen}
          onClose={onClose}
          closeFormDialog={onClose}
          subscriptionParent={subscriptionParent}
          mode="create"
          subscription={undefined}
          subscriptionType={subscriptionType}
        />
      )}
    </Box>
  );
}
