import { useEffect, useMemo } from "react";

import { DashboardType, type Widget } from "@doitintl/cmp-models";
import find from "lodash/find";

import { type Dashboard } from "../DashboardContext";

export const useInitPulse = (dashboard: Dashboard | undefined) => {
  const enabled = useMemo(
    () => Boolean(dashboard?.dashboardType && [DashboardType.Pulse].includes(dashboard.dashboardType)),
    [dashboard?.dashboardType]
  );

  useEffect(() => {
    if (!enabled || !dashboard) {
      return;
    }

    if (dashboard?.dashboardType === DashboardType.Pulse) {
      const hasItem = find(dashboard.widgets, { name: "cloudSpendSummary" });
      if (!hasItem) {
        const cloudSpendSummary: Widget = {
          cardWidth: 12,
          name: "cloudSpendSummary",
        };

        if (dashboard.widgets) {
          dashboard.widgets = [cloudSpendSummary, ...dashboard.widgets];
        } else {
          dashboard.widgets = [cloudSpendSummary];
        }
      }
    }
  }, [dashboard, enabled]);
};
