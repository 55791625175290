import { type JSX, type ReactNode, useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useAssetsContext } from "../../../Context/customer/AssetContext";
import { type Breakdown, type BreakdownData } from "../types";
import { type BreakdownTreeNode, numberOfNestedChildren, toTree } from "./breakdownUtils";

type Props = {
  breakdown: Breakdown;
  allowCondensedBreakdown: boolean;
  dataFilterFunction: (value: BreakdownData) => boolean;
  unit: string;
  valueFormatter: (value: number) => Promise<ReactNode> | ReactNode;
};

const AsyncHandler = ({
  valueFormatter,
  value,
  children,
}: {
  valueFormatter: (value: number) => Promise<ReactNode> | ReactNode;
  value: number;
  children: (resolvedValue: ReactNode) => ReactNode;
}) => {
  const [resolvedChild, setResolvedChild] = useState<ReactNode>(null);

  useEffect(() => {
    let isMounted = true;

    const result = valueFormatter(value);
    if (result instanceof Promise) {
      result.then((resolvedValue: ReactNode) => {
        if (isMounted) {
          setResolvedChild(resolvedValue);
        }
      });
    } else {
      if (isMounted) {
        setResolvedChild(result);
      }
    }

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [valueFormatter, value]);

  return <>{resolvedChild !== null ? children(resolvedChild) : null}</>;
};

export const DetailedBreakdown = ({
  breakdown,
  dataFilterFunction,
  allowCondensedBreakdown,
  unit,
  valueFormatter,
}: Props) => {
  const { assets } = useAssetsContext();

  // Filter the data by the function given
  const filteredBreakdownData = breakdown.data.filter(dataFilterFunction);

  // Convert flat breakdown to tree, using the filtered data
  // If we think about a table to visualize this tree, it should look roughly like this,
  // with "Project" and "Bucket" being the two dimensions given:
  // +------------------+------------------+------------------+
  // | Project          | Bucket           |          Savings |
  // +------------------+------------------+------------------+
  // | "project-1"      | "bucket-1"       |             $100 |
  // |                  +------------------+------------------+
  // |                  | "bucket-2"       |              $50 |
  // +------------------+------------------+------------------+
  // | "project-1"      | "bucket-3"       |             $200 |
  // +------------------+------------------+------------------+
  const breakdownTree = toTree(
    {
      ...breakdown,
      data: filteredBreakdownData,
    },
    Object.values(assets).flat()
  );

  // Special case: If all values of the last column are 1, this looks weird:
  // +------------------+------------------+------------------+------------+
  // | Project          | Cluster          | Node pool        | Node pools |
  // +------------------+------------------+------------------+------------|
  // | "project-1"      | "cluster-1"      | "node-pool-1"    |          1 |
  // |                  |                  |------------------|------------|
  // |                  |                  | "node-pool-2"    |          1 |
  // +------------------+------------------+------------------+------------+
  // Instead, we render only one column and list the dimension values, like this:
  // +------------------+------------------+------------------+
  // | Project          | Cluster          | Node pools       |
  // +------------------+------------------+------------------+
  // | "project-1"      | "cluster-1"      | "node-pool-1"    |
  // |                  |                  |------------------|
  // |                  |                  | "node-pool-2"    |
  // +------------------+------------------+------------------+
  const condenseLastColumn = allowCondensedBreakdown ? filteredBreakdownData.every((row) => row.value === 1) : false;

  // If we do that, we need to skip the last dimension header (will use the unit as normal for the last one)
  const dimensionHeaders = condenseLastColumn
    ? breakdown.dimensions.slice(0, breakdown.dimensions.length - 1)
    : breakdown.dimensions;

  // To achieve a table like above in an HTML table, we need to set the correct `rowspan` attributes on the bigger cells, like this:
  // +------------------+------------------+------------------+
  // | Project          | Cluster          | Node pools       |
  // +------------------+------------------+------------------+
  // | "project-1" (3)  | "cluster-1" (2)  | "node-pool-1"    |
  // |                  |                  |------------------|
  // |                  |                  | "node-pool-2"    |
  // |                  +------------------+------------------+
  // |                  | "cluster-2"      | "node-pool-3"    |
  // +------------------+------------------+------------------+
  // | "project-2" (3)  | "cluster-3" (3)  | "node-pool-4"    |
  // |                  |                  |------------------|
  // |                  |                  | "node-pool-5"    |
  // |                  |                  |------------------|
  // |                  |                  | "node-pool-6"    |
  // +------------------+------------------+------------------+
  // This means we have some table rows that have less cells than others - for each row, we need to figure out two things:
  // 1. How many cells do we need to render?
  //    -> We recursively collect preceding cells whenever the dimension is the first within its parent. For example:
  //       - "cluster-1" is the first dimension within "project-1", so "project-1" is a preceding cell for "cluster-1",
  //         and "node-pool-1" is first within "cluster-1", so "project-1" and "cluster-1" are both preceding cells for "node-pool-1"
  //       - "node-pool-3" is the first dimension within "cluster-2", so "cluster-2" is a preceding cell for "node-pool-3"
  //         (but "project-1" is not a preceding cell, as "cluster-2" is not the first dimension within "project-1")
  // 2. Do any of the cells need to span any rows?
  //    -> For any cell determined as a preceding cell, we recursively calculate the number of children it has (in this
  //       example, the total number of node pools e.g. a project has), which is the number of rows the cell needs to span
  // Once we reach the last recursion level (= the last dimension), we then render the cell, plus any preceding cells.
  const getTableRows = (
    rootNode: BreakdownTreeNode,
    precedingCells: JSX.Element[] = [],
    parentKey: string = "",
    level: number = 0
  ): JSX.Element[] => {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < rootNode.children.length; i++) {
      const node = rootNode.children[i];
      const isFirstDimensionWithinParent = i === 0;

      // We need a unique key - join the current value with the one we got from the parent
      const key = [parentKey, node.dimensionValue].join("-");

      // If we have not reached the last dimension yet, collect preceding cells and go one level deeper
      if (level < breakdown.dimensions.length - 1) {
        // If this cell precedes another, it needs to span the same amount of rows as it has nested children in total
        const newPrecedingCell = (
          <TableCell key={key} rowSpan={numberOfNestedChildren(node)} sx={{ paddingLeft: 0 }}>
            {node.dimensionValue}
          </TableCell>
        );

        // If this is the first cell within its parent, include the previous preceding cells, otherwise just this one
        const newPrecedingCells = isFirstDimensionWithinParent
          ? [...precedingCells, newPrecedingCell]
          : [newPrecedingCell];

        rows.push(...getTableRows(node, newPrecedingCells, key, level + 1));

        continue;
      }

      // If we are at the end, we can render the rows now
      rows.push(
        <TableRow key={key}>
          {/* If this is the first cell within its parent, render the preceding cells */}
          {isFirstDimensionWithinParent ? precedingCells : null}

          {/* If we are condensing the last column, we replace the value by just a list of dimensions */}
          {/* Otherwise, render columns for dimension and value */}
          {condenseLastColumn ? (
            <TableCell sx={{ paddingLeft: 0 }}>{node.dimensionValue}</TableCell>
          ) : (
            <>
              <TableCell sx={{ paddingLeft: 0 }}>{node.dimensionValue}</TableCell>

              <TableCell align="right" sx={{ paddingRight: 0 }}>
                <AsyncHandler valueFormatter={valueFormatter} value={node.value}>
                  {(resolvedValue) => resolvedValue}
                </AsyncHandler>
              </TableCell>
            </>
          )}
        </TableRow>
      );
    }

    return rows;
  };

  return (
    <Table size="small" sx={{ minWidth: 530, width: "auto", mt: 2 }}>
      <TableHead>
        <TableRow>
          {dimensionHeaders.map((dimension) => (
            <TableCell key={dimension} sx={{ paddingLeft: 0 }}>
              {dimension}
            </TableCell>
          ))}
          {/* If we condense the last column, we won't have numbers - align left, not right */}
          {condenseLastColumn ? (
            <TableCell sx={{ paddingLeft: 0 }}>{unit}</TableCell>
          ) : (
            <TableCell align="right" sx={{ paddingRight: 0 }}>
              {unit}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>{getTableRows(breakdownTree)}</TableBody>
    </Table>
  );
};
