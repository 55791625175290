import { Card, CardHeader, Link, Radio } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const RadioCard = ({ card, clickHandler, isChecked }) => {
  const {
    palette: { primary },
  } = useTheme();
  return (
    <Link key={card.title} onClick={clickHandler} style={{ textDecoration: "none", cursor: "pointer" }}>
      <Card sx={{ "&:hover": { borderColor: primary.main } }}>
        <CardHeader
          sx={{ p: 1 }}
          avatar={<Radio checked={isChecked} disableRipple size="small" />}
          title={card.title}
          subheader={card.text}
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
        />
      </Card>
    </Link>
  );
};
