import { memo } from "react";

import { Link } from "react-router-dom";
import { areEqual, type ListChildComponentProps } from "react-window";
import { Box, List, ListItemButton, Typography } from "@mui/material";

import { AlgoliaListSubHeaders } from "./consts";

export const _AlgoliaResultList = (props: ListChildComponentProps) => {
  const { index: parentIndex, data, style } = props;
  if (data.collections.length === 0) {
    return null;
  }
  const { source, items } = data.collections[parentIndex];
  if (!items || items.length === 0) {
    return null;
  }

  const { autocomplete } = data;
  return (
    <Box style={style}>
      <Typography data-cy="search-section-header" sx={{ fontWeight: 500, fontSize: 16, color: "text.primary" }}>
        {AlgoliaListSubHeaders[items[0]._indexName] || "Recent searches"}
      </Typography>
      <List key={parentIndex} {...autocomplete.getListProps()}>
        {items.map((item) => (
          <ListItemButton
            component={Link}
            to={source.getItemUrl({ item }) || item.url}
            sx={(theme) => ({
              '&[aria-selected="true"]': {
                background: theme.palette.action.focus,
              },
              '&[aria-selected="false"]': {
                background: "inherit",
              },
              padding: 0,
              p: 1,
              height: 52,
              mb: 0.5,
              mr: 1,
              pr: 0,
              borderRadius: 0.5,
            })}
            key={item.objectID || item.id}
            data-testid={item.objectID || item.id}
            data-cy="search-result-item"
            {...autocomplete.getItemProps({ item, source })}
          >
            {source.templates.item({ item, state: data.autocompleteState })}
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
export const AlgoliaResultList = memo(_AlgoliaResultList, areEqual);
