import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType, type Collaborators, type PublicAccess, Roles } from "@doitintl/cmp-models";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { type CronExpression, parseExpression } from "cron-parser";
import concat from "lodash/concat";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";
import psl from "psl";
import { string as YupString } from "yup";

import { useApiContext } from "../../../../api/context";
import { globalText, reportText } from "../../../../assets/texts";
import { globalURLs } from "../../../../assets/urls";
import useAnalyticsUsers from "../../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import LoadingButton from "../../../../Components/LoadingButton";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useEntitiesContext } from "../../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUserContext } from "../../../../Context/UserContext";
import { type ReportWSnap } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { useFullScreen } from "../../../../utils/dialog";
import mixpanel from "../../../../utils/mixpanel";
import timezones from "../../../../utils/timezones.json";
import Invites from "../../../IAM/InviteUserDialog/handleInvite";
import { useAnalyticsContext } from "../../CloudAnalyticsContext";
import ShareDialog from "../../dialogs/shareDialog/ShareDialog";
import {
  checkAllowedSubdomains,
  CloudAnalyticsEntities,
  getNewUsers,
  Origin,
  validateUsersFromOrg,
} from "../../utilities";
import { handleChangeSchedule, handleDeleteSchedule } from "./handlers";
import SubscriptionDialog from "./SubscriptionDialog";

const useDeleteButtonStyles = makeStyles({
  root: {
    marginRight: "auto",
  },
});

type ScheduleDialogProps = {
  open: boolean;
  onClose: () => void;
  reportId: string;
  reportData: ReportWSnap["data"];
  isEditMode: boolean;
  origin?: Origin;
};

const getInterval = (frequency: string) => {
  try {
    return parseExpression(frequency);
  } catch {
    return null;
  }
};

const ScheduleDialog = ({
  open,
  onClose,
  reportId,
  reportData,
  isEditMode = false,
  origin = Origin.REPORT,
}: ScheduleDialogProps) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { entities } = useEntitiesContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const deleteButtonClasses = useDeleteButtonStyles();
  const { userRoles } = useUserContext({ allowNull: true, requiredRoles: true });
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { handleMissingPermission } = useAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const { userOrganization } = useCustomerContext();
  const [values, setValues] = useState(reportData.schedule?.to ?? [currentUser.email]);
  const [subject, setSubject] = useState(reportData.schedule?.subject ?? "");
  const [frequency, setFrequency] = useState(reportData.schedule?.frequency ?? "");
  const [timezone, setTimezone] = useState(reportData.schedule?.timezone ?? (DateTime.local().zoneName || "UTC"));
  const [message, setMessage] = useState(reportData.schedule?.body ?? "");
  const [isFrequencyTouched, setIsFrequencyTouched] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [sharedDialogNewUsers, setSharedDialogNewUsers] = useState<string[]>([]);
  const [isAfterShareSave, setIsAfterShareSave] = useState(false);
  const [invalidEmailErrorMsg, setInvalidEmailErrorMsg] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState<string>("");

  const from: string = currentUser.displayName || currentUser.email || "";
  const { fullScreen } = useFullScreen();
  const isCurrentUserUserManager = useMemo(() => userRoles.usersManager || userRoles.doitEmployee, [userRoles]);

  const isFrequencyAtLeast24Hours = (interval: CronExpression) => {
    let d1 = interval.next();
    let d2 = interval.next();
    let hours = (d2.getTime() - d1.getTime()) / 36e5;

    // fix for DST, if the difference is less than 24 hours, it might be a DST rollover
    // try the next intervals
    if (hours < 24) {
      d1 = interval.next();
      d2 = interval.next();
      hours = (d2.getTime() - d1.getTime()) / 36e5;
    }

    return hours >= 24;
  };

  const getHelperText = () => {
    const interval = getInterval(frequency);
    if (!interval) {
      return "Use unix-cron format";
    }

    if (!isFrequencyAtLeast24Hours(interval)) {
      return "Frequency need to have at least 24 hours interval";
    }

    return undefined;
  };

  const isFrequencyValid = useCallback(() => {
    const interval = getInterval(frequency);
    return interval && isFrequencyAtLeast24Hours(interval);
  }, [frequency]);

  const reportOrganization = useMemo(
    () => (reportData.type === AnalyticsResourceType.CUSTOM ? reportData.organization : null),
    [reportData.organization, reportData.type]
  );
  const areUsersFromOrg = useMemo(() => {
    // shareOrgUsers is all the users from the current user organization, this is filtered in the query (index.js).
    const shareOrgUsers = concat(userEmails, invites);
    return validateUsersFromOrg(shareOrgUsers, values, reportOrganization, !!userOrganization);
  }, [invites, reportOrganization, values, userOrganization, userEmails]);

  const customerPrimaryDomain = customer.primaryDomain;

  const handleSetLoading = useCallback(({ newValue, isDelete }) => {
    if (isDelete) {
      setDeleteLoading(newValue);
    } else {
      setSaveLoading(newValue);
    }
  }, []);

  const getNewCollaborators: () => string[] = useCallback(() => {
    const newCollabs: string[] = [];
    for (const collaborator of values) {
      if (!reportData.collaborators?.find((c) => c.email === collaborator)) {
        newCollabs.push(collaborator);
      }
    }
    setSharedDialogNewUsers(newCollabs);
    return newCollabs;
  }, [values, reportData]);

  const saveScheduleChanges = useCallback(async () => {
    await handleChangeSchedule({
      api,
      reportId,
      customerId: reportData.customer.id,
      schedule: { to: values, from, subject, frequency, body: message, timezone },
      isEditMode,
      onClose,
      handleSetLoading,
      customerPrimaryDomain,
      showSharedSnackbar,
    });
    if (!isEditMode) {
      if (origin === Origin.REPORT) {
        mixpanel.track("analytics.reports.schedule", { reportId });
      } else if (origin === Origin.LIST) {
        mixpanel.track("analytics.report-list.schedule", { reportId });
      }
    }
  }, [
    api,
    reportId,
    reportData.customer.id,
    values,
    from,
    subject,
    frequency,
    message,
    timezone,
    isEditMode,
    onClose,
    handleSetLoading,
    customerPrimaryDomain,
    showSharedSnackbar,
    origin,
  ]);

  const addNewUsers = useCallback(
    async (collaborators) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: { data: reportData },
        allUsersAndInvites,
      });
      if (emails.length) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, reportData, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );

  const handleChangeSharing = useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess) => {
      try {
        if (!reportData.customer?.id) {
          return;
        }
        setShareLoading(true);
        if (isCurrentUserUserManager) {
          try {
            await addNewUsers(collaborators);
          } catch (e: any) {
            handleMissingPermission(e);
            setShareLoading(false);
            return;
          }
        }
        await api.request({
          method: "patch",
          url: `/v1/customers/${reportData.customer.id}/analytics/reports/${reportId}/share`,
          data: {
            public: publicAccess ? publicAccess : null,
            collaborators,
          },
        });
        showSharedSnackbar({ message: "Report shared successfully" });
        setOpenShareDialog(false);
        setIsAfterShareSave(true);
      } catch (error) {
        consoleErrorWithSentry(error);
      }

      setShareLoading(false);
    },
    [
      isCurrentUserUserManager,
      api,
      reportData.customer?.id,
      reportId,
      showSharedSnackbar,
      addNewUsers,
      handleMissingPermission,
    ]
  );

  const handleFormSubmit = useCallback(async () => {
    setIsAfterShareSave(false);
    const validators = [isFrequencyValid, () => !!subject];
    if (validators.every((item) => item())) {
      const newCollaborators = getNewCollaborators();
      if (!newCollaborators.length) {
        await saveScheduleChanges();
      } else if (reportData.public !== null) {
        const addedCollaborators = newCollaborators.map((c) => ({ email: c, role: Roles.VIEWER }));
        await handleChangeSharing([...(reportData.collaborators ?? {}), ...addedCollaborators], reportData.public);
        await saveScheduleChanges();
      } else {
        setOpenShareDialog(true);
      }
    } else {
      setIsFrequencyTouched(true);
    }
  }, [
    isFrequencyValid,
    subject,
    getNewCollaborators,
    reportData.public,
    reportData.collaborators,
    saveScheduleChanges,
    handleChangeSharing,
  ]);

  const handleDelete = useCallback(async () => {
    await handleDeleteSchedule({
      api,
      reportId,
      customerId: reportData.customer.id,
      onClose,
      showSharedSnackbar,
      handleSetLoading,
    });
  }, [api, reportId, reportData.customer.id, onClose, showSharedSnackbar, handleSetLoading]);

  // override local values if not saved to db when closing dialog
  useEffect(() => {
    setIsFrequencyTouched(false);
    setSubject(reportData.schedule?.subject || reportData.name);
    setMessage(reportData.schedule?.body || "");
    setTimezone(reportData.schedule?.timezone || DateTime.local().zoneName || "UTC");
    setFrequency(reportData.schedule?.frequency || "30 9 * * *");
    if (!open) {
      setValues(reportData.schedule?.to || [currentUser.email]);
      setInvalidEmailErrorMsg(undefined);
    }
  }, [open, currentUser, reportData.schedule, reportData.name]);

  // When dialog is opened from reportBrowser report data is assigned only on click and not on first render
  // This hook injects relevant values to the dialog without overriding new values entered by user
  useEffect(() => {
    setIsFrequencyTouched(false);
    setSubject((prevState) => {
      if (prevState && prevState !== reportData.name) {
        return prevState;
      }
      return reportData.schedule?.subject || reportData.name;
    });
    setMessage((prevState) => prevState || reportData.schedule?.body || "");
    setFrequency((prevState) => {
      if (prevState && prevState !== "30 9 * * *") {
        return prevState;
      }
      return reportData.schedule?.frequency || "30 9 * * *";
    });
    setValues(reportData.schedule?.to || [currentUser.email]);
    setTimezone((prevState) => {
      if (prevState && prevState !== DateTime.local().zoneName) {
        return prevState;
      }
      return reportData.schedule?.timezone || DateTime.local().zoneName || "UTC";
    });
  }, [reportData, currentUser]);

  useEffect(() => {
    if (isAfterShareSave) {
      if (!getNewCollaborators().length) {
        handleFormSubmit().catch(consoleErrorWithSentry);
      }
    }
  }, [isAfterShareSave, handleFormSubmit, getNewCollaborators]);

  const shareEntities = concat(userEmails, invites);
  const options = uniq([...shareEntities, currentUser.email, ...(reportData.collaborators ?? []).map((c) => c.email)]);
  const timezonesList = useMemo(() => uniq(timezones.map((element) => element.utc).flat()), []);

  const getCronLink = useCallback(() => {
    const baseURL = globalURLs.CRONTAB_GURU;
    if (frequency && isFrequencyValid()) {
      return `${baseURL}/#${frequency.replace(/ /g, "_")}`;
    }
    return baseURL;
  }, [frequency, isFrequencyValid]);
  const isCurrentUserOwner = useMemo(
    () => !!reportData.collaborators.find((c) => c.email === currentUser.email && c.role === Roles.OWNER),
    [currentUser, reportData]
  );

  if (!isCurrentUserOwner) {
    return (
      <SubscriptionDialog
        schedule={reportData.schedule}
        open={open}
        onSave={(newSchedule, isEditMode, message) =>
          handleChangeSchedule({
            api,
            reportId,
            customerId: reportData.customer.id,
            schedule: newSchedule,
            isEditMode,
            onClose,
            handleSetLoading,
            customerPrimaryDomain,
            showSharedSnackbar,
            message,
          })
        }
        onClose={onClose}
        loading={saveLoading}
        reportId={reportId}
      />
    );
  }

  const addEmail = async (value) => {
    if (areUsersFromOrg.length > 0) {
      setInvalidEmailErrorMsg(reportText.USER_NOT_IN_ORG(userOrganization?.data.name));
      return;
    }
    try {
      await YupString().email().validate(value);
    } catch (error) {
      // the added value is not a valid email address
      setInvalidEmailErrorMsg(`"${value}" is not a valid email address`);
      return;
    }
    const domain = value.split("@")[1];
    /* Allow creating option:
      1. The customer's domains/subdomains, and the user has Users manager permission
      2. A slack or teams subdomain (allowed subdomains)
      3. doit email
    */
    if (customer?.domains.some((d) => d === domain || psl.get(d) === domain) || checkAllowedSubdomains(value)) {
      if (values.findIndex((c) => c === value) === -1) {
        setValues((prevState) => [...prevState, value]);
        setInputValue("");
      } else {
        setInvalidEmailErrorMsg(`"${value}" is already included`);
      }
    } else {
      setInvalidEmailErrorMsg(`"${domain}" is not a valid domain`);
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle>Schedule report email delivery</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              sx={{ pt: 1 }}
              multiple
              options={options}
              filterSelectedOptions
              freeSolo
              value={values}
              inputValue={inputValue.trim()}
              onBlur={async (event: any) => {
                if (event.target?.value) {
                  await addEmail(event.target.value);
                }
              }}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onKeyDown={() => {
                setInvalidEmailErrorMsg(undefined);
              }}
              onChange={async (event, newValues, reason) => {
                switch (reason) {
                  case "clear": {
                    setValues([currentUser.email]);
                    break;
                  }
                  case "removeOption": {
                    if ((event as any).key === "Backspace") {
                      return;
                    }
                    setValues(newValues);
                    break;
                  }
                  case "selectOption":
                  case "createOption": {
                    await addEmail(newValues[newValues.length - 1]);
                  }
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={option}
                    color={areUsersFromOrg.includes(option) ? "secondary" : "primary"}
                    {...getTagProps({ index })}
                    key={index}
                    disabled={option === currentUser.email}
                    deleteIcon={option === currentUser.email ? <></> : <CancelIcon />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="To"
                  fullWidth
                  size="small"
                  error={!!invalidEmailErrorMsg}
                  helperText={invalidEmailErrorMsg}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: async (e: any) => {
                      if (e.key === "Enter" || e.key === " " || e.key === ",") {
                        e.stopPropagation();
                        e.preventDefault();
                        if (e.target?.value) {
                          await addEmail(e.target.value);
                        }
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Subject"
              fullWidth
              size="small"
              value={subject}
              error={!subject}
              onChange={(event) => {
                setSubject(event.target.value);
              }}
              data-testid="subject"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Message"
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              fullWidth
              multiline
              rows={3}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Frequency"
              fullWidth
              value={frequency}
              onChange={(event) => {
                setFrequency(event.target.value);
                setIsFrequencyTouched(true);
              }}
              size="small"
              error={isFrequencyTouched && !isFrequencyValid()}
              helperText={isFrequencyTouched && getHelperText()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterSelectedOptions
              disableClearable
              value={timezone}
              options={timezonesList}
              onChange={(event, value) => {
                setTimezone(value || "");
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Time Zone" fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle>Notice</AlertTitle>
              Cron jobs are scheduled at recurring intervals, specified using &nbsp;
              <Link target="_blank" rel="noreferrer" href={getCronLink()}>
                unix-cron format
              </Link>
              . You can define a schedule so that your job runs daily, or on specific days of the week. For example:{" "}
              <br />
              <Grid container>
                {[
                  {
                    desc: "- Daily at 9:30am:",
                    cron: "30 9 * * *",
                  },
                  {
                    desc: "- Every Monday at 10:00am:",
                    cron: "0 10 * * 1",
                  },
                  {
                    desc: "- On the first day of a month at 6:45pm:",
                    cron: "45 18 1 * *",
                  },
                ].map((row, i) => (
                  <Grid container item key={i} xs={12}>
                    <Grid item xs={8}>
                      {row.desc}
                    </Grid>
                    <Grid item>{row.cron}</Grid>
                  </Grid>
                ))}
              </Grid>
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        {isEditMode && (
          <LoadingButton
            onClick={handleDelete}
            size="medium"
            color="primary"
            variant="contained"
            className={deleteButtonClasses.root}
            loading={deleteLoading}
            disabled={deleteLoading || saveLoading}
            mixpanelEventId="analytics.schedule-dialog.delete"
          >
            {globalText.DELETE}
          </LoadingButton>
        )}

        <Button color="primary" onClick={onClose} variant="text" disabled={deleteLoading || saveLoading}>
          {globalText.CANCEL}
        </Button>

        <LoadingButton
          onClick={handleFormSubmit}
          color="primary"
          variant="contained"
          loading={saveLoading}
          disabled={deleteLoading || saveLoading || areUsersFromOrg.length > 0 || !isFrequencyValid() || !subject}
          mixpanelEventId={`analytics.schedule-dialog.${isEditMode ? globalText.UPDATE : globalText.SAVE}`}
        >
          {isEditMode ? globalText.UPDATE : globalText.SAVE}
        </LoadingButton>
      </DialogActions>

      <ShareDialog
        open={openShareDialog}
        onClose={() => {
          setOpenShareDialog(false);
        }}
        title={reportText.SCHEDULE_DIALOG_TITLE}
        entity={CloudAnalyticsEntities.REPORT}
        handleChangeSharing={handleChangeSharing}
        predefinedCollaborators={sharedDialogNewUsers}
        loading={shareLoading}
        organization={reportOrganization}
        shareEntities={[reportData]}
      />
    </Dialog>
  );
};

export default ScheduleDialog;
