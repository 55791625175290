import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useApiContext } from "../../../../api/context";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { CircularProgressLoader } from "../../../../Components/Loader";
import { Stepper } from "../../../../Components/Stepper";
import { customersRoute } from "../../../../Navigation/core/utils";
import { consoleErrorWithSentry, useErrorSnackbar } from "../../../Integrations/Slack/utils";
import StepExport from "./StepExport";
import StepPermissions from "./StepPermissions";
import StepTemplate from "./StepTemplate";
import StepTestConnection from "./StepTestConnection";
import { getStepStatus } from "./utils";

export default function AzureOnboarding() {
  const history = useHistory();
  const customerId = useCustomerId();

  const [currentStep, setCurrentStep] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [wasTestSuccessful, setWasTestSuccessful] = useState(false);
  const [storageAccountName, setStorageAccountName] = useState<string | null>(null);
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`v1/customers/${customerId}/azure/get-storage-account-name`);
        setStorageAccountName(response.data.storageAccountName);
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to fetch storage account name");
      }
    })();
  }, [api, customerId, errorSnackbar]);

  if (!storageAccountName) {
    return <CircularProgressLoader />;
  }

  return (
    <Stepper
      backButtonLabel="Back"
      loading={false}
      onCancel={() => history.push(customersRoute(customerId))}
      onSubmit={() => history.push(customersRoute(customerId))}
      steps={[
        {
          children: <StepPermissions />,
          label: "Permissions",
          order: 0,
          required: true,
          state: getStepStatus(0, currentStep),
        },
        {
          children: <StepTemplate storageAccountName={storageAccountName} />,
          label: "Template",
          order: 1,
          required: true,
          state: getStepStatus(1, currentStep),
        },
        {
          children: <StepExport storageAccountName={storageAccountName} />,
          label: "Billing Export",
          order: 2,
          required: true,
          state: getStepStatus(2, currentStep),
        },
        {
          children: (
            <StepTestConnection
              storageAccountName={storageAccountName}
              subscriptionId={subscriptionId}
              setSubscriptionId={setSubscriptionId}
              wasTestSuccessful={wasTestSuccessful}
              setWasTestSuccessful={setWasTestSuccessful}
            />
          ),
          label: "Test connection",
          order: 3,
          required: true,
          state: wasTestSuccessful ? "complete" : currentStep === 3 ? "editing" : "incomplete",
          hideBackButton: wasTestSuccessful,
        },
      ]}
      getCurrentStep={setCurrentStep}
      submitButtonLabel="Done"
      contentSx={{
        mt: 3,
        mb: 3,
      }}
      footerMaxWidth={860}
      maxWidth={1000}
    />
  );
}
