import { type AlgoliaIndexType } from "./types";

export const AlgoliaIndex = ["customers", "users", "entities", "assets", "invoices", "routes"] as const;
export const CloudAnalyticsIndex = [
  "attributions",
  "attributionGroups",
  "reports",
  "budgets",
  "alerts",
  "metrics",
] as const;

export const AlgoliaListSubHeaders: { [key in AlgoliaIndexType]: string } = {
  customers: "Customers",
  users: "Users",
  entities: "Billing profiles",
  assets: "Assets",
  invoices: "Invoices",
  attributions: "Attributions",
  attributionGroups: "Attribution groups",
  alerts: "Alerts",
  budgets: "Budgets",
  metrics: "Metrics",
  reports: "Reports",
  routes: "Quick links",
};
