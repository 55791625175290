import { type ChangeEvent, Fragment, useState } from "react";

import { useHistory } from "react-router-dom";
import { Box, Collapse } from "@mui/material";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { handleResponseError } from "../../../../api/axiosClient";
import { useApiContext } from "../../../../api/context";
import { helpURLs } from "../../../../assets/urls";
import LoadingButton from "../../../../Components/LoadingButton";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { UpdateErrorAlert } from "./Alerts/UpdateErrorAlert";

const DOIT_ACCOUNT_ID = "068664126052";

/**
 * Component to handle adding account manually by following documentation
 */
export const AddArn = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const [arn, setArn] = useState<string>("");
  const [loadingArn, setLoadingArn] = useState<boolean>(false);
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const onChangeArnInput = (event: ChangeEvent<HTMLInputElement>) => {
    setArn(event.target.value);
    setShowError(false);
  };

  const handleAddArn = async () => {
    setLoadingArn(true);

    try {
      await api.post(`/v1/customers/${customer.id}/cloudconnect/amazon-web-services`, {
        arn,
      });

      history.goBack();
      setLoadingArn(false);
    } catch (error: any) {
      handleResponseError(error, (err) => {
        setErrorMessage(err);
        setShowError(true);
        setLoadingArn(false);
      });
    }
  };

  return (
    <>
      <Box pb={2}>
        <Link href={helpURLs.ADD_AWS_IAM_ROLE} target="_blank" variant="subtitle1" rel="noopener noreferrer">
          How To Link Your AWS Account
        </Link>
      </Box>
      <Box display="flex">
        <Box>
          <Typography variant="body1">
            Our AWS Account:
            <br />
            Your External ID:
          </Typography>
        </Box>
        <Box pl={1}>
          <Typography variant="body1" color="textSecondary">
            {DOIT_ACCOUNT_ID}
            <br />
            {customer.id}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" pt={3} justifyContent="space-between" alignItems="center">
        <Box width="100%">
          <TextField
            id="outlined-name"
            label="Role ARN"
            variant="outlined"
            value={arn}
            onChange={onChangeArnInput}
            fullWidth
          />
        </Box>
        <Box pl={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleAddArn}
            loading={loadingArn}
            disabled={loadingArn || !arn.length}
            mixpanelEventId="settings.arn.add"
          >
            Add
          </LoadingButton>
        </Box>
      </Box>
      <Box pt={1}>
        <Collapse in={showError}>
          <UpdateErrorAlert error={errorMessage} onClose={() => setShowError(false)} />
        </Collapse>
      </Box>
    </>
  );
};
