import { Stack } from "@mui/material";

import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import { useBillingProfiles } from "../../hooks";
import { BillingItem } from "./BillingItem";

type Props = {
  selectedPriorityId: string;
  handleSelection: (text: string) => void;
};

export const BillingProfileSelector = ({ selectedPriorityId, handleSelection }: Props) => {
  const customerId = useCustomerId();
  const [billingProfiles] = useBillingProfiles(customerId);

  return (
    <Stack spacing={3}>
      {billingProfiles?.map((billingProfile, index) => (
        <BillingItem
          checked={selectedPriorityId ? billingProfile.priorityId === selectedPriorityId : index === 0}
          onClick={() => handleSelection(billingProfile.priorityId)}
          billingProfile={billingProfile}
          key={index}
        />
      ))}
    </Stack>
  );
};
