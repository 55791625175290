import { type FormikErrors } from "formik";

import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { CustomInput } from "../common/CustomInput";
import { PanelWrapper } from "../common/PanelWrapper";

export const CONTACT_PANEL_ID = "contact-details";

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: CreateBillingProfileData;
  errors: FormikErrors<CreateBillingProfileData>;
};

export const ContactPanel = ({ values, errors, handleChange }: Props) => (
  <PanelWrapper title="Contact details" subtitle="Accounts payable details" id={CONTACT_PANEL_ID} paddingTop={9}>
    <CustomInput
      data-cy="firstName"
      name="firstName"
      label="First name"
      value={values.firstName}
      onChange={handleChange}
      error={Boolean(errors.firstName)}
      maxLength={16}
      required
    />
    <CustomInput
      data-cy="lastName"
      name="lastName"
      label="Last name"
      value={values.lastName}
      onChange={handleChange}
      error={Boolean(errors.lastName)}
      maxLength={20}
      required
    />
    <CustomInput
      data-cy="email"
      name="email"
      label="Email address"
      value={values.email}
      onChange={handleChange}
      error={Boolean(errors.email)}
      maxLength={48}
      required
    />
    <CustomInput
      data-cy="phone"
      name="phone"
      label="Phone number"
      value={values.phone}
      onChange={handleChange}
      error={Boolean(errors.phone)}
      maxLength={20}
      required
    />
  </PanelWrapper>
);
