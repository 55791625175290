import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepperProps, type StepState } from "../../../Components/Stepper";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { Platform } from "../../types";

type Props = {
  stepComponents: React.ReactNode[];
  states: StepState[];
  breadcrumb: string[];
  onCancel: () => void;
  platform?: Platform;
  onChangeStep?: (step: number) => void;
  onSubmitTicket: () => void;
  submitLoading?: boolean;
  errorMsg?: any;
};

export const NewTicketStepper = ({
  stepComponents,
  states,
  breadcrumb,
  onCancel,
  onChangeStep,
  onSubmitTicket,
  platform,
  submitLoading,
  errorMsg,
}: Props) => {
  const history = useHistory();
  const { template } = useParams<{ template: string }>();
  const { customer } = useCustomerContext();
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [currentStep, getCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const showLastStep = platform === Platform.gcp || platform === Platform.aws;

  useEffect(() => {
    if (template) {
      setOverrideStep(2);
    }
  }, [template]);

  useEffect(() => {
    typeof submitLoading !== "undefined" && setLoading(submitLoading);
  }, [submitLoading]);

  useEffect(() => {
    if (!submitLoading && loading && !errorMsg) {
      // Show success message
      snackbar.onOpen({
        message: "Successfully created your request",
        variant: "success",
        autoHideDuration: 30000,
        withClose: true,
      });

      if (!showLastStep) {
        history.push(`/customers/${customer.id}/support`);
      } else {
        setOverrideStep(3);
      }
    }
    // error message
    if (errorMsg) {
      snackbar.onOpen({
        message: errorMsg?.response?.data || errorMsg?.error,
        variant: "error",
        autoHideDuration: 30000,
        withClose: true,
      });
    }
  }, [customer.id, errorMsg, history, loading, showLastStep, snackbar, submitLoading]);

  useEffect(() => {
    onChangeStep?.(currentStep);
  }, [currentStep, onChangeStep]);

  const onSubmit = async () => {
    setLoading(true);
    onSubmitTicket();
  };

  const onComplete = async () => history.push(`/customers/${customer.id}/support`);
  const lastStep = currentStep === 3 ? "complete" : "editing";
  const lastStepButtonLabel = currentStep === 3 ? "Finish" : "Submit";

  const { t } = useTranslation("services");

  const steps: Step[] = useMemo(
    () => [
      {
        label: t("NewTicketStepper.type"),
        order: 1,
        required: true,
        state: states[0],
        children: [stepComponents[0]],
        gridWidth: 4,
        breadcrumbValue: breadcrumb[0],
      },
      {
        label: t("NewTicketStepper.severity"),
        order: 2,
        required: true,
        state: states[1],
        children: [stepComponents[1]],
        gridWidth: 4,
        breadcrumbValue: breadcrumb[1],
      },
      {
        label: t("NewTicketStepper.details"),
        order: 3,
        required: true,
        state: states[2],
        children: [stepComponents[2]],
        gridWidth: 12,
      },
      {
        label: t("NewTicketStepper.access"),
        order: 4,
        required: true,
        state: lastStep,
        children: [stepComponents[3]],
        gridWidth: 12,
        breadcrumbValue: false,
      },
    ],
    [breadcrumb, lastStep, states, stepComponents, t]
  );

  const platformStep = useMemo(() => {
    const lastStepIndex = showLastStep ? steps.length : steps.length - 1;
    return platform === Platform.credits
      ? steps.filter((step) => step.order !== 2 && step.order !== 4) // credits don't have severity or access
      : steps.slice(0, lastStepIndex);
  }, [platform, showLastStep, steps]);

  const getStepperWidth = () => {
    const stepWidth = showLastStep ? 740 : 832;
    return platform === Platform.credits ? 1110 : stepWidth;
  };

  const stepperProps: StepperProps = {
    steps: platformStep,
    onCancel,
    onSubmit,
    onComplete: showLastStep ? onComplete : undefined,
    disableSubmit: false,
    loading,
    overrideStep,
    getCurrentStep,
    maxWidth: getStepperWidth(),
    submitButtonLabel: lastStepButtonLabel,
    footerMaxWidth: getStepperWidth() - 120,
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Stepper {...stepperProps} />
    </Box>
  );
};
