import { useState } from "react";

import { Button } from "@mui/material";

import { EditAWSPayerConfigModal } from "./EditAWSPayerConfigModal";
import { type ExtendedFlexsavePayerConfigs } from "./types";

type Props = {
  payerConfig: ExtendedFlexsavePayerConfigs;
};

export const EditAWSPayerConfigButton = ({ payerConfig }: Props) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsFormVisible(true)}>Edit</Button>
      {isFormVisible && <EditAWSPayerConfigModal onClose={() => setIsFormVisible(false)} payerConfig={payerConfig} />}
    </>
  );
};
