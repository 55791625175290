import { type PerkModel, SolveTierPackageSKUs, type TierModel } from "@doitintl/cmp-models";

import { type TierWithRef } from "../../Context/TierProvider";

type TemplateFunction = (displayName: string) => string;

type PaymentRequirement = "free" | "fourFree" | "paid";

export const helperTemplates: Record<PaymentRequirement, TemplateFunction> = {
  free: (displayName: string): string =>
    `Included with the Cloud ${displayName.includes("Solve") ? "" : "Solve"} ${displayName} plan`,
  fourFree: (displayName: string): string =>
    `Four Accelerators included with the Cloud ${displayName.includes("Solve") ? "" : "Solve"} ${displayName} plan`,
  paid: (displayName: string): string =>
    `Available on-demand for a fee with the Cloud ${displayName.includes("Solve") ? "" : "Solve"} ${displayName} plan`,
};

const getAcceleratorTemplate = (tier: TierModel): TemplateFunction => {
  switch (tier?.sku) {
    case SolveTierPackageSKUs.ADVANTAGE_ONLY:
    case SolveTierPackageSKUs.STANDARD_BASE:
    case SolveTierPackageSKUs.STANDARD_VARIABLE:
    case SolveTierPackageSKUs.ENHANCED_BASE:
    case SolveTierPackageSKUs.ENHANCED_VARIABLE:
      return helperTemplates.paid;
    case SolveTierPackageSKUs.PREMIUM_BASE:
    case SolveTierPackageSKUs.PREMIUM_VARIABLE:
      return helperTemplates.fourFree;
    case SolveTierPackageSKUs.ENTERPRISE_BASE:
    case SolveTierPackageSKUs.ENTERPRISE_VARIABLE:
    case SolveTierPackageSKUs.LEGACY_RESOLD:
      return helperTemplates.free;
    default:
      return helperTemplates.paid;
  }
};

const getWorkshopTemplate = (tier: TierModel): TemplateFunction => {
  switch (tier?.sku) {
    case SolveTierPackageSKUs.ADVANTAGE_ONLY:
    case SolveTierPackageSKUs.STANDARD_BASE:
    case SolveTierPackageSKUs.STANDARD_VARIABLE:
      return helperTemplates.paid;
    case SolveTierPackageSKUs.ENHANCED_BASE:
    case SolveTierPackageSKUs.ENHANCED_VARIABLE:
    case SolveTierPackageSKUs.PREMIUM_BASE:
    case SolveTierPackageSKUs.PREMIUM_VARIABLE:
    case SolveTierPackageSKUs.ENTERPRISE_BASE:
    case SolveTierPackageSKUs.ENTERPRISE_VARIABLE:
    case SolveTierPackageSKUs.LEGACY_RESOLD:
      return helperTemplates.free;
    default:
      return helperTemplates.paid;
  }
};

/**
 * Get the helper text for the perk detailing whether the user must pay.
 * @param tier the customer's tier
 * @param perk the perk being requested
 */
export const getHelperText = (perk: PerkModel, tier?: TierWithRef): string | undefined => {
  const singleUseTiers: Array<TierModel["sku"]> = [
    SolveTierPackageSKUs.SINGLE_TICKET,
    SolveTierPackageSKUs.SINGLE_WORKSHOP,
    SolveTierPackageSKUs.SINGLE_ACCELERATOR,
    SolveTierPackageSKUs.SINGLE_TRAINING,
  ];

  if (!tier || tier.packageType !== "solve" || singleUseTiers.includes(tier.sku)) {
    return undefined;
  }

  if (tier.trialTier || perk.fields.type === "training") {
    return helperTemplates.free(tier.displayName);
  }

  if (perk.fields.acceleratorProgram) {
    return getAcceleratorTemplate(tier)(tier.displayName);
  }

  if (perk.fields.type === "workshop") {
    return getWorkshopTemplate(tier)(tier.displayName);
  }

  return undefined;
};
