import { type FC } from "react";

import { Chip, useTheme } from "@mui/material";
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getSmoothStepPath } from "@xyflow/react";

const ConditionEdge: FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    palette: { general, divider },
  } = useTheme();

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ stroke: divider }} />
      <EdgeLabelRenderer>
        <Chip
          label={data?.label}
          sx={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            backgroundColor: general.backgroundDark,
            color: "text.primary",
            borderColor: divider,
          }}
          variant="outlined"
        />
      </EdgeLabelRenderer>
    </>
  );
};

export default ConditionEdge;
