import { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";

import { type GcpSqlResource, type ResourceDetailType } from "./resourceDetailType";

type Props = {
  index: number;
  values: GcpSqlResource;
  onDelete: (index: string) => void;
  onChange: (index: string, data: ResourceDetailType) => void;
};

const GcpSqlResourceDetails = ({ index, values, onDelete, onChange }: Props) => {
  const assetIndex = values.assetIndex;
  const [instanceId, setInstanceId] = useState<string>(values?.instanceId ?? "");
  const [databaseSchemaTableName, setDatabaseSchemaTableName] = useState<string>(values?.databaseSchemaTableName ?? "");

  return (
    <Stack>
      {index > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
          data-cy={`gcpSqlResourceHeaderBox-${index}`}
        >
          <Typography>Resource {index + 1}</Typography>
          <Button
            onClick={() => onDelete(assetIndex)}
            data-cy={`gcpSqlResourceDeleteButton-${index}`}
            sx={{ mb: 0.5 }}
            color="error"
          >
            <DeleteIcon />
            Remove resource
          </Button>
        </Box>
      )}
      <TextField
        label="Instance ID"
        InputLabelProps={{ shrink: true }}
        value={instanceId}
        onChange={(e) => {
          setInstanceId(e.target.value);
          onChange(assetIndex, { assetIndex, instanceId: e.target.value, databaseSchemaTableName });
        }}
        size="small"
        sx={{ my: 1 }}
        data-cy={`instanceIdField-${index}`}
      />
      <TextField
        label="Database/schema/table name"
        InputLabelProps={{ shrink: true }}
        value={databaseSchemaTableName}
        onChange={(e) => {
          setDatabaseSchemaTableName(e.target.value);
          onChange(assetIndex, { assetIndex, instanceId, databaseSchemaTableName: e.target.value });
        }}
        size="small"
        sx={{ my: 1 }}
        data-cy={`databaseSchemaTableNameField-${index}`}
      />
    </Stack>
  );
};

export default GcpSqlResourceDetails;
