import { useMemo } from "react";

import { type AWSFlexsaveConfigurationModel } from "@doitintl/cmp-models";
import { Card, CardHeader, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ColumnsChart } from "../../../Components/Charts/ColumnsChat/ColumnsChart";
import { useCurrency } from "../../../Components/hooks/useCurrency";
import { generateDailyOptions } from "../Resold/Overview/Chart/SavingsChartUtilsAWS";

type Props = {
  data: AWSFlexsaveConfigurationModel;
};

export const DailySavingsChart = ({ data }: Props) => {
  const theme = useTheme();
  const { customerCurrencySymbol } = useCurrency();

  const options = useMemo(() => {
    if (!data.dailySavingsHistory) {
      return null;
    }

    return generateDailyOptions(data.dailySavingsHistory, theme, customerCurrencySymbol);
  }, [data, theme, customerCurrencySymbol]);

  if (!options) {
    return null;
  }

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: `1px solid ${theme.palette.general.divider}`,
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "h4" }}
          title="Flexsave summary"
          subheader="The data in this graph is subject to a delay of between 1-33 days"
          subheaderTypographyProps={{ variant: "body2" }}
        />
      </Stack>
      <ColumnsChart options={options} />
    </Card>
  );
};
