import { useEffect, useMemo, useState } from "react";

import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import ProcessIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { amber, blue, green, purple, red, teal } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import { FilterContextProvider } from "../../../Components/FilterTable/Context";
import { TableFilterBar } from "../../../Components/FilterTable/Filterbar/TableFilterBar";
import Hide from "../../../Components/HideChildren/Hide";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import useTableState from "../../../Components/hooks/useTableState";
import { formatCurrency, formatDecimalNumber, sanitizeDate } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import AggregationsBar from "./AggregationsBar";
import { autopilotVal, type FlexsaveOrder } from "./types";

const filterColumns = [
  {
    label: "Customer",
    path: "metadata.customer.primaryDomain",
  },
  {
    label: "Autopilot",
    path: "execution",
    toOption: (value: any) => ({
      value: value ? "auto" : "unmanaged",
      label: value ? "True" : "False",
    }),
  },
  {
    label: "Order ID",
    path: "id",
    toOption: (value: any) => ({ value, label: `${value}` }),
  },
  {
    label: "Status",
    path: "status",
    comparators: ["==", "!="],
    toOption: (value: any) => ({ value, label: capitalize(value) }),
  },
  {
    label: "Month",
    path: "config.startDate",
    type: "DateTime",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    placeholder: "YYYY-MM",
    transform: (value: any) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL", { zone: "utc" })),
    validate: (value: any) => DateTime.fromFormat(value, "yyyy-LL")?.isValid,
    toOption: (value: any) => {
      const strValue = value.toFormat("yyyy-LL");
      return {
        value: strValue,
        label: strValue,
      };
    },
  },
  {
    label: "Region",
    path: "config.region",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Instance Family",
    path: "config.instanceFamily",
    comparators: ["==", "!="],
  },
  {
    label: "Instance Type",
    path: "config.instanceType",
    comparators: ["==", "!="],
  },
  {
    label: "Instance OS",
    path: "config.operatingSystem",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Savings",
    path: "savings",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    placeholder: "number",
    transform: (value: any) => parseFloat(value),
    validate: (value: any) => !isNaN(parseFloat(value)),
  },
  {
    label: "# Instances",
    path: "config.numInstances",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    placeholder: "number",
    transform: (value: any) => parseFloat(value),
    validate: (value: any) => !isNaN(parseFloat(value)),
  },
] as const;

const useRowStyles = makeStyles((theme) => ({
  rowNoBottomBorder: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  rowSeparator: {
    height: theme.spacing(3),
  },
  infoCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: 500,
  },
  statusNew: {
    color: blue[500],
  },
  statusPending: {
    color: amber[500],
  },
  statusActive: {
    color: green[500],
  },
  statusRetired: {
    color: teal[500],
  },
  statusFailed: {
    color: red[500],
  },
  statusCanceled: {
    color: purple[500],
  },
  savingsPositive: {
    color: red[500],
  },
  savingsNegative: {
    color: green[500],
  },
  linearProgressRoot: {
    height: 16,
    borderRadius: theme.spacing(1),
  },
  linearProgressBar: {
    borderRadius: theme.spacing(1),
  },
  linearProgressDashed: {
    // backgroundSize: "21px 21px",
    background: theme.palette.action.disabledBackground,
    animation: "none",
  },
  linearProgressBar2: {
    backgroundColor: red[300],
  },
  note: {
    maxWidth: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

type OnActivate = (order: FlexsaveOrder) => () => void;

type Props = {
  expanded: boolean;
  order: FlexsaveOrder;
  onActivate: OnActivate;
};

const FlexsaveOrdersListRow = ({ expanded, order, onActivate }: Props) => {
  const theme = useTheme();
  const classes = useRowStyles();
  const [open, setOpen] = useState(expanded);

  const isFlexRIAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);

  const colSpan = 13;
  const getGraph = () => {
    if (order.showPricingInfo) {
      const getData = () => {
        if (order.execution === autopilotVal) {
          return orderBy(
            map(order.autopilot.utilization, (utilized, day) => {
              const utilizedValue = Object.values(utilized).reduce((acc, val) => acc + val, 0);
              return {
                category: day,
                utilized: utilizedValue,
                underUtilized: order.normalizedUnits.unitsPerDay - utilizedValue,
              };
            }),
            ["category"]
          );
        }
        return orderBy(
          map(order.utilization, (utilized, day) => ({
            category: day,
            utilized,
            underUtilized: order.normalizedUnits.unitsPerDay - utilized,
          })),
          ["category"]
        );
      };

      const categories: string[] = [];
      const series = [
        {
          name: "Underutilization",
          showInLegend: false,
          color: red[200],
          data: [] as number[],
        },
        {
          name: "Utilization",
          showInLegend: false,
          color: green[200],
          data: [] as number[],
        },
      ] as const;

      const v = getData();
      v.forEach((point) => {
        categories.push(point.category);
        series[0].data.push(point.underUtilized);
        series[1].data.push(point.utilized);
      });

      return {
        chart: {
          type: "area",
          backgroundColor: "transparent",
          height: 245,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Normalized Utilization",
          margin: 8,
          style: {
            ...theme.typography.subtitle2,
            color: theme.palette.text.primary,
          },
        },
        xAxis: {
          categories,
          tickmarkPlacement: "on",
          title: {
            enabled: false,
          },
          labels: {
            style: {
              ...theme.typography.caption,
              color: theme.palette.text.primary,
            },
          },
        },
        yAxis: {
          labels: {
            format: "{value}%",
            style: {
              ...theme.typography.caption,
              color: theme.palette.text.primary,
            },
          },
          title: {
            enabled: false,
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name} (NFU)</span><br/> <b>{point.y:,.2f}</b> ({point.percentage:.1f}%)<br/>',
          split: true,
        },
        series,
        plotOptions: {
          area: {
            stacking: "percent",
            lineColor: "#ffffff",
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: "#ffffff",
            },
          },
        },
      };
    }
  };

  const factor = (normalizedUnits: Exclude<FlexsaveOrder["normalizedUnits"], null>) =>
    normalizedUnits.factor < 1 ? normalizedUnits.factor : 1;

  const getHours = (utilization: Exclude<FlexsaveOrder["autopilot"], undefined>["utilization"]) =>
    24 * Object.keys(utilization).length;

  return (
    <>
      <TableRow className={clsx({ [classes.rowNoBottomBorder]: order.showPricingInfo })}>
        <Hide smDown>
          <TableCell size="small" padding="checkbox" align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
              disabled={!order.showPricingInfo}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </Hide>
        <TableCell padding="none">{order.id}</TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Link component={RouterLink} to={`/customers/${order.customer.id}`} variant="body2" color="inherit">
            {order.metadata.customer.primaryDomain}
          </Link>
        </TableCell>
        <Hide smDown>
          <TableCell className={classes.noWrap}>
            {DateTime.fromJSDate(order.config.startDate.toDate()).toFormat("LLL, yyyy")}
          </TableCell>
          <TableCell padding="none">
            <Typography
              className={clsx(classes.fontWeightBold, {
                [classes.statusNew]: order.status === "new",
                [classes.statusPending]: order.status === "pending",
                [classes.statusActive]: order.status === "active",
                [classes.statusRetired]: order.status === "retired",
                [classes.statusFailed]: order.status === "failed",
                [classes.statusCanceled]: order.status === "canceled",
              })}
              variant="inherit"
            >
              {capitalize(order.status)}
            </Typography>
          </TableCell>
          <TableCell className={classes.noWrap}>{order.config.region}</TableCell>
          <TableCell>{order.config.instanceType}</TableCell>
          <TableCell>{order.config.operatingSystem}</TableCell>
          <TableCell>{order.config.numInstances}</TableCell>
        </Hide>
        <TableCell>
          {order.showPricingInfo ? (
            <Typography
              className={clsx({
                [classes.fontWeightBold]: order.savings !== 0,
                [classes.savingsNegative]: order.savings > 0,
                [classes.savingsPositive]: order.savings < 0,
              })}
              variant="inherit"
            >
              {formatCurrency(order.savings, "USD", 2)}
            </Typography>
          ) : (
            "N/A"
          )}
        </TableCell>
        <Hide smDown>
          <TableCell align="center" padding="none">
            {isFlexRIAdmin && order.status === "pending" && (
              <Tooltip title="Approve order">
                <IconButton aria-label="Approve order" size="small" onClick={onActivate(order)}>
                  <ProcessIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </Hide>
      </TableRow>
      {order.showPricingInfo && (
        <Hide smDown>
          <TableRow>
            <TableCell colSpan={colSpan} className={classes.infoCell}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} lg={6} xl={4} spacing={1} alignContent="flex-start">
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Reservation type</TableCell>
                              <TableCell align="left">Hourly</TableCell>
                              <TableCell align="left">Full period</TableCell>
                              <TableCell align="left">Savings over on-demand</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left" component="th" scope="row">
                                On-demand
                              </TableCell>
                              <TableCell align="left">${formatDecimalNumber(order.pricing.onDemand, 6)}</TableCell>
                              <TableCell align="left">
                                $
                                {formatDecimalNumber(order.pricing.onDemandNormalized * order.normalizedUnits.total, 2)}
                              </TableCell>
                              <TableCell align="left">-</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" component="th" scope="row">
                                Flexsave
                              </TableCell>
                              <TableCell align="left">${formatDecimalNumber(order.pricing.flexible, 6)}</TableCell>
                              <TableCell align="left">
                                $
                                {formatDecimalNumber(order.pricing.flexibleNormalized * order.normalizedUnits.total, 2)}
                              </TableCell>
                              <TableCell align="left">
                                {formatDecimalNumber((1 - order.pricing.flexible / order.pricing.onDemand) * 100, 2)}%
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" component="th" scope="row">
                                Amazon RI
                              </TableCell>
                              <TableCell align="left">${formatDecimalNumber(order.pricing.reserved, 6)}</TableCell>
                              <TableCell align="left">
                                $
                                {formatDecimalNumber(order.pricing.reservedNormalized * order.normalizedUnits.total, 2)}
                              </TableCell>
                              <TableCell align="left">
                                {formatDecimalNumber((1 - order.pricing.reserved / order.pricing.onDemand) * 100, 2)}%
                              </TableCell>
                            </TableRow>
                            <TableRow classes={{ root: classes.rowNoBottomBorder }} className={classes.rowSeparator}>
                              <TableCell colSpan={4} />
                            </TableRow>
                          </TableBody>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Used Hours</TableCell>
                              <TableCell align="left">
                                Unused {order.execution === autopilotVal ? "NFU" : "Hours"}
                              </TableCell>
                              <TableCell align="left">Norm. Factor</TableCell>
                              <TableCell align="left">Required NFU</TableCell>
                              {order.execution === autopilotVal && <TableCell align="left">Discarded NFU</TableCell>}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">
                                {formatDecimalNumber(
                                  (order.execution === autopilotVal
                                    ? order.autopilot?.mtdFlexRIUtilization
                                    : order.normalizedUnits.utilized) / order.normalizedUnits.unitsPerHour,
                                  2
                                )}
                                {` (${formatDecimalNumber(
                                  order.execution === autopilotVal
                                    ? order.autopilot?.mtdFlexRIUtilization
                                    : order.normalizedUnits.utilized,
                                  2
                                )} NFU)`}
                              </TableCell>
                              <TableCell align="left">
                                {formatDecimalNumber(
                                  order.execution === autopilotVal
                                    ? getHours(order.autopilot.utilization) *
                                        order.autopilot.mtdFlexRILineUnits *
                                        factor(order.normalizedUnits) -
                                        order.autopilot.mtdFlexRIUtilization
                                    : order.normalizedUnits.underUtilized / order.normalizedUnits.unitsPerHour,
                                  2
                                )}
                                {order.execution !== autopilotVal &&
                                  ` (${formatDecimalNumber(order.normalizedUnits.underUtilized, 2)} NFU)`}
                              </TableCell>
                              <TableCell align="left">{formatDecimalNumber(order.normalizedUnits.factor, 2)}</TableCell>
                              <TableCell align="left">
                                {formatDecimalNumber(order.normalizedUnits.unitsPerHour, 2)}
                                /hour
                              </TableCell>
                              {order.execution === autopilotVal && (
                                <TableCell align="left">
                                  {formatDecimalNumber(
                                    order.normalizedUnits.unitsPerHour -
                                      order.autopilot?.mtdFlexRILineUnits * factor(order.normalizedUnits),
                                    2
                                  )}
                                  /hour
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={8}>
                      <HighchartsReact highcharts={Highcharts} constructorType="chart" options={getGraph()} />
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Hide>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  tableToolbar: {
    padding: theme.spacing(1),
  },
  newOrder: {
    width: "max-content",
    marginLeft: 10,
    marginTop: 3,
  },
}));

const FlexsaveOrdersList = ({ orders, onActivate }: { orders: FlexsaveOrder[]; onActivate: OnActivate }) => {
  const classes = useStyles();
  const routeMatch = useRouteMatch<{
    orderId?: string;
  }>();

  const {
    tableState,
    filteredRows,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleRequestFilter,
  } = useTableState(orders, {
    persistenceKey: "flexible_ri_orders",
    sort: "id",
  });

  const expandedOrder = useMemo(
    () => orders.find((order) => order.id.toString() === routeMatch.params.orderId)?.id,
    [orders, routeMatch]
  );

  useEffect(() => {
    mixpanel.track("flexsave-aws.orders.view");
  }, []);
  if (orders === null) {
    return null;
  }

  const startOfMonth = DateTime.utc().startOf("month");
  const defaultFilters = expandedOrder
    ? ([
        {
          column: filterColumns[2],
          comparator: undefined,
          value: expandedOrder.toString(),
          label: expandedOrder.toString(),
        },
      ] as const)
    : ([
        {
          column: filterColumns[4],
          comparator: ">=",
          ...filterColumns[4].toOption(startOfMonth),
        },
      ] as const);
  return (
    <FilterContextProvider persistenceKey="flexible_ri_orders" columns={filterColumns} defaultValue={defaultFilters}>
      <Toolbar disableGutters className={classes.tableToolbar}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TableFilterBar
              items={orders}
              placeholder="Filter Orders"
              filterColumn={filterColumns}
              onFilter={handleRequestFilter}
              defaultFilters={defaultFilters}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <AggregationsBar filteredRows={filteredRows} orders={orders} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <Hide smDown>
                <TableCell size="small" padding="checkbox" align="center" />
              </Hide>
              <TableCell padding="none">
                <TableSortLabel
                  active={tableState.sort === "id"}
                  direction={tableState.direction}
                  onClick={handleRequestSort("id")}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell padding="none">
                <TableSortLabel
                  active={tableState.sort === "metadata.customer.primaryDomain"}
                  direction={tableState.direction}
                  onClick={handleRequestSort("metadata.customer.primaryDomain")}
                >
                  Customer
                </TableSortLabel>
              </TableCell>
              <Hide smDown>
                <TableCell>
                  <TableSortLabel
                    active={tableState.sort === "config.startDate"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("config.startDate")}
                  >
                    Month
                  </TableSortLabel>
                </TableCell>
                <TableCell padding="none">
                  <TableSortLabel
                    active={tableState.sort === "status"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={tableState.sort === "config.region"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("config.region")}
                  >
                    Region
                  </TableSortLabel>
                </TableCell>
              </Hide>
              <Hide smDown>
                <TableCell>
                  <TableSortLabel
                    active={tableState.sort === "config.instanceType"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("config.instanceType")}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={tableState.sort === "config.operatingSystem"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("config.operatingSystem")}
                  >
                    OS
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={tableState.sort === "config.numInstances"}
                    direction={tableState.direction}
                    onClick={handleRequestSort("config.numInstances")}
                  >
                    Instances
                  </TableSortLabel>
                </TableCell>
              </Hide>
              <TableCell>
                <TableSortLabel
                  active={tableState.sort === "savings"}
                  direction={tableState.direction}
                  onClick={handleRequestSort("savings")}
                >
                  Savings
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((order) => (
              <FlexsaveOrdersListRow
                key={order._id}
                order={order}
                onActivate={onActivate}
                expanded={expandedOrder === order.id}
              />
            ))}
          </TableBody>
          {filteredRows.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={filteredRows.length}
                  rowsPerPage={tableState.rowsPerPage}
                  rowsPerPageOptions={[10, 25, { value: -1, label: "All" }]}
                  labelRowsPerPage="Per page"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to > -1 ? to : count} of ${count}`}
                  page={tableState.page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </FilterContextProvider>
  );
};

export default FlexsaveOrdersList;
