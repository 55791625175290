import { type JSX, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type EntityModel, type GoogleCloudProjectAssetModel } from "@doitintl/cmp-models";
import { Hidden, MenuItem, TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { type Asset } from "../../../../../types";
import { buildBillingProfileString } from "../../../assetUtils";
import { RemoveDialog } from "../../../Cards/components/RemoveDialog";
import { NotAvailable } from "../../../Tabs/components/typographics";

export type GcpProjectAssetRowData = {
  id: string;
  asset: Asset<GoogleCloudProjectAssetModel>;
  projectId: string | JSX.Element;
  billingAccount: string | JSX.Element;
  billingId: string;
  billingProfile: string | JSX.Element;
  assetTag: string;
};

type Props = {
  row: GcpProjectAssetRowData;
  onRemoveAsset: (asset) => () => void;
};

/**
 * Builds asset row data from asset and entities map.
 *
 * @param asset asset to which the row relates
 * @param entities map of entities, keyed by entity ID.
 * @param billingAccountMap Map of billing account ids and corresponding display names.
 */
export const populateRowFromAsset = (
  asset: Asset<GoogleCloudProjectAssetModel>,
  entities: Record<string, EntityModel>,
  billingAccountMap: Record<string, string>
): GcpProjectAssetRowData => {
  const { properties } = asset.data;
  const entityModel = asset.data.entity?.id ? entities[asset.data.entity.id] : null;
  const assetTag = asset.data.tags?.length ? asset.data.tags[0] : NotAvailable;

  return {
    id: asset.id,
    asset,
    projectId: properties.projectId ?? NotAvailable,
    billingAccount: billingAccountMap[properties.billingAccountId] ?? NotAvailable,
    billingId: properties.billingAccountId,
    billingProfile: (entityModel && buildBillingProfileString(entityModel)) || NotAvailable,
    assetTag,
  };
};

const GcAssetRowProjects = ({ row, onRemoveAsset }: Props) => {
  const routeMatchURL = useRouteMatchURL();
  const { isDoitOwner } = useAuthContext();
  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState<boolean>(false);

  const projectIdLink = (
    <Typography component={RouterLink} to={`${routeMatchURL}/${row.id}`} color="inherit" variant="body2">
      {row.projectId}
    </Typography>
  );

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      menuItem: (
        <MenuItem
          key={`gcp-project-advanced-settings-${row.id}`}
          component={RouterLink}
          to={`${routeMatchURL}/${row.id}`}
        >
          Advanced settings
        </MenuItem>
      ),
    },
  ];
  isDoitOwner &&
    menuOptions.push({
      key: `gcp-project-remove-asset-${row.id}`,
      label: <Typography color="error">Remove asset</Typography>,
      action: () => setAckAssetRemoveDialog(true),
    });

  return (
    <>
      {isDoitOwner && ackAssetRemoveDialog && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={() => setAckAssetRemoveDialog(false)}
          onAccept={() => {
            onRemoveAsset(row.asset)();
            setAckAssetRemoveDialog(false);
          }}
          name={row.projectId}
        />
      )}
      <CellsWrapper>
        <TableCell>
          <Typography variant="body2" component="span">
            {projectIdLink}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" component="span">
            {row.billingAccount}
          </Typography>
        </TableCell>
        <Hidden mdDown>
          <TableCell>
            <Typography variant="body2" component="span">
              {row.billingId}
            </Typography>
          </TableCell>
        </Hidden>
        <Hidden lgDown>
          <TableCell>
            <Typography variant="body2" component="span">
              {row.billingProfile}
            </Typography>
          </TableCell>
        </Hidden>
        <Hidden lgDown>
          <TableCell>
            <Typography variant="body2" component="span">
              {row.assetTag}
            </Typography>
          </TableCell>
        </Hidden>
        <TableCell padding="checkbox">
          {menuOptions.length !== 0 && <ThreeDotsMenu options={menuOptions} size="small" />}
        </TableCell>
      </CellsWrapper>
    </>
  );
};

export default GcAssetRowProjects;
