import { useMemo, useState } from "react";

import { TextField } from "@mui/material";
import debounce from "lodash/debounce";

type Props = {
  value: number;
  onChange: (amount: number, fieldName: string) => void;
  error: boolean;
  loading: boolean;
};

export const DebouncedAmount = ({ onChange, error, value, loading }: Props) => {
  const [amount, setAmount] = useState(value);

  const debouncedOnChange = useMemo(() => debounce(onChange, 500, { maxWait: 1000 }), [onChange]);

  const handleChange = (event) => {
    setAmount(Number(event.target.value));
    debouncedOnChange(Number(event.target.value), "amount");
  };

  return (
    <TextField
      label="CUDs to purchase"
      fullWidth
      required
      value={amount}
      type="number"
      onChange={(event) => handleChange(event)}
      inputProps={{
        min: 0,
      }}
      error={error}
      helperText="Value must be even"
      disabled={loading}
    />
  );
};
