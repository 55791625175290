import { type Dispatch } from "react";

import { useTranslation } from "react-i18next";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import AuthProviders from "./AuthProviders";
import CheckEmailForm from "./CheckEmailForm";
import { EmailLinkLoginButton } from "./EmailLinkLoginButton";
import SsoLoginButton from "./SsoLoginButton";

export const SignInForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const { t } = useTranslation("login");
  return (
    <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
      <Typography variant="h1" alignSelf="flex-start">
        {t("signin")}
      </Typography>

      <AuthProviders loading={state.isLoading} />

      <EmailLinkLoginButton
        disabled={state.isLoading}
        handleEmailLinkLogin={() =>
          dispatch({ type: LoginHookActionType.setFormType, formType: "enterEmailForEmailLink" })
        }
      />

      <SsoLoginButton
        disabled={state.isLoading}
        handleLoginSso={() => dispatch({ type: LoginHookActionType.setFormType, formType: "sso" })}
      />

      <Box>
        <Divider flexItem={false} textAlign="center">
          <Typography variant="caption">{t("caption.signin")}</Typography>
        </Divider>
      </Box>
      <CheckEmailForm state={state} dispatch={dispatch} />
    </Stack>
  );
};
