import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type SlackChannel } from "@doitintl/cmp-models";
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import {
  Alert,
  Autocomplete,
  type AutocompleteChangeReason,
  Checkbox,
  Chip,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { consoleErrorWithSentry } from "../../../utils";
import { sortAlphabetically } from "../../../utils/common";
import useStyles from "./SlackChannelSelection.styles";
import { useSlackChannelsApi } from "./useSlackChannelApi";

type SlackChannelSelectionProps = {
  customerId: string;
  disabled?: boolean;
  text?: string;
  channels: SlackChannel[];
  setChannels: Dispatch<SetStateAction<SlackChannel[]>> | ((channels: SlackChannel[]) => void);
  updateSelection: (recipientsSlackChannels: SlackChannel[]) => void;
  includeRefresh?: boolean;
  includeNoOptionsBox?: boolean;
  includeSlackLink?: boolean;
};

export const SlackChannelSelection = ({
  customerId,
  disabled,
  text,
  channels = [],
  setChannels,
  updateSelection,
  includeRefresh = false,
  includeNoOptionsBox = false,
  includeSlackLink = true,
}: SlackChannelSelectionProps) => {
  const classes = useStyles();
  const { updateAvailableChannels, options } = useSlackChannelsApi(customerId);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(0);
  const checkBoxOutlineBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkBoxIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    const fetchChannels = async () => {
      setLoading(true);
      try {
        await updateAvailableChannels();
      } catch (e) {
        consoleErrorWithSentry(e);
      }
      setLoading(false);
    };
    fetchChannels();
  }, [customerId, updateAvailableChannels, refresh]);

  const renderSlackLink = (internal = false) => {
    if (!includeSlackLink) {
      return null;
    }

    const spacer = (
      <Typography variant="body2" className={internal ? "" : classes.link}>
        &nbsp;
      </Typography>
    );

    if (!options) {
      return spacer;
    }

    const fullyConfigured = options.find(({ shared }) => shared) && options.find(({ shared }) => !shared);
    if (!internal && fullyConfigured) {
      return spacer;
    }
    return (
      <Typography variant="body2" className={internal ? "" : classes.link}>
        Update your&nbsp;
        <Link
          component={RouterLink}
          to={`/customers/${customerId}/integrations/slack`}
          underline="always"
          variant="body2"
        >
          Slack settings
        </Link>
      </Typography>
    );
  };

  const renderNoOptionsBox = () => {
    if (!includeNoOptionsBox || loading || !!options?.length) {
      return null;
    }

    return (
      <Alert
        severity="warning"
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          Configure Slack integration to select your Slack channels &nbsp; &nbsp;
          <Link
            component={RouterLink}
            to={`/customers/${customerId}/integrations/slack`}
            underline="always"
            variant="body2"
          >
            Configure Slack
          </Link>
        </Typography>
      </Alert>
    );
  };

  const sortCategorically = (a: SlackChannel, b: SlackChannel) => {
    if (a.shared === b.shared) {
      return 0;
    }
    return a.shared ? -1 : 1;
  };

  const sortChannelType = (a: SlackChannel, b: SlackChannel) => {
    if (a.type === b.type) {
      return 0;
    }
    return a.type === "private" ? -1 : 1;
  };

  const sortChannels: (channelsToSort: SlackChannel[]) => SlackChannel[] = useCallback((channelsToSort) => {
    //  sort channels by name & shared property
    channelsToSort.sort((a, b) => sortAlphabetically(a.name, b.name));
    return channelsToSort.sort(sortChannelType).sort(sortCategorically);
  }, []);

  const sortedOptions = useMemo(() => sortChannels(options ?? []), [options, sortChannels]);

  const OptionLabel = ({ option }) => (
    <Stack direction="row" alignItems="center" gap={1}>
      {option.type === "private" ? <LockIcon fontSize="inherit" /> : "#"}
      <Typography variant="body1">{option.name}</Typography>
    </Stack>
  );

  const RefreshBtn = () => (
    <IconButton onClick={() => setRefresh(refresh + 1)} disabled={loading} color="primary">
      <RefreshIcon />
      <Typography fontWeight={500} sx={{ ml: 1 }}>
        Refresh
      </Typography>
    </IconButton>
  );

  const loaderComponent = (
    <Stack direction="row" alignItems="center" gap={1}>
      <CircularProgress size={14} />
      <Typography variant="body2">&nbsp; Loading Slack channels...</Typography>
    </Stack>
  );

  return (
    <>
      <Typography className={classes.header} color="inherit" variant="body2">
        {text}
      </Typography>

      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={3}
        options={sortedOptions}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        groupBy={({ workspace, shared, type }) =>
          shared ? "Shared DoiT Slack channel" : `${workspace} ${type} workspace channels`
        }
        value={sortChannels(channels)}
        disabled={loading || disabled || !options || options.length < 1}
        noOptionsText={
          includeSlackLink ? renderSlackLink(true) : "Once configured, your Slack channels will appear here"
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              size="small"
              label={<OptionLabel option={option} />}
              color="primary"
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={checkBoxOutlineBlankIcon}
              checkedIcon={checkBoxIcon}
              style={{ left: "-10px" }}
              checked={selected}
            />
            <OptionLabel option={option} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={loading ? loaderComponent : "Select Slack channels"}
            fullWidth
            size="small"
          />
        )}
        onChange={(event, value: SlackChannel[], reason: AutocompleteChangeReason) => {
          switch (reason) {
            case "selectOption":
              setChannels(value);
              break;
            case "clear":
            case "removeOption":
              setChannels(value);
              if (reason === "clear" || (event?.currentTarget as HTMLLIElement).tagName === "svg") {
                try {
                  updateSelection(value);
                } catch (e) {
                  consoleErrorWithSentry(e);
                }
              }
              break;
          }
        }}
        onClose={() => {
          try {
            updateSelection(channels);
          } catch (e) {
            consoleErrorWithSentry(e);
          }
        }}
      />
      {renderNoOptionsBox()}
      {renderSlackLink()}
      {includeRefresh && <RefreshBtn />}
    </>
  );
};
