import { createContext, type PropsWithChildren, useContext, useEffect, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { Button, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { globalText } from "../assets/texts";
import { useAuthContext } from "../Context/AuthContext";
import { Color } from "../muiTheme";
import mixpanel from "../utils/mixpanel";
import { useSnackbar } from "./SharedSnackbar/SharedSnackbar.context";

type AppVersionContextType = {
  commitSha: string;
  shortCommitSha: string;
  branch: string;
  versionTimestamp: DateTime;
  forceRefresh?: boolean;
};

export const AppVersionContext = createContext<AppVersionContextType | null>(null);

export const useAppVersion = () => useContext(AppVersionContext);

const VersionUpdater = (props: PropsWithChildren<unknown>) => {
  const snackbar = useSnackbar();
  const [userAppVersionState, setUserAppVersionState] = useState<AppVersionContextType | null>(null);
  const [currentAppVersionState, setCurrentAppVersionState] = useState<AppVersionContextType | null>(null);
  const theme = useTheme();
  const { tokenValue, isDoitEmployee } = useAuthContext();

  useEffect(() => {
    let reentrancy = false;

    if (!tokenValue) {
      return;
    }

    return getCollection(AppModel)
      .doc("version")
      .onSnapshot((docSnap) => {
        const data = docSnap.asModelData();
        if (!data) {
          return;
        }

        const appVersionData = {
          commitSha: data.commitSha,
          shortCommitSha: data.shortCommitSha,
          branch: data.branch,
          versionTimestamp: DateTime.fromJSDate(data.timestamp.toDate()),
          forceRefresh: !!data.forceRefresh,
        };

        if (!userAppVersionState && !reentrancy) {
          // first time init
          reentrancy = true;
          setUserAppVersionState(appVersionData);
        }
        setCurrentAppVersionState(appVersionData);
      });
  }, [userAppVersionState, tokenValue]);

  useEffect(() => {
    if (!currentAppVersionState || !userAppVersionState) {
      return;
    }

    const isVersionOlderThanADay =
      userAppVersionState.versionTimestamp &&
      currentAppVersionState.versionTimestamp &&
      currentAppVersionState.versionTimestamp.diff(userAppVersionState.versionTimestamp, "days").days > 1;

    const isNewVersionAvailable =
      currentAppVersionState.commitSha && currentAppVersionState.commitSha !== userAppVersionState.commitSha;

    if (isVersionOlderThanADay || (isDoitEmployee && isNewVersionAvailable)) {
      const showVersionUpdate = () => {
        snackbar.onOpen({
          message: globalText.NEW_VERSION_AVAILABLE,
          variant: "info",
          autoHideDuration: null,
          action: [
            <Button
              key="refresh"
              aria-label="Refresh"
              variant="contained"
              size="small"
              onClick={() => {
                mixpanel.track("app.refresh");
                window.location.reload();
              }}
              startIcon={<RefreshIcon fontSize="small" />}
              sx={theme.mixins.styleButtonContained(Color.primary)}
            >
              {globalText.REFRESH}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => {
                // Close snackbar, but show it again in 1 hour if the page was not refreshed.
                snackbar.onClose();
                setTimeout(
                  () => {
                    showVersionUpdate();
                  },
                  60 * 60 * 1000
                );
              }}
            >
              <CloseIcon />
            </IconButton>,
          ],
        });
      };

      setTimeout(() => {
        showVersionUpdate();
      }, 30000);
    }

    if (isNewVersionAvailable && currentAppVersionState.forceRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 180000);
    }
  }, [isDoitEmployee, snackbar, currentAppVersionState, userAppVersionState, theme.mixins]);

  return <AppVersionContext.Provider value={userAppVersionState}>{props.children}</AppVersionContext.Provider>;
};

export default VersionUpdater;
