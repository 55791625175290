import { forwardRef, type PropsWithChildren, type Ref } from "react";

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, type FormikProps } from "formik";
import { object as YupObject, string as YupString } from "yup";

import { useFlexsaveSupportDialog } from "../hooks";

type SupportFormProps = {
  email: string;
  customerName: string;
  postSubmitHandler?: () => void;
};

export const FlexsaveSupportSchema = YupObject().shape({
  details: YupString().required().label("Additional details"),
  issue: YupString().required().label("Issue"),
});

enum issuesOptions {
  email = "Haven't received my confirmation email",
  estimateConnect = "Can't connect to get my estimate",
  connect = "Can't connect to activate",
  estimate = "Questions on my estimate",
  savings = "Question about my savings",
}

const FlexsaveSupportForm = forwardRef(
  (
    { email, customerName, postSubmitHandler }: PropsWithChildren<SupportFormProps>,
    ref: Ref<FormikProps<Record<string, unknown>>>
  ) => {
    const { createTicket } = useFlexsaveSupportDialog();
    return (
      <Formik
        validateOnMount
        innerRef={ref}
        initialValues={{ details: "", issue: "" }}
        validationSchema={FlexsaveSupportSchema}
        onSubmit={async (values, formikHelpers) => {
          await createTicket(values, formikHelpers);
          postSubmitHandler?.();
        }}
      >
        {({ errors, values, handleChange, touched }) => (
          <Form>
            <Stack spacing={2} sx={{ pb: 3 }}>
              <Box>
                <Typography variant="caption">Name</Typography>
                <Typography>{customerName}</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Email</Typography>
                <Typography>{email}</Typography>
              </Box>
            </Stack>

            <Stack spacing={2}>
              <FormControl fullWidth required error={touched.issue && Boolean(errors.issue)}>
                <InputLabel id="issueSelectLabel">What issue are you having?</InputLabel>
                <Select
                  name="issue"
                  autoFocus
                  labelId="issueSelectLabel"
                  id="issueSelect"
                  label="What issue are you having?"
                  onChange={handleChange}
                >
                  {Object.entries(issuesOptions).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.issue && errors.issue ? errors.issue : " "}</FormHelperText>
              </FormControl>
              <TextField
                data-cy="details-input"
                value={values.details}
                onChange={handleChange}
                error={touched.details && Boolean(errors.details)}
                helperText={touched.details && errors.details ? errors.details : " "}
                label="Additional details"
                type="text"
                name="details"
                margin="none"
                variant="outlined"
                size="medium"
                fullWidth
                required
                multiline
              />
            </Stack>
          </Form>
        )}
      </Formik>
    );
  }
);
FlexsaveSupportForm.displayName = "FlexsaveSupportForm";

export default FlexsaveSupportForm;
