import {
  createContext,
  type Dispatch,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { type ConfigurationTab, type NodeConfigs } from "../../types";
import { useTabs } from "./Tabs/hooks";

type NodeConfigurationContextType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  activeTab: ConfigurationTab;
  setActiveTab: (tab: ConfigurationTab) => void;
  nodeConfig: NodeConfigs<TNodeType>;
  dispatch: Dispatch<NodeConfigAction<TNodeType>>;
  persistParameters: () => Promise<void>;
  saveNode: () => void;
};

type NodeConfigurationProviderType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  nodeConfig: NodeConfigs<TNodeType>;
  handleDoneClick: (nodeConfig: NodeConfigs<TNodeType>) => void;
  children: ReactNode;
};

type NodeConfigAction<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> =
  | { type: "UPDATE_PARAMETERS_AT_KEY"; payload: { parameterKey: string; values: any } }
  | { type: "CHANGE_NODE"; payload: NodeConfigs<TNodeType> }
  | { type: "UPDATE_NODE_NAME"; payload: string };

function isActionNode(node: NodeConfigs): node is NodeConfigs<CloudFlowNodeType.ACTION> {
  return node.type === CloudFlowNodeType.ACTION;
}

function nodeConfigReducer<TNodeType extends CloudFlowNodeType = CloudFlowNodeType>(
  state: NodeConfigs<TNodeType>,
  action: NodeConfigAction<TNodeType>
): NodeConfigs<TNodeType> {
  switch (action.type) {
    case "UPDATE_PARAMETERS_AT_KEY": {
      if (isActionNode(state)) {
        return {
          ...state,
          parameters: {
            ...state.parameters,
            [action.payload.parameterKey]: {
              ...state.parameters[action.payload.parameterKey],
              ...action.payload.values,
            },
          },
        };
      }
      throw new Error("Cannot update configuration values for non-action node");
    }
    case "UPDATE_NODE_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "CHANGE_NODE":
      return action.payload;
    default:
      return state;
  }
}

const NodeConfigurationContext = createContext<NodeConfigurationContextType<any>>({
  activeTab: "Parameters",
  setActiveTab: () => {},
  nodeConfig: {} as unknown as NodeConfigs<any>,
  dispatch: () => {},
  persistParameters: async () => {},
  saveNode: () => {},
});

export const NodeConfigurationProvider = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>({
  nodeConfig,
  handleDoneClick,
  children,
}: NodeConfigurationProviderType<TNodeType>) => {
  const [editableNodeConfig, dispatch] = useReducer(nodeConfigReducer<TNodeType>, nodeConfig);
  const [activeTab, setActiveTab] = useState<ConfigurationTab>("Parameters");
  const tabs = useTabs(nodeConfig);

  useEffect(() => {
    setActiveTab(tabs[0] || "Parameters");
  }, [tabs]);

  useEffect(() => {
    if (nodeConfig.id !== editableNodeConfig.id) {
      dispatch({ type: "CHANGE_NODE", payload: nodeConfig });
    }
  }, [editableNodeConfig.id, nodeConfig]);

  const persistParameters = useCallback(async () => {
    /*
    Probably will be moved higher in the hierarchy
    await getCollection(CloudflowEngineModel)
      .doc("cloudflows")
      .collection("cloudflowEntities")
      .doc(nodeConfig.flowId)
      .collection("nodes")
      .doc(nodeConfig.id)
      .update({ parameters: nodeConfig.apiParameters })
      .catch((error) => {
        console.error("Error updating node parameters", error);
       */
  }, []);

  const saveNode = useCallback(() => {
    handleDoneClick(editableNodeConfig);
  }, [editableNodeConfig, handleDoneClick]);

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      nodeConfig: editableNodeConfig,
      dispatch,
      persistParameters,
      saveNode,
    }),
    [activeTab, editableNodeConfig, persistParameters, saveNode]
  );

  return <NodeConfigurationContext.Provider value={value}>{children}</NodeConfigurationContext.Provider>;
};

export const useNodeConfigurationContext = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>() =>
  useContext<NodeConfigurationContextType<TNodeType>>(NodeConfigurationContext);
