import { useState } from "react";

import { addKnownIssueDocument } from "../db";
import { KnownIssueDialog, type KnownIssueDialogProps } from "../KnownIssueDialog";
import { type KnownIssue } from "../types";
import { AddKnownIssueButton } from "./AddKnownIssueButton";

export type AddKnownIssueProps = {
  productsSuggestions: KnownIssueDialogProps["productsSuggestions"];
};

export const AddKnownIssue = ({ productsSuggestions }: AddKnownIssueProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const toggleKnownIssueDialog = () => setOpenDialog(!openDialog);

  const handleSubmitNewKnownIssue = async (knownIssueData: KnownIssue) => {
    await addKnownIssueDocument(knownIssueData);
    setOpenDialog(false);
  };

  return (
    <>
      <AddKnownIssueButton onClick={toggleKnownIssueDialog} />
      <KnownIssueDialog
        open={openDialog}
        productsSuggestions={productsSuggestions}
        title="Add known issue"
        onClose={toggleKnownIssueDialog}
        onKnownIssueSubmit={handleSubmitNewKnownIssue}
        submitButtonText="Add"
      />
    </>
  );
};
