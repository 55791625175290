import { type JSX, useCallback, useState } from "react";

import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { MPAActivationDialogHOC } from "./MPAActivationDialogHOC";

export type useMasterPayerAccountActivationDialogFacade = {
  showMPAActivationDialog: (masterPayerAccount: MasterPayerAccountsModelSnapshot) => void;
  MPAActivationDialogComponent: () => JSX.Element | null;
};

export const useMPAActivationDialogFacade = (): useMasterPayerAccountActivationDialogFacade => {
  const [masterPayerAccount, setMasterPayerAccount] = useState<MasterPayerAccountsModelSnapshot | null>(null);
  const onClose = () => setMasterPayerAccount(null);

  const MPAActivationDialogComponent = useCallback(
    () => masterPayerAccount && <MPAActivationDialogHOC masterPayerAccount={masterPayerAccount} onClose={onClose} />,
    [masterPayerAccount]
  );

  return {
    showMPAActivationDialog: setMasterPayerAccount,
    MPAActivationDialogComponent,
  };
};
