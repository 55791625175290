import {
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
} from "@doitintl/cmp-models";
import { List, Typography } from "@mui/material";

export type GCPTableMode =
  | "google-cloud"
  | "google-cloud-direct"
  | "google-cloud-project"
  | "google-cloud-project-standalone";

type Props = {
  setTableMode: (tableMode: GCPTableMode) => void;
  counts: Record<GCPTableMode, number>;
  tableMode: string;
  isStandalone: boolean | undefined;
};

const GCAssetSubmenu = ({ setTableMode, counts, tableMode, isStandalone }: Props) => {
  const projectAssetType = isStandalone ? AssetTypeGoogleCloudProjectStandalone : AssetTypeGoogleCloudProject;

  return (
    <List component="nav" sx={{ ml: 3, mt: -1.25 }}>
      <Typography
        component="li"
        onClick={() => setTableMode(AssetTypeGoogleCloud)}
        variant="body2"
        sx={{ cursor: "pointer", fontWeight: tableMode === AssetTypeGoogleCloud ? "bold" : "regular" }}
      >
        {`Partner billing accounts (${counts[AssetTypeGoogleCloud]})`}
      </Typography>
      <Typography
        component="li"
        onClick={() => {
          setTableMode(AssetTypeGoogleCloudDirect);
        }}
        variant="body2"
        sx={{ cursor: "pointer", fontWeight: tableMode === AssetTypeGoogleCloudDirect ? "bold" : "regular", pt: 0.75 }}
      >
        {`Direct billing accounts (${counts[AssetTypeGoogleCloudDirect]})`}
      </Typography>
      <Typography
        component="li"
        onClick={() => {
          setTableMode(projectAssetType);
        }}
        variant="body2"
        sx={{
          cursor: "pointer",
          fontWeight: tableMode === projectAssetType ? "bold" : "regular",
          pt: 0.75,
        }}
      >
        {`Projects (${isStandalone ? counts[AssetTypeGoogleCloudProjectStandalone] + counts[AssetTypeGoogleCloudProject] : counts[AssetTypeGoogleCloudProject]})`}
      </Typography>
    </List>
  );
};

export default GCAssetSubmenu;
