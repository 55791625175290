import { Loader } from "../../Loader";
import { WidgetCard } from "../Common/WidgetCard";

export const FlexsaveCardLoading = ({ widgetHeight }: { widgetHeight: number }) => (
  <WidgetCard title="Flexsave" subheader="Loading..." widgetHeight={widgetHeight} style={{ position: "relative" }}>
    <Loader loading={true}>
      <></>
    </Loader>
  </WidgetCard>
);
