import { TierPackageTypes } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTier } from "../../../../../Context/TierProvider";

const TierInfoRow = () => {
  const { tiers } = useTier();

  const navigator = tiers?.find((t) => t.packageType === TierPackageTypes.NAVIGATOR)?.displayName ?? "N/A";
  const solve = tiers?.find((t) => t.packageType === TierPackageTypes.SOLVE)?.displayName ?? "N/A";

  return (
    <>
      <Stack flexDirection="row" alignItems="center">
        <Box>Navigator tier: </Box>
        <Typography variant="subtitle2" fontWeight={500} pl={0.5}>
          {navigator}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center">
        <Box>Solve tier: </Box>
        <Typography variant="subtitle2" fontWeight={500} pl={0.5}>
          {solve}
        </Typography>
      </Stack>
    </>
  );
};

export default TierInfoRow;
