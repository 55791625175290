import { createContext, forwardRef, type HTMLAttributes, type ReactChild, useContext, useEffect, useRef } from "react";

import { type ListChildComponentProps, VariableSizeList } from "react-window";
import { Popper, Typography } from "@mui/material";
import Autocomplete, { autocompleteClasses, type AutocompleteProps } from "@mui/material/Autocomplete";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Please see example at attributions/AttributionBuilderRow.tsx for how to customize this component.
// Please note, ALL default props below can be overwritten. speak with @chaim for any questions.

const LISTBOX_PADDING = 20;

const renderRow = (props: ListChildComponentProps) => {
  const { data: options, index, style } = props;
  // do not remove this styling, scrolling behavior will go to top otherwise
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };
  const option = options[index][1];
  return (
    <Typography noWrap style={inlineStyle}>
      {option}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = "OuterElementType";

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window.
// You can also create your own custom ListboxComponent and export the useResetCache function and use.
const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ReactChild[] = [];
  (children as ReactChild[]).forEach((item: ReactChild & { children?: ReactChild[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 48 : 36;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={() => getChildSize()}
          overscanCount={10}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

interface VirtualizedAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  myProp?: string;
}

export default function VirtualizedAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: VirtualizedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Autocomplete
      id="virtualized-autocomplete"
      disableListWrap
      PopperComponent={StyledPopper as any}
      ListboxComponent={ListboxComponent}
      {...props}
    />
  );
}
