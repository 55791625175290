import { type PerkPlatform, type PerkSolutionType } from "@doitintl/cmp-models";

import { type PerksWData } from "../../types";

export type GroupedPerks = {
  solution: Record<PerkSolutionType, PerksWData[]>;
  platform: Record<PerkPlatform, PerksWData[]>;
};

export enum RegisterInterestFromType {
  "amazon-web-services" = "AWS Marketplace link",
  "google-cloud" = "GCP Marketplace link",
  InterestButton = "Register interest button",
}

export type RegisterInterestEmail = {
  userEmail: string;
  perkName: string;
  clickedOn: RegisterInterestFromType;
};

export type RegisterInterestTicket = {
  domain: string;
  perkId: string;
  perkName: string | undefined;
  perkType: string;
  tags: string[];
  platform: string;
  userEmail: string;
  accountManager: string | undefined;
  trainingRequest?: TrainingRequest;
  isDoitEmployee: boolean;
};

export type TrainingRequest = {
  reason: string;
  numberOfAttendees: number;
  timezone: string;
};
