import { useCallback, useState } from "react";

import { type SlackChannel } from "@doitintl/cmp-models";
import { type Method } from "axios";

import { useApiContext } from "../../../api/context";
import { httpMethods } from "../../../assets/texts";
import { consoleErrorWithSentry } from "../../../utils";

export const useSlackChannelsApi = (customerId: string) => {
  const api = useApiContext();
  const [options, setOptions] = useState<SlackChannel[]>();
  const url = `/v1/customers/${customerId}/slack/channels`;

  const getCustomerSlackChannels = useCallback(async (): Promise<SlackChannel[]> => {
    try {
      const response = await api.request({
        method: httpMethods.GET as Method,
        url,
      });
      if (response && response?.status === 200) {
        return response.data as SlackChannel[];
      } else {
        consoleErrorWithSentry(`/slack/channels/ response status ${response?.status?.toString()}`);
      }
    } catch (error: any) {
      consoleErrorWithSentry(error);
    }
    return [];
  }, [api, url]);

  const updateAvailableChannels = useCallback(async () => {
    try {
      const newOptions = await getCustomerSlackChannels();
      setOptions(newOptions);
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [getCustomerSlackChannels, setOptions]);

  return { options, setOptions, getCustomerSlackChannels, updateAvailableChannels };
};
