import { type MutableRefObject } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  type DeleteElementsOptions,
  type Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  type Node,
} from "@xyflow/react";

import { EDGE_TYPE, type Position } from "../../types";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "./edgeUtils";
import { createConditionNodes, getNode } from "./nodeUtils";

const doesNodeHaveChildren = (nodes: Node[], edges: Edge[], nodeId: string): boolean => {
  const outgoers = getOutgoers(
    {
      id: nodeId,
    },
    nodes,
    edges
  );
  if (outgoers.length === 0) return false;
  return outgoers.some((outgoer) => outgoer.type !== "ghost");
};

type handleDeleteActionsProps = {
  nodes: Node[];
  edges: Edge[];
  handleEditNode: (node: Node) => void;
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => void;
  handleDeleteNode: (nodeId: string) => void;
  manageIfActionsId: string;
  removeTreeOfOutgoers: (nodeId: string) => void;
};

type replaceWithIfNodeProps = {
  nodes: Node[];
  edges: Edge[];
  nodeId: string;
  handleEditNode: (node: Node) => void;
  handleDeleteNode: (nodeId: string) => void;
};

const replaceWithIfNode = ({ nodes, edges, nodeId, handleEditNode, handleDeleteNode }: replaceWithIfNodeProps) => {
  const currentNode = nodes.find((node) => node.id === nodeId);
  const currentNodeId = currentNode!.id;
  let newNodes = [...nodes];
  const newEdges = [...edges];

  const newNode = getNode(CloudFlowNodeType.CONDITION, currentNodeId, currentNode!.position);

  newNodes = newNodes.map((node) => {
    if (node.id === currentNodeId) {
      return {
        ...newNode,
        data: {
          ...newNode.data,
          isActive: true,
          onEditNode: () => handleEditNode(newNode),
          onDeleteNode: () => handleDeleteNode(currentNodeId),
        },
      };
    }
    return node;
  });

  return { newNodes, newEdges, newNode };
};

const handleDeleteActions: ({
  nodes,
  edges,
  handleEditNode,
  handleAddNode,
  handleDeleteNode,
  manageIfActionsId,
  removeTreeOfOutgoers,
}: handleDeleteActionsProps) => {
  newNodes: Node[];
  newEdges: Edge[];
  newNode: Node;
} = ({ nodes, edges, handleEditNode, handleAddNode, handleDeleteNode, manageIfActionsId, removeTreeOfOutgoers }) => {
  const currentNode = nodes.find((node) => node.id === manageIfActionsId);
  const currentNodeId = currentNode!.id;

  const { newNodes, newEdges, newNode } = replaceWithIfNode({
    nodes,
    edges,
    nodeId: manageIfActionsId,
    handleEditNode,
    handleDeleteNode,
  });
  removeTreeOfOutgoers(currentNodeId);

  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(currentNode!, handleAddNode, handleDeleteNode);

  newNodes.push(...trueNodeTree, ...falseNodeTree);
  newEdges.push(createTruePathEdge(currentNodeId, trueNodeId));
  newEdges.push(createFalsePathEdge(currentNodeId, falseNodeId));
  newEdges.push(createEdge(trueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST));
  newEdges.push(createEdge(falseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST));

  return { newNodes, newEdges, newNode };
};

type handleMoveActionsProps = {
  nodes: Node[];
  edges: Edge[];
  handleEditNode: (node: Node) => void;
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => void;
  handleDeleteNode: (nodeId: string) => void;
  manageIfActionsId: string;
  moveToTrue: boolean;
};

const handleMoveActions = ({
  nodes,
  edges,
  handleEditNode,
  handleAddNode,
  handleDeleteNode,
  manageIfActionsId,
  moveToTrue,
}: handleMoveActionsProps) => {
  const currentNode = nodes.find((node) => node.id === manageIfActionsId);
  const currentNodeId = currentNode!.id;
  const { newNodes, newEdges, newNode } = replaceWithIfNode({
    nodes,
    edges,
    nodeId: manageIfActionsId,
    handleEditNode,
    handleDeleteNode,
  });

  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(currentNode!, handleAddNode, handleDeleteNode);

  const edgeFromCurrent = edges.findIndex((edge) => edge.source === currentNodeId);

  const realTrueNodeId = moveToTrue ? newEdges[edgeFromCurrent].target : trueNodeId;
  const realFalseNodeId = moveToTrue ? falseNodeId : newEdges[edgeFromCurrent].target;

  newEdges.splice(edgeFromCurrent, 1);

  if (moveToTrue) {
    newNodes.push(...falseNodeTree);
    newEdges.push(createEdge(realFalseNodeId, trueNodeGhostId, EDGE_TYPE.GHOST));
  } else {
    newNodes.push(...trueNodeTree);
    newEdges.push(createEdge(realTrueNodeId, falseNodeGhostId, EDGE_TYPE.GHOST));
  }

  newEdges.push(createTruePathEdge(currentNodeId, realTrueNodeId));
  newEdges.push(createFalsePathEdge(currentNodeId, realFalseNodeId));

  return { newNodes, newEdges, newNode };
};

type handleDeleteIfNodeParentProps = {
  incomer: Node;
  currentNode: Node;
  nodes: Node[];
  edges: Edge[];
  handleAddRef: MutableRefObject<
    ((nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => void) | undefined
  >;
  handleDeleteRef: MutableRefObject<((nodeId: string, forceDelete?: boolean) => Promise<void>) | undefined>;
};

const handleDeleteIfNodeParent = ({
  incomer,
  currentNode,
  nodes,
  edges,
  handleAddRef,
  handleDeleteRef,
}: handleDeleteIfNodeParentProps) => {
  if (!handleAddRef.current || !handleDeleteRef.current) return { newEdges: edges, newNodes: nodes };

  const connectedEdges = getConnectedEdges([incomer], edges);
  const outgoers = getOutgoers(incomer, nodes, edges);
  const position = { x: currentNode.position.x, y: currentNode.position.y + 50 };

  const newNode = getNode(CloudFlowNodeType.CONDITION, incomer.id, position);

  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(newNode, handleAddRef.current, handleDeleteRef.current);

  const otherEdge = connectedEdges.find((edge) => edge.source === incomer.id && edge.target !== currentNode.id);

  const label = otherEdge?.data!.label === "True" ? "False" : "True";

  let newEdges: Edge[];
  let newNodes: Node[];

  if (label === "True") {
    newEdges = [createTruePathEdge(incomer.id, trueNodeId), createEdge(trueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST)];

    newNodes = [...(outgoers.length ? trueNodeTree : [])];
  } else {
    newEdges = [
      createFalsePathEdge(incomer.id, falseNodeId),
      createEdge(falseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST),
    ];

    newNodes = [...(outgoers.length ? falseNodeTree : ([] as Node[]))];
  }

  return { newEdges, newNodes };
};

const getNodeDescendants = (nodes: Node[], edges: Edge[], nodeId: string): Node[] => {
  const childEdges = edges.filter((edge) => edge.source === nodeId);

  if (childEdges.length === 0) {
    return [] as Node[];
  }

  return childEdges.flatMap((edge) => {
    const childId = edge.target;
    const childNode = nodes.find((node) => node.id === childId);
    return childNode ? [childNode, ...getNodeDescendants(nodes, edges, childId)] : [];
  });
};

type setNodesOnDeleteIfNodeProps = {
  nodeId: string;
  nodes: Node[];
  edges: Edge[];
  deleteElements: (params: DeleteElementsOptions) => Promise<{ deletedNodes: Node[]; deletedEdges: Edge[] }>;
  handleAddRef: MutableRefObject<
    ((nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => void) | undefined
  >;
  handleDeleteRef: MutableRefObject<((nodeId: string, forceDelete?: boolean) => Promise<void>) | undefined>;
};

const rearrangeFlowOnDeleteIfNode: ({
  nodeId,
  nodes,
  edges,
  deleteElements,
  handleDeleteRef,
  handleAddRef,
}: setNodesOnDeleteIfNodeProps) => Promise<{
  newEdges: Edge[];
  newNodes: Node[];
}> = async ({ nodeId, nodes, edges, deleteElements, handleDeleteRef, handleAddRef }: setNodesOnDeleteIfNodeProps) => {
  const nodeToDelete = nodes.find((node) => node.id === nodeId);
  if (!nodeToDelete) {
    return { newNodes: nodes, newEdges: edges };
  }
  const [incomer] = getIncomers(nodeToDelete, nodes, edges);

  const outgoers = getOutgoers({ id: nodeToDelete.id } as Node, nodes, edges);
  const connectedEdges = getConnectedEdges([nodeToDelete], edges);
  await deleteElements({ nodes: [nodeToDelete], edges: connectedEdges });

  const { newNodes, newEdges } = handleDeleteIfNodeParent({
    currentNode: nodeToDelete,
    incomer,
    nodes,
    edges,
    handleDeleteRef,
    handleAddRef,
  });

  const ghostIndex = newNodes.findIndex((node) => node.type === CloudFlowNodeType.GHOST);
  const stepNodeIndex = newEdges.findIndex((edge) => edge.source === incomer.id);
  const stepNodeId = newEdges.find((edge) => edge.source === incomer.id)?.target;
  const ghostNode = newNodes[ghostIndex];

  if (outgoers[0].type === CloudFlowNodeType.GHOST) {
    newNodes.splice(ghostIndex, 1);
    newEdges.splice(
      newEdges.findIndex((edge) => edge.target === ghostNode.id),
      1
    );
    outgoers.forEach((outgoer) => {
      if (stepNodeId) {
        newEdges.push(createEdge(stepNodeId, outgoer.id));
      }
    });

    return { newNodes, newEdges };
  } else {
    newEdges.splice(
      newEdges.findIndex((edge) => edge.target === ghostNode.id),
      1
    );

    newEdges[stepNodeIndex] =
      newEdges[stepNodeIndex].data?.label === "True"
        ? createTruePathEdge(incomer.id, outgoers[0].id)
        : createFalsePathEdge(incomer.id, outgoers[0].id);

    return { newNodes: [] as Node[], newEdges };
  }
};

const utils = {
  doesNodeHaveChildren,
  handleDeleteActions,
  handleMoveActions,
  handleDeleteIfNodeParent,
  getNodeDescendants,
  rearrangeFlowOnDeleteIfNode,
};

export default utils;
