import { type CloudConnectCategory } from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";

import Step from "./Step";
import { type GKEConfigStep } from "./types";

export type Props = {
  category: CloudConnectCategory;
  steps?: GKEConfigStep[];
  hideSteps?: boolean;
};

const GKEConfig = ({ category, steps, hideSteps = false }: Props) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="body2">{category.description}</Typography>
    <Box mt={1} />
    {!hideSteps && steps?.map((step) => <Step key={step.index} {...{ step }} />)}
  </Box>
);

export default GKEConfig;
