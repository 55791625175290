import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import { makeStyles } from "@mui/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import { DateTime } from "luxon";
import { getCollection } from "@doitintl/models-firestore";
import { DashboardModel } from "@doitintl/cmp-models";
import Hide from "../HideChildren/Hide";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";
import { useCustomerContext } from "../../Context/CustomerContext";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";
import { SkeletonCard } from "./SkeletonCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    paddingBottom: 0,
    height: 201,
  },
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
    cursor: "pointer",
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
  },
}));

const RenewalsCard = ({ raised, fallbackComponent, widgetHeight = 1 }) => {
  const [renewals, setRenewals] = useState();
  const classes = useStyles();
  const { customer } = useCustomerContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  useEffect(
    () =>
      getCollection(DashboardModel)
        .doc("assetsRenewals")
        .collection("ids")
        .where("customer", "==", customer.ref)
        .orderBy("endTime", "asc")
        .onSnapshot((querySnapshot) => {
          setRenewals((prevRenewals) => {
            const newRenewals = [...(prevRenewals ?? [])];
            arrayFromDocChange(newRenewals, querySnapshot, (doc) => ({
              id: doc.id,
              ref: doc.ref,
              data: doc.data(),
            }));

            return newRenewals;
          });
        }),
    [customer.ref]
  );

  const handleClick = (renewal) => () => {
    history.push(`/customers/${renewal.data.customer.id}/assets/${renewal.data.type}/${renewal.id}`);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  useEffect(() => {
    switch (widgetHeight) {
      case 2: {
        setRowsPerPage(9);
        break;
      }
      case 1.5: {
        setRowsPerPage(6);
        break;
      }
      case 1:
      default: {
        setRowsPerPage(3);
      }
    }
  }, [widgetHeight]);

  const emptyRows = renewals ? rowsPerPage - Math.min(rowsPerPage, renewals.length - page * rowsPerPage) : 0;

  if (renewals && renewals.length === 0) {
    return fallbackComponent;
  }

  if (renewals === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Grid>
      <Card raised={raised} className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title="Renewals"
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader="Your G Suite and Office 365 renewals within the next 3 months"
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
        />
        <CardContent className={classes.cardContent} style={{ height: 200 * widgetHeight }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell padding="none" size="small">
                  Renewal Date
                </TableCell>
                <TableCell padding="none" size="small">
                  Subscription
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renewals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((renewal) => {
                const endTime = DateTime.fromJSDate(renewal.data.endTime.toDate());

                return (
                  <TableRow hover key={renewal.ref.path} className={classes.tableRow} onClick={handleClick(renewal)}>
                    <TableCell padding="none" size="small">
                      {endTime.toLocaleString(DateTime.DATE_MED)}
                    </TableCell>
                    <TableCell padding="none" size="small">
                      {renewal.data.name}
                      <Hide xlDown>
                        <span className={classes.secondaryText}> {renewal.data.domain}</span>
                      </Hide>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 32 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[rowsPerPage]}
                  count={renewals.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
        <div />
      </Card>
    </Grid>
  );
};

export default RenewalsCard;
