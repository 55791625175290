import { type FC } from "react";

import { type ListApiServiceModelDescriptor, type Member, ModelType } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@mui/material";
import { FieldArray, type FieldInputProps } from "formik";
import * as yup from "yup";

import { GenericForm, useApiActionParameterSchema } from "../ApiActionParametersForm";
import { getInitialValue } from "../useApiActionParametersSchema";
import { Fieldset } from "./wrappers/Fieldset";

export const ListParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<unknown[]>;
  label: string;
  onRemove?: () => void;
  inputModel: ListApiServiceModelDescriptor<Member>;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel }) => {
  const schema = useApiActionParameterSchema();

  return (
    <Fieldset label={`List of ${label}`} onRemove={onRemove}>
      <FieldArray
        name={fieldProps.name}
        render={(arrayHelpers) => (
          <Stack spacing={2}>
            {fieldProps.value.map((_, index) => (
              <Fieldset
                key={fieldPath + index.toString()}
                label={`${label} ${index + 1}`}
                onRemove={() => arrayHelpers.remove(index)}
              >
                <GenericForm
                  inputModel={inputModel.member.model}
                  fieldPath={`${fieldPath}[${index}]`}
                  label={inputModel.member.model.type !== ModelType.STRUCTURE ? (inputModel.memberName ?? "") : ""}
                />
              </Fieldset>
            ))}
            <Button
              sx={{ alignSelf: "flex-start" }}
              size="small"
              onClick={() =>
                arrayHelpers.push(
                  (yup.reach(schema, `${fieldPath}[0]`) as yup.Schema).getDefault() ??
                    getInitialValue(inputModel.member.model)
                )
              }
              startIcon={<AddIcon />}
            >
              Append {inputModel.memberName}
            </Button>
          </Stack>
        )}
      />
    </Fieldset>
  );
};
