import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, TableCell, TextField, Tooltip } from "@mui/material";
import clsx from "clsx";

import { type CellInputProps } from "../../../types";
import { formatUSDollars } from "../../../utils";

const CellInput = (props: CellInputProps) => {
  const {
    arrowIconClickedOn,
    cellIndexInPeriod,
    classes,
    focusedCellIndex,
    hasEditPermission,
    hoverKey,
    inputEl,
    nextCellRefOrNull,
    onCellMouseEnter,
    onCellMouseLeave,
    onInputBlur,
    onInputChange,
    onInputFocus,
    onInputKeyPress,
    periodCellsCopy,
    updateFocusedCellIndex,
    arrowClicked,
  } = props;

  const editingCellDisplay =
    periodCellsCopy !== null && !isNaN(periodCellsCopy[cellIndexInPeriod] as number)
      ? (periodCellsCopy[cellIndexInPeriod] as number).toFixed(0)
      : "";

  const prettyDisplay =
    periodCellsCopy !== null && !isNaN(periodCellsCopy[cellIndexInPeriod] as number)
      ? formatUSDollars(periodCellsCopy[cellIndexInPeriod] as number)
      : "";

  const arrowIconForDialog = (
    <Tooltip title="Set ramp growth per month">
      <IconButton onClick={arrowClicked} size="small" className={classes.arrowIcon} name={`${cellIndexInPeriod}`}>
        <ArrowDropDownIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {periodCellsCopy !== null && (
        <TableCell
          padding="none"
          size="small"
          key={cellIndexInPeriod}
          className={clsx(
            classes.borderedCell,
            classes.monthDataCell,
            cellIndexInPeriod === focusedCellIndex && classes.bgColor
          )}
          onMouseEnter={() => onCellMouseEnter(cellIndexInPeriod)}
          onMouseLeave={onCellMouseLeave}
          onBlur={() => {
            focusedCellIndex === cellIndexInPeriod && updateFocusedCellIndex(null);
          }}
        >
          {hasEditPermission && (
            <div style={{ minHeight: 5 }} data-testid="rampCellArrowIconForDialog">
              {hoverKey === cellIndexInPeriod && arrowIconForDialog}
            </div>
          )}
          <TextField
            variant="standard"
            disabled={!hasEditPermission}
            inputProps={{
              index: cellIndexInPeriod,
              key: cellIndexInPeriod,
              className: classes.rowCellInput,
              onFocus: onInputFocus,
              onBlur: onInputBlur,
              name: `${cellIndexInPeriod}`,
              onChange: onInputChange,
              value: cellIndexInPeriod === focusedCellIndex ? editingCellDisplay : prettyDisplay,
            }}
            InputProps={{
              inputRef:
                arrowIconClickedOn === cellIndexInPeriod || focusedCellIndex === cellIndexInPeriod
                  ? inputEl
                  : nextCellRefOrNull,
              disableUnderline: true,
              onKeyPress: onInputKeyPress,
            }}
            data-testid={`plan-input-${cellIndexInPeriod}`}
          />
        </TableCell>
      )}
    </>
  );
};
export default CellInput;
