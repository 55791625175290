import { useCallback, useMemo, useState } from "react";

import { AnalyticsDataSource, type ReportConfig } from "@doitintl/cmp-models";
import { Box, Button, TextField } from "@mui/material";

import { globalText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import DataHubCheckbox from "../../../../Components/DataHubCheckbox/DataHubCheckbox";
import useReportSaver, { NewReportType } from "../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import mixpanel from "../../../../utils/mixpanel";
import { useAnalyticsContext } from "../../CloudAnalyticsContext";
import { useReportConfig, useReportContext, useReportDimensionsContext } from "../../Context";
import { defaultConfig } from "../../generateReport/utils";
import { DataSourceDialog } from "./DataSourceDialog";

type Props = {
  resetReport: (newConfig: ReportConfig) => void;
};

export const DataSourceTexts = new Map([
  [AnalyticsDataSource.BILLING, "Billing Data"],
  [AnalyticsDataSource.BQLENS, "BigQuery Lens"],
  [AnalyticsDataSource.CUSTOMER_FEATURES, "Customer Features"],
  [AnalyticsDataSource.BILLING_DATAHUB, "Billing Data"],
]);

export const DataSourceSection = ({ resetReport }: Props) => {
  const { isDoitPartner } = useAuthContext();
  const [dataSourceDialogOpen, setDataSourceDialogOpen] = useState(false);
  const { createNewReport } = useReportSaver();
  const { customer } = useCustomerContext();
  const { report } = useReportContext();
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { dataSource } = reportConfig;

  const { hasDataHub } = useAnalyticsContext();
  const { dimensions, handleIncludeDatahubUpdated } = useReportDimensionsContext();

  const cloudProviders = useMemo(() => {
    const clouds = dimensions?.find((d) => d.id === "fixed:cloud_provider");
    return clouds?._values.datahub ?? [];
  }, [dimensions]);

  const reportId = report?.snapshot.id;
  const customerId = customer.id;

  const updateMixpanel = useCallback(
    (newDataSource) => {
      if (!customerId || !reportId) {
        return;
      }

      mixpanel.track("analytics.reports.dataSource.update", {
        oldDataSource: dataSource,
        newDataSource,
        reportId,
        customerId,
      });
    },
    [customerId, dataSource, reportId]
  );

  const handleUpdateDataSource = useCallback(
    (newDataSource: AnalyticsDataSource) => {
      const newConfig = { ...defaultConfig, dataSource: newDataSource };
      updateMixpanel(newDataSource);
      dispatchReportConfig({ payload: { dataSource: newDataSource } });
      resetReport(newConfig);
    },
    [updateMixpanel, dispatchReportConfig, resetReport]
  );

  const handleUpdateIncludeDataHub = useCallback(
    (checked: boolean) => {
      const dataSource = checked ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING;
      updateMixpanel(dataSource);
      dispatchReportConfig({ payload: { dataSource } });
      handleIncludeDatahubUpdated(dataSource, reportConfig.timeInterval);
    },
    [updateMixpanel, dispatchReportConfig, handleIncludeDatahubUpdated, reportConfig.timeInterval]
  );

  const handleCreateNewReport = useCallback(
    async (newDataSource: AnalyticsDataSource): Promise<string> => {
      const newConfig = { ...defaultConfig, dataSource: newDataSource };
      updateMixpanel(newDataSource);
      return createNewReport(newConfig, NewReportType.NewDataSource, "");
    },
    [createNewReport, updateMixpanel]
  );

  return (
    <>
      <TextField
        sx={{ mt: 4, width: "100%" }}
        label={reportTxt.DATA_SOURCE}
        value={DataSourceTexts.get(dataSource)}
        InputProps={{
          endAdornment: (
            <Box ml="auto">
              <Button
                variant="text"
                onClick={() => setDataSourceDialogOpen(true)}
                sx={{
                  "&:hover": {
                    backgroundColor: "inherit", // Disable background change on hover
                    boxShadow: "none", // Disable box shadow on hover (if applicable)
                  },
                }}
              >
                {globalText.CHANGE}
              </Button>
            </Box>
          ),
        }}
      />
      {!isDoitPartner &&
        (dataSource === AnalyticsDataSource.BILLING || dataSource === AnalyticsDataSource.BILLING_DATAHUB) && (
          <DataHubCheckbox
            checked={dataSource === AnalyticsDataSource.BILLING_DATAHUB}
            cloudProviders={cloudProviders}
            customerId={customerId}
            dataSource={dataSource}
            disabled={!hasDataHub}
            onChange={(_, checked) => handleUpdateIncludeDataHub(checked)}
            sx={{ mb: 4, mt: 1 }}
          />
        )}
      {dataSourceDialogOpen && (
        <DataSourceDialog
          open={dataSourceDialogOpen}
          onClose={() => setDataSourceDialogOpen(false)}
          onCreateNewReport={handleCreateNewReport}
          onSave={handleUpdateDataSource}
          currentDataSource={dataSource}
        />
      )}
    </>
  );
};
