import { type FC, useEffect, useState } from "react";

import { type TimestampApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import type { FieldInputProps, FormikProps } from "formik";

import { useFieldValidationProps } from "../useFieldValidationProps";

type Transformers<T = string | number> = [
  (value: string | number) => DateTime,
  T extends string ? (value: DateTime) => string | null : T extends number ? (value: DateTime) => number : never,
];

function createTransformersFromFormat(format: string): Transformers {
  switch (true) {
    case format === "X":
      return [(value) => DateTime.fromSeconds(value as number), (value: DateTime) => value.toUTC().toSeconds()];
    case format === "x":
      return [(value) => DateTime.fromMillis(value as number), (value: DateTime) => value.toUTC().toMillis()];
    default:
      return [
        (value) => DateTime.fromFormat(value as string, format),
        (value: DateTime) => value.toUTC().toFormat(format),
      ];
  }
}

export const TimestampParam: FC<{
  formikProps: FormikProps<unknown>;
  fieldProps: FieldInputProps<string | number | null>;
  label: string;
  inputModel: TimestampApiServiceModelDescriptor;
  onRemove?: () => void;
}> = ({ formikProps, fieldProps, label, onRemove, inputModel }) => {
  const format = inputModel.timestampFormat ?? "X";
  const [datetime, setDatetime] = useState<DateTime | null>(null);
  const { showError, errorMessage } = useFieldValidationProps(formikProps, fieldProps);
  const [[fromModelValue, toModelValue], setTransformers] = useState<Transformers>(
    createTransformersFromFormat(format)
  );
  useEffect(() => setTransformers(createTransformersFromFormat(format)), [format]);
  const required = onRemove === undefined;

  useEffect(() => {
    if (fieldProps.value !== null) {
      setDatetime(fromModelValue(fieldProps.value));
    }
  }, [fieldProps.value, fromModelValue]);

  return (
    <DateTimePicker
      renderInput={(params) => (
        <TextField
          {...params}
          name={fieldProps.name}
          required={required}
          helperText={errorMessage}
          error={showError}
          onBlur={fieldProps.onBlur}
          fullWidth
        />
      )}
      label={label}
      value={datetime}
      onChange={(datetime) => {
        if (datetime) {
          formikProps.setFieldValue(fieldProps.name, toModelValue(datetime));
        }
      }}
      onClose={() => formikProps.setFieldTouched(fieldProps.name)}
    />
  );
};
