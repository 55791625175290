import { useEffect, useState } from "react";

import { DateTime } from "luxon";

export const useDateTime = () => {
  const [startOfDay, setStartOfDay] = useState(DateTime.utc().startOf("day"));
  const [currentHour, setCurrentHour] = useState(DateTime.utc().startOf("hour"));

  useEffect(() => {
    let mounted = true;
    const updateStartOfDay = () => {
      if (!mounted) {
        return;
      }
      setStartOfDay(DateTime.utc().startOf("day"));
    };

    const updateCurrentHour = () => {
      if (!mounted) {
        return;
      }
      setCurrentHour(DateTime.utc().startOf("hour"));
    };

    const nowDateTime = DateTime.utc();
    const nextHour = nowDateTime.plus({ hours: 1 }).startOf("hour");
    const timeUntilNextHour = nextHour.diff(nowDateTime).as("milliseconds");

    const firstTimeout = setTimeout(() => {
      updateCurrentHour();
      const hourlyInterval = setInterval(updateCurrentHour, 1000 * 60 * 60); // Update every hour

      // Clear the interval on component unmount
      return () => clearInterval(hourlyInterval);
    }, timeUntilNextHour);

    const nextMidnight = nowDateTime.plus({ days: 1 }).startOf("day");
    const timeUntilMidnight = nextMidnight.diff(nowDateTime).as("milliseconds");

    const midnightTimeout = setTimeout(() => {
      updateStartOfDay();
      const dailyInterval = setInterval(updateStartOfDay, 1000 * 60 * 60 * 24); // Update every 24 hours

      // Clear the interval on component unmount
      return () => clearInterval(dailyInterval);
    }, timeUntilMidnight);

    // Clear the timeout on component unmount
    return () => {
      mounted = false;
      clearTimeout(firstTimeout);
      clearTimeout(midnightTimeout);
    };
  }, []);

  return { startOfDay, currentHour };
};
