import { createContext, type FC, useContext, useEffect, useState } from "react";

import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Form, Formik, useFormikContext } from "formik";
import type * as yup from "yup";

import { BooleanParam } from "./parameters/BooleanParam";
import { ListParam } from "./parameters/ListParam";
import { NumberParam } from "./parameters/NumberField";
import { StringParam } from "./parameters/StringParam";
import { StructureParam } from "./parameters/StructureParam";
import { TimestampParam } from "./parameters/TimestampParam";
import { useApiActionParametersSchema } from "./useApiActionParametersSchema";

const SchemaContext = createContext<yup.Schema<any, any, any, "" | "d"> | null>(null);

export const useApiActionParameterSchema = () => {
  const context = useContext(SchemaContext);
  if (!context) {
    throw new Error("useSchemaContext must be used within a SchemaProvider");
  }
  return context;
};

export const GenericForm: FC<{
  fieldPath: string;
  inputModel: UnwrappedApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
}> = ({ inputModel, fieldPath, onRemove, label }) => {
  const formikProps = useFormikContext();

  const fieldProps = formikProps.getFieldProps(fieldPath);

  if (fieldProps.value === undefined) {
    return null;
  }

  switch (inputModel.type) {
    case ModelType.STRING:
      return (
        <StringParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          formikProps={formikProps}
          label={label}
          onRemove={onRemove}
        />
      );

    case ModelType.TIMESTAMP:
      return (
        <TimestampParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          formikProps={formikProps}
          label={label}
          onRemove={onRemove}
        />
      );

    case ModelType.INTEGER:
    case ModelType.FLOAT:
      return <NumberParam fieldProps={fieldProps} formikProps={formikProps} label={label} onRemove={onRemove} />;

    case ModelType.BOOLEAN:
      return <BooleanParam formikProps={formikProps} fieldProps={fieldProps} label={label} onRemove={onRemove} />;

    case ModelType.LIST:
      return (
        <ListParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    case ModelType.STRUCTURE:
      return (
        <StructureParam
          fieldPath={fieldPath}
          formikProps={formikProps}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    default:
      return null;
  }
};

export const ApiActionParametersForm: FC<{
  inputModel: UnwrappedApiServiceModelDescriptor;
  values?: object;
}> = ({ inputModel, values, children }) => {
  const validationSchema = useApiActionParametersSchema(inputModel);
  const [initialValues, setInitialValues] = useState(validationSchema.getDefault());

  useEffect(() => {
    if (values) {
      const castData = validationSchema.cast(values);
      setInitialValues(castData);
    } else {
      setInitialValues(validationSchema.getDefault());
    }
  }, [validationSchema, values]);

  return (
    <SchemaContext.Provider value={validationSchema}>
      <Formik
        enableReinitialize
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {() => <Form>{children}</Form>}
      </Formik>
    </SchemaContext.Provider>
  );
};
