import { type JSX, useCallback, useEffect, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { AppModel, EarlyAccessFeature, PerkModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { getPlatformNonPromotion, getSingularTypeText, perkPathByType } from "../helpers";
import { usePerksApi, usePerkTagsHandler } from "../hooks";
import { RegisterInterestFromType, type TrainingRequest } from "../types";
import { PerkContent } from "./PerkContent";
import { PerkHeader } from "./PerkHeader";
import { PerkStickyHeader } from "./PerkStickyHeader";
import { RegisterInterestDialog } from "./RegisterInterestDialog";
import { RequestTrainingDialog } from "./RequestTrainingDialog";

const fullHeaderHeight = 308;
const containerWidth = 793;

const defaultSectionTitles = {
  overview: "Overview",
  checklist: "Pricing",
  agenda: "Support",
  modules: "Features",
};

export type SectionTitles = {
  overview: string;
  checklist: string;
  agenda: string;
  modules: string;
};

const PerkView = () => {
  const { perkId } = useParams<{ perkId: string }>();
  const theme = useTheme();
  const history = useHistory();

  const { customer } = useCustomerContext();
  const { isDoitEmployee, userId, currentUser } = useAuthContext({ mustHaveUser: true });
  const snackbar = useSnackbar();
  const perksApi = usePerksApi();

  const [perk, setPerk] = useState<PerkModel>();
  const [sectionTitles, setSectionTitles] = useState<SectionTitles>(defaultSectionTitles);
  const [loading, setLoading] = useState(false);
  const [disabledPerk, setDisabledPerk] = useState<boolean>(true);
  const [stickyHeaderActive, setStickyHeaderActive] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [requestTrainingDialogOpen, setRequestTrainingDialogOpen] = useState(false);

  const getPerkTags = usePerkTagsHandler({ perk, isDoitEmployee });
  const pathname = useLocation().pathname?.replace(/\/+$/, "");
  const backTo = pathname.substring(0, pathname.lastIndexOf("/"));

  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      setStickyHeaderActive(document.documentElement.scrollTop > fullHeaderHeight);
    };
    window.addEventListener("scroll", resizeHeaderOnScroll);
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
    };
  }, [setStickyHeaderActive]);

  // will return the user to perks list view if no accelerator feature is on the customer
  // and perk is part of the acclerator program
  useEffect(() => {
    if (
      !customer.earlyAccessFeatures?.includes(EarlyAccessFeature.ACCELERATOR_PROGRAM) &&
      perk?.fields.acceleratorProgram &&
      !isDoitEmployee
    ) {
      history.push(`/customers/${customer.id}/${perkPathByType[perk.fields.type]}`);
    }
  }, [perk, customer, history, isDoitEmployee]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getCollection(PerkModel).doc(perkId).get(),
      getCollection(AppModel).doc("perks").get(),
      getCollection(PerkModel).doc(perkId).collection("requestedUsers").doc(userId).get(),
    ])
      .then(([readPerkDoc, configDoc, ticketSnapshot]) => {
        const config = configDoc.asModelData();
        const readPerk = readPerkDoc.asModelData();
        setPerk(readPerk);

        const customSectionTitles = config?.perksType?.find((el) => el.id === (readPerk?.fields.type ?? "promotion"));
        if (customSectionTitles) {
          Object.keys(defaultSectionTitles).forEach((key) => {
            if (customSectionTitles[key]) {
              defaultSectionTitles[key] = customSectionTitles[key];
            }
          });
          setSectionTitles(defaultSectionTitles);
        }

        const tickets = ticketSnapshot.asModelData();
        const ticketId = isDoitEmployee ? tickets?.ticketsOnBehalfOfCustomers?.[customer.id] : tickets?.ticketId;

        if (!ticketId || !readPerk) {
          setDisabledPerk(false);
          return;
        }

        return perksApi
          .getTicketById(readPerk.fields.name, ticketId)
          .then((resp) => {
            const perkDisabled = resp.data.ticket.status !== "closed" && resp.data.ticket.status !== "solved";
            setDisabledPerk(perkDisabled);
          })
          .catch((error) => {
            setDisabledPerk(true);
            consoleErrorWithSentry(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customer.id, isDoitEmployee, perkId, perksApi, userId]);

  const registerInterestTrainingProServ = useCallback(
    async (trainingRequest?: TrainingRequest) => {
      setLoading(true);
      let accountManager: string | undefined = undefined;
      if (customer.accountTeam?.length) {
        const ref = customer.accountTeam.find(({ company }) => company === "doit")?.ref;
        if (ref) {
          const accountManagerDoc = await ref.get();
          accountManager = accountManagerDoc.data()?.email;
        }
      }
      try {
        const ticketRes = await perksApi.registerInterestTicket({
          domain: customer.primaryDomain,
          perkId,
          perkName: perk?.fields.name,
          perkType: perk?.fields.type || "",
          tags: [...getPerkTags(), ...(perk?.fields.zendeskTags || [])],
          platform: getPlatformNonPromotion(perk),
          userEmail: currentUser.email,
          accountManager,
          trainingRequest,
          isDoitEmployee,
        });

        if (!ticketRes.data.id) {
          return;
        }

        const ticketSnapshot = await getCollection(PerkModel)
          .doc(perkId)
          .collection("requestedUsers")
          .doc(userId)
          .get();

        if (isDoitEmployee) {
          const ticketsOnBehalfOfCustomers = ticketSnapshot.asModelData()?.ticketsOnBehalfOfCustomers ?? {};
          ticketsOnBehalfOfCustomers[customer.id] = ticketRes.data.id;
          await ticketSnapshot.modelRef.set({ ticketsOnBehalfOfCustomers });
        } else {
          await ticketSnapshot.modelRef.set({ ticketId: ticketRes.data.id });
        }

        setDisabledPerk(true);

        const successMessage: JSX.Element = (
          <Typography component="span" variant="body1">
            {`You successfully requested ${getSingularTypeText(perk?.fields.type || "pro-serv")}, follow the ticket`}
            <Link
              color="inherit"
              component={Button}
              p={0}
              onClick={() => {
                history.push(`/customers/${customer.id}/support/tickets/${ticketRes.data.id}`);
              }}
            >{`#${ticketRes.data.id}`}</Link>
            here
          </Typography>
        );

        snackbar.onOpen({
          message: successMessage,
          variant: "success",
          autoHideDuration: 5000,
        });
      } catch (err: any) {
        snackbar.onOpen({
          message: err.message,
          variant: "error",
          autoHideDuration: 5000,
        });
      } finally {
        setRequestTrainingDialogOpen(false);
        setLoading(false);
      }
    },
    [
      customer.accountTeam,
      customer.primaryDomain,
      customer.id,
      perksApi,
      perkId,
      perk,
      getPerkTags,
      currentUser.email,
      userId,
      isDoitEmployee,
      snackbar,
      history,
    ]
  );

  const registerInterestIsvSolutions = useCallback(
    async (interestFrom: RegisterInterestFromType) => {
      setLoading(true);
      try {
        await perksApi.registerInterestEmail({
          perkName: perk?.fields.name || "",
          userEmail: currentUser.email,
          clickedOn: interestFrom,
        });
        setLoading(false);
        if (interestFrom === RegisterInterestFromType.InterestButton) {
          setRegisterDialogOpen(true);
        }
      } catch (err: any) {
        snackbar.onOpen({
          message: err.message,
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [perk?.fields.name, perksApi, snackbar, currentUser.email]
  );

  const registerInterest = useCallback(
    async (interestFrom: RegisterInterestFromType) => {
      if (!perk?.fields.type) {
        return;
      }

      if (perk.fields.type === "promotion" || perk.fields.type === "isv-solution") {
        await registerInterestIsvSolutions(interestFrom);
        return;
      }

      if (["training", "workshop", "accelerator"].includes(perk.fields.type)) {
        setRequestTrainingDialogOpen(true);
        return;
      }
      await registerInterestTrainingProServ();
    },
    [perk?.fields.type, registerInterestIsvSolutions, registerInterestTrainingProServ]
  );

  return (
    <>
      {loading && !perk && (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh" width="100%">
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      )}
      {perk && (
        <Box>
          {registerDialogOpen && (
            <RegisterInterestDialog
              onClose={() => {
                setRegisterDialogOpen(false);
              }}
            />
          )}
          {requestTrainingDialogOpen && (
            <RequestTrainingDialog
              name={perk?.fields.name}
              loading={loading}
              onConfirm={registerInterestTrainingProServ}
              onClose={() => {
                setRequestTrainingDialogOpen(false);
              }}
            />
          )}
          {stickyHeaderActive ? (
            <PerkStickyHeader
              backTo={backTo}
              customerId={customer.id}
              isDoitEmployee={isDoitEmployee}
              disabledPerk={disabledPerk ?? true}
              registerInterest={registerInterest}
              perk={perk}
              containerWidth={containerWidth}
              sectionTitles={sectionTitles}
            />
          ) : (
            <PerkHeader
              backTo={backTo}
              isDoitEmployee={isDoitEmployee}
              disabledPerk={disabledPerk ?? true}
              registerInterest={registerInterest}
              perk={perk}
              containerWidth={containerWidth}
              sectionTitles={sectionTitles}
            />
          )}
          <PerkContent
            perk={perk}
            sectionTitles={sectionTitles}
            containerWidth={containerWidth}
            registerInterest={registerInterest}
          />
        </Box>
      )}
    </>
  );
};

export default PerkView;
