import { type ReactNode, useCallback, useRef, useState } from "react";

import {
  type DashboardModelAttributionModel as AttributionModel,
  type DashboardModelAttributionModel,
} from "@doitintl/cmp-models";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { attributionText, globalText } from "../../../../assets/texts";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import LoadingButton from "../../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useFullScreen } from "../../../../utils/dialog";
import { createAttribution } from "../db";
import AttributionBuilder from "./AttributionBuilder";

export type AttributionCreateDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSuccess: (attributionName: string) => void;
  children?: ReactNode;
};

type AttributionsFieldsPayload = Omit<Partial<DashboardModelAttributionModel>, "expireBy"> & { expireBy?: Date | null };

const totalSteps = 2;
const maxHeight = 500;

const AttributionCreateDialog = ({ open, onSuccess, onCancel, children }: AttributionCreateDialogProps) => {
  const api = useApiContext();
  const { isMobile } = useFullScreen("sm");
  const { customer } = useCustomerContext();
  const showSuccessSnackbar = useSuccessSnackbar();
  const [validAttribution, setValidAttribution] = useState(false);
  const [attributionName, setAttributionName] = useState("");
  const [attributionData, setAttributionData] = useState<Partial<AttributionModel> | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const paperRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setCurrentStep(1);
    setAttributionData(null);
    setAttributionName("");
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(async () => {
    if (!attributionName) {
      inputRef.current?.focus();
      setError(true);
      return;
    }
    setLoading(true);
    // clean out N/A values and empty filters before saving.
    if (attributionData?.filters) {
      attributionData.filters = attributionData?.filters.filter((filter) => {
        if (!filter.values?.length) {
          return false;
        }
        filter.values = filter.values.filter((value) => !value.includes(globalText.NA));
        return filter;
      });
    }
    const newAttribution = await createAttribution(
      api,
      customer.id,
      attributionData as AttributionsFieldsPayload,
      undefined,
      {
        from: "attribution-builder",
      }
    );
    setLoading(false);
    showSuccessSnackbar(attributionText.SUCCESSFULLY_CREATED);
    onSuccess(newAttribution.data?.id ?? "");
    handleClose();
  }, [api, attributionData, attributionName, customer.id, handleClose, onSuccess, showSuccessSnackbar]);

  useUpdateEffect(() => {
    if (attributionName) {
      setError(false);
      setAttributionData((prevData) => ({
        ...prevData,
        name: attributionName,
      }));
    }
  }, [attributionName]);

  const currentPaperHeight = paperRef.current?.getBoundingClientRect().height ?? 0;

  return (
    <>
      {children}
      {open && (
        <Dialog
          fullWidth
          fullScreen={isMobile}
          onClose={handleClose}
          open={open}
          maxWidth="md"
          scroll="paper"
          PaperProps={{ ref: paperRef }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            {attributionText.CREATE_NEW_ATTRIBUTION_STEP(currentStep, totalSteps)}
          </DialogTitle>
          {currentStep === 1 && (
            <DialogContentText sx={{ pb: 2, ml: 3 }}>{attributionText.DIALOG_SUB_TITLE}</DialogContentText>
          )}
          <DialogContent dividers={currentPaperHeight > maxHeight}>
            {currentStep === 1 && (
              <AttributionBuilder
                attributionData={attributionData}
                setAttributionData={setAttributionData}
                setValidAttribution={setValidAttribution}
              />
            )}
            {currentStep === 2 && (
              <TextField
                inputRef={inputRef}
                autoFocus
                variant="outlined"
                margin="dense"
                onChange={(event) => setAttributionName(event.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleConfirm();
                  }
                }}
                fullWidth
                label={attributionText.ATTRIBUTION_NAME}
                error={error}
                helperText={error ? attributionText.NO_ATTRIBUTION_NAME : ""}
                inputProps={{
                  maxLength: 150,
                }}
                value={attributionName}
              />
            )}
          </DialogContent>
          {currentPaperHeight < maxHeight && <Divider light />}
          <DialogActions>
            <Grid container>
              <Grid item xs={2}>
                {currentStep > 1 && (
                  <Button color="primary" onClick={() => setCurrentStep(currentStep - 1)} variant="text">
                    {attributionText.DIALOG_ACTION_BACK}
                  </Button>
                )}
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="row-reverse">
                  {currentStep < totalSteps ? (
                    <Button
                      color="primary"
                      onClick={() => setCurrentStep(currentStep + 1)}
                      disabled={!validAttribution}
                      variant="contained"
                    >
                      {attributionText.DIALOG_ACTION_NEXT}
                    </Button>
                  ) : (
                    <LoadingButton
                      color="primary"
                      onClick={handleConfirm}
                      disabled={loading}
                      loading={loading}
                      variant="contained"
                      mixpanelEventId="analytics.attribution-create.add"
                    >
                      {attributionText.DIALOG_ACTION_ADD}
                    </LoadingButton>
                  )}
                  <Button color="primary" onClick={handleClose} variant="text">
                    {attributionText.DIALOG_ACTION_CANCEL}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AttributionCreateDialog;
