import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import sumBy from "lodash/sumBy";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { PurchaseConfirmationDialog } from "../Common/PurchaseConfirmationDialog";
import { TargetCoverageDialog } from "../Common/TargetCoverageDialog";
import { saveNote, updateForceTargetCoverageForCustomer, updateMaxTargetCoverageForCustomer } from "../db";
import { type CustomerAggregatedPurchaseItem } from "../types";
import { AggregatedPurchasesRow } from "./AggregatedPurchasesRow";
import { defaultFilters, filters, headerColumns } from "./columns";

type Props = {
  customerAggregatedPurchaseItems: CustomerAggregatedPurchaseItem[];
};

const CustomerPurchases = memo(({ customerAggregatedPurchaseItems }: Props) => {
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);
  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const [selectedPurchaseItems, setSelectedPurchaseItems] = useState<CustomerAggregatedPurchaseItem[]>([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const [purchaseToEdit, setPurchaseToEdit] = useState<CustomerAggregatedPurchaseItem | null>(null);
  const [targetCoverageDialogOpen, setTargetCoverageDialogOpen] = useState<boolean>(true);
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  useEffect(() => setTargetCoverageDialogOpen(!!purchaseToEdit), [purchaseToEdit]);

  const canApproveAllSelectedItems = useMemo(() => {
    if (selectedPurchaseItems.length === 0 || !customerAggregatedPurchaseItems) {
      return false;
    }
    return selectedPurchaseItems.every((selectedItem) => selectedItem.canBeApproved);
  }, [selectedPurchaseItems, customerAggregatedPurchaseItems]);

  const onDialogApproveClicked = useCallback(async () => {
    if (selectedPurchaseItems.length > 0) {
      const ids = selectedPurchaseItems.map((item) => item.customerId);
      await flexSaveOpsApi.approveV2(ids);
    }
  }, [selectedPurchaseItems, flexSaveOpsApi]);

  const handleTargetCoverageDialogUpdate = useCallback(
    async (value, targetCoverageNote, forceTargetCoverage) => {
      if (purchaseToEdit) {
        await updateMaxTargetCoverageForCustomer(purchaseToEdit.customerId, value / 100);
        purchaseToEdit.forceTargetCoverage !== forceTargetCoverage &&
          (await updateForceTargetCoverageForCustomer(purchaseToEdit.customerId, forceTargetCoverage));
        const customerNote =
          purchaseToEdit.targetCoverage !== value
            ? `Target coverage changed to ${value}% from ${purchaseToEdit.targetCoverage * 100}%.${targetCoverageNote}.`
            : `Target coverage set to ${value}.${targetCoverageNote}.`;
        await saveNote(purchaseToEdit.customerId, customerNote, ["flexsave"], currentUser);
        await flexSaveOpsApi.refreshCustomer(purchaseToEdit.customerId);
      }
    },
    [currentUser, purchaseToEdit, flexSaveOpsApi]
  );

  const handleApproveDialogClose = useCallback(() => setApproveDialogOpen(false), []);
  const handlePurchaseButtonClick = useCallback(() => setApproveDialogOpen(true), []);

  const handleTargetCoverageDialogClose = useCallback(() => setPurchaseToEdit(null), []);

  const AggregatedPurchasesRowWrapper = useCallback(
    (props: { row: CustomerAggregatedPurchaseItem }) => (
      <AggregatedPurchasesRow row={props.row} onEditClick={setPurchaseToEdit} />
    ),
    []
  );

  return (
    <>
      <PurchaseConfirmationDialog
        open={approveDialogOpen}
        handleClose={handleApproveDialogClose}
        handleApprove={onDialogApproveClicked}
        totalCost={sumBy(selectedPurchaseItems, "purchasePlanPrice")}
        customerCount={selectedPurchaseItems.length}
        cudCount={sumBy(selectedPurchaseItems, "purchaseRecommendations")}
        customerName={selectedPurchaseItems.length === 1 ? selectedPurchaseItems[0].primaryDomain : undefined}
      />
      <FilterTable<CustomerAggregatedPurchaseItem>
        toolbarProps={{
          title: "Flexsave GCP Ops",
          allowToEditColumns: true,
          primaryButton: isFlexsaveAdmin
            ? {
                text: "Purchase",
                onClick: handlePurchaseButtonClick,
                color: "warning",
                disabled: !canApproveAllSelectedItems,
              }
            : undefined,
        }}
        tableItems={customerAggregatedPurchaseItems}
        showRowsSelection={isFlexsaveAdmin}
        onRowsSelected={setSelectedPurchaseItems}
        rowComponent={AggregatedPurchasesRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        defaultFilters={defaultFilters}
        persistenceKey="flexsave_opts"
      >
        {purchaseToEdit && (
          <TargetCoverageDialog
            handleClose={handleTargetCoverageDialogClose}
            handleCoverageApplied={handleTargetCoverageDialogUpdate}
            forceTargetCoverage={purchaseToEdit.forceTargetCoverage}
            value={purchaseToEdit.targetCoverage * 100}
            open={targetCoverageDialogOpen}
            coverageType="customer"
          />
        )}
      </FilterTable>
    </>
  );
});

CustomerPurchases.displayName = "CustomerPurchases";
export default CustomerPurchases;
