import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Alert, Button, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import mixpanel from "../../../utils/mixpanel";
import { type PageId } from "../../config/pages";

type Props = {
  hide: boolean;
  pageId?: PageId;
};

const hideInPages: PageId[] = []; // todo: once eks table page added to navigation add the page id here

export const EKSAlert = ({ hide, pageId }: Props) => {
  const history = useHistory();
  const { customer } = useCustomerContext({ allowNull: true });
  const [open, setOpen] = useState<boolean>(false);

  // hide for all customers now.
  // TODO: let's decide if we want to add back or remove this alert completely
  hide = true;

  const { isFeatureEntitled } = useTier();
  if (!isFeatureEntitled("lens:eks")) {
    hide = true;
  }

  useEffect(() => {
    setOpen(!hide && !!customer?.id);
  }, [hide, customer?.id]);

  const handleClickEKS = useCallback(() => {
    if (!customer?.id) {
      return;
    }

    mixpanel.track("customer.open.eks");
    history.push(`/customers/${customer.id}/eks-onboarding`);
  }, [customer?.id, history]);

  const shouldHideInPage = pageId && hideInPages.includes(pageId);

  return (
    <>
      {open && (
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          sx={{ display: shouldHideInPage ? "none" : "flex", py: 0 }}
        >
          We’ve detected EKS clusters in your account. To get advanced insights into your Kubernetes spend, activate EKS
          Lens{" "}
          <Button sx={{ py: 0 }} color="inherit" variant="text" onClick={() => handleClickEKS()}>
            <Typography variant="inherit" fontWeight="bold">
              Click here
            </Typography>
          </Button>
        </Alert>
      )}
    </>
  );
};
