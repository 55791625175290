import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";

import { cloudflowTexts, globalText } from "../../../../assets/texts";

type DeleteIfNodeDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const DeleteIfNodeDialog = ({ open, onCancel, onConfirm }: DeleteIfNodeDialogProps) => (
  <Dialog open={open} onClose={onCancel} maxWidth="sm">
    <DialogTitle>{cloudflowTexts.DELETE_IF_STATEMENT}</DialogTitle>
    <DialogContent>
      <DialogContentText>{cloudflowTexts.DELETE_IF_NODE_DIALOG_CONTENT}</DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={onCancel}>{globalText.CANCEL}</Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          onConfirm();
        }}
      >
        {globalText.DELETE}
      </Button>
    </DialogActions>
  </Dialog>
);
