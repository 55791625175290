import { Autocomplete } from "@mui/material";
import { type FormikErrors } from "formik";

import GoogleMaps from "../../../../Pages/Entity/Components/GoogleMaps";
import countries from "../../../../Pages/Entity/countries";
import priorityStates from "../../../../Pages/Entity/PriorityState";
import { fillAddressFromGMaps } from "../../../../Pages/Entity/utils";
import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { CustomInput } from "../common/CustomInput";
import { PanelWrapper } from "../common/PanelWrapper";

const countryCodes = Object.fromEntries(countries.map(({ COUNTRYNAME, COUNTRYCODE }) => [COUNTRYNAME, COUNTRYCODE]));
const countryList = countries.map(({ COUNTRYNAME }) => COUNTRYNAME);

export const ADDRESS_PANEL_ID = "billing-and-company-details";

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: CreateBillingProfileData;
  errors: FormikErrors<CreateBillingProfileData>;
  setFieldValue: (field: string, value: string | null) => void;
  inEditMode: boolean;
};

export const AddressPanel = ({ handleChange, values, errors, setFieldValue, inEditMode }: Props) => {
  const handleReset = () => {
    setFieldValue("country", "");
    setFieldValue("state", "");
    setFieldValue("address", "");
    setFieldValue("city", "");
    setFieldValue("zip", "");
  };

  const states = priorityStates[values.country];

  return (
    <PanelWrapper title="Billing address" subtitle="Billing address of your organization" id={ADDRESS_PANEL_ID}>
      <GoogleMaps
        onChange={fillAddressFromGMaps(setFieldValue, true)}
        onClear={handleReset}
        placeholder="Address finder"
        bigInput={true}
        country={countryCodes[values.country]?.toLowerCase()}
      />
      <Autocomplete
        data-cy="country"
        disableClearable
        disabled={inEditMode}
        options={countryList}
        value={values.country}
        onChange={(_, value) => {
          setFieldValue("country", value);
          setFieldValue("state", null);
        }}
        renderInput={(params) => (
          <CustomInput name="country" label="Country" required error={Boolean(errors.country)} {...params} />
        )}
      />

      {states && (
        <Autocomplete
          data-cy="state"
          options={states}
          disableClearable
          value={{ name: values.state || "", code: values.stateCode || "" }}
          isOptionEqualToValue={(option, value) => option.name === value.name && option.code === value.code}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setFieldValue("state", newValue.name);
            setFieldValue("stateCode", newValue.code);
          }}
          renderInput={(params) => <CustomInput {...params} label="State" fullWidth error={Boolean(errors.state)} />}
        />
      )}

      <CustomInput
        data-cy="city"
        name="city"
        label="City"
        value={values.city}
        onChange={handleChange}
        error={Boolean(errors.city)}
        maxLength={80}
      />
      <CustomInput
        data-cy="address"
        name="address"
        label="Address"
        value={values.address}
        onChange={handleChange}
        error={Boolean(errors.address)}
        maxLength={80}
        required
      />
      <CustomInput
        data-cy="zip"
        name="zip"
        label="Zip code"
        value={values.zip}
        onChange={handleChange}
        error={Boolean(errors.zip)}
        maxLength={10}
        required
      />
    </PanelWrapper>
  );
};

export default AddressPanel;
