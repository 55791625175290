import { useCallback, useEffect, useMemo, useState } from "react";

import { type ContractSupport, type IntegrationModelCloudhealthPricebooksModel } from "@doitintl/cmp-models";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import noop from "lodash/noop";

import AccountsList from "./AccountsList";
import { type AwsSupportStepProps, type supportMapObj } from "./types";
import { newAccountBox, payerAccountComparator } from "./utils";

const AwsSupportStep = (props: AwsSupportStepProps) => {
  const {
    handleChangeSupport,
    contractSupportErrorBool,
    support,
    allPayerAccounts,
    disableNext,
    selectedPricebooks,
    onChangeSelectedPricebooks,
    isEditForbidden,
  } = props;
  const theme = useTheme();
  const [contractSupportCopy, setContractSupportCopy] = useState<supportMapObj>({});

  useEffect(
    () =>
      setContractSupportCopy(
        support === null || Object.keys(support).length === 0 ? { ...newAccountBox } : { ...support }
      ),
    [support]
  );

  const updateStepSupportObj = (updatedSupportObj: supportMapObj, indexToUpdate: number): void => {
    const [mpaId, payerAccount] = Object.entries(updatedSupportObj).flat(1) as [string, ContractSupport];

    handleChangeSupport(
      Object.fromEntries(
        Object.entries(contractSupportCopy).map((entry, index) =>
          index === indexToUpdate ? [mpaId, payerAccount] : entry
        )
      )
    );
  };

  const onAddPayerAccount = () => handleChangeSupport({ ...support, ...newAccountBox });

  const handleDelete = (indexToDelete: number): void => {
    handleChangeSupport(
      Object.entries(contractSupportCopy).reduce((newSupport, [mpaId, payerAccount], index) => {
        if (index !== indexToDelete) {
          newSupport[mpaId] = payerAccount;
        } else {
          onChangeSelectedPricebooks({ ...selectedPricebooks, [mpaId]: null });
        }
        return newSupport;
      }, {})
    );
  };

  const changeSelectedPricebooks = useCallback(
    (selectedPricebook: IntegrationModelCloudhealthPricebooksModel | null, mpaId) =>
      onChangeSelectedPricebooks({ ...selectedPricebooks, [mpaId]: selectedPricebook?.id ?? null }),
    [onChangeSelectedPricebooks, selectedPricebooks]
  );

  const existsInSupport = useCallback(
    (mpaId: string): boolean => contractSupportCopy[mpaId] !== undefined,
    [contractSupportCopy]
  );

  const sortedPayerList = useMemo(() => Array.from(allPayerAccounts).sort(payerAccountComparator), [allPayerAccounts]);

  const disableAddPayer = Object.keys(contractSupportCopy).some((key) => key === "");

  return allPayerAccounts.length ? (
    <Box data-testid="aws-contract-support-step" data-cy="aws-contract-support-step" sx={{ width: "100%" }}>
      <AccountsList
        support={contractSupportCopy}
        payerList={sortedPayerList}
        updateStepSupportObj={updateStepSupportObj}
        handleDelete={handleDelete}
        contractSupportErrorBool={contractSupportErrorBool}
        onEditDone={noop}
        inEdit={disableNext}
        existsInSupport={existsInSupport}
        onChangeSelectedPricebooks={changeSelectedPricebooks}
        selectedPricebooks={selectedPricebooks}
        disabled={isEditForbidden}
      />
      <Button
        onClick={onAddPayerAccount}
        sx={{ marginTop: theme.spacing(3) }}
        data-testid="aws-contract-support-add-payer-btn"
        disabled={disableAddPayer || isEditForbidden}
      >
        Add payer account
      </Button>
    </Box>
  ) : (
    <></>
  );
};

export default AwsSupportStep;
