import { useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { signInWithCustomToken as firebaseSignInWithCustomToken } from "firebase/auth";

import { consoleErrorWithSentry } from "../../utils";
import { getCookie } from "../../utils/cookie";
import { auth } from "../../utils/firebase";
import mixpanel from "../../utils/mixpanel";
import { SegmentEvents, segmentTrack } from "../../utils/segment";
import { useQuery } from "../../utils/useQuery";
import { paths } from "./helpers";

const pollForCurrentUser = async () => {
  let intervalId: NodeJS.Timeout;
  const startTime = Date.now(); // Current time in milliseconds

  if (auth.currentUser) {
    return true;
  }

  return new Promise<boolean>((resolve) => {
    intervalId = setInterval(() => {
      const timeElapsed = Date.now() - startTime;

      if (auth.currentUser) {
        clearInterval(intervalId);
        resolve(true);
      }

      if (timeElapsed >= 15000) {
        // 15 seconds have passed
        clearInterval(intervalId);
        resolve(false);
      }
    }, 500); // Poll every 500 milliseconds
  });
};

export const LoginSuccess = () => {
  const history = useHistory();
  const query = useQuery();

  const errHandler = useCallback(
    (err: Error) => {
      consoleErrorWithSentry(err, { fbAppName: auth.app.name });
      history.push(`${paths.loginError}?error=${err.message}`);
    },
    [history]
  );

  const signInWithCustomToken = useCallback(async () => {
    const mode = query.get("mode");
    if (mode === "trial") {
      segmentTrack(SegmentEvents.SignUp, null);
    } else if (mode === "flexsave") {
      mixpanel.track("flexsaveSA.signup");
    }

    try {
      const token = getCookie("token");
      if (!token) {
        if (!(await pollForCurrentUser())) {
          return errHandler(new Error("no token and no current user"));
        }
        return; // already logged in - do nothing
      }

      const tenantId = getCookie("tenant_id");
      if (tenantId) {
        auth.tenantId = tenantId;
      } else {
        return errHandler(new Error("no tenant_id cookie"));
      }

      await firebaseSignInWithCustomToken(auth, token);
    } catch (err: any) {
      return errHandler(err);
    }
  }, [query, errHandler]);

  useEffect(() => {
    signInWithCustomToken();
  }, [signInWithCustomToken]);

  return (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
      <Typography variant="h1">Loading... </Typography>
      <CircularProgress disableShrink={true} color="primary" size={24} sx={{ margin: 2 }} />
    </Stack>
  );
};
