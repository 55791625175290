import { useEffect, useState } from "react";

import { type FlexsaveSageMaker } from "@doitintl/cmp-models";

export const shouldShowSelfEnableSageMakerButton = ({
  hasActiveResold,
  sageMakerData,
}: {
  hasActiveResold: boolean | undefined;
  sageMakerData: FlexsaveSageMaker | undefined;
}) =>
  hasActiveResold &&
  sageMakerData?.timeEnabled === null &&
  sageMakerData?.savingsSummary.canBeEnabledBasedOnRecommendations;

export const useDelay = (delay: number) => {
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPassed(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return passed;
};
