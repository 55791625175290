type reportState = {
  firstRender: boolean;
  reportChanged: boolean;
  reportChangedWhileRunning: boolean;
  finishedFirstQueryRun: boolean;
  queryRunning: boolean;
  querySuccess: boolean;
  queryCanceled: boolean;
  reportReady: boolean;
  mounted: boolean;
  isInit: boolean;
};

export const initialReportState: reportState = {
  firstRender: true,
  reportChanged: false,
  reportChangedWhileRunning: false,
  finishedFirstQueryRun: false,
  queryRunning: false,
  querySuccess: false,
  queryCanceled: false,
  reportReady: false,
  mounted: false,
  isInit: false,
};

export enum ReportStateKind {
  REPORT_RESET,
  RUNNING,
  FINISHED_RUNNING,
  SHOW_RUN_REPORT_ALERT,
  HIDE_RUN_REPORT_ALERT,
  REPORT_CHANGED,
}

type ReportStateAction = {
  type?: ReportStateKind;
  payload?: Partial<reportState>;
};

export const reportStateReducer = (state: reportState, action: ReportStateAction): reportState => {
  switch (action.type) {
    case ReportStateKind.REPORT_RESET:
      return {
        ...state,
        queryRunning: false,
        querySuccess: false,
        reportReady: false,
        firstRender: true,
        reportChanged: false,
        reportChangedWhileRunning: false,
      };
    case ReportStateKind.RUNNING:
      return {
        ...state,
        reportChangedWhileRunning: false,
        finishedFirstQueryRun: true,
        queryRunning: true,
        querySuccess: false,
        queryCanceled: false,
      };
    case ReportStateKind.FINISHED_RUNNING:
      return { ...state, ...action.payload, reportChanged: false, reportReady: true };
    case ReportStateKind.SHOW_RUN_REPORT_ALERT:
      return { ...state, reportChanged: true, reportChangedWhileRunning: false };
    case ReportStateKind.HIDE_RUN_REPORT_ALERT:
      return {
        ...state,
        reportChanged: false,
        reportChangedWhileRunning: false,
        querySuccess: true,
      };
    case ReportStateKind.REPORT_CHANGED: {
      if (state.firstRender) {
        // used to ignore changes of the dimensions on the first render
        return { ...state, firstRender: false, querySuccess: false };
      }
      if (state.queryRunning) {
        // if the query is running and the report changed, we need to set the reportChangedWhileRunning flag
        return { ...state, reportChangedWhileRunning: true, querySuccess: false };
      }
      if (state.reportReady) {
        return { ...state, reportChanged: true, reportChangedWhileRunning: false, querySuccess: false };
      }
      return { ...state, querySuccess: false };
    }
    default:
      return { ...state, ...action.payload };
  }
};
