import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Button, Stack } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeData } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: NodeData;
};

const buttons = [
  { label: cloudflowTexts.CUSTOM_SCHDULE, nodeType: CloudFlowNodeType.TRIGGER },
  { label: cloudflowTexts.MANUALLY_START, nodeType: CloudFlowNodeType.MANUAL_TRIGGER },
  { label: cloudflowTexts.EXTERNAL_SYSTEM, nodeType: CloudFlowNodeType.ACTION },
];

export const StartStepConfigurator = ({ id, data }: Props) => (
  <NodeWrapper title={data.name} status={data.status} isActive={false} onClick={noop} disableHover={true}>
    <Stack gap={1} mb={-1} pl={1}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          onClick={() => data.onAddNode(button.nodeType, id, data.position)}
        >
          {button.label}
        </Button>
      ))}
    </Stack>
    <SourceHandle id={id} />
  </NodeWrapper>
);
