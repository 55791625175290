import { type Dispatch, useState } from "react";

import { useParams } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import WarningIcon from "@mui/icons-material/ReportProblem";
import { Alert, CardContent, CardHeader, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/system/Container";
import { Formik } from "formik";

import { ssoTexts } from "../../../../assets/texts";
import { BottomAppBar } from "../../../../Components/BottomAppBar";
import { getInitialValues, SSOValidationSchema } from "../helpers";
import { useConvertLegacySso, useIsLegacySso, useSSOConfiguration } from "../hooks";
import { type AuthAction, type AuthState, type SSOProviders } from "../types";
import SsoDialog from "./SsoDialog";
import SsoProviderForm from "./SsoProviderForm";

type Props = {
  backPageCallback: () => void;
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
};

const SsoConfiguration = ({ backPageCallback, state, dispatch }: Props) => {
  const theme = useTheme();
  const { ssoType } = useParams<{ ssoType: SSOProviders }>();
  const [openConfirmDialog, setOpenConfirmDialog, handleSubmit] = useSSOConfiguration(
    state,
    dispatch,
    backPageCallback
  );
  const isLegacySso = useIsLegacySso(ssoType);
  const convertLegacySso = useConvertLegacySso();
  const [isLegacySsoConversionDialogOpen, setIsLegacySsoConversionDialogOpen] = useState(false);

  return (
    <Container maxWidth="md">
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
          <CardHeader
            titleTypographyProps={{ variant: "h3" }}
            title={`${ssoType.toUpperCase()} configuration`}
            avatar={
              <IconButton aria-label="Back" onClick={backPageCallback} size="large" data-cy="back-button">
                <BackIcon sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            }
          />
          {state.loading && !openConfirmDialog && <CircularProgress disableShrink={true} color="primary" size={24} />}
        </Stack>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={getInitialValues(state, ssoType)}
            validationSchema={SSOValidationSchema[ssoType]}
            onSubmit={handleSubmit}
          >
            {({ values, isValid, dirty, submitForm }) => (
              <>
                <SsoProviderForm
                  ssoType={ssoType}
                  disabled={state.loading || isLegacySso}
                  fieldProps={{ certificate: { multiline: true, rows: 4 } }}
                />
                <SsoDialog
                  loading={state.loading}
                  open={openConfirmDialog}
                  title={ssoTexts.DIALOG_SAVE_CONFIG_TITLE(ssoType)}
                  description={ssoTexts.DIALOG_SAVE_CONFIG_DESCRIPTION(ssoType)}
                  onConfirm={submitForm}
                  onClose={() => setOpenConfirmDialog(false)}
                />

                <SsoDialog
                  loading={state.loading}
                  open={isLegacySsoConversionDialogOpen}
                  title={`IdP ${ssoType.toUpperCase()} configuration`}
                  description={`You are about to change the ${ssoType.toUpperCase()} configuration for your account.\n\nSSO will be automatically updated when you press CONFIRM.`}
                  onConfirm={async () => {
                    await convertLegacySso();
                    setIsLegacySsoConversionDialogOpen(false);
                  }}
                  onClose={() => setIsLegacySsoConversionDialogOpen(false)}
                />

                {state.error && (
                  <Alert
                    iconMapping={{
                      warning: <WarningIcon />,
                    }}
                    severity="warning"
                    color="error"
                    sx={{ mb: 6 }}
                  >
                    {ssoTexts.ERROR_MESSAGE(ssoType)}
                  </Alert>
                )}
                {isLegacySso ? (
                  <BottomAppBar
                    maxWidth="md"
                    primaryButtonName="Mark as fixed"
                    secondaryButtonName="Cancel"
                    handlePrimaryButtonClicked={() => setIsLegacySsoConversionDialogOpen(true)}
                    handleSecondaryButtonClicked={backPageCallback}
                  />
                ) : (
                  <BottomAppBar
                    maxWidth="md"
                    primaryButtonName="Save"
                    secondaryButtonName="Cancel"
                    disabled={!isValid || !dirty || Object.values(values).some((v) => !v)}
                    handlePrimaryButtonClicked={() => setOpenConfirmDialog(true)}
                    handleSecondaryButtonClicked={backPageCallback}
                  />
                )}
              </>
            )}
          </Formik>
        </CardContent>
      </Stack>
    </Container>
  );
};
export default SsoConfiguration;
