import { useMemo } from "react";

import {
  type AmazonWebServicesAssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  type CloudFlowNodeType,
  type Member,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type Asset } from "../../../../types";
import { stripControlledFields } from "../Common/utils";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import { GenericForm } from "./ApiActionParametersForm";
import { useFieldValidationProps } from "./useFieldValidationProps";

const CONTROLLED_FIELDS = ["accountId"];

const AWSParameterForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const { assets } = useCustomerContext();
  const accountIdProps = formikProps.getFieldProps("accountId");
  const { showError, errorMessage } = useFieldValidationProps(formikProps, accountIdProps);
  const { dispatch } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const requiredMembers = inputModel.requiredMembers || [];
  const members = Object.keys(inputModel.members) || [];

  const awsAccountIds = useMemo(
    () =>
      Object.values(assets).flatMap((assets) =>
        assets
          .filter((asset): asset is Asset<AmazonWebServicesAssetModel> =>
            [AssetTypeAmazonWebServices, AssetTypeAwsStandalone].includes(asset.data.type)
          )
          .map((asset) => asset.data.properties.accountId)
      ),
    [assets]
  );

  const uncontrolledInputForm = useMemo(() => {
    const input = stripControlledFields(inputModel, CONTROLLED_FIELDS);
    if (Object.keys(input.members).length === 0) {
      return null;
    }
    return input;
  }, [inputModel]);

  return (
    <Stack gap={2}>
      {members.includes("accountId") && (
        <FormControl>
          <VirtualizedAutocomplete
            id="accountId"
            options={awsAccountIds}
            onChange={(e, value) => {
              dispatch({
                type: "UPDATE_PARAMETERS_AT_KEY",
                payload: {
                  parameterKey: "configurationValues",
                  values: {
                    accountId: value,
                  },
                },
              });
            }}
            renderOption={(props, option) => [
              props,
              <MenuItem key={option} {...props} value={option}>
                {option}
              </MenuItem>,
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                label="Account Id"
                name="Account Id"
                onBlur={formikProps.handleBlur("accountId")}
                required={requiredMembers.includes("accountId")}
                fullWidth
                error={showError}
                helperText={errorMessage}
                variant="outlined"
              />
            )}
            size="small"
            fullWidth
            value={formikProps.values?.accountId ? formikProps.values.accountId : null}
          />
        </FormControl>
      )}
      {uncontrolledInputForm && <GenericForm fieldPath="" inputModel={uncontrolledInputForm} label="" />}
    </Stack>
  );
};

export default AWSParameterForm;
