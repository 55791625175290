import { type Dispatch, useCallback, useMemo, useReducer } from "react";

import { Avatar } from "@mui/material";

import awsLogo from "../../../../assets/aws-logo.svg";
import { ActionStep } from "./ActionStep";
import { ProductStep } from "./ProductStep";
import { defaultState, type NewDialogAction, type NewDialogState, newNodeDialogReducer } from "./reducer";
import { ServiceStep } from "./ServiceStep";

export const useCreateNewNodeDialog = ({ handleCloseDialog }: { handleCloseDialog: () => void }) => {
  const [state, dispatch]: [NewDialogState, Dispatch<NewDialogAction>] = useReducer(newNodeDialogReducer, defaultState);
  const productStepHandler = useCallback((productType: "aws" | "gcp" | "doit") => {
    dispatch({ type: "productStepHandler", payload: { productType, status: "complete" } });
  }, []);
  const serviceStepHandler = useCallback((service) => {
    dispatch({ type: "serviceStepHandler", payload: { service, status: "complete" } });
  }, []);
  const actionStepHandler = useCallback((action) => {
    dispatch({ type: "actionStepHandler", payload: { action, status: "complete" } });
  }, []);

  // TODO [https://doitintl.atlassian.net/browse/CMP-22016]: Fetch data from firestore
  const templates = useMemo(
    () => ({
      Product: (
        <ProductStep
          productCards={[
            {
              title: "Google Cloud",
              text: "Description",
              icon: awsLogo,
            },
            {
              title: "AWS",
              text: "Description",
              icon: awsLogo,
            },
            {
              title: "Doit",
              text: "Description",
              icon: awsLogo,
            },
          ]}
          onClick={productStepHandler}
        />
      ),
      Service: (
        <ServiceStep
          serviceCards={[
            {
              title: "Google Cloud",
              text: "Description",
              icon: <Avatar sx={{ width: 32, height: 32 }} src={awsLogo} />,
            },
          ]}
          onClick={serviceStepHandler}
        />
      ),
      Action: (
        <ActionStep
          actionCards={[
            {
              title: "list",
              text: "list of instances",
            },
            {
              title: "Google Cloud 2",
              text: "Description",
            },
          ]}
          actionStepHandler={actionStepHandler}
          action={state.action}
        />
      ),
    }),
    [actionStepHandler, productStepHandler, serviceStepHandler, state.action]
  );
  const onClose = useCallback(() => {
    dispatch({ type: "close" });
    handleCloseDialog();
  }, [handleCloseDialog]);
  const prevStepHandler = useCallback(() => {
    dispatch({ type: "prevStep" });
  }, []);
  const stateWithTemplates = useMemo(() => {
    state.steps.forEach((step) => {
      step.children = templates[step.label];
    });
    return state;
  }, [state, templates]);

  return {
    stateWithTemplates,
    onClose,
    prevStepHandler,
    productStepHandler,
    serviceStepHandler,
    actionStepHandler,
  };
};
