import { Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { LinkedThreadPaper } from "./LinkedThreadPaper";

export const SkeletonLinkedThread = () => {
  const theme = useTheme();

  return (
    <LinkedThreadPaper color={cmpBaseColors.primaryDark} topBorderColor={theme.palette.primary.main}>
      <Box sx={{ p: 2 }}>
        {/* Summary */}
        <Skeleton animation="pulse" variant="text" width="90%" sx={{ mb: 1, fontSize: 14 }} />

        {/* Description */}
        <Skeleton animation="pulse" variant="text" width="100%" sx={{ fontSize: 12 }} />
        <Skeleton animation="pulse" variant="text" width="100%" sx={{ fontSize: 12 }} />
        <Skeleton animation="pulse" variant="text" width="60%" sx={{ fontSize: 12 }} />
        <Divider sx={{ my: 0.5 }} />

        {/* Status */}
        <Skeleton animation="pulse" variant="text" width="100%" />
        <Divider sx={{ my: 0.5 }} />

        {/* Priority */}
        <Skeleton animation="pulse" variant="text" width="100%" />
        <Divider sx={{ my: 0.5 }} />

        {/* Assigned to */}
        <Skeleton animation="pulse" variant="text" width="100%" sx={{ mb: 1 }} />

        {/* Buttons */}
        <Skeleton animation="pulse" variant="rounded" width="100%" height={35} sx={{ mb: 1 }} />
        <Skeleton animation="pulse" variant="rounded" width="100%" height={35} />
      </Box>
    </LinkedThreadPaper>
  );
};
