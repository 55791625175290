import { type JSX, type ReactNode } from "react";

import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import get from "lodash/get";

import { type NestedObjectLeaves } from "../../../utils/NestedKeyOf";

export type FilterTableBodyProps<T> = {
  rows: readonly T[];
  selectedItemsIds: string[];
  itemUniqIdentifierField?: NestedObjectLeaves<T> | "id";
  handleItemSelect: (itemId: string, checked: boolean) => void;
  RowComponent: (data: { row: T }) => JSX.Element;
  showRowsSelection: boolean;
  children?: ReactNode;
  wrapRowComponent: boolean;
  tableName?: string;
  rowDataCyId?: boolean;
};

export function FilterTableBody<T>({
  rows,
  selectedItemsIds,
  itemUniqIdentifierField,
  handleItemSelect,
  RowComponent,
  showRowsSelection,
  children,
  wrapRowComponent = true,
  tableName,
  rowDataCyId,
}: FilterTableBodyProps<T>) {
  return (
    <TableBody data-testid={`table-body-${tableName}`}>
      {rows.map((row, index) =>
        wrapRowComponent ? (
          <TableRow
            key={index}
            hover
            data-cy={rowDataCyId && (row as any).id ? `row-${(row as any).id}` : `row-${index}`}
          >
            {showRowsSelection && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedItemsIds.includes(get(row, itemUniqIdentifierField as string))}
                  onChange={(_, checked: boolean) =>
                    handleItemSelect(get(row, itemUniqIdentifierField as string), checked)
                  }
                  inputProps={{ "aria-label": "select-row-checkbox" }}
                  // casting to any here as we cant narrow the T generic with an optional disabled property due to weak
                  // type detection
                  disabled={(row as any).disabled}
                />
              </TableCell>
            )}
            <RowComponent row={row} />
          </TableRow>
        ) : (
          <RowComponent key={index} row={row} />
        )
      )}
      {children}
    </TableBody>
  );
}
