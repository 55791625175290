import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Stack, Typography } from "@mui/material";

import mixpanel from "../../../../../utils/mixpanel";
import { IconWithLink } from "../../../Common/IconWithLink";
import { type PreviousComputeSpend, type SavingsEstimations } from "../../../types";
import { formatAsDollars } from "../../../utils/costUtils";
import { ContactSupport } from "../../Common/ContactSupport";
import { CopyLinkWithSnackBar } from "../../Common/CopyLinkWithSnackBar";
import { DownloadEstimateButton } from "../../Common/DownloadEstimateButton";
import { HelpPanel } from "../../Common/HelpPanel";
import { InviteCollegeLink } from "../../Common/InviteCollegeLink";
import { OnboardLayout } from "../../Common/OnboardLayout";
import { SpendBar } from "../../Common/SpendBar";
import { ANNUAL_POSSIBLE_GROWTH } from "../../const";

type Props = {
  savings: SavingsEstimations;
  computeSpend: PreviousComputeSpend;
  handleDone: () => void;
  handleSavingsReportDownload: () => Promise<boolean>;
};

export const ShowEstimations = ({ handleDone, savings, computeSpend, handleSavingsReportDownload }: Props) => (
  <OnboardLayout
    information={
      <HelpPanel text="We are estimating your savings based on your last month’s bill and most commonly used resources and regions among our customers.">
        <IconWithLink
          text="Learn about how Flexsave works"
          icon={<OpenNewIcon />}
          url="https://help.doit.com/docs/flexsave/onboarding/aws"
        />
        <Box onClickCapture={() => mixpanel.track("flexsaveSA.shareestimate", { type: "aws" })}>
          <CopyLinkWithSnackBar textToCopy={window.location.href} label="Share estimation" />
        </Box>
        <ContactSupport />
        <InviteCollegeLink />
      </HelpPanel>
    }
    bottomAppBarProps={{
      primaryButtonName: "Return to home",
      handlePrimaryButtonClicked: handleDone,
    }}
  >
    <Stack spacing={3}>
      <Typography variant="h1" fontWeight="500">
        Your AWS savings estimation
      </Typography>
      <SpendBar spend={computeSpend.lastMonthComputeSpend} text="last month’s compute spend" fillAmount={100} />
      <SpendBar spend={computeSpend.lastMonthWithFlexsave} text="if using Flexsave" fillAmount={70} />
      <Stack spacing={2} pt={2}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" spacing={{ xs: 2, sm: 0 }}>
          <Stack>
            <Typography>Estimated annual savings</Typography>
            <Typography color="text.secondary" variant="body2">
              (based on 0-{ANNUAL_POSSIBLE_GROWTH * 100}% annual growth)
            </Typography>
          </Stack>
          <Typography fontSize="40px" fontWeight="bold">
            {formatAsDollars(savings.annualSavings)} - {formatAsDollars(savings.annualSavingsWithGrowth)}
          </Typography>
        </Stack>
        <Box pt={2}>
          <DownloadEstimateButton downloadOperation={handleSavingsReportDownload} />
        </Box>
      </Stack>
    </Stack>
  </OnboardLayout>
);
