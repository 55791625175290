import { type Dispatch, type ReactNode } from "react";

import { Link } from "react-router-dom";
import { type QuickLinkDescriptorModel } from "@doitintl/cmp-models";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InsightsIcon from "@mui/icons-material/Insights";
import MovingIcon from "@mui/icons-material/Moving";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Card, CardHeader, useTheme } from "@mui/material";

import mixpanel from "../../../utils/mixpanel";
import { useQuickLinkPathnameInterpolationFn } from "./useQuickLinkPathnameInterpolationFn";

const icons: Record<string, ReactNode> = {
  plus: <AddCircleOutlineIcon />,
  ticket: <ForwardToInboxIcon />,
  incidents: <CloudOffIcon />,
  notifications: <NotificationsNoneIcon />,
  insights: <InsightsIcon />,
  person: <PersonAddAltIcon />,
  rampPlan: <MovingIcon />,
  cloud: <CloudQueueIcon />,
  connect: <CompareArrowsIcon />,
};

const GUIDED_EXPERIENCE = "guided-experience";

export type QuickLinkCardProps = {
  quickLink: QuickLinkDescriptorModel;
  setIsGuidedExperienceDialogOpened: Dispatch<boolean>;
};

export const QuickLinkCard = ({
  quickLink: { name, title, icon, target, description },
  setIsGuidedExperienceDialogOpened,
}: QuickLinkCardProps) => {
  const {
    palette: { primary },
  } = useTheme();
  const interpolateQuickLinkPathname = useQuickLinkPathnameInterpolationFn();

  return (
    <Link
      to={interpolateQuickLinkPathname(target)}
      style={{ textDecoration: "none" }}
      onClick={() => {
        mixpanel.track(`quicklinks.${name}`);
        if (name === GUIDED_EXPERIENCE) {
          setIsGuidedExperienceDialogOpened(true);
        }
      }}
    >
      <Card key={name} sx={{ height: "100%", "&:hover": { borderColor: primary.main } }}>
        <CardHeader
          avatar={icons[icon]}
          title={title}
          titleTypographyProps={{ variant: "body1", fontWeight: 500 }}
          subheader={description}
          subheaderTypographyProps={{ variant: "body2" }}
          sx={{
            ".MuiCardHeader-avatar": {
              alignSelf: "flex-start",
              color: primary.main,
            },
          }}
        />
      </Card>
    </Link>
  );
};
