import { useEffect, useState } from "react";

import { type BillingStandaloneOnboardingAWSModel, SaaSConsoleType } from "@doitintl/cmp-models";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Link, Stack, styled, TextField, Typography } from "@mui/material";

import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { trackConnectInfo } from "../Common/mixpanelEvents";
import { UsefulLinks } from "../Common/UsefulLinks";
import { PermissionText, RequiredPermissionsDialog } from "./RequiredPermissionsDialog";

type Props = {
  accountId: string;
  setAccountId: (accountId: string) => void;
  onboarding?: BillingStandaloneOnboardingAWSModel;
  isValidFormat: boolean;
  isValid: boolean;
};

const StyledTextField = styled(TextField)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const PolicyOverviewTextContent = () => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <Stack spacing={4}>
      <Typography variant="h1">Required policy to connect your AWS account</Typography>
      <Stack spacing={2}>
        <Typography variant="body1">On the AWS account you wish to connect:</Typography>
        <PermissionText text="AdministratorAccess" />
        <Link
          onClick={() => setOpenDialog(true)}
          underline="none"
          type="button"
          sx={{ cursor: "pointer", display: "flex", alignItems: "center", pb: 2, pt: 1 }}
        >
          Required permissions <HelpOutlineIcon fontSize="small" sx={{ ml: 0.5 }} />
        </Link>
        <Alert severity="info">We recommend you have the correct policy before starting</Alert>
      </Stack>
      <RequiredPermissionsDialog open={openDialog} handleClose={() => setOpenDialog(false)} />
    </Stack>
  );
};

const AccountIdDetails = ({ accountId, setAccountId, onboarding, isValidFormat, isValid }: Props) => {
  const [wasAccountIdBlurred, setWasAccountIdBlurred] = useState(false);
  const [accountIdState, setAccountIdState] = useState(accountId);
  const [initialAccountIdSet, setInitialAccountIdSet] = useState(false);

  useMountEffect(() => trackConnectInfo(SaaSConsoleType.AWS));

  useEffect(() => {
    if (!accountId && onboarding?.accounts && !initialAccountIdSet) {
      const notCompletedAccounts = Object.values(onboarding.accounts).filter((account) => !account.completed);
      const initialAccountId = notCompletedAccounts.find((account) => account.agreedToContract)?.accountId;

      if (initialAccountId !== undefined) {
        setAccountIdState(initialAccountId);
        setInitialAccountIdSet(true);
      } else if (notCompletedAccounts.length > 0) {
        setAccountIdState(notCompletedAccounts[0].accountId);
        setInitialAccountIdSet(true);
      }
    }
  }, [accountId, onboarding, initialAccountIdSet]);

  useEffect(() => {
    setAccountId(accountIdState);
  }, [accountIdState, setAccountId]);

  return (
    <Stack spacing={4} pb={5}>
      <PolicyOverviewTextContent />
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Provide AWS account ID you want to connect to the DoiT Console
        </Typography>
        <StyledTextField
          data-cy="account-id-input"
          onBlur={() => setWasAccountIdBlurred(true)}
          type="number"
          variant="outlined"
          label="AWS account ID"
          value={accountId}
          onChange={(e) => setAccountId(e.target.value.trim())}
          required
          error={wasAccountIdBlurred && !(isValidFormat && isValid)}
          size="medium"
          helperText={isValid ? "" : "This account ID is your resold account ID. Please connect a different account ID"}
        />
      </Stack>
      <UsefulLinks cloud={SaaSConsoleType.AWS} />
    </Stack>
  );
};

export default AccountIdDetails;
