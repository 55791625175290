import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Alert, Button } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { useIsLegacySso } from "../../../Pages/Settings/Authentication/hooks";
import { type SSOProviders } from "../../../Pages/Settings/Authentication/types";
import { isAdmin } from "../../../utils/permissions";
import { type PageId } from "../../config/pages";

const hideInPages: PageId[] = ["iam:sso:configure"];

export function LegacySsoAlert({ hide, pageId }: Readonly<{ hide: boolean; pageId?: PageId }>) {
  const history = useHistory();
  const { customer } = useCustomerContext({ allowNull: true });
  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const [open, setOpen] = useState<boolean>(false);
  const isLegacySaml = useIsLegacySso("saml");
  const isLegacyOidc = useIsLegacySso("oidc");

  let legacySsoType: SSOProviders | undefined = undefined;
  if (isLegacySaml) {
    legacySsoType = "saml";
  } else if (isLegacyOidc) {
    legacySsoType = "oidc";
  }

  useEffect(() => {
    setOpen(!hide && !!legacySsoType && (isAdmin(userModel?.roles) || isDoitEmployee === true));
  }, [hide, userModel?.roles, isDoitEmployee, legacySsoType]);

  const handlePayClick = useCallback(() => {
    if (!open || !customer?.id) {
      return;
    }
    history.push(`/customers/${customer.id}/iam/authentication/sso/${legacySsoType}`);
  }, [open, history, customer?.id, legacySsoType]);

  const shouldHideInPage = pageId && hideInPages.includes(pageId);

  return (
    <>
      {open && (
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ display: shouldHideInPage ? "none" : "flex", paddingY: 0 }}
        >
          There's an error with your SSO login.
          <Button color="inherit" variant="outlined" sx={{ paddingY: 0, ml: 3 }} onClick={() => handlePayClick()}>
            Fix now
          </Button>
        </Alert>
      )}
    </>
  );
}
