import { type CSSProperties, type JSX, type ReactNode } from "react";

import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "../Analytics/cloudCardStyle";

type Props = {
  children: JSX.Element;
  headerAction?: ReactNode;
  style?: CSSProperties;
  subheader: string;
  title: string;
  widgetHeight: number;
};

export const WidgetCard = ({ title, subheader, headerAction, children, style, widgetHeight }: Props) => (
  <Card>
    <CardHeader
      title={title}
      titleTypographyProps={{ variant: HEADING_VARIANT }}
      subheader={subheader}
      subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
      action={headerAction}
      style={{ padding: HEADER_PADDING, height: CARD_HEADER_HEIGHT }}
    />
    <CardContent style={{ height: 200 * widgetHeight, ...style }}>{children}</CardContent>
  </Card>
);
