import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { type FlexsaveType } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import mixpanel from "../../../../../utils/mixpanel";
import { IconWithLink } from "../../../Common/IconWithLink";
import { ContactSupport } from "../ContactSupport";
import { HelpPanel } from "../HelpPanel";
import { InviteCollegeLink } from "../InviteCollegeLink";
import { OnboardLayout } from "../OnboardLayout";
import { BillingProfileSelector } from "./BillingProfileSelector";

type Props = {
  handleBack: () => void;
  handleDone: () => void;
  savedPriorityId?: string;
  cloudType: FlexsaveType;
  handleUpdate: (priorityId: string) => Promise<void>;
};

export const EditBillingProfile = ({ handleDone, handleBack, savedPriorityId, cloudType, handleUpdate }: Props) => {
  const [selectedPriorityId, setSelectedPriorityId] = useState<string>("");
  const { customer } = useCustomerContext();
  const history = useHistory();

  useEffect(() => {
    if (savedPriorityId) {
      setSelectedPriorityId(savedPriorityId);
    }
  }, [savedPriorityId]);

  const handleDoneWithSelection = () => {
    if (selectedPriorityId) {
      mixpanel.track("flexsaveSA.savebillingprofile", { type: cloudType });
      handleUpdate(selectedPriorityId);
      handleDone();
    }
  };
  return (
    <OnboardLayout
      information={
        <HelpPanel text="After activating Flexsave, you will begin to see savings in billing statements from your cloud provider. DoiT will invoice you for a percentage of the savings generated by Flexsave.">
          <IconWithLink
            text="Review our data compliance information"
            icon={<OpenNewIcon />}
            url="https://www.doit-intl.com/compliance/"
          />
          <ContactSupport />
          <InviteCollegeLink />
        </HelpPanel>
      }
      bottomAppBarProps={{
        primaryButtonName: "Confirm payment information",
        secondaryButtonName: "Back",
        handlePrimaryButtonClicked: handleDoneWithSelection,
        handleSecondaryButtonClicked: handleBack,
      }}
    >
      <Stack>
        <Typography pb={1} variant="h1" fontWeight="500">
          Payment information
        </Typography>
        <Typography pb={4} color="text.secondary">
          Payment information is required in order to receive invoices
        </Typography>
        <BillingProfileSelector selectedPriorityId={selectedPriorityId} handleSelection={setSelectedPriorityId} />
        <Button
          variant="outlined"
          sx={{ mt: 5, alignSelf: "flex-start" }}
          onClick={() =>
            history.push({ pathname: `/customers/${customer.id}/entities`, state: { from: history.location.pathname } })
          }
        >
          Create new billing profile
        </Button>
      </Stack>
    </OnboardLayout>
  );
};
