import { useHistory } from "react-router";
import { Button, Stack } from "@mui/material";

import illustration from "../../../assets/GKE-empty-illustration.svg";
import { lensEnticementTexts } from "../../../assets/texts";
import { TiersType } from "../../../Components/BlankStateUpsell/NoEntitlement";
import { TierAvailability } from "../../../Components/BlankStateUpsell/TierAvailability";
import { useCustomerId } from "../../hooks/useCustomerId";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const EKSLensEnticement = ({ state }: { state: EnticementState }) => {
  const history = useHistory();
  const customerId = useCustomerId();
  const { accountManagers, loading } = useFetchAmTamEmail();

  return (
    <LensEnticement
      illustration={<img src={illustration} alt="EKS Lens empty Illustration" width="auto" height="250" />}
      title={lensEnticementTexts.EKS_LENS_TITLE}
      listItems={lensEnticementTexts.EKS_LENS_LIST}
    >
      {state === "activate" && (
        <Button
          variant="contained"
          sx={{ width: "fit-content" }}
          onClick={() => history.push(`/customers/${customerId}/eks-onboarding`)}
        >
          Connect an EKS cluster
        </Button>
      )}

      {state === "upgrade" && (
        <Stack
          direction="column"
          spacing={4}
          sx={{
            "&&": {
              mt: 2,
            },
          }}
        >
          <TierAvailability tiers={[{ tierType: TiersType.PREMIUM }, { tierType: TiersType.ENHANCED }]} />

          <ContactSalesButton sendTo={accountManagers} loading={loading} />
        </Stack>
      )}
    </LensEnticement>
  );
};
