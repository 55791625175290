import { type HTMLAttributes, useEffect } from "react";

import { useResizeDetector } from "react-resize-detector";
import { Box } from "@mui/material";

interface ResizableElementProps extends HTMLAttributes<HTMLDivElement> {
  width: number | string;
  onResize?: (id: number, w: number | string) => void;
  colId: number;
  sx?: any;
}

export const ResizableCellHeader = (props: ResizableElementProps) => {
  const { ref, width } = useResizeDetector({ skipOnMount: true });
  const { onResize, colId } = props;
  useEffect(() => {
    if (width && onResize) {
      onResize(colId, width);
    }
  }, [width, onResize, colId]);

  useEffect(() => {
    if (props.width && onResize) {
      onResize(colId, props.width);
    }
  }, [onResize, colId, props.width]);

  return (
    <Box
      ref={ref}
      style={{
        resize: "horizontal",
        width: props.width,
      }}
      sx={props.sx}
    >
      {props.title}
    </Box>
  );
};
