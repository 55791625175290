import { useCallback, useEffect, useMemo, useState } from "react";

import { Alert, Card, Grid, MenuItem, TextField } from "@mui/material";
import sortBy from "lodash/sortBy";

import { Loader } from "../../../Components/Loader";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Role, type RoleWithIdRef, type User } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { permissions as permissionsOptions } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { text } from "../consts";
import { getAllCustomerRoles, getPermissionsNamesOfRole, updateRoleInUse } from "../db";
import { RoleChangeConfirmationDialog } from "./RoleChangeConfirmationDialog";

export type UserPermissionsTabProps = {
  user: Pick<User, "permissions">;
  updateUser: (updateData: Partial<User>) => Promise<void>;
  userRole?: RoleWithIdRef;
  readOnlyMode?: boolean;
  showDisabledDueToSsoMsg: boolean;
};

export const UserPermissionsTab = ({
  user,
  updateUser,
  userRole,
  readOnlyMode,
  showDisabledDueToSsoMsg,
}: UserPermissionsTabProps) => {
  const { customer, entities, isProductOnlyCustomer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const [roles, setRoles] = useState<Role[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [openRoleConfirmationDialog, setOpenRoleConfirmationDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [billingProfiles, setBillingProfiles] = useState<{ id: string; name: string }[]>([]);

  const mustPickRole = !readOnlyMode && !userRole;

  const getUserRolePermissions = useCallback(async () => {
    if (userRole) {
      const permissionsNames = await getPermissionsNamesOfRole(userRole.permissions);
      setPermissions(permissionsNames);
    } else {
      setPermissions(
        (user.permissions?.map(
          (permission) => permissionsOptions.find((permissionOption) => permissionOption.value === permission)?.name
        ) as string[]) ?? []
      );
    }
  }, [userRole, user.permissions]);

  const updateRole = async (newRole: Role) => {
    await updateUser({ roles: [newRole.ref], permissions: [] });
    await updateRoleInUse(userRole, newRole);
    mixpanel.track("iam.users.roles.apply", {
      name: newRole?.name,
    });
  };

  useEffect(() => {
    if (!entities) {
      return;
    }

    const e = isDoitEmployee
      ? sortBy(entities, ["active"])
          .reverse()
          .map((entity) => ({
            id: entity.id,
            name: `${entity.priorityId} - ${entity.name} ${entity.active ? "" : "(DISABLED)"}`,
          }))
      : entities
          .filter((entity) => entity.active)
          .map((entity) => ({
            id: entity.id,
            name: `${entity.priorityId} - ${entity.name}`,
          }));
    setBillingProfiles(e);
  }, [entities, isDoitEmployee]);

  useEffect(() => {
    getAllCustomerRoles(customer, isProductOnlyCustomer).then((customerRoles) => {
      setRoles(customerRoles);
    });
  }, [isProductOnlyCustomer, customer]);

  useEffect(() => {
    getUserRolePermissions().catch(consoleErrorWithSentry);
  }, [getUserRolePermissions]);

  const sortedPermissions = useMemo(() => sortBy(permissions), [permissions]);

  return (
    <Loader loading={roles.length === 0}>
      {showDisabledDueToSsoMsg && (
        <Alert style={{ marginBottom: 8 }} severity="info">
          {text.ROLE_UPDATE_DISABLED_DUE_TO_SSO_ENABLED_ALERT}
        </Alert>
      )}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <Card style={{ padding: "15px 25px", height: "100%" }}>
            <div>{text.PERMISSIONS_PANEL_HEADER}</div>
            <TextField
              style={{ marginTop: 20 }}
              select
              variant="outlined"
              fullWidth
              label="Role"
              value={userRole?.name ?? ""}
              onChange={async (e) => {
                const currentSelectedRole = roles.find((role) => role.name === e.target.value);
                if (!currentSelectedRole) {
                  return;
                }
                setSelectedRole(currentSelectedRole);
                setOpenRoleConfirmationDialog(true);
              }}
              margin="dense"
              helperText={mustPickRole && text.MUST_PICK_ROLE_MESSAGE}
              error={mustPickRole}
              disabled={readOnlyMode}
              data-cy="role-select"
            >
              {roles.map((option, index) => (
                <MenuItem key={index} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {(permissions.length > 0 || userRole) && (
              <div>
                <div style={{ fontWeight: "bold", margin: "20px 0" }}>
                  {userRole ? text.ROLE_PERMISSIONS_HEADER : text.LEGACY_PERMISSIONS_HEADER}
                </div>
                <div>
                  {sortedPermissions.map((permission) => (
                    <div key={`permission-${permission}`} style={{ margin: "8px 0" }}>
                      {permission}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card style={{ padding: "15px 25px", height: "100%" }}>
            <div>{text.BILLING_PROFILES_PANEL_HEADER}</div>
            <div style={{ marginTop: 20 }}>
              {billingProfiles.map((billingProfile) => (
                <div key={`billing-profile-${billingProfile.id}`} style={{ margin: "8px 0" }}>
                  {billingProfile.name}
                </div>
              ))}
            </div>
          </Card>
        </Grid>
      </Grid>
      {openRoleConfirmationDialog && selectedRole && (
        <RoleChangeConfirmationDialog
          onSubmit={() => updateRole(selectedRole)}
          onClose={() => setOpenRoleConfirmationDialog(false)}
        />
      )}
    </Loader>
  );
};
