import { useMemo } from "react";

import { Autocomplete, type AutocompleteChangeReason, Chip, TextField } from "@mui/material";
import uniq from "lodash/uniq";
import { string as YupString } from "yup";

import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { NoReplyEmail } from "../../../Pages/CloudAnalytics/budgets/shared";
import { useErrorSnackbar } from "../../SharedSnackbar/SharedSnackbar.context";

type RecipientSelectorProps = {
  disabled?: boolean;
  onChange?: () => void;
  recipients: string[];
  setRecipients: (recipients: string[]) => void;
};

export const RecipientSelector = ({ recipients, setRecipients, disabled, onChange }: RecipientSelectorProps) => {
  const { invites, userEmails } = useAnalyticsUsers();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();

  const errorSnackbar = useErrorSnackbar();
  const options = useMemo(
    () => uniq([...userEmails, ...invites, ...(recipients ?? []).map((c) => c)]),
    [recipients, userEmails, invites]
  );

  const addRecipient = async (email: string): Promise<boolean> => {
    try {
      await YupString().email().validate(email);
    } catch (error) {
      errorSnackbar("Please enter a valid email address");
      return false;
    }
    if (recipients?.find((c) => c === email)) {
      errorSnackbar(`${email} is already in the list.`);
      return false;
    }
    setRecipients([...recipients, email]);
    return true;
  };

  const removeRecipient = (emails: string[]) => {
    const email = recipients.find((c) => !emails.includes(c));
    if (email) {
      setRecipients(recipients.filter((c) => c !== email));
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      options={options}
      freeSolo
      filterSelectedOptions
      value={recipients?.map((r) => r)}
      onBlur={async (event: any) => {
        if (event.target?.value) {
          await addRecipient(event.target.value);
        }
      }}
      clearOnBlur={true}
      onInputChange={async (event: any, value) => {
        const inputLen = value.length - 1;
        if (value[inputLen] === " " || value[inputLen] === ",") {
          if (!addRecipient(value.split(/[,\s]/)[0])) {
            event.target.value = "";
          }
        }
      }}
      onChange={async (_event, value, reason: AutocompleteChangeReason) => {
        switch (reason) {
          case "clear": {
            if (customer.presentationMode?.isPredefined) {
              setRecipients([NoReplyEmail]);
              break;
            }
            setRecipients([currentUser.email]);
            break;
          }
          case "removeOption": {
            removeRecipient(value);
            break;
          }
          case "createOption":
            await addRecipient(value[value.length - 1]);
            break;
          case "selectOption":
            await addRecipient(value[value.length - 1]);
            break;
        }
        onChange?.();
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="medium"
            label={option}
            {...getTagProps({ index })}
            onDelete={
              (currentUser.email === option && !customer.presentationMode?.isPredefined) || disabled
                ? undefined
                : () => setRecipients(recipients.filter((c) => c !== option))
            }
            key={index}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          required
          {...params}
          variant="outlined"
          label="Send alerts to"
          fullWidth
          size="medium"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
