import { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import type { CustomerFeedback } from "@doitintl/cmp-models";

import { useTier } from "../../../Context/TierProvider";
import AnomalyFeedbackDialog from "./AnomalyFeedbackDialog";
import { anomalyFeedbackReasonsLabels } from "./consts";

type Props = {
  customerFeedback: CustomerFeedback;
};

export const AnomalyFeedbackBanner = ({ customerFeedback }: Props) => {
  const { isAnomaly, reason, additionalComments, updatedBy, updatedAt } = customerFeedback;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { isFeatureEntitled } = useTier();

  const bannerHeader = `${isAnomaly ? "Anomalous" : "Not anomalous"}, and acknowledged as '${anomalyFeedbackReasonsLabels[reason]}'`;

  if (!isFeatureEntitled("governance:costAnomalies:acknowledgement")) {
    return null;
  }

  return (
    <>
      <Stack direction="row">
        <Stack bgcolor={isAnomaly ? "success.light" : "info.light"} padding="6px" borderRadius="4px 0 0 4px" />
        <Box
          py={1}
          px={2}
          border="1px solid"
          borderLeft={0}
          borderRadius="0 4px 4px 0"
          borderColor="divider"
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack>
            <Stack direction="row" gap={1} alignItems="baseline">
              <Typography variant="subtitle1" fontWeight={500}>
                {bannerHeader}
              </Typography>
              <Typography color="text.secondary" fontSize={12}>
                by {updatedBy}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary" fontSize={14}>
              {additionalComments}
            </Typography>
            <Typography color="text.secondary" fontSize={12}>
              {updatedAt?.toDate().toLocaleString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
              })}
            </Typography>
          </Stack>

          <Box
            onClick={() => setOpen(true)}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              color: theme.palette.primary.main,
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography fontWeight={500} fontSize={14}>
              Edit
            </Typography>
            <EditIcon fontSize="small" />
          </Box>
        </Box>
      </Stack>

      {open && <AnomalyFeedbackDialog open={open} onClose={() => setOpen(false)} customerFeedback={customerFeedback} />}
    </>
  );
};
