import { useCallback, useEffect, useMemo, useState } from "react";

import { CloudAnalyticsModel, type CloudAnalyticsTemplateLibraryFavoritesModel } from "@doitintl/cmp-models";
import { getCollection, type ModelReference, useDocumentData } from "@doitintl/models-firestore";
import type { DocumentData } from "firebase/firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { type ReportTemplateWithVersion } from "../../../Pages/CloudAnalytics/templateLibrary/types";
import { getUserCachingKeys } from "../../../utils/cachingKeys";
import { arrayRemove, arrayUnion } from "../../../utils/firebase";

export type ReportTemplateFavoriteValues = [string[], (id: string) => void];

type ReturnRefOrUndefined<TVal, T extends DocumentData> =
  TVal extends NonNullable<TVal> ? ModelReference<T> : undefined;

export const useReportTemplateFavorites = (
  reportTemplatesWithVersions: ReportTemplateWithVersion[] | null
): ReportTemplateFavoriteValues => {
  // this can be used from context without user yet (for example the Reports Context Provider)
  // we might not have the user yet
  const { userId } = useAuthContext();
  const [reportTemplateFavorites, setReportTemplateFavorites] = useState<string[]>([]);

  const getTemplateRef = useCallback(
    (id: string) =>
      getCollection(CloudAnalyticsModel).doc("template-library").collection("templateLibraryReportTemplates").doc(id),
    []
  );

  const getFavoriteRef = useCallback(
    <TUserId extends string | undefined>(
      userId: TUserId
    ): ReturnRefOrUndefined<TUserId, CloudAnalyticsTemplateLibraryFavoritesModel> => {
      if (!userId) {
        return undefined as ReturnRefOrUndefined<TUserId, CloudAnalyticsTemplateLibraryFavoritesModel>;
      }
      return getCollection(CloudAnalyticsModel)
        .doc("template-library")
        .collection("templateLibraryFavorites")
        .doc(userId) as ReturnRefOrUndefined<TUserId, CloudAnalyticsTemplateLibraryFavoritesModel>;
    },
    []
  );

  const favoriteRef = useMemo(() => getFavoriteRef(userId), [getFavoriteRef, userId]);

  const [favorites, loadingFavorites] = useDocumentData(favoriteRef, {
    caching: true,
    cachingKeys: getUserCachingKeys(userId),
  });

  const handleFavoriteReportTemplateUpdate = useCallback(
    async (id: string) => {
      if (!userId) {
        return;
      }

      const ref = getTemplateRef(id);
      if (reportTemplateFavorites.includes(id)) {
        await getFavoriteRef(userId).update({
          templates: arrayRemove(ref),
        });
      } else if (reportTemplateFavorites.length) {
        await getFavoriteRef(userId).update({
          templates: arrayUnion(ref),
        });
      } else {
        await getFavoriteRef(userId).set({
          templates: [ref],
        });
      }
    },
    [getFavoriteRef, getTemplateRef, reportTemplateFavorites, userId]
  );

  useEffect(() => {
    if (!loadingFavorites) {
      return setReportTemplateFavorites((favorites?.templates || []).map((ref) => ref.id));
    }
  }, [favorites?.templates, getFavoriteRef, loadingFavorites, userId]);

  const filteredReportTemplateFavorites = useMemo(() => {
    if (!reportTemplatesWithVersions) {
      return [];
    }
    return reportTemplateFavorites.filter(
      (favoriteTemplateId) => !!reportTemplatesWithVersions.find((rt) => rt.template.id === favoriteTemplateId)
    );
  }, [reportTemplateFavorites, reportTemplatesWithVersions]);

  return [filteredReportTemplateFavorites, handleFavoriteReportTemplateUpdate];
};
