import { useMemo } from "react";

import { NODE_STATUS, type NodeStatus } from "@doitintl/cmp-models";
import CheckCircle from "@mui/icons-material/CheckCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";

interface Props {
  title: string;
  status: NodeStatus;
  onDelete?: () => void;
  onEdit?: () => void;
  isActive: boolean;
  onClick: () => void;
  children?: React.ReactNode;
  disableHover?: boolean;
}

const NodeWrapper = ({ children, title, status, onDelete, onEdit, isActive, onClick, disableHover = false }: Props) => {
  const menuOptions: ThreeDotsMenuOption[] = useMemo(() => {
    const options: ThreeDotsMenuOption[] = [];
    if (onEdit) {
      options.push({
        label: <Typography>Edit</Typography>,
        key: "edit",
        action: onEdit,
      });
    }

    if (onDelete) {
      options.push({
        label: <Typography color="error">Delete</Typography>,
        key: "delete",
        action: onDelete,
      });
    }

    return options;
  }, [onEdit, onDelete]);

  const getNodeIcon = () => {
    switch (status) {
      case NODE_STATUS.ERROR:
        return <WarningAmber color="warning" />;
      case NODE_STATUS.VALIDATED:
        return <CheckCircle color="success" />;
      case NODE_STATUS.PENDING:
      default:
        return <SettingsIcon color="primary" />;
    }
  };

  return (
    <Card
      onClick={onClick}
      sx={(theme) => ({
        width: 350,
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
        border: isActive ? `1px solid ${theme.palette.primary.main}` : undefined,
        backgroundColor: isActive ? theme.palette.primary.hoverBackground : undefined,
        ...(disableHover
          ? {}
          : {
              "&:hover": {
                borderColor: theme.palette.primary.main,
              },
            }),
      })}
    >
      <CardHeader
        avatar={getNodeIcon()}
        title={<Typography variant="h4">{title}</Typography>}
        action={menuOptions.length ? <ThreeDotsMenu options={menuOptions} size="medium" closeAfterSelect /> : undefined}
        sx={{
          ".MuiCardHeader-avatar": {
            mr: 1,
          },
        }}
      />
      {!!children && <CardContent sx={{ pt: 0, pb: 1, pl: 1 }}>{children}</CardContent>}
    </Card>
  );
};

export default NodeWrapper;
