import { type CloudFlowNodeType, CloudFlowProvider } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { IconCard } from "../../../Dialog/CreateNewActionNodeDialog/IconCard";
import { ApiActionParametersForm, GenericForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import CloudSpecificParameterForm from "../../ApiActionParametersForm/CloudSpecificParameterForm";
import { inputModelExample, paramsStructExample } from "../../ApiActionParametersForm/const";
import { FormValuesChangeListener } from "../../ApiActionParametersForm/FormValuesChangeListener";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const APIParametersTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  /* const { operationData, loading, error } = useGetOperationById(nodeConfig.parameters.operation);

  const inputModel = useUnwrappedApiActionModel({
    vendor: nodeConfig.parameters.operation.provider,
    service: nodeConfig.parameters.operation.service,
    version: nodeConfig.parameters.operation.version,
    modelId: operationData?.inputModel,
  });*/

  return (
    <Stack p={4} justifyContent="center" gap={2}>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        Service and action
      </Typography>
      <IconCard
        card={{ title: "GCP", text: "Google Cloud Services", icon: "" }}
        clickHandler={() => {}}
        cardState="change"
      />

      <ApiActionParametersForm inputModel={paramsStructExample} values={nodeConfig.parameters.configurationValues}>
        <FormValuesChangeListener formKey="configurationValues" />
        <CloudSpecificParameterForm inputModel={paramsStructExample} provider={CloudFlowProvider.GCP} />
      </ApiActionParametersForm>

      <ApiActionParametersForm inputModel={inputModelExample} values={nodeConfig.parameters.formValues}>
        <FormValuesChangeListener formKey="formValues" />
        <GenericForm inputModel={inputModelExample} fieldPath="" label="" />
      </ApiActionParametersForm>
    </Stack>
  );
};
export default APIParametersTab;
