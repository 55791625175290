import { useMemo, useState } from "react";

import { type CloudOnHoldType, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

import DeleteDialog from "../../../../Components/DeleteDialog";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../../Components/Loader";
import { assetTypeName, sanitizeDate } from "../../../../utils/common";
import { deleteField } from "../../../../utils/firebase";
import { useSnackbar } from "../../../Integrations/Slack/utils";
import { filters, headerColumns } from "./columns";
import OnHoldRow from "./OnHoldRow";
import { texts } from "./texts";
import { type CustomersOnHold, type OnHoldRowData } from "./types";

const OnHoldList = ({
  customersOnHold,
  customersLoading,
}: {
  customersOnHold: CustomersOnHold;
  customersLoading: boolean;
}) => {
  const snackbar = useSnackbar();
  const [deleteRowDialogOpen, setDeleteRowDialogOpen] = useState<OnHoldRowData | null>(null);

  const deleteOnHold = (row: OnHoldRowData) => async () => {
    try {
      const invoicesOnHold = cloneDeep(customersOnHold[row.customerId]?.invoicesOnHold);
      if (invoicesOnHold) {
        delete invoicesOnHold[row.cloud];
        let invoicesOnHoldEmpty = false;
        if (!Object.keys(invoicesOnHold).length) {
          invoicesOnHoldEmpty = true;
        }
        const customerRef = getCollection(CustomerModel).doc(row.customerId);
        customerRef.update({ invoicesOnHold: invoicesOnHoldEmpty ? deleteField() : invoicesOnHold });
      }
      snackbar.onOpen({ message: texts.HOLD_REMOVED_SUCCESSFULLY, variant: "success" });
    } catch (err) {
      snackbar.onOpen({ message: err, variant: "error" });
    }
  };

  const rows = useMemo(() => {
    const rows: OnHoldRowData[] = [];
    Object.entries(customersOnHold).forEach(([customerId, { primaryDomain, invoicesOnHold }]) => {
      Object.entries(invoicesOnHold).forEach(([cloud, { timestamp, note, email }]) => {
        rows.push({
          customerId,
          primaryDomain,
          cloud: cloud as CloudOnHoldType,
          createTime: sanitizeDate(DateTime.fromJSDate(timestamp?.toDate())),
          note,
          email,
        });
      });
    });

    return rows;
  }, [customersOnHold]);

  const onHoldRowWrapper = ({ row }) => <OnHoldRow row={row} onDelete={() => setDeleteRowDialogOpen(row)} />;

  return (
    <Box>
      <Loader loading={customersLoading}>
        <FilterTable<OnHoldRowData>
          showRowsSelection={false}
          tableItems={rows}
          headerColumns={headerColumns}
          rowComponent={onHoldRowWrapper}
          filterColumns={filters}
        />
      </Loader>

      {!!deleteRowDialogOpen && (
        <DeleteDialog
          open
          title="Remove hold"
          message={`Remove hold from ${deleteRowDialogOpen.primaryDomain} for ${assetTypeName(
            deleteRowDialogOpen.cloud
          )}.`}
          onDelete={deleteOnHold(deleteRowDialogOpen)}
          onClose={() => setDeleteRowDialogOpen(null)}
        />
      )}
    </Box>
  );
};

export default OnHoldList;
