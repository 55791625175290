import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";

import EntityAttachDialog from "../../Components/EntityAttachDialog";
import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import mixpanel from "../../utils/mixpanel";

export const ConfigControls = () => {
  const { isDoitPartner, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext();
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const isDoitDeveloper = useDoitRoleCheck(DoitRole.Developers);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogs, setDialogs] = useState({
    entityAttachDialog: false,
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (dialog: string) => () => {
    setAnchorEl(null);
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleCloseDialog = (dialog: string) => () => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  return (
    <Box mt={0.9}>
      {!isDoitPartner && (
        <Grid container spacing={1} style={{ justifyContent: "flex-end" }} alignItems="center">
          <Grid item>
            <IconButton
              aria-owns={anchorEl ? "settings-menu" : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              style={{ marginRight: 2.5 }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="settings-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem
                key="entities"
                disabled={isProductOnlyCustomer || (!userRoles?.billingProfilesAdmin && !isDoitEmployee)}
                component={RouterLink}
                onClick={() => mixpanel.track("dashboard.customer_settings.link", { page: "new_billing_profile" })}
                to={`/customers/${customer.id}/entities`}
              >
                New billing profile
              </MenuItem>
              <MenuItem
                key="customer-settings"
                component={RouterLink}
                to={`/customers/${customer.id}/settings/general`}
                onClick={() => mixpanel.track("dashboard.customer_settings.link", { page: "customer_settings" })}
              >
                Customer settings
              </MenuItem>
              {isDoitDeveloper && (
                <MenuItem key="attach-entity" onClick={handleOpenDialog("entityAttachDialog")} data-cy="attach-entity">
                  Attach billing profile
                </MenuItem>
              )}
            </Menu>
          </Grid>
        </Grid>
      )}
      {isDoitEmployee && dialogs.entityAttachDialog && (
        <EntityAttachDialog key="entity-attach-dialog" onClose={handleCloseDialog("entityAttachDialog")} />
      )}
    </Box>
  );
};
