import { useCallback, useEffect } from "react";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import find from "lodash/find";
import Autocomplete from "@mui/material/Autocomplete";
import { AssetTypeAmazonWebServices, AssetTypeAwsStandalone } from "@doitintl/cmp-models";
import { validate } from "../../utils/common";
import CategorySuggest from "./CategorySuggest";

const sortProjectProps = (platforms, projects) => {
  const arr = [];

  platforms.forEach((platform) => {
    if (platform.details.asset) {
      const filtered = projects.filter((p) => p.asset.replace("-standalone", "") === platform.details.asset);
      const uniqField = [AssetTypeAmazonWebServices, AssetTypeAwsStandalone].includes(platform.details.asset)
        ? "accountId"
        : "identifier";

      const seen = new Map();
      filtered.forEach((item) => {
        const key = item[uniqField];
        if (!seen.has(key)) {
          seen.set(key, item);
          arr.push(item);
        }
      });
    }
  });

  return arr;
};

const renderTextbox = (props) => {
  const { classes } = props;
  return (
    <TextField
      variant="outlined"
      label="Resource"
      size="medium"
      helperText={props.helperText || " "}
      onChange={props.changeCategoryInfo}
      value={typeof props.categoryInfo !== "object" ? props.categoryInfo || "" : ""}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        className: classes.textFieldInput,
      }}
      fullWidth
      error={props.error ? (props.category ? validate(props.category.categoryInfo) : true) : false}
      required
    />
  );
};
const styles = () => ({
  textField: {
    marginTop: 30,
  },
});
const SelectCategory = (props) => {
  const { handleChangeCategory, changeCategoryInfo } = props;
  const isPlatformSelected = props.selectedPlatform && props.selectedPlatform.details.asset;
  let categoryList = sortProjectProps(props.platforms, props.categoryInfoList);
  let categoryInfo = "";

  if (isPlatformSelected) {
    categoryList = categoryList.filter(
      (cat) => cat.asset.replace("-standalone", "") === props.selectedPlatform.details.asset
    );
    categoryInfo = categoryList.find((c) => (c.accountId || c.identifier) === props.categoryInfo)?.identifier ?? "";
  } else {
    categoryList = [];
  }

  useEffect(() => {
    if (props.selectedPlatform?.resourceDisabled && props.categoryInfo !== "") {
      changeCategoryInfo("");
    }
  }, [changeCategoryInfo, props.categoryInfo, props.selectedPlatform]);

  const categoryListOptions = useCallback(
    () =>
      categoryList?.filter((category) => {
        const regex = /doitintl-fs/g;
        return category?.identifier?.match(regex) ? false : category;
      }),
    [categoryList]
  );

  return (
    <div>
      {props.step !== 2 && (
        <CategorySuggest
          categories={props.categories}
          categoryTitle={props.category?.name}
          onChange={handleChangeCategory}
          error={props.error ? (props.category ? validate(props.category.title) : true) : false}
        />
      )}
      {!props.selectedPlatform?.resourceDisabled && props.step === 2 && (
        <>
          {categoryList.length > 0
            ? isPlatformSelected && (
                <>
                  <Autocomplete
                    id="combo-box-demo"
                    options={categoryListOptions()}
                    getOptionLabel={(option) => option.label || option.identifier || ""}
                    fullWidth
                    size="medium"
                    sx={{ mt: 2, mb: 2 }}
                    onChange={(event, newValue) => {
                      props.changeCategoryInfo(newValue);
                    }}
                    value={find(categoryList, { identifier: categoryInfo }) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="medium"
                        helperText={props.helperText}
                        label={props.selectedPlatform?.label}
                        required
                        variant="outlined"
                        error={
                          props.error ? (props.category ? validate(props.category.categoryInfo) : true) : undefined
                        }
                      />
                    )}
                  />
                </>
              )
            : renderTextbox(props)}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(SelectCategory);
