import {
  type ChangeEvent,
  forwardRef,
  type MouseEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useRefinementList } from "react-instantsearch";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Chip, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip } from "@mui/material";

import { useFilter } from "../FilterContext";
import { useStyles } from "../utils/styles";
import {
  calculateVisibleItems,
  formatLabel,
  getPlaceholderText,
  getPlainTextLabel,
  sortItemsByCount,
} from "./CustomRefinementListUtils";
import { initialRefinementState, type RefinementState } from "./RefinementListState";

type AttributeOptions = "timezone" | "all_skills" | "languages";

type CustomRefinementListProps = {
  attribute: AttributeOptions;
};

type CustomRefinementListRef = {
  resetFilter: () => void;
};

export const CustomRefinementList = forwardRef<CustomRefinementListRef, CustomRefinementListProps>(
  ({ attribute }, ref) => {
    const { items, refine } = useRefinementList({ attribute, operator: "or", limit: 100000 });
    const classes = useStyles();
    const { setIsFilterActive } = useFilter();

    const [state, setState] = useState<RefinementState>(initialRefinementState);

    const isDropdownOpen = Boolean(state.anchorEl);
    const visibleChipLimit = 2;

    const formatLabelWithAttribute = useCallback(
      (value: string, showEmojis: boolean) => formatLabel(value, showEmojis, attribute),
      [attribute]
    );

    const getPlainTextLabelWithAttribute = useCallback(
      (value: string): string => getPlainTextLabel(value, attribute),
      [attribute]
    );

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({ ...prevState, searchTerm: event.target.value }));
    }, []);

    const handleClearSearch = useCallback(() => {
      setState((prevState) => ({
        ...prevState,
        searchTerm: "",
        showDropdown: false,
      }));
    }, []);

    useEffect(() => {
      const activeFilters = items.filter((item) => item.isRefined);
      setIsFilterActive(activeFilters.length > 0);
    }, [items, setIsFilterActive]);

    useEffect(() => {
      const result = calculateVisibleItems(items, state.showEmojis, formatLabelWithAttribute);
      const limitedVisibleItems = result.visibleItems.slice(0, visibleChipLimit);
      const newOverflowItems = [...result.visibleItems.slice(visibleChipLimit), ...result.overflowItems];

      setState((prevState) => ({
        ...prevState,
        visibleItems: limitedVisibleItems,
        overflowItems: newOverflowItems,
        showEmojis: result.showEmojis,
        selectedOverflowCount: result.selectedOverflowCount + (result.visibleItems.length - limitedVisibleItems.length),
      }));
    }, [items, state.showEmojis, formatLabelWithAttribute]);

    useEffect(() => {
      if (state.searchTerm) {
        const lowerCaseSearchTerm = state.searchTerm.toLowerCase();
        const matchedItems = items.filter((item) =>
          getPlainTextLabelWithAttribute(item.value).toLowerCase().includes(lowerCaseSearchTerm)
        );
        setState((prevState) => ({
          ...prevState,
          filteredItems: sortItemsByCount(matchedItems),
          showDropdown: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          filteredItems: [],
          showDropdown: false,
        }));
      }
    }, [state.searchTerm, items, getPlainTextLabelWithAttribute]);

    const handleChange = useCallback(
      (value: string) => {
        refine(value);
      },
      [refine]
    );

    const handleDropdownClick = useCallback((event: MouseEvent<HTMLElement>) => {
      setState((prevState) => ({ ...prevState, anchorEl: event.currentTarget }));
    }, []);

    const handleDropdownClose = useCallback(() => {
      setState((prevState) => ({ ...prevState, anchorEl: null }));
    }, []);

    const resetFilter = useCallback(() => {
      const refinedItems = items.filter((item) => item.isRefined);

      if (refinedItems.length === 0) {
        return;
      }

      items.forEach((item) => {
        if (item.isRefined) {
          refine(item.value);
        }
      });

      setState({
        ...initialRefinementState,
        visibleItems: [],
        overflowItems: [],
        filteredItems: [],
      });

      handleClearSearch();

      setIsFilterActive(false);
    }, [items, refine, handleClearSearch, setIsFilterActive]);

    useImperativeHandle(ref, () => ({
      resetFilter,
    }));

    return (
      <Box className={classes.refinementRootBox}>
        <Box className={classes.refinementSecondLevelBox}>
          <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden", flexGrow: 1 }}>
            <TextField
              placeholder={getPlaceholderText(attribute)}
              value={state.searchTerm}
              onChange={handleSearch}
              variant="outlined"
              size="small"
              className={classes.refinementTextField}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: "action.active" }} />,
                endAdornment: state.searchTerm && (
                  <IconButton size="small" onClick={handleClearSearch}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            />
            {state.visibleItems.map((item) => (
              <Tooltip key={item.value} title={`Count: ${item.count}`} arrow>
                <Chip
                  label={formatLabel(item.value, state.showEmojis, attribute)}
                  onClick={() => {
                    handleChange(item.value);
                  }}
                  onDelete={() => {
                    handleChange(item.value);
                  }}
                  deleteIcon={<CloseIcon />}
                  color="primary"
                  variant="filled"
                  className={classes.visibleItemChip}
                />
              </Tooltip>
            ))}
          </Box>

          <Box sx={{ position: "relative" }}>
            <Tooltip
              title={`${state.selectedOverflowCount} selected item${state.selectedOverflowCount !== 1 ? "s" : ""} in dropdown`}
            >
              <IconButton
                onClick={handleDropdownClick}
                size="small"
                className={classes.dropdownIconButton}
                sx={{
                  border: state.selectedOverflowCount > 0 ? 2 : 0,
                  borderColor: "primary.main",
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
            {state.selectedOverflowCount > 0 && (
              <Box className={classes.selectedOverflowCountBox}>{state.selectedOverflowCount}</Box>
            )}
          </Box>
        </Box>

        <Menu
          anchorEl={state.anchorEl}
          open={isDropdownOpen}
          onClose={handleDropdownClose}
          PaperProps={{
            style: {
              maxHeight: "30vh",
              width: "250px",
              overflowY: "scroll",
            },
          }}
        >
          {state.overflowItems.map((item) => (
            <Tooltip key={item.value} title={`Count: ${item.count}`} arrow placement="right">
              <MenuItem
                onClick={() => {
                  handleChange(item.value);
                }}
                selected={item.isRefined}
                className={classes.refinementMenuItem}
              >
                {item.isRefined && (
                  <ListItemIcon>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                )}
                <ListItemText primary={formatLabel(item.value, state.showEmojis, attribute)} />
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>

        {state.showDropdown && state.filteredItems.length > 0 && (
          <Box sx={{ width: "100%", border: 1, borderColor: "grey.400", padding: 1, borderRadius: 1, mt: 2 }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {state.filteredItems.map((item) => (
                <Tooltip key={item.value} title={`Count: ${item.count}`} arrow>
                  <Chip
                    label={formatLabel(item.value, state.showEmojis, attribute)}
                    onClick={() => {
                      handleChange(item.value);
                    }}
                    onDelete={
                      item.isRefined
                        ? () => {
                            handleChange(item.value);
                          }
                        : undefined
                    }
                    deleteIcon={item.isRefined ? <CloseIcon /> : undefined}
                    color={item.isRefined ? "primary" : "default"}
                    variant={item.isRefined ? "filled" : "outlined"}
                    sx={{
                      fontWeight: item.isRefined ? "bold" : "normal",
                      fontSize: "12px",
                      "&:hover": {
                        backgroundColor: item.isRefined ? "primary.dark" : "action.hover",
                      },
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

CustomRefinementList.displayName = "Search";
