import { Stack, Typography } from "@mui/material";

import { IconCard } from "./IconCard";

export const ProductStep = ({
  productCards,
  onClick,
}: {
  productCards: any[];
  onClick: (title: "aws" | "gcp" | "doit") => void;
}) => (
  <Stack gap={1} sx={{ mt: 1 }}>
    <Typography variant="subtitle2">Available products:</Typography>
    <>
      {productCards.map((card) => (
        <IconCard key={card.title} card={card} clickHandler={() => onClick(card.title)} cardState="select" />
      ))}
    </>
  </Stack>
);
