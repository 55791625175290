import { createContext, type ReactNode, useCallback, useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { v4 as uuid } from "uuid";

import { type EditBillingProfileData } from "../BillingProfileForm.models";

function noop<T>(): T {
  throw new Error("Not initialized yet!");
}

export type EditBillingProfileContextAPI = () => [string, string];

export const EditBillingProfileContext = createContext<EditBillingProfileContextAPI>(noop);

const CONTEXT_ID_PARAM_NAME = "edit_billing_profile_context_id";

export const EditBillingProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const [contextId] = useState(`edit-billing-profile-context-${uuid()}`);
  const { values, setValues } = useFormikContext<EditBillingProfileData>();

  useEffect(() => {
    const currentURL = new URL(location.href);
    const savedContextId = currentURL.searchParams.get(CONTEXT_ID_PARAM_NAME);
    if (savedContextId !== null) {
      const savedValues = sessionStorage.getItem(savedContextId);
      if (savedValues !== null) {
        setValues(JSON.parse(savedValues));

        sessionStorage.removeItem(savedContextId);
      }
    }
  }, [setValues]);

  const saveContextAndGetURLParamValue = useCallback((): [string, string] => {
    sessionStorage.setItem(contextId, JSON.stringify(values));
    return [CONTEXT_ID_PARAM_NAME, contextId];
  }, [contextId, values]);

  useEffect(() => () => sessionStorage.removeItem(contextId), [contextId]);

  return (
    <EditBillingProfileContext.Provider value={saveContextAndGetURLParamValue}>
      {children}
    </EditBillingProfileContext.Provider>
  );
};
