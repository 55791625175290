import { useCallback, useEffect, useMemo, useState } from "react";

import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import orderBy from "lodash/orderBy";

import { type KnownIssue } from "../types";
import { rowsPerPageOptions } from "./consts";
import { KnownIssuesTableContent, type KnownIssuesTableContentProps } from "./KnownIssuesTableContent";
import { KnownIssuesTableHeader, type KnownIssuesTableHeaderProps } from "./KnownIssuesTableHeader";

type Props = {
  knownIssues: KnownIssue[];
  selectedKnownIssuesIds: KnownIssuesTableContentProps["selectedKnownIssuesIds"];
  onAllKnownIssuesSelect: KnownIssuesTableHeaderProps["onAllKnownIssuesSelect"];
  onKnownIssueSelect: KnownIssuesTableContentProps["onKnownIssueSelect"];
  isAllowedToModify?: boolean;
};

export const KnownIssuesTable = ({
  knownIssues,
  selectedKnownIssuesIds,
  onAllKnownIssuesSelect,
  onKnownIssueSelect,
  isAllowedToModify,
}: Props) => {
  const [orderByField, setOrderByField] = useState<keyof KnownIssue>("dateTime");
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("desc");
  const [page, setPage] = useState(0);
  const [knownIssuesPerPage, setKnownIssuesPerPage] = useState(10);
  const [currentContentLength, setCurrentContentLength] = useState(0);

  const isSomeKnownIssuesSelected = selectedKnownIssuesIds.length > 0;

  const isAllKnownIssuesSelected = isSomeKnownIssuesSelected && knownIssues.length === selectedKnownIssuesIds.length;

  const sortedKnownIssues = useMemo(
    () => orderBy(knownIssues, [orderByField], [orderDirection]),
    [knownIssues, orderByField, orderDirection]
  );

  const knownIssuesForSinglePage = useMemo(
    () => sortedKnownIssues.slice(page * knownIssuesPerPage, (page + 1) * knownIssuesPerPage),
    [page, knownIssuesPerPage, sortedKnownIssues]
  );

  const handleChangePage = (_, newPageValue: number) => setPage(newPageValue);
  const handleChangeKnownIssuesPerPage = (e) => setKnownIssuesPerPage(e.target.value);

  const selectKnownIssueOrder = useCallback(
    (selectedOrderField: keyof KnownIssue) => {
      if (orderByField === selectedOrderField) {
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
      } else {
        setOrderByField(selectedOrderField);
        setOrderDirection("desc");
      }
    },
    [orderByField, orderDirection]
  );

  useEffect(() => {
    setPage(0);
    setCurrentContentLength(sortedKnownIssues.length);
  }, [sortedKnownIssues]);

  return (
    <>
      <Table aria-labelledby="table-title" size="small" data-cy={`knownissuestable-${knownIssuesForSinglePage.length}`}>
        <KnownIssuesTableHeader
          onAllKnownIssuesSelect={onAllKnownIssuesSelect}
          orderBy={orderByField}
          orderDirection={orderDirection}
          onColumnSelect={selectKnownIssueOrder}
          isSomeKnownIssuesSelected={isSomeKnownIssuesSelected}
          isAllKnownIssuesSelected={isAllKnownIssuesSelected}
          isAllowedToModify={isAllowedToModify}
        />
        <KnownIssuesTableContent
          knownIssues={knownIssuesForSinglePage}
          selectedKnownIssuesIds={selectedKnownIssuesIds}
          onKnownIssueSelect={onKnownIssueSelect}
          isAllowedToModify={isAllowedToModify}
        />
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        count={currentContentLength}
        rowsPerPage={knownIssuesPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeKnownIssuesPerPage}
      />
    </>
  );
};
