import { useCallback, useEffect, useMemo, useState } from "react";

import { Redirect, useParams } from "react-router-dom";
import { type InviteModel, type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { Guard } from "../../Components/Guard";
import { Loader } from "../../Components/Loader";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import {
  type SideNavigationData,
  SideNavigationLayout,
} from "../../Navigation/Components/ThirdLevelNav/SideNavigationLayout";
import { type Invite, type RoleWithIdRef, type User } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { text } from "./consts";
import { observeInviteData, observeUserData, updateFirebaseDisplayName, updateUserField } from "./db";
import { UserTab } from "./types";
import { UserViewTabs } from "./UserViewTabs";

type UserViewProps = {
  isProfile?: boolean;
  userTab?: UserTab;
  canManageUsers?: boolean;
  onImpersonateUser?: (userId: string) => Promise<void>;
  onInviteAccepted?: (email: string) => void;
};

export function useUpdateUserOrInvite({ user, invite }: { user?: User; invite?: Invite }) {
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const updateUser = useCallback(
    async (updateData: Partial<User | Invite>, onError?: () => void, onSuccess?: () => void | Promise<void>) => {
      const userOrInviteId = user?.id ?? invite?.id;
      if (!userOrInviteId) {
        return;
      }

      const promises: Promise<void>[] = [];
      try {
        promises.push(
          updateUserField(userOrInviteId, {
            isInvite: Boolean(invite?.id),
            updateData,
          })
        );

        if (updateData.displayName && user?.email && user.customer?.ref.id) {
          promises.push(updateFirebaseDisplayName(user.email, updateData.displayName, api, user.customer.ref.id));
        }

        await Promise.all(promises);
        await onSuccess?.();
      } catch (e) {
        errorSnackbar(text.PERMISSIONS_ERROR);
        onError?.();
        consoleErrorWithSentry(e);
      }
    },
    [user?.id, user?.email, user?.customer?.ref.id, invite?.id, api, errorSnackbar]
  );

  return { updateUser };
}

export const UserView = ({
  isProfile,
  userTab = UserTab.Profile,
  canManageUsers,
  onImpersonateUser,
  onInviteAccepted,
}: UserViewProps) => {
  const { userId: userIdFromAuth } = useAuthContext();
  const { userId, customerId, tab } = useParams<{ userId: string; customerId: string; tab: string }>();
  const [user, setUser] = useState<User>();
  const [invite, setInvite] = useState<Invite>();
  const [userRef, setUserRef] = useState<ModelReference<UserModel> | ModelReference<InviteModel>>();
  const [userRole, setUserRole] = useState<RoleWithIdRef>();
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingInvite, setLoadingInvite] = useState(true);
  const { updateUser } = useUpdateUserOrInvite({ user, invite });

  const baseUrl = useMemo(
    () =>
      isProfile
        ? `/customers/${customerId}/profile/${userId ?? userIdFromAuth}`
        : `/customers/${customerId}/iam/users/${userId}`,
    [isProfile, customerId, userId, userIdFromAuth]
  );

  const userOrInvite = useMemo(() => {
    if (user) {
      return { ...user, invite: false } as const;
    }
    if (invite) {
      return { ...invite, invite: true } as const;
    }
  }, [user, invite]);

  useEffect(
    () =>
      observeUserData(
        userId,
        (userSnapshot) => {
          const userData = userSnapshot.asModelData();

          if (userData) {
            setUser({ ...userData, id: userSnapshot.id, ref: userSnapshot.modelRef });
            setUserRef(userSnapshot.modelRef);
          }
          setLoadingUser(false);
        },
        () => {
          setLoadingUser(false);
        }
      ),
    [userId]
  );

  useEffect(
    () =>
      observeInviteData(
        userId,
        async (inviteSnapshot) => {
          const userData = inviteSnapshot.asModelData();

          // user has accepted the invite we redirect to it user page
          if (invite?.email && !userData) {
            setLoadingInvite(true);
            try {
              onInviteAccepted?.(invite.email);
            } finally {
              setLoadingInvite(false);
            }
          } else if (userData) {
            setInvite({
              ...userData,
              id: inviteSnapshot.id,
              ref: inviteSnapshot.modelRef,
            });
            setUserRef(inviteSnapshot.modelRef);
          }
          setLoadingInvite(false);
        },
        () => setLoadingInvite(false)
      ),
    [userId, customerId, invite?.email, onInviteAccepted]
  );

  useEffect(() => {
    if (userOrInvite?.roles?.length) {
      userOrInvite.roles[0]
        .get()
        .then((roleRef) => {
          const data: any = roleRef.data();

          if (!data) {
            return;
          }

          setUserRole({
            ...data,
            ref: roleRef.ref,
            id: roleRef.id,
          });
        })
        .catch(consoleErrorWithSentry);
    }
  }, [userOrInvite?.roles]);

  const sideTabs = (): SideNavigationData => {
    const items = [
      {
        value: UserTab.Profile,
        label: text.PROFILE_TAB_LABEL,
        to: UserTab.Profile,
      },
      {
        value: UserTab.Permissions,
        label: text.PERMISSIONS_TAB_LABEL,
        to: UserTab.Permissions,
      },
      {
        value: UserTab.Api,
        label: text.API_TAB_LABEL,
        to: UserTab.Api,
      },
      {
        value: UserTab.Notifications,
        label: text.NOTIFICATIONS_TAB_LABEL,
        to: UserTab.Notifications,
      },
    ];
    return {
      value: tab,
      items,
    };
  };

  return (
    <Loader loading={loadingUser || loadingInvite}>
      {!tab && <Redirect to={`${baseUrl}/${userTab}`} />}
      <Guard isAllowed={!!tab && userOrInvite}>
        {userOrInvite && (
          <SideNavigationLayout data={sideTabs()} title="Users" backButtonRoute="..">
            <div style={{ marginTop: 8 }}>
              <UserViewTabs
                userRef={userRef}
                baseUrl={baseUrl}
                userOrInvite={userOrInvite}
                userRole={userRole}
                updateUser={updateUser}
                canManageUsers={canManageUsers}
                onImpersonateUser={onImpersonateUser ? () => onImpersonateUser(userId) : undefined}
              />
            </div>
          </SideNavigationLayout>
        )}
      </Guard>
    </Loader>
  );
};

export const UserViewFromProfile = () => {
  const { isDoitEmployee } = useAuthContext();
  const { userRoles } = useUserContext();

  return <UserView isProfile canManageUsers={isDoitEmployee || userRoles?.usersManager} />;
};
