import { TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

import { BudgetTypes, type TimeRangeOption } from "../utilities";

const getRecurringTimeUnitAmount = (timeInterval: TimeInterval) => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return 90;
    case TimeInterval.WEEK:
      return 52;
    case TimeInterval.MONTH:
      return 12;
    case TimeInterval.QUARTER:
      return 8;
    case TimeInterval.YEAR:
      return 2;
    default:
      return 1;
  }
};

export const getTimeRangeOption = (
  budgetType: BudgetTypes,
  timeInterval: TimeInterval,
  startPeriod: DateTime,
  endPeriod: DateTime
): TimeRangeOption => {
  const amount = budgetType === BudgetTypes.RECURRING ? getRecurringTimeUnitAmount(timeInterval) : undefined;

  const range =
    budgetType === BudgetTypes.FIXED
      ? {
          start: startPeriod,
          end: endPeriod,
        }
      : undefined;

  const mode = budgetType === BudgetTypes.FIXED ? TimeSettingsMode.Fixed : TimeSettingsMode.Last;

  const includeCurrent = budgetType === BudgetTypes.RECURRING;

  return {
    mode,
    time: timeInterval,
    amount,
    range,
    includeCurrent,
  };
};

export const getFixedTimeInterval = (startPeriod: DateTime, endPeriod: DateTime): TimeInterval => {
  const diff = endPeriod.diff(startPeriod, "months").months;
  if (diff >= 12) {
    return TimeInterval.YEAR;
  } else if (diff >= 6) {
    return TimeInterval.QUARTER;
  } else if (diff >= 2) {
    return TimeInterval.MONTH;
  } else if (diff >= 1) {
    return TimeInterval.WEEK;
  }
  return TimeInterval.DAY;
};

type budgetState = {
  shouldRefreshData: boolean;
  shouldRefreshPreview: boolean;
  refreshPreview: boolean;
};

type budgetStateAction = {
  type:
    | "shouldRefreshData"
    | "startedRefreshPreview"
    | "finishedRefreshPreview"
    | "shouldRefreshPreview"
    | "startedRefreshData";
  payload?: boolean;
};

export const budgetStateReducer = (state: budgetState, action: budgetStateAction): budgetState => {
  switch (action.type) {
    case "shouldRefreshPreview":
      return { ...state, shouldRefreshPreview: true };
    case "startedRefreshPreview":
      return { ...state, refreshPreview: true, shouldRefreshPreview: false };
    case "finishedRefreshPreview":
      return { ...state, refreshPreview: action.payload ?? false };
    case "shouldRefreshData":
      return { ...state, shouldRefreshData: true };
    case "startedRefreshData":
      return { ...state, shouldRefreshData: false };
  }
};
