import { type JSX, useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { CardHeader, Link as MUILink, Tooltip, Typography, useTheme } from "@mui/material";

import { jsUcfirst } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT } from "./cloudCardStyle";

type Props = {
  action?: JSX.Element;
  name: string;
  reportPath: string;
  hasAccessToReport: boolean;
  description: string;
  hasFilter: boolean;
};

export const CardReportHeader = ({ action, hasAccessToReport, name, reportPath, description, hasFilter }: Props) => {
  const theme = useTheme();

  const [showTooltip, setShowTooltip] = useState(null);

  const redirectToReport = useCallback(() => {
    if (name === "[GKE Lens] Unallocated Resources by Cluster") {
      mixpanel.track("dashboards.gke-lens.cost-allocation-clusters");
    }
  }, [name]);

  const title = hasAccessToReport ? (
    <MUILink
      variant={hasFilter ? "h4" : HEADING_VARIANT}
      component={Link}
      to={reportPath}
      underline="hover"
      color="inherit"
    >
      {name}
    </MUILink>
  ) : (
    <Typography variant={HEADING_VARIANT}>{name}</Typography>
  );

  const handlePopoverOpen = useCallback((event) => {
    if (event.currentTarget.offsetWidth < event.currentTarget.scrollWidth) {
      setShowTooltip(event.currentTarget);
    }
  }, []);

  const handlePopoverClose = useCallback(() => {
    setShowTooltip(null);
  }, []);

  return (
    <CardHeader
      sx={{
        p: HEADER_PADDING,
        height: CARD_HEADER_HEIGHT,
        borderBottomColor: theme.palette.general.divider,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        "&:hover": {
          background: theme.palette.action.hover,
          "& a": {
            textDecoration: "underline",
          },
        },
        ".MuiCardHeader-content": {
          maxWidth: hasFilter ? "calc(100% - 320px)" : "calc(100% - 40px)",
          cursor: "pointer",
        },
      }}
      onClick={redirectToReport}
      title={title}
      subheader={
        <>
          {!showTooltip && jsUcfirst(description)}
          {Boolean(showTooltip) && Boolean(description) && (
            <Tooltip title={jsUcfirst(description)} placement="bottom">
              <span>{jsUcfirst(description)}</span>
            </Tooltip>
          )}
        </>
      }
      subheaderTypographyProps={{
        variant: hasFilter ? "body2" : "caption",
        noWrap: true,
        gutterBottom: true,
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      }}
      action={action}
    />
  );
};
