import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type Collaborators, type PublicAccess, Roles, type SlackChannel } from "@doitintl/cmp-models";
import { Box, Stack, TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { type Budget } from "../../../types";
import { dateFormatDayMonthYear } from "../../../utils/dateTimeFormats";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { type LabelWithRef } from "../labels/types";
import { type BudgetRowActions } from "../utilities";
import BudgetUtilizationProgressBar from "./BudgetUtilizationProgressBar";
import { useBudgetThreeDotsMenu } from "./hooks";

const { budgets: budgetIds } = cloudAnalytics;

type BudgetRowProps = {
  actions: BudgetRowActions;
  data: BudgetRowData;
  tierLimitReached: boolean;
};

export type BudgetRowData = {
  name: string;
  timeModified: FirestoreTimestamp;
  amount: string;
  period?: string;
  currentPercentage: number;
  budgetProjectedDate: string;
  isRecipient: boolean;
  progressBarColor: { bar: any; root: string };
  snapshotId: string;
  collaborators: Collaborators;
  public: PublicAccess;
  recipients: string[];
  recipientsSlackChannels: SlackChannel[];
  row: Budget;
  labels: LabelWithRef[] | undefined;
};

export const BudgetRow = ({ actions, data, tierLimitReached }: BudgetRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const { setOpenDeleteDialog, setSelected, setShareDialogOpen } = actions;
  const rowLabels = useMemo(
    () => data.labels?.filter((l) => data.row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [data.labels, data.row.data.labels]
  );

  const formattedTimeModified = useMemo(() => {
    const date = DateTime.fromJSDate(data?.timeModified?.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
    return "";
  }, [data.timeModified]);

  const threeDotsMenu = useBudgetThreeDotsMenu({
    row: { ...data.row, ref: data.row.ref, id: data.row.ref.id },
    handleShare: () => {
      setSelected([data.row]);
      setShareDialogOpen(true);
    },
    handleDelete: () => {
      setSelected([data.row]);
      setOpenDeleteDialog(true);
    },
    tierLimitReached,
  });

  const formatedMaxUtilization = useMemo(() => {
    if (data.budgetProjectedDate !== "N/A") {
      return DateTime.fromFormat(data.budgetProjectedDate, "LLL dd, yyyy").toFormat(dateFormatDayMonthYear);
    }
    return "N/A";
  }, [data.budgetProjectedDate]);
  return (
    <>
      <TableCell data-cy={budgetIds.browser.budgetName}>
        <Stack spacing={1}>
          <Typography
            component={RouterLink}
            to={`${routeMatchURL}/${data.snapshotId}`}
            color="inherit"
            variant="body2"
            mb={1}
          >
            {data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={budgetIds.browser.budgetOwner}>
          {data.collaborators.find((c) => c.role === Roles.OWNER)?.email}
        </TableCell>
        <TableCell data-cy={budgetIds.browser.budgetLastModified}>{formattedTimeModified}</TableCell>
        <TableCell data-cy={budgetIds.browser.budgetAmount}>{data.amount}</TableCell>
        <TableCell data-cy={budgetIds.browser.budgetPeriod}>{data.period}</TableCell>
      </Hide>
      <TableCell data-cy={budgetIds.browser.budgetPercent}>
        <BudgetUtilizationProgressBar value={data.currentPercentage} color={data.progressBarColor} />
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={budgetIds.browser.budgetMaxUtilization}>{formatedMaxUtilization}</TableCell>
      </Hide>
      <TableCell data-cy={budgetIds.browser.budgetOptions}>
        <Box m={-1.5}>{threeDotsMenu}</Box>
      </TableCell>
    </>
  );
};
