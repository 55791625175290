import { Autocomplete, Checkbox, FormHelperText, Stack, TextField, Typography } from "@mui/material";

import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { SettingsAdminItemWrapper } from "../SettingsAdminItemWrapper";
import { getFeatureFlagOptionDescription, getFeatureFlagOptionDisplayName, text } from "../text";
import { earlyAccessFeaturesOptions, type SettingsValuesType } from "../types";

export function EarlyAccessFeatures({
  value,
  onChange,
  errMsg,
}: {
  value: SettingsValuesType["earlyAccessFeatures"];
  onChange: (newVal: SettingsValuesType["earlyAccessFeatures"]) => void;
  errMsg: string | undefined;
}) {
  return (
    <SettingsAdminItemWrapper disabledForTrial={true}>
      {(isDisabled) => (
        <>
          <Autocomplete
            multiple
            value={value}
            size="small"
            options={earlyAccessFeaturesOptions}
            disabled={isDisabled}
            disableCloseOnSelect
            getOptionLabel={(option) => getFeatureFlagOptionDisplayName(option)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Stack direction="row">
                  <Checkbox sx={{ alignSelf: "start", mt: -0.6 }} size="small" checked={selected} />
                  <Stack>
                    <Typography variant="subtitle1">{getFeatureFlagOptionDisplayName(option)}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {getFeatureFlagOptionDescription(option)}
                    </Typography>
                  </Stack>
                </Stack>
              </li>
            )}
            onChange={(_, newValue) =>
              onChange((newValue || []) as unknown as SettingsValuesType["earlyAccessFeatures"])
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label={text.EARLY_ACCESS_FEATURES_LABEL}
                inputProps={{
                  ...params.inputProps,
                  "data-cy": DATA_CY_GENERAL_SETTINGS_SELECTORS.EARLY_ACCESS_FEATURES,
                }}
              />
            )}
          />
          {!!errMsg && <FormHelperText sx={{ display: "flex", color: "error.main" }}>{errMsg}</FormHelperText>}
        </>
      )}
    </SettingsAdminItemWrapper>
  );
}
