import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import { IconButton, Typography } from "@mui/material";

type Props = {
  handleAskQuestion: () => void;
  processingQuestion: boolean;
  question: string;
};

export const AskButton = ({ handleAskQuestion, processingQuestion, question }: Props) => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    if (processingQuestion) {
      const interval = setInterval(() => {
        setDotCount((prevDotCount) => (prevDotCount % 3) + 1);
      }, 500); // Change dots every 500ms
      return () => clearInterval(interval);
    } else {
      setDotCount(1);
    }
  }, [processingQuestion]);

  const generateDots = () => ".".repeat(dotCount);

  return (
    <IconButton
      onClick={handleAskQuestion}
      disabled={!question.trim() || processingQuestion}
      sx={{
        justifyContent: processingQuestion ? "start" : "center",
      }}
      size="medium"
      color="primary"
    >
      {processingQuestion ? (
        <Typography variant="body2">{`Generating${generateDots()}`}</Typography>
      ) : (
        <SendIcon fontSize="small" />
      )}
    </IconButton>
  );
};
