// import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  //   Link,
  Stack,
  Typography,
} from "@mui/material";

import { TiersType } from "../../../Components/BlankStateUpsell/NoEntitlement";
import { TierAvailability } from "../../../Components/BlankStateUpsell/TierAvailability";
import useFetchAmTamEmail from "../../../Components/NoEntitlement/hooks";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";

type SetOpen = ({ open }: { open: boolean }) => void;

type Props = {
  state: { open: boolean };
  setOpen: SetOpen;
};

export function TierDialog({ state, setOpen }: Props) {
  const { accountManagers, loading } = useFetchAmTamEmail();
  const handleClose = () => {
    setOpen({ open: false });
  };

  return (
    <Dialog open={state.open} onClose={handleClose}>
      <DialogTitle>Get pods label granularity EKS spend</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            To get pods label granularity EKS spend, you need to install our agent to your cluster.
          </Typography>

          <TierAvailability tiers={[{ tierType: TiersType.PREMIUM }, { tierType: TiersType.ENHANCED }]} />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <ContactSalesButton sendTo={accountManagers} loading={loading} />
      </DialogActions>
    </Dialog>
  );
}
