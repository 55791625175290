import { UserPermissions } from "@doitintl/cmp-models";
import { Box, Paper } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";
import { useUserContext } from "../../../Context/UserContext";
import { Threads } from "../../Threads/Threads";
import { type Insight } from "../types";
import { ContactSupport } from "./ContactSupport";

export const GetStarted = (props: { insight: Insight; newSupportTicketUrl: string; mixpanelInsight: string }) => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext();

  const hasThreadsManagerPermission = isDoitEmployee || userRoles.permissions.has(UserPermissions.ThreadsManager);

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 3 }}>
        {hasThreadsManagerPermission ? <Threads insight={props.insight} /> : null}

        <ContactSupport newSupportTicketUrl={props.newSupportTicketUrl} mixpanelInsight={props.mixpanelInsight} />
      </Box>
    </Paper>
  );
};
