import TextField from "@mui/material/TextField";

type NameFieldProps = {
  name: string;
  showNameError: boolean;
  nameErrorMessage?: string;
  onPlanNameChange: (name: string) => void;
};
export const NewPlanNameField = (props: NameFieldProps) => {
  const { name, showNameError, nameErrorMessage, onPlanNameChange } = props;

  return (
    <TextField
      variant="outlined"
      id="rampName"
      name="planName"
      value={name}
      label="Ramp plan name"
      required
      margin="normal"
      onChange={(e) => onPlanNameChange(e.target.value)}
      helperText={showNameError && nameErrorMessage}
      error={showNameError}
      data-cy="dialog-ramp-name"
    />
  );
};

export default NewPlanNameField;
