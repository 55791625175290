import { type CSSProperties } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { alpha, Avatar, Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { common } from "@mui/material/colors";

import doitLogo from "../../../assets/doit-logo-hero-square.svg";
import mixpanel from "../../../utils/mixpanel";

type Props = {
  title: string;
  subheader: string;
  description: string;
  learnMoreLink: string;
};

const flexColumn: CSSProperties = { display: "flex", flexDirection: "column", flex: 1 };

export const PackageCard = ({ title, subheader, description, learnMoreLink }: Props) => (
  <Card sx={flexColumn}>
    <CardHeader
      action={<Avatar src={doitLogo} sx={{ width: 48, height: 48 }} />}
      title={title}
      titleTypographyProps={{
        color: common.white,
        variant: "subtitle1",
        fontWeight: 500,
      }}
      subheader={subheader}
      subheaderTypographyProps={{
        color: alpha(common.white, 0.7),
        variant: "body2",
      }}
      sx={{
        backgroundColor: "#05082C",
        px: 3,
      }}
    />
    <CardContent sx={{ ...flexColumn, px: 3 }}>
      <Typography variant="subtitle1" color="text.secondary" sx={{ flex: 1 }}>
        {description}
      </Typography>
      <Link
        href={learnMoreLink}
        target="_blank"
        sx={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          pt: 3,
          columnGap: 0.5,
        }}
        onClick={() => {
          mixpanel.track(`home.packages.${title}.click`, { $referrer: location.href });
        }}
      >
        <Typography color="primary" variant="body2">
          Learn more
        </Typography>
        <OpenInNewIcon color="primary" fontSize="inherit" />
      </Link>
    </CardContent>
  </Card>
);
