import { useMemo } from "react";

import { type RouteConfig } from "react-router-config";
import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";

import SearchIcon from "../../../assets//algolia/static-links-search.svg";
import SearchIconDark from "../../../assets/algolia/search-darkmode.svg";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Customer } from "../../../types";
import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { useAllowedRoutesMemoized, usePermissionsMemoized } from "../hooks";
import { type AlgoliaCustomContext } from "../types";
import { filterByPermission, getAlgoliaAnalyticsProps, isSearchForCustomer } from "../utils";

type StaticLink = Hit<{
  objectID: string;
  name: string;
  url: string;
  permissions: string[];
}>;

type PluginProps = {
  customer: Customer | null | undefined;
  permissions: Set<string> | undefined;
  allowedRoutes: RouteConfig[] | undefined;
};

const createStaticLinksPlugin = (props: PluginProps): AutocompletePlugin<StaticLink, undefined> => ({
  getSources({ query, state }) {
    const { customer, permissions, allowedRoutes } = props;
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const customerId = context.customerFromQuery?.id ?? customer?.id;
    const customerName = context.customerFromQuery?.name ?? customer?.name;

    const staticLinksSource: AutocompleteSource<StaticLink> = {
      sourceId: "routes",
      templates: {
        item({ item }) {
          const titleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["name"],
          });

          return (
            <AlgoliaResultListItem
              highlightedTitle={titleParts}
              avatarLogo={context.isDarkMode ? SearchIconDark : SearchIcon}
            />
          );
        },
      },
      onSelect({ item, event }) {
        let url = item.url;
        if (customerId) {
          url = url.replace(":customerId", customerId);
        }
        const title = context.isDoitEmployee && customerName ? `${item.name} (${customerName})` : item.name;
        context.onSelectRow({
          id: item.objectID,
          label: item.objectID,
          avatarLogo: context.isDarkMode ? SearchIconDark : SearchIcon,
          highlightedTitle: [{ value: title, isHighlighted: false }],
          highlightedSubtitle: [],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              ...getAlgoliaAnalyticsProps(context),
              indexName: "routes",
              query,
              params: {
                hitsPerPage: 10,
              },
              filters: filterByPermission(context, permissions),
            },
          ],
          transformResponse({ hits }: { hits: Hit<StaticLink>[][] }) {
            if (context.customerFromQuery) {
              // disable filtration of customer links
              return hits;
            }
            return hits.map((indexResults) =>
              indexResults.filter((hit) => allowedRoutes?.some((route) => route.path === hit.url))
            );
          },
        });
      },
      getItemUrl({ item }) {
        if (customerId) {
          return item.url.replace(":customerId", customerId);
        }
      },
    };
    return [staticLinksSource];
  },
});

export const useStaticLinksPlugin = (): AutocompletePlugin<StaticLink, undefined> => {
  const { customer } = useCustomerContext({ allowNull: true });
  const permissions = usePermissionsMemoized();
  const allowedRoutes = useAllowedRoutesMemoized();

  return useMemo(
    () =>
      createStaticLinksPlugin({
        customer,
        permissions,
        allowedRoutes,
      }),
    [allowedRoutes, customer, permissions]
  );
};
