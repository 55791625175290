import { budgetText, globalText } from "../../../assets/texts";
import { type HeaderColumn } from "../../../types/FilterTable";

export const filterColumns = [
  {
    label: budgetText.TABLE_HEADERS.ID,
    path: "snapshot.id",
    requireExactMatch: true,
  },
  {
    label: budgetText.TABLE_HEADERS.NAME,
    path: "data.name",
  },
  {
    path: "data.description",
    label: budgetText.TABLE_HEADERS.DESCRIPTION,
    comparators: ["contains"],
  },
  {
    label: budgetText.TABLE_HEADERS.OWNER,
    path: "data.owner",
  },
  {
    label: budgetText.TABLE_HEADERS.AMOUNT,
    path: "data.config.amount",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    label: budgetText.TABLE_HEADERS.TIME_INTERVAL,
    path: "data.config.timeInterval",
  },
] as const;

export const headers: HeaderColumn[] = [
  {
    value: "data.name",
    label: budgetText.TABLE_HEADERS.NAME,
    tooltip: budgetText.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.owner",
    label: budgetText.TABLE_HEADERS.OWNER,
    tooltip: budgetText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.timeModified",
    label: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.config.amount",
    label: budgetText.TABLE_HEADERS.AMOUNT,
    tooltip: budgetText.TABLE_HEADERS.AMOUNT,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.config.timeInterval",
    label: budgetText.TABLE_HEADERS.PERIOD,
    tooltip: budgetText.TABLE_HEADERS.PERIOD,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.currentPercentage",
    label: budgetText.TABLE_HEADERS.CURRENT_PCT,
    tooltip: budgetText.TABLE_HEADERS.CURRENT_PCT,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.utilization.forecastedTotalAmountDate",
    label: budgetText.TABLE_HEADERS.MAX_UTIL,
    tooltip: budgetText.TABLE_HEADERS.MAX_UTIL,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
];
