import { useMemo } from "react";

import { Redirect, useParams } from "react-router-dom";

import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { useHasCustomerMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { Budget } from "./Budget";
import { CreateBudgetWrapper } from "./CreateBudgetWrapper";

export const BudgetWrapper = ({ pageId }: { pageId: "analytics:budgets:create" | "analytics:budgets:details" }) => {
  const { budgetId } = useParams<{ budgetId: string }>();

  const [hasMetadata] = useHasCustomerMetadata();
  const [_allBudgets, budgets, budgetsLoading] = useBudgets();
  return useMemo(() => {
    if (pageId === "analytics:budgets:create") {
      return <CreateBudgetWrapper />;
    }

    if (hasMetadata === undefined || budgetsLoading) {
      return null;
    }

    const budget = budgets.find((b) => b.snapshot.id === budgetId);

    if (!budget || !hasMetadata) {
      return <Redirect to="." />;
    }

    return <Budget budget={budget} isNewBudget={false} />;
  }, [pageId, hasMetadata, budgetsLoading, budgets, budgetId]);
};
