import { Box, Grid, Link, TextField } from "@mui/material";

import { attributionText, globalText } from "../../../../assets/texts";
import { AttributionSelectMulti } from "../../../../Components/Selects/CloudAnalytics/AttributionSelectMulti";
import { type AttributionWRef } from "../../../../types";
import AttributionCreateDialog from "../../attributions/attributionBuilder/AttributionCreateDialog";

type ScopeStepProps = {
  alertName: string;
  attributions: AttributionWRef[];
  isCurrentUserEditor: boolean;
  newAttributionDialogOpen: boolean;
  scope: AttributionWRef[];
  setAlertEdited: (alertEdited: boolean) => void;
  setAlertName: (alertName: string) => void;
  setNewAttributionDialogOpen: (newAttributionDialogOpen: boolean) => void;
  setNewAttributionId: (newAttributionId: string) => void;
  setScope: (scope: AttributionWRef[]) => void;
};

export const ScopeStep = ({
  alertName,
  attributions,
  isCurrentUserEditor,
  newAttributionDialogOpen,
  scope,
  setAlertEdited,
  setAlertName,
  setNewAttributionDialogOpen,
  setNewAttributionId,
  setScope,
}: ScopeStepProps) => (
  <Box key="stepScope">
    <Grid
      container
      key="grid-key"
      sx={{
        mt: "2.5rem",
      }}
    >
      <Grid item xs={12} sx={{ mb: "2.5rem" }}>
        <TextField
          required
          fullWidth
          name="name"
          label={globalText.NAME}
          value={alertName}
          onChange={(event) => {
            setAlertEdited(true);
            setAlertName(event.target.value);
          }}
          variant="outlined"
          margin="dense"
          type="text"
          disabled={!isCurrentUserEditor}
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <AttributionSelectMulti
          disabled={!isCurrentUserEditor}
          scope={scope}
          attributions={attributions}
          onChange={(scope) => {
            setScope(scope);
            setAlertEdited(true);
          }}
          textFieldProps={{
            size: "medium",
          }}
        />
        <AttributionCreateDialog
          onCancel={() => setNewAttributionDialogOpen(false)}
          onSuccess={(name) => {
            setNewAttributionId(name);
            setAlertEdited(true);
          }}
          open={newAttributionDialogOpen}
        >
          {isCurrentUserEditor && (
            <Link
              color="inherit"
              variant="caption"
              underline="always"
              href="#"
              onClick={() => setNewAttributionDialogOpen(true)}
            >
              {attributionText.CREATE_NEW_ATTRIBUTION}
            </Link>
          )}
        </AttributionCreateDialog>
      </Grid>
    </Grid>
  </Box>
);
