export enum GKEConfigStatus {
  IDLE = "idle",
  WARNING = "warning",
  FAIL = "fail",
  OK = "ok",
}

export enum GKEConfigSubStepStatus {
  IDLE = "idle",
  OK = "ok",
  FAIL = "fail",
}

export enum CategoryStatus {
  NotSetUp,
  Healthy,
  Unhealthy,
  Critical,
  Partial,
}

export enum GKEStepNames {
  Permissions,
  Kubernetes_Engine_API,
  GKE_Usage_Metering,
  BigQuery_Data_Role,
}

export enum GKEFailSteps {
  GKEOnboardStepSuccess,
  GKEOnboardStepPermissions,
  GKEOnboardStepKubernetesAPI,
  GKEOnboardStepClusters,
  GKEOnboardStepDatasets,
  GKEOnboardStepCritical,
}
