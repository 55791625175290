import { createElement, useMemo } from "react";

import { makeStyles } from "@mui/styles";
import DOMPurify, { type SanitizeElementHookEvent } from "dompurify";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

export function SafeHtml({
  html,
  transform,
}: {
  html?: string;
  transform?: (node: Element, data: SanitizeElementHookEvent) => void;
}) {
  const classes = useStyles();

  const purified = useMemo(() => {
    if (!html) {
      return null;
    }

    const purifier = DOMPurify(window);

    if (transform) {
      purifier.addHook("uponSanitizeElement", transform);
    }

    purifier.addHook("uponSanitizeElement", (node) => {
      if (node.tagName?.toLowerCase() === "a") {
        node.classList.add(classes.link);
      }
    });

    return purifier.sanitize(html, { ADD_ATTR: ["target"] });
  }, [classes.link, html, transform]);

  if (!purified) {
    return null;
  }

  return /* #__PURE__*/ createElement("div", {
    dangerouslySetInnerHTML: {
      __html: purified,
    },
  });
}
