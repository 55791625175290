import Dagre from "@dagrejs/dagre";
import { type Edge, type Node } from "@xyflow/react";

// TODO: apply the positioning when add or remove nodes
export const applyGraphLayout = (
  nodes: Node[],
  edges: Edge[]
): { positionedNodes: Node[]; positionedEdges: Edge[] } => {
  const nodeDefaultWidth = 350;
  const nodeDefaultHeight = 100;

  const dagreGraph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: "TB", ranksep: 50, nodesep: 50 });

  edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));

  nodes.forEach((node) =>
    dagreGraph.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? nodeDefaultWidth,
      height: node.measured?.height ?? nodeDefaultHeight,
    })
  );

  Dagre.layout(dagreGraph);

  const positionedNodes = nodes.map((node) => {
    const nodePosition = dagreGraph.node(node.id);
    const nodeX = nodePosition.x - (node.measured?.width ?? nodeDefaultWidth) / 2;
    const nodeY = nodePosition.y - (node.measured?.height ?? nodeDefaultHeight) / 2;

    return { ...node, position: { x: nodeX, y: nodeY } };
  });

  return {
    positionedNodes,
    positionedEdges: edges,
  };
};
