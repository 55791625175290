import { useMemo, useState } from "react";

import {
  DiscreteColorLegend,
  FlexibleXYPlot,
  Highlight,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import { Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { formatNumber } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { type ChartGraphData } from "./types";

const titles = ["Account Receivables", "Expected"];

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flex: 1,
  },
  bar: {
    marginRight: 50,
    pointerEvents: (props: { isDragging: boolean }) => (props.isDragging ? "none" : "auto"),
  },
  hintDiv: {
    background: "rgba(0,0,0,0.8)",
    padding: 5,
    borderRadius: 7,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 7,
    marginRight: 15,
  },
  card: {
    height: 260,
  },
  verticalColor: {
    float: "right",
    marginTop: "-250px",
    fontFamily: "Roboto",
    textAlign: "center",
  },
}));

type Props = { data: ChartGraphData };

type Value = { title: string; series: string; y: number; x: string; y0?: number };

const CashGraph = ({ data }: Props) => {
  const theme = useTheme();
  const { isMobile } = useFullScreen();
  const [value, setValue] = useState<Value | null>();
  const [isDragging, setIsDragging] = useState(false);
  const [selection, setSelection] = useState<{ start: string; end: string }>({ start: "", end: "" });
  const classes = useStyles({ isDragging });

  const color = theme.palette.mode === theme.palette.text.primary;

  const setTooltip = (series: string) => (value: Value) => {
    if (value.y0) {
      value.y -= value.y0;
    }
    const dt = DateTime.fromFormat(value.x, "yyyy-LL-dd");
    value.series = series;
    value.title = dt.toFormat("dd LLL yyyy");
    setValue(value);
  };

  const xTickFormat = (value: string, index: number, _scale: number, tickTotal: number) => {
    if (selection.start && selection.end) {
      if (selection.start === value || value === selection.end) {
        const dt = DateTime.fromFormat(value, "yyyy-LL-dd");
        return dt.toFormat("dd LLL");
      }
    } else {
      if (index % tickTotal === 0) {
        const dt = DateTime.fromFormat(value, "yyyy-LL-dd");
        return dt.toFormat("dd LLL");
      }
    }
    return "";
  };

  const sum = useMemo(() => {
    if (selection.start && selection.end) {
      const receivables = data.receivables.filter((d) => selection.start <= d.x && d.x <= selection.end);
      const expected = data.expected.filter((d) => selection.start <= d.x && d.x <= selection.end);
      return [receivables.reduce((memo, d) => memo + d.y, 0), expected.reduce((memo, d) => memo + d.y, 0)];
    }
  }, [data, selection]);

  return (
    <Card className={classes.card}>
      <FlexibleXYPlot xType="ordinal" stackBy="y" margin={{ left: 50, right: 10, top: 80, bottom: 30 }}>
        <HorizontalGridLines
          style={{
            stroke: color,
            fill: "none",
          }}
        />
        <XAxis
          tickFormat={xTickFormat}
          style={{
            fontFamily: "Roboto",
            fontSize: 10,
            line: { stroke: color },
            text: { stroke: "none", fill: theme.palette.text.secondary },
          }}
          tickTotal={isMobile ? 30 : 10}
          position="start"
        />
        <YAxis
          tickFormat={(v) => `$${formatNumber(v)}`}
          style={{
            fontFamily: "Roboto",
            fontSize: 10,
            line: { stroke: color },
            text: { stroke: "none", fill: theme.palette.text.secondary },
          }}
        />
        <Highlight
          color="#829AE3"
          drag
          enableY={false}
          onBrushStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(area) => {
            setSelection({
              start: area?.left,
              end: area?.right,
            });
            setIsDragging(false);
          }}
        />
        <VerticalBarSeries
          className={classes.bar}
          color={theme.palette.primary.main}
          data={data.receivables}
          onValueMouseOver={setTooltip(titles[0])}
          onValueMouseOut={() => {
            setValue(null);
          }}
        />
        <VerticalBarSeries
          className={classes.bar}
          color={theme.palette.secondary.main}
          data={data.expected}
          onValueMouseOver={setTooltip(titles[1])}
          onValueMouseOut={() => {
            setValue(null);
          }}
        />
        <DiscreteColorLegend
          className={classes.verticalColor}
          orientation="horizontal"
          items={[
            {
              title: sum ? `$${formatNumber(sum[0])}` : titles[0],
              color: theme.palette.primary.main,
              strokeWidth: 10,
            },
            {
              title: sum ? `$${formatNumber(sum[1])}` : titles[1],
              color: theme.palette.secondary.main,
              strokeWidth: 10,
            },
          ]}
        />

        {value && (
          <Hint value={value}>
            <div className={classes.hintDiv}>
              <Typography color="inherit" variant="caption" noWrap display="block">
                {value.title}
              </Typography>
              <Typography color="inherit" variant="caption" noWrap display="block">
                {value.series}
              </Typography>
              <Typography style={{ color: "white", fontWeight: "bold" }} variant="caption">
                {formatNumber(value.y)}
              </Typography>
            </div>
          </Hint>
        )}
      </FlexibleXYPlot>
    </Card>
  );
};

export default CashGraph;
