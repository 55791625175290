import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Warning";
import { Stack, SvgIcon, Typography } from "@mui/material";

type DonationQualifierProps = {
  qualifies: boolean;
};

const DonationQualifier = ({ qualifies }: DonationQualifierProps) => {
  const checkbox = (
    <SvgIcon color="success" fontSize="small" sx={{ mr: "0.5ch" }}>
      <CheckCircleIcon />
    </SvgIcon>
  );
  const nope = (
    <SvgIcon fontSize="small" sx={{ mr: "0.5ch" }}>
      <CancelIcon color="warning" />
    </SvgIcon>
  );
  const icon = qualifies ? checkbox : nope;

  return (
    <Stack>
      <Stack direction="row">
        {icon}
        <Typography>
          {qualifies
            ? "Your feedback qualifies for a donation. Thank you!"
            : "Leave more feedback to qualify for donation"}
        </Typography>
      </Stack>
      <Typography variant="body2" color="textSecondary">
        Next month we'll update you on the amount of money we have donated thanks to your feedback.
      </Typography>
    </Stack>
  );
};

export default DonationQualifier;
