import { UserModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type User } from "firebase/auth";

import { increment, serverTimestamp } from "./firebase";

export const incrementSessionCount = async (userId: string, user: User) => {
  if (user.providerId === "email" && !user.emailVerified) {
    return;
  }

  const ref = getCollection(UserModel).doc(userId);

  try {
    await ref.update({ sessionCount: increment(1), lastLogin: serverTimestamp() });
  } catch (error) {
    // do nothing
  }
};
