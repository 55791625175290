import { useMemo } from "react";

import { CloudAnalyticsModel, Roles } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import firebase from "firebase/compat/app";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AnalyticsAlertWithRef } from "../../../../Pages/CloudAnalytics/alerts";
import { getOrganizationRef } from "../../../../Pages/CloudAnalytics/utilities";
import { alertsTransform } from "./useAlerts";

export const useAlert = (alertId: string): [AnalyticsAlertWithRef | null, boolean] => {
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer, userOrganization } = useCustomerContext();
  const publicQuery = useMemo(() => {
    let query = getCollection(CloudAnalyticsModel)
      .doc("alerts")
      .collection("cloudAnalyticsAlerts")
      .where("customer", "==", customer.ref)
      .where("public", "in", [Roles.VIEWER, Roles.EDITOR])
      .where(firebase.firestore.FieldPath.documentId(), "==", alertId);
    if (!isDoitEmployee) {
      const orgRef = getOrganizationRef(false, userOrganization, customer.id);
      query = query.where("organization", "==", orgRef);
    }
    return query;
  }, [alertId, customer.id, customer.ref, isDoitEmployee, userOrganization]);

  const sharedQuery = useMemo(() => {
    let query = getCollection(CloudAnalyticsModel)
      .doc("alerts")
      .collection("cloudAnalyticsAlerts")
      .where("customer", "==", customer.ref)
      .where("public", "==", null)
      .where(firebase.firestore.FieldPath.documentId(), "==", alertId);
    if (!isDoitEmployee) {
      const orgRef = getOrganizationRef(false, userOrganization, customer.id);
      query = query
        .where("collaborators", "array-contains-any", [
          { email: currentUser.email, role: Roles.OWNER },
          { email: currentUser.email, role: Roles.VIEWER },
          { email: currentUser.email, role: Roles.EDITOR },
        ])
        .where("organization", "==", orgRef);
    }
    return query;
  }, [alertId, currentUser.email, customer.id, customer.ref, isDoitEmployee, userOrganization]);

  const [publicAlert, publicLoading] = useCollectionData(publicQuery, { transform: alertsTransform });
  const [sharedAlert, sharedLoading] = useCollectionData(sharedQuery, { transform: alertsTransform });

  const alert = useMemo(
    () =>
      publicAlert && publicAlert?.length > 0
        ? publicAlert[0]
        : sharedAlert && sharedAlert?.length > 0
          ? sharedAlert[0]
          : null,
    [publicAlert, sharedAlert]
  );

  return [alert, publicLoading || sharedLoading];
};
