import { CloudFlowNodeType, CloudFlowProvider, NODE_STATUS } from "@doitintl/cmp-models";
import { type Node } from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { type Position } from "../../types";

// TODO: Implement the getNode function correctly as per the node types
export const getNode = (nodeType: CloudFlowNodeType, nodeId: string, position?: Position) => {
  switch (nodeType) {
    case CloudFlowNodeType.TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.TRIGGER,
        position: position ?? { x: 0, y: 0 },
        data: { name: "Daily 9 am", status: NODE_STATUS.VALIDATED },
      };
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.MANUAL_TRIGGER,
        position: position ?? { x: 0, y: 0 },
        data: { name: "Manually Start", status: NODE_STATUS.VALIDATED },
      };
    case CloudFlowNodeType.ACTION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position: position ?? { x: 0, y: 300 },
        parameters: {
          provider: CloudFlowProvider.GCP,
          operation: {
            id: "folders.insert",
            service: "storage",
            version: "v1",
            provider: CloudFlowProvider.GCP,
          },
          configurationValues: {
            organizationId: "",
            serviceAccount: "",
          },
          formValues: {
            BlockDeviceMappings: [{ Ebs: { VolumeType: "io1" } }],
          },
        },
        data: {
          name: "Perform action",
          isActive: false,
          status: NODE_STATUS.ERROR,
          statusMessage: "Additional permissions required",
        },
      };
    case CloudFlowNodeType.APPROVAL:
      return {
        id: nodeId,
        type: CloudFlowNodeType.APPROVAL,
        position: position ?? { x: 0, y: 300 },
        data: { name: "Send for approval", status: NODE_STATUS.VALIDATED },
      };
    case CloudFlowNodeType.CONDITION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.CONDITION,
        position: position ?? { x: 0, y: 300 },
        data: { name: "Condition", status: NODE_STATUS.VALIDATED },
        parameters: {},
        requiresValidation: true,
      };
    case CloudFlowNodeType.LOOP:
      return {
        id: nodeId,
        type: CloudFlowNodeType.LOOP,
        position: position ?? { x: 0, y: 300 },
        data: { name: "Loop", status: NODE_STATUS.VALIDATED },
      };
    default:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position: position || { x: 0, y: 300 },
        data: { name: "Perform action", status: NODE_STATUS.VALIDATED },
      };
  }
};

export const createConditionNodes = (
  newNode: Node,
  handleAddNode: (nodeType: CloudFlowNodeType, id: string, position?: Position) => void,
  handleDeleteNode: (id: string) => void
) => {
  const trueNodeId = uuidv4();
  const trueNodeGhostId = uuidv4();
  const falseNodeId = uuidv4();
  const falseNodeGhostId = uuidv4();
  const xPositionVariable = 200;
  const yPositionVariable = 250;

  const trueNodeTree = [
    {
      id: trueNodeId,
      type: CloudFlowNodeType.ACTION_STEP,
      data: {
        name: "What do you want to do?",
        onAddNode: handleAddNode,
        onDeleteNode: () => handleDeleteNode(trueNodeId),
        position: {
          x: newNode.position.x - xPositionVariable,
          y: newNode.position.y + yPositionVariable,
        },
      },
      position: {
        x: newNode.position.x - xPositionVariable,
        y: newNode.position.y + yPositionVariable,
      },
    },
    {
      id: trueNodeGhostId,
      type: "ghost",
      position: { x: newNode.position.x - xPositionVariable, y: newNode.position.y + 2 * yPositionVariable + 50 },
      data: {},
    },
  ] as Node[];

  const falseNodeTree = [
    {
      id: falseNodeId,
      type: CloudFlowNodeType.ACTION_STEP,
      data: {
        name: "What do you want to do?",
        onAddNode: handleAddNode,
        onDeleteNode: () => handleDeleteNode(falseNodeId),
        position: {
          x: newNode.position.x + xPositionVariable,
          y: newNode.position.y + yPositionVariable,
        },
      },
      position: {
        x: newNode.position.x + xPositionVariable,
        y: newNode.position.y + yPositionVariable,
      },
    },
    {
      id: falseNodeGhostId,
      type: "ghost",
      position: { x: newNode.position.x + xPositionVariable, y: newNode.position.y + 2 * yPositionVariable + 50 },
      data: {},
    },
  ] as Node[];

  return { trueNodeTree, falseNodeTree, trueNodeId, falseNodeId, trueNodeGhostId, falseNodeGhostId };
};

// TODO: Implement the updateNodePositions function correctly
export const updateNodePositions = (node: Node, minheight: number, edgeVar: number) => {
  const heightAdjustment = Math.max(node.measured?.height ?? minheight, minheight);
  return {
    ...node,
    position: {
      ...node.position,
      y: node.position.y + heightAdjustment + edgeVar,
    },
  };
};
