import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  type PerkModel,
  type PerkPlatform,
  perkPlatformList,
  type PerkSolutionType,
  type PerkType,
} from "@doitintl/cmp-models";
import { Chip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import AWSLogo from "../../assets/aws-logo.svg";
import AzureLogo from "../../assets/azure-logo.svg";
import GSuiteLogo from "../../assets/g-suite.png";
import GCPLogo from "../../assets/gcp-logo.svg";
import { platformValue } from "../../Support/utils";
import { type PerksWData } from "../../types";
import { type GroupedPerks } from "./types";
import type { PerkPageOverviewId } from "../../Navigation/config/pages";

export const toBeautifiedName = (key: string): string | undefined => {
  switch (key) {
    case "amazon-web-services":
      return "AWS";
    case "g-suite":
      return "Google Workspace";
    case "google-cloud":
      return "GCP";
    case "microsoft-azure":
      return "Azure";
    case "analytics":
      return "Analytics";
    case "big-data":
      return "Big Data";
    case "developer-tools":
      return "Developer Tools";
    case "security-and-monitoring":
      return "Security & Monitoring";
    case "networking":
      return "Networking";
    case "ml-and-ai":
      return "ML & AI";
    case "database":
      return "Databases";
    case "compute":
      return "Compute";
    case "blog-and-cms":
      return "Blog & CMS";
    case "data-sources":
      return "Data Sources";
    case "operating-systems":
      return "Operating Systems";
    case "storage":
      return "Storage";
    case "maps":
      return "Maps";
    case "advertising-and-gtm":
      return "Advertising & GTM";
    case "other":
      return "Other";
    default:
      return key;
  }
};

export const generateChipList = <T extends PerkPlatform | PerkSolutionType>(
  touched: boolean,
  title: string,
  entries: Record<T, boolean>,
  handleChange: (checked: boolean, key: T) => void
) =>
  touched && (
    <>
      <Typography variant="body2" component="span" color="text.secondary" sx={{ flex: "0 0 100%", fontSize: 12 }}>
        {title}:
      </Typography>
      {Object.entries(entries).map(
        ([key, value]) =>
          value && (
            <Chip
              key={key}
              label={toBeautifiedName(key)}
              onDelete={() => {
                handleChange(false, key as T);
              }}
              size="small"
              sx={{ backgroundColor: blue[100] }}
            />
          )
      )}
    </>
  );

export const isPerkPlatform = (value: string): value is PerkPlatform =>
  perkPlatformList.includes(value as PerkPlatform);

export const groupPerks = (perks: PerksWData[]): GroupedPerks =>
  perks.reduce<GroupedPerks>(
    (group, product) => {
      const { solutionType, platform } = product.data.fields;

      platform.forEach((p) => {
        group.platform[p] = group.platform[p] ?? [];
        group.platform[p].push(product);
      });

      solutionType?.forEach((s) => {
        group.solution[s] = group.solution[s] ?? [];
        group.solution[s].push(product);
      });
      return group;
    },
    { platform: {}, solution: {} } as GroupedPerks
  );

export const getPlatformNonPromotion = (perk: PerkModel | undefined) => {
  if (!perk) {
    return "";
  }
  if (["training", "pro-serv"].includes(perk?.fields?.type ?? "") && perk?.fields?.platform?.length === 1) {
    return platformValue(perk.fields.platform[0]);
  }
  return "";
};

export const perkPathByType: Record<PerkType, string> = {
  training: "training",
  accelerator: "training", // "accelerator" is training subtype until 20404 is complete
  workshop: "training",
  promotion: "promotion",
  "pro-serv": "professional-services",
  "isv-solution": "isv-solutions",
};

export const perkTypeByPageId: Record<PerkPageOverviewId, PerkType[]> = {
  "isv-solutions": ["isv-solution", "promotion"],
  training: ["accelerator", "training", "workshop"], // "accelerator" is training subtype until 20404 is complete
  "professional-services": ["pro-serv"],
};

export const perkTypeByPageIdForNewPerk: Record<PerkPageOverviewId, PerkType> = {
  "isv-solutions": "isv-solution",
  training: "training",
  "professional-services": "pro-serv",
};

export const perkCreateTitleByPageId: Record<PerkPageOverviewId, string> = {
  "isv-solutions": "ISV solution",
  training: "training",
  "professional-services": "professional service",
};

export const perkTitleByType: Record<PerkType, string> = {
  "isv-solution": "ISV solution",
  accelerator: "training", // "accelerator" is training subtype until 20404 is complete
  training: "training",
  "pro-serv": "professional service",
  workshop: "workshop",
  promotion: "promotion",
};

export const marketplaceMap = {
  [AssetTypeAmazonWebServices]: {
    title: "AWS",
    logo: AWSLogo,
  },
  [AssetTypeGoogleCloud]: { title: "GCP", logo: GCPLogo },
  [AssetTypeGSuite]: { title: "Google Workspace", logo: GSuiteLogo },
  [AssetTypeMicrosoftAzure]: { title: "Microsoft Azure", logo: AzureLogo },
};

const perkTypeNames: Record<PerkType, string> = {
  "isv-solution": "ISV solution",
  accelerator: "Accelerator",
  training: "Training",
  "pro-serv": "Professional service",
  workshop: "Workshop",
  promotion: "Promotion",
};

export const getSingularTypeText = (type: PerkType): string =>
  ["a", "e", "i", "o", "u"].includes(perkTypeNames[type][0]) ? `an ${perkTypeNames[type]}` : `a ${perkTypeNames[type]}`;
