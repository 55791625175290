import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { PermissionModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";

import Hide from "../../Components/HideChildren/Hide";
import { useQuery } from "../../utils/useQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    flex: 1,
    height: "92vh",
  },
  alertBox: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  missingPermission: {
    marginTop: 40,
  },
}));

export default function InsufficientPermissions() {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const [permissions] = useCollectionDataOnce(getCollection(PermissionModel), { idField: "id" });

  const isPermissions = useMemo(
    () =>
      query
        .get("missing_permissions")
        ?.split(",")
        .every((permission) => find(permissions, { id: permission })?.title),
    [permissions, query]
  );

  const navigateTroubleshoot = () => {
    const troubleshootUrl = query.get("troubleshoot_url");
    if (!troubleshootUrl) {
      return;
    }
    window.open(troubleshootUrl, "_blank");
  };

  const retry = () => {
    const originUrl = query.get("origin_url");
    if (!originUrl) {
      return;
    }
    history.push(originUrl);
  };

  if (permissions === undefined) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Alert
          style={{ flex: 1 }}
          action={
            <>
              <Hide smDown>
                <Button color="primary" style={{ marginRight: 6 }} onClick={retry} size="small">
                  Retry
                </Button>
                {query.get("troubleshoot_url") && (
                  <Button color="primary" onClick={navigateTroubleshoot} size="small" variant="contained">
                    Troubleshoot
                  </Button>
                )}
              </Hide>
            </>
          }
          severity="error"
        >
          You do not have sufficient permissions to view this page
        </Alert>
        <div className={classes.missingPermission}>
          <Typography variant="body2">
            {isPermissions
              ? "You are missing at least one of the following required permissions:"
              : query.get("missing_permissions")}
          </Typography>
          <ul>
            {isPermissions &&
              query
                .get("missing_permissions")
                ?.split(",")
                .map((permission, i) => (
                  <li key={i}>
                    <Typography variant="subtitle2">{find(permissions, { id: permission })?.title}</Typography>
                  </li>
                ))}
          </ul>
        </div>
      </CardContent>
    </Card>
  );
}
