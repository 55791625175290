import { useCallback, useMemo, useState } from "react";

export type MapOrEntries<K, V> = Map<K, V> | [K, V][];

// Public interface
export interface Actions<K, V> {
  set: (key: K, value: V) => void;
  setMulti: (entries: MapOrEntries<K, V>) => void;
  removeMulti: (key: K[]) => void;
  setAll: (entries: MapOrEntries<K, V>) => void;
  remove: (key: K) => void;
  reset: () => void;
}

// We hide some setters from the returned map to disable autocompletion
type Return<K, V> = [Omit<Map<K, V>, "set" | "clear" | "delete">, Actions<K, V>];

export function useMap<K, V>(initialState: MapOrEntries<K, V> = new Map()): Return<K, V> {
  const [map, setMap] = useState(new Map(initialState));

  const setMulti = useCallback((entries: MapOrEntries<K, V>) => {
    setMap((prev) => {
      const copy = new Map(prev);

      if (entries instanceof Map) {
        entries.forEach((value, key) => {
          copy.set(key, value);
        });
      } else {
        entries.forEach(([key, value]) => {
          copy.set(key, value);
        });
      }

      return copy;
    });
  }, []);

  const removeMulti = useCallback((entries: K[]) => {
    setMap((prev) => {
      const copy = new Map(prev);
      entries.forEach((key) => {
        copy.delete(key);
      });

      return copy;
    });
  }, []);

  const set = useCallback((key: K, value: V) => setMulti([[key, value]]), [setMulti]);

  const setAll = useCallback((entries: MapOrEntries<K, V>) => {
    setMap(() => new Map(entries));
  }, []);

  const remove = useCallback((key: K) => removeMulti([key]), [removeMulti]);

  const reset = useCallback(() => {
    setAll(new Map());
  }, [setAll]);

  const actions: Actions<K, V> = useMemo(
    () => ({
      setMulti,
      removeMulti,
      set,
      setAll,
      remove,
      reset,
    }),
    [setMulti, removeMulti, set, setAll, remove, reset]
  );

  return [map, actions];
}
