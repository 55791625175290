import "./locales/i18n";

import { useEffect, useMemo, useState } from "react";

import { withRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlagsProvider } from "flagged";
import { compose } from "recompose";

import { ApiContextProvider } from "./api/context";
import AppVersion from "./AppVersion";
import { AwsMarketplaceNewSubscription } from "./Components/AwsMarketplaceNewSubscription/AwsMarketplaceNewSubscription";
import PartnerBranding from "./Components/PartnerBranding";
import { AllRoutes } from "./Components/Routes/AllRoutes";
import { NoUserRoutes } from "./Components/Routes/NoUserRoutes";
import { SegmentPager } from "./Components/Segment/SegmentPager";
import { withSnackbar } from "./Components/SharedSnackbar/SharedSnackbar.context";
import { FirestoreRequestOverlay } from "./Components/TracerOverlay";
import VersionUpdater from "./Components/VersionUpdater";
import { isProduction } from "./constants";
import { AlgoliaSearchClientContextProvider } from "./Context/algoliaContext";
import { AsyncCurrencyConverterContextProvider } from "./Context/AsyncCurrencyConverterContext";
import { AuthContextConsumer, AuthContextProvider, AuthSubscribe } from "./Context/AuthContext";
import { ClientSdkContextProvider } from "./Context/ClientSdkContextProvider";
import { NotesContextProvider } from "./Context/customer/NotesContext";
import { CustomerContextProvider } from "./Context/CustomerContext";
import { CustomerSubscribe } from "./Context/CustomerSubscribe";
import { FirestoreContextProvider, FirestoreGuard } from "./Context/FirestoreContext";
import { GapiContextProvider } from "./Context/GapiContext";
import { HotkeyContextProvider } from "./Context/HotkeyContext";
import { ImpersonateContextProvider } from "./Context/impersonateContext";
import { InAppNotificationsContextProvider } from "./Context/inAppNotifications/InAppNotificationsContextProvider";
import { KeyboardShortcutsOpenProvider } from "./Context/keyboardShortcutsOpenProvider";
import { PartnerDataContextProvider } from "./Context/PartnerDataContext";
import { RestorePathProvider } from "./Context/RestorePathProvider";
import { UnsavedChangesContextProvider } from "./Context/UnsavedChangesContext";
import { useAppIndexUserState } from "./Context/useAppIndexUserState";
import { UserContextProvider, UserRolesGuard } from "./Context/UserContext";
import { MainLayout } from "./Navigation/Components/MainLayout";
import { ImpersonateAlert } from "./Pages/Login/ImpersonateAlert";
import { type Customer } from "./types";
import { AnnounceKitProvider } from "./utils/announcekit";
import { isDoitEmployeeOrPartner } from "./utils/common";
import { getFeatures } from "./utils/features";
import { MixpanelAssets } from "./utils/MixpanelAssets";
import { MixpanelTiers } from "./utils/MixpanelTiers";
import { usePrevious } from "./utils/usePrevious";
import { withProviders } from "./utils/withProviders";
import withRoot, { withDeveloperDocs } from "./withRoot";

const reactQueryDevtoolsKeyName = "showReactQueryDevtools";
const showFirestoreRequestsOverlayKeyName = "showFirestoreRequestsOverlay";

const shouldShowReactQueryDevtools = () => {
  if (process.env.NODE_ENV === "development") {
    return globalThis.localStorage.getItem(reactQueryDevtoolsKeyName) === "true";
  }

  return false;
};

const shouldShowFirestoreRequestsOverlay = () =>
  globalThis.localStorage.getItem(showFirestoreRequestsOverlayKeyName) === "true";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const AppIndex = () => {
  const { user, handleSignOut, onAuthStateChangedGotUser, onAuthStateChangedNullUser } = useAppIndexUserState();
  const [customer, setCustomer] = useState<Customer | null | undefined>();
  const [showReactQueryDevtools, setShowReactQueryDevtools] = useState(shouldShowReactQueryDevtools());
  const [showFirestoreRequestsOverlay] = useState(shouldShowFirestoreRequestsOverlay());

  const queryClient = useQueryClient();
  const prevCustomerId = usePrevious(customer?.id);

  useEffect(() => {
    if (!prevCustomerId || customer?.id === prevCustomerId) {
      return;
    }
    queryClient.removeQueries([prevCustomerId], { exact: false });
  }, [customer?.id, prevCustomerId, queryClient]);

  useEffect(() => {
    globalThis.toggleReactQueryDevtools = () => {
      setShowReactQueryDevtools((old) => {
        if (process.env.NODE_ENV === "development") {
          if (!old) {
            globalThis.localStorage.setItem(reactQueryDevtoolsKeyName, "true");
          } else {
            globalThis.localStorage.removeItem(reactQueryDevtoolsKeyName);
          }
        }

        return !old;
      });
    };
  }, []);

  const useValue = useMemo(() => ({ userModel: user ?? undefined }), [user]);

  // render the main layout for logged-in user
  return (
    <>
      {showReactQueryDevtools && <ReactQueryDevtools initialIsOpen={true} />}
      {showFirestoreRequestsOverlay && <FirestoreRequestOverlay />}
      <UserContextProvider value={useValue}>
        <PartnerBranding />
        <AuthContextConsumer>
          {({ isDoitEmployee, isDoitPartner, currentUser }) => (
            <>
              <AuthSubscribe onAuthStateChanged={onAuthStateChangedNullUser} />
              {currentUser === null && <NoUserRoutes />}
              <AwsMarketplaceNewSubscription />
              <FirestoreGuard>
                <AsyncCurrencyConverterContextProvider>
                  <AlgoliaSearchClientContextProvider>
                    <CustomerSubscribe setCustomer={setCustomer} />
                    <CustomerContextProvider value={{ customer }}>
                      <ClientSdkContextProvider>
                        <AuthSubscribe onAuthStateChanged={onAuthStateChangedGotUser} />

                        <VersionUpdater>
                          <AppVersion />
                        </VersionUpdater>
                        {isDoitEmployee !== undefined &&
                          isDoitPartner !== undefined &&
                          !isDoitEmployeeOrPartner({ isDoitEmployee, isDoitPartner }) && (
                            <>
                              <MixpanelAssets />
                              <MixpanelTiers />
                            </>
                          )}

                        {isProduction && <SegmentPager />}
                        <UserRolesGuard>
                          <GapiContextProvider>
                            <NotesContextProvider>
                              <InAppNotificationsContextProvider>
                                <MainLayout
                                  onSignOut={() => {
                                    setCustomer(undefined);
                                    return handleSignOut();
                                  }}
                                >
                                  <AllRoutes />
                                </MainLayout>
                              </InAppNotificationsContextProvider>
                              <ImpersonateAlert />
                            </NotesContextProvider>
                          </GapiContextProvider>
                        </UserRolesGuard>
                      </ClientSdkContextProvider>
                    </CustomerContextProvider>
                  </AlgoliaSearchClientContextProvider>
                </AsyncCurrencyConverterContextProvider>
              </FirestoreGuard>
            </>
          )}
        </AuthContextConsumer>
      </UserContextProvider>
    </>
  );
};

export default compose(
  withRoot,
  withSnackbar,
  withRouter,
  withProviders(
    [QueryClientProvider, { client: queryClient }],
    AuthContextProvider,
    ImpersonateContextProvider,
    HotkeyContextProvider,
    ApiContextProvider,
    FirestoreContextProvider,
    UnsavedChangesContextProvider,
    RestorePathProvider,
    AnnounceKitProvider,
    PartnerDataContextProvider,
    KeyboardShortcutsOpenProvider,
    [FlagsProvider, { features: getFeatures() }]
  ),
  withDeveloperDocs
)(AppIndex);
