import { Dialog, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Stepper } from "../../../Components/Stepper";
import { useCreateNewNodeDialog } from "./CreateNewActionNodeDialog/hooks";

type CreateNewNodeDialogProps = {
  isDialogOpened: boolean;
  handleCloseDialog: () => void;
};

const CreateNewActionNodeDialog = ({ isDialogOpened, handleCloseDialog }: CreateNewNodeDialogProps) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));
  const { stateWithTemplates, onClose, prevStepHandler } = useCreateNewNodeDialog({ handleCloseDialog });

  return (
    <Dialog open={isDialogOpened} onClose={onClose} fullScreen={fullScreen} fullWidth>
      <DialogTitle>{stateWithTemplates.steps[stateWithTemplates.currentStep].title}</DialogTitle>
      <Stepper
        relativeFooter
        isNextButtonHidden
        getCurrentStep={() => stateWithTemplates.currentStep}
        loading={false}
        onCancel={onClose}
        onSubmit={() => undefined}
        overrideStep={stateWithTemplates.currentStep}
        steps={stateWithTemplates.steps}
        submitButtonLabel="Continue"
        prevStepHandler={prevStepHandler}
      />
    </Dialog>
  );
};

export default CreateNewActionNodeDialog;
