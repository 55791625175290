import { type JSX, type MouseEvent, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { IncludedWithPlanInfo } from "../../../Components/IncludedWithPlanInfo/IncludedWithPlanInfo";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";
import HtmlTooltip from "../../../Components/Tooltip/HtmlTooltip";
import { type Insight } from "../../Insights/types";
import { useJiraApi } from "../../Integrations/Jira/useJiraApi";
import { IntroducingThreadsModal } from "../IntroducingThreadsModal";
import { CreateThreadModal } from "./CreateThreadModal";
import { ThreadsTooltip } from "./ThreadsTooltip";

type Props = {
  isEntitled: boolean;
  insight: Insight;
};

export const CreateThread = ({ isEntitled, insight }: Props): JSX.Element => {
  const { jiraInstanceData } = useJiraApi();

  const [isIntroducingThreadsModalVisible, setIsIntroducingThreadsModalVisible] = useState(false);
  const [isCreateThreadModalVisible, setIsCreateThreadModalVisible] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => setIsTooltipOpen(false);

  const handleCreateThreadClick = () => {
    if (jiraInstanceData?.status === "connected") {
      setIsCreateThreadModalVisible(true);
    } else {
      setIsIntroducingThreadsModalVisible(true);
    }
  };

  return (
    <>
      {jiraInstanceData === undefined ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={6}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="body1" sx={{ fontWeight: 500 }} mb={1}>
            Get your team involved
          </Typography>
          <Typography variant="body2" mb={2} color="text.primary" display="inline">
            Create a{" "}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <span>
                <HtmlTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<ThreadsTooltip isEntitled={isEntitled} />}
                >
                  <Box
                    sx={{ borderBottom: "1px dotted ", borderColor: "text.primary", cursor: "pointer" }}
                    component="span"
                    onClick={handleTooltipOpen}
                  >
                    DoiT Thread
                  </Box>
                </HtmlTooltip>
              </span>
            </ClickAwayListener>{" "}
            to manage your team's work on this insight.
          </Typography>
          {isEntitled ? (
            <Button
              variant="contained"
              size="medium"
              sx={{ my: 2 }}
              fullWidth
              onClick={() => handleCreateThreadClick()}
            >
              Create thread
            </Button>
          ) : (
            <Box mb={2}>
              <IncludedWithPlanInfo isIncluded={false} />
              <ContactSalesButton variant="outlined" fullWidth />
            </Box>
          )}
          {isIntroducingThreadsModalVisible && (
            <IntroducingThreadsModal onClose={() => setIsIntroducingThreadsModalVisible(false)} />
          )}
          {isCreateThreadModalVisible && (
            <CreateThreadModal
              onClose={() => setIsCreateThreadModalVisible(false)}
              insight={insight}
              jiraURL={jiraInstanceData?.url}
            />
          )}
        </>
      )}
    </>
  );
};
