import { type AxiosInstance, type Method } from "axios";

import { consoleErrorWithSentry } from "../../../utils";

async function doWidgetRequest(api: AxiosInstance, method: Method, customerId: string, reportId: string): Promise<any> {
  try {
    if (!customerId || !reportId) {
      return;
    }
    return await api.request({
      method,
      url: `/v1/customers/${customerId}/analytics/reports/${reportId}/widget`,
      data: {
        customerId,
        reportId,
      },
    });
  } catch (error) {
    consoleErrorWithSentry(error);
    return error;
  }
}

// Refreshes the widget report data
export async function refreshWidget(api, customerId, reportId) {
  return doWidgetRequest(api, "POST", customerId, reportId);
}

// Updates the widget report name and description
export async function updateWidget(api, customerId, reportId) {
  return doWidgetRequest(api, "PATCH", customerId, reportId);
}

// Deletes the report widget data
export async function deleteWidget(api, customerId, reportId) {
  return doWidgetRequest(api, "DELETE", customerId, reportId);
}
