import { useEffect, useMemo, useState } from "react";

import { FileCopyRounded as CopyIcon, OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import copy from "copy-to-clipboard";

import SlackIcon from "../../../assets/slack-mark.png";
import useQueryString from "../../../Components/hooks/useQueryString";
import { Loader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import NoEntitlement from "../../../Components/NoEntitlement/NoEntitlement";
import SimpleDialog from "../../../Components/SimpleDialog";
import { SlackAppIntegrationButton } from "../../../Components/Slack/SlackAppIntegrationButton";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { useSlackApi } from "./useSlackApi";
import { consoleErrorWithSentry, emptyFunction, type SharedChannelProps, slackText, useSnackbar } from "./utils";

const useStyles = makeStyles((theme) => ({
  emptyStateRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 180,
  },
  emptyStateInternal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 300,
    width: 680,
  },
  root: {
    minWidth: 275,
    marginBottom: 8,
    lineHeight: theme.spacing(2),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 4,
    paddingLeft: 34,
  },
  title: {
    padding: "34px 34px 20px",
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    paddingBottom: 8,
    height: 57,
  },
  circle: {
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 3,
    backgroundColor: "gray",
    marginLeft: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  summary: {
    alignItems: "center",
    display: "flex",
    marginTop: 8,
    marginRight: 4,
  },
  circleStatus: {
    marginLeft: 10,
    borderRadius: 4,
    width: 8,
    height: 8,
  },
  slackImage: {
    width: 52,
    height: 52,
  },
  slackLogo: {
    width: 24,
    height: 24,
  },
}));

export const Slack = () => {
  const classes = useStyles();
  const qs = useQueryString();

  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const { slackData, isDocEmpty, notifySupportForFailure, getChannelInvitation, createSharedChannel } = useSlackApi();
  const { isFeatureEntitled } = useTier();

  const { onOpen: snackbar } = useSnackbar();
  const [sharedChannelCreated, setSharedChannelCreated] = useState<SharedChannelProps | false>(false);
  const [sharedChannelLoading, setSharedChannelLoading] = useState<boolean>(false);
  const [joinChannelLoading, setJoinChannelLoading] = useState<boolean>(false);
  const [isSlackAppInstalled, setIsSlackAppInstalled] = useState(false);
  const noSlackChannel = isDocEmpty;

  const { isDoitEmployee } = useAuthContext();

  useEffect(() => {
    if (qs.install === "done") {
      setIsSlackAppInstalled(true);
    }
  }, [qs.install]);

  const redirectToInvitation = (invitationLink: string) => {
    const redirectUrl = new URL(invitationLink);
    window.open(redirectUrl.toString(), "_blank");
  };

  const joinSlackSharedChannel = async () => {
    const blankWindow = window.open("", "_blank");
    try {
      setJoinChannelLoading(true);
      const res = await getChannelInvitation();
      if (res.data && blankWindow) {
        blankWindow.location = res.data;
      }
    } catch (error: any) {
      // if it's an error from the API, append the message after ":"
      const axiosErrorMessage = (error?.response?.data?.error && `: ${error?.response?.data?.error}`) || "";
      snackbar({
        message: `${slackText.CANNOT_JOIN_CHANNEL}${axiosErrorMessage}`,
        variant: "error",
        autoHideDuration: 2000,
      });
      blankWindow?.close();
      consoleErrorWithSentry(error);
    } finally {
      setJoinChannelLoading(false);
    }
  };

  const createSlackSharedChannel = async () => {
    try {
      setSharedChannelLoading(true);
      const res = await createSharedChannel();
      if (res.data) {
        setSharedChannelCreated({
          title: slackText.CREATED_SUCCESSFULLY,
          invitationUrl: res.data.invitationUrl,
          name: res.data.name,
        });
        setSharedChannelLoading(false);
      }
    } catch (error: any) {
      setSharedChannelCreated({
        title: slackText.CANNOT_CREATE,
        error: error?.response?.data?.error || error,
      });
      consoleErrorWithSentry(error);
      setSharedChannelLoading(false);
    }
  };

  const copyLink = (link: string) => () => {
    copy(link);
    snackbar({
      message: "Link copied to clipboard",
      variant: "info",
      autoHideDuration: 2000,
    });
  };

  const isSlackAppEntitled = useMemo<boolean>(
    () => isFeatureEntitled("notification:slack:app") || !isProductOnlyCustomer,
    [isFeatureEntitled, isProductOnlyCustomer]
  );
  const isSlackShareEntitled = useMemo<boolean>(
    () => isFeatureEntitled("notification:slack:shared") || !isProductOnlyCustomer,
    [isFeatureEntitled, isProductOnlyCustomer]
  );

  const sharedChannelButton = (invitationUrl, fullWidth = false) => (
    <TextField
      variant="outlined"
      disabled
      margin="dense"
      fullWidth={fullWidth}
      value={invitationUrl}
      InputProps={{
        readOnly: true,
        startAdornment: (
          <Tooltip title={slackText.COPY_LINK}>
            <IconButton onClick={copyLink(invitationUrl)} size="small">
              <CopyIcon />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );

  const renderEmptyState = () => (
    <div className={classes.emptyStateRoot}>
      <div className={classes.emptyStateInternal}>
        <Typography variant="h5" color="inherit">
          {slackText.CREATE_CHANNEL}
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ textAlign: "center" }}>
          {slackText.CREATE_TEXT}
        </Typography>
        <Tooltip
          title={!isSlackShareEntitled ? slackText.SHARED_BUTTON_UNAVAILABLE : ""}
          arrow={true}
          placement="right"
        >
          <span>
            <LoadingButton
              loading={sharedChannelLoading}
              color="primary"
              variant="contained"
              onClick={createSlackSharedChannel}
              disabled={sharedChannelLoading || !isSlackShareEntitled}
              mixpanelEventId="integrations.slack.create"
            >
              {sharedChannelLoading ? "Creating Channel" : "Create shared Slack Channel"}
            </LoadingButton>
          </span>
        </Tooltip>
        <div style={{ width: "100%" }}>
          <Divider orientation="horizontal" />
        </div>
        <SlackAppIntegrationButton
          hasSlackChannel={!noSlackChannel && isSlackShareEntitled}
          customerId={customer.id || ""}
          isSlackAppEntitled={isSlackAppEntitled}
        />
      </div>
    </div>
  );

  return (
    <Loader loading={isDocEmpty === undefined}>
      {!isSlackAppEntitled && !isSlackShareEntitled ? (
        <NoEntitlement feature={slackText.SLACK_INTEGRATIONS} />
      ) : noSlackChannel || !isSlackShareEntitled ? (
        renderEmptyState()
      ) : (
        <Stack sx={{ maxWidth: "md" }}>
          <Box>
            <Typography variant="h3" mb={1}>
              Slack
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Manage shared Slack channel notifications for{" "}
              <span style={{ fontWeight: 500 }}>{customer.domains[0]}</span>
            </Typography>
          </Box>
          {slackData && (
            <Card sx={{ marginTop: 3, marginBottom: 3, maxWidth: 600 }}>
              <CardHeader
                sx={{
                  ".MuiCardHeader-action": {
                    alignSelf: "center",
                    marginRight: 1,
                  },
                }}
                avatar={<img alt="slack" className={classes.slackImage} src={SlackIcon} />}
                action={
                  <LoadingButton
                    sx={{ alignSelf: "center" }}
                    loading={joinChannelLoading}
                    onClick={joinSlackSharedChannel}
                    disabled={joinChannelLoading}
                    variant="outlined"
                    color="primary"
                    mixpanelEventId="integrations.slack.open"
                  >
                    Open
                  </LoadingButton>
                }
                title="Your Slack shared channel:"
                subheader={isDoitEmployee ? slackData.data.name : undefined}
                titleTypographyProps={{ variant: "body1", color: "text.secondary" }}
                subheaderTypographyProps={{ variant: "body1", color: "text.primary", fontWeight: 500 }}
              />
            </Card>
          )}
          <SlackAppIntegrationButton
            isSlackAppEntitled={isSlackAppEntitled}
            hasSlackChannel={!noSlackChannel}
            customerId={customer.id}
          />
        </Stack>
      )}

      <SimpleDialog
        open={isSlackAppInstalled}
        title={slackText.INSTALLED_SUCCESSFULLY}
        onConfirm={() => {
          setIsSlackAppInstalled(false);
        }}
        showCancel={false}
        confirmButtonText="Done"
        onCancel={emptyFunction}
      >
        <DialogContent>
          <DialogContentText>
            <Typography>{slackText.YOU_CAN_SHARE}</Typography>
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>

      {sharedChannelCreated !== false && (
        <SimpleDialog
          open={!!sharedChannelCreated}
          title={sharedChannelCreated.title}
          onConfirm={() => {
            !sharedChannelCreated?.error && redirectToInvitation(sharedChannelCreated.invitationUrl || "");
            setSharedChannelCreated(false);
          }}
          confirmButtonText={sharedChannelCreated?.error ? "Done" : "Join channel"}
          confirmButtonIcon={
            sharedChannelCreated?.error ? undefined : <OpenInNewIcon style={{ marginLeft: 4, width: 20 }} />
          }
          onCancel={() => {
            notifySupportForFailure(sharedChannelCreated?.error);
          }}
          showCancel={!!sharedChannelCreated?.error}
          cancelButtonText="notify support"
        >
          <DialogContent>
            <DialogContentText>
              {!!sharedChannelCreated.invitationUrl && (
                <Typography>
                  {`Channel "${sharedChannelCreated.name}" created. ${slackText.USE_LINK}: `}
                  {sharedChannelButton(sharedChannelCreated.invitationUrl, true)}
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
        </SimpleDialog>
      )}
    </Loader>
  );
};
