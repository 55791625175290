import { type JSX, useMemo, useState } from "react";

import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from "@mui/material";

import { type SectionItem } from "./SideNavigationMenu";

type Props = {
  sections: SectionItem[];
};

export function MenuSectionsMobile({ sections }: Props) {
  const [options, selectedName] = useMemo(() => {
    const options: JSX.Element[] = [];
    let selectedName = "";
    sections
      .filter((section) => section.items.length > 0)
      .map((section) => {
        options.push(<ListSubheader key={section.name}>{section.name}</ListSubheader>);

        section.items.map((item) => {
          if (item.selected) {
            selectedName = item.name;
          }
          options.push(
            <MenuItem onClick={item.onClick} value={item.name} key={item.name}>
              {item.name}
            </MenuItem>
          );
        });
      });
    return [options, selectedName];
  }, [sections]);

  const [selectValue, setSelectValue] = useState(selectedName);

  return (
    <FormControl size="small" sx={{ minWidth: "85%" }}>
      <InputLabel htmlFor="grouped-select">Select dashboard</InputLabel>
      <Select
        id="grouped-select"
        value={selectValue}
        onChange={(event) => setSelectValue(event.target.value)}
        label="Select dashboard"
      >
        {options}
      </Select>
    </FormControl>
  );
}
