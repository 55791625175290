import { type Dispatch, type SetStateAction } from "react";

import ScheduleIcon from "@mui/icons-material/AccessTime";
import { IconButton, Tooltip } from "@mui/material";

import { tooltipText } from "../../../../assets/texts";
import { useAuthContext } from "../../../../Context/AuthContext";
import { type FirebaseUserWithEmail } from "../../../../Context/UserContext";
import { type ReportWSnap } from "../../../../types";

type props = {
  isCurrentUserOwner: boolean;
  report: ReportWSnap;
  setScheduleDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export const getScheduleButtonTooltip = (
  report: ReportWSnap,
  isCurrentUserOwner: boolean,
  currentUser: FirebaseUserWithEmail
) => {
  const isCurrentUserSubscribed = report.data.schedule?.to?.includes(currentUser.email ?? "") ?? false;
  let text: string;

  if (report.data.type === "preset") {
    text = tooltipText.REPORT_EMAIL_SCHEDULE_DISABLED_PRESET;
  } else if (report.data.schedule) {
    // If report is already scheduled
    if (isCurrentUserOwner) {
      text = tooltipText.REPORT_EMAIL_SCHEDULE_UPDATE;
    } else {
      text = isCurrentUserSubscribed
        ? tooltipText.REPORT_EMAIL_SCHEDULE_UNSUBSCRIBE
        : tooltipText.REPORT_EMAIL_SCHEDULE_SUBSCRIBE;
    }
  } else {
    // If report is not scheduled
    text = !isCurrentUserOwner ? tooltipText.REPORT_EMAIL_SCHEDULE_DISBLED_NOT_OWNER(report.data.owner) : "";
  }
  return text;
};

export const isScheduledButtonDisabled = (report: ReportWSnap, isCurrentUserOwner: boolean) =>
  report.data.type === "preset" || (!isCurrentUserOwner && !report.data.schedule);

const ScheduleButton = ({ isCurrentUserOwner, report, setScheduleDialogOpen }: props) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  return (
    <Tooltip title={getScheduleButtonTooltip(report, isCurrentUserOwner, currentUser)}>
      <span>
        <IconButton
          disabled={isScheduledButtonDisabled(report, isCurrentUserOwner)}
          onClick={() => setScheduleDialogOpen(true)}
          size="small"
        >
          <ScheduleIcon color={report.data.schedule ? "primary" : "inherit"} fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ScheduleButton;
