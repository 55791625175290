import { useReducer } from "react";

import cloneDeep from "lodash/cloneDeep";

import { type AuthSettingsActionType, type AuthSettingsType } from "../types";

function reducer(state: AuthSettingsType, action: AuthSettingsActionType) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case "SET_AUTO_PROVISION_ENABLED":
      {
        newState.autoProvision.enabled = action.payload;
      }
      break;
    case "ADD_AUTO_PROVISION_DOMAIN":
      {
        newState.autoProvision.allowedDomains.push(action.payload);
      }
      break;
    case "REMOVE_AUTO_PROVISION_DOMAIN":
      {
        newState.autoProvision.allowedDomains = newState.autoProvision.allowedDomains.filter(
          (domain) => domain !== action.payload
        );
      }
      break;
    case "SET_ALLOWED_PROVIDER":
      {
        newState.allowedProvider = action.payload;
      }
      break;
    default: {
      // do nothing
    }
  }
  return newState;
}

export function useAuthSettingsReducer(
  initialState: AuthSettingsType
): [AuthSettingsType, React.Dispatch<AuthSettingsActionType>] {
  const [state, dispatch] = useReducer(reducer, cloneDeep(initialState));
  return [state, dispatch];
}
