import { useHistory } from "react-router";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";

import DoitLogo from "../../../../assets/doit-logo-hero-square.svg";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { customersRoute } from "../../../../Navigation/core/utils";

const CloudConnectionStartCard = () => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const route = customersRoute(customer.id, "start-trial");

  return (
    <Card data-testid="start-solve-card">
      <Stack divider={<Divider />}>
        <Typography variant="h4" data-cy="title" sx={{ my: 2, ml: 3 }}>
          <Box component="img" src={DoitLogo} sx={{ height: "32px", width: "32px", mb: -1.23, mr: 1 }} />
          Connect your cloud to start using DoiT Cloud Solve
        </Typography>
        <Typography ml={3} mt={3}>
          Get an overview of DoiT Solve and select which clouds you wish to connect.
        </Typography>
      </Stack>
      <Stack ml={3} my={3} justifyContent="flex-start" direction="row" spacing={2}>
        <Button variant="contained" onClick={() => history.push(route)}>
          Get started
        </Button>
      </Stack>
    </Card>
  );
};

export default CloudConnectionStartCard;
