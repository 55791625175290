import { type SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { TemplateVisibility } from "@doitintl/cmp-models";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, IconButton, InputAdornment, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import get from "lodash/get";

import { templateLibraryTxt } from "../../../assets/texts/CloudAnalytics";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import TemplateDialog from "../../../Components/TemplateDialog/TemplateDialog";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { CREATE_TEMPLATE_QUERY_PARAM, TemplateVisibilityRoles } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import { useReportDimensionsContext } from "../Context";
import { getMetricLabel } from "../utilities";
import ReportTemplatesSideFilters from "./ReportTemplatesSideFilters";
import ReportTemplatesTab from "./ReportTemplatesTab";
import { type ReportTemplateCard } from "./types";
import { searchInCategories, searchInClouds, templateLibraryConfiguration } from "./utils";

export type SelectedFilters = {
  [key: string]: string[] | number[];
};

type TabData = {
  label: string;
  value: number;
  cards: ReportTemplateCard[];
};

type Props = {
  reportTemplateCards: ReportTemplateCard[];
};

const ReportTemplatesBrowser = ({ reportTemplateCards }: Props) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();
  const { customer } = useCustomerContext();
  const { isMobile } = useFullScreen();
  const [filteredTemplateCards, setFilteredTemplateCards] = useState(reportTemplateCards);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { dimensions } = useReportDimensionsContext();
  const { transforms } = useAnalyticsContext();
  const [currentTemplateCard, setCurrentTemplateCard] = useState<ReportTemplateCard | null>();
  const { isDoitEmployee } = useAuthContext();
  const { current: touched } = useRef(new Set());

  useEffect(() => {
    mixpanel.track("analytics.templates.list-open");
  }, []);

  const configuration = useMemo(
    () =>
      currentTemplateCard &&
      dimensions &&
      templateLibraryConfiguration(transforms, currentTemplateCard.visibleVersion.config, dimensions),
    [transforms, currentTemplateCard, dimensions]
  );

  const applySearch = useCallback(
    (newTemplateCards: ReportTemplateCard[]) => {
      const searchValueText = searchValue.toLowerCase();

      return newTemplateCards.filter((card) => {
        const cardVisibleVersion = card.visibleVersion;
        return (
          cardVisibleVersion.name.toLowerCase().includes(searchValueText) ||
          cardVisibleVersion.description.toLowerCase().includes(searchValueText) ||
          getMetricLabel(cardVisibleVersion.config.metric, "", "").toLowerCase().includes(searchValueText) ||
          searchInCategories(cardVisibleVersion, searchValueText) ||
          searchInClouds(cardVisibleVersion, searchValueText)
        );
      });
    },
    [searchValue]
  );

  const resetFilters = () => {
    setSearchValue("");
    setSelectedFilters({});
  };

  useEffect(() => {
    let newTemplateCards = reportTemplateCards;

    for (const [path, values] of Object.entries(selectedFilters)) {
      for (const val of values) {
        newTemplateCards = newTemplateCards.filter((templateCard) => {
          const templateCardField = get(templateCard, path);
          if (Array.isArray(templateCardField)) {
            return templateCardField?.includes(val);
          }
          return templateCardField === val;
        });
      }
    }

    if (touched.has("search") && searchValue.length > 1) {
      newTemplateCards = applySearch(newTemplateCards);
    }

    setFilteredTemplateCards(newTemplateCards);
  }, [applySearch, reportTemplateCards, searchValue, selectedFilters, touched]);

  const tabsList: TabData[] = useMemo(() => {
    if (!isDoitEmployee) {
      return [
        {
          label: templateLibraryTxt.ALL,
          value: 0,
          cards: filteredTemplateCards,
        },
      ];
    }

    const privateTemplateCards = filteredTemplateCards.filter(
      (card) => card.visibleVersion.visibility === TemplateVisibility.PRIVATE
    );
    const doitTemplateCards = filteredTemplateCards.filter(
      (card) => card.visibleVersion.visibility === TemplateVisibility.INTERNAL
    );
    const publicTemplateCards = filteredTemplateCards.filter(
      (card) => card.visibleVersion.visibility === TemplateVisibility.GLOBAL
    );

    return [
      {
        label: templateLibraryTxt.ALL,
        value: 0,
        cards: filteredTemplateCards,
      },
      {
        label: TemplateVisibilityRoles.PRIVATE,
        value: 1,
        cards: privateTemplateCards,
      },
      {
        label: TemplateVisibilityRoles.DOIT,
        value: 2,
        cards: doitTemplateCards,
      },
      {
        label: TemplateVisibilityRoles.PUBLIC,
        value: 3,
        cards: publicTemplateCards,
      },
    ];
  }, [filteredTemplateCards, isDoitEmployee]);

  const onSearchTextChange = (e) => {
    touched.add("search");
    setSearchValue(e.target.value);
  };

  const onTabChange = (e: SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
  };

  const reportTemplateUrl = useMemo(() => {
    if (currentTemplateCard?.visibleVersion.reportId) {
      return `/customers/${customer.id}/analytics/reports/${currentTemplateCard?.visibleVersion.reportId}?templateId=${currentTemplateCard.templateRefId}`;
    }
  }, [customer.id, currentTemplateCard?.visibleVersion.reportId, currentTemplateCard?.templateRefId]);

  const redirectToReportTemplate = useCallback(
    (query?: string) => {
      if (reportTemplateUrl) {
        history.push(`${reportTemplateUrl}${query}`, { prevPage: routeMatchURL });
      }
    },
    [reportTemplateUrl, history, routeMatchURL]
  );

  const handleCardClicked = (reportTemplateCard: ReportTemplateCard) => {
    setCurrentTemplateCard(reportTemplateCard);
  };

  useEffect(() => {
    if (isDoitEmployee) {
      redirectToReportTemplate("&edit=true");
    }
  }, [isDoitEmployee, redirectToReportTemplate]);

  return (
    <Box sx={{ pt: 1 }}>
      <Stack direction="row">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h1">{templateLibraryTxt.REPORT_TEMPLATES}</Typography>
          <Typography sx={{ color: "text.secondary", mt: 1 }}>{templateLibraryTxt.EXPAND_INSIGHTS}</Typography>
        </Box>
        {isDoitEmployee && (
          <Box data-cy="create-report-template" sx={{ mt: "auto" }}>
            <Link to={`/customers/${customer.id}/analytics/reports/create?${CREATE_TEMPLATE_QUERY_PARAM}=true`}>
              <Button variant="contained">{templateLibraryTxt.CREATE_NEW_TEMPLATE}</Button>
            </Link>
          </Box>
        )}
      </Stack>
      <Grid container sx={{ mt: 6 }}>
        <Grid item md={3} lg={2} sx={{ pr: 6 }}>
          {!isMobile && (
            <ReportTemplatesSideFilters
              reportTemplateCards={filteredTemplateCards}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          )}
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            data-cy="report-templates-search"
            fullWidth
            onChange={onSearchTextChange}
            placeholder={templateLibraryTxt.SEARCH_TEMPLATES}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <IconButton data-cy="report-templates-clear-search" size="small" onClick={() => setSearchValue("")}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
          {!filteredTemplateCards.length && (searchValue || Object.keys(selectedFilters).length) ? (
            <Stack spacing={1} sx={{ alignItems: "center", mt: 16 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {templateLibraryTxt.NO_REPORT_TEMPLATES_FOUND}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {templateLibraryTxt.PLEASE_REMOVE_FILTERS}
              </Typography>
              <Button onClick={resetFilters}>{templateLibraryTxt.RESET_FILTERS}</Button>
            </Stack>
          ) : (
            <Box sx={{ pt: 3 }}>
              {isDoitEmployee && (
                <Tabs
                  data-cy="report-templates-tabs"
                  value={activeTab}
                  onChange={onTabChange}
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    ".MuiTab-root": { textTransform: "capitalize" },
                  }}
                >
                  {tabsList.map((tab) => (
                    <Tab key={`tab-${tab.value}`} label={`${tab.label} (${tab.cards.length})`} />
                  ))}
                </Tabs>
              )}
              <Box sx={{ mt: isDoitEmployee ? 4 : 0 }}>
                {tabsList.map((tab) => (
                  <ReportTemplatesTab
                    key={`tab-content-${tab.value}`}
                    activeTab={activeTab}
                    tabValue={tab.value}
                    reportTemplateCards={tab.cards}
                    handleCardClicked={handleCardClicked}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      {currentTemplateCard && !isDoitEmployee && configuration && (
        <TemplateDialog
          name={currentTemplateCard.visibleVersion.name}
          description={currentTemplateCard.visibleVersion.description}
          config={currentTemplateCard.visibleVersion.config}
          categories={currentTemplateCard.visibleVersion.categories}
          cloud={currentTemplateCard.visibleVersion.cloud}
          updatedDate={currentTemplateCard.visibleVersion.timeModified}
          configuration={configuration}
          exploreTemplate={() => redirectToReportTemplate("")}
          handleClose={() => setCurrentTemplateCard(null)}
        />
      )}
    </Box>
  );
};

export default ReportTemplatesBrowser;
