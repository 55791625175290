import { useEffect } from "react";

import { PathError } from "@doitintl/models-firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { type AxiosError, HttpStatusCode, isAxiosError } from "axios";
import { type FirebaseError } from "firebase/app";

import { errorMonitor } from "../utils/errorHandling";
import { useToggle } from "../utils/useToggle";
import StartFreeTrialButton from "./StartTrialButton/StartTrialButton";

function isFirebasePermissionDeniedError(error: Error): error is FirebaseError {
  return "name" in error && error.name === "FirebaseError" && "code" in error && error.code === "permission-denied";
}

function isPathPermissionDeniedError(error: Error): error is PathError {
  return error instanceof PathError && error.message.startsWith("PathError: Missing or insufficient permissions.");
}

function isAxiosForbiddenError(error: Error): error is AxiosError {
  return isAxiosError(error) && error.response?.status === HttpStatusCode.Forbidden;
}

function isCustomEvent(event: Event): event is CustomEvent {
  return event instanceof CustomEvent;
}

export const PresentationModeSentinel = () => {
  const [open, showDialog, closeDialog] = useToggle(false);

  useEffect(() => {
    const checkErrorAndShowDialog = (error: Error) => {
      if (
        isFirebasePermissionDeniedError(error) ||
        isAxiosForbiddenError(error) ||
        isPathPermissionDeniedError(error)
      ) {
        showDialog();
      }
    };

    const promiseRejectionHandler = (event: PromiseRejectionEvent) => checkErrorAndShowDialog(event.reason);
    const errorHandler = (event: ErrorEvent) => checkErrorAndShowDialog(event.error);
    const errorMonitorHandler = (event: Event) => {
      if (isCustomEvent(event)) {
        checkErrorAndShowDialog(event.detail);
      }
    };

    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", promiseRejectionHandler);
    window.addEventListener("rejectionhandled", promiseRejectionHandler);
    errorMonitor.addEventListener("error", errorMonitorHandler);

    return () => {
      errorMonitor.removeEventListener("error", errorMonitorHandler);
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", promiseRejectionHandler);
      window.removeEventListener("rejectionhandled", promiseRejectionHandler);
    };
  }, [showDialog]);

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Start a free trial to save content</DialogTitle>
      <DialogContent sx={{ maxWidth: 440 }}>
        <DialogContentText>
          <Typography variant="body1">
            Start a free trial with your own data to be able to save content in the DoiT Console.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={closeDialog} variant="text">
          Cancel
        </Button>
        <StartFreeTrialButton onClick={closeDialog} />
      </DialogActions>
    </Dialog>
  );
};
