import capitalize from "lodash/capitalize";

import { globalText, reportText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../types/FilterTable";

export const filterColumns = [
  {
    label: reportText.TABLE_HEADERS.ID,
    path: "snapshot.id",
    requireExactMatch: true,
  },
  {
    label: reportText.TABLE_HEADERS.NAME,
    path: "data.name",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: reportText.TABLE_HEADERS.DESCRIPTION,
    path: "data.description",
    comparators: ["contains"],
  },
  {
    label: reportText.TABLE_HEADERS.PUBLIC,
    path: "data.public",
    toOption: (value) => ({ value, label: value ? capitalize(value) : globalText.PRIVATE }),
  },
  {
    label: reportText.TABLE_HEADERS.OWNER,
    path: "data.owner",
  },
  {
    label: reportText.TABLE_HEADERS.TYPE,
    path: "data.type",
    toOption: (value) => ({ value, label: capitalize(value) }),
  },
  {
    label: globalText.LAST_MODIFIED,
    path: "data.timeModified",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: reportText.TABLE_HEADERS.SCHEDULE,
    path: "_filters.schedule",
    comparators: ["==", "!="],
    toOption: (value) => {
      let strValue;
      switch (value) {
        case 2:
          strValue = reportText.SUBSCRIBED;
          break;
        case 1:
          strValue = reportText.UNSUBSCRIBED;
          break;
        default:
          strValue = reportText.NOT_SCHEDULED;
      }
      return {
        value,
        label: strValue,
      };
    },
  },
] as const;

export const headers: HeaderColumn[] = [
  {
    value: "data.name",
    label: reportText.TABLE_HEADERS.NAME,
    tooltip: reportText.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.owner",
    label: reportText.TABLE_HEADERS.OWNER,
    tooltip: reportText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.type",
    label: reportText.TABLE_HEADERS.TYPE,
    tooltip: reportText.TABLE_HEADERS.TYPE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.timeModified",
    label: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "@",
    style: { align: "left", padding: "checkbox" },
    hidden: { smDown: true },
  },
];
