import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";
import upperCase from "lodash/upperCase";

import { doitCustomerId } from "../../../utils/customers";
import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type AlgoliaPlugin } from "../types";
import { filterByCustomer, getAlgoliaAnalyticsProps, isSearchForCustomer } from "../utils";

type User = Hit<{
  objectID: string;
  displayName: string;
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  customerId: string;
}>;

const createUsersPlugin: AlgoliaPlugin<User> = () => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const getUserProfileUrl = (customerId: string, userId: string) =>
      `/customers/${customerId ?? doitCustomerId}/iam/users/${userId}`;

    const usersSource: AutocompleteSource<User> = {
      sourceId: "users",
      templates: {
        item({ item }) {
          const titleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["email"],
          });
          const subtitleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["displayName"],
          });

          return (
            <AlgoliaResultListItem
              highlightedTitle={titleParts}
              highlightedSubtitle={subtitleParts}
              avatarLogo={item.avatar}
              avatarFallback={item.displayName.substring(0, 2).toUpperCase()}
            />
          );
        },
      },
      onSelect({ item, event }) {
        const url = getUserProfileUrl(item.customerId, item.objectID);

        context.onSelectRow({
          id: item.objectID,
          label: item.objectID,
          avatarFallback: `
              ${upperCase(item.firstName?.substring(0, 1))}
              ${upperCase(item.lastName?.substring(0, 1))}`,
          highlightedTitle: [{ value: item.email, isHighlighted: false }],
          highlightedSubtitle: [{ value: item.displayName, isHighlighted: false }],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              ...getAlgoliaAnalyticsProps(context),
              indexName: "users",
              query,
              params: {
                hitsPerPage: 10,
              },
              filters: filterByCustomer(context),
            },
          ],
          transformResponse({ hits }: { hits: Hit<User>[][] }) {
            return hits.map((indexResults) =>
              indexResults
                .filter((hit) => hit.customerId !== undefined)
                .map((hit) => {
                  const displayName = hit.displayName
                    ? hit.displayName
                    : `${hit.firstName || ""} ${hit.lastName || ""}`;
                  return {
                    ...hit,
                    displayName: !displayName.trim() ? "Name not set" : displayName,
                  };
                })
            );
          },
        });
      },
      getItemUrl({ item }) {
        return getUserProfileUrl(item.customerId, item.objectID);
      },
    };
    return [usersSource];
  },
});

export const useUsersPlugin = (restrictedIndices?: AlgoliaIndexType[]): AutocompletePlugin<User, undefined> | null =>
  useMemo(() => {
    if (restrictedIndices?.includes("users")) {
      return null;
    }

    return createUsersPlugin();
  }, [restrictedIndices]);
