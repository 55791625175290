import { useMemo } from "react";

import { type AssetType } from "@doitintl/cmp-models";
import { Autocomplete, TextField } from "@mui/material";

import { assetTypeName } from "../../../../../utils/common";
import { type AssetDescriptor } from "../../../BillingProfileForm.models";

const getAssetChipLabel = (asset: AssetDescriptor) => {
  switch (asset.data.type) {
    case "amazon-web-services":
      return `AWS Account ${asset.data.properties.accountId} (${asset.data.properties.name})`;
    case "g-suite":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    case "google-cloud":
      return `Google Cloud Billing Account ${asset.data.properties.billingAccountId} (${asset.data.properties.displayName})`;
    case "google-cloud-project":
      return `Google Cloud Project (${asset.data.properties.projectId})`;
    case "office-365":
      return `${asset.data.properties.subscription.offerName} (${asset.data.properties.customerDomain})`;
    case "microsoft-azure":
      return `${asset.data.properties.subscription.skuDescription} (${asset.data.properties.customerDomain})`;
    case "zendesk":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    case "bettercloud":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    default:
      return asset.id;
  }
};

const googleFamilyAssetTypes: AssetType[] = ["google-cloud", "google-cloud-project"];

export type AssetsMultiselectProps = {
  value: AssetDescriptor[];
  availableAssets: AssetDescriptor[];
  onChange: (newAssets: AssetDescriptor[]) => void;
};

export const AssetsMultiselect = ({ value, availableAssets, onChange }: AssetsMultiselectProps) => {
  const assetsFilteredByType = useMemo(() => {
    const typeFromValue = value[0]?.data.type;
    if (typeFromValue === undefined) {
      return availableAssets;
    }
    return availableAssets.filter(
      ({ data: { type } }) =>
        type === typeFromValue ||
        (googleFamilyAssetTypes.includes(typeFromValue) && googleFamilyAssetTypes.includes(type))
    );
  }, [availableAssets, value]);

  return (
    <Autocomplete<AssetDescriptor, true>
      multiple
      options={assetsFilteredByType}
      groupBy={(option) => assetTypeName(option.data.type)}
      getOptionLabel={getAssetChipLabel}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      filterSelectedOptions
      disableCloseOnSelect={true}
      renderInput={(params) => <TextField {...params} size="medium" label="Assigned assets" />}
      onChange={(_event, newValue) => onChange(newValue)}
      size="small"
      limitTags={6}
      sx={{
        ".MuiAutocomplete-tag": {
          maxWidth: "calc(100vw - 168px)",
        },
      }}
    />
  );
};
