import { useState } from "react";

import { InputAdornment, TextField } from "@mui/material";

type Props = {
  costNum: string;
  costNumError: boolean;
  costInputLabel: string;
  onCostNumChange: (s: string) => void;
  disabled: boolean;
};

const CostInputField = (props: Props) => {
  const { costNum, costNumError, costInputLabel, onCostNumChange, disabled } = props;
  const [touched, setTouched] = useState(false);

  const shouldShowError = costNumError && touched;

  return (
    <TextField
      id="costNumInput"
      name="costNum"
      label={costInputLabel}
      fullWidth
      value={costNum}
      onChange={(e) => onCostNumChange(e.target.value)}
      onBlur={() => setTouched(true)}
      error={shouldShowError}
      helperText={shouldShowError && "Please insert a number between 0 and 100"}
      variant="outlined"
      data-testid="aws-contract-support-costNum-input"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        id: "aws-support-payer-form-input",
      }}
      disabled={disabled}
    />
  );
};

export default CostInputField;
