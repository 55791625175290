import { useEffect } from "react";

export const useInterval = (func: () => void, updateTime: number | undefined) => {
  useEffect(() => {
    const interval = setInterval(() => {
      func();
    }, updateTime ?? 100);

    return () => clearInterval(interval);
  }, [func, updateTime]);
};
