import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Divider from "@mui/material/Divider";

type Props = {
  onClose: () => void;
};

export const RegisterInterestDialog = ({ onClose }: Props) => (
  <Dialog maxWidth="xs" open={true} data-cy="register-interest-dialog">
    <DialogTitle>Thank you for registering interest</DialogTitle>
    <DialogContent>
      <DialogContentText>
        One of our dedicated team members will be in touch to ensure you can take advantage of this great offer.
      </DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button color="primary" variant="contained" type="submit" onClick={onClose} data-cy="done">
        Done
      </Button>
    </DialogActions>
  </Dialog>
);
